import Reat from 'react';

import './FooterSignUp.css';

interface FooterSignUpProps {
    role?:string
    handleLogin?: () => void;
    terms?: () => void;
}

export const FooterSignUp: React.FC<FooterSignUpProps> = ({
    role,
    handleLogin=()=>{},
    terms
}) => {
    return (
        <div className='footerSignUp-container' role={role}>
            <div className="paragraph">Al unirse, aceptas los <span style={{color:'var(--blue-2)', cursor: 'pointer'}} onClick={terms}>términos y condiciones</span> de Arkabia.</div>
            <hr className="footerSignUp-divider" />
            <div className="small-bold footerSignUp-colorText">¿Ya eres miembro? <span style={{color: 'var(--blue-2)', cursor: 'pointer'}} onClick={handleLogin}>Ingresa aquí</span> </div>
            {/* <div className="small-bold footerSignUp-colorText">¿Eres agencia de aduana? <span style={{color: 'var(--blue-2)'}}>Regístrate aquí</span></div> */}
        </div>
    )
}