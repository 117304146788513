import React, { useEffect, useState } from "react";

/** importando modals */
import { Modal } from '../Modal/Modal';

/** importando css */
import "./ModalPrivacyPolicy.css"

/** declarando interface */
interface ModalPrivacyPolicyProps {
    showModal: boolean; // estado boolean que cotrola el modal 
    setShowModal: (e: boolean) => void; // medoto para modificar la variable showModal
    modalTyC: ()=>void
}

/** componente modal cotizacion
 * renderiza el modal 
 */
export const ModalPrivacyPolicy: React.FC<ModalPrivacyPolicyProps> = ({
    showModal,
    setShowModal,
    modalTyC
}) => {

    return (
        <Modal title={'Declaración de privacidad de Arkabia'} subTitle={''} show={showModal} setShow={setShowModal} >
            <div className="modal-privacy-container">
                <div className="modal-privacy-p">
                    <div className="paragraph">
                        En Arkabia entendemos la protección de datos personales como una oportunidad para generar valor a las Personas Usuarias. 
                        Por ello, hacemos uso responsable de la información personal y privada que nos confían las personas que usan nuestro servicio.
                    </div>
                </div>

                <p className="paragraph modal-privacy-p-one">
                    Permitiéndoles, en reciprocidad, operar con seguridad y confianza dentro de nuestro Ecosistema.
                </p>

                <p className="paragraph modal-privacy-p-one">
                    En este sentido, cualquier uso o tratamiento de datos realizado por Arkabia siempre será con el objetivo de mejorar sus servicios. Y 
                    así beneficiar a las Personas Usuarias.
                </p>

                <p className="paragraph modal-privacy-p-one">
                    Los datos que podríamos recolectar directamente al registrarte o utilizar nuestros servicios se muestran a continuación:
                </p>

                
                    <ul className="paragraph modal-privacy-ul">
                        <li>Nombre</li>
                        <li>Imagen personal (foto de perfil) </li>
                        <li>Número de documento o identificación válida</li>
                        <li>Información de contacto (como número de teléfono, domicilio, dirección de correo electrónico)</li>
                        <li>Datos de cuenta bancaria (número de cuenta, más no códigos o claves secretas)</li>
                        <li>Dispositivos desde donde accedes a la plataforma</li>
                        <li>Dirección IP de internet que utilizas al conectarte a nuestros servicios o al navegar nuestros sitios web. </li>
                        <li>Información transaccional y movimientos dentro de las plataformas de Arkabia (compras, pagos, devoluciones, preguntas,
                            retiros, transferencias, movimientos de la tarjeta, reclamos, facturación, datos fiscales, cuentas bancarias, 
                            mensajería interna)</li>
                        <li>URL desde la que provienen o acceden seguidamente (estén o no en nuestro sitio web). </li>
                        <li>Páginas visitadas dentro de Arkabia, las interacciones con dichas páginas, las búsquedas realizadas, las publicaciones, 
                            compras o ventas, calificaciones y réplicas ingresadas, reclamos realizados y recibidos, mensajes en los foros, entre 
                            otra información podrá ser almacenada y retenida.</li>
                        <li>Listas de contactos en Arkabia, nombre de clientes e información referida a las operaciones que mantienen con ellos.</li>
                        <li>Vinculaciones entre cuentas y usuarios, con motivo de acciones de prevención del fraude.</li>
                        <li>Datos para gestión de reclamos y juicios (información para la elaboración de documentos, antecedentes y estrategias).</li>
                    </ul>

                <p className="paragraph modal-privacy-p-one">
                    Asimismo se menciona la información que podríamos recopilar de otras fuentes:
                </p>

           
                    <ul className="paragraph modal-privacy-ul">
                        <li>Información recopilada para finalidades de prevención de fraude y cumplimiento de regímenes informativos (listas SBS, SUNAT, 
                            etc.).</li>
                        <li>Información crediticia, positiva y negativa, que obtenemos de bases o centrales de riesgo crediticio, empresas de telecomunicaciones 
                            y/o de fuentes de acceso público, de conformidad con la legislación aplicable.</li>
                        <li>Datos que se utilizan para la validación de identidad, completar o corregir información, obtenidos de fuentes seguras y confiables, 
                            tales como organismos públicos, proveedores de servicios o aliados comerciales con los que trabajamos.</li>
                    </ul>

                <p className="paragraph modal-privacy-p-bottom">
                    Por último, es preciso señalar que esta Declaración de Privacidad forma parte de los <a className="paragraph-header modal-link-privacy" onClick={modalTyC}>Términos y Condiciones de Arkabia</a>. 
                    Por lo que prestar tu consentimiento voluntario, expreso e informado a esta Declaración de Privacidad es un requisito esencial para poder reservar 
                    y/o tener cualquier tipo de relación con Arkabia.
                </p>
            </div>
        </Modal>
        
    )
}
