import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type BoatProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Boat: React.FC<BoatProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/boat.svg'}  alt="" />
        </div>
    )
}