import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type NubeProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Nube: React.FC<NubeProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/nube.svg"} alt="" />
    </div>
  );
};