import React, { useEffect, useRef, useState } from 'react';
import './ProgressCircle.css';

interface ProgressCircleProps {
    sqSize: number;
    size?: number;
    strokeWidth?: number;
    gradientId?: string;
    backgroundColor?: string;
    maxPercentage: number;
    textPercentage?: number;
    delay?: number; 
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({
    sqSize,
    strokeWidth = 10,
    maxPercentage,
    textPercentage = maxPercentage,
    gradientId = 'progress-gradient',
    backgroundColor = '#e0e0e0',
    delay = 0
}) => {
    const [percentage, setPercentage] = useState(0);
    const circleRef = useRef<SVGSVGElement>(null); 

    const radius = (sqSize - strokeWidth) / 2;
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    const dashArray = radius * Math.PI * 1.87;
    const dashOffset = dashArray - (dashArray * percentage) / 100;

    useEffect(() => {
        const startAnimation = (entries: IntersectionObserverEntry[]) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        setPercentage(maxPercentage);
                    }, delay); 
                } else {
                    setPercentage(0); 
                }
            });
        };

        const observer = new IntersectionObserver(startAnimation, { threshold: 0.5 });
        if (circleRef.current) {
            observer.observe(circleRef.current);
        }

        return () => {
            if (circleRef.current) observer.unobserve(circleRef.current);
        };
    }, [maxPercentage, delay]);

    return (
      <svg
          ref={circleRef}
          width={'100%'}
          height={'100%'}
          viewBox={viewBox}
          style={{ maxWidth: sqSize, maxHeight: sqSize }}
      >
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="56.54%" stopColor="#F7E246" />
            <stop offset="74.51%" stopColor="#FFC525" />
          </linearGradient>
        </defs>
        <circle
          className="circle-background"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          stroke={backgroundColor}
        />
        <circle
          className="circle-progress"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          stroke={`url(#${gradientId})`}
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
              transition: 'stroke-dashoffset 1.7s ease-out' 
          }}
        />
        <text
          className="big-subheader circle-text"
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
        >
          {`${textPercentage}%`}
        </text>
      </svg>
    );
};

export default ProgressCircle;
