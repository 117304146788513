import React from "react";

/** importando tipos */
import { RowsColorTypes } from "../../@types/rowsColorTypes";
import { RowContentType } from "../../@types/rowsContentType";
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

/** importando utilidades */
import {
  selectRowBackgroundColor,
  selectRowFontColor,
  selectRowJustifyContent,
  selectRowPadding,
} from "../../utils/selectRowStyles";

/** importando estilos */
import "./Row.css";

/* declaracion de variables de interface */
interface RowProps {
  title: any; // titulo del row
  msg?: any; // mensaje del row
  content: RowContentType; // tipo de las columnas text, imagen o button
  columValues: any[]; //  contenido de las columnas
  color: RowsColorTypes; // color del row
  className?: string; // clase del contenedor del row
  classRow?: string // clase del row
  classRowValue?: string; // clase de las la columnas
  imageClass?: string; // clase de la imagen
  onClick?: () => void | React.MouseEventHandler<HTMLDivElement>; // evento de click en un row
  isMobile?: boolean;
  handleImg? : (idx:number) => void;
  colorText?: boolean
}


/** Componente Row 
 * renderea un row 
 */
export const Row: React.FC<RowProps> = ({
  title, 
  msg = "",
  content,
  columValues,
  color,
  onClick,
  className='',
  classRow='',
  classRowValue='',
  imageClass,
  isMobile = false,
  handleImg = ()=>{},
  colorText = false
}) => {


  return (
    <div
      role='rowRole'
      className={"row smalltext-header "+className}
      style={{
        backgroundColor: selectRowBackgroundColor(color),
        color: selectRowFontColor(color),
        padding: selectRowPadding(content),
      }}
      onClick={onClick}
    >
      <div className={"row__title "+classRow}>{title}</div>
      <div className="row__values">
        {columValues.length>0 && <div
          className={"row__values "+classRowValue}
          // style={{
          //   justifyContent: `${selectRowJustifyContent(columValues)}`,
          // }}
        >
            {/* TODO CHANGE KEY */}
              {columValues && columValues.length>0 && columValues.map((val, idx) => (
                <div className={`row__values-item ${(" row__item_"+idx)} ${content === 'image' && "row__values-item__image"} ${colorText ? "rowTextWhite" : "rowTextBlack"}`}key={idx}>
                  {content === 'image'? (val.includes(".svg") ? <object className={imageClass} data={val} style={{cursor: 'pointer'}}  type="image/svg+xml"></object> : <img onClick={()=>handleImg(idx)} className={imageClass} alt='Agenciamiento de Aduana y Transporte"' src={val} />) :( content === 'number' ? ConverMoneyFormat((val || 0).toString()) : val)}
                </div>
              ))}
        </div>}

       {msg && <div className={"row__msg "+classRow}>{msg}</div>}
      </div>
    </div>
  );
};
