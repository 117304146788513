import React, {useState} from "react";

/** Importando componentes */
import "./HoverBody.css";
import { Tiktok } from "../../icons/outline/Tiktok";

interface HoverBodyProps {
    description: string;
    href: string;
}

export const HoverBody: React.FC<HoverBodyProps> = ({
    description,
    href
}) => {

  return (
    <div className="hover-body">
        <div>{description}</div>
        <div className="hover-body-content">
            <div className="tinytext-header">Ver video en</div>
            <a className="hover-body-btn"href={href} target="_blank">
                <Tiktok/>
            </a>
        </div>
    </div>
  );
};
