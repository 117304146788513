import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { PaymentMethod } from "../interfaces/PaymentMethodInterface";
import { DB, Functions } from "../firebaseConfig/FirebaseConfig";
import { AddPaymentInterface } from "../interfaces/AddPaymentInterface";
import { httpsCallable } from "firebase/functions";

// method to get payment method by id
export const GetPaymentMethodById = async(id: string): Promise<PaymentMethod> => {
    const response = await getDoc(doc(DB, 'paymentMethod', id));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            const data: PaymentMethod = response.data() as PaymentMethod;
            resolve(data);
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

// method to get payment method by userId
export const GetPaymentMethodsByUserId = async(userId: string): Promise<PaymentMethod[]> => {
    try {
        const q = query(collection(DB, "paymentMethod"),  where("status", "==", true), where("userId", "==", userId))
        const response = await getDocs(q);
        let data:PaymentMethod[] = [];
        if(!response.empty) {
            data = response.docs.map(doc => ({id: doc.id, ...doc.data()} as PaymentMethod))
        }
        return data;
    } catch (error) {
        console.log("🚀 ~ GetPaymentMethodByUserId ~ error:", error);
        throw new Error("Error al obtener los metodos de pago");
    }
}

// method to add a payment method
export const AddPaymentMethod = async (addPaymentData: AddPaymentInterface) => {
    // console.log("🚀 ~ addPaymentMethod ~ data:", addPaymentData);
    const addPaymentMethod = httpsCallable(Functions, 'addPaymentMethod');
    return addPaymentMethod(addPaymentData)
        .then((response: any) => {
            // console.log("PaymentMethod ~ response", response.data);
            if(response.data.object === 'error'){ 
                throw response.data;
            } else {
                return response.data;
            }
        })
        .catch((error) => {
            // console.log("🚀 ~ addPaymentMethod ~ error:", error)
            throw error;
        });
}