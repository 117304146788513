import { DB } from "../firebaseConfig/FirebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { ReviewUnknownInterface } from "../interfaces/ReviewUknow";

// const GetAllReviewUserId = async (id: string) => {
//     const ru = query(collection(DB, 'reviewUser'), where("userId", "==", id));
//     const response = await getDocs(ru);
//     // console.log("🚀 ~ file: Review.service.ts ~ line 7 ~ GetAllReviewByServiceId ~ response", response)
//     let data:any[] = [];
//     if(!response.empty) {
//         data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
//     } 
//     return data;
// }

const CreateReviewUknow = async (reviewUknow: ReviewUnknownInterface) => {
    try {
        const response = await addDoc(collection(DB, "reviewUnknown"), reviewUknow);  
        // console.log("🚀 ~ file: ReviewUknow.service.ts:20 ~ CreateReviewUknow ~ response:", response)
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
}

// const GetAllReviewOrderId = async (orderId: string) => {
//     const ru = query(collection(DB, 'reviewUser'), where("orderId", "==", orderId));
//     const response = await getDocs(ru);
//     // console.log("🚀 ~ file: Review.service.ts ~ line 7 ~ GetAllReviewByServiceId ~ response", response)
//     let data:any[] = [];
//     if(!response.empty) {
//         data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
//     } 
//     return data;
// }


export {
    // GetAllReviewUserId,
    CreateReviewUknow,
    // GetAllReviewOrderId
}