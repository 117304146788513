import React, { useEffect, useState } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
// slice
import { RegisterWithEmail, RegisterWithFacebook } from "../../Slices/Auth";
import { GetUserByUID } from "../../Slices/User";
import { clearError } from "../../Slices/Error";

// store
import type { AppDispatch } from "../../Store/Store";

// importando componentes
import { LogIn } from "../../components/LogIn/LogIn";
import { SignUp } from "../../components/SignUp/SignUp";
import { SignUpFullData } from "../../components/SignUp/fullData/SignUpFullData";
import { SignUpProvider } from "../../components/SignUp/Provider/SignUpProvider";
import { ForgotPassword } from "../../components/ForgotPassword/ForgotPassword";
import { ModalTyC } from '../../components/Modal/ModalTyC/ModalTyC';

// importando utilidades
import { ValidateField } from "../../utils/ValidateField";

// importando interfaces
import { ErrorInterface } from "../../interfaces/ErrorInterface";
import { User } from "../../interfaces/User";
import { RegisterFullData } from "../../interfaces/RegisterFullData";

import { toast } from 'react-toastify';

// importando servicios 
import authService from "../../Services/Auth.service";

// importando estilod
import './Login.css';
import { CreateUser, GetUserByEmail } from "../../Services/User.service";
import { SERVICE } from "../../Consts/BaseUrl";

export const Login: React.FC<{}> = () => {

    const navigation = useNavigate();
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch<AppDispatch>();

    const { message, code } = useSelector((state:any) => state.error);
    const {path} = useSelector((state:any) => state.historyLocation);

    // useState Loading
    const [ loading, setLoading ] = useState(false);

    // useState Variables
    const [loginModal, setLoginModal] = useState(true); // controla el modal de inicio de sesion
    const [registerModal, setRegisterModal] = useState(false); // controla el modal de registro
    const [providerRegisterModal, setProviderRegisterModal] = useState(false); // controla el modal de registro con proveedor
    const [detailRegisterModal, setDetailRegisterModal] = useState(false); // controla el modal de detalle de registro
    const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false); // controla el modal de recuperar contraseña
    const [showModalTyC, setShowModalTyC] = useState<boolean>(false);
    const [singUpType, setSignUpType] = useState<"google" | "email">('email'); //  tipo de registro email o google

    // login variables
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false); 

    // regiter variables
    const [fullRegister, setFullRegister] = useState<User>({
        name: '',
        lastName: '',
        userName: '',
        country: {
            alpha2Code: 'PE',
            imgFlag: 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fflag_of_Peru.png?alt=media&token=d8bfda2c-4c48-419a-b8b9-00a423a24d53',
            name: 'Perú',
        },
        email: '',
        typeIt: 'RUC',
        phone: undefined,
        itNumber: '',
        status: true,
        photoUrl: 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fuser.png?alt=media&token=dd5bd9cb-ab0f-4799-8b73-31fb2831df7b',
        createdAt: new Date(),
        updatedAt: new Date(),
        authStep: 1,
        culqiCustomerId: '',
        planId: '',
        subscriptionId: '',
        documentType: 'DNI',
    });
    const [passwordRegister, setPasswordRegister] = useState<string>('');
    
    // error
    const [msgError, setMsgError] = useState<string>('');
    const [errorEmailRegister, setErrorEmailRegister] = useState<ErrorInterface>({
        status: false,
        message: ''
    });

    const [errorFullRegister, setErrorFullRegister] = useState<RegisterFullData>({
        name: {
            status: false,
            message: ''
        },
        lastName: {
            status: false,
            message: ''
        },
        userName: {
            status: false,
            message: ''
        },
        password: {
            status: false,
            message: ''
        }, 
        country: {
            status: false,
            message: ''
        },
        email: {
            status: false,
            message: ''
        },
        itNumber: {
            status: false,
            message:''
        },
        typeIt: {
            status: false,
            message:''
        },
        businessName: {
            status: false,
            message: ''
        },
        phone: {
            status: false,
            message: ''
        }
    });

    // handle process error firebase
    const processErrorFirebase = (error: string) => {
        switch (error) {
            case 'auth/invalid-email':
                toast.error("Email o contraseña incorrectos");
                break;
        
            default:
                break;
        }
    }

    // metodo que se ejecuta al hacer click en login
    const handleLoginClick = async ()=> {
        if(email.length > 0 && password.length > 0){
            if(!loading) {
                setLoading(true);
                try {
                    const response:any = await authService.LoginWithEmail(email, password, rememberMe);
                    // console.log("🚀 ~ file: Login.tsx ~ line 111 ~ handleLoginClick ~ response", response)
                    if(response && response.uid) {
                        dispatch(GetUserByUID(response.uid))
                        .unwrap()
                        .then((response) => {
                            // console.log(response);
                            navigation(`/${SERVICE}`);
                        })
                        .catch((error) => {
                            console.log(error)
                            setLoading(false);
                        });
                    } else {
                        toast.error("Email o contraseña incorrectos");
                    }
                    setLoading(false);
                } catch (error:any) {
                    toast.error("Email o contraseña incorrectos");
                    processErrorFirebase(error.code);
                    setLoading(false);
                    
                }
            }
        } else {
            setMsgError("Ingrese Usuario y contraseña correctos");
        }
    }

    const handleLogin = () => {
        setLoginModal(true);
        setRegisterModal(false);
    }

    const handleLoginFullData = () => {
        setLoginModal(true);
        setDetailRegisterModal(false);
    }
    // metodo para mostrar el modal de registro y ocultar el modal de inicio de sesion
    const handleClickShowRegisterModal = () => {
        setLoginModal(false);
        setRegisterModal(true);
    }

    // metodo para mostrar el modal de inicio de sesion y ocultar el modal de registro
    const closeRegisterModal = () => {
        setRegisterModal(false);
        setDetailRegisterModal(false);
        clearField();
        setLoginModal(true);
    }

    // metodo para validar email al registrar y muestra modal para completar info
    const handleRegisterEmailClick = () => {
        if(!ValidateField(email, 'email')){
            setErrorEmailRegister({
                status: false,
                message: ''
            });
            setFullRegister((prev: any) => {
                return {
                    ...prev,
                    email: email
                }
            });
            setRegisterModal(false);
            setDetailRegisterModal(true);
        } else {
            setErrorEmailRegister({
                status: !ValidateField(email, 'email'),
                message: 'Ingrese correo valido'
            })
        }
    }

    //Metodo para validar los campos de registro
    const validateField = (textField: 'regiterWithEmail') => {
        // console.log (textField);
        let error: boolean = true;
        
        switch (textField) {
            case 'regiterWithEmail' :
                //validando que los campos de regitrar no esten vacios
                error = ValidateField(fullRegister.name, 'required') || ValidateField(fullRegister.lastName, 'required') || 
                ValidateField(fullRegister.userName, 'required') || 
                ValidateField(fullRegister.itNumber, fullRegister.typeIt==='RUC'?'RUC': (fullRegister.typeIt==='DNI' ? 'DNI' : 'required')) || ValidateField(fullRegister.phone, 'required');

                if(fullRegister.typeIt === 'RUC') {
                   error = error || ValidateField(fullRegister.businessName, 'required');
                }

                if(singUpType==='email') {
                    error = error || !ValidateField(password, 'password') ;
                }
               
               
                setErrorFullRegister((prev: any) => (
                    {
                        ...prev,
                        name: {
                            status: ValidateField(fullRegister.name, 'required'),
                            message: 'Ingrese su nombre'
                        },
                        lastName: {
                            status: ValidateField(fullRegister.lastName, 'required'),
                            message: 'Ingrese su apellido'
                        },
                        userName: {
                            status: ValidateField(fullRegister.userName, 'required'),
                            message: 'Ingrese un nombre de usuario'
                        },
                        password: {
                            status: singUpType === 'email' ? false : !ValidateField(password, 'password'),
                            message: 'Ingrese una contraseña valida'
                        },
                        country : {
                            status: ValidateField(fullRegister.country.name, 'notUndefined'),
                            message: 'Seleccione su país'
                        },
                        itNumber: {
                            status: ValidateField(fullRegister.itNumber, fullRegister.typeIt==='RUC'?'RUC': (fullRegister.typeIt==='DNI' ? 'DNI' : 'required')),
                            message: 'Ingrese su número de '+fullRegister.typeIt,
                        },
                        businessName: {
                            status: fullRegister.typeIt === 'RUC' ? ValidateField(fullRegister.businessName, 'required') : false,
                            message: 'Ingrese la razón social'
                        },
                        phone: {
                            status: ValidateField(fullRegister.phone, 'number'),
                            message: 'Ingrese su número de Teléfono'
                        }
                    }
                ))
                break;
        }
        return error;
    }

    // limpia los campos de los modals
    const clearField = () => {
        setEmail('');
        setPassword('');
        setFullRegister({
            name: '',
            lastName: '',
            userName: '',
            country: {
                alpha2Code: '',
                imgFlag: '',
                name: '',
            },
            email: '',
            photoUrl: '',
            itNumber: '',
            phone: undefined,
            typeIt: '',
            culqiCustomerId: '',
            authStep: 1,
            planId: '',
            subscriptionId: '',
            documentType: "DNI"
        });
        setMsgError('');
        setErrorEmailRegister({
            status: false,
            message: ''
        })
    }

    // registrar usuario con email
    const handleRegisterWithEmail = async() => {
        if(!loading) {
            setLoading(true);
            // const toastMessage = toast.loading('Registrando usuario...');
            dispatch(RegisterWithEmail({user: {...fullRegister, authStep: 3}, password: password}))
            .unwrap()
            .then(async(response) => {
                // console.log("handleRegisterUser", response);
                if(response) {
                    // SetLocalStorage('user', response);
                    toast.success('¡Se creó la cuenta exitosamente!');
                    setTimeout(() => { 
                        navigation('/', {replace: true});
                    }, 5000)
                }
            })
            .catch((error: any) => {
                console.log(error)
                toast.error('Error al registrar usuario');
                // toast.update(toastMessage, 
                //     {
                //         render: 'Error al registrar usuario', 
                //         type: 'error', 
                //         isLoading: false,
                //         autoClose: 5000,
                //         delay: 1000,
                //         closeButton: true,
                //         pauseOnHover: false,
                //         pauseOnFocusLoss: false
                //     }
                // );
                setLoading(false);
            })
         
        }
    }

    // registrar usuario con google
    const handleRegisterWithGoogle = async () => {
        if(!loading) {
            setSignUpType("google");
            setLoading(true);
            // const toastGogle= toast.loading('Solicitando las credenciales a Google...');
            try {
                const response:any = await authService.RegisterWithGoogle();
                const isCreated = await GetUserByEmail(response.email);
                if(!isCreated) {
                    if(response) {
                        toast.success('Credenciales recibidas');
                        // toast.update(toastGogle, 
                        //     {
                        //         render: 'Credenciales recibidas', 
                        //         type: 'success', 
                        //         isLoading: false,
                        //         autoClose: 2000,
                        //         closeButton: true,
                        //         pauseOnHover: false,
                        //         pauseOnFocusLoss: false
                        //     }
                        // );
                        let splitDisplayName = response.displayName.split(' ');
                        setFullRegister({
                            name: splitDisplayName[0],
                            lastName: splitDisplayName[1],
                            userName: '',
                            documentType: 'DNI',
                            country: {
                                alpha2Code: '',
                                imgFlag: '',
                                name: '',
                            },
                            email: response.email,
                            photoUrl: response.photoURL,
                            itNumber: '',
                            phone: undefined,
                            typeIt: '',
                            authProvider: 'google',
                            providerToken: response.accessToken,
                            UID: response.uid,
                            authStep: 1,
                            culqiCustomerId: '',
                            planId: '',
                            subscriptionId: '',
                        });

                        setDetailRegisterModal(true);
                    }
                } else {
                    toast.error('¡Ya existe un usuario con esta cuenta!, estamos iniciando sesión con ella 🚢');
                    // toast.update(toastGogle, 
                    //     {
                    //         render: '¡Ya existe un usuario con esta cuenta!, estamos iniciando sesión con ella 🚢', 
                    //         type: 'success', 
                    //         isLoading: false,
                    //         autoClose: 5000,
                    //         closeButton: true,
                    //         pauseOnHover: false,
                    //         pauseOnFocusLoss: false
                    //     }
                    // );
                    setTimeout(() => { 
                        navigation('/', {replace: true});
                    }, 4000);
                }
            } catch (error) {
                console.log("error", error);
                toast.error("Error al registrar usuario con Google");
                // toast.update(toastGogle, 
                //     {
                //         render: "Error al registrar usuario con Google", 
                //         type: 'error', 
                //         isLoading: false,
                //         autoClose: 5000,
                //         delay: 1000,
                //         closeButton: true,
                //         pauseOnHover: false,
                //         pauseOnFocusLoss: false
                //     }
                // );
                setLoading(false);
            }
        }
    }

    // handle login with google
    const handleLoginWithGoogle = async () => {
        if(!loading) {
            setLoading(true);
            // const toastGogle= toast.loading('Iniciando sesión con Google...');
            const response:any = await authService.RegisterWithGoogle();
            const isCreated = await GetUserByEmail(response.email);
            if(response) {
                if(!isCreated) {
                    setSignUpType("google");
                    toast.warning('¡No existe ningún usuario creado con este cuenta!, te estamos redirigiendo para que puedas crear una cuenta 🕐');
                    // toast.update(toastGogle, 
                    //     {
                    //         render: '¡No existe ningún usuario creado con este cuenta!, te estamos redirigiendo para que puedas crear una cuenta 🕐', 
                    //         type: 'warning', 
                    //         isLoading: false,
                    //         autoClose: 7000,
                    //         closeButton: true,
                    //         pauseOnHover: false,
                    //         pauseOnFocusLoss: false
                    //     }
                    // );
                    let splitDisplayName = response.displayName.split(' ');
                    setFullRegister({
                        name: splitDisplayName[0],
                        lastName: splitDisplayName[1],
                        userName: '',
                        documentType: 'DNI',
                        country: {
                            alpha2Code: '',
                            imgFlag: '',
                            name: '',
                        },
                        email: response.email,
                        photoUrl: response.photoURL,
                        itNumber: '',
                        phone: undefined,
                        typeIt: '',
                        authProvider: 'google',
                        providerToken: response.accessToken,
                        UID: response.uid,
                        authStep: 1,
                        culqiCustomerId: '',
                        planId: '',
                        subscriptionId: '',
                    });
    
                    setDetailRegisterModal(true);
                } else {
                    toast.success('Inicio de sesión exitoso');
                    // toast.update(toastGogle, 
                    //     {
                    //         render: 'Inicio de sesión exitoso', 
                    //         type: 'success', 
                    //         isLoading: false,
                    //         autoClose: 2000,
                    //         closeButton: true,
                    //         pauseOnHover: false,
                    //         pauseOnFocusLoss: false
                    //     }
                    // );
                    setTimeout(() => { 
                        navigation('/', {replace: true});
                    }, 2000)
                    setLoading(false);
                }
            }
        }
        
    }

    // registrar usuario con facebook
    const handleRegisterWithFacebook = () => {
        if(!loading) {
            setLoading(true);
            // const toastFacebook= toast.loading('Registrando usuario con Facebook...');

            dispatch(RegisterWithFacebook())
            .unwrap()
            .then((response)=> {
                if(response) {
                    toast.success('¡Verifica tu cuenta! se envio un email de verificación a tu correo electrónico');
                    // toast.update(toastFacebook, 
                    //     {
                    //         render: '¡Verifica tu cuenta! se envio un email de verificación a tu correo electrónico', 
                    //         type: 'success', 
                    //         isLoading: false,
                    //         autoClose: 4000,
                    //         closeButton: true,
                    //         pauseOnHover: false,
                    //         pauseOnFocusLoss: false
                    //     }
                    // );
                    setTimeout(() => { 
                        navigation('/', {replace: true});
                    }, 5000)
                }
            })
            .catch((error: any) => {
                toast.error("Error al registrar usuario con Facebook");
                // toast.update(toastFacebook, 
                //     {
                //         render: "Error al registrar usuario con Facebook", 
                //         type: 'error', 
                //         isLoading: false,
                //         autoClose: 5000,
                //         delay: 1000,
                //         closeButton: true,
                //         pauseOnHover: false,
                //         pauseOnFocusLoss: false
                //     }
                // );
                setLoading(false);
            })
        }
    }

    const registerUserInfo = async () => {  
        setLoading(true);
        try {
            // const toastMessage = toast.loading('Registrando usuario...');
            const response = await CreateUser({...fullRegister, userType: ['user'], createdAt: new Date(), updatedAt: new Date()});
            const responseUpdate = await authService.UpdateProfileUser({...fullRegister, updatedAt: new Date()});
            toast.success('¡Se creó la cuenta exitosamente!');
            // toast.update(toastMessage, 
            //     {
            //         render: '¡Se creó la cuenta exitosamente!', 
            //         type: 'success', 
            //         isLoading: false,
            //         autoClose: 4000,
            //         closeButton: true,
            //         pauseOnHover: false,
            //         pauseOnFocusLoss: false
            //     }
            // );
            setTimeout(() => { 
                navigation('/', {replace: true});
            }, 5000)
            } catch (error) {
                console.log("google register", error);
                toast.error("Error al registrar usuario con Google");
                setLoading(false);
            }
    }

    // metodo registrar
    const handleRegister = async () => {
        let error = validateField('regiterWithEmail');
        if(!error) {
            switch (singUpType) {
                case 'email':
                    await handleRegisterWithEmail();
                    break;
                case 'google':
                    await registerUserInfo();
                    break;
                default:
                    break;
            }
        }
    }

    // metodos del popup SignUpProvider
    // userName change
    const handleUserNameChange = (value: string) => {
        setFullRegister((prev: any) => {
            return {
                ...prev,
                userName: value
            }
        })
    }

    // country change
    const handleCountryChange = (value: string) => {
        setFullRegister((prev: any) => {
            return {
                ...prev,
                country: value
            }
        });
    }

    const handleClickRegisterWithProvider = () => {
        // console.log(fullRegister);      
    }

    const handleOpenModalForgotPassword = () => {
        setShowForgotPassword(true);
        setLoginModal(false)
    }

    const handleCloseModalForgotPassword = () => {
        setShowForgotPassword(false);
        setLoginModal(true)
    }

    // metodo para enviar email de recuperacion de contraseña
    const handleForgitPasswordClick = async() => {
        if(ValidateField(email, 'email')) {
            const response = await authService.PasswordResetByEmail(email);
            if(response) {
                toast.success('Se ha enviado un email de recuperación de contraseña, así mismo revise en spam o correos no deseados');
            } else {
                toast.error('Error al enviar email de recuperación de contraseña');
            }
        } else {
            toast.error('Email no valido');
        }
    }

    // useEffect para limpiar el error
    useEffect(() => {
        dispatch(clearError)
    }, [dispatch]);

    useEffect(() => {
        setLoading(true);
        if(searchParams.get('redirect')) {
            setLoginModal(false);
            setRegisterModal(true);
        }
        setLoading(false);
    }, [searchParams]); 

    
    return (
        <div className="loginPage-container">
            <h1 style={{display: 'none'}}>¡Empieza hoy! Nuestros operadores logísticos te están esperando.</h1>
            <h2 style={{display: 'none'}}>¿Buscas agencia de aduana? o ¿Necesitas cotizar transporte marítimo y aéreo? Hazlo aquí en Arkabia.com</h2>
            {/* <ToastContainer hideProgressBar limit={3} autoClose={5000} pauseOnFocusLoss={false} pauseOnHover={false} /> */}
            <LogIn 
                show={loginModal} 
                setShow={()=>{}} 
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                onClckLogin={handleLoginClick}
                msg={msgError}
                onClickRegiter={handleClickShowRegisterModal}
                remember={rememberMe}
                setRememberMe={setRememberMe}
                loading={loading}
                handleForgotPassword={handleOpenModalForgotPassword}
                btnGoogle={handleLoginWithGoogle}
            />
            <SignUp 
                show={registerModal}
                setShow={closeRegisterModal}
                email={email}
                setEmail={setEmail}
                onClickRegister={handleRegisterEmailClick}
                errorEmail={errorEmailRegister}
                setErrorEmailRegister={setErrorEmailRegister}
                setErrorEmail={setErrorEmailRegister}
                onClickGoogle={handleRegisterWithGoogle}
                onClickFacebook={handleRegisterWithFacebook}
                handleLogin={handleLogin}
                terms={()=>setShowModalTyC(true)}
            />
            <SignUpProvider
                show={providerRegisterModal}
                setShow={setProviderRegisterModal}
                name={fullRegister.userName}
                setName={(e:any)=>handleUserNameChange(e)}
                country={fullRegister.country}
                setCountry={(e:any)=>handleCountryChange(e)}
                onClick={handleClickRegisterWithProvider}
            />
            <SignUpFullData 
                show={detailRegisterModal}
                setShow={closeRegisterModal}
                signUp={fullRegister}
                setSignUp={setFullRegister}
                handleSignUp={handleRegister}
                errorFullRegister={errorFullRegister}
                setErrorFullRegister={setErrorFullRegister}
                handleLogin={handleLoginFullData}
                terms={()=>setShowModalTyC(true)}
                isProvider={singUpType === 'google'}
                password={password}
            />
            <ForgotPassword
                show={showForgotPassword}
                setShow={handleCloseModalForgotPassword}
                email={email}
                setEmail={setEmail}
                handleForgotPassword={handleForgitPasswordClick}
            />
            <ModalTyC setShowModal={setShowModalTyC} showModal={showModalTyC}/>
        </div>
    )
}