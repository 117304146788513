import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, query, where, getDocs, orderBy, getDoc, doc, setDoc, updateDoc, onSnapshot } from "firebase/firestore"; 
import { OrderInterface } from "../interfaces/OrderInterface";

const GetAllOrderActive =async () => {
    const response = await getDocs(collection(DB, "order")); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

const GetOrderActivByCompanyId =async (companyId: string) => {
    try {
        const rqc = query(collection(DB, "order"), where("status", "==", true), where("companyId", "==", companyId), orderBy("updatedAt", "desc"));
        const response = await getDocs(rqc);
        let data: any[] = [];
        if(!response.empty) {
            response.docs.filter((doc) => {
                if(doc.data().status !== "draft") {
                    data.push({id: doc.id, ...doc.data()});
                }
            });
        }
        return data;
    } catch (error) {
        console.log(error)
        return error;
    }
}

const GetOrderByCompanyIdRT = async (companyId: string, cb: (e: any)=>void = ()=> {}) => {
    const rqc = query(collection(DB, "order"), where("status", "==", true), where("companyId", "==", companyId), orderBy("updatedAt", "desc"));

    onSnapshot(rqc, (querySnapshot) => {
        let data:any[] = [];
        querySnapshot.forEach((doc) => {
            data.push({id: doc.id, ...doc.data()});
        });

        cb(data);
    });

}

const GetOrderByChatId = async (chatId: string)  => {
    try {
        const rqc = query(collection(DB, "order"), where("status", "==", true), where("chatId", "==", chatId));
        const response = await getDocs(rqc);
        let data: any[] = [];
        if(!response.empty) {
            response.docs.filter((doc) => {
                if(doc.data().status !== "draft") {
                    data.push({id: doc.id, ...doc.data()});
                }
            });
        }
        return data;
    } catch (error) {
        console.log(error)
        return error;
    }
}

const GetOrderDetail = async (id: string) => {
    const response = await getDoc(doc(DB, 'order', id));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()})
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

const GetOrderByQuotationId =async (quotationId: string) => {
    const oq = query(collection(DB, "order"), where("quotationId", "==", quotationId))
    const response = await getDocs(oq);
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}

const SetOrder = async (order: OrderInterface) => {
    // console.log("🚀 ~ file: RequestQuote.service.ts ~ line 55 ~ SetRequestQuote ~ requestQuote", requestQuote)
    try {
        if(order.id) {
            const orderRef = doc(DB, 'order', order.id);
            const response =  await setDoc(orderRef, {...order});
            // console.log("SetOrder", response)
            return true;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

const UpdateOrder = async(order: any) => {
    try {
        if(order.id) {
            const requestRef = doc(DB, 'order', order.id);
            const response =  await updateDoc(requestRef, {...order});

            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}


export { 
    GetAllOrderActive,
    GetOrderActivByCompanyId,
    GetOrderDetail,
    GetOrderByQuotationId,
    SetOrder,
    UpdateOrder,
    GetOrderByChatId,
    GetOrderByCompanyIdRT
}