import React, { useEffect, useState } from "react";

/** importando componentes */
import { InputOriginDestiny } from "../InputOriginDestiny/InputOriginDestiny";
import { LCLCD } from "../CheckboxDropdown/LCLCD/LCLCD";
import { FCLCD } from "../CheckboxDropdown/FCLCD/FCLCD";
import { LCLCDAir } from "../CheckboxDropdown/LCLCD/LCLCDAir/LCLCDAir";

/** importando interfaces */
import { IShipment } from "../../interfaces/Shipment";

import { QuotationTransportError } from "../../interfaces/QuotationTransportError";
import { ContainerType } from "../../interfaces/ContainerType";
import { LCLAirInterface } from "../../interfaces/LCLAir";
import { TransportInterface } from "../../interfaces/TransportInterface";

/** importando estilos */
import './QuoteTransport.css'
import { RequestQuoteInterface } from "../../interfaces/RequestQuote";
import { GetContainers } from "../../Services/FclContainer.service";
import StoreService from "../../Services/Store.service";
import { toast } from "react-toastify";
import { Loader } from "../Loader/Loader";
import { Packages } from "../../icons/solid/Package";

import { Fcl_containers } from "../../interfaces/Fcl_containers";


interface QuoteTransportProps {
    requestQuoteId: string;
    transport: TransportInterface;
    transportError: QuotationTransportError;
    setRequestQuote: React.Dispatch<React.SetStateAction<RequestQuoteInterface>>;
    serviceId?: string;
    serviceTypeId: string;
    executeScroll: (toPercent?: number) => void;
}

export const QuoteTransport: React.FC<QuoteTransportProps> = ({
    requestQuoteId,
    transport,
    transportError,
    setRequestQuote,
    serviceId = "",
    serviceTypeId="",
    executeScroll
}) => {



    // list containers
    const [containerList, setContainerList] = useState<Fcl_containers[]>([]);
    // to show shipping section
    const [showShipping, setShowShipping] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [clearShipment, setClearshipment] = useState<boolean>(false);

    const [searchActive, setSearchActive] = useState<boolean>(false);



    // TODO: methods to change fields 
    // handle type transport change
    const handleTypeTransportChange = (value: 'air' | 'sea' | 'land' | undefined ) => {
        setClearshipment(transport.type !== value);
        setRequestQuote((prev) => ({
            ...prev,
            transport: {
                ...prev.transport,
                type: value
            },
            customs: {
                ...prev.customs,
                incoterm: value === "air" ? "CIF" : "FOB",
            }
        }));
        
    }

    // handle origin change
    const handleOriginChange = (value: IShipment) => {       
        setRequestQuote((prev) => ({
            ...prev,
            transport: {
                ...prev.transport,
                origin: value
            }
        }));
       
    }

     // handle destiny change
    const handleDestinationChange = (value: IShipment) => {
            setRequestQuote((prev) => ({
                ...prev,
                transport: {
                    ...prev.transport,
                    destination: value
                }
            }));
            window.innerHeight<750 && executeScroll();
            
            if(transport.type ==="air") {
                handleTypeContainerChange({lclAir: true, fcl: false, lcl: false});
                setTimeout(() => {
                    executeScroll(3);
                }, 300);
            }   
    }

    const handleWarehouseChange = (value: string, warehouseType: "warehouseOrigin" | "warehouseDestination") => {
        setRequestQuote((prev) => ({
            ...prev,
            transport: {
                ...prev.transport,
                [warehouseType]: value,
            }
        }));
    }

    // handle initial delivery change
    const handleInitDeliveryChange = (value: string) => {
        setRequestQuote((prev) => ({
            ...prev,
            transport: {
                ...prev.transport,
                initDelivery: value.replace(/&nbsp;/g, "")
            },
            customs: {
                ...prev.customs,
                incoterm: value.length> 0 ? "EXW" : prev.customs.incoterm,
            },
        })); 
        window.innerHeight>750 && executeScroll();
    }

    // handle final delivery change
    const handleFinalDeliveryChange = (value: string) => {
        setRequestQuote((prev) => ({
            ...prev,
            transport: {
                ...prev.transport,
                finalDelivery:  value.replace(/&nbsp;/g, "")
            }
        })); 
        window.innerHeight<750 && executeScroll();
    }

    // handle type constainer change
    const handleTypeContainerChange = (value: ContainerType) => {
        setRequestQuote((prev) => ({
            ...prev,
            transport: {
                ...prev.transport,
                typeContainer: value
            },
        })); 
    //    (value.fcl || value.lcl || value.lclAir) && executeScroll(window.innerHeight<500 ? 1.7 : 3);
    }

    // method to handle lcl input changes
    const handleLclchange = (e: React.ChangeEvent<HTMLInputElement>,
        field: 'typeMeasure' | 'totalWeight' | 'unitWeight' |'totalVolume' | 'unitVolume' | 'cargoDescription' | 'stackable' | 'file' | 'packages') => {
            switch (field) {
                case 'typeMeasure':
                    setRequestQuote((prev:any)=>({...prev, transport: {...prev.transport, lcl: {...prev.transport.lcl, typeMeasure: e}}}));
                    break;
                case 'totalWeight':
                    setRequestQuote((prev:any)=>({...prev, transport: {...prev.transport, lcl: {...prev.transport.lcl, totalWeight: e}}}));
                    break;
                case 'unitWeight':
                    setRequestQuote((prev:any)=>({...prev, transport: {...prev.transport, lcl: {...prev.transport.lcl, unitWeight: e}}}));
                    break;
                case 'totalVolume':
                    setRequestQuote((prev:any)=>({...prev, transport: {...prev.transport, lcl: {...prev.transport.lcl, totalVolume: e}}}));
                    break;
                case 'unitVolume':
                    setRequestQuote((prev:any)=>({...prev, transport: {...prev.transport, lcl: {...prev.transport.lcl, unitVolume: e}}}));
                    break;
                case 'cargoDescription':
                    setRequestQuote((prev:any)=>({...prev, transport: {...prev.transport, lcl: {...prev.transport.lcl, cargoDescription: e}}}));
                    break;
                case 'stackable':
                    setRequestQuote((prev:any)=>({...prev, transport: {...prev.transport, lcl: {...prev.transport.lcl, stackable: e}}}));
                    break;
                case 'file':
                    setRequestQuote((prev:any)=>({...prev, transport: {...prev.transport, lcl: {...prev.transport.lcl, file: e.target.files}}}));
                    break;
                case 'packages':
                    setRequestQuote((prev:any)=>({...prev, transport: {...prev.transport, lcl: {...prev.transport.lcl, packages: e}}}));
                    break;
                default:
                    break;
            }
 
    }

    // handle fcl change
    const handleFclChange = (e: any) => {
        setRequestQuote((prev:any)=>({...prev, transport: {...prev.transport, fcl: e}}));
    }

    // method to handle lclAir change
    const handleLCLAirChange = (lclAir: LCLAirInterface) => {
        setRequestQuote((prev: any) => ({...prev, transport: {...prev.transport, lclAir: lclAir}}));
    }
 
    // method to get container types from firebase
    const getContainerList = async () => {
        const response = await GetContainers();
        setContainerList(response);
    }

    const fieldsAreCompleted = () => {
        if(transport.type && transport.type.length>0 && transport.origin && transport.destination) {
            setShowShipping(true);
        }
    }

    useEffect(()=> {
        if(transport.typeContainer?.fcl && containerList.length === 0) {
            getContainerList();
        }
    }, [transport.typeContainer]);

    useEffect(() => {
        if(transport.origin && transport.origin.type === "city") {
            setRequestQuote((prev) => ({
                ...prev,
                customs: {
                    ...prev.customs,
                    incoterm: "EXW"
                }
            }));
        }
    }, [transport.origin?.type]);

    useEffect(() => {
        if(transport.warehouseOrigin && transport.warehouseOrigin.length> 0 
            && transport.warehouseDestination && transport.warehouseDestination.length> 0) {
            handleTypeTransportChange("air");
            handleTypeContainerChange({lclAir: true, fcl: false, lcl: false});
        }
    }, [transport.warehouseOrigin, transport.warehouseDestination]);

    useEffect(() => {
        fieldsAreCompleted();
    }, []);

    return (
        <> {!loading ? 
            <div className={`quoteTransport-container ${searchActive ? " quoteTransport-scroll_bottom" : ""}`}> 
                <InputOriginDestiny
                    type={transport.type}
                    setType={handleTypeTransportChange}

                    origin={transport.origin}
                    setOrigin={handleOriginChange}
                    destination={transport.destination}
                    setDestination={handleDestinationChange}
                    initDelivery={transport.initDelivery}
                    setInitDelivery={handleInitDeliveryChange}
                    finalDelivery={transport.finalDelivery}
                    setFinalDelivery={handleFinalDeliveryChange}
                    error={{
                        type: transportError.type,
                        origin: transportError.origin,
                        destination: transportError.destination,
                        warehouseOrigin: transportError.warehouseOrigin,
                        warehouseDestination: transportError.warehouseDestination,
                    }}
                    setTypeContainer={handleTypeContainerChange}                  
                    serviceId={serviceId}
                    serviceTypeId={serviceTypeId}
                    clearShipment={clearShipment}
                    setClearShipment={setClearshipment}
                    warehouseOrigin={transport.warehouseOrigin}
                    warehouseDestination={transport.warehouseDestination}
                    handleWarehouseChange={handleWarehouseChange}
                    setSearchActive={setSearchActive}
                />
                {((transport.type === "air" && (serviceTypeId === 'courier' ? 
                    (transport.warehouseOrigin && transport.warehouseOrigin.length>0 && transport.warehouseDestination && transport.warehouseDestination.length>0):
                (transport.origin )&& (transport.destination))) || (transport.type === "sea" && (transport.origin && transport.origin) && (transport.destination && transport.destination) )) && 
                <div className={"quoteTransport-row"}>
                   <div className="paragraph-header quoteTransport-row__ quoteTransport-title">
                        <Packages/>
                        Datos de envío
                    </div>
                    { transport.type === 'sea' && (transport.origin && (transport.origin.city && transport.origin.country)) &&
                    (transport.destination && (transport.destination.city && transport.destination.country)) ?  <> 
                        <LCLCD 
                            isChecked={transport.typeContainer && transport.typeContainer.lcl && !transport.typeContainer.fcl ? true : false}
                            setIsChecked={()=>handleTypeContainerChange({...transport.typeContainer, lcl: !(transport.typeContainer && transport.typeContainer.lcl), fcl: false})}
                            totalWeight={transport.lcl?.totalWeight}
                            handleTotalWeight={(e: any) => handleLclchange(e, 'totalWeight')} 
                            totalWeightUnit={transport.lcl?.unitWeight}
                            setTotalWeightUnit={(e: any) => handleLclchange(e, 'unitWeight')}
                            totalVolume={transport.lcl?.totalVolume}
                            handleTotalVolume={(e: any) => handleLclchange(e, 'totalVolume')}
                            totalVolumeUnit={transport.lcl?.unitVolume}
                            setTotalVolumeUnit={(e: any) => handleLclchange(e, 'unitVolume')}
                            cargoDescription={transport.lcl?.cargoDescription}
                            setCargoDescription={(e: any) => handleLclchange(e, 'cargoDescription')}
                            errorLCL={transportError.lcl}
                            typeMeasure={transport.lcl?.typeMeasure}
                            setTypeMeasure={(e: any) => handleLclchange(e, 'typeMeasure')}
                            packages={transport.lcl?.packages ? transport.lcl?.packages : []}
                            setPackages={(e: any) => handleLclchange(e, 'packages')}
                            scrollToBottom={executeScroll}
                   
                        />
                        <FCLCD 
                            show={transport.typeContainer && transport.typeContainer.fcl && !transport.typeContainer.lcl ? true : false}
                            containerList={containerList}
                            setShow={()=>handleTypeContainerChange({...transport.typeContainer, fcl: !(transport.typeContainer && transport.typeContainer.fcl), lcl: false})}
                            // containerQtyElements={["1", "2", "3"]}
                            // containerSizeElements={['20 / pies / ft', '40 / pies / ft']}
                            // containerTypeElements={}
                            containerArray={transport.fcl ? transport.fcl : []}
                            setContainerArray={(e:any)=>handleFclChange(e)} // revisar
                            onClickContainerDropdown={()=>executeScroll()}
                            fclError={transportError.fcl}
                        />
                    </>: 
                    transport.type  === 'air' && (serviceTypeId === 'courier' ? (transport.warehouseOrigin && transport.warehouseOrigin.length>0 && transport.warehouseDestination && transport.warehouseDestination.length>0) :
                    (transport.origin && (transport.origin.city && transport.origin.country) && (transport.destination && (transport.destination.city && transport.destination.country)))) &&
                    <>
                        <LCLCDAir 
                            typeContainer={transport.typeContainer}
                            lclcdAir={transport.lclAir}
                            handleLclAirChange={handleLCLAirChange}
                            error={transportError.lclAir}
                            isCourier={serviceTypeId === 'courier'}
                            scrollToBottom={executeScroll}
                        />
                    </> }
                {transportError.typeContainer.status && <div className="text-error tinytext-header">{transportError.typeContainer.message}</div>}
            </div>}
        </div>: 
        <Loader />}
    </>
    )
}