import React, { useEffect, useRef, useState } from "react";

/** importando estilos */
import "./ScrollHighlightNabbar.css";

/**declaracion de variables de interface */
interface Header {
  headerID: string;
  headerRef: React.RefObject<HTMLDivElement>;
  headerTitle: string;
}

interface ScrollHighlightNabbarProps {
  whiteMode?: boolean;
  className?: string;
  navHeader: Header[];
  idClassScroll?: string;
}

/**
 * @param {number} currentPosition Current Scroll position
 * @param {Array} sectionPositionArray Array of positions of all sections
 * @param {number} startIndex Start index of array
 * @param {number} endIndex End index of array
 * @return {number} Current Active index
 */

const nearestIndex = (
  currentPosition: number,
  sectionPositionArray: Header[],
  startIndex: number,
  endIndex: number
): number => {
  if (startIndex === endIndex) return startIndex;
  else if (startIndex === endIndex - 1) {
    if (
      Math.abs(
        sectionPositionArray[startIndex].headerRef.current!.offsetTop -
          currentPosition
      ) <
      Math.abs(
        sectionPositionArray[endIndex].headerRef.current!.offsetTop -
          currentPosition
      )
    )
      return startIndex;
    else return endIndex;
  } else {
    var nextNearest = ~~((startIndex + endIndex) / 2);
    var a = Math.abs(
      sectionPositionArray[nextNearest].headerRef.current!.offsetTop -
        currentPosition
    );
    var b = Math.abs(
      sectionPositionArray[nextNearest + 1].headerRef.current!.offsetTop -
        currentPosition
    );
    if (a < b) {
      return nearestIndex(
        currentPosition,
        sectionPositionArray,
        startIndex,
        nextNearest
      );
    } else {
      return nearestIndex(
        currentPosition,
        sectionPositionArray,
        nextNearest,
        endIndex
      );
    }
  }
};

export const ScrollHighlightNabbar: React.FC<ScrollHighlightNabbarProps> = ({
  navHeader,
  whiteMode=false,
  className,
  idClassScroll= ""
}) => {

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleItemClick = (index: number) => {
    // console.log(navHeader[index].headerRef.current?.offsetTop);
    navHeader[index].headerRef.current?.scrollIntoView({ behavior: "smooth" });
  }
  

  useEffect(() => {
  
    const handleScroll = () => {  
      let element = document.getElementById(idClassScroll);
      let scrollPosition = element ? element.scrollTop : 0;

      let index = nearestIndex(
        scrollPosition,
        navHeader,
        0,
        navHeader.length - 1
      );
      setActiveIndex(index);
    };

    window.document.getElementById(idClassScroll)?.addEventListener("scroll", handleScroll);
    return () => {
      window.document.getElementById(idClassScroll)?.removeEventListener("scroll", handleScroll);
    };

    // document.addEventListener("scroll", handleScroll);
    // return () => {
    //   document.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  return (
    <div className="scrollHighlightNabbar-container">
      {navHeader.length > 0 && navHeader.map((header, index) => (
        <span
          key={index+header.headerID}
          role='scrollHighlightNabbar' 
          onClick={()=>handleItemClick(index)} 
          className={`${whiteMode ? 'scrollHighlightNabbar_white' : 'scrollHighlightNabbar'} ${className ? className: 'paragraph-header'} ${activeIndex === index ? "scrollHighlightNabbar-clicked" : ""}`} 
          >
            {header.headerTitle}
        </span>
      ))}
    </div>
  
  );
};
