import { useEffect, useState, RefObject } from 'react';


export const useIntersectionObserver = (
  targetRefs: RefObject<Element>[],
  
): number => {
  const [activeIndex, setActiveIndex] = useState(-1); // -1 indica que no hay elementos activos

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Encuentra el primer elemento que está intersectando y actualiza el estado con su índice
        const index = entries.findIndex(entry => entry.isIntersecting);
        if (index !== -1) {
          // Encuentra el índice real basado en el target del entry
          const activeTargetIndex = targetRefs.findIndex(ref => ref.current === entries[index].target);
          setActiveIndex(activeTargetIndex);
        } else {
          setActiveIndex(-1); // Ningún elemento está activo
        }
      },
  
    );

    const targets = targetRefs.map(ref => ref.current).filter(Boolean);
    targets.forEach(target => observer.observe(target!));

    return () => {
      targets.forEach(target => observer.unobserve(target!));
    };
  }, [targetRefs]);

  return activeIndex;
};