import React, { useState, useRef, useEffect } from "react";

// Import Packages
import { Rating } from "../Rating/Rating";
import { CommentCount } from "../CommentCount/CommentCount";
import { CityCountryDisplay } from "../CityCountryDisplay/CityCountryDisplay";

/** Importando icon */
import { Share } from "../../icons/outline/Share";
import { Like } from "../../icons/outline/Like";
import { Heart_Red } from "../../icons/outline/Heart_Red";

// imports interfaces
import { ServiceInterface } from "../../interfaces/Service";

// import css
import './ServiceMiniature.css';
import { Wsp } from "../../icons/outline/Wps";
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { VerifyBlack } from "../../icons/solid/VerifyBlack";
import { VerifyBlue } from "../../icons/solid/Verify_blue";
import { Verify } from "../Verify/Verify";

interface ServiceMiniatureProps {
    service: ServiceInterface; // service going to render
    choose: boolean; // status if choose order
    edit: boolean; // status if its editable
    quoteSended: boolean; // status if quotation are sended
    quoteSelected?: boolean; // status if quotation is seleected to send
    onClickService: (id:string | undefined, serviceType: string)=> void; // metodo que se ejecuta al hacer click en la orden minitarue
    onClickChoose:  () => void;
    onClickWsp?: () => void;
    showButtonWsp?: boolean;
    verify?: boolean;
    numberVerify: number;
    hiddenVerify?: boolean;
    // onClickScroll: () => void;// method if click y choose order
}


/**  component OrderMiniature 
    in this component the company will be rendered in card showing information about it*/
export const ServiceMiniature: React.FC<ServiceMiniatureProps> = (
    {
        service, 
        choose,
        edit,
        quoteSended,
        onClickService,
        onClickChoose,
        quoteSelected=false,
        onClickWsp = ()=>{},
        showButtonWsp=false,
        verify=false,
        numberVerify,
        hiddenVerify
        
        // onClickScroll
    }) => {

    const [isLike, setIsLike] = useState(false);
    const [showVideo, setShowVide] = useState(false);
    const [showVerify, setShowVerify] = useState(false);

    let Icon = isLike ? Heart_Red : Like;

    const handleClickLike = () => {
        setIsLike(!isLike)
    } 

    const handleVerify = () => {
        setShowVerify(!showVerify)
    }

    // const getDescription = (description: string) : string => {
    //     if(description.length >= 45){
    //         return description.slice(0, 45) + '...';
    //     }else{
    //         return description;
    //     }
    // }

    return (
        <div  role='service' 
              className={`service-minuature-main ${showVerify ? 'service-miniature-none-filter' : ''}`}
        >
            {!hiddenVerify && showVerify &&  
                <Verify 
                    verify={verify} 
                    numberVerify={numberVerify} 
                    setShowVerify={setShowVerify}
                    
                />}
           <div role='service-body' className="service-minuature-body">
                <div className="service-minuature-images"  onMouseOver={()=>setShowVide(true)} onMouseOut={()=>setShowVide(false)}>
                    {
                    // !showVideo ||  !service.video ?
                     <img 
                        className='service-minuature-img' 
                        src={service.images[0]} 
                        alt={service.companyName} 
                        onClick={choose ? (!quoteSended ? onClickChoose:()=>{}) : (quoteSended ?()=>{} : ()=>onClickService(service.id, service.serviceType))}/>
                    // : ''
                    // <div className="service-minuature-main-iframe">
                    //     <iframe 
                    //         width="266" 
                    //         height="150"
                    //         src={"https://www.youtube.com/embed/"+(service.video ?service.video :'')+"?autoplay=0&controls=0&showinfo=0&disablekb=0"}
                    //         title={service.companyName}
                    //         frameBorder="0"
                    //         allowFullScreen
                    //         allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    //     /> 
                    // </div>
                    }
                </div>
                <div className='service-minuature-text service-miniature-padding-text'>
                    <div className="service-miniature-header">
                        <div className='service-minuature-title paragraph-header'  onClick={choose ? (!quoteSended?onClickChoose:()=>{}) : (quoteSended ?()=>{} : ()=>onClickService(service.id, service.serviceType))} >
                            {service.companyName}
                        </div>
                        <div className="service-miniature-header-check">
                            {verify && numberVerify <= 2 ? 
                            <VerifyBlack onClick={()=>handleVerify()}/> : 
                            <VerifyBlue onClick={()=>handleVerify()}/>}
                        </div>
                    </div>
                    <div className='online-company'></div> 
                </div>

                <div className='service-minuature-description service-miniature-padding-text paragraph' onClick={ choose ? (!quoteSended?onClickChoose:()=>{}) : (quoteSended ?()=>{} : ()=>onClickService(service.id, service.serviceType)) }>
                    {service.title} 
                </div>
                <div className={`service-minuature-rating ${!choose ? "service-miniature-buttons--bottom" : ""}`} onClick={choose ? (!quoteSended?onClickChoose:()=>{}) : (quoteSended ?()=>{} : ()=>onClickService(service.id, service.serviceType))}>
                    <Rating rating={Number((service.rating).toFixed(1))} /> 
                    <CommentCount commentsCount={service.numComments} />
                    <CityCountryDisplay
                        city={service.city}
                        country={service.country.alpha2Code}
                        flagImg={service.country.imgFlag}
                    />
                </div>
                {/* {showButtonWsp && <div className="service-minuature-button">
                    <ButtonIcon
                        id={`serviceList-whatsapp`}
                        role={service.companyName}
                        content="Whatsapp"
                        color="green-2"
                        size="extra-small"
                        Icon={<Wsp />}
                        onClick={() => onClickWsp()}
                    />
                </div>} */}
           </div>
            {/* {!edit && !choose && <hr className='service-miniature-line' />} */}
            {/* {!edit && !choose?<div className='service-miniature-share service-miniature-padding-text '>
                {/*<div className='service-miniature-social'>
                    <Share />
                    <Icon onClick={handleClickLike} style={{cursor: 'pointer'}}/>
                </div>
                <div className='service-miniature-price paragraph-header'>
                    Desde USD ${service.basePrice}
                </div>
            </div>: null} */}
            
                {(choose || quoteSended)?<div className={`service-miniature-buttons`}>
                    <div className={'service-minuature-button-single smalltext-header'+(quoteSended?' service-minuature-button-single-green': '')+(quoteSelected?' service-minuature-button-single-blue': '')}
                        onClick={!quoteSended?onClickChoose:()=>{}}>
                        {quoteSended || quoteSelected? (quoteSended ? 'Solicitud enviada': (quoteSelected ? 'Seleccionado':'')): 'Seleccionar'}
                    </div>
                </div>: null}
                {edit?<div className='service-miniature-buttons smalltext-header'>
                    <div className='service-minuature-button-left'
                        onClick={() => {
                            // console.log("click")
                        }}
                    >
                        Despublicar
                    </div>
                    <div className='service-minuature-button-rigth'
                        onClick={() => {
                            // console.log("click")
                        }}
                    >
                        Editar
                    </div>
                </div>: null}
              
        </div>
    )
}