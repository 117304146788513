import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';

import ReactGA from "react-ga4";

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../Store/Store';

import './MainLogin.css';

import { ButtonIcon } from '../../ButtonIcon/ButtonIcon';
import { GoogleIcon } from '../../../icons/solid/googleIcon';
import { ButtonSign } from '../../ButtonSign/ButtonSign';

// import services
import authService from '../../../Services/Auth.service';
import { GetUserByEmail } from '../../../Services/User.service';
import { toast } from 'react-toastify';
import { User } from '../../../interfaces/User';
import { GetUserByUID } from '../../../Slices/User';
import { setAuth } from '../../../Slices/Auth';
import { AuthSteps, AuthSteps_item } from '../../../Consts/AuthSteps';
import { Loader } from '../../Loader/Loader';
import { TextField } from '../../TextField/TextField';
import { LeftArrow } from '../../../icons/outline/LeftArrow';
import { TextFieldPassword } from '../../TextField/TextFieldPassword/TextFieldPassword';
import { ValidateField } from '../../../utils/ValidateField';
import { ErrorInterface } from '../../../interfaces/ErrorInterface';
import { PLAN, STORAGE_ASSETS } from '../../../Consts/BaseUrl';


interface MainLoginProps {
    emailParams?: string;
    setFullRegister: React.Dispatch<React.SetStateAction<User>>;
    setshowFullRegister:  React.Dispatch<React.SetStateAction<boolean>>;
    setAuthStep: React.Dispatch<React.SetStateAction<AuthSteps_item>>;
    redirectToPath: ()=>void;
    processNavigation: (userId: string)=>void;
    handleCloseAuth: ()=>void;
}

const MainLogin = forwardRef((props: MainLoginProps, ref) => {

    // redux
    const navigation = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const dispatch = useDispatch<AppDispatch>();

    // local states
    const [loading, setLoading] = useState<boolean>(false);
    // step 0 main login. 1 email login
    const [step, setStep] = useState<number>(0);
    // email
    const [email, setEmail] = useState<string>('');
    // password
    const [password, setPassword] = useState<string>('');
    // typePassword
    const [typePassword, setTypePassword] = useState<string>('password');
    // state login error
    const [loginError, setLoginError] = useState<{
        email: ErrorInterface,
        password: ErrorInterface
    }>({
        email: {
            message: "",
            status: false
        },
        password: {
            message: "",
            status: false
        }
    })

    // method login with google
    const handleGoogleLogin = async () => {
        if(!loading) {
            setLoading(true);
            // const toastGogle= toast.loading('Iniciando sesión con Google...');
            const response:any = await authService.RegisterWithGoogle();
            const isCreated = await GetUserByEmail(response.email);
            if(response) {
                if(!isCreated) {
                    toast.warning('¡No existe ningún usuario creado con este cuenta!, te estamos redirigiendo para que puedas seleccionar un plan y completar tu registro');
                    // let splitDisplayName = response.displayName.split(' ');
                    // props.setFullRegister((prev)=> ({
                    //     ...prev,
                    //     name: splitDisplayName[0],
                    //     lastName: splitDisplayName[1],
                    //     userName: '',
                    //     password: '',
                    //     country: {
                    //         alpha2Code: '',
                    //         imgFlag: '',
                    //         name: '',
                    //     },
                    //     email: response.email,
                    //     photoUrl: response.photoURL,
                    //     itNumber: '',
                    //     phone: undefined,
                    //     typeIt: '',
                    //     authProvider: 'google',
                    //     providerToken: response.accessToken,
                    //     UID: response.uid
                    // }));                  
                    setLoading(false);
                    navigation("/"+PLAN);
                    props.handleCloseAuth();
                    // props.setshowFullRegister(true);
                    // props.setAuthStep('MAIN_REGISTER_STEP');
                } else {
                    toast.success('Inicio de sesión exitoso');
                    GA4_login('google');
                          
                    setTimeout(() => {    
                        dispatch(GetUserByUID(response.uid?response.uid:''))
                        .unwrap()
                        .then((responseUser) => {
                             dispatch(setAuth({
                                isLoggedIn: true,
                                displayName: responseUser.name+" "+responseUser.lastName,
                                userId: responseUser.id,
                                uid: responseUser.uid,
                                imgProfile: responseUser.photoUrl
                            }));
                            if(responseUser.authStep === 4) {
                                props.redirectToPath();
                            } else {
                                props.setFullRegister(responseUser);
                                props.setAuthStep(AuthSteps[responseUser.authStep-1].step);
                            }
                        }).catch((error) => {
                            console.log("🚀 ~ file: MainLogin.tsx:122 ~ setTimeout ~ error:", error);
                        }) ;                 
                    }, 1200)
                    setLoading(false);
                }
            }
        }
    }

    // method to show email section
    const handleEmailLogin = () => {
        setStep(1);
    }

    // metthod to show Registration authStep
    const handleRegister = () => {
        navigation("/"+PLAN);
        props.handleCloseAuth();
    }

    // method to return internal step
    const handleReturnclick = () => {
        if(step === 0) return false;

        setStep((prev)=> step>0 ? prev-1 : prev);
        return true;
    }

    // method to show restore password authStep
    const handleRecoverAccount = () => {
        props.setAuthStep("RESTORE_STEP");
    }

    // handle to change email
    const handleEmailChange = (e:  React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        props.setFullRegister((prev)=> ({
            ...prev,
            email: value,
        }));
        setEmail(value.toLocaleLowerCase());
    }

    // handle to change password
    const handlePasswordChange = (e:  React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }
    
    // method to validate login fields
    const validateFields = ():boolean => {
        let error = false;
        error = error || ValidateField(email, "email") || ValidateField(password, "required");
        setLoginError({
            email: {
                status: ValidateField(email, "email") ,
                message: "Ingrese un correo electrónico válido"
            },
            password: {
                status: ValidateField(password, "required") ,
                message: "Ingrese una contraseña válida"
            }
        })

        return error;
    }

    // handle process error firebase
    const processErrorFirebase = (error: string) => {
        switch (error) {
            case 'auth/invalid-email':
                toast.error("Email o contraseña incorrectos");
                break;
        
            default:
                break;
        }
    }

    // handle to login with email and password
    const handleLoginClick = async () => {
        if(!validateFields() && !loading) {
            setLoading(true);
            try {
                // toast.info("Iniciando sesión...")
                const response:any = await authService.LoginWithEmail(email, password, true);
                if(response && response.uid) {
                    dispatch(GetUserByUID(response.uid))
                    .unwrap()
                    .then((resp) => {
                        GA4_login('email');
                        dispatch(setAuth({
                            isLoggedIn: true,
                            displayName: resp.name+" "+resp.lastName,
                            userId: resp.id,
                            uid: response.uid,
                            imgProfile: resp.photoUrl
                        }));

                       setTimeout(() => {
                        if(resp.authStep === 4) {
                            props.redirectToPath();
                        } else {
                            props.setFullRegister(resp);
                            props.setAuthStep(AuthSteps[resp.authStep-1].step);
                        }
                       }, 500);
                    })
                    .catch((error) => {
                        console.log("🚀 ~ file: MainLogin.tsx:221 ~ handleLoginClick ~ error:", error)
                        setLoading(false);
                    });
                } else {
                    toast.error("Email o contraseña incorrectos");
                }
                setLoading(false);
            } catch (error:any) {
                toast.error("Email o contraseña incorrectos");
                processErrorFirebase(error.code);
                setLoading(false);
                
            }
            
        }
    }

    // method to send the register event to GA4
    const GA4_login = (method: string) => {
        ReactGA.gtag("event", "login", {
            method: method
        });
    }


    useImperativeHandle(ref, () => ({
        handleReturnclick
    }));
    

    useEffect(() => {
        if(props.emailParams && props.emailParams.length>0) {
            setEmail(props.emailParams);
        }
    }, []);    

    return (
        <div className='mainLogin-container'>
            {!loading ? <>
                {/* {step >0 && <div className='mainLogin-prevButton' onClick={()=>handleReturnclick()}><LeftArrow/></div>} */}
                {step === 0 ? <>
                    <div className='paragraph mainLogin-text'>Elige una de las opciones para iniciar sesión.</div>
                    <div className='mainLogin-items'>
                        <ButtonSign 
                            title='Gmail' 
                            icon={<GoogleIcon style={{width: '26px', height: '26px'}} />}
                            onClick={handleGoogleLogin}
                        />
                        <ButtonSign 
                            title='Correo electrónico'
                            icon={<img src={STORAGE_ASSETS+'/images/png/mensaje.png'} style={{width: '24px', height: '24px'}}/>}
                            onClick={handleEmailLogin}
                        />
                    </div>
                    <div className='paragraph mainLogin-text'>¿No tienes cuenta? <span onClick={()=>handleRegister()} className='mainLogin-link'>Regístrate</span></div>
                </>: 
                <>
                    <div className='mainLogin-items'>
                        <TextField 
                            title='Email'
                            placeholder=' '
                            value={email} 
                            onChange={handleEmailChange}
                            error={loginError.email}
                        />
                        <TextFieldPassword
                            title='Contraseña'
                            placeholder=" "
                            type='password'
                            value={password}
                            onChange={handlePasswordChange}
                            error={loginError.password}
                        />
                    </div>
                    <div className='smalltext mainLogin-text'>¿Olvidaste tu contraseña? <span onClick={()=>handleRecoverAccount()} className='mainLogin-link'>Recupérala aqui</span></div>
                </>
                }
                {step>0 && <div className='mainLogin-confirm paragraph-header' onClick={()=>handleLoginClick()}>Ingresar</div>}
            </> : <Loader />}
        </div>
    )
});

export default MainLogin;