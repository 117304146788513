import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CheckyellowblackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Checkyellowblack: React.FC<CheckyellowblackProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/checkYellowBlack.svg"} alt="" />
    </div>
  );
};