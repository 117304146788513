import React, { useEffect } from 'react';

import {createRoot} from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import ReactGA from "react-ga4";

/** importando estilos */
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

/** importando rutas */
import { RoutesMain } from './Routes/RoutesMain';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';

import store from './Store/Store';

ReactGA.initialize("G-B1LJ29VGK2");

const root = createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  
  <Provider store={store} >
    <BrowserRouter>
      <div className='app'>
        <RoutesMain />
      </div>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
