import React from "react";

import './ButtonTypeTransport.css';

interface ButtonTypeTransportProps {
    icon: React.ReactNode;
    title: string // title of button
    isActive: boolean;
    onClick: () => void;
} 

export const ButtonTypeTransport: React.FC<ButtonTypeTransportProps> = ({
    icon,
    title,
    isActive,
    onClick=()=>{}
}) => {

    return (
        <div onClick={()=> onClick()} className={`buttonTypeTransport-container ${isActive ? "buttonTypeTransport-active":""}`}>
            <div className="buttonTypeTransport-icon">{icon}</div>
            <div className="smalltext-header buttonTypeTransport-text">{title}</div>
        </div>
    )
}