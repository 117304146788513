import React, { useState } from 'react';

import './FiveSeccion.css';
import { STORAGE_ASSETS } from '../../../Consts/BaseUrl';
import { Animation } from '../../Animation/Animation';
import { Helmet } from 'react-helmet-async';

interface FiveSeccionProps {
}

export const FiveSeccion: React.FC<FiveSeccionProps> = ({

}) => {

    const handleVideo = () => {
        window.open('https://www.youtube.com/watch?v=-pudIpW2ABc', '_blank');
    }

  return (
    <div className="five-section">
        <Helmet>
            <title>Arkabia | Agencia de Aduana</title>
            <meta name="description" 
            content="Arkabia cuenta con más de 18 agencias de aduana y 
            carga registradas para brindarte un servicio eficiente y 
            seguro en la gestión de tus importaciones. Descubre cómo 
            trabajamos para asegurar la calidad en cada operación." />

            <meta name="keywords" content="agencia de aduana, 
            agencias de aduana registradas, Arkabia, agencias de carga, 
            gestión de importaciones, servicios aduaneros, importaciones 
            seguras, agencias de aduanas Perú, servicio aduanero eficiente, 
            servicios logísticos" />
        </Helmet>
        
        <div className="five-section-header">
            <div className='big-header five-section-white five-section-title five-section-w'>
                Tenemos más de  
                <b className='five-section-text-grandient'> 18 agencias </b>
                de aduana y carga registradas. 
            </div>
            <div className='big-header five-section-white five-section-title five-section-m'>
                <div>
                    Tenemos más de  
                    <b className='five-section-text-grandient'> 18 agencias </b>
                    de aduana
                </div>
                <div className='big-header five-section-white five-section-title five-section-m'> y carga registradas </div>
            </div>

            <div className='big-header five-section-white five-section-title five-section-m_1'>
                <div>
                    Tenemos más de <b className='five-section-text-grandient'> 18 agencias </b>
                </div>
                <div className='big-header five-section-white five-section-title five-section-m_1'> 
                    de aduana y carga registradas. 
                </div>
            </div>
            
            <div className='small-subheader five-section-white five-section-subtitle'>
                Mira cómo nos aseguramos de que 
                recibas un excelente servicio.
            </div>
        </div>
        
        <Animation 
            animation={"./animations/Desktop/Animación_Loop_Agencias/Animación_Loop_Agencias.json"}
            nameAnimation={"Animación_Loop_Agencias"}/>

        <div className='small-header-medium five-section-button' onClick={()=>handleVideo()}>
            <img 
                className='five-section-button-img' 
                src={STORAGE_ASSETS+'/images/png/youtube-white.png'}
                alt={"Mira cómo nos aseguramos de que"}/>
            Ver video
        </div>
    </div>
  );
};