import data from '../ubigeos.json';

export const getDepartaments = () => {
   return data.filter((d, idx, self) => self.map(itm => itm.departamento).indexOf(d.departamento) === idx);
}

export const getProvince = (departamento: string) => {
    let provinces = data.filter((d, idx) => d.departamento === departamento);
    return provinces.filter((d, idx, self) => self.map(itm => itm.provincia).indexOf(d.provincia) === idx);
}

export const getDistrict= (provincia: string) => {
    let district = data.filter((d, idx) => d.provincia === provincia);
    // console.log("🚀 ~ file: Places.service.ts ~ line 14 ~ getDistrict ~ district", district)
    return district.filter((d, idx, self) => self.map(itm => itm.distrito).indexOf(d.distrito) === idx);
}