import React, { useEffect, useState } from "react";


// importando componentes
import { Checkbox } from "../../../../Checkbox/Checkbox";
import { Dropdown } from "../../../../Dropdown/Dropdown";
import { InputElement } from "../../../../InputElement/InputElement";
import { TextFieldMoney } from "../../../../TextFieldMoney/TextFieldMoney";

// importando iconos
import { Trash } from "../../../../../icons/outline/Trash";

// importando estilos
import './Bulto.css';
import { PackageLCLAir } from "../../../../../interfaces/LCLAir";
import { CargoDescription } from "../../../../../Consts/CargoDescription";
import { ValidateField } from "../../../../../utils/ValidateField";

interface BultoProps {
    add: boolean; // indica si se agrega un nuevo bulto o no
    num: number;
    bulto: PackageLCLAir;
    isCourier?: boolean;
    handleChange: (id: number, type: "cargoDescription" | 'numBulto' | 'weight' | 'unitWeight' | 'width'| "length" | "tall" | "unit", e: string) => void;
    handleAdd: () => void;
    handleRemove: (id: number) => void;
    handlePackageChange: (value: PackageLCLAir) => void;
}

export const Bulto: React.FC<BultoProps> = ({
    add = true,
    num,
    bulto,
    isCourier = false,
    handleChange,
    handleAdd,
    handleRemove,
    handlePackageChange
}) => {

    // useEffect(() => {
    //     if(!(ValidateField(bulto.cargoDescription, "required") && ValidateField(bulto.numBulto, "number")  && ValidateField(bulto.weight, "number") && 
    //     ValidateField(bulto.unitWeight, "required") && ValidateField(bulto.width, "number") && ValidateField(bulto.length, "number") && 
    //     ValidateField(bulto.tall, "number") && ValidateField(bulto.unit, "required"))){
    //         handlePackageChange(bulto);
    //     }
    // }, [bulto]);

    return (
        <div className="bulto-container">
            <div className="bulto-item">
                <div className="paragraph-header bulto-colorText">Paquete Nro{num}</div>
                    <div className="bulto-item--row-second">
                        <div className="bulto-item--row-column">
                            <Dropdown
                                title={"Tipo de embalaje"}
                                elements={CargoDescription}
                                setValue={(e: any) => handleChange(num-1, 'cargoDescription', e)}
                                value={bulto.cargoDescription.length > 0 ? bulto.cargoDescription : 'Caja'}
                            />
                            <InputElement 
                                name='numBulto' 
                                title="Cantidad" 
                                value={bulto.numBulto} 
                                onChange={(e:any) =>handleChange(num-1, 'numBulto', e)} 
                            />
                        </div>
                        {/* <div className="smalltext-header bulto-colorText">Cantidad y peso</div> */}
                        <div className="bulto-item--row-column">
                            
                            <InputElement
                                value={bulto.weight}
                                name="Peso por unidad"
                                title="Peso por unidad"
                                onChange={(e) => handleChange(num-1, 'weight',  e)}
                            />
                            <Dropdown
                                title="Unidad"
                                elements={["kg", "lb"]}
                                setValue={(e: any) => handleChange(num-1, 'unitWeight', e)}
                                value={bulto.unitWeight}
                            />
                        </div>
                    </div>
                <div className="bulto-item--row-second bulto-item--row-second__">
                    <div className="bulto-item--row-column-second bulto-item--row-column-second-desktop">
                        <InputElement
                            value={bulto.width}
                            name=""
                            title="Ancho"
                            onChange={(e) => handleChange(num-1, 'width',  e)}
                        />
                        <InputElement
                            value={bulto.length}
                            name="Largo"
                            title="Largo"
                            onChange={(e) => handleChange(num-1, 'length',  e)}
                        />
                        <InputElement
                            value={bulto.tall}
                            name="Alto"
                            title="Alto"
                            onChange={(e) => handleChange(num-1, 'tall',  e)}
                        />  
                        <Dropdown
                            title="Medida"
                            elements={["cm", "m"]}
                            setValue={(e: any) => handleChange(num-1, 'unit', e)}
                            value={bulto.unit}
                        /> 
                        {/* {!isCourier && <Dropdown
                            title="¿Es apilable?"
                            elements={["Si", "No"]}
                            setValue={(e: any) => handleChange(num-1, 'stackable', e)}
                            value={bulto.stackable+""}
                        />  }                            */}
                    </div>

                    {/* <div className="bulto-item--row-column-second bulto-item--row-column-second-mobile">
                        <Dropdown
                            title="¿Es apilable?"
                            elements={["Si", "No"]}
                            setValue={(e: any) => handleChange(num-1, 'stackable', e)}
                            value={bulto.stackable+""}
                        />  
                    </div> */}
                    <div className="bulto-item--row-column-second bulto-item--row-column-second-mobile">
                        <InputElement
                            value={bulto.width}
                            name=""
                            title="Ancho"
                            onChange={(e) => handleChange(num-1, 'width',  e)}
                        />
                        <InputElement
                            value={bulto.length}
                            name="Largo"
                            title="Largo"
                            onChange={(e) => handleChange(num-1, 'length',  e)}
                        />
                        <InputElement
                            value={bulto.tall}
                            name="Alto"
                            title="Alto"
                            onChange={(e) => handleChange(num-1, 'tall',  e)}
                        />  
                        <Dropdown
                            title="Medida"
                            elements={["cm", "m"]}
                            setValue={(e: any) => handleChange(num-1, 'unit', e)}
                            value={bulto.unit}
                        /> 
                    </div>
                </div>

                
                <div className="bulto-item--footer">
                    {num>1 ? <Trash onClick={()=>handleRemove(num-1)} />: <div></div>}
                    {add && <div className="smalltext-header bulto-item--footer-text" onClick={()=>handleAdd()}>+ Agregar carga</div>}
                </div>
            </div>
        </div>
    )
}