import React from "react";

import "./Checkbox.css";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  setChecked: (e:any)=>void,
  white?: boolean
};

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  disabled,
  setChecked,
  white=false,
  ...props
}) => {
  let disabledClass = "";
  if (disabled) {
    disabledClass = "checkbox-disabled";
  }

  return (
    <div className={`checkbox-container ${white?'checkbox-container_white':''} ${disabledClass}`} role="checkbox-content">
      <input type="checkbox" role='check-box' disabled={disabled} checked={checked} {...props} onChange={(e)=>{setChecked(e.target.checked)}} />
      <label></label>
      {checked && disabled ? (
        <img
          className="checkbox-check"
          src={STORAGE_ASSETS+"/images/svg/checkbox-checked.svg"}
          alt="checked"
        />
      ) : (
        <img
          className="checkbox-check"
          src={STORAGE_ASSETS+"/images/svg/checkbox-check.svg"}
          alt="check"
        />
      )}
    </div>
  );
};
