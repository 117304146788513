import React, { useCallback, useEffect, useRef, useState } from "react";

/** importando componenetes */
import { HeaderCalendar } from "./Header/HeaderCalendar";
import { HoursGridCalendar } from "./HoursGrid/HoursGrid";
import { EventGridalendar } from "./EventGrid/EventGrid";

import { SelectWeek } from "../../../utils/SelectWeek";
import { TimeLine } from "./TimeLine/TimeLine";

import { EventCalendar } from "./Event/EventCalendar";

import './CalendarDetail.css';
import { BodyCalendar } from "./Body/Body";
import { HeaderResponsive } from "./HeaderResponsive/HeaderResponsive";
import { TranslateMonth } from "../../../utils/translateMonth";
import { format } from "date-fns";
import { STORAGE_ASSETS } from "../../../Consts/BaseUrl";

interface CalendarDetailProps {
    week: Date[]; // array
    events: any[];
}

export const CalendarDetail: React.FC<CalendarDetailProps> = ({
    week,
    events
}) => { 
    const ref = useRef<HTMLDivElement>(null);
   
    const [selectedDay, setSelectedDay] = useState<Date>(new Date());
    const [today, setToday] = useState<Date>(new Date());

    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
   
    // var Date_ = new Date();
    // var Month_ = months[Date_.getMonth()]

    // const week = SelectWeek();

    useEffect(()=>{
        window.innerWidth >= 620 && window.scrollTo({ top: ((696/642)*((new Date()).getHours()*60)*2.107), left: 0, behavior: 'smooth' });
    }, [])
   
    return (  
        <div className="CalendarDetail-content" >
            <div className="calendarDetail"ref={ref}>
                <HeaderCalendar
                        days={week}
                        selectedDay={selectedDay}
                        setSelectedDate={setSelectedDay}
                    />      
                <div className="calendarDetail-container">
                    <HoursGridCalendar />
                    <EventGridalendar events={events} />
                    <TimeLine hour={today.getHours()} minute={today.getMinutes()} />
                </div>
            </div>

            <div className="calendarDetail-responsive">
                <div className="calendarDetail-responsive-content-title">
                    <div className="paragraph calendarDetail-responsive-title-month">{TranslateMonth(format(week[0], 'MMMM'))}</div>
                    <img className="HeaderResponsive-responsive-icon" src={STORAGE_ASSETS+"/images/png/dropdown-arrow.png"} />
                </div>
                <div className="calendarDetail-responsive-content">
                    <HeaderResponsive 
                        days={week}
                        selectedDay={selectedDay}
                        setSelectedDate={setSelectedDay}
                    />
                    <BodyCalendar days={week}/>
                    <TimeLine dayWeekNumber={today.getDay()} hour={today.getHours()} minute={today.getMinutes()} />
                </div>
            </div>
        </div>
    )
}