import React from "react";
import { ImportTimelineStages } from "../../@types/timelineStagesTypes";
import "./ImportTimelineSupplier.css";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface ImportTimelineSupplierProps {
  stage: ImportTimelineStages;
  setStage?: React.Dispatch<React.SetStateAction<ImportTimelineStages>>;
}

export const ImportTimelineSupplier: React.FC<ImportTimelineSupplierProps> = ({
  stage = 1,
  setStage=()=>{}
}) => {
  return (
    <div className="import-timeline-supplier">
      {/* <div
        className={stage === 1 || stage === 2 || stage === 3 ? "import-timeline-supplier-last-stage":"import-timeline-supplier-last-stage-yellow"}
        style={{
          backgroundColor: `${
            stage === 1 || stage === 2 || stage === 3 ? "var(--yellow)" : "var(--white)"
          }`,
          cursor: 'pointer'
        }}
        onClick={()=>setStage(1)}
      >
        <img src={STORAGE_ASSETS+"/images/svg/target-flag-icon.svg"} alt="" />
      </div>

      <div
        className={stage === 2 || stage === 3 ? "import-timeline-supplier-connection-yellow":"import-timeline-supplier-connection"}
        style={{
          backgroundColor: `${
            stage === 2 || stage === 3 ? "var(--yellow)" : "var(--white)"
          }`,
        }}
      />

      <div
        className={stage === 2 || stage === 3 ?"import-timeline-supplier-stage-yellow":"import-timeline-supplier-stage"}
        style={{
          backgroundColor: `${
            stage === 2 || stage === 3
              ? "var(--yellow)"
              : "var(--white)"
          }`,
          cursor: 'pointer'
        }}
        onClick={()=>setStage(2)}
      >
        <h2 className="smalltext-header">Solicitud</h2>
      </div> */}

      <div
        className={stage === 1 || stage === 2 || stage === 3 ?"import-timeline-supplier-stage-yellow":"import-timeline-supplier-stage"}
        style={{
          backgroundColor: `${
            stage === 1 || stage === 2 || stage === 3
              ? "var(--yellow)"
              : "var(--white)"
          }`,
          cursor: 'pointer'
        }}
        onClick={()=>setStage(1)}
      >
        <h2 className="smalltext-header">Solicitud</h2>
      </div>

      <div
        className={stage === 2 || stage === 3 ? "import-timeline-supplier-connection-yellow":"import-timeline-supplier-connection"}
        style={{
          backgroundColor: `${
            stage === 2 || stage === 3 ? "var(--yellow)" : "var(--white)"
          }`,
        }}
      />

      <div
        className={stage === 2 || stage === 3 ? "import-timeline-supplier-last-stage":"import-timeline-supplier-last-stage-yellow"}
        style={{
          backgroundColor: `${
            stage === 2 || stage === 3 ? "var(--yellow)" : "var(--white)"
          }`,
          cursor: 'pointer'
        }}
        onClick={()=>setStage(2)}
      >
        <img src={STORAGE_ASSETS+"/images/svg/target-flag-icon.svg"} alt="" />
      </div>

    </div>
  );
};
