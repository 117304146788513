import { collection, doc, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { DB, Functions } from "../firebaseConfig/FirebaseConfig";
import { httpsCallable } from "firebase/functions";

export const getNotificationByUserId = async (userId: string,  cb: (e: any[])=>void = ()=> {}) => {
    const q = query(collection(DB, "notification"), where("userId", "==", userId), orderBy('createdAt', "desc"));
    onSnapshot(q, (querySnapshot) => {
        let data:any[] = [];
        querySnapshot.forEach((doc) => {
            data.push({id: doc.id, ...doc.data()});
        });
        cb(data);
    });
}
export const sendNotificationToUser = (data: { title: string, content: string,
    operationName: string, operationId: string, userId: string,
    name: string, image: string, event: "newRQ" | "respQuotation" |
    "newQuotation" | "newOrder" | "editRQUser" | "editRQProvider" |
    "rejectRQ" | "editOperation", urlPath: string }) => {
    const sendNotification = httpsCallable(Functions, "sendNotification");
    return sendNotification(data);
}

export const UpdateNotification = async (notificationId: string, notification: any) => {
    try {
        if(notificationId.length> 0) {
            const requestRef = doc(DB, 'notification', notificationId);
            const response =  await updateDoc(requestRef, {...notification});
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}