import React, {useState, useEffect} from "react";

/** importando componentes */
import { Hover } from "../../Hover/Hover";
import { RatingSlider } from "../../RatingSlider/RatingSlider";
import { Dropdown, PhotoUnit, TextField } from "../..";

import { ArrowUp } from "../../../icons/outline/ArrowUp";
import { ArrowDown } from "../../../icons/solid/ArrowDown";
import { MedalRating } from "../../../icons/outline/MedalRating";

// import css
import './Quote.css';

interface QuoteUnknowProps {
    open?: boolean; // estado de collapse;
    open2?: boolean;
    open3?: boolean;
    edit: boolean;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    isCourier?: boolean;
}
export const QuoteUnknow: React.FC<QuoteUnknowProps> = ({
    open, 
    open2,
    open3,
    edit,
    setEdit,
    isCourier = false
}) => {

    const [isHover, setIsHover] = useState(false);

    const handleMouseOver = () => {
        setIsHover(true);
    };
    
     const handleMouseOut = () => {
        setIsHover(false);
    }; 

    const [collapse, setCollapse] = useState(open);
    const [collapse2, setCollapse2] = useState(open2);
    const [collapse3, setCollapse3] = useState(open3);
    const [isExtended, setIsExtended] = useState(open);

    let Arrow;
    if (isExtended) {
        Arrow = ArrowUp;
    } else {
        Arrow = ArrowDown;
    }

    return (
        <div className="tableQuote ">
            <div className='tableQuote-container box-shadow-card'> 
                <div className="paragraph-header tableQuotation-title">Gastos de importación</div>
                <div className="rowQuote-container">

                    {/** Primer bloque  */ } 
                    <div className="rowQuote-header-container rowQuote-header--pointer" onClick={()=>setCollapse(!collapse)}>
                        <div className="paragraph-header rowQuote-header--title">{`Servicios en origen`}</div>
                        {!collapse ? <ArrowDown className="rowQuote-header--pointer"/> : 
                        <ArrowUp className="rowQuote-header--pointer" />}
                    </div>

                    { collapse && 
                        <div className="rowQuote-body">
                            <div className="rowQuote__ rowQuote_transparent" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Transporte internacional</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>
                            <div className="rowQuote__ rowQuote_white" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Seguro</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>
                            <div className={"rowQuote__ "} >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Gastos de Origen</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>
                            <div className="rowQuote_black" >
                                <div className="paragraph" style={{color: "var(--white)"}}>Subtotal 1</div>
                                <div className="paragraph" style={{color: "var(--white)"}}>-</div>
                            </div>
                        </div>
                    }

                    {/** Segundo bloque  */ } 
                    <div className="rowQuote-header-container rowQuote-header--pointer" onClick={()=>setCollapse2(!collapse2)}>
                        <div className="paragraph-header rowQuote-header--title">{`Servicios en destino `}</div>
                        {!collapse2 ? <ArrowDown className="rowQuote-header--pointer"/> : 
                        <ArrowUp className="rowQuote-header--pointer" />}
                    </div>

                    { collapse2 && 
                        <div className="rowQuote-body">
                            {!isCourier && <div className="rowQuote__ rowQuote_white" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Visto bueno</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>}
                            <div className="rowQuote__ rowQuote_transparent" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Almacén</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>
                            {!isCourier && <div className="rowQuote__ rowQuote_white" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Desconsolidación</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>}
                            {!isCourier && <div className={"rowQuote__"} >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Handling</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>}
                            {!isCourier && <div className="rowQuote__ rowQuote_transparent" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Gastos de almacenaje</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>}
                            <div className="rowQuote__ rowQuote_white" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Gastos operativos</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>
                            {!isCourier && <div className="rowQuote__ rowQuote_transparent" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Gastos administrativos</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>}

                            {isCourier && <div className="rowQuote__ rowQuote_transparent" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Desadunaje</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>}

                            <div className="rowQuote__ rowQuote_transparent" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Transporte local</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>
                            {!isCourier && <div className="rowQuote__ rowQuote_transparent" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Comisión por agenciamiento de aduana</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>}
                            {!isCourier && <div className="rowQuote__ rowQuote_transparent" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Sobrecostos</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>}
                            {!isCourier && <div className="rowQuote__ rowQuote_white" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Devolución del contenedor</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>}
                            <div className="rowQuote__ rowQuote_transparent" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Otros gastos</div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>
                            <div className="rowQuote__ rowQuote_white" >
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>IGV (18%) </div>
                                <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                            </div>
                        
                            
                            <div className="rowQuote_black" >
                                <div className="paragraph" style={{color: "var(--white)"}}>Subtotal 2</div>
                                <div className="paragraph" style={{color: "var(--white)"}}>-</div>
                            </div>
                        </div>
                    }

                    {/** Tercer bloque  */ } 
                    <div className="rowQuote-header-container rowQuote-header--pointer" onClick={()=>setCollapse3(!collapse3)}>
                        <div className="paragraph-header rowQuote-header--title">Impuesto</div>
                        {!collapse3 ? <ArrowDown className="rowQuote-header--pointer"/> : 
                        <ArrowUp className="rowQuote-header--pointer" />}
                    </div>

                    <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Ad Valorem</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                    </div>
                    <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>IGV (16%)</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                    </div>
                    <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>IPM (2%)</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                    </div>
                    <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Percepción</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                    </div>
                    {!isCourier && <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Impuesto Selectivo al Consumo</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                    </div>}
                    {!isCourier && <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Derecho Específicos</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                    </div>}
                    {!isCourier && <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Derecho Antidumping</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                    </div>}
                    {!isCourier && <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Sobretasa</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>-</div>
                    </div>}

                    <div className="paragraph-header rowQuoteFooter__">
                        <div className="paragraph-header" style={{color: "var(--gray-1)"}}> Total USD </div>
                        <div className="paragraph-header" style={{color: "var(--gray-1)"}}>$ - </div>
                    </div>
                </div>
            </div>

            {/** Ranting del aduanero */}
            {/* {edit && <div className='tableQuote-container__'>
                <div className="tableQuote-rating__">
                    <div className="tableQuote-rating-content">
                        <div className="paragraph-header tableQuote-title__">Puntaje de servicio</div>
                        <Hover 
                            content="Este es el puntaje que le han dado los usuarios a la agencia de aduana"
                            show={isHover}
                            setShow={setIsHover}>
                            <MedalRating  
                                onMouseOver={handleMouseOver} 
                                onMouseOut={handleMouseOut}
                            />
                        </Hover>
                    </div>
                </div>
                <div className="tableQuote-rating">
                    <RatingSlider rating={0}/>
                    <span className="paragraph tableQuote-title__">{Number((0).toFixed(1))}</span>
                    <div className="tableQuote-rating-icon">
                        <MedalRating/>
                    </div>
                </div>
            </div>} */}

            <div className='tableQuote-container box-shadow-card'> 
                <div className="paragraph-header tableQuote-title">Detalles</div>
                <div className="rowQuoteDetail-container">
                    <div className="rowQuoteDetail-body">
                        <div className="rowQuoteDetail__ rowQuoteDetail_transparent">
                            <div className="paragraph rowQuoteDetail_text" style={{color: "var(--gray-1)"}}>Tiempo de tránsito</div>
                            <div className="paragraph rowQuoteDetail_text__" style={{color: "var(--gray-1)"}}>-</div>
                        </div>
                        {!isCourier && <div className="rowQuoteDetail__ rowQuoteDetail_white">
                            <div className="paragraph rowQuoteDetail_text" style={{color: "var(--gray-1)"}}>Días libres de almacenamiento</div>
                            <div className="paragraph rowQuoteDetail_text__" style={{color: "var(--gray-1)"}}>-</div>
                        </div>}
                        {!isCourier && <div className="rowQuoteDetail__ rowQuoteDetail_transparent">
                            <div className="paragraph rowQuoteDetail_text" style={{color: "var(--gray-1)"}}>Sobre estadía</div>
                            <div className="paragraph rowQuoteDetail_text__" style={{color: "var(--gray-1)"}}>-</div>
                        </div>}
                        <div className={"rowQuoteDetail__"}>
                            <div className="paragraph rowQuoteDetail_text" style={{color: "var(--gray-1)"}}>Vencimiento de cotización</div>
                            <div className="paragraph rowQuoteDetail_text__" style={{color: "var(--gray-1)"}}>-</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='tableQuote-container'>  */}
                {/* <div className="paragraph-header tableQuote-title">Restricciones</div> */}
                {/* <div className="tableQuote-container-restriction">
                <div role='quotationRole' className="iteminfo-quotation"> */}
                    {/* <div className="iteminfo-quotation-header" onClick={() => setIsExtended(!isExtended)}>
                        <p className="smalltext-header">Producto 1</p>
                        <Arrow />
                    </div> */}
                    {/* {isExtended && ( */}
                        {/* <> */}
                        {/* <div className="iteminfo-quotation-row iteminfo-quotation-row-one">
                            <TextField
                                title="Nombre comercial"
                                value={'-'}
                                onChange={(e) => {}}
                                placeholder=" "
                                disable
                            />
                            <TextField
                                title="Marca"
                                value={'-'}
                                onChange={(e) => {}}
                                placeholder=" "
                                disable
                            />
                            <TextField
                                title="Modelo"
                                value={'-'}
                                onChange={(e) => {}}
                                placeholder=" "
                                disable
                            />
                            <Dropdown
                                title="Estado"
                                elements={[]}
                                value={'-'}
                                setValue={(e) => {}}
                                disabled
                            /> 
                        </div> */}
                        
                        {/** Fila de dos */}
                        {/* <div className="iteminfo-quotation-row iteminfo-quotation-row-two">
                            <TextField
                                title="Nombre comercial"
                                value={'-'}
                                onChange={(e) => {}}
                                placeholder=" "
                                disable
                            />
                            <TextField
                                title="Marca"
                                value={'-'}
                                onChange={(e) => {}}
                                placeholder=" "
                                disable
                            />
                        </div>

                        <div className="iteminfo-quotation-row iteminfo-quotation-row-two">
                            <TextField
                                title="Modelo"
                                value={'-'}
                                onChange={(e) => {}}
                                placeholder=" "
                                disable
                            />
                            <Dropdown
                                title="Estado"
                                elements={[]}
                                value={'-'}
                                setValue={(e) => {}}
                                disabled
                            /> 
                        </div> */}

                        {/** Fin de fila de dos */}

                        {/* <div className="iteminfo-quotation-row">
                            <TextField
                                title="Descripciones mínimas"
                                value={'-'}
                                onChange={(e) => {}}
                                placeholder=" "
                                disable
                            />
                        </div>
                        <div className="iteminfo-quotation-row">
                            <TextField
                                title="Uso o función principal"
                                value={'-'}
                                onChange={(e) => {}}
                                placeholder=" "
                                disable
                            />
                        </div>

                        <div className="iteminfo-quotation-last">
                            <div className="uploadphoto-photos">
                                <PhotoUnit 
                                    idx={0} 
                                    key={0} 
                                    setImageData={()=>{}} 
                                    imageSrc={''}
                                    disabled 
                                    downloadable
                                />
                            </div>
                        </div> */}
                        {/* <div className="iteminfo-quotation-restrictions-contentCheck">
                            <div className="iteminfo-quotation-restrictions-contentCheck___unknown">
                                <div className="paragraph iteminfo-quotation-text">
                                    ¿Requiere permiso?
                                </div> 
                                   
                                <div className="paragraph iteminfo-quotation-text">
                                    -
                                </div> 
                            </div>
                        </div> */}
                        {/* </> */}
                     {/* )} */}
                    {/* </div>
                </div> */}
            {/* </div> */}

            <div className="box-shadow-card" style={{borderRadius: '24px'}}>
                <TextField 
                    title="Observaciones" 
                    value={'-'} 
                    onChange={(e:any) => {}} 
                    placeholder=""
                    type='text' 
                    disabled
                />
            </div>
        </div>
    )
}