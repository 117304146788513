import React, { useEffect, useState } from "react";

// Importando estilo
import "./PlanSection.css";
import { Plan } from "../Plan/Plan";
import {Switch} from "../Switch/Switch";
import {ToggleSwitch} from "../ToggleSwitch/ToggleSwitch";
import { CardSummaryPay } from "../CardSummaryPay/CardSummaryPay";
import { toast } from "react-toastify";
import { PlanInterface } from "../../interfaces/PlansInterface";
import { GetPlansArkabia } from "../../Services/PlanArkabia.service";
import { useFetcher, useNavigate } from "react-router-dom";
import { ShiftString } from "../../utils/ShiftString";
import { AUTH_MODAL, AUTH_REGISTER, AUTH_REGISTER_STEP, OPERATIONS, PLAN_CURRENCY_MODAL, PLAN_MODAL, PLAN_SECONDATY_MODAL, PROFILE } from "../../Consts/BaseUrl";
import { useSelector } from "react-redux";
import { ScheduleOperation } from "../../interfaces/ScheduleOperation";
import { FormatISODate } from "../../utils/FormatISODate";
import { BeforeDay } from "../../utils/BeforeDay";
import { CreateScheduleOperation } from "../../Services/ScheduleOperation.service";
import { FormatDate } from "../../utils/formatDate";
import { CreditCard } from "../CreditCard/CreditCard";
import { CreateNewSuscription } from "../../Services/Subscription.service";
import { Loader } from "../Loader/Loader";
import { ModalProcessPay } from "../Modal/ModalProcessPay/ModalProcessPay";
import { ModalCulqi } from "../Modal/ModalCulqi/ModalCulqi";
import { GetPlanDetailById } from "../../Services/PlanDetailArkabia.service";
import { SimplePlan } from "../SimplePlan/SimplePlan";

interface PlanSectionProps {
    black?: boolean;
    isChagePlan?: boolean;
}

export const PlanSection: React.FC<PlanSectionProps> = ({
    black = true,
    isChagePlan = false
}) => {

    const navigate = useNavigate();

    const {plan} = useSelector((state: any) => state.plan);
    const {subscription} = useSelector((state: any) => state.subscription);
    const {userDetail} = useSelector((state: any) => state.user);

    // const [phase, setPhase] = useState<"plan" | "checkout">("plan");

    const [loading, setLoading] = useState<boolean>(false);
    const [currency, setCurrency] = useState<"USD" | "PEN">("USD");
    const [plans, setPlans] = useState<PlanInterface[]>([]);

    const [planSelected, setPlanSelected] = useState<PlanInterface | null>(null);

    const [secondaryPlanId, setSecondaryPlanId] = useState<string>("null");
    
    const [disabled, setDisabled] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalCulqi, setShowModalCulqi] = useState<boolean>(false);


    // method to handle currency change
    const handleCurrencyChange = (status: string) => {
        setCurrency(status as "USD" | "PEN");
    }

    // method to handle plan click
    const handlePlanItemClick = (plan: PlanInterface) => {
        if(!plan.id) {
            return;
        }
        setPlanSelected(plan);
        handleStartClick(plan.id, "", currency);
    }

    const handleStartClick = (planId: string, secondaryPlanId: string, currency: string) => {
        // console.log("🚀 ~ handleStartClick ", planId, secondaryPlanId, currency);
        setSecondaryPlanId(secondaryPlanId);
        setLoading(true);
        if(isChagePlan) {
            handleChangePlanClick();
        } else {
            navigate(`?${AUTH_MODAL}=${AUTH_REGISTER}&${PLAN_MODAL}=${ShiftString(planId, 12)}&${PLAN_SECONDATY_MODAL}=${ShiftString(secondaryPlanId.length>0 ? secondaryPlanId : "0", 12)}&${PLAN_CURRENCY_MODAL}=${ShiftString(currency, 12)}&${AUTH_REGISTER_STEP}=${ShiftString("1", 12)}`);
        }
        setLoading(false);
       // navigate(`?auth=register`);
    }

     // metho to change plan 
     const handleChangePlanClick = async () => {
        if(disabled) {
            toast.warn("Ya tienes un cambio de plan programado");
        } else {
            if(planSelected?.id === plan?.id) {
                toast.warn("Ya tienes este plan activo");
            } else {
              
                if(plan.priceUsd === 0) {
                    // setPhase("checkout");
                    setShowModalCulqi(true);
                } else {
                    setLoading(true);
                    const scheduleOperationData: ScheduleOperation = {
                        subscriptionId: subscription?.id || "",
                        paymentMethodId: subscription?.paymentMethodId || "",
                        newPlanId: planSelected?.id || "",
                        dateExecution: FormatISODate(BeforeDay(subscription.billingDate.toDate())),
                        userId: userDetail.id,
                        type: "changePlan",
                        source: "web",
                        status: "pending",
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString(),
                        executed: false,
                    }
                    console.log("🚀 ~ handleChangePlanClick ~ scheduleOperationData:", scheduleOperationData);
                    try {
                        const response = await CreateScheduleOperation(scheduleOperationData);
                        if(response) {
                            toast.success("se ha programado el cambio de plan para el próximo ciclo de facturación: "+FormatDate(BeforeDay(subscription.billingDate.toDate())), {autoClose: 6500});
                            goToPrincipalPanel();
                            //navigate('/'+PROFILE, { replace : true });
                        }
                        setLoading(false);
                    } catch (error) {
                        console.log("🚀 ~ handleChangePlanClick ~ error:", error)
                        setLoading(false);
                        toast.error("Hubo un error al cambiar de plan, intentelo en unos minutos");
                    }
                }
                
              //  setLoading(false);
            }
        }        
    }

    const goToPrincipalPanel = () => {
        window.location.href = window.location.origin+'/'+OPERATIONS;
    }

    // method to upgrade plan
    const handleUpgradePlan = (token: string) => {
        // console.log("🚀 ~ handleUpgradePlan ~ token:", token);
        if(!planSelected) {
            return;
        }
        setLoading(true);
        setShowModal(true);
        CreateNewSuscription({
            toke: token,
            userId: userDetail.id,
            planId: planSelected.id || "",
            secondaryPlanId: secondaryPlanId,
            currency: currency
        }).then((response) => {
            // console.log("🚀 ~ handleUpgradePlan ~ response:", response);
            toast.success("Suscripción creada correctamente", {autoClose: 2000});
           setTimeout(() => {
            setLoading(false); 
            setShowModal(false);    
            goToPrincipalPanel();
           }, 2000);
        }).catch((error) => {
            setLoading(false);
            console.log("🚀 ~ createSubscription ~ error:", error)
            toast.error("Error al crear la suscripción");
        });     
    }

    
    // method to get plans from firebase
    const getPlans = async () => {
        if(loading) return;

        setLoading(true);
        try {
            const response = await GetPlansArkabia();
            console.log("🚀 ~ getPlans ~ response:", response)
            !isChagePlan && setPlanSelected(response[4]);
            // !isChagePlan && setPlanSelected(response[2]);
            setPlans(response);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getPlans ~ error:", error);
            setLoading(false);
            toast.error("Ocurrió un error al obtener los planes, por favor intenta nuevamente");
        }
    }



    useEffect(() => {
        if(plan) {
            setPlanSelected(plan);
        }
    }, [plan]);

    useEffect(() => {
        setDisabled(subscription.scheduleOperationId && subscription.scheduleOperationType === "changePlan" ? true : false);
    }, [subscription]);


    useEffect(() =>{
        getPlans();
    }, []);


    return (
        <div className="planSection-content">
           {/* { phase === "plan" ? 
           <> */}
                <div className="planSection-switch">
                    <ToggleSwitch
                        textLeft="USD"
                        textRight="PEN"
                        onToggle={handleCurrencyChange}
                        black={black}
                    />
                    <div className={`small-boldheader ${black ? "planSection-switch__text" : "planSection-switch__text_black"}`}>Ahorra 30% con el plan anual</div>
                </div>
                {!loading ? 
                <div className="planSection-row-scroll">
                    <div className="planSection-row">
                    { plans.length>0 && plans.map((item, idx) => {
                        return (
                            <SimplePlan
                                key={idx}
                                plan={item}
                                onclickPlan={handlePlanItemClick}
                                currencyActive={currency}
                                isPlanSelected={planSelected?.id === item.id}
                                black={black}
                            />
                        )
                    })}
                    </div>
                    {/* {planSelected && <CardSummaryPay 
                        planSelected={planSelected}
                        currency={currency}
                        handlePlanClick={handleStartClick}
                        black={black}
                        
                    />} */}
                </div> : <Loader />}
            {/* </> : 
            <div className="planSection-chackout">
                <CreditCard
                    price={currency === "USD" ? (planSelected?.priceUsd || 0) : (planSelected?.pricePen || 0)}
                    email={userDetail.email}
                    currency={currency}
                    onClick={handleUpgradePlan}
                />
            </div>
            } */}
            <ModalProcessPay 
                show={showModal}
                setShow={setShowModal}
                loading={loading}
            />

            {showModalCulqi && <ModalCulqi
                showModal={showModalCulqi}
                setShowModal={setShowModalCulqi}

                price={currency === "USD" ? (planSelected?.priceUsd || 0) : (planSelected?.pricePen || 0)}
                email={userDetail.email}
                currency={currency}
                onClick={handleUpgradePlan}
                loading={loading}
            />}
        </div>
    );
};
