import axios from "axios";

import { PORT_URL } from "../Consts/BaseUrl";

export const GetPortByLabel = async (label: string) => {
    try {
        const response = await axios.get(`${PORT_URL}${label}`);
        // console.log("🚀 ~ file: Port.service.ts ~ line 7 ~ GetPortByLabel ~ response", response.data.data.ports)
        return response.data.data;
    } catch (error) {
        console.log("🚀 ~ file: Port.service.ts ~ line 12 ~ GetPortByLabel ~ error", error)
        return error;
    }
}