import React from "react";

import { Button } from "..";

import './HeaderCustomsPhases.css';

interface HeaderCustomsPhasesProps {
    numPhase: number;
    title: string;
    handleMessageclick: () => void;
}

export const HeaderCustomsPhases: React.FC<HeaderCustomsPhasesProps> = ({
    numPhase,
    title,
    handleMessageclick,
    ...props
}) => {
    return (
        <div className="headerCustomsPhases-container" role='header-customs-phases'>
            <div className="headerCustomsPhases-title">
                <div className="paragraph-header headerCustomsPhases-title--number">
                    <div className="paragraph-header headerCustomsPhases-title--number-content">
                        {numPhase}
                    </div>
                </div>
                <div className="small-bold headerCustomsPhases-title--text">{title}</div>
            </div>
            {/* <Button 
                role='btn-message'
                color="black-05"
                content="Ver mensajes"
                onClick={()=>handleMessageclick()}
            /> */}
        </div>
    )
}