import React, { useEffect, useState } from "react";

import { ContainerBlack } from "../../../../icons/solid/Container";
import { Button, Dropdown } from "../../..";

// importando interfaces
import { ContainerInterface } from "../../../../interfaces/Container";

import "./ContainerInfo.css";

interface ContainerInfoProps {
  constainerDetail: ContainerInterface,
  typeContainer: string,
  showButtonAdd?: boolean,
  handleButtonAdd?: ()=>void,
}


export const ContainerInfo: React.FC<ContainerInfoProps> = ({
  constainerDetail,
  typeContainer='',
  showButtonAdd=false,
  handleButtonAdd= ()=>{}
}) => {



  return (
    <div className="container-info-column">
      <div className="container-info-row">
        <img src={constainerDetail?.urlImg} alt="contenedor-estandar" className="img-container"/>
        <div className="container-info">
          <div className="container-info-title">
            <p className="paragraph-header">{typeContainer}</p>
            <p className="smalltext">{constainerDetail?.minDescription}</p>
          </div>
          <div className="container-body">
            <div className="container-specs">
              <div className="container-spec">
                <p className="smalltext-header">Medidas exteriores</p>
                <p className="smalltext">{constainerDetail?.externalMeasures}</p>
              </div>
              <div className="container-spec">
                <p className="smalltext-header">Medidas interiores</p>
                <p className="smalltext">{constainerDetail?.internalMeasures}</p>
              </div>
              <div className="container-spec">
                <p className="smalltext-header">Capacidad nominal</p>
                <p className="smalltext">{constainerDetail?.ratedCapacity}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showButtonAdd && <Button
        content="Añadir"
        size="normal"
        color="black-75"
        onClick={handleButtonAdd}
      /> }
    </div>
  );
};
