import React, { useState } from "react";
import { DocsFileValidation, PDFFileValidation, SpreadSheetFileValidation } from "../../../Consts/FilesValidation";
import { UploadFileArray } from "../../UploadFile/UploadFileArray/UploadFileArray";

import './ArrivalNotice.css';

interface ArrivalNoticeProps {
    aceptTypeFiles: string;
    handleFileChange: (e: any) => void;
    noticeArrival: {
        name: string;
        url: string;
        size: number;
        userId: string;
    }[];
    disabled?: boolean;
    deleteFile: (idx: number) => void;
}

export const ArrivalNotice: React.FC<ArrivalNoticeProps> = ({
    handleFileChange,
    aceptTypeFiles,
    noticeArrival,
    disabled=false,
    deleteFile
}) => {

    const [notice, setNotice] = useState<any>(undefined);

    return ( 
        <div className='arrivalNotice-main' role='arrival-notice'> 
             <UploadFileArray 
                     title="Aviso de llegada"
                     subtitle="El aviso de llegada es el documento que se tiene que pagar cuando la mercancía ya está cerca a su destino."
                     buttonContent="Subir archivo"
                     fileValue={notice}
                     file={noticeArrival}
                     disabled={disabled}
                     handleFileValue={handleFileChange}
                     acceptType={aceptTypeFiles}
                     handleDelete={deleteFile}
                     isDelete
                />
        </div>
    )
}