import React, { useState } from "react";

/** importando componentes */
import { Dropdown, Textarea } from "..";
import { TextField } from "..";
import { ButtonIcon } from "..";
import { Insurance } from "../Insurance/Insurance";

/** importando iconos */
import { CloudUp } from "../../icons/outline/CloudUp";

/** importando estilos */
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

import './QuoteResume.css';
import { InfoCircleGray } from "../../icons/outline/InfoCircleGray";
import { TextAreaInput } from "../TextAreaInput/TextAreaInput";
import { RequestQuoteInterface } from "../../interfaces/RequestQuote";

interface QuoteResumeProps {
    role?: string
    requestQuote: RequestQuoteInterface;
}

export const QuoteResume: React.FC<QuoteResumeProps> = ({
    role = "",
    requestQuote,
}) => {

    const [disabled, setDisabled] = useState(true);

    return (
        <div className="quoteResume-container" role={role}>
            {/* <div className=" small-bold quoteResume--colorText">Resumen</div> */}
            <div className="quoteResumer-content">
                <div className="small-medium">Revisa que todos tus datos estén correctos</div>
                <div className="quoteResume-transport">
                    <div className="paragraph-header quoteResume--colorText">Transporte</div>
                    <div className="quoteResume-column">
                        <div className="quoteResume-row quoteResume-row--transport">
                            <div className="quoteResume-row--transport_column">
                                <div className="quoteResume-row-title">Medio de transporte</div>
                                <div className="quoteResume-row_value">{requestQuote.transport.type? (requestQuote.transport.type === 'sea' ? 'Marítimo' : 'Aéreo') : ''}</div>
                            </div>
                        </div>
                        <div className="quoteResume-row quoteResume-row--transport_ quoteResume-row-width">
                            <TextAreaInput 
                                title={`${requestQuote.serviceTypeId === 'courier' ? "Almacén" : (requestQuote.transport.origin?.type === "PORT" ? "Puerto": requestQuote.transport.origin?.type === "AIRPORT" ? "Aeropueto" : "Ciudad") } de origen`} 
                                value={requestQuote.serviceTypeId === 'courier' ? requestQuote.transport.warehouseOrigin : 
                                    requestQuote.transport.type === 'sea' ? (requestQuote.transport.origin?.type === "PORT" ? `${requestQuote.transport.origin.name} - ${requestQuote.transport.origin.portIsoCode}` : requestQuote.transport.origin?.city+', '+requestQuote.transport.origin?.country) :
                                    (requestQuote.transport.type === 'air' ? (requestQuote.transport.origin?.type === "AIPORT" ? requestQuote.transport.origin.airport : requestQuote.transport.origin?.city+", "+requestQuote.transport.origin?.country ) : '')}
                            />
                            <TextAreaInput 
                                title={`${requestQuote.serviceTypeId === 'courier' ? "Almacén" : (requestQuote.transport.destination?.type === "PORT" ? "Puerto": requestQuote.transport.destination?.type === "AIRPORT" ? "Aeropueto" : "Ciudad") } de destino`} 
                                value={requestQuote.serviceTypeId === 'courier' ? requestQuote.transport.warehouseDestination : 
                                    requestQuote.transport.type === 'sea' ? (requestQuote.transport.destination?.type === "PORT" ? `${requestQuote.transport.destination.name} - ${requestQuote.transport.destination.portIsoCode}` : requestQuote.transport.destination?.city+', '+requestQuote.transport.destination?.country) :
                                    (requestQuote.transport.type === 'air' ? (requestQuote.transport.destination?.type === "AIPORT" ? requestQuote.transport.destination.airport : requestQuote.transport.destination?.city+", "+requestQuote.transport.destination?.country ) : '')}
                            />
                        </div>
                    </div>
                    {(requestQuote.transport?.initDelivery && requestQuote.transport?.initDelivery.length>0 ) && (requestQuote.transport?.finalDelivery &&
                        requestQuote.transport?.finalDelivery.length>0 ) &&
                    <div className="quoteResume-row quoteResume-row--transport quoteResume-row--transport-direction">
                        <TextAreaInput title="Dirección de recojo" 
                            
                            value={requestQuote.transport?.initDelivery.replace(/(<([^>]+)>)/g, " ")} 
                            
                        />
                        <TextAreaInput title="Dirección de entrega" 
                         
                            value={requestQuote.transport?.finalDelivery.replace(/(<([^>]+)>)/g, " ")} 
                            
                        />
                    </div>}
                    {requestQuote.transport.typeContainer && requestQuote.transport.typeContainer?.lcl &&
                    <div className="smalltext-header quoteResume--colorText">LCL</div>}
                    {requestQuote.transport.typeContainer?.lcl && 
                    <>
                        <div className="quoteResume-row">
                            <div className="quoteResume-row quoteResume-row--transport_ quoteResume-row--transport-">
                                {!requestQuote.transport.lcl?.packages && <Dropdown
                                    elements={[requestQuote.transport.lcl?.cargoDescription? requestQuote.transport.lcl.cargoDescription:'']}
                                    value={requestQuote.transport.lcl?.cargoDescription?requestQuote.transport.lcl.cargoDescription:''}
                                    setValue={()=>{}}
                                    title='Tipo de embalaje'
                                    disabled={disabled}
                                />}
                                
                                <div className="quoteResume-row--transport_1">
                                    <div className="quoteResume-row--transport_column">
                                        <div className="quoteResume-row-title">Peso total</div>
                                        <div className="quoteResume-row_value">{requestQuote.transport.lcl?.totalWeight}</div>
                                    </div>
                                    <Dropdown
                                        elements={[requestQuote.transport.lcl?.unitWeight?requestQuote.transport.lcl.unitWeight: '']}
                                        value={requestQuote.transport.lcl?.unitWeight?requestQuote.transport.lcl.unitWeight:''}
                                        setValue={()=>{}}
                                        title='Unidad'
                                        disabled={disabled}
                                    />
                                </div>
                                
                                <div className="quoteResume-row--transport_2">
                                    <div className="quoteResume-row--transport_column">
                                        <div className="quoteResume-row-title">Volumen total</div>
                                        <div className="quoteResume-row_value">{requestQuote.transport.lcl?.totalVolume}</div>
                                    </div>
                                    <Dropdown
                                        elements={[requestQuote.transport.lcl?.unitVolume?requestQuote.transport.lcl.unitVolume:'']}
                                        value={requestQuote.transport.lcl?.unitVolume?requestQuote.transport.lcl.unitVolume:''}
                                        setValue={()=>{}}
                                        title='Unidad'
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </div>
                        {requestQuote.transport.lcl?.packages && requestQuote.transport.lcl?.packages.length>0 && requestQuote.transport.lcl.packages.map((p, idx) => (
                            <>
                                <div className="smalltext-header quoteResume--colorText">Paquete No {idx+1}</div>
                                <div className="quoteResume-row">
                                    <div className="quoteResume-row quoteResume-row--transport_ quoteResume-row--transport-">
                                        <div className="quoteResume-row--transport_1">   
                                            <Dropdown
                                                elements={[p.type]}
                                                value={p.type}
                                                setValue={()=>{}}
                                                title='Tipo'
                                                disabled={disabled}
                                            />  
                                            <div className="quoteResume-row--transport_column">
                                                <div className="quoteResume-row-title">Cantidad</div>
                                                <div className="quoteResume-row_value">{p.quantity}</div>
                                            </div>                                        
                                        </div>
                                        <div className="quoteResume-row--transport_1">
                                            <div className="quoteResume-row--transport_column">
                                                <div className="quoteResume-row-title">Ancho</div>
                                                <div className="quoteResume-row_value">{p.width}</div>
                                            </div>
                                            <div className="quoteResume-row--transport_column">
                                                <div className="quoteResume-row-title">Largo</div>
                                                <div className="quoteResume-row_value">{p.tall}</div>
                                            </div>
                                        </div>
                                        <div className="quoteResume-row--transport_1">
                                            <div className="quoteResume-row--transport_column">
                                                <div className="quoteResume-row-title">Alto</div>
                                                <div className="quoteResume-row_value">{p.height}</div>
                                            </div>
                                            <Dropdown
                                                elements={[p.unitMeasurement]}
                                                value={p.unitMeasurement}
                                                setValue={()=>{}}
                                                title='Unidad'
                                                disabled
                                            />   
                                        </div>                                      
                                    </div>
                                </div>
                            </> 
                        ))}
                    </>
                    }
                {/* {isLclAir && } */}

                {requestQuote.transport.typeContainer?.lclAir && <div className="quoteResume-row">
                    <div className="quoteResume-row quoteResume-row--transport_ quoteResume-row--transport-">
                        <Dropdown
                            elements={[requestQuote.transport.lclAir?.cargoDescription?requestQuote.transport.lclAir.cargoDescription:'']}
                            value={requestQuote.transport.lclAir?.cargoDescription?requestQuote.transport.lclAir.cargoDescription:''}
                            setValue={()=>{}}
                            title='Tipo de embalaje'
                            disabled={disabled}
                        />
                        <div className="quoteResume-row--transport_1">
                            <div className="quoteResume-row--transport_column">
                                <div className="quoteResume-row-title">Peso total</div>
                                <div className="quoteResume-row_value">{requestQuote.transport.lclAir?.weight}</div>
                            </div>
                            <Dropdown
                                elements={[requestQuote.transport.lclAir?.unitWeight?requestQuote.transport.lclAir.unitWeight: '']}
                                value={requestQuote.transport.lclAir?.unitWeight?requestQuote.transport.lclAir.unitWeight:''}
                                setValue={()=>{}}
                                title='Unidad'
                                disabled={disabled}
                            />
                        </div>
                        <div className="quoteResume-row--transport_2">   
                            <div className="quoteResume-row--transport_column">
                                <div className="quoteResume-row-title">Volumen total</div>
                                <div className="quoteResume-row_value">{requestQuote.transport.lclAir?.volume}</div>
                            </div>
                            <Dropdown
                                elements={[requestQuote.transport.lclAir?.unitVolume?requestQuote.transport.lclAir.unitVolume:'']}
                                value={requestQuote.transport.lclAir?.unitVolume?requestQuote.transport.lclAir.unitVolume:''}
                                setValue={()=>{}}
                                title='Unidad'
                                disabled={disabled}
                            />
                        </div>                       
                    </div>
                </div>}

                    {/** FCL */}
                {requestQuote.transport.typeContainer?.fcl && <div className="smalltext-header quoteResume--colorText">FCL</div>}
                {requestQuote.transport.fcl && 
                    <>
                        {
                            requestQuote.transport.fcl.length>0  &&  requestQuote.transport.fcl.map((f, idx) => (
                            <div  key={idx} className="quoteResume-row quoteResume-row-desktop">
                                <div className="quoteResume-row--transport_column">
                                    <div className="quoteResume-row-title">{window.innerWidth<= 600 ? 'Tipo' : "Tipo de contenedor" }</div>
                                    <div className="quoteResume-row_value">{window.innerWidth<= 600 ? f.type + ' ' + f.size : f.type}</div>
                                </div>
                                <div className="quoteResume-row--transport_column">
                                    <div className="quoteResume-row-title">Tamaño</div>
                                    <div className="quoteResume-row_value">{f.size}</div>
                                </div>
                                <div className="quoteResume-row--transport_column">
                                    <div className="quoteResume-row-title">Cantidad</div>
                                    <div className="quoteResume-row_value">{f.quantity}</div>
                                </div>
                            </div>))
                        }

                        <>
                            {
                                requestQuote.transport.fcl.length>0  &&  requestQuote.transport.fcl.map((f, idx) => (
                                <div  key={idx} className="quoteResume-row-mobile quoteResume-row-mobile__">
                                    <div className="quoteResume-row--transport_column">
                                        <div className="quoteResume-row-title">{window.innerWidth<= 600 ? 'Tipo' : "Tipo de contenedor"}</div>
                                        <div className="quoteResume-row_value">{window.innerWidth<= 600 ? f.type + ' ' + f.size : f.type}</div>
                                    </div>
                                    <div className="quoteResume-row--transport_column">
                                        <div className="quoteResume-row-title">Cantidad</div>
                                        <div className="quoteResume-row_value">{f.quantity}</div>
                                    </div>
                                </div>))
                            }
                        </>
                    </>
                }
                </div>
                {requestQuote.customs && 
                    <div className="quoteResume-customs">
                    <div className="paragraph-header quoteResume--colorText">Aduana</div>
                    <div className="quoteResume-row quoteResume-row-aduana-input">
                        {requestQuote.serviceTypeId !== 'courier' && 
                        <div className="quoteResume-row--transport_column">
                            <div className="quoteResume-row-title">{window.innerWidth <= 600 ? "Valor" : "Importe total"}</div>
                            <div className="quoteResume-row_value">{ConverMoneyFormat(requestQuote.customs.value?requestQuote.customs.value?.toString():'0.00')} </div>
                        </div>}
                        <div className="quoteResume-row quoteResume-row-aduana">
                            {requestQuote.serviceTypeId === 'courier' &&
                            <div className="quoteResume-row--transport_column">
                                <div className="quoteResume-row-title">{window.innerWidth <= 600 ? "Valor" : "Importe total"}</div>
                                <div className="quoteResume-row_value">{ConverMoneyFormat(requestQuote.customs.value?requestQuote.customs.value?.toString():'0.00')}</div>
                            </div>}
                            <div className="quoteResume-row--transport_column">
                                <div className="quoteResume-row-title">Moneda</div>
                                <div className="quoteResume-row_value">{requestQuote.customs.coin} </div>
                            </div>
                            {requestQuote.serviceTypeId === 'courier' && 
                            <div className="quoteResume-row--transport_column">
                                <div className="quoteResume-row-title">Link de compra</div>
                                <div className="quoteResume-row_value">{requestQuote.customs.purchaseLink} </div>
                            </div>
                            }

                           {requestQuote.serviceTypeId !== 'courier' && 
                            <div className="quoteResume-row--transport_column">
                                <div className="quoteResume-row-title">Incoterm</div>
                                <div className="quoteResume-row_value">{requestQuote.customs.incoterm} </div>
                            </div>}
                        </div>
                    </div>
                    {requestQuote.serviceTypeId !== 'courier' && <div className="quoteResume-row">
                        <div className="quoteResume-row">
                            <Insurance 
                                secure={requestQuote.customs.insurance?requestQuote.customs.insurance:false} 
                                setSecure={()=>{}}
                                resume
                            />
                        </div>
                    </div>}

                    {requestQuote.customs.incoterm === 'EXW' && requestQuote.customs.exwDirection && 
                    <div className="quoteResume-row">
                        <TextField 
                            title="Dirección de recojo de la mercancía" 
                            placeholder=" " 
                            value={requestQuote.customs.exwDirection} 
                            disable={disabled} 
                        />
                    </div>}
                    <div className="quoteResume-row quoteResume-row-desktop_file">
                        <div className="quoteResume-row quoteResume--file">
                            <div className="quoteResume--file-hover">
                              
                                <InfoCircleGray 
                                    className="insurance--icon"
                                />
                                <div className="smalltext-header quoteResume--colorText" >{`Proforma o ${requestQuote.serviceTypeId === 'courier' ? "factura" : "cotización"}`}</div>
                            </div>
                            {(requestQuote.customs.merchandisePermits && requestQuote.customs.merchandisePermits?.proforma.length>0) ? 
                            <div className="quoteResume-customs--attached">
                                {requestQuote.customs.merchandisePermits.proforma.map((d, idx) => (
                                    <ButtonIcon
                                        key={idx}
                                        content={d.name}
                                        Icon={<CloudUp />}
                                        color="black-25"
                                        size="extra-small"
                                        place="right"
                                        onClick={() => { }}
                                        disabled={disabled}
                                    />
                                ))}
                            </div> : 
                            <div className="smalltext-header">No existe</div>}
                        </div>
                        {requestQuote.serviceTypeId !== 'courier' && <div className="quoteResume-row quoteResume--file">
                            <div className="quoteResume--file-hover">
                     
                                <InfoCircleGray 
                                    className="insurance--icon"/>
                                <div className="smalltext-header quoteResume--colorText" >Lista de empaque</div>
                            </div>
                            {(requestQuote.customs.merchandisePermits && requestQuote.customs.merchandisePermits?.packingList.length>0) ? 
                            <div className="quoteResume-customs--attached">
                                {requestQuote.customs.merchandisePermits.packingList.map((d, idx) => (
                                    <ButtonIcon
                                        key={idx}
                                        content={d.name}
                                        Icon={<CloudUp />}
                                        color="black-25"
                                        size="extra-small"
                                        place="right"
                                        onClick={() => { }}
                                        disabled={disabled}
                                    />
                                ))}
                            </div> :
                            <div className="smalltext-header">No existe</div>}
                        </div>}
                        <div className="quoteResume-row quoteResume--file">
                            <div className="quoteResume--file-hover">
                                 <InfoCircleGray 
                                        className="insurance--icon"/>
                                <div className="smalltext-header quoteResume--colorText" >{requestQuote.serviceTypeId === 'courier' ? "Foto del producto" : "Ficha Técnica"}</div>
                            </div>
                            {(requestQuote.customs.merchandisePermits && requestQuote.customs.merchandisePermits?.dataSheet.length>0) ? 
                            <div className="quoteResume-customs--attached">
                                {requestQuote.customs.merchandisePermits.dataSheet.map((d, idx) => (
                                    <ButtonIcon
                                        key={idx}
                                        content={d.name}
                                        Icon={<CloudUp />}
                                        color="black-25"
                                        size="extra-small"
                                        place="right"
                                        onClick={() => { }}
                                        disabled={disabled}
                                    />
                                ))}
                            </div> : 
                            <div className="smalltext-header">No existe</div>}
                        </div>
                    </div>

                    <div className="paragraph-header quoteResume--colorText">Situación o comentarios</div>
                    <div className="quoteResume-row__">
                        <Textarea 
                            title="" 
                            value={requestQuote.customs.descriptionOperation} 
                            disable={disabled}
                            />
                    </div>
                </div>}
            </div>
        </div>
    )
}