import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type Heart_RedProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Heart_Red: React.FC<Heart_RedProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/heart_red.svg'} alt='' />
        </div>
    )
}