export const ShiftString = (str: string, shift: number): string => {
    return str.split('').map(char => {
        const code = char.charCodeAt(0);
        // Shift character within the full ASCII range
        return String.fromCharCode((code + shift) % 256);
      }).join('');
};

export const UnshiftString = (str: string, shift: number): string => {
    return str.split('').map(char => {
        const code = char.charCodeAt(0);
        // Unshift character within the full ASCII range
        return String.fromCharCode((code - shift + 256) % 256);
      }).join('');
};