import React, { useCallback, useEffect, useRef, useState } from "react";

import './SearchService.css';
import { SearchTextField } from "../SearchTextField/SearchTextField";
import debounce from "lodash.debounce";
import { ServiceInterface } from "../../interfaces/Service";
import { GetServicesType } from "../../Services/Service.service";
import { LinearLoader } from "../LinearLoader/LinearLoader";
import { ErrorInterface } from "../../interfaces/ErrorInterface";
import { ServiceMiniature } from "../ServiceMiniature/ServiceMiniature";
import { FilterService } from "../FilterService/FilterService";

interface SearchServiceProps {
    title: string;
    serviceTypeId: string;
    serviceSelected: ServiceInterface | undefined;
    handleServiceSelected: (service: ServiceInterface | undefined) => void;
    error?: ErrorInterface
    disabled?: boolean
}

export const SearchService: React.FC<SearchServiceProps> = ({
    title='Buscar',
    serviceTypeId='',
    serviceSelected,
    handleServiceSelected=()=>{},
    error = {
        status: false,
        message: "" 
    },
    disabled=false
}) => {
    
    const [search, setSearch] = useState<string>('');
    const [services, setServices] = useState<ServiceInterface[]>([]);
    const [serviceFiltered, setServiceFiltered] = useState<ServiceInterface[]>([]);

     // loading 
     const [loading, setLoading] = useState<boolean>(false);


    const handleSearchService = useCallback( 
        debounce(() => {
            if(search.length>1) {
                const filtered = services.filter((service) => {
                    return service.companyName.toLowerCase().includes(search.toLowerCase());
                });
                setServiceFiltered(filtered);
            } 
        }, 300),[search]);

    const getAllServiceByType = async (typeId: string) => {
        if(!loading) {
            setLoading(true);
            try {
             const resopnse = await GetServicesType(typeId);
             let service = resopnse.sort((a, b) => (a.rating > b.rating) ? -1 : 1);
             setServices(service);
             setServiceFiltered(service);
             setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: SearchService.tsx:40 ~ getAllServiceByType ~ error:", error);
                setLoading(false);
            }
         }
    }

    const handleFilterService = (filter: string) => {
        if(filter === "Todos") {
            setServiceFiltered(services);
        } else {
            const filtered = services.filter((service) => {
                return service.keywords?.includes(filter);
            });
            setServiceFiltered(filtered);
        }
    }
       

    const handleItemClick = (item: ServiceInterface) => {
        handleServiceSelected(item);
        setServiceFiltered([item]);
       
    }

    const handleUnSelectItem = () => {
        setServiceFiltered(services);
        handleServiceSelected(undefined);
    }

    useEffect(() => {
        if(search.length>1) {
            handleSearchService();
        } else {
           setServiceFiltered(services);
        }
       
    }, [search]);

    useEffect(()=> {
        getAllServiceByType(serviceTypeId);
    }, [serviceTypeId]);


    return (
        <div className="searchService-container"> 
            {serviceTypeId !== "courier" ? <div className="smalltext searchService-colorText">Elige un proveedor</div> :
            <div className="searchService-header">
                <div className="smalltext searchService-colorText">¿Dónde será tu compra?</div>
                <FilterService
                  title=""
                  titleDropdown="Todos"
                  filterItems= {["China", "EE.UU", "Todos"]}
                  handleItemSelected={handleFilterService}
                />
            </div>}
            {!serviceSelected && serviceTypeId !== "courier" && <SearchTextField title={title} setTextSearch={setSearch} />}
            {error.status &&!serviceSelected && <div className="text-error tinytext-header">{error.message}</div>}
            
            {serviceFiltered.length> 0 && <div className="searchService-listMain">
                {serviceSelected ? 
                    <ServiceMiniature
                        key={serviceSelected.id}
                        service={serviceSelected}
                        choose={false}
                        edit={false}
                        quoteSended={false}
                        quoteSelected={false}
                        onClickChoose ={()=>{}}
                        onClickService={()=> !disabled ? handleUnSelectItem(): {}}
                        // onClickScroll={ScrollTop}
                        onClickWsp={()=>()=>{}}
                        showButtonWsp={false}
                        verify={serviceSelected.verify}
                        numberVerify={serviceSelected.numberVerify}
                    />
                // <SearchServiceItem 
                //     img={serviceSelected.companyLogo || ""}
                //     companyName={serviceSelected.companyName}
                //     countryFlag={serviceSelected.country.imgFlag}
                //     rating={serviceSelected.rating}
                //     numComments={serviceSelected.numComments}
                //     companyId={serviceSelected.companyId}
                //     serviceId={serviceSelected.id || ""}
                //     habdleItemUnSelect={ ()=> !disabled ? handleUnSelectItem(): {}}
                //     isSelected
                //     verify={serviceSelected.verify}
                //     numberVerify={serviceSelected.numberVerify}
                // /> 
                :
                <div className="searchService-list">
                    {!loading ? serviceFiltered.map((s, idx) => (
                        <ServiceMiniature
                            key={s.id}
                            service={s}
                            choose={false}
                            edit={false}
                            quoteSended={false}
                            quoteSelected={false}
                            onClickChoose ={()=>{}}
                            onClickService={()=>handleItemClick(s)}
                            // onClickScroll={ScrollTop}
                            onClickWsp={()=>()=>{}}
                            showButtonWsp={false}
                            verify={s.verify}
                            numberVerify={s.numberVerify}
                        
                        />
                        // <SearchServiceItem 
                        //     key={idx}
                        //     img={s.companyLogo || ""}
                        //     companyName={s.companyName}
                        //     countryFlag={s.country.imgFlag}
                        //     rating={s.rating}
                        //     numComments={s.numComments}
                        //     companyId={s.companyId}
                        //     serviceId={s.id || ""}
                        //     handleItemClick={()=>handleItemClick(s)}
                        //     verify={s.verify}
                        //     numberVerify={s.numberVerify}
                        // />
                    )): <div className="searchService-loader"><LinearLoader /></div>}
                </div> }
            </div>}
            <div></div>
        </div>
    )
}