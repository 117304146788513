import { doc, getDoc} from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";
import { ServicePlanInterface } from "../interfaces/ServicesPlanInterface";


// obtener servicePlan segun id
export const GetServicePlanById = async(id: string): Promise<ServicePlanInterface>  => {
    const response = await getDoc(doc(DB, 'servicePlan', id));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            let data:any = response.data();
            resolve({
                id: response.id, 
                plans: data.plans,
                price: data.price,
                description: data.description,
                detail: data.detail,
                deliveryTime: data.deliveryTime,
                numRevision: data.numRevision,
                companyId: data.companyId,
                companyName: data.companyName,
                serviceId: data.serviceId,
                status: data.status,
                mostPopular: data.mostPopular,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt,
            })
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}