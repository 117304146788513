import React from "react";

/** importando componentes */
import { TextField } from "../..";

/** importando utilidades */

/** importando estilo */
import './RowGroupDetail.css';

/** declarando variables de interface */
import { Detail } from "../../../interfaces/Quotation";

import { GetValidDate } from "../../../utils/GetValidDate";

interface RowGroupDetailProps {
    detail: Detail
    setDetail: React.Dispatch<React.SetStateAction<Detail>>
    disabled: boolean;
    isFcl: boolean;
    handleAutoSave?: ()=>void;
    isCourier?: boolean;
}

export const RowGroupDetail: React.FC<RowGroupDetailProps> = ({
    detail,
    setDetail,
    disabled,
    isFcl,
    handleAutoSave=()=>{},
    isCourier = false
}) => {

    // metodos
    // handle tiempor de transito
    const handleTransitChange=(value: number | string)=>{
        setDetail((prev:any) => {
            return {
                ...prev,
                transitTime: value
            }
        });
        handleAutoSave();
    }  

    // handle dias libres de almacenamiento
    const handleFreeDaysChange=(value: number)=>{
        setDetail((prev:any) => {
            return {
                ...prev,
                storageBase: value
            }
        });
        handleAutoSave();
    }

    // handle dias libres de almacenamiento
    const handleAboutStayChange=(value: number)=>{
        setDetail((prev:any) => {
            return {
                ...prev,
                aboutStay: value
            }
        });
        handleAutoSave();
    }

    // handle vencimiento de cotizacion
    const handleQuotationExpirationChange=(value: string)=>{
        setDetail((prev:any) => {
            return {
                ...prev,
                expiration: (GetValidDate(value))
            }
        });
        handleAutoSave();
    }

    // metodo blur tiempo de transito 
    const handleTransitBlur = (value: string = '') => {
       let splitText = value.split(' ');
       let dayText = '';
    //    console.info(splitText)
       if(splitText.length === 1){
        dayText = `${value} ${Number(value)>1 ? 'días' : 'día'}`;    
       } else {
        dayText = `${splitText[0]} ${Number(splitText[0])>1 ? 'días' : 'día'}`;
       }
       setDetail((prev: any) => {
            return {
                ...prev,
                transitTime: dayText
            }
        });
    }

    // metodo blur dias libres de almacenamiento
    const handleFreeDaysBlur = (value: string = '') => {
        let splitText = value.split(' ');
        let dayText = '';
        // console.info(splitText)
        if(splitText.length === 1){
         dayText = `${value} ${Number(value)>1 ? 'días' : 'día'}`;    
        } else {
         dayText = `${splitText[0]} ${Number(splitText[0])>1 ? 'días' : 'día'}`;
        }
        setDetail((prev: any) => {
             return {
                 ...prev,
                 storageBase: dayText
             }
         });
     }

     // metodo blur dias libres de almacenamiento
    const handleAboutStayBlur = (value: string = '') => {
        let splitText = value.split(' ');
        let dayText = '';
        // console.info(splitText)
        if(splitText.length === 1){
         dayText = `${value} ${Number(value)>1 ? 'días' : 'día'}`;    
        } else {
         dayText = `${splitText[0]} ${Number(splitText[0])>1 ? 'días' : 'día'}`;
        }
        setDetail((prev: any) => {
             return {
                 ...prev,
                 aboutStay: dayText
             }
         });
     }

    return (
        <div role='rowGroupDetailRole' className="rowGroup-detail-container">
            <div className="rowGroup-detail-body">
                {<div className="rowGroupDetail__ rowGroupDetail_transparent" >
                    <div className="paragraph rowGroupDetail_text" style={{color: "var(--gray-1)"}}>Tiempo de tránsito</div>
                    <TextField title="" 
                               value={detail.transitTime} 
                               onChange={(e:any) => handleTransitChange(e.target.value)} 
                               placeholder="días" 
                            
                               disabled={disabled}
                                role='rowGroupDetailTransitTime'
                                onBlur={()=>handleTransitBlur(detail.transitTime?.toString())}
                            />
                </div>}
                {!isCourier && <div className="rowGroupDetail__ rowGroupDetail_white" >
                    <div className="paragraph rowGroupDetail_text" style={{color: "var(--gray-1)"}}>Días libres de almacenamiento</div>
                    <TextField title="" 
                               value={detail.storageBase} 
                               onChange={(e:any) => handleFreeDaysChange(e.target.value)} 
                               placeholder="días" 
                                onBlur={()=>handleFreeDaysBlur(detail.storageBase?.toString())}
                               disabled={disabled}
                                role='rowGroupDetailFreeDays'
                               />
                </div>}
                {!isCourier && isFcl && <div className="rowGroupDetail__ rowGroupDetail_transparent" >
                    <div className="paragraph rowGroupDetail_text" style={{color: "var(--gray-1)"}}>Sobre estadía</div>
                    <TextField title="" 
                               value={detail.aboutStay} 
                               onChange={(e:any) => handleAboutStayChange(e.target.value)} 
                               placeholder="días" 
                                onBlur={()=>handleAboutStayBlur(detail.aboutStay?.toString())}
                               disabled={disabled}
                                role='rowGroupDetailFreeDays'
                               />
                </div>}
                <div className={`rowGroupDetail__ ${isFcl ? 'rowGroupDetail_white': 'rowGroupDetail_transparent'}`} >
                    <div className="paragraph rowGroupDetail_text" style={{color: "var(--gray-1)"}}>Vencimiento de cotización</div>
                    <TextField title="" 
                               value={detail.expiration} 
                               onChange={(e:any) => handleQuotationExpirationChange(e.target.value)} 
                               placeholder="dd/mm/aa" 
                               type=''
                               disabled={disabled}
                               role='rowGroupDetailQuotationExpiration'
                               />
                </div>
            </div>
        </div>
    )
}

