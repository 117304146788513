import { collection, getDocs, query, where } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";
import { ReceiptInterface } from "../interfaces/ReceiptInterface";

// method to get receipt from user
export const GetReceiptFromUser = async(userId: string): Promise<ReceiptInterface[]> => {
    try {
        const q = query(collection(DB, "receipt"),  where("userId", "==", userId))
        const response = await getDocs(q);
        let data:ReceiptInterface[] = [];
        if(!response.empty) {
            data = response.docs.map(doc => ({id: doc.id, ...doc.data()} as ReceiptInterface))
        }
        return data;
    } catch (error) {
        console.log("🚀 ~ GetReceiptFromUser ~ error:", error);
        throw new Error("Error al obtener los recibos");
    }
}