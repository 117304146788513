import axios from "axios";

import { AIRPORT_URL } from "../Consts/BaseUrl";

export const GetAirportLabel = async (label: string) => {
    try {
        const response = await axios.get(`${AIRPORT_URL}${label}`);
        // console.log("🚀 ~ file: Port.service.ts ~ line 7 ~ GetPortByLabel ~ response", response.data)
        return response.data;
    } catch (error) {
        console.log("🚀 ~ file: Airport.service.ts ~ line 11 ~ GetAirportLabel ~ error", error)
        return error;
    }
}