import React, { useEffect, useState } from "react";

/** importando componentes */
import { Modal } from "../Modal/Modal";
import { ButtonIcon } from "../..";
import { TextField } from "../..";
import { Textarea } from "../..";
import { Button } from "../..";
import { Calendar as CalendarMain } from "../../Calendar/Calendar";
import { SelectCategoryEvent } from "../../SelectCategoryEvent/SelectCategoryEvent";
import { ProfileDetail } from "../../ProfileDetail/ProfileDetail";
import { Checkbox } from "../../Checkbox/Checkbox";

/** importando iconos */
import { Calendar } from "../../../icons/outline/Calendar";
import { ArrowDown } from "../../../icons/outline/ArrowDown";

import { Event } from "../../../interfaces/Event";
import { CategoryEvent } from "../../../interfaces/Event";

/** importando estilos */
import './ModalAddEvent.css';
import { format } from "date-fns";
import { TranslateMonth } from "../../../utils/translateMonth";
import { User } from "../../../interfaces/User";

interface ModalAddEventProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    newEvent: Event;
    setNewEvent: React.Dispatch<React.SetStateAction<Event>>;
    newCategory: CategoryEvent;
    setNewCategory: React.Dispatch<React.SetStateAction<CategoryEvent>>;
    userList: User[]; 
}

export const ModalAddEvent: React.FC<ModalAddEventProps> = ({
    show,
    setShow,
    newEvent,
    setNewEvent,
    newCategory,
    setNewCategory,
    userList
}) => {

    const [showCalendar, setShowCalendar] = useState(false);
    const [showAddCategory, setShowAddCategory] = useState(false);
    const [showAddPerson, setShowAddPerson] = useState(false);
    const [checkContact, setCheckContact] = useState(false);

    const handleDateChange = (e: Date) => {
        setNewEvent((prev) => {
            return {
                ...prev,
                date: e
            }
        });
    }

    const handleStartHourChange = (e: Date) => {
        setNewEvent((prev) => {
            return {
                ...prev,
                startHour: e
            }
        })
    } 

    const handleEndHourChange = (e: Date) => {
        setNewEvent((prev) => {
            return {
                ...prev,
                endHour: e
            }
        })
    }

    const handleDetailChange = (e: string) => {
        setNewEvent((prev) => {
            return {
                ...prev,
                detail: e
            }
        })
    }

    const clearField = ()=>{
        setShowCalendar(false);
        setShowAddCategory(false);
        setShowAddPerson(false);
    }

    useEffect(()=>{
        if(!show) {
            clearField();
        }
    },[show])

    return (
       <div role='modalAddEventRole' className="modalAddEvent">
            <Modal title="Agregar un evento" show={show} setShow={setShow} >
            <div className="modalAddEvent-container">
                <div className="modalAddEvent-row_">
                   <div className="modalAddEvent-row">
                        <Calendar className="modalAddEvent-icon" /> 
                        <div className="modalAddEvent-calendar-container">
                                <ButtonIcon Icon={<ArrowDown />} 
                                    content={newEvent.date?format(newEvent.date, 'd') + " "+(TranslateMonth(format(newEvent.date, 'LLLL')))?.substring(0,3)+", "+
                                    format(newEvent.date, 'yyy'): 'Fecha'} color="black-05" place="right" size="large" onClick={()=>setShowCalendar(!showCalendar)} />
                                {showCalendar && 
                                <div className="modaAddEvent-calendar">
                                    <CalendarMain setDay={(e: any)=>{handleDateChange(e); setShowCalendar(false)}} />
                            </div>}
                        </div>

                   </div>
                    <div className="modalAddEvent-textField">
                        <TextField role='startHourRole' title="Empieza" placeholder=" " type='time' onChange={(e: any)=>{handleStartHourChange(e.target.value)}} />
                        <TextField  role='endHourRole' title="Termina" placeholder=" " type='time' onChange={(e: any)=>{handleEndHourChange(e.target.value)} }/>
                    </div>
                </div>

                <div className="modalAddEvent-row"> 
                    <Textarea role='detailRole' title="Agrega información del evento" placeholder=" " onChange={(e: any)=>{handleDetailChange(e.target.value)}}/>
                </div>

                <div className="modalAddEvent-row">
                    <SelectCategoryEvent newCategory={newCategory} setNewCategory={setNewCategory} show={showAddCategory} setShow={setShowAddCategory} service={['']} tag={[]} />
                </div>

                <div className="smalltext-header modalAddEvent-add-person" onClick={()=>setShowAddPerson(true)}>+ Añadir personas</div>
                 {
                     showAddPerson && 
                     <div className="modalAddEvent-list-add-person">
                        <div className="paragraph-header modalAddEvent-add-person-title">DeChinaAPeru.com</div>
                        <div className="modalAddEvent-content-list-add-person__">
                            { userList.map((u, idx) => (
                                <div key={idx} className="modalAddEvent-content-list-add-person">
                                    <Checkbox setChecked={()=>{}}/>
                                    <ProfileDetail user={u}/>
                                </div>
                            ))}
                        </div>
                     </div>
                 }


                <div className="modalAddEvent-row modalAddEvent-button">
                    <Button content="Cancelar" color="black-25" size="large" onClick={()=>setShow(false)}/>
                    <Button content="Agregar" color="black-75" size="large" onClick={()=>setShow(false)}/>
                </div>
            </div>
        </Modal>
       </div>
    )
}
