import { configureStore } from '@reduxjs/toolkit'

// import logger from 'redux-logger'

import AuthReducer from '../Slices/Auth'
import ErrorReducer from '../Slices/Error'
import ServiceSlice from '../Slices/Service'
import ServiceType from '../Slices/ServiceType'
import RequestQuote from '../Slices/RequestQuote'
import Order from '../Slices/Order'
import Operation from '../Slices/Operation'
import Quotation from '../Slices/Quotation'
import Company from '../Slices/Company'
import User from '../Slices/User'
import Review from '../Slices/Review'
import Consignee from '../Slices/Consignee'
import HistoryLocation from '../Slices/HistoryLocation'
import Plan from '../Slices/Plan';
import Subscription from '../Slices/Subscription';
import PlanDetail from '../Slices/PlanDetail';

const reducer = {
  auth: AuthReducer,
  user: User,
  service: ServiceSlice,
  serviceType: ServiceType,
  requestQuote: RequestQuote,
  order: Order, 
  operation: Operation,
  company: Company,
  historyLocation: HistoryLocation,
  quotation: Quotation,
  review: Review,
  consignee: Consignee,
  plan: Plan,
  subscription: Subscription,
  planDetail: PlanDetail,
  error: ErrorReducer
}
const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}), //.concat(logger),
  devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;