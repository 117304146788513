import React, { useEffect, useRef } from "react";

import './Modal.css';
import { STORAGE_ASSETS } from "../../../Consts/BaseUrl";

interface ModalProps {
    title?: string;
    subTitle?: string;
    icon?: React.ReactNode;
    show: boolean; 
    setShow: (e:boolean) => void;
    children?: React.ReactNode;
    role?:string
    className?: string;
    onClose?: ()=>void;
}

export const Modal: React.FC<ModalProps> = ({
    title,
    subTitle,
    icon,
    show,
    setShow,
    children,
    role,
    className='',
    onClose = ()=>{},
}) => {
    
    const refModalAnimation = useRef<HTMLDivElement>(null);


    useEffect(()=>{
        show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    //    window.document.body.style.overflow =  show ?  'hidden' : 'unset';
    //    const handleClickOutside = (event: any) => {
    //     if (ref.current && !ref.current.contains(event.target)) {
    //         console.log("click")
    //         setShow(false);
    //     }
    //   };
    //   document.addEventListener('click', handleClickOutside, true);
    //   return () => {
    //     document.removeEventListener('click', handleClickOutside, true);
    //   };
    },[show]);


    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (refModalAnimation.current && !refModalAnimation.current.contains(event.target)) {
                // console.log("click")
                setShow(false);
            }
          };
          document.addEventListener('click', handleClickOutside, true);
          return () => {
            document.removeEventListener('click', handleClickOutside, true);
          };
    }, []);

    return (
        <>
        {/* {!disableToast&& <ToastContainer hideProgressBar limit={5} position='top-right'  autoClose={3500} pauseOnFocusLoss={false} pauseOnHover={false} />} */}
            {show &&
            <div className={"modal-main " + className} role={role}>
                <div ref={refModalAnimation} className="modal-container">
                    <div className="modal__header">
                            <div className="modal__heade-left">
                                {icon && <div className="modal-logo">
                                    {icon}
                                </div>}
                                <div className="modal-header__text">
                                    <div className={subTitle?'paragraph-header modal-title':"small-bold "}>{title}</div>
                                    {subTitle && <div className="paragraph modal-subtitle">{subTitle}</div>}
                                </div>
                            </div>
                        <img src={STORAGE_ASSETS+'/images/svg/close_big.svg'} style={{cursor:'pointer'}} alt="" onClick={()=>{onClose(); setShow(false)}} role='modal-btn'/>
                    </div>
                    {children}
                </div>   
            </div>}  
        </>
    )
}