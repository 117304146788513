import React, { useEffect, useState } from "react";
import { ErrorInterface } from "../../interfaces/ErrorInterface";

import "./InputElementMin.css"

interface inputInterface {
  name: string;
  value: number | undefined;
  disable?: boolean;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  title?: string;
  placeholder?: string;
  error?: ErrorInterface
  decimal?: number
}

export const InputElementMin: React.FC<inputInterface> = ({
  name,
  value=0.00,
  onChange,
  onBlur,
  disable = false,
  placeholder = "0.00",
  title,
  decimal=2,
  error={
    status: false,
    message: ''
  }
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [disabledClass, setDisabledClass] = useState<string>('');


  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const toCurrency = (number: number) => {
    const formatter = new Intl.NumberFormat("en", {
      style: "decimal",
      currency: "USD",
      minimumFractionDigits: 2
    });

    return number && formatter.format(number) !== "0"
      ? formatter.format(number)
      : undefined;
  };

  const handleChange = (e: any) => {
    let newValue = Number(e.target.value);
    if (newValue >= 0) {
      onChange(newValue);
    }
  };

  const handleOnBlur = (e: any) => {
    toggleEditing();
    if (Number(e.target.value) > 0) {
      onChange(Number(e.target.value).toFixed(decimal));
      onBlur && onBlur(Number(e.target.value).toFixed(decimal));
    }
  };

  useEffect(()=> { 
    if (disable) {
      setDisabledClass("inputElement-disabled");
    }
  },[])

  return (
    <div className="inputElementMin-container">
      <div className={`inputElementMin ${error.status ? 'inputElementMin-input-error' : ''}`}>
        {title && title.length> 0 &&<label className={"paragraph-header inputElementMin-focus"} htmlFor={name}>{title}</label>}
        <input
          className={`inputElementMin-input ${disabledClass} ${title && title.length>0 ? "inputElementMin-input-title" : ""}`}
          type={isEditing?"number":"text"}
          name={name}
          value={isEditing ? (value===0?undefined:value) : toCurrency(Number(value))}
          onFocus={() => isEditing ? {} : toggleEditing()}
          onChange={(e:any) => isEditing ? handleChange(e) : {}}
          onBlur={(e:any) => isEditing ? handleOnBlur(e) : {}}
          placeholder={placeholder}
          disabled={disable}
        />

        {/* {isEditing ? (
          <input
            className={`inputElement-input ${disabledClass} `}
            type="number"
            name={name}
            value={value===0?undefined:value}
            disabled={disable}
            onChange={(e:any) => handleChange(e)}
            onBlur={(e:any) => handleOnBlur(e)}
          />
        ) : (
          <input
            className={`inputElement-input ${disabledClass} `}
            type="text"
            name={name}
            value={toCurrency(Number(value))}
            onFocus={() => toggleEditing()}
            // readOnly
            disabled={disable}
            placeholder={placeholder}
          />
        )} */}
      </div>
      {(error.status ||  !(value && value>0)) ? <div className="textfield-error tinytext-header">{error.message}</div>: null}
    </div>
  );
};