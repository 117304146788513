import React, { ForwardedRef, useEffect, useRef } from "react";

/** importando estilos */
import './UserVerify.css';
import { VerifyBlack } from "../../icons/solid/VerifyBlack";
import { VerifyBlue } from "../../icons/solid/Verify_blue";
import { Calendar } from "../../icons/outline/Calendar";
import { Button } from "../Button/Button";

interface UserVerifyProps {
    showVerify?: boolean;
    description?: number;
    close?: ()=>void;
    title?:string; 
    setShowVerify: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserVerify: React.FC<UserVerifyProps> = ({
    showVerify = false,
    description = 1, 
    close = ()=>{},
    title="",
    setShowVerify = ()=>{},
}) => {

    const ref = useRef<HTMLDivElement>(null);
    const [showMore, setShowMore] = React.useState(false);

    // const handleSendWsp = () => {
    //     window.open(`https://api.whatsapp.com/send/?phone=51905431863&text=¡Hola! Me gustaría obtener mi verificación`)
    // } 

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                // console.log("click")
                setShowVerify(false);
            }
          };
          document.addEventListener('click', handleClickOutside, true);
          return () => {
            document.removeEventListener('click', handleClickOutside, true);
          };
    }, []);

    return (
   <div className={`${showVerify && "userVerify-active" } userVerify-container` }>
        <div ref={ref} className="userVerify-content" >
            <div className="userVerify-line" onClick={()=>setShowVerify(false)}></div>
            <div className="userVerify-content-header">
                <div className="userVerify-title paragraph-header">{title}</div>
            </div>

            <div className="userVerify-description">
                <VerifyBlack />
                {!showMore && <div className="paragraph userVerify-description-title">Está cuenta está verificada por Arkabia. 
                    <b className="userVerify-more" onClick={()=>setShowMore(!showMore)}> Ver más</b>
                </div>}
                {showMore && <div className="paragraph userVerify-description-title">
                Está cuenta está verificada por Arkabia, cuenta con permisos y 
                licencias de operación requeridos por su país.
                    <b className="userVerify-more" onClick={()=>setShowMore(false)}> Ver menos</b>
                </div>}
            </div>
        </div>
    </div>
    )
} 