import React, { useEffect, useState } from 'react';

import './CardHoverExpand.css';
import { STORAGE_ASSETS } from '../../Consts/BaseUrl';
import { CardHoverExpandInterface } from '../../interfaces/CardHoverExpandInterface';

interface CardHoverExpandProps {
    itemsHover: CardHoverExpandInterface;
}

export const CardHoverExpand: React.FC<CardHoverExpandProps> = ({
    itemsHover
}) => {

    useEffect(() => {
        // Selecciona todos los elementos con la clase 'cardHoverExpand'
        const cards = document.querySelectorAll('.cardHoverExpand');
        
        // Elimina la clase 'active' de todos los elementos
        cards.forEach(card => card.classList.remove('active'));
        
        // Aplica la clase 'active' al elemento con el índice deseado (por ejemplo, el primero)
        if (cards.length > 0 && cards[0]) {
            cards[0].classList.add('active'); // Cambia el índice según sea necesario
        }

        // Agrega manejadores de eventos para el hover
        cards.forEach(card => {
            card.addEventListener('mouseenter', () => {
                // Elimina la clase 'active' de todos los elementos
                cards.forEach(c => c.classList.remove('active'));
                // Agrega la clase 'active' al elemento actual
                card.classList.add('active');
            });
        });

        // Limpia los manejadores de eventos al desmontar el componente
        return () => {
            cards.forEach(card => {
                card.removeEventListener('mouseenter', () => {
                    cards.forEach(c => c.classList.remove('active'));
                    card.classList.add('active');
                });
            });
        };
    }, []); // El array vacío asegura que esto se ejecute solo una vez al montar el componente


    return (
        <div className=
        {window.innerWidth > 1045 ? 
            (`cardHoverExpand ${itemsHover.id === 1 ? 'cardHoverExpand-1' : 'cardHoverExpand-2'}`) : 
            (`cardHoverExpand-mobile ${itemsHover.id === 1 ? "cardHoverExpand-mobile-1":"cardHoverExpand-mobile"}`)}>
            <div className='cardHoverExpand-left'>
                <div className='small-header cardHoverExpand-title'>
                    {itemsHover.title}
                </div>
                <div className='paragraph cardHoverExpand-title'>
                    {itemsHover.description}
                </div>

                <div className='paragraph cardHoverExpand-list'>
                    {itemsHover.list.map((item, index) => (
                        <div className='cardHoverExpand-item'>
                            <img 
                                className='cardHoverExpand-img' 
                                src={STORAGE_ASSETS+'/images/png/'+item.img}
                                alt=''
                            />
                            <div className='paragraph cardHoverExpand-text'>{item.item}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='cardHoverExpand-right'>
                <img src={STORAGE_ASSETS+'/images/png/'+itemsHover.img} alt={itemsHover.alt}/>
            </div>
        </div>
  );
};