import React, { useEffect, useRef, useState} from "react";

import ReactGA from "react-ga4";

/** import components */
import { Order } from "../../../icons/solid/Order";
import { Filter } from "../../../icons/solid/Filter";

/** import styles */
import './FilterSelectorExtended.css';
import { ServiceTypeInterface } from "../../../interfaces/Service";
import { UpBlack } from "../../../icons/solid/UpBlack";
import { ServicesOptions } from "../../Services/ServicesOptions/ServicesOptions";
import { SearchTextField } from "../../SearchTextField/SearchTextField";
import { set } from "date-fns";
import { FilterWhite } from "../../../icons/solid/FilterWhite";
import { OrderWhite } from "../../../icons/solid/OrderWhite";
import { Up } from "../../../icons/solid/Up";



/** Interface props */
interface FilterSelectorExtendedProps {
    style?: any; // style to will be applied to online block
    relevanceFilter: string[]; 
    category: ServiceTypeInterface[]; 
    handleKeyboardSelected: (value: string) => void;
    handleSelectRelevanceItem: (value: string) => void;
    handleCategorySelected: (e: any) => void;
    categoryId?: string;
    titleFilterOne: string;
    titleFilterTwo: string;

    // ServicesOptionsDataDropdown: string[];
    // valueCategory?: string;
    // setValueCategory?: React.Dispatch<React.SetStateAction<string>>;
}


/** Component to rendered filters for desktop */
export const FilterSelectorExtended: React.FC<FilterSelectorExtendedProps> = ({
    style,
    relevanceFilter=[],
    handleKeyboardSelected= () => {},
    handleSelectRelevanceItem = () => {},
    handleCategorySelected = () => {},
    category=[],
    categoryId = '',
    titleFilterOne,
    titleFilterTwo,
    // ServicesOptionsDataDropdown,
    // valueCategory = '',
    // setValueCategory = () => {}
}) => {

    const [showRelevance, setShowRelevance] = useState(false);
    const [showKeyboards, setShowKeyboards] = useState(false);
    const [showCategory, setShowCategory] = useState(false);

    const [selectOne, setSelectOne] = useState(false); // item selected in one filter
    // const [selectTwo, setSelectOTwo] = useState(false); // item selected in one filter

    // const [relevanceLabel, setRelevanceLabel] = useState<string>('Relevancia');
    // const [keyboardLabel, setKeyboardLabel] = useState<string>('Industria');
    const [itemOneSelected, setItemOneSelected] = useState<string>('Todos'); // item selected in one filter
    // const [itemTwoSelected, setItemTwoSelected] = useState<string>(''); // item selected in two filter
    // const [categoryLabel, setCategoryLabel] = useState<string>('Agenciamiento de aduana');
    // const [categoryImg, setCategoryImg] = useState<string>('https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Ficon%2Fagenciamiento-aduana.svg?alt=media&token=b4989f72-552b-4fb8-8472-23fa9c3b723b');
    
    const ref = useRef<HTMLDivElement>(null);

    const handleItemClick = (item: string) => {
        handleSelectRelevanceItem(itemOneSelected === item ? '': item);

        setItemOneSelected(item === itemOneSelected ? titleFilterOne: item);
        // setRelevanceLabel(relevanceLabel === item ? 'Relevancia': item);

        setSelectOne(item === itemOneSelected ? false : true)

        GA4_filter('Order', item);

        setShowRelevance(false);
    }

    // const handleKeyboardClick = (item: string) => {
    //     setItemTwoSelected(item === itemTwoSelected ? titleFilterTwo: item);
    //     handleKeyboardSelected(keyboardLabel === item ? '' : item);
    //     setKeyboardLabel(keyboardLabel === item ? 'Industria': item);
    //     setSelectOTwo(item === itemTwoSelected ? false : true)

    //     GA4_filter('business category', item);

    //     setShowKeyboards(false);
    // }

    const handleCategoryClick = (item: string, name: string, img: string) => {
        GA4_filter('Category', name);
        handleCategorySelected(item);
        // setCategoryLabel(name);
        // setCategoryImg(img);
        setShowCategory(false);
    }

    const handleShowRelevanceClick = () => {
        setShowRelevance(!showRelevance);
        setShowKeyboards(false);
        setShowCategory(false);
    }

    // const handleShowKeyboardsClick = () => {
    //     setShowKeyboards(!showKeyboards);
    //     setShowRelevance(false);
    //     setShowCategory(false);
    // }

    const handleShowCategoryClick = () => {
        setShowCategory(!showCategory);
        setShowKeyboards(false);
        setShowRelevance(false);

    }

    // const getCategoryImage = ():string=> {
    //     return category.find((c: any) => c.id === categoryId)?.urlIcon || 'Categoría';
    // }

    //se busca la categoria seleccionada desde el padre para que se vea reflejado en el filtro
    const getCategoryLabel = ():string=> {
        return category.find((c: any) => c.id === categoryId)?.name || 'Categoría';
    }

    // method to send filter order event to GA4
    const GA4_filter = (category: string, filter: string,) => {
        // Send a custom event
        ReactGA.event({
        category: category,
        action: "Filter",
        label: filter, // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
        });
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                // console.log("click")
                setShowKeyboards(false);
                setShowRelevance(false);
            }
          };
          document.addEventListener('click', handleClickOutside, true);
          return () => {
            document.removeEventListener('click', handleClickOutside, true);
          };
    }, []);

    useEffect(() => {
        if(titleFilterOne!== itemOneSelected) {
            setItemOneSelected(titleFilterOne);
            setSelectOne(false);
        }
     
        // setItemTwoSelected(titleFilterTwo);
    }, [titleFilterOne, titleFilterTwo]);

    
    return (
        <div className='filterSelectorExtended'>
            <div className="filterSelectorExtended-item-content-dropdown filterSelectorExtended-categorie">
                <div className='filterSelectorExtended-item  paragraph-header' onClick={()=>handleShowCategoryClick()}>
                    {/* <img src={getCategoryImage()}/> */}
                    <span style={{color:'var(--gray-1)'}} className='paragraph filterSelectorExtended-text-filter'> 
                    {getCategoryLabel()}
                    </span>  

                    <UpBlack/>
                </div>
            
                { showCategory && 
                    <div className="filterSelectorExtended--options">
                        {
                            category.map((c:any, idx)=> (
                                !c.hover && 
                                <div 
                                    key={idx} 
                                    className={`filterSelectorExtended--options-item paragraph ${categoryId === c.id ? 'filterSelectorExtended--options-background_selected' : 
                                            'filterSelectorExtended--options-background'}`} 
                                            onClick={()=> handleCategoryClick(c.id, c.name, c.urlIcon)}>
                                        <img src={c.urlIcon}/>      
                                        {c.name}
                                </div>
                        ))}
                    </div>
                }
            </div> 

            <div ref={ref} role='filterSelectorExtendedRole' style={style} className='smalltext-header filterSelectorExtended-main'>
                {itemOneSelected.length>0 && 
                <div className={"filterSelectorExtended-item-content-dropdown"}>
                     Ordenar por
                    <div className={`smalltext-header ${selectOne ? "filterSelectorExtended-item-selected" : "filterSelectorExtended-item"}`} 
                         onClick={()=>handleShowRelevanceClick()}>
                        <span style={{color: selectOne ? 'var(--white)' : 'var(--gray-1)'}} className='smalltext-header filterSelectorExtended-text-filter'> 
                            {itemOneSelected} 
                        </span>  
                        {selectOne ? <Up/> : <UpBlack/>}
                    </div>
                    { showRelevance && 
                        <div className="filterSelectorExtended--options">
                            {
                                relevanceFilter.map((r, idx)=> (
                                    <div 
                                        key={idx} 
                                        id="filterSelectorExtended--order"
                                        className={`filterSelectorExtended--options-item paragraph ${itemOneSelected === r ? 'filterSelectorExtended--options-background_selected' : 
                                            'filterSelectorExtended--options-background'}`}
                                        onClick={()=>handleItemClick(r)}>{r}</div>
                            ))}
                        </div>
                    }
                </div>}
                
                {/* {isCourier ? itemTwoSelected.length>0 && 
                <div className="filterSelectorExtended-item-content-dropdown">
                    <div className={`"filterSelectorExtended-item  paragraph-header" ${selectTwo ? "filterSelectorExtended-item-selected" : "filterSelectorExtended-item"}`} onClick={()=>handleShowKeyboardsClick()}>
                        {selectTwo ? <FilterWhite/> : <Filter/>}
                        <span style={{color: selectTwo ? 'var(--white)' : 'var(--gray-1)'}} className='paragraph filterSelectorExtended-text-filter'>
                            {itemTwoSelected}  
                        </span>  
                    </div>
                    { showKeyboards && 
                        <div className={ "filterSelectorExtended--options-keyboards"}>
                            {/* <ServicesOptions
                                servicesOptions={servicesOptions}
                                mobile={window.innerWidth < 768}
                                ServicesOptionsDataDropdown={ServicesOptionsDataDropdown}
                                setTextSearch={setTextSearch}
                                serviceTypeActive={serviceTypeActive}
                                onClick={onClick}
                                title="Buscar"
                            /> // <--volver a comentar
                            {search && <SearchTextField 
                                darkIcon 
                                title="Buscar" 
                                setTextSearch={(e)=>setTextSearch(e)} 
                            />}
                            
                            <div className={search ? "filterSelectorExtended--options-content" : "filterSelectorExtended--options-keyboards--none-search"}>
                                {keyboardsFilter.map((r, idx)=> (
                                <div 
                                    key={idx}
                                    id="filterSelectorExtended--filter"
                                    className={`filterSelectorExtended--options-item paragraph ${keyboardLabel === r ? 'filterSelectorExtended--options-background_selected' : 
                                        'filterSelectorExtended--options-background'}`}
                                    onClick={()=>handleKeyboardClick(r)}>{r}</div>
                                ))}
                            </div>
                        </div>
                    }     
                </div> 
                : null}  */}
            </div>
        </div>
    )
}