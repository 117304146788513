import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import "./Billing.css";
import { Edit } from "../../icons/outline/Edit";
import { TextField } from "../TextField/TextField";
import { Dropdown } from "../Dropdown/Dropdown";
import { CountryDropdown } from "../CountryDropdown/CountryDropdown";
import { CardContent } from "../CardContent/CardContent";
import { Memo } from "../../icons/solid/Memo";
import { BillingInterface } from "../../interfaces/BillingInterface";
import { TypePersons } from "../../Consts/TypePersons";
import { GetCountryList } from "../../Services/Country";
import { TypeDocuments } from "../../Consts/TypeDocuments";
import { BillingErrorInterface } from "../../interfaces/BillingErrorInterface";
import debounce from "lodash.debounce";
import { ValidateField } from "../../utils/ValidateField";

interface BillingProps {
    billingData: BillingInterface ;
    setBillingData: React.Dispatch<React.SetStateAction<BillingInterface>>
}

export const Billing = forwardRef((props: BillingProps, ref) => {

    const [countryList, setCountryList] = useState<{country: string; alpha2Code: string, phoneCode: string }[]>([]);

    // useState billing error
    const [billingError, setBillingError] = useState<BillingErrorInterface>({
        typeIt: {
            status: false,
            message: "",
        },
        itNumber: {
            status: false,
            message: "",
        },
        businessName: {
            status: false,
            message: "",
        },
        country: {
            status: false,
            message: "",
        },
        direction: {
            status: false,
            message: "",
        },
        identityType: {
            status: false,
            message: "",
        },
        name: {
            status: false,
            message: "",
        },
        lastName: {
            status: false,
            message: "",
        },
    });

    const validateBillingFields = (): boolean => {
        let error = false;
        error = ValidateField(props.billingData.typeInvoice, "required") || ValidateField(props.billingData.country, "required");
        
        setBillingError((prev) => ({...prev,
            typeIt: {
                status: ValidateField(props.billingData.typeInvoice, "required"),
                message: "Seleccione un tipo de persona"
            },
            country: {
                status: ValidateField(props.billingData.country, "required"),
                message: "Seleccione un país",
            },
          
        }));


        if(props.billingData.typeInvoice === "Factura") {
            error = error || (ValidateField(props.billingData.businessName, "required") || ValidateField(props.billingData.itNumber, "number"));
            setBillingError((prev) => ({...prev,
                businessName: {
                    status: ValidateField(props.billingData.businessName, "required"),
                    message: "Ingrese un nombre de empresa"
                },
                itNumber: {
                    status: ValidateField(props.billingData.itNumber, "number"),
                    message: "Ingrese un número de RUC válido",
                },
                direction: {
                    status: ValidateField(props.billingData.direction, "required"),
                    message: "Ingrese una dirección",
                },
            }));
        } else {
            error = error || (ValidateField(props.billingData.name, "required") || ValidateField(props.billingData.lastName, "required") || 
            ValidateField(props.billingData.identityType, "required") || ValidateField(props.billingData.itNumber, props.billingData.identityType === "DNI" ? "DNI" : 
            props.billingData.identityType === "RUC" ? "RUC" : "required"));

            setBillingError((prev) => ({...prev,
                name: {
                    status: ValidateField(props.billingData.name, "required"),
                    message: "Ingrese un nombre"
                },
                lastName: {
                    status: ValidateField(props.billingData.lastName, "required"),
                    message: "Ingrese un apellido",
                },
                identityType: {
                    status: ValidateField(props.billingData.identityType, "required"),
                    message: "Seleccione un tipo de documento",
                },
                itNumber: {
                    status: ValidateField(props.billingData.itNumber, props.billingData.identityType === "DNI" ? "DNI" : 
                    props.billingData.identityType === "RUC" ? "RUC" : "required"),
                    message: "Ingrese un número de "+props.billingData.identityType+" válido",
                },
            }));
        }
        return error;
    }

    const handleTypeItChange = useCallback((value: "Factura" | "Boleta") => {
        props.setBillingData((prev) => ({
            ...prev,
            typeInvoice: value,
        }));
    }, []);

    const handleBusinessNameChange = useCallback( (value: string) => {
        props.setBillingData((prev) => ({
            ...prev,
            businessName: value
        }));
    }, []);

    const handleItNumberChange = (value: number) => {
        props.setBillingData((prev) => ({
            ...prev,
            itNumber: value
        }));
    }

    const handleDirectionChange =  useCallback((value: string)=> {
        props.setBillingData((prev) => ({
            ...prev,
            direction: value
        }));
    }, []);
    

    const handleNameChange = (value: string) => {
        props.setBillingData((prev) => ({
            ...prev,
            name: value
        }));
    }

    const handleLastNameChange = (value: string) => {
        props.setBillingData((prev) => ({
            ...prev,
            lastName: value
        }));
    }

    const handleIdentityTypeChange = (value: string) => {
        props.setBillingData((prev) => ({
            ...prev,
            identityType: value
        }));
    }

    const handleCountryChange = (country: string, alpha2Code: string) => {
        props.setBillingData((prev) => ({
            ...prev,
            country: country,
            countryAlpha2Code: alpha2Code,
            countryFlag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${alpha2Code.toLowerCase()}.svg`,
        }));        
    }

    // obteniendo lista de paises
    const getCountries = async () => {
        try {
            const response: any = await GetCountryList();
            setCountryList(response);
        } catch (error) {
            console.log("🚀 ~ file: SupplierInvoceTranslation.tsx:65 ~ getCountries ~ error", error);
        }
    }

    useImperativeHandle(ref, () => ({
        validateBillingFields,
    }));

    useEffect(() => {
        getCountries();
    }, []);

    return ( 
        <div className="billing-content">
             <div className="quotationPermitsComponent-title paragraph-header">
                <Memo/>
                Facturación
            </div>

            <div className="paragraph">
                Tu factura se emitirá de acuerdo a los datos registrados aquí.
            </div>

            <CardContent className="billing-cardContent">
                <div className="billing-cardContent-container">
                <Dropdown
                    title="Comprobante de pago"
                    value={props.billingData.typeInvoice}
                    elements={["Boleta", "Factura"]}
                    setValue={(e: any)=>handleTypeItChange(e)}
                    //setValue={(e:any) =>{setConsigneeSelected(e)}}
                    setIdx={()=>{}}
                    disabled={false}
                    error={billingError.typeIt}
                />

                <div className="billing-row">
                    {countryList.length>0 && <CountryDropdown 
                        title='País'    
                        country={
                            {
                                alpha2Code: props.billingData.countryAlpha2Code,  // fecha de creacion del pais
                                imgFlag: props.billingData.countryFlag, // imagen de la bandera del pais
                                name: props.billingData.country 
                            }
                        }
                        countryList={countryList}
                        handleCountryChange={handleCountryChange}
                    />}

                    { props.billingData.typeInvoice === "Boleta" ? <>
                    <TextField
                        title="Nombre"
                        placeholder=" "
                        value={props.billingData.name}
                        onChange={(e)=>handleNameChange(e.target.value)}
                        error={billingError.name}
                        disable={false}
                        
                    /> 
                    <TextField
                        title="Apellido"
                        placeholder=" "
                        value={props.billingData.lastName}
                        onChange={(e)=>handleLastNameChange(e.target.value)}
                        error={billingError.lastName}
                        disable={false}
                    /> 
                    </> : 
                    <TextField
                        title="Razón social"
                        placeholder=" "
                        value={props.billingData.businessName}
                        onChange={(e)=>handleBusinessNameChange(e.target.value)}
                        error={billingError.businessName}
                        disable={false}
                    /> }

                    {props.billingData.typeInvoice === "Factura" && <TextField
                        title='Número de RUC'
                        placeholder=" "
                        type="number"
                        value={props.billingData.itNumber === 0 ? '' : props.billingData.itNumber}
                        onChange={(e)=>handleItNumberChange(Number(e.target.value) || 0)}
                        error={billingError.itNumber}
                        disable={false}
                    />}
                </div>

                {props.billingData.typeInvoice === "Boleta" && <div className="billing-row">
                    <Dropdown
                        title="Tipo de documento"
                        value={props.billingData.identityType || "DNI"}
                        elements={TypeDocuments}
                        setValue={(e: any)=>handleIdentityTypeChange(e)}
                        //setValue={(e:any) =>{setConsigneeSelected(e)}}
                        setIdx={()=>{}}
                        disabled={false}
                        error={billingError.identityType}
                    />
                    <TextField
                        title={`Número de ${props.billingData.identityType || "DNI"}`}
                        placeholder=" "
                        type="number"
                        value={props.billingData.itNumber === 0 ? '' : props.billingData.itNumber}
                        onChange={(e)=>handleItNumberChange(Number(e.target.value) || 0)}
                        error={billingError.itNumber}
                        disable={false}
                    />
                   
                </div>}
                {props.billingData.typeInvoice === "Factura" && <div className="billing-row">
                    <TextField
                        title="Dirección fiscal"
                        placeholder=" "
                        type="text"
                        value={props.billingData.direction}
                        onChange={(e)=>handleDirectionChange(e.target.value)}
                        error={billingError.direction}
                    />
                </div>}
                </div>
            </CardContent>
        </div>
    );
  });