import React, { useEffect, useState } from "react";

import { TextField } from "../TextField/TextField";
import { Dropdown } from "../Dropdown/Dropdown";


import './SupplierInvoceTranslation.css';
import { GetCityByCountry, GetCountryList } from "../../Services/Country";
import { CountryDropdown } from "../CountryDropdown/CountryDropdown";
import { CountryInterface } from "../../interfaces/Country";
import { COUNTRY_FLAG_BASE } from "../../Consts/BaseUrl";
import { CityDropdown } from "../CityDropdown/CityDropdown";
import { NormalizeText } from "../../utils/normalizeText";

interface SupplierInvoceTranslationProps {
    idx: number;
    name: string;
    handleNameChange: (e: string, idx: number) => void;
    numFac: string;
    handleNumFacChange:  (e: string, idx: number) => void;
    country: CountryInterface;
    handleCountryChange:  (e: CountryInterface, idx: number) => void;
    city: string;
    handleCityChange:  (e: string, idx: number) => void;
    type: string;
    handleTypeChange:  (e: string, idx: number) => void;
    direction: string;
    handleDirecionChange:  (e: string, idx: number) => void;
    phone: string;
    handlePhoneChange:  (e: string, idx: number) => void;
    handleDeleteProvider: (idx: number) => void;
    typeSupplierList: string[];
    requiredAll?: boolean;
    disabled: boolean;
    countryList: {country: string; alpha2Code: string, phoneCode: string }[];
}

export const SupplierInvoceTranslation: React.FC<SupplierInvoceTranslationProps> = ( {
    idx,
    name,
    numFac,
    country,
    city,
    type,
    direction, 
    phone,
    typeSupplierList,
    requiredAll=false,
    handleNameChange,
    handleNumFacChange,
    handleCityChange,
    handleTypeChange,
    handleCountryChange,
    handleDirecionChange,
    handlePhoneChange,
    disabled=false,
    handleDeleteProvider,
    countryList,
}) => {

    const [cityList, setCityList] = useState<string[]>([]);

    
    const getCities = async (countryName: string) => {
        try {
            const response = await GetCityByCountry(countryName);
            // console.log("🚀 ~ file: SupplierInvoceTranslation.tsx:73 ~ getCities ~ response", response);
            setCityList(response.sort());
        } catch (error) {
            console.log("🚀 ~ file: SupplierInvoceTranslation.tsx:76 ~ getCities ~ error", error)
        }
    }

    const handleCountrySelect = async (country: string, alpha2Code: string) => {
        handleCountryChange({
            name: country,
            imgFlag: COUNTRY_FLAG_BASE+alpha2Code.toLocaleLowerCase()+".svg",
            alpha2Code: alpha2Code,
        }, idx);

        await getCities(NormalizeText(country));
    }

    return (
        <div role='supplierInvoceTranslationRole' className='supplierInvoceTranslation-main'>
            {/* <div className='supplierInvoceTranslation-bt supplierInvoceTranslation-text paragraph-header'>Proveedor {idx+1} {idx>0 && <div className="supplierInvoceTranslation-delete" onClick={()=>handleDeleteProvider(idx)}>Eliminar proveedor</div>}</div> */}
            <div className='supplierInvoceTranslation-row'>
                <TextField 
                    title='Razón social'
                    placeholder=' '
                    value={name}
                    onChange={(e: any)=>handleNameChange(e.target.value, idx)}
                    
                    required={requiredAll}
                    disabled={disabled}
                    role='nameSupplierInvoceTranslation'
                />
                <TextField 
                    title='Número de factura'
                    placeholder=' '
                    value={numFac}
                    onChange={(e: any) => handleNumFacChange(e.target.value, idx)}
                    required={requiredAll}
                    disabled={disabled}
                    role='numFacSupplierInvoceTranslation'
                />
            </div>
             <div className='supplierInvoceTranslation-row'>
                <div className='supplierInvoceTranslation-row-m'>
                    <CountryDropdown 
                        title='País'    
                        country={country}
                        countryList={countryList}
                        handleCountryChange={handleCountrySelect}
                        disable={disabled}
                    />
                    <TextField 
                        title='Ciudad'
                        placeholder=' '
                        value={city}
                        onChange={(e: any) => handleCityChange(e.target.value, idx)}
                        required={requiredAll}
                        disabled={disabled}
                        role=''
                    />
                    {/* <CityDropdown
                        title="Ciudad"
                        city={city}
                        cityList={cityList}
                        handleCityChange={(e)=>handleCityChange(e, idx)}
                    /> */}
                </div>
                {/* <Dropdown 
                    title='Ciudad'
                    elements={cityList}
                    setValue={(e: any)=> handleCityChange(e, idx)}
                    value={city}
                    required={requiredAll}
                    disabled={disabled}/> */}
                <Dropdown 
                    title='Tipo de proveedor'
                    elements={typeSupplierList}
                    setValue={(e: any)=> e !== type ? handleTypeChange(e, idx) : {}}
                    value={type}
                    required={requiredAll}
                    disabled={disabled}/>
            </div>
            <div className='supplierInvoceTranslation-row'>
                <TextField 
                    title='Domicilio del proveedor'
                    placeholder=' '
                    value={direction}
                    onChange={(e: any) => handleDirecionChange(e.target.value, idx)}
                    required={requiredAll}
                    disabled={disabled}
                    role='directionSupplierInvoceTranslation'
                />
                <TextField 
                    title='Teléfono'
                    placeholder=' '
                    value={phone}
                    onChange={(e: any) =>handlePhoneChange(e.target.value, idx)}
                    required={requiredAll}
                    disabled={disabled}
                    role='phoneSupplierInvoceTranslation'
                />
            </div>
        </div>
    )
}