import React, { useRef, useState, useEffect } from "react";

/** importando hooks react router */
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import { useDispatch, useSelector } from "react-redux";

import ReactGA from "react-ga4";

// store
import type { AppDispatch } from "../../../../Store/Store";

// slice
import { GetServiceById } from "../../../../Slices/Service";
import { GetUserByUID } from '../../../../Slices/User'
import { setHistoryLocation } from "../../../../Slices/HistoryLocation";

/** importando componentes */
import { Button, CompanyCard, FaqDisplay, Rating, CommentCount, CityCountryDisplay } from "../../../../components";
import { ServiceDisplay } from "../../../../components/ServiceDisplay/ServiceDisplay";
import { GobackButton } from "../../../../components/Button/GobackButton/GobackButton";
import { Loader } from "../../../../components/Loader/Loader";
import { CompanyCardResponsive } from "../../../../components/CompanyCardResponsive/CompanyCardResponsive";
import { ReviewDisplay } from "../../../../components/ReviewDisplay/ReviewDisplay";



/** importando tipos */
import { Phase } from "../../../../@types/servicePhaseTypes";

/** declarando interfaces */
import { ServiceInterface } from "../../../../interfaces/Service";
import { Company } from "../../../../interfaces/Company";
import { RequestQuoteInterface } from "../../../../interfaces/RequestQuote";

/** Importando url */
import { AUTH_LOGIN, AUTH_MODAL, CHAT } from "../../../../Consts/BaseUrl";

/** importando estilos */
import "./ServiceDetailPage.css";
import { toast } from "react-toastify";
import { CreateChatInterface } from "../../../../interfaces/ChatInterface";
import { CheckChatByCompanyAndUser, CreateNewChat } from "../../../../Services/Chat.service";
import { CardCategoryPlan } from "../../../../components/CardCategoryPlan/CardCategoryPlan";
import { TableComparePlan } from "../../../../components/TableComparePlan/TableComparePlan";
import { ModalProcessService } from "../../../../components/Modal/ModalProcessService/ModalProcessService";
import { ServicePlanInterface } from "../../../../interfaces/ServicesPlanInterface";
import { GetServicePlanById } from "../../../../Services/ServicePlan.service";
import { GetCompanyById } from "../../../../Services/Company.service";
import { ScrollHighlightNabbar } from "../../../../components/ScrollHighlightNabbar/ScrollHighlightNabbar";


interface ServiceDetailPageProps {
}

export const ServiceDetailPage: React.FC<ServiceDetailPageProps> = () => {
  /** obteniendo parametros de url */
  let navigation = useNavigate();
  const location = useLocation();

  const { isLoggedIn } = useSelector((state: any) => state.auth);

  const cardRef = useRef<HTMLInputElement>(null);

  let params = useParams();

  const serviceDetailRef = useRef<HTMLInputElement>(null);

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch<AppDispatch>();

  const {serviceList} = useSelector((state: any) => state.service);
  const {userDetail} = useSelector((state: any) => state.user);

  // useState user
  const [user, setUser] = useState(() => {
    const user = getAuth().currentUser;
    return user;
  });

  // useState Logeado
  const [sendRequest, setSendRequest] = useState<boolean>(true);

  // if search Provider
  const [isSupplierSearch, setIsSupplierSearch] = useState<boolean>(false);

  /** declarando useStates */
  const [divPhase, setDivPhase] = useState<Phase>("summary");

  // sections refs
  const summaryRef = useRef<HTMLDivElement>(null);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);

  // useState navBar header
  const [navHeader, setNavHeader] = useState<{
    headerID: string;
    headerRef: React.RefObject<HTMLDivElement>;
    headerTitle: string;
  }[]>([{
    headerID: 'order-display',
    headerRef: summaryRef,
    headerTitle: 'Resumen'
  }, {
    headerID: 'review-display',
    headerRef: reviewsRef,
    headerTitle: 'Reseñas'
  }, {
    headerID: "faq-display",
    headerRef: faqRef,
    headerTitle: 'Preguntas frecuentes'
  }
]);


  const [servicePlan, setServicePlan] = useState<ServicePlanInterface>();
  const [mostPopularPlanIdx, setMostPopularPlanIdx] = useState<number>(0);

  // useStateservicios
  const [serviceDetail, setServiceDetail] = useState<ServiceInterface>({
    id: '',
    active: false,
    basePrice: 0,
    city: '',
    companyId: '',
    companyName: '',
    country: {
      alpha2Code: '', 
      imgFlag: '',
      name: ''
    },
    createdAt: new Date(),
    description: '',
    faq: [],
    images: [],
    numComments: 0, 
    rating: 0,
    serviceType: '',
    serviceTypeLabel: '',
    status: 'published',
    title: '',
    updatedAt: new Date(),
    verify: false,
    numberVerify: 1
  });

  // useState de la compañia
  const [company, setCompany] = useState<Company>({
    aboutUs: '',
    catProviderName: '',
    city: '',
    country: {
      alpha2Code: '',
      imgFlag: '',
      name: ''
    },
    createdAt: new Date(),
    name: '',
    numComments: 0,
    numOperation: 0,
    photoCover: '',
    phone: '',
    photoProfile: '',
    rating: 0,
    serviceType: '',
    status: false,
    updatedAt: new Date(),
    userAdmin: '',
    reviewServiceId: '',
    direction: ''
  })

  // useState que maneja el loading
  const [loading, setLoading] = useState<boolean>(false);
  // const [showModalContractedService, setshowModalContractedService] = useState<boolean>(false);

  /** declarando useState */
  /** state cotizacion */
  const [requestQuote, setRequestQuote] = useState<RequestQuoteInterface>({
    title: "",
    number: 0,
    user: {
      id: '',
      name: '',
      typeIt: '',
      itNumber: 0,
      numOperations: 0,
      phone: '',
      rating: 0,
    },
    companyName: '',
    serviceId: '',
    serviceTypeId: '',
    serviceTypeLabel: '',
    status: 'draft',
    statusProvider: 'No leída',
    active: true,
    customs: {
      regime: 'Importación definitiva',
      incoterm: 'FOB',
      // value: undefined,
      coin: '',
      // insurance: undefined,
      merchandisePermits: {
        dataSheet: [],
        proforma: [],
        packingList: [],
      },
      exwDirection: '',
      descriptionOperation: '',
    },
    transport: {
      type: undefined,
      origin: {
        city: '',
        country: '',
      },
      destination: {
        city: '',
        country: '',
      },
      typeContainer: {
        lcl: false,
        fcl: false,
        lclAir: false
      },      
    },
    phaseDraft: 0,
    operationId: '',
    numServiceComments: 0,
    numServiceRating: 0,
    observation: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  /** useState del modal titulo de cotizacion */
  const [showModalQuote, setShowModalQuote] = useState<boolean>(false);

  /** useState completar datos de cotizacion */
  const [showQuoteImport, setShowQuoteImport] = useState<boolean>(false);
 
  /** useState modal se ha enviado su cotizacion */
  const [showThanks, setShowThanks] = useState(false);

  const [planIndex, setPlanIndex] = useState<number>(0);

  // const [activeSection, setActiveSection] = useState('');

  const handleCompareClick = () => {
    if(cardRef.current) {
     cardRef.current.scrollIntoView({behavior: 'smooth'});
     window.scrollTo(0, 800);
    }
  }
 
  // recupera la compañia segun id de firestore
  const getCompany =  async (id: string) => {
    if(id && id.length>0) {
      try {
        const response: Company = await GetCompanyById(id);
        if(response) {
          setCompany(response);
          setRequestQuote((prev)=>({
            ...prev,
            usersProvider: [response.userAdmin],
          }))
          setLoading(false);
        }
      } catch (error) {
        console.log("🚀 ~ getCompany ~ error:", error)
      }
    }
  }

  // recupera el servicio de redux segun id
  const  GetLocalServiceDetail = (id: string) => {
    return serviceList.find((s:ServiceInterface) => (s.id === id));
  }

  // recupera el servicio segun el id de fireStore
  const GetServiceDetail = (id: string) => {
    if(!loading) {
      setLoading(true);
      dispatch(GetServiceById(id))
      .unwrap()
      .then((response:any) => {
        if(response) {
          setServiceDetail(response);
          getCompany(response.companyId);
          response.serviceType === "busqueda-de-proveedor" && getServicePlanById(response.servicePlanId?response.servicePlanId:'');
          GA4_view_item(response);
        }
      })
      .catch((error) =>{
        console.log("error", error);
        setLoading(false)
      })
    }
  }
  
  // metodo para recuperar informacion del usuario
  const getUser = async (UID: string) => {
    setLoading(true)
    dispatch(GetUserByUID(UID))
    .unwrap()
    .then((response) => {
      if (!(response && response.userType) || (response.userType.includes("provider"))) {
        setSendRequest(false);
      }
        // console.log("🚀 ~ file: ProtectedRouter.tsx ~ line 46 ~ .then ~ response", response);
        setLoading(false);
    })
    .catch((error) => {
        console.log(error);
        setLoading(false);
    });
  }

  // method to check if chat exists
  const existChat = (companyId: string) => {
    if(!loading) {
      // setLoading(true);
      CheckChatByCompanyAndUser(companyId, userDetail.id)
        .then((responseCheck) => {
            if(responseCheck) {
                // console.log("existe chat", responseCheck)
                navigation("/"+ CHAT+"/?activeChat="+responseCheck.id);
            } else {
              createChatCompany(companyId)
            }
        })
    }
  }

  // method to create a new chat with company
  const createChatCompany = (companyId: string) => {
    // setLoading(true);
    const chatData: CreateChatInterface = {
      generatedFrom: "serviceDetail",
      typeChat: 'individual',
      userIdGenerated: userDetail.id,
      userTypeGenerated: userDetail.userType[0],
      recivedUserId: userDetail.id,
      receivedCompanyId: companyId,
      
    }
    // console.log("🚀 ~ file: ServiceDetailPage.tsx:353 ~ createChatCompany ~ chatData:", chatData)
    // console.log("🚀 ~ file: ServiceDetailPage.tsx:335 ~ createChatCompany ~ chatData:", chatData)
    CreateNewChat(chatData).then((response: any) => {
      // console.log("🚀 ~ file: ServiceDetailPage.tsx:353 ~ CreateNewChat ~ response:", response);
      // setLoading(false);
    navigation("/"+ CHAT+"/?activeChat="+response);

    }).catch((error) => {
      // setLoading(false);
      console.log("🚀 ~ file: ServiceDetailPage.tsx:340 ~ CreateNewChat ~ error:", error)
      toast.error("Hubo un error al creat Chat, por favor intentelo mas tarde");
    });
  }

  // handle send messages click
  const handleChat = async (companyId: string) => {
    // console.log("🚀 ~ handleChat ~ companyId:", companyId)
    if(user !== null) { // user loginIn
      existChat(companyId);
    } else { // user loginOut
      navigation(`?${AUTH_MODAL}=${AUTH_LOGIN}&noRedirect=true&serviceAction=openChat`);
    }
  }

  // Get service plan of service
  const getServicePlanById = async (id:string) => {
    setLoading(false)
    if(!loading && userDetail) {
        try {
            setLoading(true);
            const response: ServicePlanInterface = await GetServicePlanById(id);
            if(response) {
                setMostPopularPlanIdx(response.mostPopular.values.findIndex((item) => item.value === true));
                setServicePlan(response);
            }
            setLoading(false);
            return response;
        } catch (error) {
            console.log("Hubo un error al obtener los datos del servicio plan", error)
        }
    }
}

  const handlePlanClick = (typePlanId: string) => {
        setPlanIndex(Number(typePlanId));
    setShowModalQuote(true);
  }

  // Method to send view_item event to GA4
  const GA4_view_item = (service: ServiceInterface) => {
    ReactGA.gtag("event", "view_item", {
      currency: "USD",
      value: 1000,
      items: [{
        item_id: service.id,
        item_name: service.title,
        item_brand: service.companyName,
        item_category: service.serviceTypeLabel,
        item_list_id: "services_"+service.serviceType,
        item_list_name: "Services "+service.serviceTypeLabel,
        price: 1000,
        quantity: 1
      }]
    });
  }

  useEffect(() => {
    let search = searchParams.get("mode");
    if(search && search === 'view') {
      setSendRequest(false);
    }

    let urlPath = location.pathname.split('/');

    if(urlPath[2]=== 'busqueda-de-proveedor') {
      setIsSupplierSearch(true)
    }

  }, [location]);

  // recuperando los datos de la operacion que se encuentran en draft
  useEffect(() => {
    // recuperando el usuario loageado
    const auth = getAuth();
    onAuthStateChanged(auth, (userCurrent) => {
      if(userCurrent && isLoggedIn) {
        setUser(userCurrent);
        getUser(userCurrent.uid);
      } 
    });

    dispatch(setHistoryLocation(location.pathname));

  }, []);

  // useEffect
  useEffect(() => {
    // console.log(params);
    if(params && params.serviceType && params.serviceId ) {
      const service = GetLocalServiceDetail(params.serviceId);
      if(service) {
        setServiceDetail(service);
        getCompany(service.companyId);

        if(service.serviceType === 'busqueda-de-proveedor') {
          getServicePlanById(service.servicePlanId?service.servicePlanId:'');
        }

        setRequestQuote((prev) => ({
          ...prev,
          country: service.country,
          city: service.city,
          companyName: service.companyName,
          companyId: service.companyId,
          // numServiceComments: service.numServiceComments,
          // serviceRating: service.serviceRating
        }));
        GA4_view_item(service);
      } else {
        GetServiceDetail(params.serviceId);
      }
    }
  }, [params.serviceId]);

  // actualiza la solicitud de cotizacion con los datos del usuario cuando este logeado
  useEffect(() => {
    if(isLoggedIn) {
      setRequestQuote((prev) => ({
        ...prev,
        user: {
          id: userDetail.id,
          name: userDetail.name+" "+userDetail.lastName,
          itNumber: userDetail.itNumber? userDetail.itNumber : 0,
          typeIt: userDetail.typeIt? userDetail.typeIt : '',
          numOperations: userDetail.numOperations? userDetail.numOperations : 0,
          phone: userDetail.phone? userDetail.phone : '',
          rating: userDetail.rating? userDetail.rating: 0,
        }
      }));

      // validacion si esta logeado puede enviar requestQuote
      if(userDetail && userDetail.id && userDetail.userType.includes('user') && !searchParams.get("mode")) {
        setSendRequest(true);
      }
    }
  }, [userDetail]);

  // useEffect que carga las solicitud
  useEffect(() => {
    window.scrollTo(0, 10);
    if(serviceDetail) {
      setRequestQuote((prev) => ({
        ...prev,
        companyId: serviceDetail.companyId,
        companyName: serviceDetail.companyName,
        serviceId: serviceDetail.id?serviceDetail.id:'',
        serviceTypeId: serviceDetail.serviceType,
        serviceTypeLabel: serviceDetail.serviceTypeLabel,
        numServiceComments: serviceDetail.numComments,
        numServiceRating: serviceDetail.rating || 0,
        category: 'Importación',
        createdAt: new Date(),
        updatedAt: new Date() 
      }));
    }
    /*console.log("useEfect active");*/
  }, [serviceDetail]);
  

  useEffect(() => {
    if(searchParams.get('serviceAction') && searchParams.get('serviceAction') === 'showChat' ) {
      company.id && existChat(company.id);
    }
  }, [searchParams, company]);
  
  return (
    <div className="service" >
     {(!(showModalQuote || showQuoteImport || showThanks)) && sendRequest && <div className="service-mobile-btn">
        {
        // sendRequest && 
        <Button id="btnRequestQuote" content="Solicitar cotización"  onClick={()=>setShowModalQuote(true)}/>
        }
      </div>}
      <div className="service--resume" ></div>
      <div className="service-container" ref={serviceDetailRef} >
        <div className="service-left">
          {!loading &&  
          <div className="service-display-header">
            <h1 className="small-header">{serviceDetail.companyName}</h1>
            <div className="service-display-ratings">
              <Rating rating={Number((serviceDetail.rating).toFixed(1))} />
              <CommentCount commentsCount={serviceDetail.numComments} />
              <CityCountryDisplay
                city={serviceDetail.city}
                country={serviceDetail.country.alpha2Code}
                flagImg={serviceDetail.country.imgFlag}
              />
            </div>
            {/*<div className="order-display-social">
              <Share />
              <Icon onClick={handleClickLike} style={{cursor: 'pointer'}}/>
            </div>*/}
          </div>}
          <div className="service-left-go-back">
            <GobackButton />
            {!loading &&<div className="service-left-go-back-header">
              <ScrollHighlightNabbar
                navHeader={navHeader}   
                idClassScroll={navHeader[0].headerID}          
              />
            </div>}
          </div>
          { !loading && ( !isSupplierSearch  ?
            <CompanyCard 
              company={company} 
              service={serviceDetail}
              supplier={userDetail && userDetail.userType && userDetail.userType.includes("provider")} 
              setSupplier={()=>{}}
              handleSend={handleChat}
              verify={serviceDetail.verify}
              numberVerify={serviceDetail.numberVerify}
            /> : 
            <CompanyCardResponsive 
              company={company} 
              supplier={false} 
              setSupplier={()=>{}} 
              clickMessage={handleChat}
              verify={serviceDetail.verify}
              numberVerify={serviceDetail.numberVerify}
            />)
          }

          { isSupplierSearch &&
            <div className="service-left-category">
              <CardCategoryPlan
                servicePlan={servicePlan}
                handleComparar={handleCompareClick}
                handleComprar={()=>setShowModalQuote(true)}
              />
            </div>
          }
        </div>

        {!loading && requestQuote ? 
        <div id={navHeader[0].headerID} ref={navHeader[0].headerRef} className="service-right">
          <div className="service-right-go-back">
            <GobackButton />
          </div>
          
          <div  className="service-right-top">
            <div className="service-right-subheaders">
              {/* <button
                className="service-custom-button"
                onClick={() => handleSubheaderClick("summary")}
                ref={sumRef}
              >
                <Subheader 
                  active={isActive.sum} 
                  content="Resumen" 
                  handleClick={handleSummaryClick} 
                  className='smalltext-header'  />
              </button>
              <button
                className="service-custom-button"
                onClick={() => handleSubheaderClick("reviews")}
              >
                <Subheader 
                  active={isActive.rev} 
                  content="Reseñas" 
                  handleClick={handleReviewClick} 
                  className='smalltext-header' 
                />
              </button>
              <button
                className="service-custom-button"
                onClick={() => handleSubheaderClick("faq")}
              >
                <Subheader
                  active={isActive.faq}
                  content="Preguntas frecuentes"
                  handleClick={handleFaqClick}
                  className='smalltext-header'/>
              </button> */}
              
              {/* resonsive */}
             {!loading && <div className="service-right-header">
                <ScrollHighlightNabbar
                  navHeader={navHeader}    
                  idClassScroll={navHeader[0].headerID}
                />
              </div>}
            </div>

            {
              sendRequest && 
              <div className="service-right-btn">
              <Button 
                id="btnRequestQuote" 
                content={isSupplierSearch ? "Comparar planes" : "Solicitar cotización"} 
                onClick={isSupplierSearch ? ()=>handleCompareClick() : ()=>setShowModalQuote(true)} />
            </div>}
          </div>

          {divPhase === "summary" && 
            <>
              <CompanyCardResponsive 
                company={company} 
                supplier={false} 
                setSupplier={()=>{}} 
                clickMessage={handleChat}
                verify={serviceDetail.verify}
                numberVerify={serviceDetail.numberVerify}
              />
              { isSupplierSearch &&
                <div className="service-left-category-responsive">
                  <CardCategoryPlan
                    servicePlan={servicePlan}
                    handleComparar={handleCompareClick}
                    handleComprar={()=>setShowModalQuote(true)}
                  />
                </div>
              }
            </>
            }

          
          {/* {divPhase === "summary" ? ( */}
            <> 
              
              <ServiceDisplay 
                service={serviceDetail} 
                onClickQuote={()=>setShowModalQuote(true)} 
                onClickCompare={()=>handleCompareClick()}
                isSupplierSearch={isSupplierSearch}
                showButton={sendRequest} 
          
           
              />
              
              { isSupplierSearch &&
                <div id="sectionPlan" ref={cardRef} style={{width: '100%'}}>
                  <TableComparePlan
                    handleModalPlan={handlePlanClick}
                    servicePlan={servicePlan}
                    importantIdx={mostPopularPlanIdx}
                  />
                </div> 
              }
              
              <div id={navHeader[1].headerID} ref={navHeader[1].headerRef}></div>
              <ReviewDisplay
                reviewId={serviceDetail.reviewServiceId?serviceDetail.reviewServiceId: ''}
              />

              <div id={navHeader[2].headerID} ref={navHeader[2].headerRef}></div>
              <FaqDisplay
                faqs={serviceDetail.faq}
              />
            </>
          {/* ) : divPhase === "reviews" ? (
            <ReviewDisplay reviewId={serviceDetail.reviewServiceId?serviceDetail.reviewServiceId: ''}/>
          ) : divPhase === "faq" ? (
            <FaqDisplay faqs={serviceDetail.faq} />
          ) : null} */}
        </div>  
        
        : <Loader />}
      </div>
      
      {/** seccion modal de cotizacion */}
      {/* {showModalQuote && <div className="modal-quote-responsive">
        <ModalQuote 
          requestQuote={requestQuote}
          setRequestQuote={setRequestQuote}
          show={showModalQuote} 
          setShow={setShowModalQuote} 
          serviceDetail={serviceDetail}
          fromPage="serviceDetail"
        />
      </div>} */}

      {<ModalProcessService
        fromPage="serviceDetail"
        serviceTypeId={serviceDetail.serviceType}
        show={showModalQuote}
        setShow={setShowModalQuote}
        service={serviceDetail}
        requestQuote={requestQuote}
        planIndex={planIndex}
      />}

      {/* <ModalContractedService
        title="🥳 ¡Servicio contratado!"
        subTitle="Se ha cargado S/ 200.00 a tu tarjeta, es momento de empezar a trabajar"
        show={showModalContractedService}
        setShow={setshowModalContractedService}
        handleYes={()=>{}}
        handleNo={()=>setshowModalContractedService(false)}
      /> */}

      {/** modal de solicitud de cotizacion enviada */}

      {/* {userDetail && userDetail.userType &&  */}
      {/* <Modal disableToast title="¡Tu solicitud fue enviada!" show={showThanks} setShow={setShowThanks}  >
        <div className="modalThanks-container"> */}
 

          {/* <div className="modalThanks-container-row">
            <img src={STORAGE_ASSETS+"/images/png/check_yellow.png"} alt="" />
            <div className="paragraph-header service-colorText">
              {"Gracias por utilizar Arkabia, la agencia te responderá en breves."}
            </div>
          </div> */}
          {/* <div className="modalThanks-container-row__">
            
          </div> */}

          {/* <div className="modalThanks-container-row">
            <div className="modalThanks-container--buttons">
              <Button 
                content="Si"
                color="green-2"
                onClick={()=>{setShowThanks(false); navigation(`/${OPERATIONS}/${REQUEST_QUOTE}/${redirectOperationId}?sendOtherQuote=provider`)}}
              /> */}
              {/* <Button 
                content="Volver"
                color="black-25"
                onClick={()=>{setShowThanks(false); cleanFields();}}
              /> */}
              {/* <Button 
                content="No"
                onClick={()=>{setShowThanks(false); navigation('/'+OPERATIONS)}}
              />
            </div>
          </div> */}
        {/* </div>
      </Modal> */}
      {/* } */}

      {/* <ModalContact
        show={showContactModal}
        setShow={setShowContactModal}
      /> */}

      {/* <ModalSendMessage
        setShowModal={setShowModalSendMessage} 
        showModal={showModalSendMessage}
        title='Datos de la agencia'
        agencyName={company.name}
        direction={company.direction}
        email={company.email?company.email:''}
        phone={company.phone?company.phone:''}
        whatsapp={company.whatsapp? company.whatsapp:''}
        message={`¡Hola *${company.name}*!%0aMi nombre es *${userDetail.name} ${userDetail.lastName}* y te escribo desde Arkabia.com`}
      />*/}
    </div>

  );
};
