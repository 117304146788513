import { httpsCallable } from "firebase/functions";
import { CardInterface } from "../interfaces/CardInterface";
import { OrderCreatePayInterface } from "../interfaces/OrderCreatePay";
import { Functions } from "../firebaseConfig/FirebaseConfig";
import { BillingInterface } from "../interfaces/BillingInterface";

export const ProcessPay= async (paymentData: CardInterface, orderData: OrderCreatePayInterface, billingData: BillingInterface) => {
    return new Promise((resolve, reject) => {
        const hireService = httpsCallable(Functions, 'hireService');
        return hireService({paymentData: paymentData, ordeData: orderData, billingData: billingData})
        .then((result:any) => {
            console.log("🚀 ~ file: ProcessPayment.ts:9 ~ result:", result)
            result.data.code && result.data.code === "unknown" ? reject(result.data) : resolve(result.data);
        }).catch((error) => {
          console.log("🚀 ~ file: ProcessPayment.ts:19 ~ .then ~ error:", error)
            reject(error);
        });
    });
};
