import React, {useEffect, useState} from "react";

import { HelpCircle } from "../../../icons/outline/HelpCircle";
import { TextField } from "../../TextField/TextField";
import { Dropdown } from "../../Dropdown/Dropdown";

import { InvoiceTranslation, LegalRepresentative } from "../../../interfaces/InvoiceTranslation";

import './InvoceTranslationLegal.css';
import { CardContent } from "../../CardContent/CardContent";
import { ButtonEditChange } from "../../ButtonEditChange/ButtonEditChange";

interface InvoceTranslationLegalProps {
    legalRepresentative: LegalRepresentative;
    setLegalRepresentative: React.Dispatch<React.SetStateAction<InvoiceTranslation>>;
    typeDocs: string[],
    disabled?: boolean; // permite activar y desactivar el texField, para poder editar
    handleSave: ()=>void;
    handleRefresh: () => void;

}

export const InvoceTranslationLegal: React.FC<InvoceTranslationLegalProps> = ( {
    legalRepresentative,
    setLegalRepresentative,
    typeDocs,
    disabled=false,
    handleSave=()=>{},
    handleRefresh=()=>{}
}) => {

    const [ showEdit, setShowEdit ] = useState(disabled);

    const handleNameChange = (value: string) => {
        setLegalRepresentative((prev:any) => ({
            ...prev,
            legalRepresentative: {
                ...prev.legalRepresentative,
                declarantName: value
            }
        }));
        // handleAutoSave();
    }

    const handleLastNameChange = (value: string) => {
        setLegalRepresentative((prev:any) => ({
            ...prev, 
            legalRepresentative: {
                ...prev.legalRepresentative,
                declarantLastName: value
            }
        }));
        // handleAutoSave();
    }

    const handlePositionChange = (value: string) => {
        setLegalRepresentative((prev:any) => ({
            ...prev,
            legalRepresentative: {
                ...prev.legalRepresentative,
                position: value
            }
        }));
        // handleAutoSave();
    }

    const handleDocTypeChange = (value: string) => {
        setLegalRepresentative((prev:any) => ({
            ...prev, 
            legalRepresentative: {
                ...prev.legalRepresentative,
                typeIt: value
            }
        }));
        // handleAutoSave();
    }

    const handleNumDocChange = (value: number) => {
        let num = Number(legalRepresentative.itNumber);
        switch (legalRepresentative.typeIt) {
            case 'DNI':
                if(value >=0 && value<=99999999) {
                    num=value
                }    
                break;
            case 'RUC':
                if(value >=0 && value<=99999999999) {
                    num=value;
                }
                break;
            default:
                num=value;
                break;
        }

        // console.log(num)
        setLegalRepresentative((prev:any) => ({
            ...prev, 
            legalRepresentative: {
                ...prev.legalRepresentative,
                itNumber: num
            }
        }));
        // handleAutoSave();
    }

    const handleSaveClick = () => {
        handleSave();
        setShowEdit(!showEdit);
    }

   const handleCancelClick = () => {
    handleRefresh()
   setShowEdit(true);
}

    useEffect(() =>{
        if(legalRepresentative.declarantLastName.length>0 && legalRepresentative.declarantName.length>0 &&
            legalRepresentative.position.length>0 && legalRepresentative.itNumber>0 && legalRepresentative.typeIt.length>0) {
           setShowEdit(true);
        }
    }, [])

    return (
        <div role='invoceTranslationLegalRole' className='invoceTranslationLegal-main'>
            <CardContent>
                <div className='invoceTranslationLegal-header'>
                    <div className='invoceTranslationLegal-color paragraph-header'>Representante legal</div>
                    <HelpCircle />
                </div>
                <div className='invoceTranslationLegal-textField'>
                    <TextField 
                        title='Nombre' 
                        placeholder=' '
                        value={legalRepresentative.declarantName} 
                        onChange={(e: any) =>handleNameChange(e.target.value)}
                        disabled={showEdit}
                        required 
                        role='invoceTranslationLegalName'
                    />
                    <TextField 
                        title='Apellido' 
                        placeholder=' ' 
                        value={legalRepresentative.declarantLastName}
                        onChange={(e: any) =>handleLastNameChange(e.target.value)}
                        required
                        disabled={showEdit}
                        role='invoceTranslationLegalLastName'
                    />
                    <TextField 
                        title='Cargo' 
                        placeholder=' ' 
                        value={legalRepresentative.position}
                        onChange={(e: any) =>handlePositionChange(e.target.value)}
                        required
                        disabled={showEdit}
                        role='invoceTranslationLegalPosition'
                    />
                </div>
                <div className='invoceTranslationLegal-textField-m'>
                    <div className='invoceTranslationLegal-textField-m-row'>
                        <TextField 
                            title='Nombre' 
                            placeholder=' '
                            value={legalRepresentative.declarantName} 
                            onChange={(e: any) =>handleNameChange(e.target.value)}
                            disabled={showEdit}
                            required 
                            role='invoceTranslationLegalName'
                        />
                        <TextField 
                            title='Apellido' 
                            placeholder=' ' 
                            value={legalRepresentative.declarantLastName}
                            onChange={(e: any) =>handleLastNameChange(e.target.value)}
                            required
                            disabled={showEdit}
                            role='invoceTranslationLegalLastName'
                        />
                    </div>
                    <TextField 
                        title='Cargo' 
                        placeholder=' ' 
                        value={legalRepresentative.position}
                        onChange={(e: any) =>handlePositionChange(e.target.value)}
                        required
                        disabled={showEdit}
                        role='invoceTranslationLegalPosition'
                    />
                </div>
                <div className='invoceTranslationLegal-document'>
                    <Dropdown 
                        title='Tipo de documento'
                        elements={['DNI']}
                        value={legalRepresentative.typeIt}
                        setValue={(e) =>handleDocTypeChange(e+"")}
                        required
                        disabled={showEdit}/>
                    <TextField 
                        title={'Número de '+ ((legalRepresentative.typeIt.length>0) ? legalRepresentative.typeIt :'documento' )}
                        placeholder=' ' 
                        value={legalRepresentative.itNumber>0 ? legalRepresentative.itNumber : ''}
                        onChange={(e: any) =>handleNumDocChange(e.target.value)}
                        type='number'
                        required
                        disabled={showEdit}
                        role='invoceTranslationLegalNumDoc'
                    />
                </div>
                <ButtonEditChange 
                        onClickEdit={()=>setShowEdit(!showEdit)}
                        edit={!showEdit}
                        setEdit={setShowEdit}
                        add={!showEdit}
                        onClickCancel={()=>handleCancelClick()}
                        onClickComplete={()=>handleSaveClick()}
                        onClickSave={()=>handleSaveClick()}
                    />
            </CardContent>
        </div>
    )
}