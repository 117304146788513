import React, { useEffect, useRef, useState } from "react";
import { CountryInterface } from "../../interfaces/Country";
import { Dropdown } from "../Dropdown/Dropdown";
import { ItemVessels } from "../SearchListVessels/ItemVessels/ItemVessels";
import { SearchTextField } from "../SearchTextField/SearchTextField";
import { TextField } from "../TextField/TextField";

import './CountryDropdown.css';


interface CountryDropdownProps {
    countryList: {country: string; alpha2Code: string, phoneCode: string }[];
    country: CountryInterface;
    handleCountryChange: (country: string, alpha2Code: string) => void;
    disable?: boolean;
    title: string
}

export const CountryDropdown: React.FC<CountryDropdownProps> = ({
    countryList,
    country,
    handleCountryChange,
    disable = false,
    title
}) => {

    const [open, setOpen] = useState(false);

    const [searchCountry, setSearchCountry] = useState<{country: string; alpha2Code: string, phoneCode: string }[]>([]);
    const [countrySelected, setCountrySelected] = useState<string>('');
    const [countrySelectedItem, setCountrySelectedItem] = useState<CountryInterface>();
    
    const inputRef = useRef<HTMLInputElement>(null)

    let disabledClass = "";

    if (disable) {
        disabledClass = "countryDropdown-item-column-dropdown-disabled";
    }

    const handleSearchCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        // handlePhoneCodeChange(value);
        setCountrySelected(value)
        if(value.length>0) {
            initSearchCountry(value);
        } else {
            setSearchCountry([]);
        }
    }

    const getAlpha2Code = (country: any) => {
        let alpha2Code =  countryList.find((c) => c.country === country)?.alpha2Code;
        return alpha2Code ? alpha2Code : '';
    }

    const handleChangeCountry = (e: string) => {
        let value_split = e.split(" ");
        setCountrySelected(e);
        // handlePhoneCodeChange(value_split[1]);
        handleCountryChange(value_split[0], getAlpha2Code(value_split[0]));
    }

    const handleOpenDropdown = () => {
        setOpen(!open)
    }

    const handleClickItem = (item: {country: string; alpha2Code: string, phoneCode: string }) => {
        setCountrySelected('');
        handleCountryChange(item.country, item.alpha2Code); 
        setSearchCountry([]);
        setCountrySelectedItem({
            name: item.country,
            alpha2Code: item.alpha2Code,
            imgFlag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${item.alpha2Code.toLowerCase()}.svg`,
        });
        setOpen(false);
        initSearchCountry(item.country.slice(0,2));
    }

    const initSearchCountry = (search: string) => {
        setSearchCountry(countryList.filter((c) => c.country.toLocaleUpperCase().includes(search.toLocaleUpperCase())));
    }

    useEffect(() => {
        initSearchCountry('a');
    }, [countryList]);

    useEffect(() => {
        if(country && country.name.length>0) {
       
            setCountrySelected(country.name);
            setCountrySelectedItem(country)
        }
    }, [country.name]);

    useEffect(() => {
        if(open) {
            inputRef.current?.focus();
        }
    }, [open]);
    
    return (
        <div className="countryDropdown-container">
            <div className="countryDropdown-item">

                <div className={`countryDropdown-item-column-dropdown`}>
                    
                    <div className={`countryDropdown-item-content ${disabledClass}`} onClick={disable ? ()=>{} : handleOpenDropdown}>
                    {countrySelectedItem ? 
                        <div className="countryDropdown-item-content-column">
                            <div className="paragraph-header countryDropdown-item-content-text-selected">{title}</div>
                            <div className="countryDropdown-item-content-row">
                                <img
                                    alt="arkabia-pais" width="25" height="25"
                                    src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${countrySelectedItem && countrySelectedItem.alpha2Code.toLocaleLowerCase()}.svg`}
                                />
                                <div className="paragraph-header">{countrySelectedItem?.name} </div>
                        
                            </div>
                        </div>
                        : <div className="paragraph countryDropdown-item-content-text">{title}</div>}
                    </div> 
    

                    {open ?  
                    <>
                        <div className="countryDropdown-item--country">
                                <TextField 
                                    title="" 
                                    placeholder="Selecciona tu país" 
                                    value={countrySelected}
                                    onChange={handleSearchCountryChange} 
                                    refInput={inputRef}
                                />
                                
                                {/* { countrySelected.length>0 && */}
                                    <div className="phoneCountry-list">
                                    {
                                    // searchCountry.length>0 && 
                                    searchCountry.map((s, idx)=> (
                                        <ItemVessels
                                            key={idx}
                                            alpha2Code={s.alpha2Code.toLocaleLowerCase()}
                                            name={s.country}
                                            description={''}
                                            handleclick={()=>handleClickItem(s)}
                                        />
                                    ))}
                                </div> 
                                {/* } */}
                        </div>
                    </> : ''}
                
                </div>
            </div>
        </div>
    )
}