import React from "react";

/** importando components */
import { Button } from "../../../../Button/Button";

import { GetLastTimeMinText } from "../../../../../utils/GetLastTimeText";
import { useNavigate } from "react-router-dom";
import { UserActive } from "../../../../UserActive/UserActive";

/** importando css */
import './CardNotification.css';

/** declarando interface */
interface CardNotificationProps {
    title: string;
    createAt: Date;
    content: string;
    operationName?: string;
    redirectUrl?: string;
    handleItemClick: ()=>void;
    isCompany: boolean | undefined;
    companyImage?: string;
    profileUrl?: string;
    userName?: string // nomber del usuario
    userLastName?: string // apellido del usuario
    isNew?: boolean;
    status?: boolean;
}

export const CardNotification: React.FC<CardNotificationProps> = ({
    title,
    createAt,
    content,
    operationName = "",
    redirectUrl = "",
    handleItemClick,
    isCompany,
    companyImage,
    profileUrl= '',
    userName='',
    userLastName,
    isNew = false,
    status = false, 
}) => {

    

    return (
         <div onClick={status ? ()=> handleItemClick() : ()=>{}} 
              role='cardNotificationRole' 
              className={`${isNew ? (`${status ? 'card-notification-new box-shadow-card' : 'card-notification-disactived card-notification-new box-shadow-card'}`) : 
              (`${status ? "card-notification box-shadow-card" : "card-notification-disactived card-notification box-shadow-card"}`)}`}>
                {/* ? ${isDisactivecard ? 'card-notification-disactived' + "card-notification-new" : 'card-notification-new'}  : "card-notification" */}
            {<div className="card-notification-content">
                <div className="paragraph header-title-content">
                    {operationName}
                </div>
            </div>}

            <div className="card-notification-body">
                <UserActive
                    userImg={profileUrl}
                    userId=""
                    name={userName}
                    lastname={userLastName}
                    white={isCompany=== true }
                />
                <div className="smalltext header-title-business">{content}</div>
            </div>
            {/* {content.length> 0 && <div className="card-notification-business">
                <div className="smalltext header-title-business">{content}</div>
            </div>} */}
            <div className="card-notification-footer">
                <div className="tinytext card-notification-header-time">{ "hace " + GetLastTimeMinText(createAt)}</div>
            </div>
            {/* { type === 'turn' ? <div className="card-notification-content">
                <div className="card-notification-estado" />
                <div className="smalltext-header header-title-business">¡Es tu turno!</div>
            </div> : <div className="card-step-content">
                { type === 'event' ? 
                    <div className="card-event-content">
                        <div className="smalltext card-step_">{user} te ha agregado a un evento.</div>
                        <div className="tinytext header-title-business">Para el dia { week } {day} / {hours}</div>
                    </div>
                    :
                    <div className="smalltext card-step_">
                        {user} ha completado “{step}” - Paso {numberStep}
                    </div>
                   
                }
            </div>}

            {type === 'turn' ? <div className="tinytext header-title-business">Actualizado hace {updateAt} por {user}</div> :
                <div className="card-step-button">
                    { type === 'event' ? <Button content={"Ver evento"} color={"black-75"} role='btn-modal-complete_' onClick={onClickViewEvent}/> : 
                              <Button content={"Ver"+'  "'+step+'"'} color={"yellow"} role='btn-modal-complete' onClick={onClickPhase}/>}
                </div>
            } */}
        </div>
    )
}
