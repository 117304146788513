import React, { useEffect, useState } from "react";
import { EventCard } from "../EventCard/EventCard";

import './EventSeccion.css';
import { CardContent } from "../CardContent/CardContent";
import { QuoteIcon } from "../../icons/outline/QuoteIcon";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { Nube } from "../../icons/outline/Nube";
import { CalendarIconBlack } from "../../icons/outline/CalendarIconBlack";
import { Loader } from "../Loader/Loader";
import { useSelector } from "react-redux";
import { UserPlanResoucesInterface } from "../../interfaces/UserPlanResoucesInterface";
import { CreateUserPlanResources, GetUserPlanResources } from "../../Services/UserPlanResources.service";
import { UpdateUser } from "../../Services/User.service";
import { toast } from "react-toastify";
import { EventArkabiaInterface } from "../../interfaces/EventArkabiaInterface";
import { GetEventsArkabia } from "../../Services/EventsArkabia.service";

interface EventSeccionProps {
}

export const EventSeccion: React.FC<EventSeccionProps> = ({

}) => {

    const {userDetail} = useSelector((state: any) => state.user);
    const {plan} = useSelector((state: any) => state.plan);
    const {planDetail} = useSelector((state: any) => state.planDetail);

    const [loading, setLoading] = useState<boolean>(false);
    const [userPlanResources, setUserPlanResources] = useState<UserPlanResoucesInterface>({
        createdAt: new Date(),
        limitRequests: 0,
        limitStorage: 0,
        numRequests: 0,
        storage: 0,
        updatedAt: new Date(),
        userId: ""
    });
    const [eventsArkabia, setEventsArkabia] = useState<EventArkabiaInterface[]>([]);

    // method to get userPlanResources by userId
    const getUserPlanResources = async () => {
        // check if planDetail is empty
        if(!planDetail) {
            return;
        }

        try {
            setLoading(true);
            // check if user has userPlanResources
            if(userDetail.userPlanResourcesId && userDetail.userPlanResourcesId.length > 0) {
                const response = await GetUserPlanResources(userDetail.id);
                if(response) {
                    // console.log("🚀 ~ getUserPlanResources ~ response", response);
                    setUserPlanResources(response);    
                }
                setLoading(false);
            } else {
                // create userPlanResources
                const tmpUserPlanResources: UserPlanResoucesInterface = {
                    numRequests: 0,
                    limitRequests: planDetail.numRequestQuote,
                    storage: 0,
                    limitStorage: planDetail.storage,
                    userId: userDetail.id,
                    createdAt: new Date(),
                    updatedAt: new Date()
                }
                console.log("🚀 ~ getUserPlanResources ~ tmpUserPlanResources:", tmpUserPlanResources);
                setTimeout(async () => {
                    const response: UserPlanResoucesInterface = await CreateUserPlanResources(tmpUserPlanResources);
                    // console.log("🚀 ~ getUserPlanResources ~ response:", response)
                    if(response && response.id) {
                        await UpdateUser({
                            ...userDetail,
                            userPlanResourcesId: response.id,
                            updatedAt: new Date(),
                        });
                        setUserPlanResources(response);
                    }
                    setLoading(false);
                }, 590);
            }           
        } catch (error) {
            console.log("🚀 ~ getUserPlanResources ~ error:", error)
            //toast.error("Hubo un error al obtener los recursos del plan");
        }
    }

    // method to calcule progress bar
    const calculeProgressBar = (num: number, limit: number): number => {
        return (num * 100) / limit;
    }

    // get storage from mb to GB
    const getStorageGB = (storage: number): string => {
        return (storage / 1000).toFixed(2);
    }

    const getEventsArkabia = async () => {
        try {
            const response = await GetEventsArkabia();
            // console.log("🚀 ~ getEventsArkabia ~ response:", response)
            setEventsArkabia(response);
        } catch (error) {
            console.log("🚀 ~ getEventsArkabia ~ error:", error);
        }
    }

    useEffect(() => {
        getUserPlanResources();
    }, [planDetail]);

    useEffect(() => {
        getEventsArkabia();
    }, []);



    return (
        <div className="eventSeccion">
            {!loading && <CardContent className="eventSeccion-cardContent">
                {plan && plan.priceUsd === 0 && <div className="eventSeccion-column">
                    <div className="eventSeccion-row">
                        <QuoteIcon/>
                        <div className="smalltext-header">Cotizaciones</div>
                    </div>
                    <div className="smalltext eventSeccion-row">
                        <span className="smalltext-header">{userPlanResources.numRequests}</span>
                        de
                        <span className="smalltext-header">{userPlanResources.limitRequests}</span>
                        cotizaciones disponibles
                    </div>
                    <div className="smalltext-header eventSeccion-text-blue">¿Más cotizaciones?</div>
                </div> }

                <div className="eventSeccion-column">
                    <div className="eventSeccion-row">
                        <Nube/>
                        <div className="smalltext-header">Almacenamiento</div>
                    </div>
                    <div className="eventSeccion-progress">
                        <ProgressBar bgcolor={"linear-gradient(90deg, #000 0%, rgba(51, 51, 51, 0.70) 100%)"} completed={calculeProgressBar(userPlanResources.storage, userPlanResources.limitStorage)}/>
                    </div>
                    <div className="smalltext eventSeccion-row">
                        <span className="smalltext-header">{getStorageGB(userPlanResources.storage)} GB</span>
                        de
                        <span className="smalltext-header">{getStorageGB(userPlanResources.limitStorage)} GB</span>
                    </div>
                </div>

                <div className="eventSeccion-row">
                    <CalendarIconBlack/>
                    <div className="smalltext-header">Eventos</div>
                </div>
                
                <div className="eventSeccion-eventcard">
                    { eventsArkabia.length>0 ? eventsArkabia.map((e, idx) => {
                        return (
                            <EventCard
                                key={idx}
                                event={e}
                            />
                        )
                    }): <div className="anyEvent">
                            <div className="paragraph anyEvent-message">No tienes ningún evento.</div> 
                        </div>
                    }
                </div> 
            </CardContent>}
        </div>
    )
}