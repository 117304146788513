import React from "react";

// import styles
import './Tabs.css';

interface TabsProps {
    active: number;
    titles: string[];
    content: React.ReactNode;
    setActiveTab: (e: number) => void;
}

export const Tabs: React.FC<TabsProps> = ({
    active=1,
    titles,
    content,
    setActiveTab
}) => {
    // const tabRef1 = useRef<HTMLSpanElement>(null);
    // const tabRef2 = useRef<HTMLSpanElement>(null);

    // active === 1 ? tabRef1.current?.classList.add("subheader-clicked") : tabRef1.current?.classList.remove("subheader-clicked");

    // if (active === 2) tabRef2.current?.classList.add("subheader-clicked");
    // else tabRef2.current?.classList.remove("subheader-clicked");

    return (
      <div className='tabs-main' role='tabs'>
            <div className='tabs-header'>
                <span role='text-first-tabs' onClick={()=> {setActiveTab(1)}} className={"subheader smalltext-header"+(active === 1 ? ' subheader-clicked' : '') }>
                    {titles[0]}
                </span>
                <span role='text-second-tabs' onClick={()=> {setActiveTab(2)}} className={"subheader smalltext-header"+(active === 2 ? ' subheader-clicked' : '')} >
                    {titles[1]}
                </span>
            </div>
            <div className='tabs-container'>
                {content}
            </div>
      </div>
    );
}