import React, { useEffect, useRef, useState } from "react";
/** Importando componentes */
import { Dropdown } from "../Dropdown/Dropdown";
import { Rating } from "../Rating/Rating";
import { RatingDisplay } from "../RatingDisplay/RatingDisplay";
import { Review } from "../Review/Review";


import { useDispatch, useSelector } from "react-redux";

// store
import type { AppDispatch } from "../../Store/Store";

import {ReviewInterface, ReviewServiceInterface} from "../../interfaces/ReviewServiceInterface";

import { GetReviewByServiceId } from "../../Slices/Review";

/** Importando interfaces */

import "./ReviewDisplay.css";
import { Loader } from "../Loader/Loader";
import { GetUserCompanyByUserId } from "../../Services/UserCompany.service";
import { GetUserById, GetUserList } from "../../Services/User.service";
import { UpdateReviewInReviewService } from "../../Services/Review.service";

interface ReviewDisplayProps {
  reviewId: string;

}

export const ReviewDisplay: React.FC<ReviewDisplayProps> = ({
  reviewId,

}) => {

  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState<boolean>(false);

  // const [userList, setUserList] = useState<string>('');
  
  // const [rating5, setRating5] = useState(5);
  // const [rating4, setRating4] = useState(4);
  // const [rating3, setRating3] = useState(3);
  // const [rating2, setRating2] = useState(2);
  // const [rating1, setRating1] = useState(1);

  const [reviewListService, setReviewListService] = useState<ReviewServiceInterface>({
    id: '',
    billingReviewId: '',
    serviceId: '',
    review: [],
    createdAt: new Date(),
    detailRating: {
      rating_1: 0, 
      rating_2: 0,
      rating_3: 0,
      rating_4: 0,
      rating_5: 0
    },
    numReview: 0,
    sumTotal: 0,
    updatedAt: new Date(),
  });

  const [numReviewActive, setNumReviewActive] = useState<number>(-1);

  // lista los review de cada servicio segun id
  const GetReviewByService = (id: string) => {
    if(!loading) {
      setLoading(true);
      dispatch(GetReviewByServiceId(id))
      .unwrap()
      .then((response:any) => {
        if(response) {
          // console.log("🚀 ~ .then ~ response:", response)
          let groupRating = response.detailRating;
          if(response.review.length>0) {
            // const sum = response.review.reduce((acc:any, review:any) => {
            //   acc += review.rating;
            //   return acc;
            // }, 0);
            groupRating = response.review.reduce((acc:any, review:any) => {
              acc['rating_'+review.rating] = acc['rating_'+review.rating] + 1 || 1;
              return acc;
            }, {
              rating_1: 0,
              rating_2: 0,
              rating_3: 0,
              rating_4: 0,
              rating_5: 0
            });
            // console.log("🚀 ~ groupRating ~ groupRating:", groupRating)
          }
          setReviewListService({...response, detailRating: groupRating});
        }
        setLoading(false);
      })
      .catch((error)=>{
        console.log(error);
        setLoading(false);
      })
    }
  }

  /** Todos los usuarios */
  //   const getUser = async () => {
  //     if(!loading) {
  //         setLoading(true);
  //         try {
  //             const response: any = await GetUserList();
  //             // console.log("🚀 ~ file: ListChat.tsx:97 ~ getUser ~ response:", response)
  //             setUserList(response);
  //             setLoading(false);
  //         } catch (error) {   
  //             console.log("🚀 ~ file: ListChat.tsx:81 ~ getUser ~ error:", error)
  //             setLoading(false);
  //         }
  //     }
  // }

  // const rating = (numRating: number) => {
  //   console.log('numero actual: ', numRating)
  //   if(numRating===rating5) {
  //     const rating_5 =  Number(reviewListService.detailRating.rating_5) + 1
  //     console.log('numero es: ', rating_5)
  //   } else if(numRating===rating4) {
  //     const rating_4 =  Number(reviewListService.detailRating.rating_4) + 1
  //     console.log('numero es: ', rating_4)
  //   } else if(numRating===rating3) {
  //     const rating_3 =  Number(reviewListService.detailRating.rating_3) + 1
  //     console.log('numero es: ', rating_3)
  //   } else if(numRating===rating2) {
  //     const rating_2 =  Number(reviewListService.detailRating.rating_2) + 1
  //     console.log('numero es: ', rating_2)
  //   } else if(numRating===rating1) {
  //     const rating_1 =  Number(reviewListService.detailRating.rating_1) + 1
  //     console.log('numero es: ', rating_1)
  //   } else {
  //     console.log('no tenemos ningun numero de rating')
  //   }
  // }

  const handleUpdateReview = async (idx: number, review: ReviewInterface) => {
    const tmpReview = reviewListService.review.map((r, idy) => idx === idy ? review : r);

    try {
      const response = await UpdateReviewInReviewService(reviewListService.id || "", tmpReview); 
      
    } catch (error) {
      console.log("🚀 ~ handleUpdateReview ~ error:", error)
    }
  }

  const handleNumReviewChange = (numReview: number) => {
    setNumReviewActive((prev) => numReview === prev ? -1 : numReview);
  }

  let menor = Number(numReviewActive < numReviewActive+1)
  // console.log(reviewListService.review.filter((r) => r.rating === menor))
  
  useEffect(()=>{
    if(reviewId.length> 0) {
      GetReviewByService(reviewId);
    }
  }, [reviewId]);

  return (
    <section className="review-display-content">
      { !loading?
        <>
        { reviewListService && reviewListService.review.length>0? <div className="review-display">
        <h1 className="small-header review-display-title">Reseñas</h1>
        <div className="review-display-ratings">
          <Rating select count rating={5} onClick={()=>handleNumReviewChange(5 || 5.1 || 5.2 || 5.3 || 5.4 || 5.5)} ratingCount={(reviewListService.detailRating.rating_5).toString()} color={numReviewActive === 5 ? "black-25" : "black-05"} />
          <Rating select count rating={4} onClick={()=>handleNumReviewChange(4 || 4.1 || 4.2 || 4.3 || 4.4 || 4.5)} ratingCount={(reviewListService.detailRating.rating_4).toString()} color={numReviewActive === 4 ? "black-25" : "black-05"}/>
          <Rating select count rating={3} onClick={()=>handleNumReviewChange(3 || 3.1 || 3.2 || 3.3 || 3.4 || 3.5)} ratingCount={(reviewListService.detailRating.rating_3).toString()} color={numReviewActive === 3 ? "black-25" : "black-05"} />
          <Rating select count rating={2} onClick={()=>handleNumReviewChange(2 || 2.1 || 2.2 || 2.3 || 2.4 || 2.5)} ratingCount={(reviewListService.detailRating.rating_2).toString()} color={numReviewActive === 2 ? "black-25" : "black-05"} />
          <Rating select count rating={1} onClick={()=>handleNumReviewChange(1 || 1.1 || 1.2 || 1.3 || 1.4 || 1.5)} ratingCount={(reviewListService.detailRating.rating_1).toString()} color={numReviewActive === 1 ? "black-25" : "black-05"} />
          <div style={{background: numReviewActive === -1 ? "var(--black-25)" : "var(--black-05)"}} 
            className="review-display-ratings--button smalltext-header" onClick={()=>handleNumReviewChange(-1)}>Ver todos</div>
        </div>
        {/* <div className="review-display-top">
          <RatingDisplay
            title={"Reseñas"+' '+"("+(reviewListService.review.length)+")"}
            companyService="Agenciamiento de aduana"
            rating={Number((reviewListService.sumTotal/reviewListService.numReview).toFixed(1))}
            ratingText="Excelente"
          />
          {/* <div className="review-display-dropdown">
            <Dropdown
              title="Ordenar por"
              elements={[]}
              value={"Relevancia"}
              setValue={() => {}}
            />
          </div>
        </div> */}
  
        {/* <div className="review-display-ratings-mobile">
          <Rating rating={5} />
          <Rating rating={4} />
          <Rating rating={3} />
          <Rating rating={2} />
          <Rating rating={1} />
        </div> */}
  
        <div className="review-display-list">
          { reviewListService.review && reviewListService.review.length>0 && [...numReviewActive === -1 ? reviewListService.review : 
          reviewListService.review.filter((r) => r.rating >= numReviewActive && r.rating< numReviewActive+1
          // reviewListService.review.filter((r) => r.rating === numReviewActive
          )].sort((a:any, b:any) => b.updatedAt > a.updatedAt ? 1 : -1).map((r:any, idx:number)=>(
            <Review key={idx} idx={idx} review={r} handleUpdateReview={handleUpdateReview} /> 
          ))}

          {numReviewActive !== -1 && reviewListService.review.filter((r) => r.rating >= numReviewActive && r.rating < numReviewActive + 1).length === 0 &&
            <div className="paragraph review-notFound">
              No hay reseñas con la calificación seleccionada.
            </div>
          }
        </div>
      </div>: <div className="paragraph-header review-notFound">
          Usted aún no tiene una reseña
      </div>}
      </> : <Loader/>
      }
    </section>
  );
};
