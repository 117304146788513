import React from "react";

import { Dots } from "../../icons/outline/Dots";
import { Rating } from "../Rating/Rating";
import { CommentCount } from "../CommentCount/CommentCount";
import { CityCountryDisplay } from "../CityCountryDisplay/CityCountryDisplay";

import './ImportPhasesCard.css';
import { Button } from "..";
import { ConverDecimal, ConverMoneyFormat } from "../../utils/ConvertDecimal";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface ImportPhasesCardProps {
    order?: {
        num: number;
        numComments: number;
        date: string;
        
    }; // order object to will be rendered
    type: "Cotización" | "Orden" // type of order
    serviceTypeLabel: string; // tipo de orden
    typeOperation: string; // tipo de operación
    companyName: string;
    rating: number;
    online: boolean; // status if company is online
    phases: any[]; // phases 
    activePhase: number; // phase active  
    price: number, // price to quotation
    black?: boolean // status to rendered active phase circle in black
    showFlags?: boolean;  // status show icons
    resume?: boolean; // show resume mode
    unlockedPhases?: number // numero de fases desbloqueadas
    country?: {
        alpha2Code: string;
        city: string;
        imgFlag: string;
    },

    handlePhaseClick: (e: number) => void;
    handleClick: () => void;
    showButton?: boolean
    currency: string
}

export const ImportPhasesCard: React.FC<ImportPhasesCardProps> = ({
    order,
    serviceTypeLabel,
    type,
    typeOperation,
    companyName,
    rating,
    online,
    phases,
    activePhase=1,
    price,
    black=false,
    showFlags=true,
    resume=false,
    unlockedPhases = 1,
    country,
    handlePhaseClick,
    handleClick,
    showButton=false,
    currency,
    ...props
}) => {

    const onClickPhase = (num: number)=> {
        if(num <= unlockedPhases) {
            handlePhaseClick(num); 
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } 
    }

    return ( 
        <div className='importPhasesCard-main box-shadow-card' role='importPhasesCardRole'>  
            <div className='importPhasesCard-header'>
                <div className='importPhasesCard-title'>
                    <h1 className='smalltext-header importPhasesCard-colorText'>{serviceTypeLabel === 'Agenciamiento de aduana' ? 'Logística y aduanas' : serviceTypeLabel }</h1>
                    <div className='importPhasesCard-header-rigth'>
                        <p className='tinytext importPhasesCard-colorText'>{order?.date}</p>
                        <Dots />
                    </div>
                </div>
                <p className='small-bold importPhasesCard-colorText importPhasesCard-colorText_company'> {companyName}</p>
                     {/* <img src={STORAGE_ASSETS+"/images/png/verify.png"} className="importPhasesCard-content-verify"/> */}
                
                    
                <p className='smalltext importPhasesCard-colorText importPhaseCard-subtitle'> {typeOperation+" "} 
                - {type} {" #" + order?.num ? order?.num : '1'}
                </p>
                {showFlags && <div className='importPhasesCard-rating '>
                    {/* <Rating rating={Number((rating).toFixed(1))} /> */}
                    <CommentCount commentsCount={order?.numComments?order.numComments:0} />
                    <CityCountryDisplay
                        city={country?.city?country.city:''}
                        country={country?.alpha2Code?country.alpha2Code:''}
                        flagImg={country?.imgFlag?country.imgFlag:"https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fflag_of_Peru.png?alt=media&token=d8bfda2c-4c48-419a-b8b9-00a423a24d53"}
                    />
                    <div className='importPhasesCard-online smalltext box-shadow-card'>
                        {online?'En Línea': 'Desconectado'}
                    </div>
                </div>}
            </div>
            {!resume && <div className='importPhasesCard-container'>
               {phases.length>0 && phases.map((phase, idx) => {
                   if ((idx+1) <= unlockedPhases) {
                    return (
                       <div role='importPhasesCardButton' onClick={()=>onClickPhase(idx+1)} key={idx}>
                            <div className='importPhasesCard-row' style={{opacity: ((idx+1) <= unlockedPhases?'100%':'50%')}}>
                                <div className={(idx+1)===activePhase ? 'importPhasesCard-number-content' : 'importPhasesCard-number-content-white'}>
                                    <div className={
                                        'paragraph-header importPhasesCard-number'+
                                        (black && (idx+1)===activePhase ?
                                        ' importPhasesCard-active-black ':'')}>
                                        {idx+1}
                                    </div>
                                </div>
                                <div className='importPhasesCard-phase paragraph'>{phase}</div>
                            </div>
                            {(idx < (phases.length-1)) && (idx+1 < unlockedPhases) ? <div className='importPhasesCard-row'>
                                <img className='importPhasesCard-line' src={STORAGE_ASSETS+'/images/svg/line.svg'} />
                            </div>: null}
                       </div>
                   )} else {
                    return null;
                   }
               }) }
            </div>}
            <div className='importPhasesCard-price'>
               <p className='smalltext importPhasesCard-price-title'>Total</p>
               {price>0 ? <p className='paragraph-header importPhasesCard-price-number'>{currency} {currency === "USD" ? "$": "€"}{ConverMoneyFormat(price.toString())}</p>: <p className='paragraph-header'>No cotizado</p>}
            </div>
            {showButton && <Button 
                role='importPhasesCardCancel'
                content='Reservar'
                size='large'
                onClick={()=>handleClick()}
            />}
        </div>
    ) 
}