import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type AnchorProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Anchor: React.FC<AnchorProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/anchor.svg'}  alt=""  />
        </div>
    )
}