import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type WeekProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Week: React.FC<WeekProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/Week.svg"} alt="" />
    </div>
  );
};