import React, { useState } from 'react';

import './TwelveSeccion.css';
import { STORAGE_ASSETS } from '../../../Consts/BaseUrl';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import GaleryScroll from '../../GaleryScroll/GaleryScroll';
import { Helmet } from 'react-helmet-async';

interface TwelveSeccionProps {
}

export const TwelveSeccion: React.FC<TwelveSeccionProps> = ({

}) => {

    return (
        <div className="twelve-section">
             <Helmet>
                <title>Arkabia | Agencia de Aduana</title>
                <meta name="description" 
                content="¡Arkabia se destaca en Perú Cargo Week 2024! Descubre 
                las mejores soluciones tecnológicas en logística y cómo ayudamos 
                a importadores y exportadores a optimizar sus operaciones." />

                <meta name="keywords" content="Perú Cargo Week, Arkabia, soluciones 
                logísticas, importadores, exportadores, tecnología en logística, 
                agencia de aduana, evento de logística, servicios aduaneros" />
            </Helmet>

            <div className='twelve-seccion-history'>
                <div className='twelve-section-history-right'>
                    <div className='big-header twelve-section-title'>
                        ¡Arkabia en Perú Cargo Week 2024!
                    </div>
                    <div className='small-medium twelve-section-white'>
                        Este es un evento donde importadores y exportadores de diferentes 
                        industrias tienen la oportunidad de conocer las mejores soluciones 
                        tecnológicas en la industria logística.
                    </div>
                </div>
                <div className='twelve-section-history-left'>
                    <GaleryScroll 
                        images={[
                            {
                                href:'https://www.instagram.com/reel/C7UTfrRA-9Y/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                                src: STORAGE_ASSETS+'/images/png/Peru_Cargo_Week_Reel_01.webp'
                            },
                            {
                                href:'https://www.instagram.com/reel/C7xFP1WvPEy/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                                src: STORAGE_ASSETS+'/images/png/Peru_Cargo_Week_Reel_02.webp'
                            },
                            {
                                href:'https://www.instagram.com/reel/C9iSN6fPuaY/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                                src: STORAGE_ASSETS+'/images/png/Peru_Cargo_Week_Reel_03.webp'
                            },
                        ]}
                    />
                </div>
            </div>
            
        </div>
    );
};