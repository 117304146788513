import React from "react";

/** Importando componentes */
import { Button } from "../Button/Button";

import "./CardBalance.css";

interface CardBalanceProps {
    content: string,
    balance: string,
    button?: boolean,
    onClick?: ()=>void
};


export const CardBalance: React.FC<CardBalanceProps> = ({
    content,
    balance,
    button,
    onClick=()=>{}
}) => {

  return (
    <div role='cardBalanceRole' className="cardBalance__main" onClick={()=>onClick()}>
        <div className="paragraph-header">{content}</div>
        <div className="cardBalance__row">
            <div className="small-bold cardBalance__text-balance">{balance}</div>
            {button && <Button content="Solicitar retiro" color="yellow" size="extra-small"/>}
        </div>

    </div>
  );
};
