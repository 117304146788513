import React, { useState } from "react";

import { Rating, TextField } from "..";
import { Button } from "..";
import { RatingMarker } from "../RatingMarker/RatingMarker";
import { MedalRating } from "../../icons/outline/MedalRating";
import { Textarea } from "../Textarea/Textarea";

import './ReviewCard.css';
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

interface ReviewCardProps {
    title: string;
    companyName: string;
    concept: string;
    amount: number
    addReview: boolean;
    responseProvider: boolean;
    reviewValue: string | undefined;
    handleRatingchange: (e: number) => void;
    handleReviewChange: (e: string) => void; 
    supplier ?: boolean;
    handleClick?: ()=>void;
    valueRating?: number; 
    edit?: boolean;
}

export const ReviewCard: React.FC<ReviewCardProps> = ({
    title,
    companyName,
    concept,
    amount,
    addReview,
    responseProvider,
    reviewValue,
    handleRatingchange,
    handleReviewChange,
    handleClick=()=>{},
    supplier = false,
    valueRating=0,
    edit=true
}) => {

    const [textArea, setTextArea] = useState<boolean>(false)

    const handleDetailChange = (e: string) => {
        setTextArea(false)
    }

    return (
        <div role='reviewCardRole' className="reviewCard-contrainer box-shadow-card">
            <div className={ supplier ? "reviewCard-column" : "reviewCard-blockText"}>
                <div className={ supplier ? "paragraph-header" : "smalltext-header reviewCard-colorTitle reviewCard-colorTitle1" }>{title}</div>
                { supplier ? <>
                    <div className="paragraph">¿Qué tal te pareció el cliente?</div>
                    <div className="reviewCard-display">
                        {/* <MedalRating className="rating_pointer-icon"/> */}
                        <RatingMarker disabled={!edit}  value={valueRating} setRating={handleRatingchange}  />
                    </div>
                    <Textarea title="El servicio me pareció..." placeholder=" " onChange={(e: any)=>{handleDetailChange(e.target.value)}}/>
                    <div className="reviewCard-right__">
                        <Button content="Finalizar servicio" color='black-75' size="small"/>
                    </div>
                </> : <>
                    {(!addReview) && (!responseProvider) ? '': <div className="smalltext reviewCard-colorTitle ">{companyName}</div>}
                    <div className="smalltext reviewCard-colorDetail">{"¿"+ concept}<a style={{color: 'var(--blue-2)'}}>{" "+companyName+""}</a>?</div>
                    {/* {amount > 0 ? <div className="smalltext reviewCard-colorAmount">USD $ {ConverMoneyFormat(amount.toString())}</div> : ''} */}
                </> }
            </div>
            { supplier ? null : <div className="reviewCard-score">
                <div className="reviewCard-score-content">
                    <Rating showNumber={false} rating={0}/> 
                    <RatingMarker disabled={!edit} value={valueRating} setRating={handleRatingchange} />
                </div>
                <TextField 
                    title="Escribe aquí tu reseña"
                    placeholder=" "
                    value={reviewValue}
                    onChange={(e: any) => handleReviewChange(e.target.value)}
                    role='reviewCardText'
                    disabled={!edit}
                />
            </div> }

            {supplier ? null : <div className="reviewCard-button">
                
                {(!addReview) && (!responseProvider)? 
                edit  && <Button
                    onClick={()=>handleClick()}
                    content={(addReview) && (responseProvider) ? "Dejar reseña" :  "Finalizar servicio"}
                    color='black-75'
                />:''}
                <div className="reviewCard-add">
                    <div className="smalltext-header" style={{color: 'var(--blue-2)'}}>{addReview && 'Adicionar a reseña'} { responseProvider && 'Responder al proveedor'}</div>
                    {(addReview || responseProvider )&&<div className="smalltext-header" style={{color: 'var(--gray-2)'}}>Tiempo restante 15 días</div>}
                </div>
            </div>}
        </div>
    )
}