import { child, onValue, push, ref, update } from "firebase/database";
import { Database, Functions } from "../firebaseConfig/FirebaseConfig";
import { MessageInterface } from "../interfaces/MessageInterface";
import { GetChatId, UpdateChat } from "./Chat.service";
import { arrayUnion } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

// method to read all messages by id
export const getMessagesById = (userId: string, chatId: string, cb: (e: any,)=>void = ()=> {} ) => {
    const chatRef = ref(Database, 'chat/'+chatId);
    onValue(chatRef, (snapshot) => {
        const data = snapshot.val();
        cb(data.members.includes(userId) ? data.messages : []);
      });
}



// method to push messages to chat
export const pushMessage = async (chatId: string, content: MessageInterface) => {
  const newMessageKey = push(child(ref(Database), 'chat/'+chatId+"/messages")).key;
  update(ref(Database, 'chat/'+chatId+"/messages/"+newMessageKey), content)
    .then(async (resp) => {
      let tmp: any = {
        lastUserId: content.userId,
        lastMessage: content.typeMessage === "text" ? content.userName.split(' ')[0]+": "+content.content : 'Adjunto',
        updatedAt: new Date(), 
      }
    
      if(content.typeMessage === "attach" && content.attach) {
        tmp = {
          ...tmp,
          attachments: arrayUnion(content.attach),
        }
      }
      return await UpdateChat(chatId, tmp);
    })
    .catch((error) => {
      console.log("🚀 ~ file: Message.service.ts:39 ~ pushMessage ~ error:", error);
      return error;
    });
}

// method to send push notification
export const sendPushNotifications = async (chatId: string, message: string, userIdSended: string) => {
  try {
    const chatDetail: any = await GetChatId(chatId);

    const userIdList = (chatDetail.members.filter((m: any) => m.userId !== userIdSended)).map((m: any)=> m.userId);

    const sendPushNotification = httpsCallable(Functions, "sendPushNotification");
    return sendPushNotification({
      title: chatDetail.typeChat === "individual" ? "Nuevo Mensaje" : chatDetail.operationName,
      content: message, userIds: userIdList,
      urlPath: "https://arkabia.com/mensajes?activeChat="+chatDetail.id,
    })
  } catch (error) {
    console.log("🚀 ~ file: Message.service.ts:41 ~ sendPushNotifications ~ error:", error)
    return error;
  }
 
}