import React from "react";
import './ProgressBar.css';

interface ProgressBarProps {
    bgcolor: string;
    completed: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    bgcolor, 
    completed
}) =>{
    return (
        <div>
          <div className={"progress-content"} style={{background: `${bgcolor}`, width: `${completed}`+'%' }}>
          </div>
        </div>
      );
};