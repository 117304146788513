import React from "react";

import './ReceiptMobile.css';
import { formatReceiptNumber } from "../../utils/FormatNumber";
import { ReceiptInterface } from "../../interfaces/ReceiptInterface";
import { Download } from "../../icons/solid/Download";
import { GoBack } from "../GoBack/GoBack";
interface ReceiptMobileProps {
    receipt: ReceiptInterface;
}

export const ReceiptMobile: React.FC<ReceiptMobileProps> = ({
    receipt
}) => {
    return (
        <div className="receipt-mobile">
            <div className="smalltext-header receipt-mobile-text_">{receipt.description}</div>
            <div className="receipt-mobile-header">                        
                <div className="receipt-mobile-header-detail">
                    <div className="tinytext-header receipt-mobile-text">Nº Documento:</div>
                    <div className="tinytext-header receipt-mobile-text">{formatReceiptNumber(receipt.documentNumber)}</div>
                </div>

                <div className="receipt-mobile-header-detail">
                    <div className="tinytext-header receipt-mobile-text">Fecha:</div>
                    <div className="tinytext-header receipt-mobile-text">{receipt.dateRecept}</div>
                </div>
            </div>  

            <div className="receipt-mobile-body">
                {/* <div className="receipt-mobile-body-detail-row">
                    <div className="tinytext-header receipt-mobile-text">Cantidad:</div>
                    <div className="tinytext receipt-mobile-text_">{receipt.amount}</div>
                </div> */}

                <div className="receipt-mobile-body-detail-row">
                    <div className="tinytext-header receipt-mobile-text">Monto:</div>
                    <div className="tinytext receipt-mobile-text_">{receipt.amount+" "+receipt.currency}</div>
                </div>

                <div className="receipt-mobile-body-detail-row">
                    <div className="tinytext-header receipt-mobile-text">Pago:</div>
                    <div className="tinytext receipt-mobile-text_">Culqi</div>
                </div>
                <div className="receipt-mobile-body-detail-row">
                    <div className="tinytext-header receipt-mobile-text">Recibo:</div>
                    <div className="tinytext receipt-mobile-text_">{receipt.typeReceipt}</div>
                </div>
            </div>

            <div className="receipt-mobile-footer">
                <a className="tinytext-header receipt-mobile-a" href={receipt.documentUrl} target="_blank"><Download/> Descargar</a>
            </div>

                    
        </div>
    ) 
}