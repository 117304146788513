import React from "react";

import { Button } from "../Button";

import { BtnsColorOptions } from "../../../@types/btnsColorTypes";

import './Absolute.css';

interface AbsoluteButtonProps {
    title: string; // titulo del boton
    color?: BtnsColorOptions;
    handleClick: (e?:any) => void;
}

export const AbsoluteButton: React.FC<AbsoluteButtonProps> = ({
    title,
    color,
    handleClick,
}) => {
    return (
        <div className="absolute-container">
            <div className="absolute-container--button">
                <Button role='btn-absolute' content={title} onClick={()=>handleClick(true)} color={color} />
            </div>
        </div>
    )
}