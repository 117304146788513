import React, { useEffect, useState } from "react";

// Importando constants
import { StatusOrderSupplier } from "../../Consts/StatusOrderSupplier";

// Importando components
import { Dropdown } from "../../components/Dropdown/Dropdown";

// Importando types
import { StatusOrder } from "../../interfaces/StatusOrder";


// Importando estilos
import './ContentQuote.css';

interface ContentQuoteProps {
    requestId: string;
    statusLabel: string;
    number: number;
    handleClickStatus: (id: string, e: string) => void;
    disabled?: boolean;
    onClick?:()=>void;
}

export const ContentQuote: React.FC<ContentQuoteProps> = ({
    requestId,
    statusLabel,
    number,
    disabled = false,
    handleClickStatus = () => { },
    onClick=()=>{}
}) => {

    const [status, setStatus] = useState<StatusOrder>();


    const getStatus = (label: string) => {
        return StatusOrderSupplier.find((s:StatusOrder) => s.name === label);
    }

    useEffect(()=>{
        setStatus(getStatus(statusLabel));
    }, [statusLabel]);
    

    useEffect(() => {
        if(status && status.name !==  statusLabel) {
            handleClickStatus(requestId, status.name);
        }
    }, [status]);


    return (
        <div className="content-quote">
            {status?.name === 'Leída' || status?.name === 'No leída' ? 
                <Dropdown 
                    title={statusLabel}
                    elements={[ "Leída", "No leída"]}
                    value={statusLabel}
                    disabled={disabled}
                    white={true}
                    setValue={(e:any)=> setStatus(getStatus(e))}
                    onClick={onClick}
                    classNameHeader={status.name === 'Leída' ? 'content-quote-leida box-shadow-card':'content-quote-no-leida box-shadow-card'}
                /> :
                <div className="tinytext-header content-quote-header box-shadow-card" style={{backgroundColor: "var(--"+status?.color+")"}}>
                    <div className="tinytext-header content-quote-header-text" style={{color: (status?.id === 1 || status?.id===5? 'var(--gray-1)': 'white')}}>{status?.name}</div>
                    { /*status?.id === 1 ? <ArrowDown /> : <ArrowDown_White />*/}
                </div>
            }
            {number > 0 && <div className="tinytext-header content-quote-header-number box-shadow-card">Nº {number}</div>}
         </div> 
    )
}