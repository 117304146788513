import React, { useEffect, useRef, useState, useCallback } from "react";

import Lottie from "lottie-web";

import './Animation.css';

interface AnimationProps {
    animation: string;
    nameAnimation?: string;
}

export const Animation: React.FC<AnimationProps> = ({
    animation, 
    nameAnimation = "",

}) => {

    let animationRef = useRef<HTMLDivElement>(null); 

    const [currentAnimationName, setCurrentAnimationName] = useState<string>("");

    const createAnimation = useCallback(() => {
        if(animationRef.current) {     
            Lottie.destroy(nameAnimation); 
            Lottie.loadAnimation({
                container: animationRef.current, // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path:  animation, // the path to the animation json
                name: nameAnimation,              
          });
        } 
    }, [animation]);


    useEffect(() => {
        if(currentAnimationName !== nameAnimation) {
            setCurrentAnimationName(nameAnimation);
            createAnimation();
        }
    }, [animation]);



    return (
        <div className= "animation-video" ref={animationRef}></div>
    )
}