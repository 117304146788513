import React, { useEffect, useState } from "react";

import './PreviewDoc.css';
import { GetAttachIcon } from "../../utils/GetAttachIcon";

interface PreviewDocProps {
    name: string;
    url: string;
    disabled?: boolean;
}

export const PreviewDoc: React.FC<PreviewDocProps> = ( {
    name,
    url,
    disabled = false
}) => {
    
    return (
        <a className={`previewDoc ${disabled && 'previewDoc-disabled'}`} href={url} target="_blank">
            <img src={GetAttachIcon(name, url)}/>
            <div className="paragraph previewDoc__title">{name}</div>
        </a>
    )
}