import React, { useState } from "react";

import './Packages.css';
import { Dropdown } from "../../../Dropdown/Dropdown";
import { CargoDescription } from "../../../../Consts/CargoDescription";
import { PackageLCLError, PackageLCLInterface } from "../../../../interfaces/LCL";
import { InputElement } from "../../../InputElement/InputElement";
import { UMListKilogramsMobile, UMUnitMobile } from "../../../../Consts/UMlist";
import { Trash } from "../../../../icons/outline/Trash";

interface PackagesProps {
    num: number;
    bulto: PackageLCLInterface;
    handleRemoveBulto: (index: number)=>void;
    handleAddBulto: ()=>void;
    add: boolean;
    handleChangeInput: (id: number, type: "type" | 'height' | 'quantity' | 'width' | 'tall'| "weight" | "unitWeight" | "unitMeasurement" , value: any) => void;
    getTotalWeight?: ()=>void;
    getTotalVolume?: ()=>void;
    showTotalAdd?: boolean;
}

export const Packages: React.FC<PackagesProps> = ({
    num,
    bulto,
    handleRemoveBulto,
    handleAddBulto,
    add,
    handleChangeInput,
    getTotalWeight = ()=>{},
    getTotalVolume = ()=>{},
    showTotalAdd = false
}) => {

    const [error, setError] = useState<PackageLCLError>({
        type: {
            message: "",
            status: false
        },
        quantity:  {
            message: "",
            status: false
        },
        weight:  {
            message: "",
            status: false
        },
        unitWeight:  {
            message: "",
            status: false
        },
        width:   {
            message: "",
            status: false
        },
        height:  {
            message: "",
            status: false
        },
        tall:  {
            message: "",
            status: false
        },
        unitMeasurement:  {
            message: "",
            status: false
        },
    });

    // handle new bulto
    const handleAddNewBulto = () => {
        setError({
            type: {
                message: "Selecciona tipo de embalaje",
                status: bulto.type.length>0 ? false : true
            },
            quantity:  {
                message: "Ingresa cantidad",
                status: bulto.quantity && bulto.quantity>0 ? false : true
            },
            weight:  {
                message: "Ingresa peso bruto",
                status: bulto.weight && bulto.weight>0 ? false : true
            },
            unitWeight:  {
                message: "Selecciona unidad de medida",
                status: bulto.unitWeight && bulto.unitWeight.length>0 ? false : true
            },
            width:   {
                message: "Ingresa ancho",
                status: bulto.width && bulto.width>0 ? false : true
            },
            height:  {
                message: "Ingresa largo",
                status: bulto.height && bulto.height>0 ?false : true
            },
            tall:  {
                message: "Ingresa altura",
                status: bulto.tall && bulto.tall>0 ? false : true
            },
            unitMeasurement:  {
                message: "Selecciona unidad de medida",
                status: bulto.unitMeasurement && bulto.unitMeasurement.length>0 ? false : true
            },
        });
        (bulto.type.length>0 && bulto.quantity && bulto.weight && bulto.unitWeight && bulto.width && 
            bulto.tall && bulto.height && bulto.unitMeasurement) && handleAddBulto();
    }

    return (
        <div className="packages-container">
            <div className="paragraph-header packages-colorText">Paquete No {num}</div>
            <div className="packages-row">
                <div className="packages-row-content">
                    <Dropdown
                        title={"Tipo de embalaje"}
                        elements={CargoDescription}
                        value={bulto.type?bulto.type:CargoDescription[0]}
                        error={error.type}
                        setValue={(e)=>handleChangeInput(num-1, 'type', e)}
                    />
                    <InputElement
                        value={bulto.quantity}
                        name="Cantidad"
                        title="Cantidad"
                        onChange={(e)=>handleChangeInput(num-1, 'quantity', e)}
                        error={error.quantity}
                    />
                </div>
                
                <div className="packages-row-content">
                    <InputElement
                        value={bulto.weight}
                        name="Peso bruto"
                        title="Peso bruto"
                        onChange={(e)=>handleChangeInput(num-1, 'weight', e)}
                        error={error.weight}
                    />
                    <Dropdown
                        title="Unidad"
                        elements={UMListKilogramsMobile}
                        value={bulto.unitWeight?bulto.unitWeight:(window.innerWidth<=600 ? UMListKilogramsMobile[0] : UMListKilogramsMobile[0])}
                        setValue={(e)=>handleChangeInput(num-1, 'unitWeight', e)}
                        roleText='LCLCDtotalWeightUnitRole'
                        error={error.unitWeight}
                    />
                </div>
            </div>

            <div className="packages-row packages-row-first-mobile packages-row-first-mobile__1 packages-row-first-mobile_">
                <Dropdown
                    title={"Tipo de embalaje"}
                    elements={CargoDescription}
                    value={bulto.type?bulto.type:CargoDescription[0]}
                    error={error.type}
                    setValue={(e)=>handleChangeInput(num-1, 'type', e)}
                />
                <InputElement
                    value={bulto.quantity}
                    name="Cantidad"
                    title="Cantidad"
                    onChange={(e)=>handleChangeInput(num-1, 'quantity', e)}
                    error={error.quantity}
                />
            </div>

            <div className="packages-row packages-row-first-mobile packages-row-first-mobile__2">
                <InputElement
                    value={bulto.weight}
                    name="Peso bruto"
                    title="Peso bruto"
                    onChange={(e)=>handleChangeInput(num-1, 'weight', e)}
                    error={error.weight}
                />
                <Dropdown
                    title="Unidad"
                    elements={UMListKilogramsMobile}
                    value={bulto.unitWeight?bulto.unitWeight:(window.innerWidth<=600 ? UMListKilogramsMobile[0] : UMListKilogramsMobile[0])}
                    setValue={(e)=>handleChangeInput(num-1, 'unitWeight', e)}
                    roleText='LCLCDtotalWeightUnitRole'
                    error={error.unitWeight}
                />
            </div>

            <div className="packages-row packages-row-second">
                <InputElement
                    value={bulto.width}
                    name="Ancho"
                    title="Ancho"
                    onChange={(e)=>handleChangeInput(num-1, 'width', e)}
                    error={error.width}
                />
                <div className="smalltext packages-xColor">x</div>
                <InputElement
                    value={bulto.tall}
                    name="Largo"
                    title="Largo"
                    onChange={(e)=>handleChangeInput(num-1, 'tall', e)}
                    error={error.tall}
                />
                <div className="smalltext packages-xColor">x</div>
                <InputElement
                    value={bulto.height}
                    name="Alto"
                    title="Alto"
                    onChange={(e)=>handleChangeInput(num-1, 'height', e)}
                    error={error.height}
                />
                <Dropdown
                    title="Unidad"
                    elements={UMUnitMobile}
                    value={bulto.unitMeasurement?bulto.unitMeasurement:(window.innerWidth<=600 ? UMUnitMobile[0] : UMUnitMobile[0])}
                    setValue={(e)=>handleChangeInput(num-1, 'unitMeasurement', e)}
                    roleText='LCLCDtotalWeightUnitRole'
                    error={error.unitMeasurement}
                />
            </div>

            <div className="packages-row packages-row-second-mobile">
                <div className="packages-row packages-row-second-mobile-1">
                    <InputElement
                        value={bulto.width}
                        name="Ancho"
                        title="Ancho"
                        onChange={(e)=>handleChangeInput(num-1, 'width', e)}
                        error={error.width}
                    />
                    <div className="smalltext packages-xColor">x</div>
                    <InputElement
                        value={bulto.tall}
                        name="Largo"
                        title="Largo"
                        onChange={(e)=>handleChangeInput(num-1, 'tall', e)}
                        error={error.tall}
                    />
                    <div className="smalltext packages-xColor">x</div>
                    <InputElement
                        value={bulto.height}
                        name="Alto"
                        title="alto"
                        onChange={(e)=>handleChangeInput(num-1, 'height', e)}
                        error={error.height} 
                    />
                </div>
                <Dropdown
                    title="Unidad"
                    elements={UMUnitMobile}
                    value={bulto.unitMeasurement?bulto.unitMeasurement:(window.innerWidth<=600 ? UMUnitMobile[0] : UMUnitMobile[0])}
                    setValue={(e)=>handleChangeInput(num-1, 'unitMeasurement', e)}
                    roleText='LCLCDtotalWeightUnitRole'
                    error={error.unitMeasurement}
                />
            </div>
            <div className="packages-add">
                {add ? <div className="packages-add--text smalltext-header" onClick={()=>handleAddNewBulto()}>
                    {window.innerWidth <= 600 ? "+ Agregar" : "+ Agregar paquete"}
                    </div> 
                : <div></div> }
                {add  &&  <Trash style={{cursor: "pointer"}} onClick={()=>handleRemoveBulto(num-1)} />}
                {/*{showTotalAdd &&
                <div className="packages-add-total"> 
                    <span className="small-bold">Envío total:</span>
                    <div className="small-subheader lclcd-paquete-colorBlack">{` ${getTotalWeight()} kg;  ${getTotalVolume()} m3`}</div>
                </div>} */}
            </div>
        </div>
    ) 
}