import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type PhoneBlackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const PhoneBlack: React.FC<PhoneBlackProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/phone_black.svg"} alt="" />
    </div>
  );
};
