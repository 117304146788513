import React, { useCallback, useEffect, useState } from "react";


import './Suscription.css';
import { HeadPage } from "../HeadPage/HeadPage";
import { TextField } from "../TextField/TextField";
import { Dropdown } from "../Dropdown/Dropdown";
import { CreditNumber } from "../CreditNumber/CreditNumber";
import { CardContent } from "../CardContent/CardContent";
import { subscriptionInterface } from "../../interfaces/SubscriptionInterface";
import { FormatDate } from "../../utils/formatDate";
import { PlanInterface } from "../../interfaces/PlansInterface";
import { GetPlanById } from "../../Services/PlanArkabia.service";
import { toast } from "react-toastify";
import { PaymentMethod } from "../../interfaces/PaymentMethodInterface";
import { GetPaymentMethodById } from "../../Services/PaymentMethod.service";
import { SecondaryPlanInterface } from "../../interfaces/SecondaryPlanInterface";
import { GetSecondaryPlanById } from "../../Services/SecondaryPlanArkabia.service";
import { CreateScheduleOperation, GetScheduleOperation } from "../../Services/ScheduleOperation.service";
import { ScheduleOperation } from "../../interfaces/ScheduleOperation";
import { Button } from "../Button/Button";
import { FormatISODate } from "../../utils/FormatISODate";
import { BeforeDay } from "../../utils/BeforeDay";
import { useSelector } from "react-redux";
import { Loader } from "../Loader/Loader";

interface SuscriptionProps {
    subscription: subscriptionInterface;
    handleAdministrar: () => void;
    handlePlan: () => void;
}

export const Suscription: React.FC<SuscriptionProps> = ( {
    subscription,
    handleAdministrar,
    handlePlan
}) => {

    const {userDetail} = useSelector((state: any) => state.user);

    const [loading, setLoading] = useState<boolean>(false);
    const [plan, setPlan] = useState<PlanInterface>();
    const [secondaryPlan, setSecondaryPlan] = useState<SecondaryPlanInterface>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
    const [scheduleOperation, setScheduleOperation] = useState<ScheduleOperation>();
    const [newPlan, setNewPlan] = useState<PlanInterface>();


    // method to get the plan of the user
    const getPlanById = async (subscriptionType: string, planId: string) => {
        try {
            setLoading(true);
            const response: any = await (subscriptionType === "primary" ?  GetPlanById(planId) : GetSecondaryPlanById(planId));
            // console.log("🚀 ~ getPlanById ~ response:", response)
            if(response) {
                subscription.subscriptionType === "primary" ? setPlan(response) : setSecondaryPlan(response);
            }     
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getPlanById ~ error:", error);
            setLoading(false);
            toast.error("Error al obtener el plan");
        }
    }

    // metho to get the payment method active
    const getPaymentMethod = async (paymentMethodId: string) => {
        try {
            const response = await GetPaymentMethodById(paymentMethodId);
            // console.log("🚀 ~ getPaymentMethod ~ response:", response)
            if(response) {
                setPaymentMethod(response);
            }
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getPaymentMethod ~ error:", error);
            setLoading(false);
            toast.error("Error al obtener el método de pago");
        }
    }


    // method to get the plan of the user
    const getNewPlanById = async (planId: string) => {
        try {
            setLoading(true);
            const response = await GetPlanById(planId);
            if(response) {
                setNewPlan(response);
            }     
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getPlanById ~ error:", error);
            setLoading(false);
            toast.error("Error al obtener el plan");
        }
    }

    const getSchetuleOperationById = async (id: string) => {
        setLoading(true);
        try {
          const response = await GetScheduleOperation(id);
        //   console.log("🚀 ~ getSchetuleOperationById ~ response:", response)
          if(response && response.source !== "autoPlan") {
            setScheduleOperation(response);
            (response.newPlanId && response.newPlanId.length>0) && getNewPlanById(response.newPlanId);
          }
          setLoading(false);
        } catch (error) {
          console.log("🚀 ~ getSchetuleOperationById= ~ error:", error);
          setLoading(false);
        }
    }

    // method to cancel the subscription
    const cancelSubscription = async () => {
        console.log("delete subscription");
        if(scheduleOperation && scheduleOperation.type === "cancelSubscription") {
          toast.error("Ya tienes una cancelación de suscripción programada");
          return;
        }
        if(subscription) {
          setLoading(true);
          try {
            const scheduleOperationData: ScheduleOperation = {
              subscriptionId: subscription?.id || "",
              paymentMethodId: subscription?.paymentMethodId || "",
              newPlanId: "",
              dateExecution: FormatISODate(BeforeDay(subscription.billingDate.toDate())),
              userId: userDetail.id || "",
              type: "cancelSubscription",
              status: "pending",
              source: "web",
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              executed: false,
            }
            const response = await CreateScheduleOperation(scheduleOperationData);
            // console.log("🚀 ~ handleDeleteSubscription ~ response:", response)
            if(response) {
                setTimeout(() => {
                  toast.success("se ha programado la cancelación de la suscripción para: "+FormatDate(BeforeDay(subscription.billingDate.toDate())), {autoClose: 6500});
                  getPlanById(subscription.subscriptionType, subscription.planId); 
                  getSchetuleOperationById(subscription.scheduleOperationId || "")
                }, 1500);
            }
            setLoading(false);
          } catch (error) {
            console.log("🚀 ~ handleDeleteSubscription ~ error:", error);
            setLoading(false);
            toast.error("Error al cancelar la suscripción");
          }
        }   
    }

    // method to scroll to the top of the page
    const scrollToTop = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        getPlanById(subscription.subscriptionType, subscription.planId);
        subscription.paymentMethodId.length>0 && getPaymentMethod(subscription.paymentMethodId);
        subscription.scheduleOperationId && getSchetuleOperationById(subscription.scheduleOperationId || "")
    }, [subscription]);

    return (
        <>{ !loading ? <>
            <CardContent>
                <div className="suscription">
                    <HeadPage
                        title={`Mi suscripción`}
                        subTitle=""
                    />
                    {scheduleOperation && scheduleOperation.type === "changePlan" && <div className="smalltext">Se ha programado un cambio de plan de {plan?.title} a {newPlan?.title} para el: {FormatDate(scheduleOperation.dateExecution.toDate())}</div>}
                    {scheduleOperation && scheduleOperation.type === "cancelSubscription" && <div className="smalltext">Se ha programado la cancelación del plan {plan?.title} para el: {FormatDate(new Date(scheduleOperation.dateExecution.toDate()))}</div>}

                    <div className="suscription-row">
                        <div className="suscription-two">
                            <TextField 
                                title='Fecha de suscripción'
                                placeholder=' '
                                value={FormatDate(subscription.subscriptionDate.toDate())}
                                onChange={()=>{}}
                                disabled={true}
                            />
                            <TextField 
                                title='Fecha de facturación'
                                placeholder=' '
                                value={FormatDate(subscription.billingDate.toDate())}
                                onChange={()=>{}}
                                disabled={true}
                            />
                        </div>
                        <div className="suscription-two">
                            <TextField 
                                title='Monto de facturación'
                                placeholder=' '
                                value={`${subscription.billingAmount} ${subscription.currency}`}
                                onChange={()=>{}}
                                disabled={true}
                            />
                            <Dropdown 
                                title="Método de pago"
                                elements={['Cargo automático', 'Cargo manual']}
                                disabled
                                value={'Cargo automático'}
                                setValue={(e:any)=> {}}/>
                        </div>
                    </div>

                    {!loading && paymentMethod && <div className="subscription-creditNumber" >
                        <div className="suscription-two">
                            <CreditNumber
                                brandCard={paymentMethod.cardBrand}
                                lastDigits={paymentMethod.lastFour}
                            />
                            <div className="tinytext">se renueva automaticamente el {FormatDate(subscription.billingDate.toDate())}</div>
                        </div>
                        <div className="tinytext-header detailSubscription-text-blue" onClick={()=>handleAdministrar()}>Administrar</div>
                    </div>}

                    {!loading && <div className="subscription-plan" >
                        <div className="subscription-column">
                            <div className="subscription-text-title">Plan</div>
                            <div className="subscription-text-plan">{subscription.subscriptionType === "primary" ?  plan?.title || "" : secondaryPlan?.name || ""}</div>
                        </div>
                        {!scheduleOperation && <div className="tinytext-header detailSubscription-text-blue" onClick={()=>handlePlan()}>Cambiar de plan</div>}
                    </div>}
                </div>
            </CardContent>
           {!scheduleOperation &&  <div className="subscription-cancel">
                <Button content="Cancelar plan" size={`${window.innerWidth<768 ? "small" : "normal"}`} color="red" onClick={()=>cancelSubscription()} />
            </div>}
        </> : <Loader />
        }</>
    )
}