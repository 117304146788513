import React, { useState } from "react";
import {format, isSameDay} from "date-fns";

import { DaysName } from "../../../../Consts/daysName";
import { TranslateMonth } from "../../../../utils/translateMonth";

import './HeaderResponsive.css';

interface HeaderResponsiveProps {
    days:  any[],
    selectedDay: Date,
    header?: boolean;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
}

export const HeaderResponsive: React.FC<HeaderResponsiveProps> = ({
    days,
    selectedDay,
    setSelectedDate,
}) => { 

    const getSelectedDayStyle = (day: Date) => {
        if (isSameDay(day, selectedDay)) {
            return 'HeaderResponsive-content-now';
        }
    } 

    const getTodayStyle = (day: Date) => {
        if (isSameDay(selectedDay, day)) {
            return 'HeaderResponsive-day-now';
        }
    }

    return (        
        <div className="HeaderResponsive">
            { selectedDay && days.length > 0 && days.map((d, idx) => {
                   return (
                    <div onClick={() => {setSelectedDate(d)}} key={idx} className={ `smalltext-header HeaderResponsive-content  ${ getSelectedDayStyle(d)}`}>
                        <div className={isSameDay(selectedDay, d) ? 'smalltext-header '+getTodayStyle(d) : 'HeaderResponsive-day'}>
                            {DaysName[d.getDay()]}
                        </div>
                        <div className={isSameDay(selectedDay,d) ? 'smalltext-header HeaderResponsive-date-now' : 'smalltext-header HeaderResponsive-date'}>
                            {d.getDate()+ " " + (TranslateMonth(format(d, 'MMMM')))?.substring(0,3)}
                        </div>
                    </div>
                   )
               }) }
        </div>
    )
}