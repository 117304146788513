import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type Home_whiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Home_white: React.FC<Home_whiteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/home_alt_fill.svg"} alt="" />
    </div>
  );
};
