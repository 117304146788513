import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type HomeWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const HomeWhite: React.FC<HomeWhiteProps> =({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/svg/icono_home.svg"} style={{height: "21px"}} alt="" />
        </div>
    )
}