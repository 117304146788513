import React, { useEffect } from "react";

/** importando componentes */
import { TextField } from "../..";
import { SliderContent } from "../../SliderContent/SliderContent";

/** importando utilidades */
import { RoundNumber } from "../../../utils/roundNumber";

/** importando estilo */
import './RowGroupTaxesSupplier.css';

/** declarando variables de interface */
import { ArrivalControlChannel, Taxes } from "../../../interfaces/ArrivalControlChannelInterface";
import { ConverMoneyFormat } from "../../../utils/ConvertDecimal";
import { InputElement } from "../../InputElement/InputElement";
import { InputElementMin } from "../../InputElementMin/InputElementMin";

interface RowGroupTaxesSupplierProps {
    taxes: Taxes,
    setArrivalControlChannel: React.Dispatch<React.SetStateAction<ArrivalControlChannel>>;
    // setTaxes: React.Dispatch<React.SetStateAction<any>>
    // taxesValue: Taxes,
    // setTaxesValue: React.Dispatch<React.SetStateAction<Taxes>>
    disabled: boolean
}

export const RowGroupTaxesSupplier: React.FC<RowGroupTaxesSupplierProps> = ({
    taxes,
    setArrivalControlChannel,
    // setTaxes,
    // taxesValue,
    // setTaxesValue,
    disabled
}) => {

    // metodos 
    const handleAdValoremChange = (value: number) => {
        setArrivalControlChannel((prev:any)=>({
            ...prev,
            dutiesTaxes: {
                ...prev.dutiesTaxes,
                taxes: {
                    ...prev.dutiesTaxes.taxes,
                    adValorem: Number(value)>=0? value : prev.dutiesTaxes.taxes.adValorem
                }
            }
        }));
    }

    const handleIgvChange = (value: number) => {
        setArrivalControlChannel((prev:any)=>({
            ...prev,
            dutiesTaxes: {
                ...prev.dutiesTaxes,
                taxes: {
                    ...prev.dutiesTaxes.taxes,
                    igv: Number(value)>=0? value : prev.dutiesTaxes.taxes.igv
                }
            }
        }));
    }

    const handleIpmChange = (value: number) => {
        setArrivalControlChannel((prev:any)=>({
            ...prev,
            dutiesTaxes: {
                ...prev.dutiesTaxes,
                taxes: {
                    ...prev.dutiesTaxes.taxes,
                    ipm: Number(value)>=0? value : prev.dutiesTaxes.taxes.ipm
                }
            }
        }));
    }

    const handlePerceptionChange = (value: number) => {
        setArrivalControlChannel((prev:any)=>({
            ...prev,
            dutiesTaxes:{
                ...prev.dutiesTaxes,
                taxes: {
                    ...prev.dutiesTaxes.taxes,
                    perception: Number(value)>=0? value : prev.dutiesTaxes.taxes.perception
                }
            }
        }));
    }

    // const getTotalTaxes = () => {
    //     const {adVlorem, antidumpi, igv, ipm, isc, perception, specifivRight, surcharge} = taxesValue;
    //     let sum = Number(adVlorem) + Number(igv) + Number(ipm) + Number(perception) + Number(exciseTax) +
    //               Number(specificLaw.value) + Number(antidumpingLaw.value) + Number(surcharge.value);
    //     setTaxesValue((prev: any) => {
    //         return {
    //             ...prev,
    //             total: RoundNumber((sum*100)/100)
    //         }
    //     })
    // }
        
    // useEffect(() => {
    //     getTotalTaxes();
    // }, [taxesValue.adValorem.value, taxesValue.igv.value, taxesValue.ipm.value, taxesValue.perception.value, taxesValue.exciseTax.value, taxesValue.specificLaw.value,
    //    taxesValue.antidumpingLaw.value, taxesValue.surcharge.value]);
       
    // useEffect(() => {
    //     handleAdValoremChange(taxes.adValorem.value?taxes.adValorem.value: 0);
    //     handleIgvChange(taxes.igv.value?taxes.igv.value: 0);
    //     handleIpmChange(taxes.ipm.value?taxes.ipm.value: 0);
    //     handlePerceptionChange(taxes.perception.value?taxes.perception.value:0)
    //     handleExciseTaxChange(taxes.exciseTax.value?taxes.exciseTax.value:0)
    //     handleSpecificLawChange(taxes.specificLaw.value?taxes.specificLaw.value:0)
    //     handleAntidumpingLawChange(taxes.antidumpingLaw.value?taxes.antidumpingLaw.value:0)
    //     handleSurchargeChange(taxes.surcharge.value?taxes.surcharge.value:0)

    // }, [total])

    useEffect(() => {
        const {adValorem, igv, ipm, perception } = taxes
        let sum = Number(adValorem) + Number(igv) + Number(ipm) + Number(perception);
        setArrivalControlChannel((prev:any) => ({
            ...prev,
            dutiesTaxes: {
                ...prev.dutiesTaxes,
                taxes: {
                    ...prev.dutiesTaxes.taxes,
                    total: Number(Number(RoundNumber(sum)).toFixed(2))
                }
            }
        }))
    }, [taxes.adValorem, taxes.igv, taxes.ipm, taxes.perception])

    return (
        <div role='rowGroup-taxes-supplier-role' className="rowGroup-taxes-supplier-container">
            <div className="rowGroup-taxes-supplier-body">
                <div className="rowGroupTaxesSupplier__ rowGroupTaxesSupplier_transparent" >
                    <div className="paragraph rowGroupTaxesSupplier_text" style={{color: "var(--gray-1)"}}>Ad Valorem</div>
                    <div className="rowGroupTaxesSupplier__b">
                        {/* <SliderContent value={taxes.adValorem} setValue={(e: any) => handleAdValoremChange(e)} disabled={disabled}/> */}
                        <InputElementMin 
                            title="" 
                            name="" 
                            value={taxes.adValorem} 
                            onChange={handleAdValoremChange} 
                            disable={disabled} />
                    </div>
                </div>
                <div className="rowGroupTaxesSupplier__ rowGroupTaxes_white" >
                    <div className="paragraph rowGroupTaxesSupplier_text" style={{color: "var(--gray-1)"}}>IGV (18%)</div>
                    <div className="rowGroupTaxesSupplier__b">
                        {/* <SliderContent value={taxes.igv} setValue={(e: any) => handleIgvChange(e)} disabled={disabled}/> */}
                        <InputElementMin 
                            title="" 
                            name="" 
                            value={taxes.igv}  
                            onChange={handleIgvChange} 
                            disable={disabled} />
                    </div>
                </div>
                <div className="rowGroupTaxesSupplier__ rowGroupTaxesSupplier_transparent" >
                    <div className="paragraph rowGroupTaxesSupplier_text" style={{color: "var(--gray-1)"}}>IPM (2%)</div>
                    <div className="rowGroupTaxesSupplier__b">
                        {/* <SliderContent value={taxes.ipm} setValue={(e: any) => handleIpmChange(e)} disabled={disabled}/> */}
                        <InputElementMin 
                            title="" 
                            name="" 
                            value={taxes.ipm}  
                            onChange={handleIpmChange} 
                            disable={disabled} />
                    </div>
                </div>
                <div className="rowGroupTaxesSupplier__ rowGroupTaxes_white" >
                    <div className="paragraph rowGroupTaxesSupplier_text" style={{color: "var(--gray-1)"}}>Percepción (3.5%)</div>
                    <div className="rowGroupTaxesSupplier__b">
                        {/* <SliderContent value={taxes.perception} setValue={(e: any) => handlePerceptionChange(e)} disabled={disabled}/> */}
                        <InputElementMin 
                            title="" 
                            name="" 
                            value={taxes.perception}  
                            onChange={handlePerceptionChange} 
                            disable={disabled}  />
                    </div>
                </div>
               
            </div> 

            {/** Footer total*/}
            <div className="paragraph-header rowGroupTaxesFooter__">
                <div className="paragraph-header rowGroupTaxesFooter__text" style={{color: "var(--gray-1)"}}> Total USD </div>
                <div className="paragraph-header rowGroupTaxesFooter__text" style={{color: "var(--gray-1)"}}>$ {ConverMoneyFormat(taxes.total.toString())}</div>
            </div>
        </div>
    )
}

