import React, { useEffect, useState } from "react";

import "./CardCategoryPlan.css";
import { CardContent } from "../CardContent/CardContent";
import { Subheader } from "../Subheader/Subheader";
import { Button } from "../Button/Button";
import { ClockBlack } from "../../icons/outline/ClockBlack";
import { Revision } from "../../icons/outline/Revisión";
import { CheckBlack } from "../../icons/outline/CheckBlack";
import { GetServicePlanById } from "../../Services/ServicePlan.service";
import { TypePlan } from "../../@types/TypePlan";
import { ServicePlanInterface } from "../../interfaces/ServicesPlanInterface";
import { Check_green } from "../../icons/solid/Check_green";

interface CardCategoryPlanProps {
    servicePlan: ServicePlanInterface | undefined;
    handleComparar: () => void;
    handleComprar: () => void;
};


export const CardCategoryPlan: React.FC<CardCategoryPlanProps> = ({
    servicePlan,
    handleComparar,
    handleComprar
}) => {

    const [activePlan, setActivePlan] = React.useState<number>(0);

    return (
        <CardContent className="cardContentPlan">
            <div className="cardContentPlan-tabs">                
                { servicePlan?.plans.values.map(s => s.value).map((s, idx) => (
                    <Subheader
                        key={idx}
                        active={idx === activePlan} 
                        content={s}
                        handleClick={()=>setActivePlan(idx)} 
                        className='smalltext-header' 
                    />
                ))}
            </div>


            { servicePlan && <>
                <div className="cardContentPlan-detail-column">
                    <div className="cardContentPlan__row" style={{paddingTop: 10, alignItems: 'center', paddingBottom: '-10px'}}>
                        <div className="paragraph-header">Precio</div>
                        <div className="small-subheader cardContentPlan-Price">
                                {servicePlan?.price.values[activePlan].value}
                            <div className="cardContentPlan-moneda tinytext">
                                {servicePlan?.price.values[activePlan].currency}
                            </div>
                        </div>
                    </div>

                    <div className="cardContentPlan__row">
                        <div className="paragraph" style={{color: '#333333'}}>{servicePlan.description.values[activePlan].value}</div>
                    </div>
                </div>

                <div className="cardContentPlan__row">
                    <div className="cardContentPlan__row__min smalltext-header">
                        <ClockBlack/>
                        Entrega en {servicePlan?.deliveryTime.values[activePlan].value} días
                    </div>
                    <div className="cardContentPlan__row__min smalltext-header">
                        <Revision/>
                        {servicePlan?.numRevision.values[activePlan].value} 
                        {servicePlan?.numRevision.values[activePlan].value === '1' ? ' revisión' : ' revisiones'}
                    </div>
                </div>

                <div className="cardContentPlan__row smalltext-header">Qué incluye</div>

                <div className="cardContentPlan__column">
                    { servicePlan.detail.length>0 && servicePlan.detail.map((item, idx) => (
                        <div className="cardContentPlan__row__min smalltext" style={{color: '#333333'}}>
                            { item.values[activePlan].type === 'check' ? item.values[activePlan].value && <Check_green/> : <div className="smalltext-header">{item.values[activePlan].value }</div>}
                            { item.values[activePlan].type === 'check' ? item.values[activePlan].value && item.title : item.title }
                        </div>
                    ))}
                </div> 

                <div className="cardContentPlan__row ">
                    <div className="cardContentPlan-button">
                        <Button 
                            color="black-50"
                            // content={window.innerWidth<945 ? "Comparar" : "Comparar planes"}
                            content="Comparar planes"
                            onClick={()=>handleComparar()}
                        />
                    </div>
                    <Button 
                        color="black-75"
                        content="Comprar"
                        onClick={()=>handleComprar()}
                    />
                </div>
            </> }
        </CardContent>
    );
};
