import React, { useEffect, useRef, useState } from "react";

// Importando Store

/** importando componentes */
import { Loader } from "../Loader/Loader";
import { ImportationSubstructureConsignee } from "../ImportationSubstructureConsignee/ImportationSubstructureConsignee";
import { ImportationSubstructureCargoagentandhscodeInput } from "../ImportationSubstructureCargoagentandhscode/Input/ImportationSubstructureCargoagentandhscodeInput";
import { ReviewCard } from "../ReviewCard/ReviewCard";
import { TrackingShipping } from "../TranckingShipping/TrackingShipping";
import { UploadFileArray } from "../UploadFile/UploadFileArray/UploadFileArray";
import { DeliveryOrder } from "../DeliveryOrder/DeliveryOrder";
import { FilesFLC } from "../FilesFLC/FilesFLC";
import { InvoceTranslationImporter } from "../InvoceTranslation/Importer/InvoceTranslationImporter";
import { InvoceTranslationLegal } from "../InvoceTranslation/LegalRepresentative/InvoceTranslationLegal";
import { InvoceTranslationSupplier } from "../InvoceTranslation/Supplier/InvoceTranslationSupplier";
import { ArrivalNotice } from "../ArrivalandControl/ArrivalNotice/ArrivalNotice";
import { RowPhaseSeven } from "../Row/RowPhaseSeven/RowPhaseSeven";
import { ChannelControl } from "../ArrivalandControl/ChannelControl/ChannelControl";
import { ArrivalDelivery } from "../ArrivalandControl/Delivery/ArrivalDelivery";
import { ButtonEditChange } from "../ButtonEditChange/ButtonEditChange";
import { Quote } from "../Quotation/Quote/Quote";

/** Importando Interfaces */
import { QuotationDB } from "../../interfaces/Quotation";
import { OrderInterface } from "../../interfaces/OrderInterface";
import { Consignee } from "../../interfaces/Consignee";
import { ConsigneeError } from "../../interfaces/ConsigneeError";
import { DocumentationInterface } from "../../interfaces/Documentation";
import { BillingReviewInterface } from "../../interfaces/BillingReviewInterface";
import { OperationInterface } from "../../interfaces/Operation";
import { RequestQuoteInterface } from "../../interfaces/RequestQuote";
import { CargoShippet } from "../../interfaces/CargoShippet";
import { InvoiceTranslation } from "../../interfaces/InvoiceTranslation";
import { ArrivalControlChannel } from "../../interfaces/ArrivalControlChannelInterface";

/** Importando constantes */
import { TypePersons } from "../../Consts/TypePersons";
import { TypeDocuments } from "../../Consts/TypeDocuments";
import { CompressFile, DocsFileValidation, ImageFileValidation, PDFFileValidation, SpreadSheetFileValidation } from "../../Consts/FilesValidation";

// importando servicios

/** Importando types */
import { ImportTimelineStages } from "../../@types/timelineStagesTypes";

/** Importando Estilo */
import './PhaseCustomsAgency.css';
import { ImportTimelineSupplier } from "../ImportTimelineSupplier/ImportTimelineSupplier";
import { QuoteTransportData } from "../QuoteTransportData/QuoteTransportData";
import { QuoteCustomsData } from "../QuoteCustomsData/QuoteCustomsData";
import { SupplierSearchRequirements } from "../SupplierSearchRequirements/SupplierSearchRequirements";
import { HeaderCustomsPhases } from "../HeaderCustomsPhases/HeaderCustomsPhases";
import { ButtonBadget } from "../ButtonBadget/ButtonBadget";
import { MessageBlack } from "../../icons/outline/MessageBlack";
import { phaseTypeService } from "../../Consts/phasesImport";
import { useIntersectionObserver } from "../../Hooks/useIntersectionObserver";


interface PhaseCustomsAgencyProps { 
    phase: number;
    setPhase: (phase: number) => void;

    orderStatus: ImportTimelineStages;
    setOrderStatus: React.Dispatch<React.SetStateAction<ImportTimelineStages>>;
    quotationDetail: QuotationDB | undefined;
    orderDetail: OrderInterface;
    setOrderDetail: React.Dispatch<React.SetStateAction<OrderInterface>>;
    requestQuote: RequestQuoteInterface;
    setRequestQuote: React.Dispatch<React.SetStateAction<RequestQuoteInterface>>;
    consigneeSelected: string | undefined;
    consigneeList: any[],
    consignee: Consignee;
    setConsignee: React.Dispatch<React.SetStateAction<Consignee>>;
    consigneeError: ConsigneeError;
    handleSaveConsigneeClick: React.MouseEventHandler<HTMLButtonElement>;
    assignConsignee: (idx: number) => void;
    isDisabled: boolean;
    setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    handleSaveBulkingAgent: () => void;
    bulkingAgent: string;
    setBuilkingAgent: React.Dispatch<React.SetStateAction<string>>; 
    operationDetail: OperationInterface | undefined;
    documentationData: DocumentationInterface;
    setDocumentationData: React.Dispatch<React.SetStateAction<DocumentationInterface>>;
    invoice: any;
    billingReviewData: BillingReviewInterface;
    setBillingReviewData: React.Dispatch<React.SetStateAction<BillingReviewInterface>>;
    handleInvoiceChange: React.ChangeEventHandler<HTMLInputElement>;
    handleDeleteBilling: (idx: number) => void;
    review: any;
    setReview: React.Dispatch<React.SetStateAction<any>>;
    handleRatingReviewChange: (e: number) => void;
    handleValueReviewChange: (e: string) => void;
    handleEndServiceClick: () => void;
    cargoShippet: CargoShippet;
    setCargoShippet: React.Dispatch<React.SetStateAction<CargoShippet>>;
    invoiceTranslation: InvoiceTranslation;
    setInvoiceTranslation: React.Dispatch<React.SetStateAction<InvoiceTranslation>>;
    commercialLevel:  string[];
    handleConfirmInvoiceTranslationClick: (isNew: boolean) => void;
    getInvoiceTranslation: (id: string, incoterm: string, coin: string) => void;
    handleInvoicestatementSave: (providerIdx: number, q1: boolean, q2: boolean, q3: boolean, q4: boolean, q5: boolean, q6: boolean, q7: boolean, q8: boolean)=>void
    arrivalControlChannel: ArrivalControlChannel;
    setArrivalControlChannel: React.Dispatch<React.SetStateAction<ArrivalControlChannel>>;
    handleNoticeArrivalChange: (e: any) => void;
    handleDeleteNoticeArrival: (idx: number) => void;
    handleSaveArrivalControlChannel: () => void;
    showEdit: boolean;
    setShowEdit: React.Dispatch<React.SetStateAction<boolean>>,
    ChannelControls: string[];
    handleEditSix: () => void;
    handleClickCancelSix: () => void;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
 
    parentRef?: React.RefObject<HTMLDivElement>;    
    updateRqeustQuote: () => void;
}

export const PhaseCustomsAgency: React.FC<PhaseCustomsAgencyProps> = ({
    phase,
    setPhase,
    orderStatus,
    setOrderStatus,
    quotationDetail,
    orderDetail,
    setOrderDetail,
    requestQuote,
    setRequestQuote,
    consigneeSelected,
    consigneeList,
    consignee,
    setConsignee,
    consigneeError,
    handleSaveConsigneeClick,
    assignConsignee,
    isDisabled,
    setIsDisabled,
    handleSaveBulkingAgent,
    bulkingAgent,
    setBuilkingAgent,
    operationDetail,
    documentationData,
    setDocumentationData,
    invoice,
    billingReviewData,
    setBillingReviewData,
    handleInvoiceChange,
    handleDeleteBilling,
    review,
    setReview,
    handleRatingReviewChange,
    handleValueReviewChange,
    handleEndServiceClick,
    cargoShippet,
    setCargoShippet,
    invoiceTranslation,
    setInvoiceTranslation,
    commercialLevel,
    handleConfirmInvoiceTranslationClick,
    getInvoiceTranslation,
    handleInvoicestatementSave,
    arrivalControlChannel,
    setArrivalControlChannel,
    handleNoticeArrivalChange,
    handleDeleteNoticeArrival,
    handleSaveArrivalControlChannel,
    showEdit,
    setShowEdit,
    ChannelControls,
    handleEditSix,
    handleClickCancelSix,

    updateRqeustQuote,

    parentRef
    
}) => {
    
    const scrollRef = useRef<HTMLDivElement>(null);


    const phaseOneRef = useRef(null);
    const phaseTwoRef = useRef(null);
    const phaseThreeRef = useRef(null);
    const phaseFourRef = useRef(null);
    const phaseFiveRef = useRef(null);
    const phaseSixRef = useRef(null);
    const phaseSevenRef = useRef(null);

    const phaseRefs = [phaseOneRef, phaseTwoRef, phaseThreeRef, phaseFourRef, phaseFiveRef, phaseSixRef, phaseSevenRef]

    const activeIndex = useIntersectionObserver(phaseRefs); 

    const [currentPhase, setCurrentPhase] = useState<number>(0);
   

    // method to navigate to the section
    const navigateToSection = (refDiv:React.RefObject<HTMLDivElement> | undefined) => {
        if (scrollRef && scrollRef.current && refDiv && refDiv.current) {
            let leftScroll = refDiv.current.clientWidth*(phase-1);
            scrollRef.current?.scrollTo(leftScroll, 0);
        } 
    };

    // method to redirect to the section when the phase changes
    const redirectToPosition = (position: number) => {
        switch (position) {
            case 1:
                navigateToSection(phaseOneRef);
                break;
            case 2:
                navigateToSection(phaseTwoRef);
                break;
            case 3:
                navigateToSection(phaseThreeRef);
                break;
            case 4:
                navigateToSection(phaseFourRef);
                break;
            case 5:
                navigateToSection(phaseFiveRef);
                break;
            case 6:
                navigateToSection(phaseSixRef);
                break;
            case 7:
                navigateToSection(phaseSevenRef);
                break;
            default:
                break;
        }
    } 

    useEffect(() => {
        if(currentPhase !== -1 && phase !== currentPhase) {
            setCurrentPhase(phase);
            setTimeout(() => {
                redirectToPosition(phase)
            }, 1250);
        } else {
            setCurrentPhase(phase);
        }
    }, [phase]);

    useEffect(() => {
        if(activeIndex !== -1 && activeIndex !== phase-1) {
            setPhase(activeIndex+1);
            setCurrentPhase(activeIndex+1);
        }
    }, [activeIndex]);

    return ( 
        <>
           {window.innerWidth<=700 && <div ref={parentRef} style={{marginBottom: phase!== -1 ? "2.5rem" : "0"}}></div>}
            <div className="phaseCustomAgency-chat-content" >
                <HeaderCustomsPhases
                    numPhase={phase}
                    title={
                        orderDetail.serviceTypeId != 'busqueda-de-proveedor' ? 
                        (phaseTypeService.agenciamiento[phase-1]) : 
                        phaseTypeService.busquedaProveedor[phase-1]
                    }
                    handleMessageclick={()=>{}}
                />
                {/* <ButtonBadget
                    name={window.innerWidth <881 ? <MessageBlack/> : "Ver mensajes"}
                    handleBadget={()=>{}}
                    badget={0}
                /> */}
            </div>
            <div className="customsAgency-right-phases" id="customsAgencyPhases" ref={scrollRef}> 
                {(phase === 1 || window.innerWidth<=700) && <section id="phase-one" ref={phaseOneRef}>
                    <div className="phaseCustomsAgency-column phaseCustomsAgency-left">
                    {
                    // phase === 1 && 
                    // !showchat && 
                    orderDetail.serviceTypeId != 'busqueda-de-proveedor' &&
                    <ImportTimelineSupplier stage={orderStatus} setStage={setOrderStatus} />
                    }    
                
                    { orderStatus === 1 && phase === 1 &&
                    <div className="customsAgency-right-phases-one">  
                        <div className="small-bold customsAgency-colorText">Resumen</div>
                        <div className="customsAgency-right-phases-one-content box-shadow-card">
                            <QuoteTransportData
                                transport={requestQuote.transport}
                                isCourier={requestQuote.serviceTypeId === "courier"}
                            /> 
                            <QuoteCustomsData
                                requestQuoteId={requestQuote.id || ""}
                                updateData={updateRqeustQuote}
                                customs={requestQuote.customs}
                                isCourier={requestQuote.serviceTypeId === "courier"}
                                canUpdate={false}
                            /> 
                        </div>
                    </div> }
                    { orderDetail && operationDetail && phase === 1 &&
                    orderDetail.serviceTypeId === 'busqueda-de-proveedor' &&
                        <SupplierSearchRequirements
                            requirementsId={orderDetail.requirementsId || ''}
                            orderId={orderDetail.id || ''}
                            setOrderDetail={setOrderDetail}
                            companyName={orderDetail.companyName}
                        />
                    }
                    
                    { orderStatus !== 1 && 
                    (window.innerWidth >= 700 && phase === 1 || window.innerWidth <= 700) &&
                        orderDetail.serviceTypeId != 'busqueda-de-proveedor' && quotationDetail &&
                        <Quote
                            edit= {true}
                            setEdit={()=>{}}
                            rating={quotationDetail.numServiceRating?quotationDetail.numServiceRating:0}
                            countryOrigin={quotationDetail.countryOrigin}
                            transportInternational={quotationDetail.importCost.serviceOrigin.internationalTransport.toString()}
                            safe={quotationDetail.importCost.serviceOrigin.insurance.toString()}
                            expensesOrigin={quotationDetail.importCost.serviceOrigin.expensiveOrigin.toString()}
                            subTotal1={quotationDetail.importCost.serviceOrigin.subTotal.toString()}
                            subTotal2={quotationDetail.importCost.serviceDestination.subTotal.toString()}
                            destinationCountry={quotationDetail.destinationCountry}
                            storage={quotationDetail.importCost.serviceDestination.storage.toString()}
                            storageExpenses={quotationDetail.importCost.serviceDestination.storageExpenses.toString()}
                            onCosts={quotationDetail.importCost.serviceDestination.onCosts.toString()}
                            operatingExpenses={quotationDetail.importCost.serviceDestination.operationExpensive.toString()}
                            administrativeExpensive={quotationDetail.importCost.serviceDestination.administrativeExpensive?
                                quotationDetail.importCost.serviceDestination.administrativeExpensive.toString():'0.00'}
                            desadunaje={'quotationDetail.importCost.serviceDestination.desadunaje.toString()'}
                            approval={quotationDetail.importCost.serviceDestination.approval?quotationDetail.importCost.serviceDestination.approval.toString():'0.00'}
                            localTransport={quotationDetail.importCost.serviceDestination.localTransport.toString()}
                            handling={quotationDetail.importCost.serviceDestination.handling ? quotationDetail.importCost.serviceDestination.handling.toString():'0.00'}
                            deconsolidation={quotationDetail.importCost.serviceDestination.deconsolidation.toString()}
                            returnContain={quotationDetail.importCost.serviceDestination.returnContain.toString()}
                            commissionCustoms={quotationDetail.importCost.serviceDestination.commissionCustoms.toString()}
                            otherExpenses={quotationDetail.importCost.serviceDestination.otherExpenses ? quotationDetail.importCost.serviceDestination.otherExpenses.toString(): ''}
                            tax={quotationDetail.importCost.serviceDestination.tax.toString()}
                            total={quotationDetail.price.toString()}
                            toValue={quotationDetail.taxes.adValorem.toString()}
                            igv={quotationDetail.taxes.igv.toString()}
                            ipm={quotationDetail.taxes.ipm.toString()}
                            perception={quotationDetail.taxes.perception.toString()}
                            isc={quotationDetail.taxes.isc.toString()}
                            specificRight={quotationDetail.taxes.specificRight.toString()}
                            antidumpi={quotationDetail.taxes.antidumpi.toString()}
                            surcharge={quotationDetail.taxes.surcharge.toString()}
                            totalTaxes={quotationDetail.taxes.subTotal.toString()}
                            transitTime={quotationDetail.detail.transitTime}
                            storageBase={quotationDetail.detail.storageBase}
                            expiration={quotationDetail.detail.expiration}
                            restrictions={quotationDetail.restriction}
                            typeContainer={quotationDetail.typeContainer}
                            quotationObservation={quotationDetail.observation}
                            setQuotationObservation={()=>{}}
                            isInsurance={requestQuote.customs?.insurance}
                            isFcl={requestQuote.transport?.typeContainer?.fcl?true:false}
                            aboutStay={quotationDetail.detail.aboutStay? quotationDetail.detail.aboutStay:''}
                            isCourier={orderDetail.serviceTypeId === "courier"}
                            currency={quotationDetail.currency}
                        />
                    }
                    </div>
                </section>}

                {(phase === 2 || window.innerWidth<=700) && <section id="phase-two" ref={phaseTwoRef}>
                { consigneeSelected  &&  // seccion de consignatario
                    (orderDetail.serviceTypeId != 'busqueda-de-proveedor' ? 
                        <div className="phaseCustomsAgency-column">
                            <ImportationSubstructureConsignee
                                consigneeSelected={consigneeSelected}
                                consigneeElements={[...consigneeList.map(c=> c.typeIt === 'RUC' ? c.companyName : c.name+' '+c.lastName), "Agregar consignatario"]}
                                consignee={consignee}
                                setConsignee={setConsignee}
                                personOrCompanyElements={TypePersons}
                                documentTypeElements={TypeDocuments} 
                                handleSaveConsignee={handleSaveConsigneeClick}
                                errors={consigneeError}
                                handleConsigneeSelected={assignConsignee}

                                disabled={isDisabled}
                                setDisabled={setIsDisabled}
                            />
                            <ImportationSubstructureCargoagentandhscodeInput
                                textAreaRows={1}
                                HandleClickSave={handleSaveBulkingAgent}
                                bulkingAgentValue={bulkingAgent}
                                handleClickCancel={()=>setBuilkingAgent(orderDetail.bulkingAgent ? orderDetail.bulkingAgent : '')}
                                supplier
                                setBulkingAgentValue={setBuilkingAgent}
                            />
                        </div> :
                        <div className="phaseCustomsAgency-provider">
                            <DeliveryOrder 
                                deliveryOrderId={orderDetail.deliveryOrderId || ''}
                                servicePlanId={orderDetail.planId || ""}
                                orderId={orderDetail.id || ''}
                                setOrderDetail={setOrderDetail}
                            />
                        </div>
                    ) 
                }
                </section>}

                {(phase === 3 || window.innerWidth<=700) && <section id="phase-three" ref={phaseThreeRef}>
                { orderDetail && operationDetail && // seccion documentacion
                    <div className="phaseCustomsAgency-column">
                        {/* <div className="paragraph customsAgency-content--colotTextblack">
                            Tu agencia de aduana podrá ver y descargar esta información.
                        </div> */}
                        {orderDetail.serviceTypeId !== 'busqueda-de-proveedor' ? 
                            <FilesFLC
                                documentation={documentationData}
                                setDocumentation={setDocumentationData}
                                operationDetail={operationDetail}
                                orderDetail={orderDetail}
                                setOrderDetail={setOrderDetail}
                                hiddenTitle={true}
                                supplier
                                multimedia
                            /> :

                            <div className="phaseCustomsAgency-provider">
                                <UploadFileArray
                                    title='Facturación'
                                    subtitle='El proveedor colocará aquí tu comprobante de compra.'
                                    buttonContent="Subir archivo"
                                    fileValue={invoice}
                                    file={billingReviewData.billingUrl}
                                    handleFileValue={handleInvoiceChange}
                                    acceptType={`${CompressFile}, ${DocsFileValidation}, ${SpreadSheetFileValidation}, ${PDFFileValidation}, ${ImageFileValidation}`}
                                    handleDelete={handleDeleteBilling}
                                    multimedia
                                    disabled={isDisabled}
                                />

                                {review && <ReviewCard
                                    title="Reseña"
                                    companyName={orderDetail? orderDetail?.companyName: ''}
                                    concept= "Qué te pareció el servicio de"
                                    amount={orderDetail?.price?orderDetail.price: 0}
                                    addReview={false}
                                    responseProvider={false}
                                    valueRating={review.raintg}
                                    reviewValue={review ? review?.review: ''}
                                    handleRatingchange={handleRatingReviewChange}
                                    handleReviewChange={handleValueReviewChange}
                                    handleClick={handleEndServiceClick}
                                    edit={!orderDetail?.finishedUser}
                                />}
                            </div>
                        }
                    </div> 
                }
                </section>}

                {(phase === 4 || window.innerWidth<=700) && <section id="phase-four" ref={phaseFourRef}>
                    <TrackingShipping
                        location={{lat: 11, lng: 11}}
                        cargoShippet={cargoShippet}
                        setCargoShippet={setCargoShippet}
                        handleSave={()=>{}}
                    />
                </section>}
                
                {(phase === 5 || window.innerWidth<=700) && <section id="phase-five" ref={phaseFiveRef}>
                    <div className="phaseCustomsAgency-column">
                        <div className="paragraph phaseCustomsAgency-text-description">
                            Esta es la información que tu agencia va a declarar ante Sunat. 
                            Colocar datos incorrectos puede ocasionar multas o sanciones. 
                            <a className="phaseCustomsAgency-text-red"> Queda bajo responsabilidad del importador la pena por mala descripción 
                            y/o detalles en la traducción de la factura.</a>
                        </div>
                        <InvoceTranslationImporter
                            typeDocument={TypePersons}
                            typeIt={TypeDocuments}
                            importer={invoiceTranslation.importer}
                            setInvoiceTranslation={setInvoiceTranslation}
                            commercialLevel={commercialLevel}
                            disabled={true}
                            handleSave={()=>handleConfirmInvoiceTranslationClick(true)}
                            handleRefresh={()=>getInvoiceTranslation(orderDetail.invoiceTranslationId || "", requestQuote?.customs.incoterm || "", requestQuote?.customs.coin || "")}
                            />
                        <InvoceTranslationLegal
                            legalRepresentative={invoiceTranslation.legalRepresentative}
                            setLegalRepresentative={setInvoiceTranslation}
                            typeDocs={TypeDocuments}
                            disabled={true}
                            handleSave={()=>handleConfirmInvoiceTranslationClick(true)}
                            handleRefresh={()=>getInvoiceTranslation(orderDetail.invoiceTranslationId || "", requestQuote?.customs.incoterm || "", requestQuote?.customs.coin || "")}

                            />
                        <InvoceTranslationSupplier
                            supplier={invoiceTranslation.provider}
                            setSupplier={setInvoiceTranslation}
                            handleConfirmClick={()=>handleConfirmInvoiceTranslationClick(false)}
                            HandleClickSave={()=>handleConfirmInvoiceTranslationClick(false)}
                            handleClickCancel={()=>{}}
                            handleEdit={()=>{}}
                            disabled={true}
                            setDisabled={()=>{}}
                            handleStatement={handleInvoicestatementSave}
                            isNew={invoiceTranslation.id?false:true}
                            handleSave={()=>handleConfirmInvoiceTranslationClick(true)}
                            handleRefresh={()=>getInvoiceTranslation(orderDetail.invoiceTranslationId || "", requestQuote?.customs.incoterm || "", requestQuote?.customs.coin || "")}

                        />
                            
                    </div>
                </section>}

                {(phase === 6 || window.innerWidth<=700 ) && <section id="phase-six" ref={phaseSixRef}>
                    <div className="phaseCustomsAgency-column">
                        <ArrivalNotice
                            noticeArrival={arrivalControlChannel.noticeArrival}
                            handleFileChange={handleNoticeArrivalChange}
                            aceptTypeFiles={DocsFileValidation+','+SpreadSheetFileValidation+','+PDFFileValidation+','+ImageFileValidation}
                            deleteFile={handleDeleteNoticeArrival}
                        />
                        <RowPhaseSeven
                            taxableBase={arrivalControlChannel.dutiesTaxes.taxableBase}
                            dutiesTaxes={arrivalControlChannel.dutiesTaxes}
                            taxes={arrivalControlChannel.dutiesTaxes.taxes}
                            setArrivalControlChannel={setArrivalControlChannel}
                            onClickSave={()=>{}}
                            handleClickComplete={handleSaveArrivalControlChannel}
                            comercialInvoice={arrivalControlChannel.dutiesTaxes.file}
                            // showEdit={showEdit}
                            userId={orderDetail.userId ? orderDetail.userId : ''}
                            showEdit={false}
                            setShowEdit={setShowEdit}
                            exchageRate={arrivalControlChannel.dutiesTaxes.exchangeRate}
                            operationId={operationDetail && operationDetail.id ? operationDetail.id : ''}
                        />
                        <div className="customsAgency-phase-six">
                            <ChannelControl 
                                disabled={!showEdit}
                                // disabled={true}
                                arrivalControlChannel={arrivalControlChannel}
                                setArrivalControlChannel={setArrivalControlChannel}
                                controlChannel={ChannelControls}
                            />
                            <ArrivalDelivery 
                                finalDeliveryPlace = {arrivalControlChannel.finalDeliveryPlace}
                                setArrivalControlChannel={setArrivalControlChannel}
                                showEdit={showEdit}
                                // showEdit={false}
                                setShowEdit={setShowEdit}/>
                        
                            <ButtonEditChange    
                                onClickEdit={()=>handleEditSix()}
                                edit={showEdit}
                                setEdit={setShowEdit}
                                add={showEdit}
                                onClickCancel={()=>handleClickCancelSix()}
                                onClickComplete={()=>handleSaveArrivalControlChannel()}
                                onClickSave={()=>handleSaveArrivalControlChannel()}
                            />
                        </div>
                    </div>

                </section>}

                {(phase === 7 || window.innerWidth<=700) && <section id="phase-seven" ref={phaseSevenRef}>
                    <div className="phaseCustomsAgency-column phaseCustomsAgency-right">
                        {/* <div className="customsAgency-content--bankAcount">
                            <Button content="Ver cuentas bancarias de Grupo Aduan Agencia de Aduana S.A.C."
                                    color='black-75'
                                    size="extra-small"
                                    onClick={()=>handleBankClick()} />
                        </div> */}
                        <div className="customsAgency-phase-seven">
                            <UploadFileArray
                                title='Facturación'
                                subtitle='El consolidado de todos los pagos que ha tenido tu carga. Tu agencia colocará los comprobantes, facturas y documentos finales aquí.'
                                buttonContent="Subir archivo"
                                fileValue={invoice}
                                file={billingReviewData.billingUrl}
                                handleFileValue={handleInvoiceChange}
                                acceptType={`${CompressFile}, ${DocsFileValidation}, ${SpreadSheetFileValidation}, ${PDFFileValidation}, ${ImageFileValidation}`}
                                handleDelete={handleDeleteBilling}
                            
                            />
                        </div>
                        {review && <ReviewCard
                            title="Reseña"
                            companyName={orderDetail? orderDetail?.companyName: ''}
                            concept= "Qué te pareció el servicio de"
                            amount={orderDetail?.price?orderDetail.price: 0}
                            addReview={false}
                            responseProvider={false}
                            valueRating={review.raintg}
                            reviewValue={review ? review?.review: ''}
                            handleRatingchange={handleRatingReviewChange}
                            handleReviewChange={handleValueReviewChange}
                            handleClick={handleEndServiceClick}
                            edit={!orderDetail?.finishedUser}
                        />}
                    </div>
                </section>}
            </div>
        </>
    )
}                                                                 