import React from "react";

// importando tipos
import { BtnsColorOptions } from "../../@types/btnsColorTypes";

// importando iconos
import { MedalRating } from "../../icons/outline/MedalRating";

// importando estilos
import "./Rating.css";

interface RatingProps {
  rating: number;
  ratingCount?: string;
  color?: BtnsColorOptions
  count?: boolean;
  showNumber?: boolean;
  select?: boolean;
  onClick?: () => void;
}

export const Rating: React.FC<RatingProps> = ({ 
  rating, 
  ratingCount, 
  color='yellow',
  count,
  select=false,
  showNumber=true,
  onClick = () => {}
}) => {
  return (
    <div onClick={()=> onClick()} className={select ? "rating_pointer box-shadow-card" : "rating box-shadow-card" }style={{background: `var(--${color})`}}>
      <MedalRating className={showNumber ? "rating_pointer-icon" : "rating_pointer--start"}/>
      {showNumber && (count ? <span className="smalltext-header rating_pointer-text" style={{color: '#333333'}}>
        {rating} {"("+ratingCount+")"}
      </span> :
      <span className="smalltext-header rating_pointer-text" style={{color: '#333333'}}>
        {rating} 
      </span>)}
    </div>
  );
};
