import React, { useCallback, useEffect, useState } from "react";


/** Importando estilo */
import "./NotFound.css";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface NotFoundProps {
}

export const NotFound: React.FC<NotFoundProps> = () => { 

    return (
        <div className="notFound"> 
            <div className="notFound-content">
                <div className="notFound-img">
                    <img src={STORAGE_ASSETS+"/images/png/404.png"}/>
                </div>

                <div className="notFound-content-text">
                    <div className="submega-encabezado">
                        PÁGINA NO ENCONTRADA.
                    </div>
                    <div className="small-subheader">
                        Si necesitas ayuda conversa con nosotros
                    </div>
                </div>
            </div>
        </div>
    );
};