import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

/** Importando componentes */
import { Subheader, Button } from "../../../components";
import { Option } from "../../../components/Calendar/Option/Option";
import { CardBalance } from "../../../components/CardBalance/CardBalance";
import { ServiceMiniature } from "../../../components/ServiceMiniature/ServiceMiniature";
import { GobackButton } from "../../../components/Button/GobackButton/GobackButton";
import { Services } from "../../../components/Services/Services";

/** Importanto data */
import { ORDERDATA } from '../../../testdata/fakedata';

/** Importando url */
import { NEW_CUSTOMS, SERVICE } from "../../../Consts/BaseUrl";

/** Importando utilidades */
import { SelectWeek } from "../../../utils/SelectWeek";
import { SelectMonth } from "../../../utils/SelectMonth";
import { addWeeks } from "date-fns";
import { addMonths } from "date-fns/esm";

/** Importando estilos */
import './ServiceProviderPage.css';

export const ServiceProviderPage: React.FC<{}> = () => {
    let navigation = useNavigate()

    const [activeSubMenu, setActiveSubmenu] = useState('servicios');
    const [activeCalendar, setActiveCalendar] = useState('semana');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedWeek, setSelectedWeek] = useState(new Date());

    const [newService, setNewService] = useState(false);
    const [showCategorie, setShowCategorie] = useState(false);

    const [title, setTitle] = useState<string>('');

    const [showBack, setShowService] = useState (false);


    let week = SelectWeek(selectedWeek)();
    let month = SelectMonth(selectedDate)();

    /** Siguiente semana */
    const nextWeek = ()=> {
        setSelectedWeek(addWeeks(selectedWeek, 1));          
    }
    const preWeek = ()=> {
        setSelectedWeek(addWeeks(selectedWeek, -1));         
    }

    /** Siguiente mes */
    const nextMonth = ()=> {
        setSelectedDate(addMonths(selectedDate, 1));
    }
    const prevMonth = ()=> {
        setSelectedDate(addWeeks(selectedDate, -4)); 
       
    }

    /** Nuevo servicio */
    const handleClickNewService = () => {
        setNewService(true);
    }

    const handleCategorieImport = () => { 
        setTitle('importación')
        setShowCategorie(true);
    }

    const handleCategorieTransport = () => { 
        setTitle('transporte')
        setShowCategorie(true);
    }

    const handleCategorieWarehouse = () => { 
        setTitle('almacén')
        setShowCategorie(true);
    }
    
    const handleServiceClick = () => {
        navigation("/"+SERVICE+'?back=true&provider=true');
    }
    return ( 
        <div className="service-seller__main">
            <div className="service-seller__content">


                {/** Lado izquierdo */}
                <div className="service-seller__left">
                    <div className='service-seller-title'>
                        Servicios
                    </div>
                    <div className="order__nav">
                        {/** Tab de ordenes y calendario */}
                        <Subheader content="Terceros" active={activeSubMenu === 'terceros'} 
                                   handleClick={()=> handleServiceClick() } />
                                
                        <Subheader content="Mis servicios" active={activeSubMenu === 'servicios'} 
                                   handleClick={()=>{ setActiveSubmenu('servicios');}} /> 
                    </div>

                    { activeSubMenu === 'terceros' ?  
                        <div className="small-bold"></div>
                    :   <>
                            { !newService ? <div className="service-seller___services">
                                <div className="small-bold">Estadísticas</div>
                                <Option nextWeek={nextWeek}
                                        prevWeek={preWeek}
                                        active= {activeCalendar}
                                        setActive={setActiveCalendar}
                                        rangeDate={week}
                                        prevMonth={prevMonth}
                                        nextMonth={nextMonth}
                                        day={selectedDate}
                                        onClickModalEvent={()=>{}}/>
                                    <div className="service-seller__row">
                                        <CardBalance balance="8,304.94" content="USD Vendidos"/>
                                        <CardBalance balance="6" content="Servicios vendidos"/>
                                    </div>
                                    <div className="service-seller__row">
                                        <CardBalance balance="34" content="Entregas pendientes"/>
                                        <CardBalance balance="2" content="Reseñas pendientes"/>
                                    </div>
                                    <div className="service-seller__row__1">
                                        <CardBalance balance="Servicio más vendido" 
                                                    content="Más de 10,000 clientes satisfechos con nuestro agenciamiento."/>
                                    </div>
                            </div>: 
                            
                            /** Creando nuevo servicio */
                            <div className="service-seller___new-services">
                                <div className="service-seller___new-services__content-text">
                                    {showCategorie && <GobackButton onClick={()=>setShowCategorie(false)}/>}
                                    <div className="small-bold">Crea un nuevo servicio</div>
                                    {!showCategorie && <div className="smalltext-header">Elige una categoría principal</div>}
                                </div>
                                
                                {!showCategorie &&  <div className="service-seller___new-services__content-subcategorie">
                                    <CardBalance balance="" content="Importación" onClick={handleCategorieImport}/>
                                    <CardBalance balance="" content="Transporte" onClick={handleCategorieTransport}/>
                                    <CardBalance balance="" content="Almacén" onClick={handleCategorieWarehouse}/>
                                </div> }

                                
                                
                            </div>}
                    </>}
                </div>
                

                {/** Lado derecho */}
                <div className="service-seller__right">
                    <div className="service-seller__row service-seller__right-center">
                        <div className="small-header">{!newService ? 'Mis servicios publicados': !showCategorie ? 'Crea servicio' : 'Crea servicio de ' + title}</div>
                        {!newService && <Button content="Agregar servicio" onClick={handleClickNewService}/>}
                    </div>
                    {!newService  ? <div className="service-seller__right-grid">
                        {/* { [...Array(5)].map((a, idx)=>(
                            <OrderMiniature key={idx}
                                            order={ORDERDATA} 
                                            edit price={0} 
                                            choose={false} 
                                            onClickChoose={()=>{}} 
                                            onClickOrder={()=>{}} 
                                            quoteSended={false}/>
                        ))
                        } */}
                    </div> : 
                    
                    <div className="paragraph">{!showCategorie ? 'Selecciona una categoría principal' : 'Selecciona una sub categoría '}</div>}
                    { newService && !showCategorie &&  <>
                        <hr className="split"/>

                        <div className="small-bold service-seller___new-services___">Servicios guardados sin publicar</div>
                        {/** En esta sección solo se visualiza el boton editar */}
                        <div className="service-seller___new-services-slope">
                            {/* {   [...Array(5)].map((b, idx)=>(
                                <OrderMiniature key={idx}
                                                order={ORDERDATA} 
                                                edit={true} price={0} 
                                                choose={false} 
                                                onClickChoose={()=>{}} 
                                                onClickOrder={()=>{}} 
                                                quoteSended={true}/>
                            ))
                            } */}
                        </div>
                    </>}

                    { showCategorie && 
                        <div className="service-seller___sub-categorie">
                            <Services onClickService={()=>{navigation(NEW_CUSTOMS)}} name='Agenciamiento' />
                            <Services onClickService={()=>{}} name='Busqueda'/>
                            <Services onClickService={()=>{}} name='Permiso'/>
                            <Services onClickService={()=>{}} name='Traduccion'/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}