import { httpsCallable } from "firebase/functions";
import { Functions } from "../firebaseConfig/FirebaseConfig";

export const registerTopicService = (token: string, userId: string) => {
    return new Promise<void>((resolve, reject) => {
        if(token.length>0) {
            const registerTopic = httpsCallable(Functions, "registerTopic");
            return registerTopic({registerToke: token, userId: userId}).then((response:any) => {
                resolve(response);
            }).catch((error) => {
                console.log("🚀 ~ file: Topic.service.ts:10 ~ returnregisterTopic ~ error:", error)
                reject(error);
            })
        } else {
            reject(false);
        }
    });
}

export const unRegisterTopicService = (token: string) => {
    return new Promise<void>((resolve, reject) => {
        if(token.length>0) {
            const unRegisterTopic = httpsCallable(Functions, "unRegisterTopic");
            return unRegisterTopic({registerToke: token}).then((response:any) => {
                resolve(response);
            }).catch((error) => {
                console.log("🚀 ~ file: Topic.service.ts:27 ~ returnunRegisterTopic ~ error:", error);
                reject(error);
            })
        } else {
            reject(false);
        }
    });
}