import { UMWeightMobile } from "../@types/UMWeight"

export const ConvertToKilogram = (weight: number, umWeight: UMWeightMobile | undefined): number => {
    switch (umWeight) {
        case "kg":
            return weight;
        case "lb":
            return Number((weight * 0.453592).toFixed(2));
        case "tn":
            return weight * 1000;
        default:
            return weight;
    }
}