import React, { useState } from "react";

/** importando componentes */
import { Row } from "../Row";
import { ArrowUp } from "../../../icons/outline/ArrowUp";
import { ArrowDown } from "../../../icons/solid/ArrowDown";

/** importando estilo */
import './RowGroup.css';

/** declarando variables de interface */
interface RowGroupProps {
    title: string; // titulo del row
    rows: any[]; // array de valores de la grupo de rows
    footerValues?: {
        title: string,
        values: any[]
    },
    open?: boolean // estado de collapse
    click?: boolean;
}

export const RowGroup: React.FC<RowGroupProps> = ({
    title,
    rows,
    footerValues,
    open = false,
    click=true
}) => {

    const [collapse, setCollapse] = useState(open);
    
    return (
        <div role='rowGroupRole' className="rowGroup-container">
            <div className="rowGroup-header-container rowGroup-header--pointer" onClick={  ()=> click && setCollapse(!collapse)}>
                <div className="paragraph-header rowGroup-header--title">{title}</div>
                {!collapse ? <ArrowDown className="rowGroup-header--pointer"/> : 
                <ArrowUp className="rowGroup-header--pointer" />}
            </div>
            {
                collapse && 
                <div>
                    {rows && rows.length>0 && (rows.filter((r) => {if(r) return r})).map((r1, ydx) =>{
                            return (
                                <Row 
                                    key={ydx}
                                    title={r1.name}
                                    color={ydx%2===0?'transparent':'white-25'}
                                    content='number'
                                    columValues={r1.value}
                                    classRow="paragraph"
                                />
                            )
                        })
                    }
                    { footerValues && <Row 
                        title={footerValues.title}
                        content='number'
                        color="black-75"
                        columValues={footerValues.values}
                        classRow="rowGroup-color-white"
                        colorText
                    />}
                </div>
                
            }    
            
        </div>
    )
}

