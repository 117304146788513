import { ForwardRefRenderFunction, ForwardedRef, useEffect, useRef, useState } from "react";

import "./ModalIA.css";
import { CloseBig } from "../../../icons/solid/CloseBig";
import { Button } from "../../Button/Button";
import { SendRequestWithAI } from "../../../Services/RequestQuote.service";
import { toast } from "react-toastify";
import { Loader } from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { OPERATIONS, STORAGE_ASSETS } from "../../../Consts/BaseUrl";


interface ModalIAProps {
    operaionId: string; // operation id
    requestQuoteId: string; // request quote id
    show: boolean; // variable to show modal
    setShow: React.Dispatch<React.SetStateAction<boolean>>; 
    handleChoseAgencies: () => void; // function to handle chose agencies
} 

export const ModalIA: React.FC<ModalIAProps> = ({
    operaionId,
    requestQuoteId,
    show,
    setShow,
    handleChoseAgencies,
}) => {

    let navigation = useNavigate();

    const [loading, setLoading] = useState(false);

    const handleProcessWithIA = () => {
        if(operaionId.length>0 && requestQuoteId.length>0) {
            setLoading(true);
            SendRequestWithAI(operaionId, requestQuoteId).then((response) => {
           
                toast.success("Tus cotizaciones fueron enviadas de forma éxitosa");
                console.log(response);
                setLoading(false);
                setShow(false);
                navigation("/"+OPERATIONS);
            }).catch((error) => {
                console.log(error);
                toast.error('Error al procesar con IA');
                setLoading(false);
                setShow(false);
            });
        } else {
            toast.error('Ups algo salio mal, por favor intentalo dentro de unos minutos');
            setShow(false);
        }
    }

    useEffect(() => {
        show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    }, [show]);


    return (
       <>
         { show && 
        <div className="modalIA">
            
                <div className="modalIA-content">
                    <div className="modalIA-hearder">
                        <div className="modalIa-title">
                            <img src={STORAGE_ASSETS+"/images/png/groupStart.png"} style={{height: '23px', width: '23px'}}/>
                            <div className="paragraph-header modalIa-text">Mejora tus opciones con la IA</div>
                        </div>
                        {!loading && <div className='processServiceHeader--close'>
                            <CloseBig onClick={()=>handleChoseAgencies()} />
                        </div>  }
                    </div>
                    {!loading ? <>
                    <div className="paragraph">
                        {/* Cotiza con las mejores agencias de aduana y carga para tu importación 
                        con la ayuda de la Inteligencia Artificial. */}
                        La IA compara lo que estás trayendo con las agencias que tienen experiencia en ese producto.
                    </div>
                    <div className="modalIa-footer">
                        <Button size="only-text" content="Elegir mis propias agencias" color="black-75" onClick={()=>handleChoseAgencies()}/>
                        <Button content="Continuar con IA" color="black-75" onClick={()=>handleProcessWithIA()}/>
                    </div>
                    </>: 
                    <div className="modalIA-loader">
                        <div className="paragraph">Estamos buscando las mejores agencias para tu producto.</div>
                        <Loader />
                    </div>}
                </div>
         
        </div>   }
       </>
    )
}