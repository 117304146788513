import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import {  TextField } from "../..";
import { Checkbox } from "../../Checkbox/Checkbox";
import { CardDataInterface, CardDataInterfaceError } from "../../../interfaces/BankAccount";
import { ValidateField } from "../../../utils/ValidateField";
import { CardContent } from "../../CardContent/CardContent";
import { Card } from "../../../icons/solid/Card";

import "./PaymentCD.css";
import { AddingScript } from "../../../Hooks/UseScript";
import { URL_CULQI_CUSTOM_CHECKOUT } from "../../../Consts/BaseUrl";
import { BannerPayment } from "../../BannerPayment/BannerPayment";
import { Loader } from "../../Loader/Loader";
import { toast } from "react-toastify";
import { max, set } from "date-fns";

interface PaymentCDProps {
  cardData: CardDataInterface;
  setCardData: React.Dispatch<React.SetStateAction<CardDataInterface>>;
  curency: string;
  amount: number;
}

export const PaymentCD = forwardRef((props: PaymentCDProps, ref) => {

  const { 
    REACT_APP_CULQI_PUBLIC_KEY,
    REACT_APP_CULQI_RSA_ID,
    REACT_APP_CULQI_RSA_PUBLIC_KEY
  } = process.env;


  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCulqi, setLoadingCulqi] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(true);
  const [token, setToken] = useState<string>("");

  let Culqi: any;


  const validateCardData = (): boolean => {
    let error = token.length === 0;
    if(error) toast.error("Haga click en el botón procesar para validar los datos de la tarjeta",
      {autoClose: 5000});

    return error;
  }


  const handleGetCheckoutToken = () => {
    return token;
  }

  useImperativeHandle(ref, () => ({
    validateCardData,
    handleGetCheckoutToken
  }));

  const handleCulqiAction = () => {
    if (Culqi.token) {
      const tokenCulqi = Culqi.token.id;
      Culqi.close();
      setToken(tokenCulqi);
      toast.success("Tus datos de la tarjeta han sido validados correctamente");
      setIsChecked(false);
 
      //En esta linea de codigo debemos enviar el "Culqi.token.id"
      //hacia tu servidor con Ajax
    } else if (Culqi.order) { // ¡Objeto Order creado exitosamente!
      Culqi.close();
      const order = Culqi.order;
      setToken(order);
      setIsChecked(false);
    } else {
      // Mostramos JSON de objeto error en consola
      console.log('Error : ', Culqi.error);
      toast.error("Hubo un error al validar los datos de la tarjeta, intentelo más tarde");
    }

  }

  const initCulqi = async() => {
    try {
      setLoadingCulqi(true);
      await AddingScript(URL_CULQI_CUSTOM_CHECKOUT);

      const tempWindow: any = window;

      setTimeout(() => {
        const settings = {
          title: 'Arkabia',
          currency: props.curency, // Este parámetro es requerido para realizar pagos yape
          amount: props.amount*100, // Este parámetro es requerido para realizar pagos yape(80.00)
          order: '', // Este parámetro es requerido para realizar pagos con pagoEfectivo, billeteras y Cuotéalo
          // xculqirsaid: REACT_APP_CULQI_RSA_ID,
          // rsapublickey: REACT_APP_CULQI_RSA_PUBLIC_KEY,
        }

        const client = {
          email: props.cardData.email,
        }

        const paymentMethods = {// las opciones se ordenan según se configuren
          tarjeta: true,
          yape: false,
          billetera: false,
          bancaMovil: false,
          agente: false,
          cuotealo: false,	
        }

        const options = {
          lang: 'auto',
          installments: false, // Habilitar o deshabilitar el campo de cuotas
          modal: false,
          container: "#paymentcd-culqi", // Opcional - Div donde quieres cargar el checkout
          paymentMethods: paymentMethods,
          paymentMethodsSort: Object.keys(paymentMethods), // las opciones se ordenan según se configuren en paymentMethods
        }

        const appearance = {
          theme: "default",
          hiddenCulqiLogo: true,
          hiddenBannerContent: true,
          hiddenBanner: true,
          hiddenToolBarAmount: false,
          menuType: "select", // sidebar / sliderTop / select
          buttonCardPayText: "Procesar", // 
          logo: "https://www.arkabia.com/images/arkabia-logo.png", // 'http://www.childrensociety.ms/wp-content/uploads/2019/11/MCS-Logo-2019-no-text.jpg',
          defaultStyle: {
            bannerColor: "blue", // hexadecimal
            buttonBackground: "#000000BF", // hexadecimal
            menuColor: "pink", // hexadecimal
            linksColor: "#333333", // hexadecimal
            buttonTextColor: "#FFF", // hexadecimal
            priceColor: "#4F4F4F",
          },
          variables: {
            fontFamily: "'Faktum-Medium'",
            colorPrimaryText: "#1A1B25",
            colorText: "white",
            colorTextSecondary: "white",
            colorTextPlaceholder: "#727F96",
            colorIconTab: "white",
            colorLogo: "dark",
          },
          rules: {
            ".max-w-culqi-mobile": {
              maxWidth: window.innerWidth>600  ? "88%" : "100%",
            },
            ".Culqi-Toolbar-Price": {
              fontStyle: "normal",
              fontSize: "0.875rem",
              lineHeight: "18px",
              color: "#333333",
            },
            ".Culqi-Main-Container": {
              color: "#333333",
            },
            ".relative input": {
              height: "52.5px",
              borderRadius: "24px",
            },
            "footer .Culqi-Button": {
              borderRadius: "24px",
              color: "FFFFFF",
              background: "#000000bf",
            }
          }
        };

        const config = {
          settings,
          client,
          options,
          appearance,
        };

        const culqiPublicKey= REACT_APP_CULQI_PUBLIC_KEY;

        Culqi = tempWindow.Culqi;

        Culqi = new tempWindow.CulqiCheckout(culqiPublicKey, config);

        setLoadingCulqi(false);

      
        Culqi.culqi = handleCulqiAction;
       
      
        setTimeout(() => {  
       
          Culqi.open();
          
        }, 500);
    
      }, 2000);
      } catch (error) {
      console.log("🚀 ~ file: PaymentCD.tsx:222 ~ initCulqi ~ error:", error)
      toast.error("Hubo un error con la pasarela de pago, intentelo más tarde")
    }
  }
  
  useEffect(() => {
    initCulqi();
  }, [isChecked]);

  return (
    <div className="paymentcd" role='payment-cd'>
      <div className="quotationPermitsComponent-title paragraph-header">
        {/* <Card/> */}
        Pago
      </div>

      <CardContent className="paymentcd-cardContent">
        <div className="payment-container">
          <Checkbox checked={isChecked} setChecked={setIsChecked} />
          <h3 className="paragraph-header">Pagar con tarjeta</h3>
        </div>

        {isChecked && (
          !loading ? <>
            <form>
            {loadingCulqi ? <Loader /> :
              <div id="paymentcd-culqi" className="paymentcd-detail"></div> 
            }
            </form>
            {/* <BannerPayment/> */}
          </> : <Loader />
        )}
      </CardContent>
    </div>
  )
});