import axios from "axios";
import { API_SUNAT_TIPO_CAMBIO, CORS_PROXY } from "../Consts/BaseUrl";

// get exchange rate from sunat
export const GetExchangeRate = async () => {
    try {
        const response:any = await axios.get(`${CORS_PROXY}`, {
            headers: { 
                'X-RapidAPI-Key': 'fb787e7ee7msh626404bd86119e4p10d117jsn9c17995bb7c6',
                'X-RapidAPI-Host': 'cors-proxy4.p.rapidapi.com'
            },
            params: {url: `${API_SUNAT_TIPO_CAMBIO}`},
        });
        let data_fetch = (response.data).toString().split("|");
        return {
            date: data_fetch[0],
            purchase: data_fetch[1],
            sale: data_fetch[2]
        }
    } catch (error) {
        console.log("🚀 ~ file: sunat.service.ts:7 ~ GetExchangeRate ~ error:", error)
        return error;
    }
}