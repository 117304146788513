export const GetAttachIcon = (fileName: string, fileUrl: string) => {
    let base_name = (fileName.split('.').pop())?.toLowerCase();

    if(base_name?.includes('doc')) {
        return 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Ficon%2Ficon_doc_svg.svg?alt=media&token=dc7775b8-c12b-4fbf-bec7-a375c18d623c';
    }

    if(base_name?.includes('xls')) {
        return 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Ficon%2Ficon_xsl_svg.svg?alt=media&token=1adfc0f5-c96a-43ac-bd96-b9cbd7d05f58';
    }

    if(base_name?.includes('pdf')) {
        return 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Ficon%2Ficon_pdf_svg.svg?alt=media&token=0a89268f-cd67-4bd2-8c95-958f13aeab5d';
    }

    if(base_name?.includes('ppt')) {
        return 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Ficon%2Ficon_ppt_svg.svg?alt=media&token=e7d5fa6a-103a-4031-8439-5580445de6ec';
    }

    if(base_name?.includes('jpg') || base_name?.includes('jpeg') || base_name?.includes('png') || base_name?.includes('gif') || base_name?.includes('ttf') || base_name?.includes('bmp') || base_name?.includes('tiff')) {
        return fileUrl;
    }
    
    return 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2FothersFile.png?alt=media&token=d262628d-82dd-4167-a8ff-f11b378c663d&_gl=1*39fn0j*_ga*MTczMjYyMzYwMS4xNjg2NjA4Njk5*_ga_CW55HF8NVT*MTY5NjQzMTY0Ni4xOTEuMS4xNjk2NDMyODcxLjMuMC4w';
}