import { STORAGE_ASSETS } from "../Consts/BaseUrl";

export const Notification: any[] = [
    {
        id: 1,
        title: "Carros de juguete para navidad",
        time: "11:11",
        business: "DeChinaAPeru.com E.I.R.L.",
        updateAt: "3 minutos",
        user: {
            id: 1,
            username: 'Pepito',
            lastname: "Pedro",
            email: "pedro.pereºœyopmail.com",
            userImgUrl: STORAGE_ASSETS+"/images/png/profile_min.png"
        },
        type: "turn",
        step: "",
        numberStep: "",
        week: "",
        day: "",
        hours: "",
        read: false
    },
    {
        id: 2,
        title: "Bicicletas negras",
        time: "10:30",
        business: "DeChinaAPeru.com E.I.R.L.",
        updateAt: "",
        user: {
            id: 1,
            username: 'Pepito',
            lastname: "Pedro",
            email: "pedro.pereºœyopmail.com",
            userImgUrl: STORAGE_ASSETS+"/images/png/profile_min.png"
        },
        type: "step",
        step: "Entrega de orden",
        numberStep: "4",
        week: "",
        day: "",
        hours: "",
        read: false
    },
    {
        id: 3,
        title: "Polos del hombre araña",
        time: "13:50",
        business: "DeChinaAPeru.com E.I.R.L.",
        updateAt: "5 minuto",
        user: {
            id: 1,
            username: 'Pepito',
            lastname: "Pedro",
            email: "pedro.pereºœyopmail.com",
            userImgUrl: STORAGE_ASSETS+"/images/png/profile_min.png"
        },
        type: "turn",
        step: "",
        numberStep: "",
        week: "",
        day: "",
        hours: "",
        read: false
    },
    {
        id: 4,
        title: "Laptops Asus 7G",
        time: "15:10",
        business: "DeChinaAPeru.com E.I.R.L.",
        updateAt: "",
        user: {
            id: 1,
            username: 'Pepito',
            lastname: "Pedro",
            email: "pedro.pereºœyopmail.com",
            userImgUrl: STORAGE_ASSETS+"/images/png/profile_min.png"
        },
        type: "step",
        step: "Entrega de orden",
        numberStep: "3",
        week: "",
        day: "",
        hours: "",
        read: true
    },
    {
        id: 5,
        title: "Mascarilla k9",
        time: "11:11",
        business: "DeChinaAPeru.com E.I.R.L.",
        updateAt: "",
        user: {
            id: 1,
            username: 'Pepito',
            lastname: "Pedro",
            email: "pedro.pereºœyopmail.com",
            userImgUrl: STORAGE_ASSETS+"/images/png/profile_min.png"
        },
        type: "step",
        step: "Entrega de orden",
        numberStep: "5",
        week: "",
        day: "",
        hours: "",
        read: true
    },
    {
        id: 6,
        title: "Zapatillas adidas tallas 36-37",
        time: "11:11",
        business: "DeChinaAPeru.com E.I.R.L.",
        updateAt: "",
        user: {
            id: 1,
            username: 'Pepito',
            lastname: "Pedro",
            email: "pedro.pereºœyopmail.com",
            userImgUrl: STORAGE_ASSETS+"/images/png/profile_min.png"
        },
        type: "step",
        step: "Entrega de orden",
        numberStep: "3",
        week: "",
        day: "",
        hours: "",
        read: true
    },
    {
        id: 7,
        title: "Reunion urgente para coordinar",
        time: "13:10",
        business: "DeChinaAPeru.com E.I.R.L.",
        updateAt: "",
        user: {
            id: 1,
            username: 'Pepito',
            lastname: "Pedro",
            email: "pedro.pereºœyopmail.com",
            userImgUrl: STORAGE_ASSETS+"/images/png/profile_min.png"
        },
        type: "event",
        step: "",
        numberStep: "3",
        week: "Lunes",
        day: "04",
        hours: "13:00",
        read: true
    },
    {
        id: 8,
        title: "Quiere ser parte de tu red",
        time: "13:10",
        business: "DeChinaAPeru.com E.I.R.L.",
        updateAt: "",
        user: {
            id: 1,
            username: 'Pepito',
            lastname: "Pedro",
            email: "pedro.pereºœyopmail.com",
            userImgUrl: STORAGE_ASSETS+"/images/png/profile_min.png"
        },
        type: "friend",
        step: "",
        numberStep: "3",
        week: "Lunes",
        day: "04",
        hours: "13:00",
        read: true
    },
]