import React from "react";

import { useNavigate } from "react-router-dom";

import "./GobackButton.css";
import { STORAGE_ASSETS } from "../../../Consts/BaseUrl";

type GobackButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const GobackButton: React.FC<GobackButtonProps> = ({ onClick, ...props }) => {
  
  let navigation = useNavigate();

  const handleClick = () => {
    navigation(-1);
  }

  return (
    <button role='goback-button' className="go-back-button" {...props} onClick={onClick? onClick : handleClick}>
      <img src={STORAGE_ASSETS+"/images/svg/left-arrow-icon.svg"} alt="" />
      <span className="smalltext-header go-back-button-text">Regresar</span>
    </button>
  );
};
 