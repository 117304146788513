import { collection, getDocs, query, where } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";

export const GetWarehouseCourierByService = async (serviceId: string) => {
    const q = query(collection(DB, "warehouseCourier"), where("serviceId", "==", serviceId));
    const response = await getDocs(q); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}