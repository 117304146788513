import { DB } from "../firebaseConfig/FirebaseConfig";
import { doc, getDoc, updateDoc, } from "firebase/firestore"; 
import { ReviewInterface } from "../interfaces/ReviewServiceInterface";

const GetAllReviewByServiceId = async (id:string) => {
    const response = await  getDoc(doc(DB, 'reviewService', id));

    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()});
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    }); 
}

// actualiza un review en reviewService segun el "id"
const UpdateReviewInReviewService = async(reviewServiesId: string, reviews: ReviewInterface[]) => {
    // console.log("🚀 ~ file: RequestQuote.service.ts ~ line 70 ~ UpdateRequestQuote ~ requestQuote", requestQuote)
    try {
        if(reviewServiesId.length>0) {
            const requestRef = doc(DB, 'reviewService', reviewServiesId);
            const response =  await updateDoc(requestRef, {review: reviews});

            // console.log("UpdateRequestQuote", response)
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}
    
// const GetAllReviewByServiceId = async (id:string) => {
//     const response:any = await  getDoc(doc(DB, 'reviewService', id));
//     return new Promise((resolve, reject) => {
//         if(response.data()) {
//             // resolve({id: response.id, ...response.data()});
//             let reviewList:any[] = [];

//             response.data().review.forEach(async(r:any)=> {
//                 const userCompany:any = await GetUserCompanyByUserId(r.user.userId);
//                if (reviewList.length===0) {
//                 reviewList = [{...r, companyName: userCompany?userCompany.name:''}];
//                } else {
//                 reviewList.push({...r, companyName: userCompany?userCompany.name:''});
//                }
//             });

//             console.log(reviewList)
            
//             resolve({id: response.id, ...response.data(), review: reviewList});
//         } else {
//             reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
//         }
//     }); 
// }

export {
    GetAllReviewByServiceId,
    UpdateReviewInReviewService
}