import React, { useEffect, useState } from "react";

/** Importando componente */
import { Button } from "../Button/Button";
import { Rating } from "../Rating/Rating";
import { TextField } from "..";
import { UserActive } from "../UserActive/UserActive";

/** Importando interface */
import { ReviewInterface } from "../../interfaces/ReviewServiceInterface";

/** Importando data */
import { ComentReview } from "../../testdata/ComentReview";

/** Importando icon */
import { Dots } from "../../icons/outline/Dots";

/** Importando estilo */
import "./Review.css";
import { GetLastTimeText } from "../../utils/GetLastTimeText";
import { GetUserCompanyByUserId } from "../../Services/UserCompany.service";
import { LikeComponent } from "../LikeComponent/LikeComponent";
import { GetAllReviewByServiceId, UpdateReviewInReviewService } from "../../Services/Review.service";
import { useSelector } from "react-redux";

interface ReviewProps {
  idx: number;
  review: ReviewInterface;
  handleUpdateReview: (idx: number, review: ReviewInterface) => void;
}

export const Review: React.FC<ReviewProps> = ({ idx, review, handleUpdateReview }) => {

  const {userDetail} = useSelector((state: any) => state.user);  

  const [loading, setLoading] = useState<boolean>(false);
  const [showResult, setShowResult]= useState(false);
  const [companyName, setCompanyName] = useState<string>('');

  const [reviewDetail, setReviewDetail] = useState<ReviewInterface>(review);

  const [isLike, setIsLike] = useState(false);

  const handleClickButton = () => {
    if(review.answers && review.answers.length>0) {
      setShowResult(!showResult);
    }
  }

  const getUserCompany = async (userId: string) => {
    setLoading(true);
    try {
      const response = await GetUserCompanyByUserId(userId);
      if(response) {
        setCompanyName(response.name || "");
      }      
    
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ file: Review.tsx:49 ~ getUserCompany ~ error:", error)
      setLoading(false);
    }
  }

  const handleLikeClick = async (review: ReviewInterface) => {
    let tmpReview:any = {...reviewDetail};
    console.log("🚀 ~ handleLikeClick ~ tmpReview:", tmpReview)

    if(review.detailLikes && review.detailLikes.length>0) {
      const idx = review.detailLikes.findIndex((d) => d.userId === userDetail.id);
      if(idx>=0) {
        tmpReview = {...reviewDetail, numLikes: Number(reviewDetail.numLikes) - 1, detailLikes: reviewDetail.detailLikes.filter((d) => d.userId !== userDetail.id)};
        // setReviewDetail({...reviewDetail, numLikes: Number(reviewDetail.numLikes) - 1, detailLikes: reviewDetail.detailLikes.filter((d) => d.userId !== userDetail.id)});
      } else {
        tmpReview = {...reviewDetail, numLikes: Number(reviewDetail.numLikes) + 1, detailLikes: [...reviewDetail.detailLikes, {like: true, 
          userId: userDetail.id, createdAt: new Date(), updatedAt: new Date()}]};
        // setReviewDetail({...reviewDetail, numLikes: Number(reviewDetail.numLikes) + 1, detailLikes: [...reviewDetail.detailLikes, {like: true, 
        //   userId: userDetail.id, createdAt: new Date(), updatedAt: new Date()}]});
        // review.detailLikes.push({userId: userDetail.id});
      }
    } else {
      tmpReview = {...reviewDetail, numLikes: 1, detailLikes: [{like: true, 
           userId: userDetail.id, createdAt: new Date(), updatedAt: new Date()}]};

      // setReviewDetail({...reviewDetail, numLikes: 1, detailLikes: [{like: true, 
      //   userId: userDetail.id, createdAt: new Date(), updatedAt: new Date()}]});
    }

    setReviewDetail(tmpReview);

    setTimeout(() => {
      handleUpdateReview(idx, tmpReview);
    }, 1000)
  }

  useEffect(() => {
    if(review.user.userId && review.user.userId.length>0) {
      getUserCompany(review.user.userId);
    } else {
      setCompanyName(review.user.business || "");
    }
  }, []);

  useEffect(() => {
    setReviewDetail(review);
  }, [review]);

  return (
    <div role='reviewRole' className="review box-shadow-card">
      <div className="review-top">
        {/* {!loading && <UserActive
          userImg={reviewDetail.user.urlPhoto?reviewDetail.user.urlPhoto:''}
          name={reviewDetail.user.name}
          lastname={reviewDetail.user.lastName} 
        />} */}
        <div className="review-top-userName">
          <div className="review-top-userName-header">
            <h1 className="small-bold review-name-text">{reviewDetail.user.name+" "+reviewDetail.user.lastName}</h1>
            <div className="review-right">
              <div className="smalltext-header review-bot_right">
                {"hace" + " " + GetLastTimeText(reviewDetail.createdAt.toDate())}
              </div>
              <Rating rating={reviewDetail.rating}/>
              {/* <Dots />   */}
            </div>
          </div>
          {companyName.length> 0 && <div className="smalltext-header review-colorCompany">{companyName}</div>}
        </div>
        <div className="review-right">
          <div className="smalltext-header review-bot_right">
            {"hace" + " " + GetLastTimeText(reviewDetail.createdAt.toDate())}
          </div>
          <Rating rating={reviewDetail.rating}/>
          {/* <Dots />   */}
        </div>
      </div>

      <div className="review-content-detail">
        <div className="review-mid">
          <p className="paragraph review-mid-p">{reviewDetail.comment}</p>
        </div>

        <div className="review-bot">
          <div className="review-bot_left">
            {/* <LikeComponent
              onClick={() => handleLikeClick(reviewDetail)}
              numLikes={reviewDetail.numLikes}
              isLike={reviewDetail.detailLikes && reviewDetail.detailLikes.length>0 && reviewDetail.detailLikes.findIndex((d) => d.userId === userDetail.id)>=0}
            /> */}
          </div>
          {/* <div className="smalltext-header review-bot_right">
            {"hace" + " " + GetLastTimeText(reviewDetail.createdAt.toDate())}
          </div> */}
          {/* {review.answers && review.answers.length>0 && 
            <Button content={showResult ? "Ocultar respuestas" : "Ver respuestas"} size="only-text" color="blue-2"  onClick={ handleClickButton}/>
          } */}
        </div>
      </div>

      {/* { showResult &&
        <div className="review-comment">
          {review.answers && review.answers.map((a, idx) => (
            <TextField key={idx}
                       title={a.user.name+" "+a.user.lastName+ " " + " escribio" + " " + "el " + a.createdAt} 
                       placeholder={a.msg}
                       />
          ))}
        </div>
      } */}
    </div>
  );
};
