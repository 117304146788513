import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CouponIconProps = React.HTMLAttributes<HTMLDivElement> & {};

export const CouponIcon: React.FC<CouponIconProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/couponIcon.svg"} alt="" />
    </div>
  );
};
