import React, { useEffect } from "react";

import './TimeLine.css';

interface TimeLineProps {
   hour: number;
   minute: number,
   dayWeekNumber?: number
}

export const TimeLine: React.FC<TimeLineProps> = ({
   hour,
   minute,
   dayWeekNumber=0
}) => { 

    const getTopDesktop = () => {
        return ((696/642)*(hour*60 + minute)*2.107);;
    }


    const getTopMobile = () => {
        let t = dayWeekNumber === 0 ?
        (30+((54/24)*hour)): 
        (30+((54/24)*hour))+(70*dayWeekNumber);
        return t;
    }

    let top = window.innerWidth >= 620 ? getTopDesktop(): getTopMobile();
   
    return (
        <div className="timeLine-container" style={{top: top}}>
             {/* Dots */}
            <div className="timeLine-detail-dots" />
            {/* Line */}
            <div className="timeLine-detail-line"/>
        </div>
    )
}