import React from "react";
import { loaderText } from "../../Consts/LoaderText";

import './Loader.css'

export const Loader: React.FC<{}> = ({...props}) => {
    return (
        <div className="loader-container">
            <div {...props} className="loader"></div>
        </div>
    )
}