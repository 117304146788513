import React, { useState } from 'react';
import './ToggleSwitch.css';

interface ToggleSwitchProps {
    textLeft: string;
    textRight: string;
    onToggle: (status: any) => void;
    black?: boolean;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  textLeft,
  textRight,
  onToggle,
  black=false
}) => {

  const [isActive, setIsActive] = useState<boolean>(false);

  const toggle = () => {
    setIsActive(!isActive);
    onToggle(isActive ? textLeft : textRight);
  };

  return (
    <div className="toggle-switch">
      <span className={
        `${!isActive ? "active" : ""} 
        ${black ? "toggle-switch-white" : "toggle-switch-text-black"}`}>
          {textLeft}</span>
      <div className={`${black ? "toggle-slider" : "toggle-slider-black"} `} onClick={()=>toggle()}>
        <div className={`slider ${isActive ? "annual" : "monthly"}`}></div>
      </div>
      <span className={
        `${isActive ? "active" : ""} 
        ${black ? "toggle-switch-white" : "toggle-switch-text-black"}`
      }>{textRight}</span>
    </div>
  );
};

