import React, { useState, useEffect, useRef } from "react";

// import style
import './useTypingText.css';

export const useTypingText = (
    words: string[],
    keySpeed: number=1000,
    maxPauseAmount: number=10
)  => {

  const FORWARD = "forward";
  const BACKWARD = "backward";

  const [wordIndex, setWordIndex] = useState<number>(0);
  const [currentWord, setCurrentWord] = useState(words[wordIndex].split(""));
  const [isStopped, setIsStopped] = useState(false);
  
  const [pauseCounter, setPauseCounter] = useState<number>(0);

  const direction = useRef(BACKWARD);
  const typingInterval = useRef<any>();
  const letterIndex = useRef<any>(null);

  const stop = () => {
      typingInterval && clearInterval(typingInterval.current);
      setIsStopped(true);
  };

  const typeLetter = () => {
    if (letterIndex.current >= words[wordIndex].length) {
      direction.current = BACKWARD;

      // Begin pause by setting the maxPauseAmount prop equal to the counter
      setPauseCounter(maxPauseAmount);
      return;
    }

    const segment = words[wordIndex].split("");
    
    setCurrentWord(currentWord.concat(segment[letterIndex.current]));
    letterIndex.current = letterIndex.current + 1;
  };

  const backspace = () => {
    if (letterIndex.current === 0) {
      const isOnLastWord = wordIndex === words.length - 1;

      setWordIndex(!isOnLastWord ? wordIndex + 1 : 0);
      direction.current = FORWARD;

      return;
    }

    const segment = currentWord.slice(0, currentWord.length - 1);
    setCurrentWord(segment);
    letterIndex.current = currentWord.length - 1;
  };

  useEffect(() => {
    // Start at 0
    if (isStopped) return;   
  
    typingInterval.current = setInterval(() => {
      // Wait until counter hits 0 to do any further action
      if (pauseCounter > 0) {
        setPauseCounter((prev) => prev - 1);
        return;
      }

      if (direction.current === FORWARD) {
        typeLetter();
      } else {
        backspace();
      }
    }, keySpeed);
  
    return () => {
      clearInterval(typingInterval.current);
    };
  }, [currentWord, wordIndex, keySpeed, words, maxPauseAmount, isStopped]);

  return {
      word: (
          <span className={`word ${currentWord.length ? "full" : "empty"}`}>
              <span className="mega-header useTypingtext">{currentWord.length ? currentWord.join("") : "0"}</span>
          </span> 
      ),
      start: () =>  setIsStopped(false),
      stop      
  } 
}