import { ForwardRefRenderFunction, ForwardedRef, useEffect, useRef, useState } from "react";

import "./ModalProcessService.css";

import { FromPage } from "../../../@types/fromPageTypes";
import { ServiceInterface, ServiceTypeInterface } from "../../../interfaces/Service";
import { ProcessServiceHeader } from "./Header/ProcessServiceHeader";
import { Button } from "../../Button/Button";
import ProcessService from "../../ProcessService/ProcessService";
import { ProcessServiceFooter } from "./Footer/ProcessServiceFooter";
import { RequestQuoteInterface } from "../../../interfaces/RequestQuote";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MODAL_REQUEST, OPERATIONS, SHOW_MODAL } from "../../../Consts/BaseUrl";


interface ModalProcessServiceProps {
    show: boolean; // variable to show modal
    setShow: React.Dispatch<React.SetStateAction<boolean>>; // setter to show modal
    serviceTypeId: string; // service type id
    fromPage: FromPage; // from page where the modal is called
    companyId?: string; // company id
    service?: ServiceInterface //  service
    requestQuote?: RequestQuoteInterface | undefined; // id of the request quote to update
    updatedData?: () => void; // function to update data if the service type is agency or courier
    planIndex?: number; // index of the plan selected
} 

export const ModalProcessService: React.FC<ModalProcessServiceProps> = ({
    show,
    setShow,
    serviceTypeId = "agenciamiento-de-aduana",
    fromPage,
    companyId="",
    service,
    requestQuote,
    updatedData = () => { },
    planIndex = 1
}) => {

    // searchParams
    const [searchParams, setSearchParams] = useSearchParams();

    const processServiceRef: HTMLInputElement | any = useRef<HTMLInputElement>(null);

    const [stepProcess, setStepProcess] = useState<number>(0); // Magic number to control the steps of the process

    const [title, setTitle] = useState<string>('¡Empezamos!') ; // title of the modal
    const [subtitle, setSubtitle] = useState<string>(''); // subtitle of the modal

    const [serviceType, setServiceType] = useState<ServiceTypeInterface | undefined>(undefined); // service type
    const [serviceSelected, setServiceSelected] = useState<ServiceInterface | undefined>(undefined); // service selected

    // method to validate the fields of the process service
    const handleNextStepClick = () => {
        if(processServiceRef?.current) {
            processServiceRef.current.validateFields();
        }   
    };

    // method to go back to the previous step
    const handleBeforeStepClick = () => {
        if(stepProcess>0) {
            if(stepProcess === 3 && (serviceType?.id === "agenciamiento-de-aduana" || serviceType?.id === "courier")) { 
                setShow(false);
                return;
            }
            setStepProcess((prev) => prev - 1);
        }
    }

    const handleCloseModal = () => {
        if(searchParams.get(SHOW_MODAL)) {
            searchParams.delete(SHOW_MODAL);
            setSearchParams(searchParams);
            console.log("delete", SHOW_MODAL)
        }
        setShow(false);
    }

    // useEfect to set the service type
    useEffect(() => {
        if (service) {
            setServiceSelected(service);
        }
    }, [service]);

    // useEffect to set the title of the modal and the step process when the request quote is updated
    useEffect(() => {
        if(requestQuote && requestQuote.id) {
            setTitle(requestQuote.title);
            setStepProcess(1);
        }
    }, [requestQuote]);

    useEffect(() => {
        if(searchParams.get(SHOW_MODAL) && searchParams.get(SHOW_MODAL) === MODAL_REQUEST ) {
            setShow(true);
        }
    }, [])

    useEffect(() => {
        return () => {
            window.document.body.style.overflow = 'unset'
        }
    }, []);

    

    useEffect(() => {
        show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    }, [show]);

    return (
     <> { show && <div className="modalProcessService">
        <div className="modalProcessService-container">
            <ProcessServiceHeader 
                title={stepProcess === 0 ? "¡Empezamos!" : title} 
                subtitle={subtitle} 
                showLogo={stepProcess>0} 
                onClose={()=>handleCloseModal()} />

            <div className="modalProcessService-content" style={{marginTop: stepProcess === 0 ? 20 : 30}}>
                <ProcessService 
                    companyId={companyId}
                    requestQuoteUpdate={requestQuote}
                    serviceTypeId={serviceType?.id  ? serviceType?.id  : serviceTypeId} 
                    stepProcess={stepProcess}
                    setStepProcess={setStepProcess}
                    fromPage={fromPage}
                    title={title}
                    setTitle={setTitle}
                    ref={processServiceRef}
                    serviceSelected={serviceSelected}
                    setServiceSelected={setServiceSelected}
                    setServiceType={setServiceType}
                    setShowModal={setShow}
                    updatedData={updatedData}
                    planIndex={planIndex}
                />
            </div>
            <ProcessServiceFooter
                stepProcess={stepProcess}
                onClickNextStep={handleNextStepClick}
                onClickBeforeStep={handleBeforeStepClick}
                isOrder={serviceTypeId !== 'agenciamiento-de-aduana' && serviceTypeId !== 'courier'}
                
                showDraftButton={serviceType?.id === "agenciamiento-de-aduana" || serviceType?.id === "courier"}
            />
        </div> </div>}
     </>
    )
}