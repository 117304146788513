import React, { useEffect, useRef, useState } from "react";
import { ServiceInterface, ServiceTypeInterface } from "../../interfaces/Service";
import { UpBlack } from "../../icons/solid/UpBlack";

import './SelectServiceType.css'
import { GetAllServicesHover } from "../../Services/ServiceType.service";
import { GetServiceByCompany } from "../../Services/Service.service";
import { Loader } from "../Loader/Loader";
import { set } from "date-fns";
import { LinearLoader } from "../LinearLoader/LinearLoader";
import { ErrorInterface } from "../../interfaces/ErrorInterface";
import { FromPage } from "../../@types/fromPageTypes";

interface SelectServiceTypeProps {
    companyId: string;
    serviceTypeId: string;
    handleServiceTypeChange: (serviceType: ServiceTypeInterface) => void;
    setServiceSelected : (serviceDetail: ServiceInterface | undefined) => void;
    fromPage: FromPage;
    disabled?: boolean;
    error?: ErrorInterface
}

export const SelectServiceType: React.FC<SelectServiceTypeProps> = ({
    companyId,
    serviceTypeId,
    handleServiceTypeChange = () => {},
    setServiceSelected = () => {},
    disabled = false,
    error
}) => {

    const [loading, setLoading] = useState<boolean>(false);
   
    const [showItems, setShowItems] = useState(false);
   
    // useState all service type
    const [serviceTypeList, setServiceTypeList] = useState<ServiceTypeInterface[]>([]);
    // useState all service type
    const [serviceTypeCompany, setServiceTypeCompany] = useState<ServiceTypeInterface[]>([]);
    // useState service type was selected
    const [serviceTypeSelected, setServiceTypeSelected] = useState<ServiceTypeInterface>({
        id: '',
        name: '',
        status: true,
        urlIcon: "",
        createdAt: new Date(),
        updatedAt: new Date(),
    });
    // useState service list by company
    const [serviceList, setServiceList] = useState<ServiceInterface[]>([]);

    const [selectType, setSelectType] = useState<string>('');

    const handleShowCategoryClick = () => {
        setShowItems(!showItems);
    }

    const handleCategoryClick = (id: string) => {
        setShowItems(false);
        setSelectType(id === 'agenciamiento-de-aduana' ? 'Para importes de 2,000 USD a más' : (id === 'courier' ? 'Para importes menores a 2,000 USD' : ''));
        let serviceType = serviceTypeList.find((item) => item.id === id);
        if(serviceType) {
            setServiceTypeSelected(serviceType);
            handleServiceTypeChange(serviceType);
            if(companyId.length>0 && serviceList.length>0) {
                setServiceByType(id, serviceList);
            }
        }

        
    }

    const setServiceByType = (serviceTypeId: string, services: ServiceInterface[]) => {
        const service = services.find((item) => item.serviceType === serviceTypeId);
        if(service) {
            setServiceSelected(service);
        }
    }

    // method to get all services by company
    const getServiceByCompany = async () => {
        if (!loading) {
          try {
            setLoading(true);
            const response = await GetServiceByCompany(companyId);
            if(response.length>0) {
                
                handleCategoryClick(response[0].serviceType);            
                setServiceByType(response[0].serviceType, response);
                setServiceList(response);
                             
                const tmp = response.map((item) => ({
                    id: item.serviceType,
                    name: serviceTypeList.find((i) => i.id === item.serviceType)?.name || "",
                    status: true,
                    createdAt: serviceTypeList.find((i) => i.id === item.serviceType)?.createdAt || new Date(),
                    updatedAt: serviceTypeList.find((i) => i.id === item.serviceType)?.updatedAt || new Date(),
                    urlIcon: serviceTypeList.find((i) => i.id === item.serviceType)?.urlIcon || "",
                }));
                setServiceTypeCompany(tmp);
                setServiceTypeSelected(tmp[0]);
            } 
            setLoading(false);
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
        }
    }

    // method to get all services type
    const getAllServiceType = async () => {
        if(!loading) {
            try {
                const response = await GetAllServicesHover();
                if(response.length>0) { 
                    setServiceTypeList(response);
                    if(serviceTypeId.length>0) { // if have service type id set service type selected
                        const serviceType = response.find((item) => item.id === serviceTypeId);
                        setServiceTypeSelected(serviceType);
                        setSelectType(serviceType?.id === 'agenciamiento-de-aduana' ? 'Para importes de 2,000 USD a más' : (serviceType?.id === 'courier' ? 'Para importes menores a 2,000 USD' : '') );
                       // handleServiceTypeChange(serviceType);
                    } else { // else set first service type
                        setServiceTypeCompany(response);
                        setServiceTypeSelected(response[0]);
                        handleServiceTypeChange(response[0]);
                        setSelectType(response[0].id === 'agenciamiento-de-aduana' ? 'Para importes de 2,000 USD a más' : (response[0].id === 'courier' ? 'Para importes menores a 2,000 USD' : '') );
                    }      
                }
            } catch (error) {
                console.log("🚀 ~ file: SelectServiceType.tsx:116 ~ getAllServiceType ~ error:", error)
            }
        }
    }

    // use effect to get all services by company
    useEffect(() => {
        getAllServiceType();
    },[]);

    // use effect to get all services by company when company id change or service type list change
    useEffect(() => {
        companyId.length>0 && serviceTypeList.length>0 && getServiceByCompany();
    }, [serviceTypeList, companyId]);

    return (
        <>
            { !loading ? <div className="selectServiceType-container">
                    {serviceTypeSelected.name.length>0 &&<div className='selectServiceType-item  paragraph-header' onClick={()=>(serviceTypeCompany.length>1  && !disabled) ?  handleShowCategoryClick() : {}}>
                        <div className="selectServiceType-item-row">
                            <img className="selectServiceType-logoMin" src={serviceTypeSelected.urlIcon}/>
                            <span style={{color:'var(--gray-1)'}} className='paragraph selectServiceType-text-filter'> 
                                {serviceTypeSelected.name}
                            </span>  
                        </div>
        
                        <UpBlack/>
                    </div>}                
                    { showItems && 
                        <div className="selectServiceType--options" >
                            {
                                serviceTypeCompany.map((c:any, idx)=> (
                                    !c.hover && 
                                    <div 
                                        key={idx} 
                                        className={`selectServiceType--options-item paragraph ${serviceTypeSelected === c.id ? 'filterSelectorExtended--options-background_selected' : 
                                                'selectServiceType--options-background'}`} 
                                                onClick={()=> handleCategoryClick(c.id)}>
                                            <img src={c.urlIcon}/>      
                                            {c.name}
                                    </div>
                            ))}
                        </div>
                    }
                   {error && error.status && <div className="text-error ">{error.message}</div>}

                   <div className="selectServiceType-select"> 
                        <div className="selectServiceType-select tinytext">{selectType}</div>
                    </div>

                </div> : <div className="selectServiceType-loader">
                    <LinearLoader/>
                </div>
            }
        </>
    )
};