import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CloseBlackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const CloseBlack: React.FC<CloseBlackProps> =({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/png/close-black.png"} alt=""  />
        </div>
    )
}