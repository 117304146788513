import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CheckBlackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const CheckBlack: React.FC<CheckBlackProps> =({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/png/done_black.png"}  alt=""  />
        </div>
    )
}