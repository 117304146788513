import React, { useState, useRef, useEffect } from "react";

import { HOURS } from "../../../../Consts/timeLine";
import {format, isSameDay} from "date-fns";

import { EventCalendar } from "../Event/EventCalendar";

import './EventGrid.css';


interface EventGridCalendarProps {
   events: any[]
}

export const EventGridalendar: React.FC<EventGridCalendarProps> = ({
    events
}) => { 

    return (
        <div className="eventGridCalendar-container">
            {[...Array(336)].map((row, idx) => {  /** Array para la cantidad de grillas */
                return (
                    <div key={idx} className={"eventGridCalendar-detail--row"}  >
                    {[...Array(29)].map((m, idy) => <div key={idy} className="eventGridCalendar-detail--minute">
                            { (idx === (12*2*7+1) && idy === 0) &&  //formula para hallar 
                                <EventCalendar
                                    title= "Oficina"
                                    detail = "Este es mi primer recordatorio."
                                    priority = {'low'}
                                    startHour={20}
                                    startMinute={10}
                                    endHour={21}
                                    endMinute={40}
                                /> 
                            }      
                        </div>)}
                    
                    </div>

                    /*
                <div key={idx} className={"eventGridCalendar-detail--row" + ((idx%2 ===0) ? ' eventGridCalendar-detail--transparent': '')}  >
                    {[...Array(29)].map((m, idy) => <div key={idy} className="eventGridCalendar-detail--minute">
                            { (idx === (12*2*7+1) && idy === 0) &&  //formula para hallar 
                                <EventCalendar
                                    title= "Oficina"
                                    detail = "Este es mi primer recordatorio."
                                    priority = {'high'}
                                    startHour={12}
                                    startMinute={0}
                                    endHour={13}
                                    endMinute={0}
                                /> 
                            }      
                        </div>)}
                    
                    </div>* */
                )
            })
            }
        </div>   
    )
}