import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type TiktokProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Tiktok: React.FC<TiktokProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/tiktok-slogan.svg'} alt='' />
        </div>
    )
}  