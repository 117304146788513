import axios from "axios";
import { FUNCTIONS_API, FUNCTIONS_API_VALIDATE_COUPON } from "../Consts/BaseUrl";
import { ComparePlansInterface } from "../interfaces/ComparePlanInterface";
import { SecondaryPlanInterface } from "../interfaces/SecondaryPlanInterface";

const { 
    REACT_APP_BASIC_AUTH
  } = process.env;

// method to get compare table
export const validateCounCode = async (couponCode: string, planId: string, currency: string): Promise<{
    status: boolean;
    secondaryPlan: SecondaryPlanInterface;
}>=> {
    let config = {
        method: 'post',
        url: FUNCTIONS_API+FUNCTIONS_API_VALIDATE_COUPON,
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Basic '+REACT_APP_BASIC_AUTH,
        },
        data : {
            "couponCode": couponCode,
            "planId": planId,
            "currency": currency
        }
    };
    return axios.request(config)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error);
            throw error;
        });
}