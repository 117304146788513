import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, getDoc, doc, addDoc, updateDoc } from "firebase/firestore";

import { ArrivalControlChannel } from "../interfaces/ArrivalControlChannelInterface";

export const GetArrivalControlChannel = async (id: string) => {
    const response = await getDoc(doc(DB, 'arrivalControlChannel', id));
    // console.log(response.data())
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()});
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

export const CreateArrivalControlChannel = async (arrivalControlChannel:ArrivalControlChannel) => {
    try {
        const response = await addDoc(collection(DB, "arrivalControlChannel"), arrivalControlChannel);
        // console.log("🚀 ~ file: ArrivalControlChannel.service.ts ~ line 22 ~ CreateArrivalControlChannel ~ response", response)
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const UpdateArrivalControlChannel = async (arrivalControlChannel:ArrivalControlChannel) => {
    try {
        if(arrivalControlChannel.id) {
            const requestRef = doc(DB, 'arrivalControlChannel', arrivalControlChannel.id);
            const response = await updateDoc(requestRef, {...arrivalControlChannel});
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}