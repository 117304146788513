import React, { useRef } from "react";

/** importando estilos */
import "./Subheader.css";

/**declaracion de variables de interface */
interface SubheaderProps {
  active?: boolean;
  content: any;
  index?: number;
  className?: string;
  handleClick: (e: string)=> void;
  setIndex?: (e: number) => void;
  whiteMode?: boolean; // active el modo white de los items
}

/**clase SubHeader 
 * renderiza un sub menu
 * propiedades:
 * - handleClick => onClick el el sub menu
*/
export const Subheader: React.FC<SubheaderProps> = ({ 
  active, 
  content, 
  handleClick,
  index=0,
  setIndex = ()=>{},
  className='',
  whiteMode = false,
  ...props
}) => {
  const subheaderRef = useRef<HTMLSpanElement>(null);

  if (active) {
    subheaderRef.current?.classList.add(whiteMode ? "subheader-clicked" : "subheader-clicked-black");
  } else {
    subheaderRef.current?.classList.remove(whiteMode ? "subheader-clicked" : "subheader-clicked-black");
  }

  const handleItemClick = () => {
    handleClick(content); 
    setIndex(index)
  }


  return (
    <span {...props} 
      role='sub-header' 
      onClick={()=>handleItemClick()} 
      className={`${whiteMode ? 'subheader_white' : 'subheader'} ${className ? className: 'paragraph-header'} ${active ? "subheader-clicked" : ""}`} 
      ref={subheaderRef}>
        {content}
    </span>
  );
};
