import React from "react"

// import style
import './TypeTransportInput.css';

// import components
import { Onboarding } from "../Onboarding/Onboarding";
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";

// import icons
import { PlaneGray } from "../../icons/solid/PlaneGray";
import { PlaneWhite } from "../../icons/solid/PlaneWhite";
import { BoatGray } from "../../icons/outline/BoatGray";
import { BoatWhite } from "../../icons/outline/BoatWhite";

// interfaces
import { ErrorInterface } from "../../interfaces/ErrorInterface";
import { ButtonTypeTransport } from "./ButtonTypeTransport/ButtonTypeTransport";
import { World } from "../../icons/solid/World";



interface TypeTransportInputProps {
    type: 'air' | 'sea' | 'land' | undefined; 
    handleAirClick: () => void;
    handleSeaClick: () => void;
    error: ErrorInterface;
}

export const TypeTranportationInput : React.FC<TypeTransportInputProps> = ({
    type,
    handleAirClick,
    handleSeaClick,
    error,
}) => {

    return (
        <div className="inputOriginDestiny-column">
            <div className="paragraph-header inputOriginDestiny-row inputOriginDestiny-type_title" id="inputOriginDestiny-type_title" >
                <World/>
                Medio de transporte
            </div>
            <div className="inputOriginDestiny-row-button">
                <ButtonTypeTransport 
                    icon={<PlaneGray className="icon-plane"/>}
                    title="Aéreo"
                    isActive={type==='air'}
                    onClick={handleAirClick}

                />
                <ButtonTypeTransport 
                    icon={<BoatGray className="icon-boat" />}
                    title="Marítimo"
                    isActive={type==='sea'}
                    onClick={handleSeaClick}
                />
        </div>
            {(error.status && !type) && <div className="text-error tinytext-header">{error.message}</div>}
        </div>
    )
}