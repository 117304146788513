import React, { useEffect, useState, useRef } from "react";

import  './NotificationComponent.css';
import { AttachChat } from "../../interfaces/MessageInterface";
import { ModalNotification } from "../Modal/ModalNotification/ModalNotification";
import { BellWhite } from "../../icons/solid/BellWhite";
import { CardFriend } from "../Modal/ModalNotification/Card/CardFriends/CardFriend";
import { CardNotification } from "../Modal/ModalNotification/Card/CardNotifications/CardNotification";

import { Notification } from "../../testdata/Notification";
import { BellBlack } from "../../icons/solid/BellBlack";
import { NotificationInterface } from "../../interfaces/NotificationInterface";
import { useNavigate } from "react-router-dom";
import { UpdateNotification } from "../../Services/Notification.service";
import { Loader } from "../Loader/Loader";
import { PanelContent } from "../PanelContent/PanelContent";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface NotificationComponentProps {
    notifications: NotificationInterface[];
    showModalRight: boolean;
    setShowModalRight: React.Dispatch<React.SetStateAction<boolean>>;
    handleModalNotification: ()=>void;
}

export const NotificationComponent: React.FC<NotificationComponentProps> = ({
    notifications,
    showModalRight,
    setShowModalRight,
}) => {

    const navigate = useNavigate();

    const [activeSubMenu, setActiveSubmenu] = useState('Todo');

    const [loading, setLoading] = useState<boolean>(false);

    const [read, setRead] = useState(
        Notification.filter(n => n.read === true)
    );
    const [noRead, setNoRead] = useState(
        Notification.filter(n => n.read === false)
    );

    // method to redirect path url 
    const reditectToPage = (redirectUrl: string) => {
        navigate(redirectUrl, {replace: true});
        setShowModalRight(false);
    }

    // methos to mark as read in notification firestore
    const readNotificacion = (notificationId: string, cb: () => void) => {
       return new Promise((resolve, reject) => {
            UpdateNotification(notificationId, {
                isNew: false,
                updatedAt: new Date(),
            }).then((response) => {
                resolve(cb());
            }).catch((error) => {
                console.log("🚀 ~ file: NotificationComponent.tsx:48 ~ handleItemClick ~ error:", error);
                reject(error);
            });
       })
    }

    // handle click item
    const handleItemClick = (notificationId: string, redirectUrl: string, isNew: boolean) => {
        if(redirectUrl.length> 0 && isNew) {
            setLoading(true);
            readNotificacion(notificationId, () => {
                setLoading(false);
                reditectToPage(redirectUrl)
            }).catch((error) => {
                setLoading(false);
                console.log("🚀 ~ file: NotificationComponent.tsx:48 ~ handleItemClick ~ error:", error)
            });
        } else {
            reditectToPage(redirectUrl);
        }
    }

    // methos to mark as read all notifications when user close modal
    const markAsReadAllNotificacion = () => {
        let notificationsMark = notifications.filter((n) => n.isNew);
        notificationsMark.forEach((n) => {
            readNotificacion(n.id ? n.id : "", ()=> {});
        });
    }

    useEffect(() => {
        return () => {
            markAsReadAllNotificacion();
        }
    }, []); 

    return (
        <>
            {/* <div className='messageTopBar-user_main--imageIcon' onClick={()=>setShowModalRight(true)}>
                <BellBlack/>
            </div> */}
            <PanelContent 
                show={showModalRight} 
                setShow={setShowModalRight}
                // active={activeSubMenu}
                // setActive={setActiveSubmenu}
                // subMenu={[]}
                title={'Notificaciones'}
                detail=""
                icon=""
            >
                
                <div style={{display: 'flex', flexDirection: 'column', gap: '30px' }}>
                 {/* {noRead.length>0 && activeSubMenu==='Todo' &&<div className="small-bold ">Nuevas</div>} */}
                 {notifications.filter((n) => n.isNew === true).length>0 &&
                    <div className="panel-content-notification">
                        <div className="panel-content-notification-header">
                            <img src={STORAGE_ASSETS+"/images/png/ambulancia.png"} alt="" />
                            <div className="paragraph">Nuevos</div>
                        </div>

                        <div className="panel-content-notification-body">
                        { notifications.filter((n) => n.isNew === true).map((n, idx) => {
                            return (
                            <React.Fragment key={idx}> 
                                <CardNotification 
                                    key={idx} 
                                    isNew={n.isNew === true ? true : false}
                                    status={n.status === true ? true : false}
                                    title={n.title} 
                                    content={n.content}
                                    operationName={n.operationName}
                                    redirectUrl={n.deeplink}
                                    createAt={n.createdAt.toDate()}  
                                    handleItemClick={()=> handleItemClick(n.id ? n.id : "", n.deeplink, n.isNew)}
                                    isCompany={true}
                                    companyImage={''}
                                    profileUrl={n.img}
                                    userName={n.name.split(" ")[0][0]} // nomber del usuario
                                    userLastName={n.name.split(" ").length>1 ? n.name.split(" ")[1][0]: ""} // apellido del usuario
                                />  
                            </ React.Fragment>
                            )})   
                        }
                        </div>
                    </div>}

                    <div className="panel-content-notification">
                        {notifications.filter((n) => n.isNew === false).length>0 && <div className="panel-content-notification-header">
                            <img src={STORAGE_ASSETS+"/images/png/anterior.png"} alt="" />
                            <div className="paragraph">Anteriores</div>
                        </div>}

                        <div className="panel-content-notification-body">
                        {notifications.filter((n) => n.isNew === false).map((n, idx) => {
                            return (
                            <React.Fragment key={idx}>
                                <CardNotification 
                                    key={idx} 
                                    isNew={n.isNew === true ? true : false}
                                    status={n.status === true ? true : false}
                                    title={n.title} 
                                    content={n.content}
                                    operationName={n.operationName}
                                    redirectUrl={n.deeplink}
                                    createAt={n.createdAt.toDate()}  
                                    handleItemClick={()=> handleItemClick(n.id ? n.id : "", n.deeplink, n.isNew)}
                                    isCompany={true}
                                    companyImage={''}
                                    profileUrl={n.img}
                                    userName={n.name.split(" ")[0][0]} // nomber del usuario
                                    userLastName={n.name.split(" ").length>1 ? n.name.split(" ")[1][0]: ""} // apellido del usuario
                                />  
                            </ React.Fragment>
                            )}) }
                        </div>
                        {notifications.length === 0 &&  <div className="paragraph">¡Lo sentimos! Por el momento no tiene notificaciones.</div> }
                    </div>
                </div>
            </PanelContent>
        </>
    )
}