import React from "react";

import './LoaderShimmerOperation.css'

export const LoaderShimmerOperation: React.FC<{}> = ({...props}) => {
    return (
        <div className="card-operation">
            <div className="loader-shimmer-operation-header">
                <div className="loader-shimmer-operation-row">
                    <div className="loader-shimmer-operation-logo shimmer-operation-animation"></div>
                    <div className="loader-shimmer-operation-text shimmer-operation-animation"></div>
                </div>
                <div className="loader-shimmer-operation-item shimmer-operation-animation"></div>
            </div>

            <div className="loader-shimmer-operation-body">
                <div className="loader-shimmer-operation-body_content">
                    <div className="loader-shimmer-operation-table_item shimmer-operation-animation"></div>
                    <div className="loader-shimmer-operation-table_item shimmer-operation-animation"></div>
                    <div className="loader-shimmer-operation-table_item shimmer-operation-animation"></div>
                    <div className="loader-shimmer-operation-table_item shimmer-operation-animation"></div>
                </div>

                <div className="loader-shimmer-operation-body_content">
                    <div className="loader-shimmer-operation-table_item shimmer-operation-animation"></div>
                    <div className="loader-shimmer-operation-table_item shimmer-operation-animation"></div>
                    <div className="loader-shimmer-operation-table_item shimmer-operation-animation"></div>
                    <div className="loader-shimmer-operation-table_item shimmer-operation-animation"></div>
                </div>
            </div>
            
        </div>
    )
}