import React, { useEffect, useState } from "react";

/** importando estilos */
import "./OptionMultiple.css";
import { Dots } from "../../icons/outline/Dots";
import { CardContent } from "../CardContent/CardContent";
import { OperationHeaderOptionInterface } from "../../interfaces/OperationHeader";

interface OptionMultipleProps {
  item: OperationHeaderOptionInterface[];
}

export const OptionMultiple: React.FC<OptionMultipleProps> = ({
  item
}) => {

  const [showOption, setShowOption] = useState<boolean>(false);

  const handleShowOption = () => {
    setShowOption(!showOption)
  }


  const onClickItem = (method: ()=> void) => {
    setShowOption(false); 
    method(); 
  }

  return (
      <>
        <div className="option-content" role='option'>
        <Dots onClick={handleShowOption}/>
          
        </div>
        { showOption && 
          <div className="chat-page-header-option">
            {item.map((item, id) => (
              <div className="paragraph chat-list-option" key={id} 
                onClick={()=>onClickItem(item.method)}>
                  {item.name}
              </div>
            ))}
          </div>
        }
      </>
  );
}