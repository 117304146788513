import React, { useEffect, useState } from "react";

/** Importando componente */
import { Button } from "../Button/Button";
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { CityCountryDisplay } from "../CityCountryDisplay/CityCountryDisplay";
import { CommentCount } from "../CommentCount/CommentCount";
import { Rating } from "../Rating/Rating";
import LazyLoad from 'react-lazyload';
import { Status } from "../Status/Status";

/** Importando estilo */
import "./CompanyCardResponsive.css";

/** Importando interfaces */
import { Company } from "../../interfaces/Company";
import { Wsp } from "../../icons/outline/Wps";
import { CheckCirlceGreen } from "../../icons/solid/Check_Circle_Green";
import { Verify } from "../Verify/Verify";
import { VerifyBlack } from "../../icons/solid/VerifyBlack";
import { VerifyBlue } from "../../icons/solid/Verify_blue";
import { UserActive } from "../UserActive/UserActive";
interface CompanyCardProps {
  company: Company;
  supplier?: boolean;
  setSupplier: React.Dispatch<React.SetStateAction<boolean>>;
  clickProfile?: () => void;
  clickMessage: (companyId: string) => void;
  verify?: boolean;
  numberVerify: number;
}

export const CompanyCardResponsive: React.FC<CompanyCardProps> = ({ 
  company,
  supplier,
  setSupplier,
  clickProfile,
  clickMessage,
  verify=false,
  numberVerify
}) => {

  const [isLike, setIsLike] = useState(false);

  const [showVerify, setShowVerify] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [more, setMore] = useState(false);

  const handleVerify = () => {
    setShowVerify(!showVerify)
  }

  const handleSendMessageClick = () => {
    setLoading(true);
    clickMessage(company.id?company.id:"");
    setLoading(false);
  }

  useEffect(()=> {
    window.innerWidth <= 600 && ( showVerify ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset')
}, [showVerify]);

  return (
    <div className={`company-card-responsive__main box-shadow-card ${showVerify ? 'company-card-responsive__none' : '"company-card-responsive__main"'}`}>

      {showVerify && 
      <Verify 
        verify={showVerify} 
        numberVerify={numberVerify}
        setShowVerify={setShowVerify}/>}

      <div className="company-card-responsive_column">
        <div className="company-card-responsive">
          <div className="company-card-responsive-imgs">
              <LazyLoad height={200}>
              {/* <img role={'company-card-responsive-bg'}
                  className="company-card-responsive-bg"
                  src={company.photoProfile}
                  alt={`${company.name}`}/> */}
              <UserActive 
                userId={company.id?company.id:''}
                userImg={company.photoProfile}
                isConnected={true}
                name={`${company.name}`}
                lastname={''}
                loadUser={()=>{}} 
              />
              </LazyLoad>
          </div>

          <div className="company-card-info-responsive">
            <div className="company-card-responsive-title">
              <h1 className="paragraph-header">{company.name}</h1>
              
              {verify && numberVerify < 3 ? 
                <VerifyBlack onClick={handleVerify}/> : 
                <VerifyBlue onClick={handleVerify}/>}
            </div>
            <h2 className="smalltext" style={{color: '#333333'}}>{company.catProviderName}</h2>
            { supplier ? '' :
              <div className="company-card-responsive-social">
                  <Rating rating={Number((company.rating).toFixed(1))} />
                  <CommentCount commentsCount={company.numComments} />
                  <CityCountryDisplay
                  city={company.city}
                  country={company.country.alpha2Code}
                  flagImg={company.country.imgFlag}/>
                  {/* <Status online/> */}
              </div>
            }
            {/* <div className="company-card-info-responsive-row">
              <h3 className="smalltext">
                <span className="smalltext-header">
                {company.numOperation}
                </span>{" "}
                Operaciones Exitosas
              </h3>
              <h2 className="smalltext-header company-card-responsive-more" onClick={handleShowMore}>{!more ? 'Ver más': 'Ver menos'}</h2>
            </div> */}
          </div>
        </div>

        {/* <div className="company-card-responsive-info">
            <div className="company-card-responsive-main">
                <div className="company-card-responsive-title">
                    <h1 className="small-bold">{company.name}</h1>
                    <CheckCirlceGreen/>
                </div>
                <div className="company-card-responsive-row">
                    <h2 className="smalltext">{company.serviceType}</h2>
                    <h2 className="smalltext-header company-card-responsive-more" onClick={handleShowMore}>{!more ? 'Ver más': 'Ver menos'}</h2>
                </div>
            
                {/** Muestra el detalle del proveedor 
                {more && <div>
                    <h3 className="smalltext">
                        <span className="smalltext-header">
                        {company.numOperation}
                        </span>{" "}
                        Operaciones Exitosas
                    </h3>
                    <div className="company-card-responsive-work">
                        <h4 className="smalltext-header">¿Por qué trabajar con nosotros?</h4>
                        <p className="smalltext">{company.aboutUs}</p>
                    </div>
                </div>}

                { supplier ? '' :
                <div className="company-card-responsive-social">
                    <Rating rating={Number((company.rating).toFixed(1))} />
                    <CommentCount commentsCount={company.numComments} />
                    <CityCountryDisplay
                    city={company.city}
                    country={company.country.alpha2Code}
                    flagImg={company.country.imgFlag}/>
                    {/* <Status online/>
                </div>
              }
          </div>
        </div> */}
        
        {more && <div>
                {/* <h3 className="smalltext">
                    <span className="smalltext-header">
                    {company.numOperation}
                    </span>{" "}
                    Operaciones Exitosas
                </h3> */}
                <div className="company-card-responsive-work">
                    <h4 className="smalltext-header">¿Por qué trabajar con nosotros?</h4>
                    <p className="smalltext">{company.aboutUs}</p>
                </div>
            </div>}

        { supplier ? '' :
          <div className="company-card-responsive-btns">
            {/* <Button role={'profile-btn'} content="Ver Perfil" size="small" onClick={clickProfile} color="yellow"/> */}
            <Button role={'message-btn'} 
              content="Enviar mensaje"
              color="blue-2" 
              size="small" 
              onClick={()=>handleSendMessageClick()}
              loading={loading}
              disabled={loading}
            />
            {/*<ButtonIcon role={'profile-btn'} content="WhatsApp" size="small" Icon={<Wsp />} onClick={clickMessage} color="green-2"/>*/}
          </div>
        }
      </div>
    </div>
  );
};
