import React, { useEffect, useState } from "react";
import { Importer, InvoiceTranslation } from "../../../interfaces/InvoiceTranslation";
import { Dropdown } from "../../Dropdown/Dropdown";
import { TextField } from "../../TextField/TextField";

import './InvoceTranslationImporter.css';
import { CardContent } from "../../CardContent/CardContent";
import { ButtonEditChange } from "../../ButtonEditChange/ButtonEditChange";

interface InvoceTranslationImporterProps {
    typeIt: string[];
    typeDocument: string[];
    commercialLevel: string[];
    importer: Importer;
    disabled?: boolean; // permite activar y desactivar el texField, para poder editar
    setInvoiceTranslation: React.Dispatch<React.SetStateAction<InvoiceTranslation>>;
    handleSave: () => void;
    handleRefresh: () => void;
}

export const InvoceTranslationImporter: React.FC<InvoceTranslationImporterProps> = ( {
    typeIt,
    typeDocument,
    commercialLevel,
    importer,
    setInvoiceTranslation,
    disabled=false,
    handleSave=()=>{},
    handleRefresh
}) => {

    const [ showEdit, setShowEdit ] = useState<boolean>(disabled);

    const handleTypeDocumentChange = (value: string) => {
        setInvoiceTranslation((prev:any) => ({
            ...prev,
            importer: {
                ...prev.importer,
                typeDocument: value
            }
        }));
        // handleAutoSave();
    }

    const handleBusinessNameChange = (value: string) => {
        setInvoiceTranslation((prev:any) => ({
            ...prev,
            importer: {
                ...prev.importer,
                nameCompany: value
            }
        }));
        // handleAutoSave();
    }

    const handleRUCChange = (value: number) => {
      if(value<=99999999999) {
        setInvoiceTranslation((prev:any) => ({
            ...prev,
            importer: {
                ...prev.importer,
                itNumber: value
            }
        }));
        // handleAutoSave();
      }
    }

    const handleCommercialChange = (value: string) => {
        setInvoiceTranslation((prev:any) => ({
            ...prev,
            importer: {
                ...prev.importer,
                commercialLevel: value
            }
        }));
        // handleAutoSave();
    }

    const handleNameChange = (value: string) => {
        setInvoiceTranslation((prev:any) => ({
            ...prev,
            importer: {
                ...prev.importer,
                name: value
            }
        }));
        // handleAutoSave();
    }

    const handleLastNameChange = (value: string) => {
        setInvoiceTranslation((prev:any) => ({
            ...prev,
            importer: {
                ...prev.importer,
                lastName: value
            }
        }));
        // handleAutoSave();
    }

    const handleTypeIthange = (value: string) => {
        setInvoiceTranslation((prev:any) => ({
            ...prev,
            importer: {
                ...prev.importer,
                typeIt: value
            }
        }));
        // handleAutoSave();
    }

    const handleItNumberChange = (value: string) => {
        setInvoiceTranslation((prev:any) => ({
            ...prev,
            importer: {
                ...prev.importer,
                itNumber: value
            }
        }));
        // handleAutoSave();
    }

    const getStatusEdit = () => {
        if(importer.typeDocument === "Persona jurídica") {
            if(importer.typeIt.length>0 && importer.nameCompany.length>0 && importer.itNumber>0 && 
                importer.commercialLevel.length>0) {
                    setShowEdit(true);
                }
        } else if(importer.typeDocument === "Persona natural") {
            if(importer.name.length>0 && importer.lastName.length>0 && importer.typeIt.length>0 && 
                importer.itNumber>0) {
                    setShowEdit(true);
                }
        }
    }

    const handleSaveClick = () => {
        handleSave();
        setShowEdit(true);
    
    }

   const handleCancelClick = () => {
         handleRefresh()
        setShowEdit(true);
    }

    useEffect(() => {
       getStatusEdit();
    }, []);

    return ( 
        <div role='invoceTranslationImporterRole' className='invoceTranslationImporter-main'>
            <CardContent>
                <p className='paragraph-header invoceTranslationImporter-color'>Importador</p>
                <div className="paragraph invoceTranslationImporter-color">
                    Es la persona natural o jurídica que va a ser declarada como el 
                    dueño de la carga para los trámites aduaneros.
                </div>
                <Dropdown 
                    title='Entidad'
                    value={importer.typeDocument}
                    elements={typeDocument}
                    setValue={(e:any)=> {handleTypeDocumentChange(e)}}
                    required
                    disabled={showEdit}
                />

                {importer.typeDocument === 'Persona jurídica' && 
                    <>
                        <div className='invoceTranslationImporter-input'>
                            <TextField
                                title='RUC'
                                placeholder=' '
                                classNameText="invoceTranslationImporter-input--ruc"
                                value={importer.itNumber === 0 ? '' : importer.itNumber}
                                type='number'
                                onChange={(e:any)=>{handleRUCChange(e.target.value)}}
                                required
                                disabled={showEdit}
                                role='invoceTranslationImporterRUC'/>

                            <TextField
                                title='Razón social'
                                placeholder=' '
                                classNameText="invoceTranslationImporter-input--businessName"
                                value={importer.nameCompany}
                                onChange={(e:any) => handleBusinessNameChange(e.target.value)}
                                required
                                disabled={showEdit}
                                role='invoceTranslationImporterBusinessName'/>
                            
                            <Dropdown 
                                title='Nivel comercial'
                                value={importer.commercialLevel}
                                elements={commercialLevel}
                                setValue={(e:any)=> {handleCommercialChange(e)}}
                                required
                                disabled={showEdit}/>
                        </div>
                        <div className='invoceTranslationImporter-input-m'>
                            <TextField
                                title='Razón social'
                                placeholder=' '
                                classNameText="invoceTranslationImporter-input--businessName"
                                value={importer.nameCompany}
                                onChange={(e:any) => handleBusinessNameChange(e.target.value)}
                                required
                                disabled={showEdit}
                                role='invoceTranslationImporterBusinessName'/>
                            <div className='invoceTranslationImporter-input-m-row'>
                                <TextField
                                    title='RUC'
                                    placeholder=' '
                                    classNameText="invoceTranslationImporter-input--ruc"
                                    value={importer.itNumber}
                                    type='number'
                                    onChange={(e:any)=>{handleRUCChange(e.target.value)}}
                                    required
                                    disabled={showEdit}
                                    role='invoceTranslationImporterRUC'/>

                                <Dropdown 
                                    title='Nivel comercial'
                                    value={importer.commercialLevel}
                                    elements={commercialLevel}
                                    setValue={(e:any)=> {handleCommercialChange(e)}}
                                    required
                                    disabled={showEdit}/>
                            </div>
                    </div>
                    </>
                }

                { importer.typeDocument === 'Persona natural' && 
                    <div className='invoceTranslationImporter-input-natural'>
                        {/* {!showEdit ?  */}
                        <TextField
                            title='Nombre'
                            placeholder=' '
                            classNameText="invoceTranslationImporter-input--personNatural"
                            value={importer.name}
                            onChange={(e:any) => handleNameChange(e.target.value)}
                            required
                            disabled={showEdit}
                            role='invoceTranslationImporterBusinessName'/>  
                        {/* //     :<div className="invoceTranslationImporter-input-natural-column">
                        //     <div className="invoceTranslationImporter-input-natural-title">Nombre</div> 
                        //     <div className="invoceTranslationImporter-input-natural-answer">{importer.name}</div> 
                        // </div>} */}

                        <TextField
                            title='Apellido'
                            placeholder=' '
                            classNameText="invoceTranslationImporter-input--personNatural"
                            value={importer.lastName}
                            onChange={(e:any) => handleLastNameChange(e.target.value)}
                            required
                            disabled={showEdit}
                            role='invoceTranslationImporterBusinessName'/>
                    </div>
                }
                { importer.typeDocument === 'Persona natural' &&
                    <div className='invoceTranslationImporter-input-natural'>
                        <Dropdown 
                            title='Tipo de documento'
                            value={importer.typeIt}
                            elements={typeIt}
                            setValue={(e:any)=> {handleTypeIthange(e)}}
                            required
                            disabled={showEdit}/>

                        <TextField
                            title={'Número de '+ ((importer.typeIt.length>0) ? importer.typeIt :'documento' )}
                            placeholder=' '
                            classNameText="invoceTranslationImporter-input--personNatural"
                            value={importer.itNumber}
                            onChange={(e:any) => handleItNumberChange(e.target.value)}
                            required
                            disabled={showEdit}
                            role='invoceTranslationImporterBusinessName'/>
                    </div>
                }
                 <ButtonEditChange 
                        onClickEdit={()=>setShowEdit(!showEdit)}
                        edit={!showEdit}
                        setEdit={setShowEdit}
                        add={!showEdit}
                        onClickCancel={()=>handleCancelClick()}
                        onClickComplete={()=>handleSaveClick()}
                        onClickSave={()=>handleSaveClick()}
                    />
            </CardContent>
        </div>
    )
}