import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type WorldProps = React.HTMLAttributes<HTMLDivElement> & {};

export const World: React.FC<WorldProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/svg/world.svg"} alt="" />
    </div>
  );
};