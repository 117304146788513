import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, getDoc, doc, addDoc, updateDoc } from "firebase/firestore"; 

import { BillingReviewInterface } from "../interfaces/BillingReviewInterface";

export const CreateBillingReview = async(data: BillingReviewInterface) => {
    try {
        const response:any = await addDoc(collection(DB, "billingReview"), data);  
        console.log("🚀 ~ file: BillingReview.service.ts ~ line 10 ~ CreateBillingReview ~ response", response)
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
        return error
    }
}

export const GetBillingReview = async (billingReviewId: string) => {
    // console.log("🚀 ~ file: BillingReview.service.ts:20 ~ GetBillingReview ~ billingReviewId:", billingReviewId)
    try {
        const response = await getDoc(doc(DB, 'billingReview', billingReviewId));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()})
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
    } catch (error) {
        console.log("🚀 ~ file: BillingReview.service.ts:30 ~ GetBillingReview ~ error:", error)
        return error;
    }
}

export const UpdateBillingReview = async(billingReviewId: string, billingReview: any) => {
    try {
        if(billingReviewId) {
            const requestRef = doc(DB, 'billingReview', billingReviewId);
            const response =  await updateDoc(requestRef, {...billingReview, updatedAt: new Date()});
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}