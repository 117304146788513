import React, { useRef, useState, useEffect } from "react";
import "./UploadFileArray.css";
import { CloudUp } from "../../../icons/outline/CloudUp";
import { ButtonIcon } from "../../ButtonIcon/ButtonIcon";
import { UploadText } from "../UpdateText/UpdateText";
import { AttachChat } from "../../../interfaces/MessageInterface";
import { Hover } from "../../Hover/Hover";
import { ModalNotification } from "../../Modal/ModalNotification/ModalNotification";
import { FileAttach } from "../../../interfaces/FileAttach";
import { GetAttachIcon } from "../../../utils/GetAttachIcon";
import { Close_RedSmall } from "../../../icons/solid/Close_RedSmall";

interface UploadFileArrayProps {
  title: string;
  subtitle: string;
  buttonContent: string;
  fileValue?: any;
  file: {
    name: string;
    url: string;
    size: number
  }[];
  disabled?: boolean;
  acceptType?: string;
  handleFileValue: React.ChangeEventHandler<HTMLInputElement>;
  handleDelete?: (idx: number)=>void;
  role?: string;
  multimedia?: boolean;
  isDelete?: boolean;
  loading?: boolean;
}

export const UploadFileArray: React.FC<UploadFileArrayProps> = ({
  title,
  subtitle,
  buttonContent,
  fileValue=[],
  file,
  disabled=false,
  acceptType,
  handleFileValue,
  role,
  handleDelete=()=>{},
  multimedia = false,
  isDelete = false,
  loading = false
}) => {


  return (
    <div>
      <div className="UploadFileArray box-shadow-card" role={role}>
        <div className="UploadFileArray-container">
          {title.length>0 && <h1 className="paragraph-header">{title}</h1>}
          {subtitle.length>0 && <p className="paragraph">{subtitle}</p>}
          <div className="UploadFileArray-content-bottom">
          <div className="UploadFileArray-bottom">
            <UploadText
                handleFileValue={!disabled? handleFileValue:()=>{}}
                fileName=""
                fileUrl=""
                // fileValue={fileValue}
                acceptType={acceptType}
                disabled={disabled}
                label={buttonContent}
              />
            {!loading && file.length>0 ? file.map((f, idx) => (
              <UploadText
                  key={idx}
                  handleFileValue={()=>{}}
                  fileName={f.name}
                  fileUrl={f.url}
                  fileValue={file}
                  acceptType={acceptType}
                  isDelete={isDelete}
                  handleDelete={()=> !disabled ? handleDelete(idx):{}}
                  disabled={disabled}
                />)) : 
                <div className="tinytext uploadText-nathing">No hay ningún documento todavía</div>
              }
          </div>            
            {/* <ButtonIcon
              content={buttonContent}
              Icon={<CloudUp />}
              color="blue-2"
              size="extra-small"
              place="right"
              onClick={() => {
                fileRef.current?.click();
              }}
            />
            <input
              onChange={handleFileValue}
              type="file"
              hidden
              ref={fileRef}
              // value={fileValue}
              disabled={disabled}
              accept={acceptType}
              role='upload-file-input-upload'/> */}
            {/* <span className="tinytext">
              {.length === 0 && "No hay ningún documento todavía"}
            </span> */}
          </div>
        </div>
      </div>
    
    </div>
  );
};
