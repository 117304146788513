import React, { useState } from "react";

/** Importando componente */
import { Button } from "../Button/Button";
import { CityCountryDisplay } from "../CityCountryDisplay/CityCountryDisplay";
import { CommentCount } from "../CommentCount/CommentCount";
import { Rating } from "../Rating/Rating";
import LazyLoad from 'react-lazyload';

/** Importando icon */
import { Heart_Red } from "../../icons/outline/Heart_Red";
import { LeftArrow } from "../../icons/outline/LeftArrow";
import { Like } from "../../icons/outline/Like";
import { RightArrow } from "../../icons/outline/RightArrow";
import { ArrowDown } from "../../icons/solid/ArrowDown";

// importando interfaces
import { ServiceInterface } from "../../interfaces/Service";

/** Importando estilo */
import "./ServiceDisplay.css";
import { ArrowUp } from "../../icons/outline/ArrowUp";
// import { Modal } from "../Modal/Modal/Modal";

interface ServiceDisplayProps {
  service: ServiceInterface;
  onClickQuote: () => void;
  onClickCompare: () => void;
  isSupplierSearch?: boolean;
  showButton?: boolean;
  setClick?: (click: boolean) => void;

}

export const ServiceDisplay: React.FC<ServiceDisplayProps> = ({ 
  service,
  onClickQuote,
  onClickCompare,
  isSupplierSearch = false,
  showButton= true,
  setClick,

}) => {

  const [itemActive, setItemActive] = useState<number>(0);
  const [isLike, setIsLike] = useState(false);
  const [showVideo, setShowVideo] = useState(service.video?true:false);
  // const [more, setMore] = useState(true);
  const [show, setShow] = useState(false);

  // const handleShowMore = () => {
  //   setMore(!more)
  // } 

  const directionLeftClick=() => {
    if (showVideo) {
      setShowVideo(false);
      setItemActive(service.images.length - 1);
    } else {
      if (itemActive === 0 && service.video) {
        setShowVideo(true);
      }
      setItemActive(itemActive > 0 ? itemActive - 1 : service.images.length - 1);
    }
    // if(showVideo) {
    //   setShowVideo(false);
    //   setItemActive(service.images.length-1);
    // } else {
    //   if(itemActive === 0 ){
    //     service.video ? setShowVideo(true) : setItemActive(service.images.length-1);
    //   } else {
    //     setItemActive(itemActive-1);
    //   }
    // }
  }

  const directionRightClick=() => {
    if (showVideo) {
      setShowVideo(false);
      setItemActive(0);
    } else {
      if (itemActive === service.images.length - 1 && service.video) {
        setShowVideo(true);
      }
      setItemActive(itemActive < service.images.length - 1 ? itemActive + 1 : 0);
    }
    // if(showVideo) {
    //   setShowVideo(false);
    //   setItemActive(0);
    // } else {
    //   if(itemActive === service.images.length-1 ){
    //     service.video ? setShowVideo(true) :setItemActive(0);
    //   } else {
    //     setItemActive(itemActive+1)
    //   }
    // }
  }

  let Icon = isLike ? Heart_Red : Like;

  const handleClickLike = () => {
    setIsLike(!isLike)
  } 

  const handleCLickVideo = (idx: number) => {
    setShowVideo(false);
    setItemActive(idx)
  }

  const handleShowImg = () => {
    setShow(true)
  }

 
  return (
    <section className="order-display-topp">
        <div className={`order-display box-shadow-card`} role='order-display' >
          <div className="order-display-header-content">
            <div className="order-display-subheader">
              <div className="small-subheader" style={{color: '#333333'}}>{service.serviceTypeLabel}</div>
              <div className="order-display-ratings">
                <Rating rating={Number((service.rating).toFixed(1))} />
                <CommentCount commentsCount={service.numComments} />
                <CityCountryDisplay
                  city={service.city}
                  country={service.country.alpha2Code}
                  flagImg={service.country.imgFlag}
                />
              </div>
            </div>

            <div className="order-display-top">
              <h1 className="big-header">{service.title.length<=100 ? service.title : service.title.slice(0, 97)+"..."}</h1>
            </div>
          </div>

        <div className="order-display-mid">
          <div className="order-display-main-img">
              {service.images.length > 1 && <LeftArrow onClick={directionLeftClick} style={{cursor: 'pointer'}}/>}
              
              {!showVideo 
                ? <img 
                    className="order-display-img" 
                    src={service.images[itemActive]} 
                    alt={service.title} 
                  /> 
                : <div className="order-display-video">
                    <iframe 
                      className="order-display-video"
                      src={`https://www.youtube.com/embed/${service.video || ''}?autoplay=0&controls=0&showinfo=0`}
                      title={service.companyName}
                      frameBorder="0"
                      allowFullScreen
                      allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    /> 
                  </div>
              }
              
              {service.images.length > 1 && <RightArrow onClick={directionRightClick} style={{cursor: 'pointer'}}/>}
          </div>
          
          <div className="order-display-mini-imgs"> 
            {/* TODO CHANGE KEY FOR IMG ID */}
          {
            service.video && service.video.length> 0 && 
              <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQycB_EWp5BYjJDyKFqWYyyRHO-iwVqQAOh9g&usqp=CAU' 
              alt={service.companyName} onClick={()=>setShowVideo(true)} style={{cursor:'pointer'}}/>
            } 

            {miniImages(service.images.slice(0).length === 1 ? service.images : service.images.slice(0, service.images.length)).map((miniImg: string, idx) => ( 
              <LazyLoad key={idx} height={200} className={itemActive === idx ? 'order-display-mini-imgs--active' : ''} >
                <img  src={miniImg} alt={service.companyName} onClick={()=>handleCLickVideo(idx)} style={{cursor:'pointer'}}/>
              </LazyLoad>
            ))} 
          </div>

          <div className="order-display-images-mobile">
            {service.video && service.video.length>0 && 
              <iframe 
                // width="720" 
                // height="400"
                className= "order-display-images-mobile-video" 
                src={"https://www.youtube.com/embed/"+(service.video ?service.video :'')+"?autoplay=0&controls=0&showinfo=0"}
                title={service.companyName}
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            />}
            {(service.images.length === 1 ? service.images : service.images.slice(1, service.images.length)).map((images: string, idx) => (
              <LazyLoad key={idx} height={200}>
                <img 
                  onClick={()=>handleShowImg()}
                  src={images} 
                  alt={service.companyName} 
                  style={{cursor:'pointer'}} 
                  className={(service.images.length === 1 ? service.images : service.images.slice(1, service.images.length)).length>1 ? "order-display-images-mobile-imgs": 'order-display-images-mobile-img'}
                />
              </LazyLoad>
            ))}
          </div>
        </div>
          
        {/* {!more && <div className="small-bold serviceDisplay-more-description" onClick={handleShowMore}>
          Ver descripción
          <ArrowDown/>
        </div> } */}

        

        {/* {more && */}
          <>

          {/* <div className="order-display-header-mobile">
            <div className="order-mobile-subheader">
              <div className="order-display-ratings">
                <Rating rating={service.rating} /><
                <CommentCount commentsCount={service.numComments} />
                <CityCountryDisplay
                  city={service.city}
                  country={service.country.alpha2Code}
                  flagImg={service.country.imgFlag}
                />
              </div>
              {/*<div className="order-display-social">
                <Share />
                <Like />
              </div>
            </div>
          </div> */}

          <div className="order-display-bot">
            {/* <h1 className=" small-header">Acerca de esta oferta</h1> */}

            <p className="paragraph" dangerouslySetInnerHTML={{__html: service.description}} />
          </div>
          </>
        {/* } */}

        {/* { service.keywords &&
        service.keywords?.length>0 ? <div className="order-display-keyboards">
          { service.keywords && service.keywords.map((k: string, idx) => (
            <div className="paragraph-header" key={idx}>
                {'#'+ k}
            </div>
          ))}
        </div> : null } */}

        {/* {!more ? '' : <div className="small-bold serviceDisplay-more-description" onClick={handleShowMore}>
          Ver menos
          <ArrowUp/>
        </div>} */}

      {showButton && <div className="order-display-main-btn">
          <Button 
            content={isSupplierSearch ? "Comparar planes" : "Solicitar cotización"}  
            onClick={isSupplierSearch ? ()=>onClickCompare() : ()=>onClickQuote()} role='btn-order-display' />
        </div>}

        
        {/* <Modal show={show} setShow={setShow} title="">
          {!showVideo?<img className={"order-display-img"} src={service.images[itemActive]} alt={service.title} /> : <div className="order-display-video">
            
            <iframe 
                // width="720" 
                // height="400"
                className="order-display-video"
                src={"https://www.youtube.com/embed/"+(service.video ?service.video :'')+"?autoplay=0&controls=0&showinfo=0"}
                title={service.companyName}
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            /> 

              </div>}
        </Modal> */}
      </div>
    </section>
  );
};

const miniImages = (imagesArr: string[]) => {
  return imagesArr.slice(0, imagesArr.length);
};
