import React from 'react';
 
/* import components */
import { SearchTextField } from '../SearchTextField/SearchTextField';
import { UserActive } from '..';
import { DropdownTurn } from '../Dropdown/Turn/DropdownTurn';


/** import style */
import './MessageTopbar.css';
import { MemberChat } from '../../interfaces/ChatInterface';
import { CircleWhite } from '../../icons/outline/CircleWhite';
import { MoreUser } from '../../icons/outline/MoreUser';
import { MembersChat } from '../MemberChat/MemberChat';


/** variables de interface */
interface MessageTopbarProps {
    searchText: string; // texto de busqueda
    team: MemberChat[]; // array de equipo de trabajo
    typeChat?: string; // tipo o nivel del chat
    disableDrop?: boolean;
    setSearchText: React.Dispatch<React.SetStateAction<string>>; // metodo setter del texto a buscar
    onClickType?: ()=> void; // metodo que se ejecuta al hacer click en type chat
    handleMultimedia?: ()=> void;
    children?: React.ReactNode;
    canAddMembers?: boolean; // if user can add members
    handleAddMemberClick?: ()=> void; // metodo to show modal member;
    isGroup?: boolean | undefined;
    isCompany?: boolean | undefined;
}

/**
 * componente MessageTopBar
 *  
 */
export const MessageTopbar: React.FC<MessageTopbarProps> = ({
    searchText,
    team=[],
    typeChat='',
    disableDrop=false,
    setSearchText,
    onClickType  = ()=>{},
    handleMultimedia = ()=>{},
    canAddMembers = false,
    handleAddMemberClick = () => [],
    children,
    isGroup= false,
    isCompany = false,
}) => {


    return (
        <div className='messageTopBar-main' role='message-topbar'>
            <div className='messageTopBar-users'>
                <SearchTextField 
                    darkIcon
                    title={searchText}
                    setTextSearch={setSearchText}
                    isMobile
                />

                {children}
                {isGroup ?
                    <MembersChat 
                        members={team} 
                        canAddMembers={canAddMembers}
                        handleAddMember={handleAddMemberClick}
                        isCompany={isCompany}
                    /> : null}
                {/* {typeChat.length>0 && <div className='messageTopBar-user--typeChat smalltext-header ' onClick={()=>onClickType()} role='btn-message-tobar'>
                    {typeChat} 
                </div>} */}
            </div>
            {/* <div className='messageTopBar-user-active'>
                <DropdownTurn
                    content={team}
                    setUSerActive={()=>{}}
                    userActive={1}
                    title="Elige un turno"
                    disabled={disableDrop}
                />
            </div> */}
        </div>
    )
}