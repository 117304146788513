import React, { useCallback, useEffect, useState } from "react";

// importando interfaces
import { ErrorInterface } from "../../interfaces/ErrorInterface";

// importando estilos
import "./TextField.css";

type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  title: string;
  value?: string | number | undefined;
  disable?: boolean;
  completed?: boolean;
  required?: boolean;
  classNameText?: string;
  refInput?: React.Ref<HTMLInputElement>;
  error?: ErrorInterface
  setError?: React.Dispatch<React.SetStateAction<ErrorInterface>>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const TextField: React.FC<TextFieldProps> = ({
  title,
  value='',
  disable = false,
  completed,
  placeholder,
  error={status: false, message: ''},
  required = false,
  classNameText='',
  refInput=null,
  setError=()=>{},
  onChange=()=>{},
  ...props
}) => {

  const [localValidation, setLocalValidation] = useState(false);

  let disabledClass = "";
  if (disable) {
    disabledClass = "textfield-disabled";
  }

  if (completed) {
    disable = true;
  }

  const handleChangeValue = useCallback((e:  React.ChangeEvent<HTMLInputElement>) => {
    setError({
      status: false,
      message: ''
    });
    onChange(e);
  }, [value]);


  const capitaLetter = (str:any) => {
    if(str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }


  return (
    <div role='textFieldRole' className={`textfield ${classNameText} ${props.type === 'number'?'textfield-number': ''}`}>
      <input
        className={`textfield-input ${disabledClass} ${error.status ? 'textfield-error--outline' : ''}`}
        disabled={disable}
        autoCapitalize = 'words'
        placeholder={`${disable ? " " : ((props.type === 'number' &&  placeholder === '0')? '0.00' : placeholder)}`}
        value={(props.type === 'number' && value === 0)? '0.00' : props.type === 'text' ? capitaLetter(value) : value}
        onChange={(e)=> handleChangeValue(e)}
        style={{paddingTop: title.length>0 ? '22.5px':' 8.5px', maxHeight: '52.5px'}}
        ref={refInput}
        {...props}
      />
      {title && title.length>0 && <span className={`paragraph textfield-title ${error.status ? 'textfield-title-error' : ''}` }>{title}</span>}
      {(error.status) && <div className="textfield-error tinytext-header">{error.message}</div>}
    </div>
  );
};