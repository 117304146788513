import React, { useState } from 'react';

import './ElevenSeccion.css';
import { AUTH_LOGIN, AUTH_MODAL, AUTH_REGISTER, FREQUENTLY_QUESTIONS, STORAGE_ASSETS } from "../../../Consts/BaseUrl";

import { PreregisterEmailInterface } from "../../../interfaces/Preregisterinterface";
import { PreregisterEmail } from "../../../Services/PreregisterEmail";
import { EmailSection } from '../../EmailSection/EmailSection';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { GetIpHost } from "../../../Services/GetIp.service";
import { GroupWhatsapp } from '../../../icons/solid/Group_whatsapp';
import { ModalTyC } from '../../Modal/ModalTyC/ModalTyC';
import { ModalPrivacyPolicy } from '../../Modal/ModalPrivacyPolicy/ModalPrivacyPolicy';

interface ElevenSeccionProps {
}

export const ElevenSeccion: React.FC<ElevenSeccionProps> = ({

}) => {

    const navigate = useNavigate();

    const [showModalTyC, setShowModalTyC] = useState<boolean>(false);
    const [showModalP, setShowModalP] = useState<boolean>(false);

    const modalTyC = async () => {
      setShowModalP(false);
      setShowModalTyC(true)
    } 

    const handleQuestion = () => {
      navigate("/"+FREQUENTLY_QUESTIONS);
      window.scrollTo(0, 0);
    }

    const handleClickModal = async (email: string) => {
        if(email.length === 0) {
          navigate(`?${AUTH_MODAL}=${AUTH_REGISTER}`);
        } else {
          try {
            const preRegister: PreregisterEmailInterface = {
              email: email,
              ip: await GetIpHost(), 
              status: "propspect",
              createdAt: new Date(),
              updatedAt: new Date()
            }
            const response = await PreregisterEmail(preRegister);
            if(response) {
              navigate(`?${AUTH_MODAL}=${AUTH_REGISTER}&email=`+email) 
            } else {
              console.log('error al resgistrar email');
            }
          } catch (error) {
            console.log("🚀 ~ handleClickModal ~ error:", error);
            toast.error("Hubo un error al validar el correo electrónico");
          }
        }
    }
  
    const redirectToLogin = (email: string) => {
      navigate(`?${AUTH_MODAL}=${AUTH_LOGIN}&email=`+email)
    }

    const handleCalendar = () => {
      window.open('https://calendar.app.google/RK5yB4ECtQGboMQ99', '_blank');
    }

  return (
    <div className="eleven-section">
        <div className='eleven-section-row'>
            <div className='big-header eleven-section-text eleven-section-title'>Cotiza, importa y escala en pocos clicks</div>
            {/* <div className='eleven-section-email'>
              <div className='paragraph eleven-section-text'>¡Empieza a importar sin complicaciones!</div>
              <EmailSection 
                  onClick={handleClickModal}
                  redirect={redirectToLogin}
              />
            </div> */}
            <div className='eleven-section-email2'>
              <div className='paragraph eleven-section-text'>¡Empieza a importar sin complicaciones!</div>
              <div className='smalltext eleven-section-button' onClick={()=>handleCalendar()}>
                Agendar <b className='eleven-section-bold'>CITA</b>
              </div>
            </div>
        </div>
        <div className='eleven-section-row_ eleven-section-second'>
            {/* <div className='eleven-section-row_1'>
                <div className='small-bold eleven-section-text eleven-section-row_1_title'>Para importadores</div>
                <div className='paragraph eleven-section-text eleven-section-row_1_item'>Cotizaciones múltiples</div>
                <div className='paragraph eleven-section-text eleven-section-row_1_item'>Servicio Courier</div>
                <div className='paragraph eleven-section-text eleven-section-row_1_item'>Agentes en China</div>
                <div className='paragraph eleven-section-text eleven-section-row_1_item'>Panel principal</div>
            </div>
            <div className='eleven-section-row_2'>
                <div className='small-bold eleven-section-text eleven-section-row_1_title'>Para Dropshippers</div>
                <div className='paragraph eleven-section-text eleven-section-row_1_item'>Importaciones grupales</div>
                <div className='paragraph eleven-section-text eleven-section-row_1_item'>Tracking</div>
            </div> */}
            <div className='eleven-section-row_3'>
                <div className='small-bold eleven-section-text eleven-section-row_1_title'>Empresa</div>
                <div onClick={()=>setShowModalTyC(true)} className='paragraph eleven-section-text eleven-section-row_1_item  eleven-section_row_social_hrf'>Términos y condiciones</div>
                <div onClick={()=>setShowModalP(true)} className='paragraph eleven-section-text eleven-section-row_1_item  eleven-section_row_social_hrf'>Políticas de privacidad</div>
                <div onClick={()=>handleQuestion()} className='paragraph eleven-section-text eleven-section-row_1_item eleven-section_row_social_hrf'>
                  Preguntas frecuentes
                </div>
            </div>
            <div className='eleven-section-row_4'>
                <img src={STORAGE_ASSETS+'/images/png/Presentación-Arkabia.png'} alt="Los usuarios encuentran varias agencias de aduana"/>
            </div>
        </div>

        <div className='eleven-section-row'>
            <div className='eleven-section_column eleven-section_left'>
                <div className='eleven-section-row_new'>
                  <img className='eleven-section-logo' src={STORAGE_ASSETS+'/images/png/logo_new_icon.png'} alt="Arkabia"/>
                  <div className='tinytext eleven-section-text eleven-section-row_new_copy'>Copyright © Arkabia v1.1.1</div>
                </div>
                <div className='tinytext eleven-section-text'>Protegido por RS RNº1692 - 2021/DDA - INDECOPI</div>
            </div>
            <div className='eleven-section_column eleven-section_right'>
                {/* <GroupWhatsapp/> */}
                <div className='eleven-section_row_'>
                    <div className='smalltext eleven-section-text'>Únete a la comunidad</div>
                    <div className='eleven-section_row_social'>
                        <a className='eleven-section_row_social_hrf' href="https://www.instagram.com/arkabia_oficial/?igshid=YmMyMTA2M2Y%3D">
                          <img className='eleven-section_row_social-img' 
                            src={STORAGE_ASSETS+'/images/png/instagramIcon.png'} alt=''/>
                        </a>
                        <a className='eleven-section_row_social_hrf' href="https://www.tiktok.com/@arkabia_oficial">
                          <img className='eleven-section_row_social-img' 
                            src={STORAGE_ASSETS+'/images/png/tiktokIcon.png'} alt=''/>
                        </a>
                        <a className='eleven-section_row_social_hrf' href="https://www.linkedin.com/company/arkabia/">
                          <img className='eleven-section_row_social-img' 
                            src={STORAGE_ASSETS+'/images/png/linkendIcon.png'} alt=''/>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <ModalTyC setShowModal={setShowModalTyC} showModal={showModalTyC}/>
        <ModalPrivacyPolicy setShowModal={setShowModalP} showModal={showModalP} modalTyC={modalTyC}/>
    </div>
  );
};