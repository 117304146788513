import { SituationOptionsInterface } from "../interfaces/SituationOptionsInterface";
import { STORAGE_ASSETS } from "./BaseUrl";

export const SituationOptions : SituationOptionsInterface[] = [{
    title: "Deseo cotizar mi importación",
    urlImg: STORAGE_ASSETS+"/images/png/situationOptions5.png",
    navigation: "agenciamiento-de-aduana"
},{
    title: "Busco proveedor para mi negocio",
    urlImg: STORAGE_ASSETS+"/images/png/situationOptions3.png",
    navigation: "busqueda-de-proveedor"
}
// ,{
//     title: "Busco agencia de aduana",
//     urlImg: STORAGE_ASSETS+"/images/png/situationOptions2.png",
//     navigation: "agenciamiento-de-aduana"
// }
,{
    title: "Quiero traer algo via courier",
    urlImg: STORAGE_ASSETS+"/images/png/situationOptions1.png",
    navigation: "courier"
},{
    title: "Es mi primera importación",
    urlImg: STORAGE_ASSETS+"/images/png/situationOptions4.png",
    navigation: "whatsapp"
}]