import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";
import { UserPlanResoucesInterface } from "../interfaces/UserPlanResoucesInterface";

// method to get User plan resources from firestore
export const GetUserPlanResources = async (userId: string): Promise<UserPlanResoucesInterface | null> => {
    try {
        const q = query(collection(DB, "userPlanResources"), where("userId", "==", userId));
        const response = await getDocs(q);
        if (!response.empty) {
            const doc = response.docs[0];
            return { id: doc.id, ...doc.data() as UserPlanResoucesInterface} as UserPlanResoucesInterface;
        } else {
            return null;
        }
    } catch (error) {
        console.log("🚀 ~ getUserPlanResources ~ error:", error);
        throw error;
    }
};

// method to create User plan resources from firestore
export const CreateUserPlanResources = async (userPlanResources: UserPlanResoucesInterface): Promise<UserPlanResoucesInterface> => {
    try {
        const response = await addDoc(collection(DB, "userPlanResources"), userPlanResources);
        return { id: response.id, ...userPlanResources } as UserPlanResoucesInterface;
    } catch (error) {
        console.log("🚀 ~ createUserPlanResources ~ error:", error);
        throw error;
    }
};

// method to update field in userPlanResources
export const UpdateUserPlanResourcesFromUser = async (userId: string, field: "storage" | "numRequest", value: number) => {
    try {
        const q = query(collection(DB, "userPlanResources"), where("userId", "==", userId));
        const response: any = await getDocs(q);
        if (!response.empty) {
            const docRef = doc(DB, "userPlanResources", response.docs[0].id);
            const currentFieldValue = response.docs[0].data()[field];
            if (currentFieldValue !== undefined) {
                const rspUpdate = await updateDoc(docRef, {
                    [field]: currentFieldValue + value
                });
                return rspUpdate;
            } else {
                throw new Error(`Field ${field} does not exist on the document.`);
            }
        } 
        return null;
    } catch (error) {
        console.log("🚀 ~ UpdateUserPlanResourcesFromUser ~ error:", error);
        throw error;        
    }
};

// method to validate if user have resource with storage or numRequest
export const ValidateUserPlanResources = async (userId: string, field: "storage" | "numRequests", value: number): Promise<boolean> => {
    try {
        const q = query(collection(DB, "userPlanResources"), where("userId", "==", userId));
        const response: any = await getDocs(q);
        if (!response.empty) {
            const doc = response.docs[0];
            const userPlanResources = { id: doc.id, ...doc.data() as UserPlanResoucesInterface};
            const currentFieldValue = userPlanResources[field];
            const maxFieldValue = field === "storage" ? userPlanResources.limitStorage : userPlanResources.limitRequests;
            if (currentFieldValue !== undefined) {
                if ((currentFieldValue+value) <= maxFieldValue) {
                    return true;
                } else {
                    return false;
                }
            } else {
                throw new Error(`Field ${field} does not exist on the document.`);
            }
        } 
        return false;
    } catch (error) {
        console.log("🚀 ~ ValidateUserPlanResources ~ error:", error);
        throw error;        
    }
};