import React, { useEffect, useRef, useState } from "react";
import { CountryInterface } from "../../interfaces/Country";
import { Dropdown } from "../Dropdown/Dropdown";
import { ItemVessels } from "../SearchListVessels/ItemVessels/ItemVessels";
import { SearchTextField } from "../SearchTextField/SearchTextField";
import { TextField } from "../TextField/TextField";

import './PhoneCountry.css';
import { ErrorInterface } from "../../interfaces/ErrorInterface";


interface PhoneCountryProps {
    countryList: {country: string; alpha2Code: string, phoneCode: string }[];
    phoneFlag: string;
    handlePhoneCodeChange: (phoneCountry: string) => void;
    handleCountryChange: (country: string, alpha2Code: string) => void;
    phoneCode: string;
    phone: string;
    handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    title: string;
    disable?: boolean;
    error?: {
        phoneCode: ErrorInterface;
        phone: ErrorInterface;
    
    }
}

export const PhoneCountry: React.FC<PhoneCountryProps> = ({
    countryList,
    phoneFlag,
    handleCountryChange,
    phoneCode,
    handlePhoneChange,
    handlePhoneCodeChange,
    title,
    disable = false,
    phone,
    error= {
        phone: {
            status: false,
            message: ''
        },
        phoneCode: {
            status: false,
            message: ''
        }
    }
}) => {

    const [open, setOpen] = useState(false);

    const [searchCountry, setSearchCountry] = useState<{country: string; alpha2Code: string, phoneCode: string }[]>([]);
    const [countrySelected, setCountrySelected] = useState<string>('');
    const [countrySelectedItem, setCountrySelectedItem] = useState<{phoneCode: string, phoneFlag: string}>();

    const inputRef = useRef<HTMLInputElement>(null)

    const handleSearchCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        // handlePhoneCodeChange(value);
        setCountrySelected(value)
        if(value.length>0) {
            initSearchCountry(value);
        } else {
            setSearchCountry([]);
        }
    }

    let disabledClass = "";

    if (disable) {
        disabledClass = "phoneCountry-item-column-dropdown-disabled";
    }

    // const getAlpha2Code = (country: any) => {
    //     let alpha2Code =  countryList.find((c) => c.country === country)?.alpha2Code;
    //     return alpha2Code ? alpha2Code : '';
    // }

    // const handleChangeCountry = (e: string) => {
    //     let value_split = e.split(" ");
    //     setCountrySelected(e);
    //     handlePhoneCodeChange(value_split[1]);
    //     handleCountryChange(value_split[0], getAlpha2Code(value_split[0]));
    // }

    const handleOpenDropdown = () => {
        setOpen(!open);
    }

    

    const handleClickItem = (item: {country: string; alpha2Code: string, phoneCode: string }) => {
        setCountrySelected('');
        handleCountryChange(item.country, item.alpha2Code); 
        handlePhoneCodeChange(item.phoneCode); 
        setSearchCountry([]);
        setCountrySelectedItem({
            phoneCode: item.phoneCode,
            phoneFlag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${item.alpha2Code.toLowerCase()}.svg`,
         
        });
        setOpen(false);
        initSearchCountry(item.country.slice(0,2));
    }

    const initSearchCountry = (search: string) => {
        setSearchCountry(countryList.filter((c) => c.country.toLocaleUpperCase().includes(search.toLocaleUpperCase())));
    }

    useEffect(() => {
        initSearchCountry('a');
    }, [countryList]);

    useEffect(() => {
        if(phoneCode && phoneFlag && phoneFlag.length>0) {
            setCountrySelectedItem({
                phoneCode: phoneCode,
                phoneFlag: phoneFlag
            })
        }
    }, [phoneCode, phoneFlag])

    useEffect(() => {
        if(open) {
            inputRef.current?.focus();
        }
    }, [open]);
    
    return (
        <div className="phoneCountry-container">
            <div className="phoneCountry-item">

                <div className="phoneCountry-item-column-dropdown">
                    
                    <div className={`phoneCountry-item-content ${disabledClass} ${error.phoneCode.status ? " phoneCountry-item-error" : ""} `} onClick={disable ? ()=>{} : handleOpenDropdown}>
                    {countrySelectedItem ? 
                        <div className="phoneCountry-item-content-column">
                            <div className="paragraph phoneCountry-item-content-text-selected">{title}</div>
                            <div className="phoneCountry-item-content-row">
                                <img
                                    alt="arkabia-pais" width="25" height="25"
                                    src={countrySelectedItem.phoneFlag}
                                />
                                <div className="paragraph-header">{countrySelectedItem?.phoneCode} </div>
                            
                            </div>
                          
                        </div>
                        : <div className="paragraph phoneCountry-item-content-text">{title}</div>}
                    </div> 
                    {error.phoneCode.status && <div className="tinytext-header text-error ">{error.phoneCode.message}</div>}

                    {open ? 
                    <>
                        <div className="phoneCountry-item--country ">
                                <TextField 
                                    title="" 
                                    placeholder="Selecciona tu país" 
                                    value={countrySelected}
                                    onChange={handleSearchCountryChange}
                                    refInput={inputRef}
                                />
                                
                                {/* { countrySelected.length>0 && */}
                                    <div className="phoneCountry-list">
                                    {
                                    // searchCountry.length>0 && 
                                    searchCountry.map((s, idx)=> (
                                        <ItemVessels
                                            key={idx}
                                            alpha2Code={s.alpha2Code.toLocaleLowerCase()}
                                            name={s.country+" "+( s.country === "Estados Unidos" ? "+1" : s.phoneCode)}
                                            description={''}
                                            handleclick={()=>handleClickItem({
                                                country: s.country,
                                                alpha2Code: s.alpha2Code,
                                                phoneCode: s.country === "Estados Unidos" ? "+1" : s.phoneCode
                                            
                                            })}
                                        />
                                    ))}
                                </div> 
                                {/* } */}
                        </div>
                    </> : ''}
                
                </div>

                <TextField 
                    title='Teléfono'
                    placeholder=" "
                    value={phone}
                    type='number'
                    onChange={(e:any) => {handlePhoneChange(e)}}
                    role='signUp-user' 
                    disabled={disable}
                    error={error.phone}
                />
            </div>
            
            
        </div>
    )
}