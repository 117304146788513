import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type LocationCircleProps = React.HTMLAttributes<HTMLDivElement> & {};

export const LocationCircle: React.FC<LocationCircleProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/location-transport.svg'} alt='location' />
        </div>
    )
} 