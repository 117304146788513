import React, { useEffect, useRef, useState } from "react";

import { Modal } from '../../Modal/Modal/Modal';

// importando interfaces
import { RequestQuoteInterface } from "../../../interfaces/RequestQuote";

// importando iconos
import { ArkabiaOp } from "../../../icons/outline/ArkabiaOp";

/** importando css */
import './ModalQuote.css';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CloseBig } from "../../../icons/solid/CloseBig";
import { Quoter } from "../../Quoter/Quoter";
import { Loader } from "../../Loader/Loader";
import { ServiceInterface, ServiceTypeInterface } from "../../../interfaces/Service";
import { ModalConfirm } from "../ModalConfirm/ModalConfirm";
import { CHAT, OPERATIONS, REQUEST_QUOTE } from "../../../Consts/BaseUrl";
import { SelectServiceType } from "../../SelectServiceType/SelectServiceType";
import { AppDispatch } from "../../../Store/Store";

/** declarando interface */
interface ModalQuoteProps {
    show?: boolean; // variable to show modal
    setShow: React.Dispatch<React.SetStateAction<boolean>>; // setter to show
    requestQuote: RequestQuoteInterface;    // variable to requestQuote
    setRequestQuote: React.Dispatch<React.SetStateAction<RequestQuoteInterface>>; // setter to requestQuote
    fromPage: "home" |"chat" | "mainService" | "serviceDetail";
    serviceDetail?: ServiceInterface;
    isUpdateRequestQuote?: boolean;
    updateData?: () => void;
    hiddenConfirm?: boolean; // show modal confirmation dialog
}

/** componente modal cotizacion
 * renderiza el modal  
 */
export const ModalQuote: React.FC<ModalQuoteProps> = ({
    show=true,
    setShow,
    requestQuote,
    setRequestQuote,
    serviceDetail,
    isUpdateRequestQuote,
    updateData = ()=>{},
    hiddenConfirm=false,
    fromPage,
    ...props
}) => {

    const refModalQuote = useRef<HTMLDivElement>(null);

    const navigation = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const dispatch = useDispatch<AppDispatch>();
  

    // useState
    const [loading, setLoading] = useState<boolean>(false);

    const [title, setTitle] = useState<string>('¡Empezamos!');
    const [subTitle, setSubTitle] = useState<string>('Solicitud de cotización - Agenciamiento de aduana');


    const [showSubTitle, setShowSubTitle] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('Escribe un nombre para tu importación.');
    const [showDescription, setShowDescription] = useState<boolean>(true);

    const [temOperationId, setTemOperationId] = useState<string>('');

    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);

    const [serviceSelected, setServiceSelected] = useState<ServiceInterface>();

    
    // metodo para cerrar modal
    const handleCloseClick = () => {
        setShow(false);
        !isUpdateRequestQuote && setRequestQuote({
            title: "",
            number: 0,
            user: {
              id: "",
              name: "",
              typeIt: "",
              itNumber: 0,
              numOperations: 0,
              phone: "",
              rating: 0,
            },
            companyName: "",
            serviceId: "",
            serviceTypeId: requestQuote.serviceTypeId,
            serviceTypeLabel: requestQuote.serviceTypeLabel,
            status: "draft",
            statusProvider: "No leída",
            active: true,
            customs: {
              regime: "Importación definitiva",
              incoterm: "FOB",
              value: undefined,
              coin: "",
              insurance: undefined,
              merchandisePermits: {
                dataSheet: [],
                proforma: [],
                packingList: [],
              },
              exwDirection: "",
              descriptionOperation: "",
            },
            transport: {
              type: undefined,
              origin: {
                city: "",
                country: "",
              },
              destination: {
                city: "",
                country: "",
              },
              typeContainer: {
                lcl: false,
                fcl: false,
                lclAir: false,
              },
            },
            phaseDraft: 0,
            operationId: "",
            numServiceComments: 0,
            numServiceRating: 0,
            observation: "",
            category: "Importación",
            createdAt: new Date(),
            updatedAt: new Date(),
        });
    }

    const navigateToSendRequest = async (operationId: string) => {
        setLoading(true);
            navigation(`/${OPERATIONS}/${REQUEST_QUOTE}/${operationId}?sendOtherQuote=provider`, {replace: true});
        setLoading(false);
    }

    // method to change service type
    const handleSelectServiceType = (serviceType: string, serviceTypeLabel: string) => {
      setRequestQuote((prev) => ({
        ...prev,
        serviceTypeId: serviceType,
        serviceTypeLabel: serviceTypeLabel,
      }));

      setSubTitle("Solicitud de cotización - "+serviceTypeLabel);

    }

    const handleSelectService = (service: ServiceInterface) => { 
      setRequestQuote((prev) => ({
        ...prev,
        serviceId: service.id || "",
        numServiceRating: service.rating,
        numServiceComments: service.numComments
      }));
    }

    useEffect(() => {
      if(serviceDetail) {
        setServiceSelected(serviceDetail);
      }
      setShowSubTitle(requestQuote.phaseDraft && requestQuote.phaseDraft>0 || false);
      setShowDescription(requestQuote.phaseDraft && requestQuote.phaseDraft>0 ? false : true);
     }, [])

    useEffect(() => {
      setSubTitle("Solicitud de cotización - "+requestQuote.serviceTypeLabel);
    }, [requestQuote]);

    useEffect(()=> {
        setTitle(requestQuote.id ? requestQuote.title: '¡Empezamos!');
        show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    }, [show]);
    
  return (
        <>  
            {show && <div className="modalQuote" >
                {!loading ? 
                <div ref={refModalQuote} className={`modalQuote-main modalQuote-full`}>
                    <div className="modalQuote-header">
                        <div className="modalQuote-header-title">
                            {!showDescription && <ArkabiaOp/>}
                            <div className="modalQuote-header--text">
                                <div className="modalQuote-header--color small-header">{title}</div>
                                {showSubTitle && <div className="modalQuote-header--subtitle paragraph">{subTitle}</div>}
                                {!showSubTitle &&  fromPage !== "serviceDetail" && <div className="modalQuote-header-select">
                                  <div className="modalQuote-header--subtitle paragraph">Selecciona un servicio</div>
                                    <SelectServiceType 
                                      companyId={requestQuote.companyId || ""}
                                      serviceTypeId={requestQuote.serviceTypeId || ""}
                                      setServiceSelected={()=> {}} 
                                      handleServiceTypeChange={()=> {}} 

                                      fromPage={fromPage}
                                    />
                                </div>}
                                {showDescription && <div className="modalQuote-header--color modalQuote-header--description paragraph">{description}</div>}
                                
                            </div>
                            
                        </div>
                        <div className="modalQuote-header--close"><CloseBig  onClick={()=>handleCloseClick()} /></div>
                    </div>
                    <div className="modalFull-body">
                        {/* seccion donde se muestran las preguntas */}
                        <Quoter 
                            hiddenConfirm={hiddenConfirm}
                            showModal={show}
                            setShowModal={setShow}
                            requestQuote={requestQuote}
                            setRequestQuote={setRequestQuote}
                            setTitle={setTitle}
                            setShowSubtitle={setShowSubTitle}
                            setShowDescription={setShowDescription}
                            setSection={()=>{}}
                            isUpdateRequestQuote={isUpdateRequestQuote}
                            fromPage={fromPage}
                            setShowModalConfirm={setShowModalConfirm}
                            setTemOperationId={setTemOperationId}
                            updateData={updateData}
                            serviceSelected={serviceSelected || serviceDetail}
                            setServiceSelected={setServiceSelected}
  
                        /> 
                    </div>
                </div> : <Loader /> }
            </div>}
            {(!hiddenConfirm && showModalConfirm) && <ModalConfirm
                show={showModalConfirm}
                setShow={setShowModalConfirm}
                title="¡Tu solicitud fue enviada!"
                subTitle="¿Deseas cotizar con más agencias?"
                handleYes={()=>navigateToSendRequest(temOperationId)}
                handleNo={()=>setShowModalConfirm(false)} 
            />}
        </>
        
    )
}
