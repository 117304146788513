import React, { useEffect, useState } from "react";

// importando interfaces
import { ErrorInterface } from "../../../interfaces/ErrorInterface";

// importando iconos
import { Eye } from "../../../icons/outline/Eye";
import { EyeOff } from "../../../icons/outline/EyeOff";

// importando estilos
import "./TextFieldPassword.css";
import { EyeBlack } from "../../../icons/outline/EyeBlack";
import { EyeOffBlack } from "../../../icons/outline/EyeOffBlack";

type TextFieldPasswordProps = React.InputHTMLAttributes<HTMLInputElement> & {
  title: string;
  value?: string | number | undefined;
  disable?: boolean;
  completed?: boolean;
  required?: boolean;
  classNameText?: string;
  refArea?: React.Ref<HTMLTextAreaElement>;
  error?: ErrorInterface
  setError?: React.Dispatch<React.SetStateAction<ErrorInterface>>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const TextFieldPassword: React.FC<TextFieldPasswordProps> = ({
  title,
  value='',
  disable = false,
  completed,
  placeholder,
  error={status: false, message: ''},
  required = false,
  classNameText='',
  refArea,
  setError=()=>{},
  onChange=()=>{},
  ...props
}) => {

  const [localValidation, setLocalValidation] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);

  let disabledClass = "";
  if (disable) {
    disabledClass = "textfield-disabled";
  }

  if (completed) {
    disable = true;
  }

  const handleChangeValue = (e:  React.ChangeEvent<HTMLInputElement>) => {
    setError({
      status: false,
      message: ''
    });
    onChange(e);
  }

  // const first_letter_uppercase_word = (str:any) => {
  //   console.log(str[0].toLowerCase().replace(/\b[a-z]/g, (c:any) => c.toUpperCase()));
  //   return str.toLowerCase().replace(/\b[a-z]/g, (c:any) => c.toUpperCase())
  // }
  
  // first_letter_uppercase_word("HELLO worlD")
  // console.log(first_letter_uppercase_word)

  function capitaLetter(str:any) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /** Show password */
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };


  return (
    <div className="textfieldPassword-container">
        <div role='textFieldRole' className={`textfieldPassword ${classNameText} ${props.type === 'number'?'textfieldPassword-number': ''}`}>
          <input
            className={`textfieldPassword-input ${disabledClass} `}
            disabled={disable}
            autoCapitalize = 'words'
            placeholder={`${disable ? " " : ((props.type === 'number' &&  placeholder === '0')? '0.00' : placeholder)}`}
            value={(props.type === 'number' && value === 0)? '0.00' : props.type === 'text' ? capitaLetter(value) : value}
            onChange={handleChangeValue}
            {...props}
            type={showPassword ? "text" : "password"}
          />
          <div className="textfieldPassword-icon" onClick={togglePassword}>{ showPassword ? <EyeOffBlack/> : <EyeBlack/> }</div>
          <span className="textfieldPassword-title paragraph">{title}</span>
        </div>
        {(error.status) && <div className="textfieldPassword-error tinytext-header">{error.message}</div>}
    </div>
  );
};