import React, { useEffect, useState } from "react";

/** importando componentes */
import { ContentQuote } from "../ContentQuote/ContentQuote";

/** importando iconos */

/** importando interfaces */

/** importando estilos */
import "./QuoteProvider.css";
import { UserCompany } from "../../interfaces/UserCompany";
import { Loader } from "../Loader/Loader";
import { GetUserCompanyByUserId } from "../../Services/UserCompany.service";
import { VerifyBlack } from "../../icons/solid/VerifyBlack";
import { UserVerify } from "../UserVerify/UserVerify";
import { GetNumberOfOperationsByUser } from "../../Services/Operation.service";
import { CopyIcon } from "../../icons/outline/CopyIcon";
import { toast } from "react-toastify";

interface QuoteProviderProps {
  num?: number;
  role?: string;
  userId: string;
  userName: string;
  userLastName?: string;
  phone?: number;
  email?: string;
  statusProvider?: string;
  verified?: boolean;
  handleReviewUser: () => void;
  isVerified?: boolean;
  isFirstImport?: boolean;
  showVerify?: boolean;
  setShowVerify?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QuoteProvider: React.FC<QuoteProviderProps> = ({
  num=0,
  role,
  userId,
  userName,
  userLastName = '',
  statusProvider = "No leída",
  phone = 0,
  email = '',
  verified = false,
  handleReviewUser,
  isVerified,
  isFirstImport = false,
  showVerify = false,
  setShowVerify = () => {}
}) => {
  
  const [loading, setLoading] = useState<boolean>(false);
  const [userOperations, setUserOperations] = useState<number>(0);

  const [userCompany, setUserCompay] = useState<UserCompany>({
    name: "",
    itNumber: "",
    position: "",
    status: true,
    typeIt: "",
    userId: "",
    verified: false,
    createdAt: new Date(),
    updatedAt: new Date()
  });

  const handleVerify = () => {
    setShowVerify(true);
  }
  
  const getUserCompanyByUserId = async (userId: string) => {
    if(!loading) {
      try {
        setLoading(true);
        const response = await GetUserCompanyByUserId(userId);  
        if(response) {
          setUserCompay(response);
        }
        setLoading(false);
      } catch (error) {
        console.log("🚀 ~ file: QuoteProvider.tsx:52 ~ getUserCompanyByUserId ~ error:", error)
        setLoading(false);
      }
    }
  } 

  const getUserOperation = async (userId: string) => {
    if(!loading) {
      try {
        setLoading(true);
        const response = await GetNumberOfOperationsByUser(userId);  
        if(response) {
          setUserOperations(response);
        }
        setLoading(false);
      } catch (error) {
        console.log("🚀 ~ file: QuoteProvider.tsx:52 ~ getUserCompanyByUserId ~ error:", error)
        setLoading(false);
      }
    }
  }

  const handleCopyEmailClick = () => {
    window.navigator.clipboard.writeText(email);
    toast.success('Correo copiado al portapapeles');
  }

  // use effect
  useEffect(() => {
    getUserCompanyByUserId(userId);
    getUserOperation(userId);
  }, []); 

  return (
    <div className="quote-provider" role={role}>
      {!loading ? <>
        <div className="quote-provider-header">
          <div className="small-bold quote-provider-colorText">Cotización</div>
          <ContentQuote
            requestId=""
            handleClickStatus={() => {}}
            statusLabel={statusProvider}
            number={num ? num : 0}
            // disabled
          />
        </div>

        <div className="quote-provider-body">
          {/* <div className="quote-provider-body-content">
                      <div className="smalltext quote-provider-body-text quote-provider-colorText">Título</div>
                      <div className="paragraph-header quote-provider-body-answer quote-provider-colorText">{title}</div>
                  </div>
                  <div className="quote-provider-body-content">
                      <div className="smalltext quote-provider-body-text quote-provider-colorText">Categoría</div>
                      <div className="paragraph-header quote-provider-body-answer quote-provider-colorText">{category}</div>
                  </div> */}
          <div className="quote__row___ row___1">
            <div className="quote-provider-body-content_row">
              <div className="smalltext-header quote-provider-body-text quote-provider-colorText">
                Cliente
              </div>
              <div className="smalltext quote-provider-body-answer quote-provider-colorText">
                {userName 
                //&& userLastName   && userLastName.length>0 && userLastName.length>0 
                ? 
                <div className="quote-provider-body-header-check">
                  {userName}
                  {isVerified && <VerifyBlack onClick={()=>handleVerify()}/> }
                </div> : "-"} 
              {/* {userName && userName.length > 0 ? userName : "-"} */}
              </div>
            </div>
            
            <div className="quote-provider-body-content_row">
              <div className="smalltext-header quote-provider-body-text quote-provider-colorText">
                Puesto
              </div>
              <div className="smalltext quote-provider-body-answer quote-provider-colorText">
                { userCompany.position ? userCompany.position : '-'}
              </div>
            </div>
          </div>
          
          <div className="quote__row___ row___1">
            <div className="quote-provider-body-content_row">
              <div className="smalltext-header quote-provider-body-text quote-provider-colorText">
                Empresa
              </div>
              <div className="smalltext quote-provider-body-answer quote-provider-colorText">
                {userCompany.name ? userCompany.name : '-'}
              </div>
            </div>
            {/* <div className="quote-provider-body-content_row">
              <div className="smalltext-header quote-provider-body-text quote-provider-colorText">
                Tipo de cliente:           
              </div>
              <div className="smalltext quote-provider-body-answer quote-provider-colorText">
                {userCompany.typeIt.length>0 ? userCompany.typeIt : 'Persona natural'}
              </div>
            </div> */}
            <div className="quote-provider-body-content_row">
              <div className="smalltext-header quote-provider-body-text quote-provider-colorText">
                RUC            
              </div>
              <div className="smalltext quote-provider-body-answer quote-provider-colorText">
                {userCompany.itNumber ? userCompany.itNumber : '-'}
              </div>
            </div>
          </div>     
          
          <div className="quote__row___ row___1">
          {/* {userCompany.itNumber.length >0 && <div className="quote-provider-body-content_row">
              <div className="smalltext-header quote-provider-body-text quote-provider-colorText">
                RUC:            
              </div>
              <div className="smalltext quote-provider-body-answer quote-provider-colorText">
                {userCompany.itNumber}
              </div>
            </div>} */}
            <div className="quote-provider-body-content_row">
              <div className="smalltext-header quote-provider-body-text quote-provider-colorText">
                Teléfono
              </div>
              <div className="smalltext quote-provider-body-answer quote-provider-colorText">
                {phone && phone>0 ? phone: '-'}</div>
            </div> 
            <div className="quote-provider-body-content_row">
              <div className="smalltext-header quote-provider-body-text quote-provider-colorText">
                Correo           
              </div>
              <div className="quote-provider-body__email" onClick={()=>handleCopyEmailClick()}>
                <div className="smalltext quote-provider-colorText">{email && email.length>0 ? email: '-'}</div>
                <CopyIcon   />
              </div>
            </div>
          </div>

          <div className="quote__row___ row___1">
          <div className="quote-provider-body-content_row">
              <div className="smalltext-header quote-provider-body-text quote-provider-colorText">
                Tipo de cliente       
              </div>
              <div className="smalltext quote-provider-body-answer quote-provider-colorText">
                {isFirstImport ? 'Primera importación' : 'Importador frecuente'}
              </div>
            </div>
            {/* <div className="quote-provider-body-content_row">
              <div className="smalltext-header quote-provider-body-text quote-provider-colorText">
                Operaciones en Arkabia          
              </div>
              <div className="smalltext quote-provider-body-answer quote-provider-colorText">
                {userOperations}
              </div>
            </div> */}
          </div>

          

          {/* <div className="quote-provider-body-content">
                      <div className="smalltext quote-provider-body-text quote-provider-colorText">Capacidad de compra anual</div>
                      <div className="paragraph-header quote-provider-body-answer quote-provider-colorText">{"Sin datos" }</div>
                  </div> */}
        </div>

        {/*<div className="quote-provider-footer">
                  <div className="small-bold quote-provider-body-text quote-provider-colorText">Últimos 90 días</div>
                  <div className="quote-provider-body-content">
                      <div className="smalltext quote-provider-body-text quote-provider-colorText">Importaciones</div>
                      <div className="paragraph-header quote-provider-body-answer quote-provider-colorText">Sin datos</div>
                  </div> 
              </div>*/}

      </>: <Loader />}
     
    </div>
    
  );
};
