import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type MailProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Mail: React.FC<MailProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/icono_mail.svg"} style={{height: "19px"}} alt="" />
    </div>
  );
};
