import { UMUnitMobileType } from "../@types/UMUnit";

export const ConvertToMeters = (measure: number, umWeight: UMUnitMobileType | undefined): number => {
    switch (umWeight) {
        case "m":
            return measure;
        case "cm":
            return measure*0.01;
        case "ft":
            return Number((measure * 0.3048).toFixed(2));
        case "in":
            return Number((measure * 0.0254).toFixed(2));
        default:
            return measure;
    }
}