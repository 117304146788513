import { StatusOrder } from "../interfaces/StatusOrder"

export const StatusOrderSupplier: StatusOrder [] = [
    {
        id: 0,
        name: 'No leída',
        color: 'black-25'
    }, 
    {
        id: 1,
        name:'Cotización enviada',
        color: 'yellow'
    },
    {
        id: 2,
        name:'Aceptada',
        color: 'green-2'
    },
    {
        id: 3,
        name:'Leída',
        color: 'blue-2'
    },
    {
        id: 4,
        name:'Rechazada',
        color: 'red'
    },
    {
        id: 4,
        name:'No aceptada',
        color: 'red'
    },
    {
        id: 5,
        name: 'En progreso',
        color: 'yellow',
    },
    {
        id: 6,
        name: 'Finalizado',
        color: 'green-2',
    },
    {
        id: 7,
        name: 'Más barato',
        color: 'green-2',
    },
    {
        id: 8,
        name: 'Más costoso',
        color: 'red',
    }
] 