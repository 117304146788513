import React, { useRef, useEffect } from "react";

/** importando estilos */
import './PanelContent.css';
import { SearchTextField } from "../SearchTextField/SearchTextField";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface PanelContentProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    detail: string;
    icon?: string;
    children?: React.ReactNode;
    search?: boolean;
    setTextSearch?: React.Dispatch<React.SetStateAction<string>>;
}

export const PanelContent: React.FC<PanelContentProps> = ({
    show,
    setShow,
    title,
    detail,
    icon,
    children,
    search = false,
    setTextSearch=()=>{},
}) => { 

    const refPanelContent = useRef<HTMLDivElement>(null);

    // useEffect(()=> {
    //     show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    // }, [show])

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (refPanelContent.current && !refPanelContent.current.contains(event.target)) {
                // console.log("click")
                setShow(false);
            }
          };
          document.addEventListener('click', handleClickOutside, true);
          return () => {
            document.removeEventListener('click', handleClickOutside, true);
          };
    }, []);

    return (
        <>
            {show && 
                <div className="panel-content">
                    <div className="panel-content__header">
                        <div className="small-bold">
                            {title}
                        </div>
                        <img src={STORAGE_ASSETS+'/images/svg/close_big.svg'} alt="" onClick={()=>{setShow(false)}} className="panel-close"/>
                    </div>

                    { detail.length>0 && 
                        <div className="paragraph panel-content-body"> 
                            {icon && <img src={icon}/>}
                            {detail} 
                        </div> 
                    }

                    {/* <SearchTextField 
                        darkIcon 
                        title="Buscar" 
                        setTextSearch={(e)=>setTextSearch(e)} 
                    /> */}

                    <div className="panel-body" ref={refPanelContent}>
                        {children}
                    </div>
                </div>
            }
        </>
    )
}
