import React from "react";

import { ArrowDown } from "../../icons/solid/ArrowDown";
import { ArrowDown_White } from "../../icons/solid/ArrowDown_White"

interface DropdownSelectProps {
  handleDropdown: () => void;
  selectRef: React.RefObject<HTMLDivElement>;
  title: string;
  itemText: string;
  itemRef: React.RefObject<HTMLSpanElement>;
  className: string;
  classNameHeader?: string;
  disabled: boolean,
  roleClick?: string;
  roleText?: string 
  white?: boolean;
  error?: boolean;
}

export const DropdownSelect: React.FC<DropdownSelectProps> = ({
  handleDropdown,
  selectRef,
  title,
  itemText,
  itemRef,
  className,
  classNameHeader,
  disabled,
  roleClick,
  roleText,
  white=false,
  error=false
}) => {

  return (
      <div role={roleClick} className={classNameHeader || "dropdown-select "+className+`${disabled && "dropdown-select__disabled"} ${error ? 'dropdown-select--error' : ''} `} onClick={handleDropdown} ref={selectRef}>
        {itemText !== title && <span className="select-title">{title}</span>}
        <span className={`${disabled ? "dropdown_disable_color": " paragraph-header"} ${itemText !== title ? " dropdown_text" : "" }`} role={roleText} ref={itemRef}>{itemText}</span>
        { white ? <ArrowDown_White/> : <ArrowDown/>}
      </div>       
  );
};
