import React from 'react'

import Emoji from '../../../Consts/Emoji/Emoji.json'

import './EmojiMessage.css';

interface EmojiMessageProps {
    handleEmoji: (e:string) => void;
}

export const EmojiMessage: React.FC<EmojiMessageProps> = ({
    handleEmoji
}) => {
    return (
        <div className={`emoji-container`}>
            <div className='emoji-grid'>
               { Emoji.slice(0.50).map((e, idx) => (
                    <div key={idx} className='emoji-item' onClick={()=>handleEmoji(e.emoji)}>
                        {e.emoji}
                    </div>
               ))}
            </div>
        </div>
    )
}