import React from "react";

import {
    format, 
    isSameDay, 
    isSunday, 
    isSaturday, 
    isSameWeek,
    isSameMonth
} from "date-fns";

import { EventMonth } from "../EventMonth/EventMonth";

import './BodyMonth.css';

interface  BodyMonthrops {
    rowData: any[];
    header?: boolean; // opcion para mostrar el hader
    selectedDate?: Date;
    today?: Date;
    setSelectDay?: (e: Date) => void;
}

export const BodyMonth: React.FC<BodyMonthrops> = ({
    rowData,
    header=false, //
    selectedDate = new Date(),
    today = new Date(),
    setSelectDay = ()=> {}
}) => {


    const getSelectedDayStyle = (day: Date) => {
        if (isSameDay(day, selectedDate)) {
            return 'body-month-DaySelected';
        }
    } 

    const getTodayStyle = (day: Date) => {
        if(!header) {
            if (isSameDay(today, day)) {
               return 'body-month-today';
            }
        }
    }

    const getColorDay = (day: Date) => {
        if(!header) {
            if(isSameMonth(day, selectedDate)) {
                return 'body-month-colorCell';
            } else {
                return 'body-month-colorDesactive';
            }
        }
    }

    return (
        <div className="body-month-container">
            {
                rowData.length>0 && rowData.map((row, idx) => {
                    return (
                        <div 
                            key={idx} 
                            className={`body-month-cell ${!header && (row)}`}
                            onClick={()=> {!header && setSelectDay(row)}}
                        >
                            <div 
                                className={`paragraph ${!header && getSelectedDayStyle(row)} ${getTodayStyle(row)} ${getColorDay(row)}`}>
                                {header?row:format(row, "dd")}
                            </div>
                        </div>
                    )
                }) 
            }

            {/** Eventos */}
            <div className="body-month-event">
                { [...Array(3)].map((c, idx) => (
                <EventMonth key = {idx}
                            title= "Oficina"
                            priority = {'high'}
                            time = {'09:00'}
                /> ))}
                <div className="tinytext body-month-event-more">3 más...</div>
            </div>

        </div>
    )
}