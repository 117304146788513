import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CircleCheckWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const CircleCheckWhite: React.FC<CircleCheckWhiteProps> = ({
  ...props
}) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/circle_check_outline_white.svg"}  alt="" />
    </div>
  );
};
