import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import './ProcessTitle.css';
import { SelectServiceType } from "../../SelectServiceType/SelectServiceType";
import { FromPage } from "../../../@types/fromPageTypes";
import { TextField } from "../../TextField/TextField";
import debounce from "lodash.debounce";
import { ServiceInterface, ServiceTypeInterface } from "../../../interfaces/Service";
import { ErrorInterface } from "../../../interfaces/ErrorInterface";
import { ValidateField } from "../../../utils/ValidateField";
import { SearchService } from "../../SearchService/SearchService";
import { ServiceTypeQuestions } from "../../../Consts/ServiceTypeQuestion";

interface ProcessTitleProps {
    serviceTypeId: string;
    fromPage: FromPage;
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    companyId?: string;
    setServiceType: React.Dispatch<React.SetStateAction<ServiceTypeInterface | undefined>>;
    serviceSelected?: ServiceInterface | undefined;
    setServiceSelected : React.Dispatch<React.SetStateAction<ServiceInterface | undefined>>;
}

const ProcessTitle  = forwardRef((props: ProcessTitleProps, ref) => {

    // UseState for number of characters in the title
    const [countWord, setCoundWord] = useState<number>(0);

    const [error, setError] = useState<{
        serviceType: ErrorInterface,
        title: ErrorInterface
        serviceSelected?: ErrorInterface
    }>({
        serviceType: {
            status: false,
            message: ''
        },
        title: {
            status: false,
            message: ''
        }
    });

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value.length <= 60) {
            props.setTitle(e.target.value);
            setCoundWord(e.target.value.length);
        }       
    }

    // method to validate fields
    const validateTitleFields = (): boolean => {
        let error = false;
    
        error = error || ValidateField(props.serviceTypeId, "required");

        error = error ||  (props.title === "¿Cuál es tu situación?" || props.title.length === 0);

        setError({
            serviceType: {
                message: "Selecciona un servicio",
                status: ValidateField(props.serviceTypeId, "required")
            },
            title: {
                message: "Escribe un título para tu importación",
                status: (props.title === "¿Cuál es tu situación?" || props.title.length === 0)
            }
        });

        if(props.serviceTypeId !== "agenciamiento-de-aduana" ){
            error = error || ValidateField(props.serviceSelected, "notUndefined");
            setError((prev) => ({
                ...prev,
                serviceSelected: {
                    message: "Selecciona un servicio",
                    status: ValidateField(props.serviceSelected, "notUndefined")
                }
            }));
        }


        return error;
    }
    

    const handleServiceSelected = (service: ServiceInterface | undefined) => { 
        props.setServiceSelected(()=>service);
    }

    // const handleServiceTypeChange = (value: ServiceTypeInterface) => {
    //     if(value.id !== props.serviceTypeId) {
    //         props.setServiceType(value);
    //         props.setServiceSelected(undefined);  
    //     }
    // }

    useImperativeHandle(ref, () => ({
        validateTitleFields,
    }));

    return (
        <div className="processTitle-container">
            {/* <div className="smalltext">Elige un servicio</div>
            <SelectServiceType 
                companyId={props.companyId || ""}
                serviceTypeId={props.serviceTypeId}
                handleServiceTypeChange={handleServiceTypeChange}
                setServiceSelected={handleServiceSelected} 
                fromPage={props.fromPage}
                disabled={props.fromPage === "mainService" || props.fromPage === "serviceDetail"}
                error={error.serviceType}
            /> */}
            <div className="smalltext">Escribe un título para tu operación <span className="processTitle-description">{ServiceTypeQuestions.find((st) => st.serviceTypeId === props.serviceTypeId)?.question || ""}</span></div>
            <div className="processTitle-title">
                <TextField 
                    value={props.title === "¡Empezamos!" ? '' : props.title} 
                    title=''
                    placeholder="Ej: Carros de juguete para navidad" 
                    onChange={handleTitleChange} 
                    role='text-modal-quote'
                    type='text' 
                    error={error.title}
                />
                <div className="smalltext-header processTitle-count"> {countWord} / 60 caracteres</div>
            </div>
            {props.serviceTypeId !== "agenciamiento-de-aduana" && props.fromPage !== "serviceDetail" &&
                <SearchService
                    title="Buscar"
                    serviceTypeId={props.serviceTypeId}
                    handleServiceSelected={handleServiceSelected}
                    serviceSelected={props.serviceSelected}
                    error={error.serviceSelected}
                    disabled={props.fromPage === "chat"}
                /> 
            }
           
        </div>
    )
});

export default ProcessTitle;