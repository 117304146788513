import React, { useState, useEffect } from "react";

/** Importando componentes */
import { Maps } from "../Maps/Maps";
import { TextField } from "../TextField/TextField";
import { ButtonEditChange } from "../ButtonEditChange/ButtonEditChange";

/** Importando imagenes */
import { Calendar } from "../../icons/outline/Calendar";
import { StopWatch } from "../../icons/outline/StopWatch";
import { Boat } from '../../icons/outline/Boat';

/** Importando estilos */
import './TrackingShipping.css';
import { FormatDate } from "../../utils/formatDate";
import { CreateCargoShippet, GetLocationByMMSI, GetVesselsByName } from "../../Services/CargoShippet";
import { ShipmentPortsList } from "../Shipment/ShipmentPortsList";
import { SearchListVessels } from "../SearchListVessels/SearchListVessels";
import { CargoShippet } from "../../interfaces/CargoShippet";
import { GetValidDate } from "../../utils/GetValidDate";
import { Loader } from "../Loader/Loader";
import { toast } from "react-toastify";
import { SetOrder } from "../../Services/Order.service";
import { OrderInterface } from "../../interfaces/OrderInterface";
import { differenceInDays, getHours, intlFormatDistance, setSeconds } from "date-fns";
import { HEROKU_CORS, STORAGE_ASSETS } from "../../Consts/BaseUrl";
import { CardContent } from "../CardContent/CardContent";
interface TrackingShippingProps {
    location: {
        lat: number, 
        lng: number
    };
    date? : boolean;
    role?:string,
    cargoShippet: CargoShippet;
    setCargoShippet: React.Dispatch<React.SetStateAction<CargoShippet>>;
    handleSave: ()=>void;
    user?: boolean;
}

export const TrackingShipping: React.FC<TrackingShippingProps> = ({
    location,
    date,
    role,
    cargoShippet,
    setCargoShippet,
    handleSave,
    user = false
}) => {

    const [ showEdit, setShowEdit ] = useState(false);
    const [loadingItem, setLoadingItem] = useState<boolean>(false);
    const [daysRemaining, setDaysRemaining] = useState<number>(0);
    const [queryDate, setQueryDate] = useState<Date>(new Date());
    const [loadPage, setLoadPage] = useState<boolean>(false);

    const [boatLocation, setBoatLocation] = useState<{
        lat: number
        lng: number
    }>({
        lat: -12.052858,
        lng: -77.152983
    })
    const [zoomMap, setZoomMap] = useState<number>(5);

    const [search, setSearch] = useState<any[]>([]);

    const [localDate, setLocalDate] = useState<string>('');

    // handle fecha de llegada
    const handleDeliveryDate=(value: string)=>{
        setLocalDate(GetValidDate(value));
    }

    const handleDateBlur = (estimate: string) => {
        let parseDate = estimate.split("/");
        let new_date = new Date(`${parseDate[2]}-${parseDate[1]}-${parseDate[0]} 00:00:00`);
        setCargoShippet((prev) => ({
            ...prev,
            arrivalDate: new_date
        }))
    }

    const handleName = async ( value: string ) => {
        setCargoShippet((prev) => ({
            ...prev,
            nameBoat: value,
        }))
        if(value && value.length>2) {
            setLoadingItem(true)
            try {
                const response = await GetVesselsByName(value);
                setSearch(response);
                setLoadingItem(false)
            } catch (error) {
                setLoadingItem(false);
                console.log("🚀 ~ file: TrackingShipping.tsx ~ line 64 ~ handleName ~ error", error);
            }
        } else if(value.length===0) {
            setSearch([]);
        }
    }

    const handleVesselClick = (mmsi: number, name: string, type: string, vType: number) => {
        setCargoShippet((prev)=>({
            ...prev,
            mmsi: mmsi,
            nameBoat: name,
            typeBoat: type,
            vTypeBoat: vType
        }));
        setSearch([]);
    }

    /** Buttons Edit */
    const HandleClickComplete = async () => {
        // console.log(localDate, cargoShippet.nameBoat)
        if(localDate && cargoShippet.nameBoat) {
            await handleSave();
            setShowEdit(false);
        } else {
            toast.warning("Por favor ingrese los datos requeridos");
        }
    }

    const handleEdit = () => {
        setShowEdit(true)
    };

    const handleClickCancel = () => {
        setShowEdit(false);
        setLocalDate('');
        setSearch([]);
    }

    const HandleClickSave = () => {
        // console.log('Guardadooooooooo!!')
    }

    /* Funcion para mostrar editar cuando los campos se encuentran llenos
       caso contrario se muestran los botones guardar y cancelar */
    const checkFields = () => {
        /** Se coloca los value */
        setShowEdit(cargoShippet.id ? false:true);
        if(cargoShippet.id && cargoShippet.arrivalDate) {
           console.log(cargoShippet.arrivalDate);
            try {
                let new_date = new Date(cargoShippet.arrivalDate.seconds*1000)
                // console.log("🚀 ~ file: TrackingShipping.tsx ~ line 144 ~ checkFields ~ new_date", new_date)
                setLocalDate(FormatDate(new_date));
            } catch (error) {
                console.log("🚀 ~ file: TrackingShipping.tsx ~ line 148 ~ checkFields ~ error", error)
                let d = FormatDate(new Date(cargoShippet.arrivalDate));
                setLocalDate(d)
            }
        }
    }

    const getEstimatedArrival = () => {
        if(!date && cargoShippet.arrivalDate) {
            // console.log("cargoShippet.arrivalDate", cargoShippet.arrivalDate.toDate())
            const today = new Date();
            const distance = differenceInDays(new Date(cargoShippet.arrivalDate.toDate()), today);
            setDaysRemaining(distance);
        }
        
    }

    // const getBoatLocation = async () => {
    //     if(cargoShippet.id && cargoShippet.id.length>0 && cargoShippet.nameBoat.length>0 && cargoShippet.mmsi>0) {
    //         const response = await GetLocationByMMSI(cargoShippet.mmsi);
    //         if(response) {
    //             const locArray =  response.split("\t");
    //             // console.log("🚀 ~ file: TrackingShipping.tsx ~ line 153 ~ getBoatLocation ~ response", response.split("\t"));
    //             setBoatLocation({
    //                 lat: Number(locArray[0]),
    //                 lng: Number(locArray[1]),
    //             });
    //             setZoomMap(Number(locArray[2]));
    //         }
            
    //     }
    // }

    useEffect(()=> {
        setLoadPage(true)
        // getBoatLocation();
        checkFields();
        !date && getEstimatedArrival();
        setLoadPage(false);
    }, []);

    console.log(cargoShippet)

    return (
        <>
            {
                !loadPage ? <>
                <div className="trackingShipping-container" role={role}>
                { date ? 
                <CardContent>
                    <div className="trackingShipping__column">
                        <div className="paragraph-header trackingShipping-title">Tracking</div>
                        <div className="paragraph trackingShipping-description">Dale a tu cliente una vista clara de su carga en tránsito.</div>
                        <div className="trackingShipping__row">
                            <TextField 
                                title='Fecha de llegada (dd/mm/aaaa)'
                                value={localDate} 
                                onChange={(e:any) => handleDeliveryDate(e.target.value)} 
                                placeholder=" " 
                                type=''
                                onBlur={(e)=>handleDateBlur(e.target.value)}
                                disabled={!showEdit}
                            />
                            <div className="trackingShipping-searchVessels">
                                <TextField
                                    title='Nombre del barco'
                                    placeholder=' '
                                    value={cargoShippet.nameBoat}
                                    onChange={(e:any) => handleName(e.target.value)}
                                    disabled={!showEdit}/>
                                {(loadingItem || search.length>0 ) && <SearchListVessels
                                    vessels={search}
                                    loading={loadingItem}
                                    handleItemClick={handleVesselClick}
                                />}
                            </div>
                        </div>
                        <ButtonEditChange add={showEdit} 
                                        setEdit={setShowEdit} 
                                        edit={showEdit} 
                                        onClickCancel={handleClickCancel} 
                                        onClickComplete={HandleClickComplete} 
                                        onClickEdit={handleEdit}
                                        onClickSave={HandleClickSave}/>
                    </div>
                </CardContent>
                :
                <div className="trackingShipping-static">
                    <div className="trackingShipping-card box-shadow-card">
                        { window.innerWidth <= 880 ? <img src={STORAGE_ASSETS+"/images/png/calendar-mobile.png"}/> : <Calendar className="trackingShipping-icon" /> }
                        <div className="trackingShipping-text">
                            <div className="paragraph-header trackingShipping-color">Llegada estimada</div>
                            <div className="paragraph trackingShipping-color">{localDate.length>0 ? localDate : 'No hay datos'}</div>
                        </div>
                    </div>  
                    
                    { window.innerWidth <= 880 ? user : !user && 
                    <div className="trackingShipping-card box-shadow-card trackingShipping-card-day">
                        <StopWatch className="trackingShipping-icon" /> 
                        <div className="trackingShipping-text">
                            <div className="paragraph-header trackingShipping-color">Días restantes</div>
                            <div className="paragraph trackingShipping-color">{localDate.length>0 ? daysRemaining+" días": 'No hay datos'} </div>
                        </div>
                    </div> }
                     <div className="trackingShipping-card box-shadow-card">
                        {window.innerWidth <= 880 ? <img src={STORAGE_ASSETS+"/images/png/boat-mobile.png"}/>  : <Boat className="trackingShipping-icon" /> }
                        <div className="trackingShipping-text">
                            <div className="paragraph-header trackingShipping-color">Barco</div>
                            <div className="paragraph trackingShipping-color">{cargoShippet.nameBoat.length>0 ? cargoShippet.nameBoat: 'No hay datos'}</div>
                        </div>
                    </div>
                </div>}
                {localDate.length===0 && cargoShippet.nameBoat.length==0  && <div className="trackingShipping-notDataMsg tinytext-header">
                    La agencia aún no ha ingresado los datos del barco
                </div>}
                {/** mobile */}
    
                { !date &&
                <div className="trackingShipping-static-mobile">
                    <div className="trackingShipping-static-mobile-row trackingShipping-static-mobile-row--line">
                        <Calendar className="trackingShipping-icon-mobile" />  
                        <div className="trackingShipping-static-mobile-text">
                            <div className="tinytext-header trackingShipping-color">Llegada estimada</div>
                            <div className="tinytext trackingShipping-color">{localDate.length>0 ? localDate : 'No hay datos'}</div>
                        </div>
                    </div>
                    <div className="trackingShipping-static-mobile-row trackingShipping-static-mobile-row--line">
                        <StopWatch className="trackingShipping-icon-mobile" /> 
                        <div className="trackingShipping-static-mobile-text">
                            <div className="tinytext-header trackingShipping-color">Días restantes</div>
                            <div className="tinytext trackingShipping-color">{localDate.length>0 ? daysRemaining+" días": 'No hay datos'} </div>
                        </div>
                    </div>
                    <div className="trackingShipping-static-mobile-row">
                        <Boat className="trackingShipping-icon-mobile" />   
                        <div className="trackingShipping-static-mobile-text">
                            <div className="tinytext-header trackingShipping-color">Barco</div>
                            <div className="tinytext trackingShipping-color">{cargoShippet.nameBoat.length>0 ? cargoShippet.nameBoat: 'No hay datos'}</div>
                        </div>
                    </div>
                </div> }
                {localDate.length===0 && cargoShippet.nameBoat.length===0  && <div className="trackingShipping-notDataMsg-mb tinytext-header">
                    La agencia aún no ha ingresado los datos del barco
                </div>}
                <div style={{width: '100%'}}>
                   {!loadPage && window && <iframe 
                        name="marinetraffic" 
                        id="marinetraffic" 
                        className="marinetraffic box-shadow-card"
                        width="100%" 
                        height={window.innerWidth < 700 ? window.innerHeight-50 : window.innerHeight-200} 
                        scrolling="no" 
                        frameBorder="1" 
                        src={`https://www.marinetraffic.com/en/ais/embed/zoom:5/centery:-10/centerx:-80/maptype:0/shownames:/mmsi:/shipid:${cargoShippet.mmsi}/fleet:/fleet_id:/vtypes:${cargoShippet.vTypeBoat}/showmenu:false/remember:false`}
                    />}
                </div>

                {/* {!loadPage && <Maps 
                    locationMarker={boatLocation} 
                    zoom={8}
                    updatedTime={`${getHours(queryDate)} ${getHours(queryDate)>9 ? 'Horas': 'Hora'}`}
                />} */}
            </div>
        </>: <Loader /> 
            }
        </>
    )
}

