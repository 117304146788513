import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";

export const GetAllTimeLineByServiceType = async(serviceTypeId:string) => {
    const q = query(collection(DB, "timeLineModal"), where("serviceTypeId", "==", serviceTypeId), orderBy("position",  "asc"));
    const response = await getDocs(q);
    // const response = await getDocs(collection(DB, "service")); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}