import React, { useState } from "react";

/** importando componentes */
import { Dropdown } from "../Dropdown/Dropdown";
import { UploadText } from "../UploadFile/UpdateText/UpdateText";
import { Checkbox } from "../Checkbox/Checkbox";
import { Quotation } from "../ItemInfo/Quotation/Quotation";

/** importando iconos */

import { DocsFileValidation, SpreadSheetFileValidation, PDFFileValidation, ImageFileValidation } from "../../Consts/FilesValidation";

/** importando interfaces */
import { CustomsInterface } from "../../interfaces/CustomsInterface";

// importando utilidades 
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

/** importando estilos */
import './QuoteCustomsData.css';
import { TextField } from "../TextField/TextField";
import { Textarea } from "../Textarea/Textarea";
import { TextAreaInput } from "../TextAreaInput/TextAreaInput";
import StoreService from "../../Services/Store.service";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UpdateRequestQuote } from "../../Services/RequestQuote.service";
import { Loader } from "../Loader/Loader";


interface QuoteCustomsDataProps {
    role?: string
    requestQuoteId: string
    customs: CustomsInterface | undefined
    isCourier?: boolean
    updateData: () => void
    canUpdate?: boolean
}

export const QuoteCustomsData: React.FC<QuoteCustomsDataProps> = ({
    role,
    requestQuoteId,
    customs = {
        regime: '',
        incoterm: '',
        value: 0,
        coin: '',
        insurance: undefined,
        exwDirection: '',
        merchandisePermits: {
            dataSheet: [],
            proforma: [],
            packingList: [],
        },
        descriptionOperation: '',
    },
    updateData,
    canUpdate = true,
    isCourier = false
}) => {

    const {userDetail} = useSelector((state: any) => state.user);

    const [isChecked, setIsChecked] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);

    // method to upload proforma file
    const uploadProformaFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!canUpdate) return;

        const file = e.target.files?.[0];
        if (file && userDetail) {
            setLoading(true);
            try {
                const responseFile = await StoreService.UploadFile(file, requestQuoteId+`/requestQuote/customs/proforma/`+file.name, userDetail.userType.includes("user") ? userDetail.id : "" );
                const tmpRequest = {
                    id: requestQuoteId,
                    customs: {
                        ...customs,
                        merchandisePermits: {
                            ...customs?.merchandisePermits,
                            proforma: [
                                ...customs?.merchandisePermits?.proforma || [],
                                {
                                    name: file.name,
                                    url: responseFile,
                                    size: file.size/1024
                                  },
                            ]
                        }
                    }
                }
               
                setTimeout(async() => {
                    await UpdateRequestQuote(tmpRequest);
                    setLoading(false)
                     updateData();
                }, 500);
                        
            } catch (error) { 
                setLoading(false);
                console.log("🚀 ~ uploadProformaFile ~ error:", error);
                toast.error("Hubo un error al subir el archivo, intentelo en unos minutos");
            }
        }
    }

    // method to upload packing list file
    const uploadPackingListFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!canUpdate) return;

        const file = e.target.files?.[0];
        if (file && userDetail) {
            setLoading(true);
            try {
                const responseFile = await StoreService.UploadFile(file, requestQuoteId+`/requestQuote/customs/packingList/`+file.name, userDetail.userType.includes("user") ? userDetail.id : "" );
                const tmpRequest = {
                    id: requestQuoteId,
                    customs: {
                        ...customs,
                        merchandisePermits: {
                            ...customs?.merchandisePermits,
                            packingList: [
                                ...customs?.merchandisePermits?.packingList || [],
                                {
                                    name: file.name,
                                    url: responseFile,
                                    size: file.size/1024
                                  },
                            ]
                        }
                    }
                }
               
                setTimeout(async() => {
                    await UpdateRequestQuote(tmpRequest);
                    setLoading(false)
                     updateData();
                }, 500);
                        
            } catch (error) { 
                setLoading(false);
                console.log("🚀 ~ uploadPackingListFile ~ error:", error);
                toast.error("Hubo un error al subir el archivo, intentelo en unos minutos");
            }
        }
    }

    // method to upload data sheet file
    const uploadDataSheetFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!canUpdate) return;

        const file = e.target.files?.[0];
        if (file && userDetail) {
            setLoading(true);
            try {
                const responseFile = await StoreService.UploadFile(file, requestQuoteId+`/requestQuote/customs/dataSheet/`+file.name, userDetail.userType.includes("user") ? userDetail.id : "" );
                const tmpRequest = {
                    id: requestQuoteId,
                    customs: {
                        ...customs,
                        merchandisePermits: {
                            ...customs?.merchandisePermits,
                            dataSheet: [
                                ...customs?.merchandisePermits?.dataSheet || [],
                                {
                                    name: file.name,
                                    url: responseFile,
                                    size: file.size/1024
                                  },
                            ]
                        }
                    }
                }
               
                setTimeout(async() => {
                    await UpdateRequestQuote(tmpRequest);
                    setLoading(false)
                     updateData();
                }, 500);
                        
            } catch (error) { 
                setLoading(false);
                console.log("🚀 ~ uploadDataSheetFile ~ error:", error);
                toast.error("Hubo un error al subir el archivo, intentelo en unos minutos");
            }
        }
    }


    return (
        <div className="new-quote-customs-data" role={role}>
            <div className="smalltext-header new-quote-customs-data-title">Aduana</div>

            {/* <div className="new-quote-customs-data-regimen">
                <Dropdown title="Régimen aduanero" value={customs.regime} elements={[customs.regime]} setValue={()=>{}} disabled />
            </div> */}

            <div className="new-quote-customs-data-row__">
                <TextField 
                    title={window.innerWidth< 600 ? "Valor" : "Valor de la mercancía" }
                    placeholder=" " 
                    value={ConverMoneyFormat(customs.value+"")} 
                    disabled 
                />
                <TextField 
                    title="Moneda"
                    placeholder=" " 
                    value={customs.coin} 
                    disabled 
                />
                {!isCourier && <TextField 
                    title="Incoterm"
                    placeholder=" " 
                    value={customs.incoterm}
                    disabled 
                /> }       
            </div>

            { isCourier &&
                <div className="new-quote-customs-data-row__">
                    <TextAreaInput title="Link de compra" value={customs.purchaseLink} />
                </div>
            
            }
            {customs.incoterm === 'EXW' && customs.exwDirection && <div className="new-quote-customs-column__">
                <TextField 
                    title="Dirección de recojo de la mercancía" 
                    placeholder=" " 
                    value={customs.exwDirection} 
                    disable={true} 
                />    
            </div>}

           {!isCourier && <div className="new-quote-customs-column__ new-quote-customs-column__seguro">
                {/* <div className="tinytext new-quote-customs-data-title___"> Importación definitiva </div> */}
                <div className="new-quote-customs-data-row__check">
                    <div className="smalltext-header new-quote-customs-data-title new-quote-customs-data-title__">¿Deseas seguro? </div>
                    <div className="new-quote-customs-data-check">
                        <div className="new-quote-customs-data-center new-quote-customs-data-row__check_">
                            <Checkbox setChecked={setIsChecked} checked={customs.insurance} disabled/>
                            <div className="smalltext new-quote-customs-data-row__check_answer">{window.innerWidth< 600 ? "Sí" : "Sí, deseo"}</div>
                        </div>
                        <div className="new-quote-customs-data-center new-quote-customs-data-row__check_">
                            <Checkbox setChecked={setIsChecked} checked={!customs.insurance} disabled/>
                            <div className="smalltext new-quote-customs-data-row__check_answer">{window.innerWidth< 600 ? "No" : "No, gracias"}</div>
                        </div>
                    </div>
                </div>
            </div>}

         
           {!loading ? <div className="new-quote-customs-data-row__ new-quote-customs-data-row__upload">
                
                <div className={"new-quote-customs-data-upload "+( isCourier? "new-quote-customs-data-upload--courier" : "")}>
                    <div className="paragraph-header new-quote-customs-data-upload-text">Proforma</div>
                    <div className="new-quote-customs-data-upload--attached">
                        {customs.merchandisePermits && customs.merchandisePermits.proforma.map((p, idx) => (
                            <UploadText 
                                key={idx}                                   
                                fileUrl={p.url}
                                fileName={p.name}
                                handleFileValue={()=>{}}
                                />
                        ))}
                        <UploadText        
                            fileUrl={""}
                            fileName={""}
                            handleFileValue={uploadProformaFile}
                            />
                    </div> 
               </div>
       
                <div className="new-quote-customs-data-upload">
                    <div className="paragraph-header new-quote-customs-data-upload-text">Lista de empaque</div>
                    <div className="new-quote-customs-data-upload--attached">
                        {customs.merchandisePermits && customs.merchandisePermits.packingList.map((d, idx) => (
                            <UploadText 
                                key={idx}
                                
                                fileUrl={d.url}
                                fileName={d.name}
                                handleFileValue={()=>{}}
                            />
                            ))}  
                            <UploadText        
                                fileUrl={""}
                                fileName={""}
                                handleFileValue={uploadPackingListFile}
                            />
                    </div>
                    
                </div>
               
                <div className={"new-quote-customs-data-upload "+( isCourier? "new-quote-customs-data-upload--courier" : "")}>
                    <div className="paragraph-header new-quote-customs-data-upload-text">Ficha técnica</div>
                    <div className="new-quote-customs-data-upload--attached">
                        {customs.merchandisePermits && customs.merchandisePermits.dataSheet.map((d, idx) => (
                            <UploadText 
                                key={idx}
                                
                                fileUrl={d.url}
                                fileName={d.name}
                                handleFileValue={()=>{}}
                                />
                        ))}  
                        <UploadText        
                            fileUrl={""}
                            fileName={""}
                            handleFileValue={uploadDataSheetFile}
                        />
                    </div>
                </div>
            </div> : <Loader />}

            { customs.descriptionOperation && customs.descriptionOperation.length>0 && 
                <div className="smalltext-header new-quote-customs-data-title new-quote-customs-data-title_situation">Situación</div>
            }
            { customs.descriptionOperation && customs.descriptionOperation.length>0 && 
            <div className="new-quote-customs-column__">
                <Textarea
                    title="Situación o comentario"
                    value={customs.descriptionOperation}
                    disable={true} 
                />  
            </div>}
            
        </div>
    )
}