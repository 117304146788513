import React, { useRef, useState, useEffect } from "react";
import "./Dropdown.css";
import { DropdownList } from "./DropdownList";
import { DropdownSelect } from "./DropdownSelect";
import {DropdownSelectIcon} from './DropdownSelectIcon'

// importando interfaces
import {ErrorInterface} from "../../interfaces/ErrorInterface";

interface DropdownProps {
  title: string; //titulo del dropdown
  elements: string[]; // elementos del dropdown 
  value: string; // valor en texto del dropdown
  iconActive?: boolean; // estado para ver si el dropdown tiene icono
  disabled?: boolean; // estado si esta habilitado el dropdown
  setValue: React.Dispatch<React.SetStateAction<string>>; // setter para editar el valor en texto del dropdown
  className?: string // nombre de la clase de dropdown
  classNameHeader?: string
  required?: boolean; // el campo es requerido
  error?: ErrorInterface; // mensaje que se muestra cuento sale algun error
  setIdx?: (num: number) => void;
  roleClick?: string;
  roleText?: string;
  white?: boolean;
  onClick?:()=>void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  title,
  elements,
  iconActive,
  disabled=false, 
  value,
  setValue,
  className='',
  classNameHeader,
  required=false,
  error={
    status: false,
    message: ''
  },
  setIdx = () =>{},
  roleClick,
  roleText,
  white,
  onClick=()=>{}
}) => {
  const [itemText, setItemText] = useState("");
  const [icon, setIcon] = useState(false) ;
  const [open, setOpen] = useState(false);
  const [dropdownOrientatio, setDropdownOrientation] = useState('bottom');

  const selectRef = useRef<HTMLDivElement>(null);
  const selectRefIcon = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLSpanElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const dropList = useRef<HTMLDivElement>(null);
  const hrRef = useRef<HTMLDivElement>(null);

  const calculatePositionList = () => {
    const dropdownHeight = elements.length <5 ? elements.length : 5 *40; // total height of the dropdown
    const windowHeight = window.innerHeight*0.85; //total height of the window
    const dropdownTop = Number(selectRef.current?.getBoundingClientRect().top); // distance from the top of the dropdown to the top of the window
    const orientatio = windowHeight - dropdownTop < dropdownHeight ? 'top' : 'bottom';
    setDropdownOrientation(orientatio);
    return orientatio;
  }

  const handleDropdown = () => {
    const orientatio = calculatePositionList();
    setItemText(title);
    setOpen(true);
    selectRef.current?.classList.toggle("dropdown-select__click");
    selectRefIcon.current?.classList.toggle('dropdown-select__click-icon');
    listRef.current?.classList.toggle("dropdown-show");
    listRef.current?.classList.toggle(`dropdown-show--${orientatio}`);
    hrRef.current?.classList.toggle("dropdown-show");
    itemRef.current?.classList.remove("move-text");
    itemRef.current?.classList.toggle('dropdown-no-transform');
    iconRef.current?.classList.toggle('dropdown-no-display');
    onClick();
  
  };

  const setItem = (item: string) => {
    setOpen(false);
    setValue(item);
    setItemText(item);
    // setIdValue(id);
    listRef.current?.classList.remove("dropdown-show");
    listRef.current?.classList.remove(`dropdown-show--top`);
    listRef.current?.classList.remove(`dropdown-show--bottom`);
    hrRef.current?.classList.remove("dropdown-show");
    selectRef.current?.classList.remove("dropdown-select__click");
    selectRefIcon.current?.classList.remove('dropdown-select__click-icon');
    itemRef.current?.classList.add("move-text");
    iconRef.current?.classList.remove('dropdown-no-display');
    itemRef.current?.classList.remove('dropdown-no-transform');
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if(open && dropList.current && !dropList.current.contains(e.target)) {
        listRef.current?.classList.remove("dropdown-show");
        listRef.current?.classList.remove(`dropdown-show--top`);
        listRef.current?.classList.remove(`dropdown-show--bottom`);
        setOpen(false);
        setValue('');
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }

  }, [open])
  

  useEffect(()=> {
    if(value && value.length>0) {
      setItem(value);
    } else {
      setItemText(title);
      setOpen(false);
    }
  }, []);

  // useEffect(()=> {
  //   if(value.length===0) {
  //     setItemText(title);
  //     setOpen(false);
  //     itemRef.current?.classList.remove("move-text");
  //   }
  // }, [value]);

  return (
    <div role='dropdownRole' ref={dropList} className={`dropdown ${disabled && "dropdown__disabled"}`}>
     {!icon?<DropdownSelect
          handleDropdown={handleDropdown}
          itemRef={listRef}
          itemText={itemText}
          selectRef={selectRef}
          title={title}
          className={className} 
          classNameHeader={classNameHeader}
          disabled={disabled}    
          roleClick={roleClick}
          roleText={roleText}
          white={white}
          error={error.status}/>:
        <DropdownSelectIcon
          handleDropdown={handleDropdown}
          itemRef={itemRef}
          itemText={itemText}
          selectRef={selectRefIcon}
          title={title}
          icon={icon}
          iconRef={iconRef}
          textRef={textRef}
        />}
      <DropdownList 
        className={className} 
        icon={iconActive}
        elements={elements} 
        listRef={listRef} 
        refHr={hrRef}
        setItem={setItem} 
        setIcon={setIcon}
        setIdx={setIdx} 
      />
      { (error.status && value.length===0) && <div className="dropdown-error tinytext-header ">{error.message}</div>}
    </div>
  );
};
