import React, { useState } from 'react';

import './FourSeccion.css';
import { SectionAnimation } from '../../SectionAnimation/SectionAnimation';
import { ToolsConsts } from '../../../Consts/ToolsConsts';
import { Helmet } from 'react-helmet-async';

interface FourSeccionProps {
}

export const FourSeccion: React.FC<FourSeccionProps> = ({

}) => {

  return (
    <div className="four-section">
        <Helmet>
            <title>Arkabia | Agencia de Aduana</title>
            <meta name="description" 
            content="Arkabia ofrece una herramienta integral con 
            múltiples posibilidades para escalar tu negocio de 
            importaciones desde cero. Descubre cómo optimizar 
            tu logística y gestiona tus importaciones de manera 
            eficiente." />
            <meta name="keywords" content="agencia de aduana,
            herramienta de importaciones, optimización de importaciones, 
            servicios logísticos, Arkabia, escalar negocio importaciones, 
            importaciones eficientes, plataforma de importaciones, 
            agencia de aduanas, gestión de importaciones" />
        </Helmet>
        <div className="four-section-header">
            <div className='four-section-text'>
                Una herramienta. 
                <b className='four-section-text-grandient'> Muchas posibilidades.</b>
            </div>
            <div className='small-subheader four-section-white four-section-text_subtitle'>
                Todo lo que necesitas para escalar tu negocio de 
                importaciones desde cero está aquí.
            </div>
        </div>

        <div className="four-section-tools">
            {ToolsConsts.map((item, index) => (
                <SectionAnimation 
                    key={index}
                    tools={item}
                />
            ))}
        </div>
    </div>
  );
};