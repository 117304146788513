import { useEffect } from "react";

export const useScript = (url: string) => {
    useEffect(() => {
        const script = window.document.createElement("script");

        script.src = url;
        script.async = true;

        window.document.body.appendChild(script);

        return () => {
            window.document.body.removeChild(script);
        }
    }, [url]);
}

export const AddingScript = (url: string) => {
    return new Promise((res, reject) => {
        const script = window.document.createElement("script");

        script.src = url;
        script.async = true;

        window.document.body.appendChild(script);

        setTimeout(() => {
        }, 2000);
      res(true);
    });
}