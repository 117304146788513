import { STORAGE_ASSETS } from "../Consts/BaseUrl";

export const UserList: any[] = [
    {
        id: 1,
        name: "Juancito",
        img: STORAGE_ASSETS+"/images/png/profile_min.png",
        isActive: true,
        owner: true
        
    },
    {
        id: 2,
        name: "Alexandra",
        img: STORAGE_ASSETS+"/images/png/profile_min.png",
        isActive: true,
        owner: false
        
    },
    {
        id: 3,
        name: "Pepito",
        img: STORAGE_ASSETS+"/images/png/profile_min.png",
        isActive: true,
        owner: false
        
    },
    {
        id: 4,
        name: "Franchesco",
        img: STORAGE_ASSETS+"/images/png/profile_min.png",
        isActive: true,
        owner: false
    },
]