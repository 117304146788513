import React from "react";

import './Badge.css';

interface BadgeProps {
    number: string;
}

export const Badge: React.FC<BadgeProps> = ({
    number,
    ...props
}) => {
    return (
        <div {...props} className={`badge-container`}>
            <div className="smalltext-header badge-text-color">{number}</div>
        </div>
    )
}