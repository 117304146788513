import React, {useState} from "react";


/** importando componentes */
import { RowGroupSupplier } from "../../../components/Row/RowGroupSupplier/RowGroupSupplier";
import { RowGroupDetail } from "../../Row/RowGroupDetail/RowGroupDetail";
import { Textarea } from "../../Textarea/Textarea";
import { Button } from "../../../components/Button/Button";



/** importando interface */
import { ImportCost, Taxes, Detail, Restrictions } from "../../../interfaces/Quotation";

/** importando estilos */
import './QuotationSupplier.css';
import { ContainerType } from "../../../interfaces/ContainerType";
import { Checkbox } from "../../Checkbox/Checkbox";
import { Dropdown } from "../../Dropdown/Dropdown";

 
/** declarando variables de interface */
interface QuotationSupplierProps {
    importCost: ImportCost
    setImportCost: React.Dispatch<React.SetStateAction<ImportCost>>;
    total: string;
    setTotal: React.Dispatch<React.SetStateAction<string>>;
    taxesValue: Taxes;
    setTaxesValue: React.Dispatch<React.SetStateAction<Taxes>>;
    detail: Detail;
    setDetail: React.Dispatch<React.SetStateAction<Detail>>;
    restrictions: boolean;
    setRestrictions: React.Dispatch<React.SetStateAction<boolean>>;
    observation: string;
    setObservation: React.Dispatch<React.SetStateAction<string>>;
    edit: boolean;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    onClick: ()=>void;
    countryOrigin: string;
    countryDestination: string;
    typeTransport: string; // tipo de transporte
    merchandiseValue: number;
    typeContainer?: ContainerType | undefined // tipo de contenedor
    handleCancel: ()=>void;
    statusProvider: string;
    insurance: boolean; // la carga tendra seguro?
    isLcl: boolean; // is LCL?
    isFcl: boolean; // is Fcl ?
    handleAutoSave?: ()=>void;
    isCourier?:boolean;
    currency: "USD" | "EUR";
    setcurrency: React.Dispatch<React.SetStateAction<"USD" | "EUR">>;
}

export const QuotationSupplier: React.FC<QuotationSupplierProps> = ({
    importCost,
    setImportCost,
    total,
    setTotal,
    taxesValue,
    setTaxesValue,
    detail,
    setDetail,
    restrictions,
    setRestrictions,
    observation,
    setObservation,
    onClick,
    edit,
    setEdit,
    countryOrigin,
    countryDestination,
    merchandiseValue,
    typeContainer = {
        lcl: false,
        fcl: false,
        lclAir: false,
    },
    handleCancel,
    statusProvider,
    insurance,
    isLcl,
    isFcl,
    handleAutoSave=()=>{},
    isCourier=false,
    currency,
    setcurrency
}) => {
    // const [isHover, setIsHover] = useState(false);
    // const [activeAutoSave, setAciveAutoSave] = useState<boolean>(false);


    // const handleRestrictionChange = (index: number, value: Restrictions) => {
       
    // }

    // const handleMouseOver = () => {
    //     setIsHover(true);
    // };
    
    //  const handleMouseOut = () => {
    //     setIsHover(false);
    // };

    return (
        <div className="tableQuotation ">
             <div className="tableQuotation-currecy">
                <Dropdown 
                    title='Moneda'
                    elements={["USD", "EUR"]}
                    setValue={(e:any)=>setcurrency(e) }
                    value={currency || "USD"}    
                    disabled={edit}
                />
            </div>
            <div className='tableQuotation-container box-shadow-card'>
                <div className="paragraph-header tableQuotation-title">Gastos de importación</div>
                <RowGroupSupplier 
                    originCountry={countryOrigin}
                    destinationCountry={countryDestination}
                    open={true}
                    open1={true}
                    open2={true}
                    disabled= {edit}
                    importCost={importCost}
                    setImportCost={setImportCost}
                    total={total}
                    setTotal={setTotal}
                    typeContainter={typeContainer}
                    insurance={insurance} 
                    isLcl={isLcl}
                    handleAutoSave={handleAutoSave}
                    isCourier={isCourier}
                    taxesValue={taxesValue}
                    setTaxesValue={setTaxesValue}
                    merchandiseValue={merchandiseValue}
                />
            </div>

            {/* <div className='tableQuotation-container'>
                <div className="paragraph-header tableQuotation-title">Impuestos</div>
                <RowGroupTaxes 
                    total={total}
                    taxes={taxes}
                    setTaxes={setTaxes}
                    taxesValue={taxesValue}
                    setTaxesValue={setTaxesValue}
                    disabled={edit}
                    importCost={importCost}
                    merchandiseValue={merchandiseValue}
                    handleAutoSave={handleAutoSave}
                    isCourier={isCourier}
                />               
            </div> */}

            {/** Ranting del aduanero */}
            {/* {edit &&<div className='tableQuotation-container__'>
                <div className="tableQuotation-rating">
                    <div className="tableQuotation-rating-content">
                        <div className="paragraph-header tableQuotation-title__">Puntaje de servicio</div>
                        <Hover 
                            content="Este es el puntaje que le han dado los usuarios a la agencia de aduana"
                            show={isHover}
                            setShow={setIsHover}>
                            <MedalRating  
                                onMouseOver={handleMouseOver} 
                                onMouseOut={handleMouseOut}
                            />
                        </Hover>
                    </div>
                </div>
                <div className="tableQuotation-rating">
                    <RatingSlider rating={rating}/>
                    <span className="paragraph tableQuotation-title__">{Number((rating).toFixed(1))}</span>
                    <div className="tableQuotation-rating-icon">
                        <MedalRating/>
                    </div>
                </div>
            </div> } */}


            <div className='tableQuotation-container box-shadow-card'>
                <div className="paragraph-header tableQuotation-title">Detalles</div>
                <RowGroupDetail
                    detail={detail}
                    setDetail={setDetail}
                    disabled= {edit}
                    isFcl={isFcl}
                    handleAutoSave={handleAutoSave}
                    isCourier={isCourier}
                />
            </div>
            <div className='tableQuotation-container box-shadow-card'>
                <div className="paragraph-header tableQuotation-title">Permisos</div>
                <div className="tableQuotation-restrictions">
                    <div className="paragraph tableQuotation-title__">¿Requiere permiso?</div>
                    <div className="tableQuotation-restrictions--check">
                        <div className="paragraph tableQuotation-title__">Sí</div>
                        <Checkbox disabled={edit} checked={restrictions} setChecked={()=>setRestrictions(true)} />
                        <div className="paragraph tableQuotation-title__">No</div>
                        <Checkbox disabled={edit} checked={!restrictions} setChecked={()=>setRestrictions(false)} />
                    </div>
                </div>
                
            </div>

            {/* { restrictions && restrictions.length>0 &&<div className='tableQuotation-container'> 
                <div className="paragraph-header tableQuotation-title">Restricciones</div>
                <div className="tableQuotation-container-restriction">
                    {
                        restrictions && restrictions.length>0 && restrictions.map((r, idx) => (
                            <Quotation
                                key={idx}
                                productNumber={idx}
                                products={restrictions}
                                setProductArr={()=>{}}
                                setProductDetails={setRestrictions}
                                disabledFields={true}
                                disabledImages={true}
                                supplier={true}
                                editRestrictions={edit}
                            />
                        ))   
                    }
                </div>
            </div>} */}

            <div className='tableQuotation-container box-shadow-card'>
                <div className="paragraph-header tableQuotation-title">{isCourier ? 'Observaciones' : 'Notas' }</div>
                <div className='tableQuotation-body'>
                    <Textarea title={observation.length>0 ? '' : "Inserta aquí tus notas" }
                            value={observation}
                            onBlur={(e:any) =>  {setObservation(e.target.innerHTML); handleAutoSave()}}
                            placeholder= " "
                            disable={edit}
                            //   style={{paddingTop: observation.length>0 ? '0px' : '30px'}}
                            />
                </div>
            </div>
            
            <div className={!edit ? 'tableQuotation-footer': 'tableQuotation-footer-quotation'}>
                {/* {!edit && <ButtonIcon content="guardar borrador"
                            Icon={<FolderPlusGray />}
                            color='clear'
                            size="extra-small"
                            place="left"
                            onClick={()=>{}}/>} */}

                { !edit ?  
                        <div className="tableQuotation-footer--buttons">
                            <Button style={{backgroundColor: 'var(--red)', color: 'var(--white)', fontFamily: 'Faktum-SemiBold', width: '100%'}}
                                content="Cancelar" onClick={handleCancel}/>
                            <Button style={{backgroundColor: 'var(--black-75)', fontFamily: 'Faktum-SemiBold', width: '100%'}}
                                content="Enviar cotización" onClick={()=>{onClick()}} /> 
                            
                        </div>: 
                        statusProvider!== 'Rechazada' && statusProvider !== 'No aceptada' &&  <Button style={{backgroundColor: 'var(--yellow)', color: 'var(--gray-1)', fontFamily: 'Faktum-SemiBold', width: '100%'}}
                        content="Modificar cotización" onClick={()=>{setEdit(!edit)}}/> }
            </div> 
        
        </div>
    )
}   