import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { GetAllConsigneeByUserId } from "../Services/Consignee.service";

import { setError } from "./Error";

export const GetConsigneeByUserId = createAsyncThunk(
    'consignee/getConsigneeByUserId',
    async (userId: string, thunkAPI: any) => {
        try {
            const response:any = await GetAllConsigneeByUserId(userId);
            console.log("🚀 ~ file: Consignee.ts ~ line 12 ~ response", response)
        
            thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
            return response;
       
        } catch (error:any) {
            console.log('slice/consignee/getConsigneeByUserId => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
)

const initialState = {
    consigneeList: [],
}

const consigneeSlice = createSlice ({
    name: "consignee",
    initialState,
    reducers: {},
    extraReducers: {
        [GetConsigneeByUserId.fulfilled.toString()]: (state, action) => {
            state.consigneeList = action.payload
        },
        [GetConsigneeByUserId.rejected.toString()]: (state, action) => {
            state.consigneeList = []
        }
    }
})

const {reducer} = consigneeSlice;
export default reducer;