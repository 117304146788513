import React, { EventHandler, useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";

import { FileValidate } from "../../../utils/FileValidate";
import { ToastWarning } from "../../ToastWarning/ToastWarning";

import { CloudUp } from "../../../icons/outline/CloudUp";
import { Close_RedSmall } from "../../../icons/solid/Close_RedSmall";

import "./UploadText.css";

interface UploadTextProps {
    label?: string;
    fileName?: string;
    fileUrl?: string;
    handleFileValue: (e: any) => void;
    disabled?: boolean;
    acceptType?: string;
    fileValue?: any[];
    isDelete?: boolean;
    maxSize?: number;
    handleDelete?: ()=>void;
}

export const UploadText: React.FC<UploadTextProps> = ({
    label = 'Subir archivo',
    fileName='',
    fileUrl='',
    handleFileValue,
    disabled=false,
    fileValue=[],
    isDelete=false,
    acceptType='',
    maxSize=20,
    handleDelete=()=>{}
  }) => {

    const fileRef = useRef<HTMLInputElement>(null);

    const [show, setShow] = useState<boolean>(false);

    const handleDeleteFile= () => {
        handleDelete();
        
        handleFileValue({ target: { files: [] } });
        setShow(!show);
    }

    const showToast = () => {
        if(!toast.isActive('confirm')) {
            toast(
                <ToastWarning
                    title = "¿Está seguro que desea eliminar el documento?"
                    textButtonCancel = "No"
                    textButtonAccept = "Si"
                    onClickCancel={()=>{}}
                    onClickAccept={handleDeleteFile}
                />, {
                    hideProgressBar: true,
                    autoClose: 7000,
                    toastId: 'confirm'
                }
            );
        }
    }
    
    const handleChange = (e: any) => {
        let file =  e.target.files[0]
        // console.log("🚀 ~ file: UpdateText.tsx ~ line 47 ~ handleChange ~ file", file)
        if(FileValidate(file, acceptType, 20)) {
            handleFileValue(e);
            // setShow(true);
        } else {
            toast.info("El archivo no cumple con las condiciones");
        }
    }

    useEffect(() => {
       
        if(fileValue.length>0 || (fileName.length>0 && fileUrl.length>0)) {
            setShow(!show);
        }
    }, []);

    return (
        <div className="updateText-content">
            <div className={ 
                    !show? 
                    "updateText-content-button" : (isDelete ? "updateText-content-button-hover" : "updateText-content-button-file")} 
                 onClick={ 
                    !show ? 
                    () => {fileRef.current?.click()} : () => {}}
                >
                <div className={ 
                    !show ? 
                    "tinytext-header updateText-content-button-text" : "tinytext-header updateText-content-text"}>
                    {
                        !show ? label: 
                        !disabled ? <a href={fileUrl} target="_blank" download={fileName} className="tinytext-header updateText-a">{fileName }</a> :
                        <div>{fileName}</div>
                    }   
                </div>

                <div className='updateText-icon'> 
                    { 
                        !show ? <CloudUp /> : 
                        !disabled && isDelete ? <div className="updateText-icon-close">
                            <Close_RedSmall onClick={()=>showToast()}/>
                        </div> : ''
                    } 
                </div>
            </div>

            
            <input
                onChange={(e)=>handleChange(e)}
                type="file"
                hidden
                ref={fileRef}
                // value={fileValue}
                disabled={disabled}
                accept={acceptType}
                maxLength={1}
                role='upload-file-input-upload'
            />
        </div>
    )
  }