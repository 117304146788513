import React, { useEffect, useState } from "react";

/** importando modals */
import { Modal } from '../../Modal/Modal/Modal';

/** importando css */
import './ModalStatement.css';
import { CheckCircle } from "../../CheckCircle/CheckCircle";
import { Button } from "../../Button/Button";
interface ModalStatementProps {
    setShowModal: (e: boolean) => void; // medoto para modificar la variable showModal
    showModal: boolean,
    title: string;
    providerIdx: number;
    statement?: {
        q1: boolean;
        q2: boolean;
        q3: boolean;
        q4: boolean;
        q5: boolean;
        q6: boolean;
        q7: boolean;
        q8: boolean;
    }
    handleSaveStatement: (providerIdx: number, q1: boolean, q2: boolean, q3: boolean, q4: boolean, q5: boolean, q6: boolean, q7: boolean, q8: boolean)=>void
}

export const ModalStatement: React.FC<ModalStatementProps> = ({
    setShowModal,
    showModal,
    title,
    providerIdx,
    handleSaveStatement,
    statement = {
        q1: false,
        q2: false,
        q3: false,
        q4: false,
        q5: false,
        q6: false,
        q7: false,
        q8: false,
    }
})=> { 

    const [ check, setCheck] = useState<boolean>(false);
    const [ check2, setCheck2] = useState<boolean>(false);
    const [ check3, setCheck3] = useState<boolean>(false);
    const [ check4, setCheck4] = useState<boolean>(false);
    const [ check5, setCheck5] = useState<boolean>(false);
    const [ check6, setCheck6] = useState<boolean>(false);
    const [ check7, setCheck7] = useState<boolean>(false);
    const [ check8, setCheck8] = useState<boolean>(false);

    useEffect(()=> {
        setCheck(statement.q1);
        setCheck2(statement.q2);
        setCheck3(statement.q3);
        setCheck4(statement.q4);
        setCheck5(statement.q5);
        setCheck6(statement.q6);
        setCheck7(statement.q7);
        setCheck8(statement.q8);
    }, [providerIdx]);

    return (
        <Modal setShow={setShowModal} show={showModal} title={title} className="modal-sendMessage">
            <div className="modalstatement-content">
                <div className="smalltext-header modalstatement-content-subtitle">
                    A efectos de valoración en aduana, existe vinculación entre el importador  y el proveedor extranjero 
                    si se presenta alguna  de las siguientes situaciones:
                </div>

                <div className="modalstatement-check-row" onClick={()=>setCheck(!check)}>
                    <CheckCircle checked={check} setChecked={()=>setCheck(!check)}/>
                    <div className="smalltext">
                        Si una de ellas ocupa cargos de responsabilidad o dirección de una  empresa de la otra.
                    </div>
                </div>

                <div className="modalstatement-check-row" onClick={()=>setCheck2(!check2)}>
                    <CheckCircle checked={check2} setChecked={()=>setCheck2(!check3)}/>                    
                    <div className="smalltext">
                        Si están legalmente reconocidas como asociadas en negocios.
                    </div>
                </div>

                <div className="modalstatement-check-row" onClick={()=>setCheck3(!check3)}>
                    <CheckCircle checked={check3} setChecked={()=>setCheck3(!check3)}/>                    
                    <div className="smalltext">
                        Si están en relación de Empleador y Empleado.
                    </div>
                </div>

                <div className="modalstatement-check-row" onClick={()=>setCheck4(!check4)}>
                    <CheckCircle checked={check4} setChecked={()=>setCheck4(!check4)}/>                    
                    <div className="smalltext">
                        Si una persona tiene directa o indirectamente la propiedad, el control o la posesión del 5% o más  de 
                        las acciones o títulos en circulación y con derecho a voto de ambos.
                    </div>
                </div>

                <div className="modalstatement-check-row" onClick={()=>setCheck5(!check5)}>
                    <CheckCircle checked={check5} setChecked={()=>setCheck5(!check5)}/>                    
                    <div className="smalltext">
                        Si una de ellas controla directa o indirectamente a la otra.                         
                    </div>
                </div>

                <div className="modalstatement-check-row" onClick={()=>setCheck6(!check6)}>
                    <CheckCircle checked={check6} setChecked={()=>setCheck6(!check6)}/>                    
                    <div className="smalltext">
                        Si ambas personas están controladas directa o indirectamente por una tercera.
                    </div>
                </div>

                <div className="modalstatement-check-row" onClick={()=>setCheck7(!check7)}>
                    <CheckCircle checked={check7} setChecked={()=>setCheck7(!check7)}/>                    
                    <div className="smalltext">
                        Si juntas controlan directa o indirectamente a una tercera persona.
                    </div>
                </div>

                <div className="modalstatement-check-row" onClick={()=>setCheck8(!check8)}>
                    <CheckCircle checked={check8} setChecked={()=>setCheck8(!check8)}/>                    
                    <div className="smalltext">
                        Si son de la misma familia.
                    </div>
                </div>

                <div className="modalstatement-check-row__">
                    <Button
                        content='Guardar'
                        size='normal'
                        color='black-75'
                        onClick={()=>handleSaveStatement(providerIdx, check, check2, check3, check4, check5, check6, check7, check8)}
                    />
                </div>
            </div>
        </Modal>
    )
}