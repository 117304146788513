import React, { useEffect, useRef, useState, useCallback } from "react";
import { Modal } from '../Modal/Modal';

import Lottie from "lottie-web";

import './ModalAnimation.css';
import { Button } from "../../Button/Button";
import { CircleTab } from "../../CircleTab/CircleTab";

interface ModalAnimationProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    animation: {
        path: string;
        title: any;
        description: string;
        description2?: string;
        description3?: string;
        image: string;
    }[];
}

export const ModalAnimation: React.FC<ModalAnimationProps> = ({
    show,
    setShow,
    animation
}) => {

    let animationRef = useRef<HTMLDivElement>(null); 

    const [subTab, setSubTab] = useState<number>(0);
    // const [text, setText] = useState<string>('Siguiente');

    // const [animation, setAnimation] = useState<any>(null);

    const handleClose = () => {
        setShow(false);
    }

    const handleClick = () => {
        createAnimation(subTab === animation.length-1 ? 0 : subTab+1);
        setSubTab(subTab === animation.length-1 ? 0 : subTab+1);
    }

    const createAnimation = useCallback((idx: number) => {
        if(animationRef.current) {
            Lottie.destroy();
            Lottie.loadAnimation({
                container: animationRef.current, // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path:  animation[idx].path // the path to the animation json
          });
        } 
    }, [subTab]);

    const handleDotClick = (idx:number) => {
       setSubTab(idx);
       createAnimation(idx);
    };


    useEffect(() => {
        // handleClick()
        createAnimation(0)
    }, [animationRef.current]);

    return (
        <> {
            animation.length > 0 && subTab>=0 && 
            <Modal show={show} setShow={setShow} className="modal-modalAnimation">
            <div className="modalAnimation-content">   
                <div className='modalAnimation'>

                    <div className= "modalAnimation-video" ref={animationRef}></div>
                    
                    <div className="modalAnimation-circleTabs">
                    { animation.length>1 &&animation.map((item, idx) => ( 
                        <CircleTab 
                            key={idx}
                            status={(subTab) === idx} 
                            handleClick={()=>handleDotClick(idx)} 
                        />
                    ))}
                    </div>
                    <div className="modalAnimation-body" >
                        <div className={"paragraph-header modalAnimation-text"}>
                            {animation[subTab].image.length>0 && <img className="modalAnimation-img" src={animation[subTab].image} alt="icon"/>}
                            {animation[subTab].title}
                        </div>
                        <div className="paragraph modalAnimation-description__">
                            <div className="paragraph modalAnimation-description">
                                {animation[subTab].description}
                            </div>
                            {animation[subTab].description2 && 
                            <div className="paragraph modalAnimation-description">
                                {animation[subTab].description2}
                            </div>}
                            {animation[subTab].description3 && 
                            <div className="paragraph modalAnimation-description">
                                {animation[subTab].description3}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            <div className={animation.length>1 ? "modalAnimation-buttons" : "modalAnimation-button-one"}>
                {/* {animation.length>1 && <Button
                    content="Ver Luego" 
                    className="modalAnimation-btn-outline"
                    onClick={() => handleClose()}
                />} */}
                <Button
                    content={animation.length-1 === subTab ? "Cerrar" : "Siguiente"} 
                    onClick={() => animation.length-1 === subTab ? handleClose() : handleClick()}
                />
            </div>
        </Modal>
        }
        </>
    )
}