import React from "react";

import { Loader } from "../Loader/Loader";
import { Row } from "../Row/Row";
import { ItemVessels } from "./ItemVessels/ItemVessels";



import './SearchListVessels.css';

interface SearchListVesselsProps {
    loading: boolean;
    vessels: any[];
    handleItemClick: (mmsi: number, name: string, type: string, vType: number)=>void;
}

export const SearchListVessels: React.FC<SearchListVesselsProps> = ({
    loading,
    vessels,
    handleItemClick
}) => {
    return (
        <div className="searchListVessels-container">
            {loading ? <Loader /> :
            (<>
                {vessels.length>0 && vessels.map((v, idx) => (
                    <ItemVessels 
                        key={idx}
                        alpha2Code={v.desc.split("[").length>1 ? ((v.desc.split("[")[1]).split("]")[0]).toLowerCase(): ''}
                        name={v.value}
                        description={v.desc}
                        handleclick={()=>handleItemClick(Number(v.id), v.value, v.type, Number(v.type_color))}
                    />
                ))}
            </>
            )}
        </div>
    )
}