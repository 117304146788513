import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from "firebase/database";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";

const { 
  REACT_APP_APIKEY_FIREBASE,
  REACT_APP_AUTHDOMAIN_FIREBASE,
  REACT_APP_PROJECTID_FIREBASE,
  REACT_APP_STORANGEBUCKET_FIREBASE, 
  REACT_APP_MESSAGINSENDERID_FIREBASE,
  REACT_APP_APPID_FIREBASE,
  REACT_APP_DATABASEURL,
  REACT_APP_MESSAGING
} = process.env;

const FirebaseConfig = {
  apiKey: REACT_APP_APIKEY_FIREBASE,
  authDomain: REACT_APP_AUTHDOMAIN_FIREBASE,
  projectId: REACT_APP_PROJECTID_FIREBASE,
  storageBucket: REACT_APP_STORANGEBUCKET_FIREBASE,
  messagingSenderId: REACT_APP_MESSAGINSENDERID_FIREBASE,
  appId: REACT_APP_APPID_FIREBASE,
  databaseURL: REACT_APP_DATABASEURL,
  messaging: REACT_APP_MESSAGING,
};

  
// Initialize Firebase
const FirebaseApp = initializeApp(FirebaseConfig);

// Firebase functions instance
const Functions = getFunctions(FirebaseApp);
// connectFunctionsEmulator(Functions, "localhost", 4040);

// Firebase Firestore instance
const DB = getFirestore(FirebaseApp);

// Firestore RealTime Database instance
const Database = getDatabase(FirebaseApp);

const messaging = (async () => {
  try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
          //Firebase Cloud Messageing
          return getMessaging(FirebaseApp);
      }
      return null;
  } catch (err) {
      console.log(err);
      return null;
  }
  })();

//Firebase Cloud Messageing
const Messaging = messaging;

// Messageing get token
export const fetchToken = async (setTokenFound: (key: string)=> void) => {
  let m: any = await messaging;
  if(!m)
    return false;

  return getToken(m, {vapidKey: REACT_APP_MESSAGING}).then((currentToken) => {
    if (currentToken) {
      setTokenFound(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      setTokenFound("");
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    // console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

// listen for messages from FCM
export const onMessageListener = async (cb: (e: any)=>void) => {
  let m: any = await messaging;
  if(!m)
    return false;

  onMessage(m, (payload) => {
    cb(payload);
  });
}
  


export {FirebaseApp, DB, Functions, Database, Messaging};