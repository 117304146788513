import React, { useRef } from "react";
import "./UploadFile.css";
import { CloudUp } from "../../icons/outline/CloudUp";
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { UploadText } from "./UpdateText/UpdateText";

interface UploadFilesProps {
  title: string; // titulo del componente
  subtitle: string; // subtitulo del componente
  buttonContent: string; // texto del boton
  fileValue?: any; //  file value local
  fileUrl?: string // url de la archivo de la base de datos
  fileName?: string;  // nombre del archivos de la base de datos
  disabled?: boolean;
  acceptType?: string;
  handleFileValue: React.ChangeEventHandler<HTMLInputElement>;
  handleDelete?: ()=>void;
  role?: string
}

export const UploadFile: React.FC<UploadFilesProps> = ({
  title,
  subtitle,
  buttonContent,
  fileValue=undefined,
  fileName='',
  fileUrl='',
  disabled=false,
  acceptType,
  handleFileValue,
  role,
  handleDelete=()=>{}
}) => {
  const fileRef = useRef<HTMLInputElement>(null);

  return (
    <div className="uploadfile" role={role}>
      <div className="uploadfile-container">
        {title.length>0 && <h1 className="paragraph-header">{title}</h1>}
        {subtitle.length>0 && <p className="paragraph">{subtitle}</p>}
        <div className="uploadfile-bottom">
          <UploadText
            handleFileValue={handleFileValue}
            fileName={fileName}
            fileUrl={fileUrl}
            fileValue={fileValue}
            acceptType={acceptType}
            disabled={disabled}
            isDelete
            handleDelete={handleDelete}
            label={buttonContent}
          />
          {/* <ButtonIcon
            content={buttonContent}
            Icon={<CloudUp />}
            color="blue-2"
            size="extra-small"
            place="right"
            onClick={() => {
              fileRef.current?.click();
            }}
          />
          <input
            onChange={handleFileValue}
            type="file"
            hidden
            ref={fileRef}
            // value={fileValue}
            disabled={disabled}
            accept={acceptType}
            role='upload-file-input-upload'/> */}


            
          {/* <span className="tinytext">
            {fileName.length === 0 && "No hay ningún documento todavía"}
          </span> */}
        </div>
      </div>
    </div>
  );
};
