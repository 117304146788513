import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type HamburgerGrayMenuProps = React.HTMLAttributes<HTMLDivElement> & {};

export const HamburgerGrayMenu: React.FC<HamburgerGrayMenuProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/png/hamburguerGray.png"} alt="" />
    </div>
  );
};