import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

import { GetCompanyById, GetCompanyByUser, GetAllCompanyActive} from "../Services/Company.service";

export const GetCompanyActive = createAsyncThunk(
    'company/getAllActive',
    async (_, thunkAPI: any) => {
        try {
            const response = await GetAllCompanyActive();
           
            thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
            return response;
          
        } catch (error:any) {
            console.log("slice/company/getAllActive => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
            return thunkAPI.rejectWithValue();
        }
    }
);


export const GetCompany = createAsyncThunk(
    'company/getById',
    async (id: string, thunkAPI: any) => {
        try {
            const response = await GetCompanyById(id);
            if(response) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response;
            }
        } catch (error:any) {
            console.log("slice/company/getById => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const GetCompanyUser = createAsyncThunk(
    'company/getCompanyUser', 
    async (id: string, thunkAPI: any) => {
        try {
            const response = await GetCompanyByUser(id);

            if(response.length>0) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response[0];
            } else {
                return false
            }
        } catch (error: any) {
            console.log('slice/company/getCompanyUser => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
);


const initialState = {
    CompanyDetail : {
        id: '',
        name: '',
        userAdmin: '',
        aboutUs: '', 
        rating: 0,
        numComments: 0,
        country: {
            name: '',
            alpha2Code: '',
            imgFlag: ''
        },
        city: '',
        catProviderName: '',
        serviceType: '',
        numOperation: 0,
        photoProfile: '',
        photoCover: '',
        phone: 0,
        status: false,
        createdAt: new Date(),
        updatedAt: new Date()
    }, 
    companyList: []
}

const CompanySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {},
    extraReducers: {
        [GetCompanyActive.fulfilled.toString()]: (state, action) => {
            state.companyList = action.payload
        },
        [GetCompanyActive.rejected.toString()]: (state, action) => {
            state.companyList = [];
        },
        [GetCompany.fulfilled.toString()]: (state, action) => {
            state.CompanyDetail.id = action.payload.id;
            state.CompanyDetail.name = action.payload.name;
            state.CompanyDetail.userAdmin = action.payload.userAdmin;
            state.CompanyDetail.aboutUs = action.payload.aboutUs;
            state.CompanyDetail.rating = action.payload.rating;
            state.CompanyDetail.numComments = action.payload.numcomments;
            state.CompanyDetail.country = action.payload.country;
            state.CompanyDetail.city = action.payload.city;
            state.CompanyDetail.catProviderName = action.payload.catProviderName;
            state.CompanyDetail.serviceType = action.payload.serviceType;
            state.CompanyDetail.numOperation = action.payload.numOperation;
            state.CompanyDetail.photoProfile = action.payload.photoProfile;
            state.CompanyDetail.photoCover = action.payload.photoCover;
            state.CompanyDetail.status = action.payload.status;
            state.CompanyDetail.createdAt = action.payload.createdAt;
            state.CompanyDetail.updatedAt = action.payload.updatedAt;
        },
        [GetCompany.rejected.toString()]: (state, action) => {
            state.CompanyDetail.id = '';
            state.CompanyDetail.name = '';
            state.CompanyDetail.userAdmin = '';
            state.CompanyDetail.aboutUs = '';
            state.CompanyDetail.rating = 0;
            state.CompanyDetail.numComments = 0;
            state.CompanyDetail.country = {
                name: '',
                alpha2Code: '',
                imgFlag: ''
            };
            state.CompanyDetail.city = '';
            state.CompanyDetail.catProviderName = '';
            state.CompanyDetail.serviceType = '';
            state.CompanyDetail.numOperation = 0;
            state.CompanyDetail.photoProfile = '';
            state.CompanyDetail.photoCover = '';
            state.CompanyDetail.status = false;
            state.CompanyDetail.createdAt = new Date();
            state.CompanyDetail.updatedAt = new Date();
        },
        [GetCompanyUser.fulfilled.toString()]: (state, action) => {
            state.CompanyDetail.id = action.payload.id;
            state.CompanyDetail.name = action.payload.name;
            state.CompanyDetail.userAdmin = action.payload.userAdmin;
            state.CompanyDetail.aboutUs = action.payload.aboutUs;
            state.CompanyDetail.rating = action.payload.rating;
            state.CompanyDetail.numComments = action.payload.numcomments;
            state.CompanyDetail.country = action.payload.country;
            state.CompanyDetail.city = action.payload.city;
            state.CompanyDetail.catProviderName = action.payload.catProviderName;
            state.CompanyDetail.serviceType = action.payload.serviceType;
            state.CompanyDetail.numOperation = action.payload.numOperation;
            state.CompanyDetail.photoProfile = action.payload.photoProfile;
            state.CompanyDetail.photoCover = action.payload.photoCover;
            state.CompanyDetail.status = action.payload.status;
            state.CompanyDetail.createdAt = action.payload.createdAt;
            state.CompanyDetail.updatedAt = action.payload.updatedAt;
        },
        [GetCompanyUser.rejected.toString()]: (state, action) => {
            state.CompanyDetail.id = '';
            state.CompanyDetail.name = '';
            state.CompanyDetail.userAdmin = '';
            state.CompanyDetail.aboutUs = '';
            state.CompanyDetail.rating = 0;
            state.CompanyDetail.numComments = 0;
            state.CompanyDetail.country = {
                name: '',
                alpha2Code: '',
                imgFlag: ''
            };
            state.CompanyDetail.city = '';
            state.CompanyDetail.catProviderName = '';
            state.CompanyDetail.serviceType = '';
            state.CompanyDetail.numOperation = 0;
            state.CompanyDetail.photoProfile = '';
            state.CompanyDetail.photoCover = '';
            state.CompanyDetail.status = false;
            state.CompanyDetail.createdAt = new Date();
            state.CompanyDetail.updatedAt = new Date();
        }
    }
});

const {reducer} = CompanySlice;
export default reducer;
