import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CheckProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Check: React.FC<CheckProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/done_all.svg"} alt="" />
    </div>
  );
};