import React, { useEffect, useState } from "react";

import "./TableComparePlan.css";

// Import components
import { CardContent } from "../CardContent/CardContent";
import { Button } from "../Button/Button";
import { TagButton } from "../Button/Tag/TagButton";

// Import services
import { ServicePlanInterface } from "../../interfaces/ServicesPlanInterface";

// Import icons
import { Close_red } from "../../icons/solid/Close_red";
import { Check_green } from "../../icons/solid/Check_green";

interface TableComparePlanProps {
    handleModalPlan: (value: string) => void;
    servicePlan: ServicePlanInterface | undefined;
    callToActionText?: string;
    importantIdx: number;
};


export const TableComparePlan: React.FC<TableComparePlanProps> = ({
    handleModalPlan,
    servicePlan,
    importantIdx = 0,
    callToActionText = 'Comprar'
}) => {


    return (
        <CardContent className="tableComparePlan_contain">
            <div className="tableComparePlan__content">

                <div className="tableComparePlan_header">
                    {/** Cabecera */}
                    <div className="tableComparePlan__content_header">
                        <div className="tableComparePlan___row">
                            <div className="tableComparePlan___row_min"></div>
                            <div className="tableComparePlan___row__right">
                                {servicePlan?.mostPopular.values.map((item, idb) => (
                                    <>
                                        {item.value ? <TagButton onClick={()=>{}} content='Más popular' color="green-2" key={idb}/> : <div></div>}
                                    </>
                                ))}
                            </div>
                        </div>

                        <div className="tableComparePlan___row">
                            <div className="tableComparePlan___row_min tableComparePlan" style={{fontFamily: 'Faktum-Medium'}}>{servicePlan?.plans.title}</div>

                            <div className="tableComparePlan___row__right">
                                {servicePlan?.plans.values.map((item, idx) => (
                                    <div className={`tableComparePlan__index ${idx === importantIdx ? 'tableComparePlan-important_title' : 'tableComparePlan-paragraph '}`} key={idx}>{item.value}</div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/** Precio */}
                    <div className="tableComparePlan___row">
                        <div className="tableComparePlan___row_min tableComparePlan" style={{fontFamily: 'Faktum-Medium'}}>{servicePlan?.price.title}</div>
                        
                        <div className="tableComparePlan___row__right">
                            {servicePlan?.price.values.map((item, idy) => (
                                <div className={`tableComparePlan__index ${idy === importantIdx ? 'tableComparePlan-important' : 'tableComparePlan-small'}`} key={idy}>{item.value} 
                                    <div className={`tableComparePlan__index ${idy === importantIdx ? 'tableComparePlan-important-simbol' : 'tableComparePlan-simbol-small'}`}>{item.currency}</div>
                                </div>
                                
                            ))}
                        </div>
                    </div>
                </div>

                {/** Detail */}
                <div className="tableComparePlan__column">
                    <div className="tableComparePlan___row tableComparePlan" style={{fontFamily: 'Faktum-Medium'}}>Qué incluye</div>
                    <div className="tableComparePlan__column__min">
                        {servicePlan?.detail.map((row, idz) => (
                            <div className="tableComparePlan___row tableComparePlan__bottom" key={idz}>
                                <div className="tableComparePlan___row_min paragraph" style={{color: "#333"}}>{row.title}</div>
                                    <div className="tableComparePlan___row__right">
                                        { row.values.map((item, idy) => (
                                            <div key={idy} className="tableComparePlan__index paragraph">
                                                {item.type === "check" ?  (item.value === true ? <Check_green/> : <Close_red/>  ) : (item.type === 'string' ? item.value : (item.type === 'price' ? item.currencySymbol+' '+item.value : '')) }
                                            </div>
                                        ))}
                                    </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/** Time delivery */}
                <div className="tableComparePlan___row">
                    <div className="tableComparePlan___row_min tableComparePlan" style={{fontFamily: 'Faktum-Medium'}}>{servicePlan?.deliveryTime.title}</div>
                    
                    <div className="tableComparePlan___row__right">
                        {servicePlan?.deliveryTime.values.map((item, idd) => (
                            <div className={`tableComparePlan__index ${idd === importantIdx ? 'tableComparePlan-important-paragraph' : 'tableComparePlan-paragraph' }`} key={idd}>{item.value + " días"}</div>
                        ))}
                    </div>
                </div>

                {/** Revision */}
                <div className="tableComparePlan___row">
                    <div className="tableComparePlan___row_min tableComparePlan" style={{fontFamily: 'Faktum-Medium'}}>{servicePlan?.numRevision.title}</div>
                    
                    <div className="tableComparePlan___row__right">
                        {servicePlan?.numRevision.values.map((item, idr) => (
                            <div className={`tableComparePlan__index ${idr === importantIdx? 'tableComparePlan-important-paragraph' : 'tableComparePlan-paragraph' }`} key={idr}>{item.value}</div>
                        ))}
                    </div>
                </div>

                {/** Button */}
                <div className="tableComparePlan___row">
                    <div className="tableComparePlan___row_min"></div>
                    <div className="tableComparePlan___row__right">
                        {servicePlan?.price.values.map((item, idr) => (                        
                            <div className="tableComparePlan__index">
                                <Button 
                                    key={idr}
                                    color="black-75"
                                    onClick={()=>handleModalPlan(idr.toString())}
                                    content={callToActionText}/> 
                            </div>
                           
                        ))}
                    </div>
                </div>
            </div>
        </CardContent>
    );
};
