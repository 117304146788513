import React from "react";

// importando componente
import { Button, ButtonIcon, TextField } from "..";
import { Modal } from "../Modal/Modal/Modal";
import { FooterSignUp } from "./FooterSignUp";


// importando iconos
import { ErrorInterface } from "../../interfaces/ErrorInterface";


// importando iconos
import { GoogleIcon } from "../../icons/solid/googleIcon";
import { FacebookIcon } from "../../icons/solid/facebookIcon";
import { AppleIcon } from "../../icons/solid/appleIcon";

import authService from "../../Services/Auth.service";

import { ValidateField } from "../../utils/ValidateField";

import './SignUp.css';
import { toast } from "react-toastify";

interface SignUpProps {
    show: boolean;
    setShow: (e: boolean) => void;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    onClickRegister?: () => void;
    terms?: () => void;
    handleLogin?: () => void;
    errorEmail?: ErrorInterface;
    setErrorEmailRegister: React.Dispatch<React.SetStateAction<ErrorInterface>>;
    setErrorEmail?: React.Dispatch<React.SetStateAction<ErrorInterface>>;
    onClickGoogle?: () => void;
    onClickFacebook?: () => void;
}

export const SignUp: React.FC<SignUpProps> = ({
    show,
    setShow,
    email,
    setEmail,
    onClickRegister=()=>{},
    handleLogin=()=>{},
    terms=()=>{},
    errorEmail={
        status: false,
        message: ''
    },
    setErrorEmail=()=>{},
    onClickGoogle,
    onClickFacebook,
    setErrorEmailRegister
}) => {

    const setErrorText = (content: string) => {
        setErrorEmailRegister({
            status: true,
            message: content
        })
    }
    
    const handleNextClick = async () => {
        if(!ValidateField(email, 'email')){
            try {
                const response: any = await authService.CheckEmailRegister(email);
                !response ?  onClickRegister() : setErrorText("Correo electrónico registrado");
            } catch (error) {
                console.log("🚀 ~ file: SignUp.tsx:70 ~ handleNextClick ~ error:", error)
                toast.error("Hubo un error al verificar el correo electrónico, por favor intentelo en unos minutos");
           }
        } else {
          setErrorText('Ingrese correo valido');
        }
    }


    return (
        <Modal title="Únete a Arkabia" show={show} setShow={setShow}  >
            <div role='signUpRole' className="signUp-container">
                <ButtonIcon 
                    Icon={<GoogleIcon style={{width: '30px', height: '30px'}} />}
                    content="Gmail"
                    place="left"
                    size='large'
                    color='white'
                    style={{color: 'var(--gray-1)', background: 'white'}}
                    onClick={onClickGoogle}
                />
                 {/* <ButtonIcon 
                    Icon={<FacebookIcon style={{width: '30px', height: '30px'}} />}
                    content="Continuar con Facebook"
                    place="left"
                    size='large'
                    color='white'
                    style={{color: 'var(--gray-1)', background: 'white'}}
                    onClick={onClickFacebook}
                />
                 <ButtonIcon 
                    Icon={<AppleIcon style={{width: '30px', height: '30px'}} />}
                    content="Continuar con Apple"
                    place="left"
                    size='large'
                    color='white'
                    style={{color: 'var(--gray-1)', background: 'white'}}
                /> */}
                <hr className="signUp-divider" />
                <TextField
                    title='Email'
                    placeholder=" "
                    value={email}
                    onChange={(e:any) =>setEmail(e.target.value)}
                    error={errorEmail}
                    setError={setErrorEmail}
                />
                <Button 
                    content="Registrarme"
                    size="large"
                    color='black-75'
                    onClick={()=>handleNextClick()}
                />
                <FooterSignUp 
                    handleLogin={()=>handleLogin()}
                    terms={()=>terms()}
                />
            </div>
        </Modal>
    )
}