import React, { SetStateAction, useState } from "react";

/** importando componentes */
import { Button, Dropdown } from "../..";
import { Checkbox } from "../../Checkbox/Checkbox";
import { ContainerInfo } from "./ContainerInfo/ContainerInfo";
import { ContainerRow } from "./ContainerRow/ContainerRow";

/** importando tipos */
import { FCLInterface } from "../../../interfaces/FCL";

/** importando estilos */
import "./FCLCD.css";
import { ContainerInterface } from "../../../interfaces/Container";
import {
  ListContainersFcl,
  ListContainersFclMobile,
} from "../../../Consts/ContainersFcl";
import { ErrorInterface } from "../../../interfaces/ErrorInterface";
import { Fcl_containers } from "../../../interfaces/Fcl_containers";

interface FCLCDProps {
  containerList: Fcl_containers[];
  containerArray: FCLInterface[]; // array de contenedores agregador
  setContainerArray: React.Dispatch<SetStateAction<FCLInterface[]>>;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onClickContainerDropdown?: () => void;
  fclError: ErrorInterface | undefined;
}

export const FCLCD: React.FC<FCLCDProps> = ({
  containerList,
  containerArray = [],
  setContainerArray,
  show,
  setShow,
  onClickContainerDropdown = () => {},
  fclError,
}) => {
  const [isFirst, setIsFirst] = useState<boolean>(true);

  // state para manejar el array de contenedores
  const [containersArr, setContainersArr] = useState<any[]>(containerArray);

  // state para manejar los inputs
  const [containerType, setContainerType] = useState("");
  const [containerSize, setContainerSize] = useState("");
  const [containerQty, setContainerQty] = useState("");

  const [sizeContainer, setSizeContainer] = useState<any[]>([]);
  const [containerDetail, setContainerDetail] = useState<ContainerInterface>({
    label: "",
    externalMeasures: "",
    internalMeasures: "",
    minDescription: "",
    ratedCapacity: "",
    urlImg: "",
  });

  // metodo para limpiar los campos
  const clearFields = () => {
    setContainerType("");
    setContainerSize("");
    setContainerQty("");
    setContainerDetail({
      label: "",
      externalMeasures: "",
      internalMeasures: "",
      minDescription: "",
      ratedCapacity: "",
      urlImg: "",
    });
  };

  const handleAddContainer = () => {
    if (isFirst) {
      setIsFirst(false);
    }
    //TODO: CHNAGE Image URL
    if (!containerType || !containerSize || !containerQty) return;
    setContainerArray([
      ...containersArr,
      {
        imgUrl: containerDetail.urlImg,
        type: containerType,
        size: containerSize,
        quantity: containerQty,
      },
    ]);

    setContainersArr((p) => [
      ...p,
      {
        imgUrl: containerDetail.urlImg,
        type: containerType,
        size: containerSize,
        quantity: containerQty,
      },
    ]);
    clearFields();
  };

  const handleTypeChange = (e: string) => {
    setContainerSize("");
    setSizeContainer([]);
    setContainerDetail({
      label: "",
      externalMeasures: "",
      internalMeasures: "",
      minDescription: "",
      ratedCapacity: "",
      urlImg: "",
    });
    setContainerType(e);
    const container = containerList.find((p) => p.type === e);
    if (container) {
      setSizeContainer(container.sizes);
      setContainerDetail({
        externalMeasures: "-",
        internalMeasures: "-",
        label: "-",
        minDescription: "- ",
        ratedCapacity: "-",
        urlImg: container.urlImg,
      });
    }
    onClickContainerDropdown();
  };
 
  const handleSizeChange = (e: string) => {
    setContainerSize(e);
    setContainerDetail(sizeContainer.find((p) => p.label === e));
  };


  return (
    <section id="fclcd_" role="fclcdRole" className="fclcd">
      <div className="fclcd-container">
        <Checkbox onClick={()=>onClickContainerDropdown()} checked={show} setChecked={setShow} />
        <div className="fclcd-title">
          <h2 className="paragraph">Contenedor completo</h2>
          <p className="paragraph-header">FCL</p>
        </div>
      </div>
      {show && (
        <>
          <div className="fclcd-addContainer">
            {/* <p className="paragraph-header">
              {window.innerWidth <= 600
                ? "Elige contenedor(es)"
                : "Elige un contenedor"}
            </p> */}
            <div className="fclcd-container-data">
              <div className="fclcd-container-data_row">
                <Dropdown
                  // onClick={onClickContainerDropdown}
                  title="Tipo de contenedor"
                  elements={containerList.map((c) => c.type)}
                  value={containerType.length > 0 ? containerType : ""}
                  setValue={(e: any) => handleTypeChange(e)}
                  roleText="containerTypeRole"
                  className="fclcd-addContainer--select fclcd-addContainer--select-bottom"
                />
                
              </div>
              <div className="fclcd-container-info-mobile">
                {containerDetail && containerDetail.label.length > 0 && (
                  <ContainerInfo
                    constainerDetail={containerDetail}
                    typeContainer={containerType}
                  />
                )}
              </div>
              <div className="fclcd-container-data_row_">
                <Dropdown
                  onClick={
                    containerType.length > 0
                      ? onClickContainerDropdown
                      : () => {}
                  }
                  title="Tamaño"
                  elements={sizeContainer.map((s) => s.label)}
                  value={containerSize}
                  setValue={(e: any) => handleSizeChange(e)}
                  roleText="containerSizeElementsRole"
                />
                <Dropdown
                  onClick={
                    containerType.length > 0 && containerSize.length > 0
                      ? onClickContainerDropdown
                      : () => {}
                  }
                  title="Cantidad"
                  elements={
                    window.innerWidth <= 600
                      ? ListContainersFclMobile
                      : ListContainersFcl
                  }
                  value={containerQty}
                  setValue={setContainerQty}
                  roleText="containerQtyRole"
                />
              </div>

              {containerType.length > 0 &&
                containerSize.length > 0 &&
                containerQty.length > 0 && (
                  <Button
                    content="Añadir"
                    size="normal"
                    color="black-75"
                    onClick={() => handleAddContainer()}
                  />
                )}
            </div>
          </div>
          {containerDetail && containerDetail.label.length > 0 && (
            <div className="fclcd-container-info">
              <ContainerInfo
                constainerDetail={containerDetail}
                typeContainer={containerType}
                showButtonAdd={true}
                handleButtonAdd={() => handleAddContainer()}
              />
            </div>
          )}
          {containerArray.length> 0 && <ContainerRow
            containersArr={containersArr}
            setContainerArr={setContainersArr}
            setContainer={setContainerArray}
            isFirst={isFirst}
          />}
        </>
      )}
    {fclError && fclError.status && <div className="smalltext-header text-error">{fclError.message}</div>}
    </section>
  );
};
