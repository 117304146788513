import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type Close_redProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Close_red: React.FC<Close_redProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/close_red.svg"} alt="" />
    </div>
  );
};