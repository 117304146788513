import React, { useState } from "react";

/** importando estilos */
import './CardToken.css';

interface CardTokenProps {
    title: string, // variable titulo
    price: string, // variable precio
    subTitle: string, // variable subtitulo
    date: string, // variable dia

    offer?:boolean, // se muestra el monto de lo que esta ahorrando, siempre y cuando sea true
    sale: string, // variable precio
    
    handleClick: ()=>void;
    active: boolean,
}

export const CardToken: React.FC<CardTokenProps> = ({
    title,
    price,
    subTitle,
    date,

    offer,
    sale,

    handleClick,
    active,
}) => {

    

    return (
        <div role='cardTokenRole' className={active ? "card-token-main__active" : "card-token-main"} onClick={handleClick}>
            <div className="card-token__column">
                <div className="card-token-main__header">
                    <div className={active ? "card-token_text small-bold text____title" : "small-bold text____title"}>{title}</div>
                    <div className={active ? "card-token_text paragraph-header card-token_text__responsive" : "paragraph-header card-token_text__responsive"}>{"$"+" "+price}</div>
                </div>
                <div className={active ? "card-token_text smalltext text__font" : "smalltext text__font"}>{subTitle}</div>
            </div>
           
            <div className="card-token-price-responsive">
                <div className={active ? "card-token_text paragraph-header card-token_text__" : "paragraph-header card-token_text__"}>{"$"+" "+price}</div>
                { offer && <div className={active ? "card-token_text smalltext-header card-token_text__offer" : "smalltext-header card-token_text__offer"}>Ahorra {"$"+" "+ sale }</div>}
            </div>

            <div className={active ? "card-token_text tinytext card-token__row text__footer": "tinytext card-token__row text__footer"}>
                <b>Válido hasta: </b> {"  " + date}
            </div>
        </div>
    )
}
