import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

import { GetUserList, GetUserByFirebaseId, GetUserById } from "../Services/User.service";

export const GetUserByUID = createAsyncThunk(
    'user/getUserByUID',
    async (uid: string, thunkAPI: any) => {
        try {
            const response = await GetUserByFirebaseId(uid);
            if (response) {
                // console.log("user/getByUID", response);
                let tmp = response;
                if(tmp.password) {
                    delete tmp.password;
                }
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return tmp;
            }
        } catch (error) {
            console.log("slice/auth/register => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error, code: error }));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const GetUser = createAsyncThunk(
    'user/getById',
    async (id: string, thunkAPI: any) => {
        try {
            const response = await GetUserById(id);
            if(response) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response;
            }
        } catch (error:any) {
            console.log("slice/user/getById => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const GetAllUser = createAsyncThunk(
    'user/getUserType',
    async (_, thunkAPI: any) => {
        try {
            const response = await GetUserList();

            if(response.length>0) {
                thunkAPI.dispatch(setError({status: false, message: '', code: ''}))
                return response;
            }
        } catch (error: any) {
            console.log("slice/userType/getUserType => error:", error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code:error.code }));
            return thunkAPI.rejectWithValue();
        }
    }
)


const initialState = {
    userDetail: {} || undefined,
    userId:[], 
    userList: [],
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
    },
    extraReducers: {
        [GetUserByUID.fulfilled.toString()]: (state, action) => {
            state.userDetail = action.payload;
        },
        [GetUserByUID.rejected.toString()]: (state, action) => {
            state.userDetail = undefined;
        },
        [GetUser.fulfilled.toString()]: (state, action) => {
            state.userDetail = action.payload;
        },
        [GetUser.rejected.toString()]: (state, action) => {
            state.userId = [];
        },
        [GetAllUser.fulfilled.toString()] : (state, action) => {
            state.userList = action.payload
        },
        [GetAllUser.rejected.toString()] : (state, action) => {
            state.userList = []
        }
    }
});

const { reducer } = userSlice;
export default reducer;