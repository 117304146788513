import React, { useCallback, useEffect, useRef, useState } from "react";

/** importando componentes */
import { Dropdown } from "..";
import { TextField } from "..";
import { InputElement } from "../InputElement/InputElement";

/** importando iconos */
import { InfoCircleGray } from "../../icons/outline/InfoCircleGray";

// importando interfaces
import { ErrorInterface } from "../../interfaces/ErrorInterface";
import { ConverDecimal, ConverMoneyFormat } from "../../utils/ConvertDecimal";
import { TextFieldMoney } from "../TextFieldMoney/TextFieldMoney";

/** importando estilo */
import './MerchandiseValue.css';
import { Hover } from "../Hover/Hover";
import { CurrencyConsts, CurrencyConstsMobile } from "../../Consts/CurrencyConsts";
import { IncotermText } from "../../Consts/IncotermText";
import { InfoCircleWhite } from "../../icons/outline/InfoCircle_white";
import debounce from "lodash.debounce";
import { Page } from "../../icons/solid/Page";
import { incotermTypeSea } from "../../@types/Incoterms";
import { incotermAereo, incotermAereoCourier, incotermSea } from "../../Consts/IncotermList";
import { TypeTransport } from "../../@types/TypeTransport";
import { Insurance } from "../Insurance/Insurance";
import { RequestQuoteInterface } from "../../interfaces/RequestQuote";
import { CustomsInterface } from "../../interfaces/CustomsInterface";


interface MerchandiseValueProps {
    intercom: string;
    setIntercom: React.Dispatch<React.SetStateAction<string>>;
    merchandise: number | undefined;
    setMerchandise: React.Dispatch<React.SetStateAction<string>>;
    coin: string;
    setCoin: React.Dispatch<React.SetStateAction<string>>;
    purchaseLink?: string
    setPurchaseLink?: React.Dispatch<React.SetStateAction<string>>;
    error?: {
        intercom: ErrorInterface,
        value: ErrorInterface,
        coin: ErrorInterface,
        purchaseLink?: ErrorInterface
    },
    typeTransport: TypeTransport,
    serviceTypeId: string,
    setRequestQuote: React.Dispatch<React.SetStateAction<RequestQuoteInterface>>;
    customs: CustomsInterface;
    // exwDirection?: string;
    // setExwDirection?: React.Dispatch<React.SetStateAction<string>>;
}

export const MerchandiseValue: React.FC<MerchandiseValueProps> = ({
    intercom='FOB',
    setIntercom,
    merchandise,
    setMerchandise,
    coin,
    setCoin,
    purchaseLink,
    setPurchaseLink = () => {},
    error={
        intercom: {
            status: false,
            message: ''
        },
        value: {
            status: false,
            message: ''
        },
        coin: {
            status: false,
            message: ''
        },
        purchaseLink: {
            status: false,
            message: ''
        },
        insurance: {
            status: false,
            message: ''
        },
    },
    typeTransport,
    serviceTypeId,
    setRequestQuote,
    customs
    // exwDirection='',
    // setExwDirection=()=>{},
}) => {
    
    const ref = React.useRef<HTMLDivElement>(null);
    // ref from parent div
    const refDiv = useRef<HTMLDivElement>(null);
    const [isHover, setIsHover] = useState(false);
    const [cancelMouseOut, setCancelMouseOut] = useState<boolean>(false);

  
    const handleMouseOver = () => {
        setIsHover(true);
    };
    
     const handleMouseOut = () => {
        setIsHover(false);
    };

    const handleClick = () => {
        setCancelMouseOut(true);
        setIsHover(true);
    }

    // handle insurence change
    const handleInsuranceChange = (value: boolean) => {
        setRequestQuote((prev)=> ({
            ...prev,
            customs: {
                ...prev.customs,
                insurance: value
            }
        }));
        executeScroll();
    }

    const executeScroll = useCallback(
        debounce((toPercent: number = 1.5)=>{
            if (refDiv) {
                refDiv.current?.scrollTo({
                    top: refDiv.current.scrollHeight/toPercent,
                    behavior: 'smooth',
                });
            }
        }, 500),[]);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
          if(cancelMouseOut && ref.current && !ref.current.contains(e.target)) {
            setCancelMouseOut(false);
            setIsHover(false);
          }
        }
    
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    
      }, [cancelMouseOut]);

    return (
        <div className={`merchandiseValue`}>
            <div className={"paragraph-header merchandiseValue-row__"}>
                <Page/>
                Proforma comercial
            </div>
            <div className="merchandiseValue-container" role='merchandiseValue'>
                <div className="merchandiseValue-container_" >
                    <div className="merchandiseValue-value">
                        <InputElement
                            value={merchandise}
                            name="Importe total"
                            placeholder="Importe total"
                            onChange={(e: any) => setMerchandise(e)}
                            error={error.value} 
                        />
                        <Dropdown 
                            title='Moneda'
                            elements={window.innerWidth <= 600 ? CurrencyConstsMobile : CurrencyConstsMobile }
                            setValue={(e)=>{setCoin(e)} }
                            value={coin.length>0 ? coin : window.innerWidth <=600 ? CurrencyConstsMobile[0] : CurrencyConstsMobile[0]}
                            error={error.coin}       
                        />
                    </div>
                    <div className="merchandiseValue-incoterm">
                       {serviceTypeId === 'agenciamiento-de-aduana' && <Dropdown 
                            title='Incoterm'
                            elements={typeTransport === "sea" ? incotermSea : ( typeTransport === "air" ? (serviceTypeId != 'agenciamiento-de-aduana' ? incotermAereoCourier : incotermAereo) : []) }
                            setValue={(e)=> {setIntercom(e)}}
                            value={intercom === null ? '' : (intercom.length>0 ? intercom : '')}
                            roleText='dropdown-merchandiseValue'
                            error={error.intercom}
                        />}
                    {/* <div ref={ref} className={serviceTypeId === 'agenciamiento-de-aduana' ? "agencia" : "merchandiseValue-courier"}>
                        {serviceTypeId === 'agenciamiento-de-aduana' ? 
                            <Hover 
                                show={isHover}
                                title={intercom !== '' ? (IncotermText.find(i => i.title === intercom))?.title : 'Incoterms'}
                                content={intercom !== '' ? (IncotermText.find(i => i.title === intercom))?.description : 'Son términos comerciales utilizados para resumir las obligaciones y responsabilidad de cada parte en una compra o venta internacional.'}
                                setShow={setIsHover}
                            >
                                <InfoCircleGray
                                    className="merchandiseValue--icon"
                                    onMouseOver={handleMouseOver} 
                                    onMouseOut={!cancelMouseOut ? handleMouseOut : ()=>{}}
                                    onClick={()=>window.innerHeight>720 ? handleClick() : {}}
                                />
                            </Hover> : 
                            <Hover 
                                show={isHover}
                                title="Link de compra"
                                content="Enlace web para ver el producto que quieres comprar."
                                setShow={setIsHover}
                            >
                                <InfoCircleGray
                                    className="merchandiseValue--icon"
                                    onMouseOver={handleMouseOver} 
                                    onMouseOut={!cancelMouseOut ? handleMouseOut : ()=>{}}
                                    onClick={()=>window.innerHeight>720 ? handleClick() : {}}
                                />
                            </Hover> 
                        }
                    </div> */}
                        {serviceTypeId === 'courier' && <div className="merchandiseValue-link">
                            <TextField 
                                title="Link de compra"
                                placeholder=" "
                                value={purchaseLink}
                                onChange={(e) => setPurchaseLink(e.target.value)}
                                error={error.purchaseLink}
                            />
                        </div>}
                        <div className="merchandiseValue-insurance">
                            {serviceTypeId != 'courier' && <Insurance 
                                secure={customs.insurance} 
                                setSecure={(e: any)=>handleInsuranceChange(e)}
                                error={error.intercom}
                            />}
                        </div>
                    </div>
                </div>         
                {/* <div className="merchandiseValue-row"  >
                   
                </div> */}
            </div>
            <div className="merchandiseValue-insurance_">
                {serviceTypeId != 'courier' && <Insurance 
                    secure={customs.insurance} 
                    setSecure={(e: any)=>handleInsuranceChange(e)}
                    error={error.intercom}
                />}
            </div>
            {/* {intercom === 'EXW' && 
            <TextField 
                title="Dirección de recojo de la mercancía" 
                placeholder=" " 
                value={exwDirection} 
                onChange={(e) => setExwDirection(e.target.value)}
            />} */}
        </div>
    )
}