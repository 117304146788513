import { DB, Functions } from "../firebaseConfig/FirebaseConfig";
import { collection, doc, getDocs, getDoc, where, query, orderBy, updateDoc, onSnapshot } from "firebase/firestore"; 
import { ChatInterface, CreateChatInterface } from "../interfaces/ChatInterface";
import { httpsCallable } from "firebase/functions";

// get all chats by id in real time
const GetChatsByUserId = async(userId:string, cb: (e: any)=>void = ()=> {}) => {
    const q = query(collection(DB, "chat"), orderBy("updatedAt", "desc"));

    onSnapshot(q, (querySnapshot) => {
        let data:any[] = [];
        querySnapshot.forEach((doc) => {
            data.push({id: doc.id, ...doc.data()});
        });

        let chats:ChatInterface[] = [];
        if(data.length>0) {
            chats = data.filter((d)=>(d.members.find((m:any)=>m.userId === userId)));
        }

        cb(chats);
    });
}

// get number chats un read by id in rela time
const GetNumChatsByUserId = async(userId: string, cb: (e: any)=>void = ()=> {}) => {
    // console.log("🚀 ~ file: Chat.service.ts:27 ~ GetNumChatsByUserId ~ userId:", userId)
    // const q = query(collection(DB, "chat"));
    GetChatsByUserId(userId, (chats) => {
        // console.log("🚀 ~ file: Chat.service.ts:30 ~ GetChatsByUserId ~ chats:", chats)
        // console.log("🚀 ~ file: Chat.service.ts:29 ~ GetChatsByUserId ~ chats:", chats)
        let num = 0;
        chats.forEach((c: any) => {
            // console.log(c.members.find((m: any) => m.userId === userId).numMessagesUnread);
            num = num + c.members.find((m: any) => m.userId === userId).numMessagesUnread;
        });
        // let num = chats.reduce((a:any, b:any)=> b.members.re, 0);
        cb(num);
    });
}

// method to get chat by id in real time
const GetChatById = async (id: string, cb: (e: any)=>void = ()=> {}) => {
    onSnapshot(doc(DB, "chat", id), (doc) => {
        cb({id: doc.id, ...doc.data()});
    });
}

const GetChatId = async (id: string,) => {
    const response = await getDoc(doc(DB, 'chat', id));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()})
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}


// check if exist chat between user and company
const CheckChatByCompanyAndUser = async(companyId:string, userI: string) => {
    // console.log("🚀 ~ file: Chat.service.ts:35", companyId, userI)
    const q = query(collection(DB, "chat"), where("typeChat", "==", 'individual'),
    where("userIdGenerated", "==", userI));
    const response = await getDocs(q);
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data.find((d)=>(d.members.find((m:any)=>m.companyId === companyId)));
}

// check if user have a chat
// const CheckChatByUserId = async(userId: string) => {
//     const q = query(collection(DB, "chat"), where("typeChat", "==", 'individual'));
//     const response = await getDocs(q);
//     let data:any[] = [];
//     if(!response.empty) {
//         data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
//     }
//     return data.find((d)=>(d.members.find((m:any)=>m.userId === userId)));
// }

// create chat
const CreateNewChat = async (data: CreateChatInterface) => {
    const createChat = httpsCallable(Functions, 'createChat');
    return createChat(data)
    .then((response)=> {
        return response.data;
    })
    .catch((error)=> {
        console.log("🚀 ~ file: Chat.service.ts:27 ~ CreateNewChat ~ error:", error)
        return error;
    });
}

// update chat by id
const UpdateChat = async(chatId: string, data: any) => {
    // console.log("🚀 ~ file: RequestQuote.service.ts ~ line 70 ~ UpdateRequestQuote ~ requestQuote", requestQuote)
    try {
        if(chatId.length>0) {
            const requestRef = doc(DB, 'chat', chatId);
            const response =  await updateDoc(requestRef, {...data});
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}


export { 
    GetChatById,
    GetChatsByUserId,
    CreateNewChat,
    CheckChatByCompanyAndUser,
    // CheckChatByUserId,
    UpdateChat,
    GetNumChatsByUserId,
    GetChatId
}