import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CreateOperation, GetAllOperation, GetOperationById, GetOperationByUser } from "../Services/Operation.service";

import { setError } from "./Error";

import { OperationInterface } from "../interfaces/Operation";

export const GetOperation = createAsyncThunk(
    'operation/getOperation',
    async (_, thunkAPI: any) => {
        try {
            const response:any = await GetAllOperation();
            
            if(response.length>0) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
            }
        } catch (error:any) {
            console.log('slice/operation/getOperation => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
)

export const GetOperationByUserId = createAsyncThunk(
    'operation/getOperationByUserId',
    async (userId: string, thunkAPI: any) => {
        try {
            const response:any = await GetOperationByUser(userId);
            
            if(response.length>0) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
            }
        } catch (error:any) {
            console.log('slice/operation/getOperation => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
)



export const GetOperationDetail = createAsyncThunk(
    'operation/getById',
    async (id: string, thunkAPI: any) => {
        try {
            const response:any = await GetOperationById(id);
           /* console.log(response)*/
            if(response) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
            }
        } catch (error:any) {
            console.log('slice/operation/getOperation => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
)

const initialState = {
    operationList: [],
    operationDetail: {}
}

const operationSlice = createSlice({
    name: "operation",
    initialState,
    reducers: {},
    extraReducers: {
        [GetOperation.fulfilled.toString()]: (state, action) => {
            state.operationList = action.payload
        },
        [GetOperation.rejected.toString()]: (state, action) => {
            state.operationList = []
        },
        [GetOperationDetail.fulfilled.toString()]: (state, action) => {
            state.operationDetail = action.payload;
        },
        [GetOperationDetail.rejected.toString()]: (state, action) => {
            state.operationDetail = {}
        },
        [GetOperationByUserId.fulfilled.toString()]: (state, action) => {
            state.operationList = action.payload
        },
        [GetOperationByUserId.rejected.toString()]: (state, action) => {
            state.operationDetail = []
        } 
    }
})

const {reducer} = operationSlice;
export default reducer;