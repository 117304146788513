import React, { useEffect, useState, useCallback } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import debounce from "lodash.debounce";

import ReactGA from "react-ga4";

// importando componentes
import { ButtonIcon, OperationHeader } from "../../../components";
import { Button } from "../../../components/Button/Button";
import { Multimedia } from "../../../components/Multimedia/Multimedia";
import { Orders } from "../../../components/Orders/Orders";
import { QuotationSupplier } from "../../../components/Table/QuotationSupplier/QuotationSupplier";
import { ImportTimelineSupplier } from "../../../components/ImportTimelineSupplier/ImportTimelineSupplier";
import { Chat } from "../../../components/Chat/Chat";
import { MessageTopbar } from "../../../components/MessageTopBar/MessageTopbar";
import { ModalComplete } from "../../../components/Modal/ModalComplete/ModalComplete";
import { QuoteTransportData } from "../../../components/QuoteTransportData/QuoteTransportData";
import { QuoteCustomsData } from "../../../components/QuoteCustomsData/QuoteCustomsData";
import { Loader } from "../../../components/Loader/Loader";
import { QuoteProvider } from "../../../components/QuoteProvider/QuoteProvider";
import { ModalDecline } from "../../../components/Modal/ModalDecline/ModalDecline";
import { ModalReviewUser } from "../../../components/Modal/ModalReviewUser/ModalReviewUser";
import { ModalSendMessage } from "../../../components/Modal/ModalSendMessage/ModalSendMessage";
import { ButtonBadget } from "../../../components/ButtonBadget/ButtonBadget";
import { ModalSearchUser } from "../../../components/Modal/ModalSearchUser/ModalSearchUser";

// importando constante
import { PhasesImportData } from "../../../Consts/phasesImport";
import { CHAT, DETAIL, OPERATIONS } from "../../../Consts/BaseUrl";

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../../Store/Store";

// importando slice
import { GetQuotationById } from "../../../Slices/Quotation";
import { ImportTimelineStages } from "../../../@types/timelineStagesTypes";
import { GetCompany } from "../../../Slices/Company";
import { GetRequestQuoteDetail } from "../../../Slices/RequestQuote";
import { GetOperationDetail } from "../../../Slices/Operation";

// importando interfaces
import { RequestQuoteInterface } from "../../../interfaces/RequestQuote";
import { ChatInterface, CreateChatInterface } from "../../../interfaces/ChatInterface";
import {
  ImportCost,
  Taxes,
  Detail,
} from "../../../interfaces/Quotation";
import { QuotationDB } from "../../../interfaces/Quotation";
import { reviewUserInterface } from "../../../interfaces/reviewUser";
import { User } from "../../../interfaces/User";

// importando servicios
import {
  CreateQuotation,
  UpdateQuotation,
} from "../../../Services/Quotation.service";
import {
  ChangeProviderRequestQuote,
  UpdateRequestQuote,
  RejecteRequestQuote,
} from "../../../Services/RequestQuote.service";
import { GetAllReviewUserId } from "../../../Services/ReviewUser.service";
import { GetUserById } from "../../../Services/User.service";
import { CreateNewChat, GetChatById } from "../../../Services/Chat.service";
import { GetOperationById, UpdateOperation } from "../../../Services/Operation.service";

// importando utilidades
import { FormatDate } from "../../../utils/formatDate";

import "./Quote.css";
import { sendNotificationToUser } from "../../../Services/Notification.service";
import { sendProviderMessageNotification, sendUserMessageNotification } from "../../../Services/Whatsapp.service";
import { EditWhite } from "../../../icons/outline/EditWhite";
import { OperationInterface } from "../../../interfaces/Operation";
import { ModalProcessService } from "../../../components/Modal/ModalProcessService/ModalProcessService";
import { UserVerify } from "../../../components/UserVerify/UserVerify";


export const Quote: React.FC<{}> = () => {
  let navigation = useNavigate();
  let params = useParams();

  const dispatch = useDispatch<AppDispatch>();

  // redux state
  const { CompanyDetail } = useSelector((state: any) => state.company);
  const { quotationDetail } = useSelector((state: any) => state.quotation);
  const { userDetail } = useSelector((state: any) => state.user);

  const [operationDetail, setOperationDetail] = useState<OperationInterface>();

  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const [numPhase, setNumPhase] = useState<number>(1);

  const [showchat, setShowChat] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  // Modal para review user
  const [showReviewUser, setShowReviewUser] = useState(false);
  // Modal para rechazar cotizacion
  const [showModalDecline, setShowModalDecline] = useState(false);
  // Modal para enviar mensaje
  const [showModalSendMessage, setShowModalSendMessage] = useState(false);

  const [showModalComplete, setShowModalComplete] = useState(false);

  const [edit, setEdit] = useState(false);
  const [total, setTotal] = useState<string>("0.00");

  const [quoteStatus, setQuoteStatus] = useState<ImportTimelineStages>(1);


  // use state cliente user
  const [user, setUser] = useState<User>(); // cliente user to selected to send quotation generated from provider
  const [showSearchUser, setShowSearchUser] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reasonRejecting, setReasonRejecting] = useState<string>("");

  // useState search
  const [searchText, setSearchText] = useState<string>('');

  const [numRQ, setNumRQ] = useState<number>(0);

  const [reqQuote, setReqQuote] = useState<RequestQuoteInterface>({
    active: false,
    serviceId: "",
    serviceTypeLabel: "",
    status: "draft",
    title: "",
    user: {
      id: "",
      itNumber: 0,
      name: "",
      numOperations: 0,
      typeIt: "",
      phone: "",
      rating: 0,
    },
    statusProvider: "No leída",
    country: {
      alpha2Code: "",
      imgFlag: "",
      city: "",
    },
    companyName: "",
    numServiceRating: 0,
    numServiceComments: 0,
    transport: {
      origin: {
        city: "",
        country: "",
      },
      destination: {
        city: "",
        country: "",
      },
      typeContainer: {
        lcl: false,
        fcl: false,
        lclAir: false,
      },
      type: undefined,
    },
    customs: {
      coin: '',
      incoterm: '',
      insurance: undefined,
      regime: '',
      value: undefined,
      descriptionOperation: '',
    },
    observation: "",
  });

  const [quotation, setQuotation] = useState<QuotationDB>({
    importCost: {
      serviceOrigin: {
        expensiveOrigin: 0,
        insurance: 0,
        internationalTransport: 0,
        subTotal: 0,
      },
      serviceDestination: {
        storage: 0,
        // warehouseExpenses: 0,
        storageExpenses: 0,
        onCosts: 0,
        operationExpensive: 0,
        administrativeExpensive: 0,
        desadunaje: 0,
        approval: 0,
        localTransport: 0,
        tax: 0,
        subTotal: 0,
        commissionCustoms: 0,
        returnContain: 0,
        deconsolidation: 0,
        otherExpenses: 0,
      },
    },
    taxes: {
      adValorem: 0,
      igv: 0,
      ipm: 0,
      perception: 0,
      isc: 0,
      specificRight: 0,
      antidumpi: 0,
      surcharge: 0,
      subTotal: 0,
    },
    detail: {
      transitTime: "",
      storageBase: "",
      expiration: "",
    },
    observation: "",
    restriction: false,
    countryOrigin: "",
    destinationCountry: "",
    requestQuoteId: "",
    status: "draft",
    price: 0,
    companyId: "",
    companyName: "",
    companyImage: "",
    operationId: "",
    typeContainer: {
      fcl: false,
      lcl: false,
      lclAir: false,
    },
    sendEmail: "",
    quoteGenerated: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    currency: "USD",
  });

  const [reviewUser, setReviewUser] = useState<reviewUserInterface[]>([]);

  const [quotationId, setQuotatioId] = useState<string>("");

  const [currency, setCurrency] = useState<"USD" | "EUR">("USD");

  /** State de import cost */
  const [importCost, setImportCost] = useState<ImportCost>({
    serviceOrigin: {
      internationalTransport: 0,
      insurance: 0,
      expensiveOrigin: 0,
      subTotal: 0,
    },
    serviceDestination: {
      storage: 0,
      // warehouseExpenses: 0,
      storageExpenses: 0,
      onCosts: 0,
      operationExpensive: 0,
      administrativeExpensive: 0,
      desadunaje: 0,
      approval: 0,
      localTransport: 0,
      tax: 0,
      subTotal: 0,
      commissionCustoms: 0,
      returnContain: 0,
      deconsolidation: 0,
      otherExpenses: 0,
    },
  });


  /** State de valor de impuestos */
  const [taxesValue, setTaxesValue] = useState<Taxes>({
    adValorem: 0,
    igv: 0,
    ipm: 0,
    perception: 0,
    isc: 0,
    specificRight: 0,
    antidumpi: 0,
    surcharge: 0,
    subTotal: 0,
  });

  /** State de detalle */
  const [detail, setDetail] = useState<Detail>({
    transitTime: "",
    storageBase: "",
    expiration: "",
  });

  //chat
  const [chatdetail, setchatDetail] = useState<ChatInterface>();
  const [restriction, setRestriction] = useState<boolean>(false);
  const [showVerify, setShowVerify] = useState(false);

  const [observation, setObservation] = useState<string>("");

  const [sendEmail, setSendEmail] = useState<string>("");

  // use state controll modalQuote
  const [editRq, setEditRq] = useState<boolean>(false);

  const [timer, setTimer] = useState<any>(null);

  // handle click mesage
  const handleMessageClick = (chatId: string) => {
    setNumPhase(0);
    window.innerWidth <= 880 ? handleMessageMobileClick(chatId) : setShowChat(true);
  };

  // handle change status to show quotation
  const handleQuoteClick = () => {
    setQuoteStatus(2);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleMessageMobileClick = (id: string) => {
    navigation("/" + CHAT + "/" + DETAIL + "/" + id);
  };

  const handlePhaseClick = (phase: number) => {
    setNumPhase(phase);
    if (phase === 1) {
      setShowChat(false);
    }
  };

  // metodo para guardar
  const handleSave = () => {
    if (timer) {
      clearTimeout(timer);
    }
    let timeOut = setTimeout(() => {
      reqQuote.quoteGenerated && reqQuote.user.id.length === 0
        ? setShowSearchUser(true)
        : updateQuotationData(false);
    }, 500);
    setTimer(timeOut);
    setEdit(false);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // metodo para validar campos de la cotizacion
  const validateFiels = () => {
    if (
      importCost.serviceOrigin.expensiveOrigin >= 0 &&
      importCost.serviceOrigin.insurance >= 0 &&
      importCost.serviceOrigin.internationalTransport >= 0 &&
      importCost.serviceOrigin.subTotal >= 0 &&
      importCost.serviceDestination.commissionCustoms >= 0 &&
      importCost.serviceDestination.localTransport >= 0 &&
      importCost.serviceDestination.operationExpensive >= 0 &&
      importCost.serviceDestination.returnContain >= 0 &&
      importCost.serviceDestination.deconsolidation >= 0 &&
      importCost.serviceDestination.storage >= 0 &&
      importCost.serviceDestination.tax >= 0 &&
      importCost.serviceDestination.subTotal >= 0 &&
      taxesValue.adValorem >= 0 &&
      taxesValue.antidumpi >= 0 &&
      taxesValue.igv >= 0 &&
      taxesValue.isc >= 0 &&
      taxesValue.perception >= 0 &&
      taxesValue.surcharge >= 0 &&
      taxesValue.subTotal >= 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  // medoto para actualizar cotizacion
  const updateQuotationData = async (
    background: boolean = false,
    sendEmail_: string = "",
    userSelected: string = "",
  ) => {
    !background && setLoading(true);
    try {
      // tmp quote
      let quote: any = {
        companyId: reqQuote.companyId,
        companyName: reqQuote.companyName,
        companyImage: CompanyDetail.photoProfile,
        importCost: importCost,
        taxes: taxesValue,
        detail: detail,
        restriction: restriction,
        observation: observation,
        price: Number(total), // + Number(taxesValue.subTotal),
        countryOrigin: reqQuote.transport?.origin && reqQuote.transport?.origin.country + "",
        destinationCountry: reqQuote.transport?.destination && reqQuote.transport?.destination.country + "",
        status:
          background && reqQuote.statusProvider !== "Cotización enviada"
            ? "draft"
            : "send",
        quoteGenerated: reqQuote.quoteGenerated
          ? reqQuote.quoteGenerated
          : false,
        sendEmail: sendEmail_ ? sendEmail_ : "",
        updatedAt: new Date(),
        currency: currency,
      };
      // send to GA4 data
      reqQuote.statusProvider === "Cotización enviada" && GA4_begin_checkout(quote, reqQuote);
      // update quotation
      const responseQuotation = await UpdateQuotation(quotationId, quote);
      // update useState
      setQuotation({ id: quotationId, ...quote });

      // create chat if status is send and requestQuote have user
      if(quote.status === "send" && sendEmail_.length === 0 && userSelected.length>0 && !reqQuote.chatId){
        let tmpchat: CreateChatInterface = {
          generatedFrom: "quotation",
          receivedCompanyId: reqQuote.companyId ? reqQuote.companyId : "",
          recivedUserId: userSelected,
          typeChat: "group",
          userIdGenerated: userDetail.id,
          userTypeGenerated: userDetail.userType,
          operationName: reqQuote.title,
          operationType: "Importación",
          operationPhase: "quotation"
        }

        // create chat
        CreateNewChat(tmpchat).then(async (response: any) => {
          // console.log("🚀 ~ file: Quote.tsx:465 ~ response:", response);
          // add chatId to requestQuote
          await UpdateRequestQuote({id: req.id, chatId: response});
          // update useState reqQuote
          setReqQuote((prev)=> ({
            ...prev,
            chatId: response,
          }))
        }).catch((errorChat) => {
          console.log("🚀 ~ file: Quote.tsx:467 ~ errorChat:", errorChat);
        });
      }

      // tmp requesQuote
      let req: any = {
        id: reqQuote.id,
        quotationId: quotationId,
        statusProvider:
          background && reqQuote.statusProvider !== "Cotización enviada"
            ? "Leída"
            : "Cotización enviada",
        price: Number(total),
        usersProvider: [userDetail.id],
        updatedAt: new Date(),
      };

      // update requestQuote
      const responseRequestQuote = await UpdateRequestQuote(req);
      setReqQuote((prev: any) => ({
        ...prev,
        ...req,
      }));
      if (!background) {
        setEdit(!edit);
        setLoading(false);
        toast.success("¡Cotización actualizada!");
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: Quote.tsx:410 ~ updateQuotationData ~ error:",
        error
      );
    }
    setLoading(false);
  };

  // metodo para crear nueva cotizacion
  const createQuotationData = async (
    background: boolean = false,
    sendEmail_: string = ""
  ) => {
    !background && setLoading(true);
    // validando si ya esta creado la cotizacion
    if (
      quotationId.length > 0 &&
      quotationDetail &&
      quotationDetail.id.length > 0
    ) {
      return true;
    }

    try {
      let data: QuotationDB = {
        companyId: reqQuote.companyId,
        companyName: reqQuote.companyName,
        companyImage: CompanyDetail.photoProfile,
        number: 0,
        importCost: importCost,
        taxes: taxesValue,
        detail: detail,
        restriction: restriction,
        observation: observation,
        numServiceRating: reqQuote.numServiceRating,
        status:
          background &&
          (reqQuote.statusProvider === "No leída" ||
            reqQuote.statusProvider === "Leída")
            ? "draft"
            : "send",
        price: Number(total) + Number(taxesValue.subTotal),
        countryOrigin: reqQuote.transport?.origin && reqQuote.transport?.origin.country
          ? reqQuote.transport?.origin.country
          : "",
        destinationCountry: reqQuote.transport?.destination && reqQuote.transport?.destination.country
          ? reqQuote.transport?.destination.country
          : "",
        requestQuoteId: reqQuote.id + "",
        createdAt: new Date(),
        operationId: reqQuote.operationId + "",
        typeContainer: reqQuote.transport?.typeContainer,
        updatedAt: new Date(),
        quoteGenerated: reqQuote.quoteGenerated
          ? reqQuote.quoteGenerated
          : false,
        sendEmail: sendEmail_ ? sendEmail_ : "",
        currency: currency,
      };

      const response = await CreateQuotation(data); // guardar cotizacion
      if (response) {
        setQuotatioId(response.id);
        setQuotation({ id: response.id, ...data });
        let req: RequestQuoteInterface = {
          // actualizando solicitud de cotizacion en tmp
          ...reqQuote,
          quotationId: response.id,
          statusProvider:
            background && reqQuote.statusProvider !== "Cotización enviada"
              ? "Leída"
              : "Cotización enviada",
          price: data.price,
          updatedAt: new Date(),
        };
        setReqQuote(req);
        const responseReqQuote = await UpdateRequestQuote(req); // actualizando solicitud de cotizacion en firebase
        if (responseReqQuote) {
          !background &&
            window.scroll({
              top: 10,
              left: 100,
              behavior: "smooth",
            });

          if (!background) {
            setEdit(!edit);
            toast.success("Cotización enviada");
          }
        }
      }
      !background && setLoading(false);
    } catch (error) {
      console.log(
        "🚀 ~ file: Quote.tsx:495 ~ createQuotationData ~ error:",
        error
      );
      toast.error(
        "Hubo un error al crear la cotización, por favor intentelo en unos minutos"
      );
      setLoading(false);
    }
  };

  // metodo para guardar cotizacion
  const handleQuotationSupplier = async (
    background: boolean = false,
    sendEmail_: string = ""
  ) => {
    // console.log("🚀 ~ file: Quote.tsx:289 ~ handleQuotationSupplier ~ handleQuotationSupplier:", sendEmail_, background)
    if (validateFiels()) {
      !background && setLoading(true);
      if (quotationId) {
        // console.log("updateding...");
        if (
          quotation &&
          (quotation.importCost.serviceDestination.subTotal !==
            importCost.serviceDestination.subTotal ||
            quotation.taxes.subTotal !== taxesValue.subTotal ||
            quotation.importCost.serviceOrigin.subTotal !==
              importCost.serviceOrigin.subTotal ||
            quotation.detail.storageBase !== detail.storageBase ||
            quotation.detail.expiration !== detail.expiration ||
            quotation.observation !== observation)
        ) {
          // console.log("cambios detectados actualizar");
          await updateQuotationData(background, sendEmail_);
        }
        !background && setLoading(false);
      } else {
        // console.log("creando...");
        await createQuotationData(background, sendEmail_);
      }
      // setEdit( reqQuote.statusProvider === "Cotización enviada");
    } else {
      toast.error("Por favor complete todos los campos");
    }
  };

  const getUserById = async (userId: string) => {
    // console.log("getUserById", userId);
    if (!loading && userId && userId.length > 0) {
      setLoading(true);
      try {
        const response: any = await GetUserById(userId);
        setUser(response);
      } catch (error) {
          console.log("🚀 ~ getUserById ~ error:", error)
      }
      setLoading(false);
    }
  };

  const getReviewUser = async (userId: string) => {
    // console.log("getReviewUser");
    if (!loading) {
      setLoading(true);
      const response: any = await GetAllReviewUserId(userId);
      setReviewUser(response);
      setLoading(false);
    }
  };

  // methos to create a new chat
  const createChatToOperation = (rq: RequestQuoteInterface) => {
    setLoading(true);
    const chatData: CreateChatInterface = {
      generatedFrom: "requestQuote",
      typeChat: 'group',
      userIdGenerated: userDetail.id,
      userTypeGenerated: userDetail.userType[0],
      recivedUserId: rq.user.id,
      receivedCompanyId: rq.companyId ? rq.companyId : "",
      operationName: rq?.title,
      operationId: rq?.operationId,
      operationType: rq?.category,
      operationPhase: "requestQuote",
      documentIdPhase: rq.id,
    }
    // console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:426 ~ createChatToOperation ~ chatData:", chatData);
    CreateNewChat(chatData).then(async (response:any) => {
      if(response) {
        const respUpdate = await UpdateRequestQuote({
          id: rq.id,
          chatId: response,
          updatedAt: new Date(),
        });
        setReqQuote((prev) => ({
          ...prev,
          chatId: response.id,
        }));
        getChat(response);
      }
      setLoading(false);

      // await getRequestQuote(requestQuoteOp.id);
    }).catch((error) => {
      console.log("🚀 ~ file: Quote.tsx:717 ~ CreateNewChat ~ error:", error)
      setLoading(false);
      toast.error("Hubo un error al creat Chat, por favor intentelo mas tarde");
    });
  }

  // obteniendo solicitud de cotizacion de firestore
  const GetRequestQuote = (id: string) => {
    if (!loading) {
      // toast.info("Obteniendo datos...");
      setLoading(true);
      dispatch(GetRequestQuoteDetail(id))
        .unwrap()
        .then((response) => {
          if(response.usersProvider.includes(userDetail.id) ) {
            // setLoading(false);
            getData(response);
            getUserById(response.user.id);
            getReviewUser(response.user.id);
            GetOperationDetailById(response.operationId);
            if (response.quoteGenerated) {
              setQuoteStatus(2);
            }
            if(!response.quoteGenerated ) {
              response.chatId && response.chatId.length> 0 ? getChat(response.chatId) : createChatToOperation(response);
            }
            
            setReqQuote(response);
            setReasonRejecting(response.observation);
          } else {
            setLoading(false);
            toast.error("No tienes permisos para ver esta operación");
            navigation("/"+OPERATIONS);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const GetOperationDetailById = async (id: string) => {
    setLoading(true);
    try {
      const response: any = await GetOperationById(id);
      setOperationDetail(response);
    } catch (error) {
      console.log("🚀 ~ file: Quote.tsx:757 ~ GetOperationById ~ error:", error)
      setLoading(false);
    }
  }

  // obtiene detalle de la operacion segun id de FireStore
  const getOperationOfDetail = async (operationId: string) => {
    setLoading(false);
    if (!loading) {
      setLoading(true);
      dispatch(GetOperationDetail(operationId))
        .unwrap()
        .then((response) => {
            setOperationDetail(response);
            setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  // obteniendo compañia
  const getCompany = (id: string) => {
    setLoading(true);
    dispatch(GetCompany(id))
      .unwrap()
      .then((response) => {
        // console.log("🚀 ~ file: Quote.tsx ~ line 439 ~ .then ~ response", response)
        setLoading(false);
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Quote.tsx ~ line 443 ~ getCompany ~ error",
          error
        );
        setLoading(false);
      });
  };

  // obteniendo cotizacion
  const GetQuotationDetail = (quotationId: string) => {
    setLoading(true);
    dispatch(GetQuotationById(quotationId))
      .unwrap()
      .then((response) => {
        if (response) {
          setQuotation(response);
          setQuotatioId(response.id);
          setNumRQ(response.number);
          setImportCost(response.importCost);
          setTaxesValue(response.taxes);
          setDetail(response.detail);
          setRestriction(response.restriction);
          setTotal(
            (
             response.price
            ).toString()
          );
          getCompany(response.companyId);
          setObservation(response.observation);
          setCurrency(response.currency || "USD");
          setLoading(false);
        }
        if (isFirstTime) {
          setIsFirstTime(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Quote.tsx ~ line 276 ~ GetQuotationDetail ~ error",
          error
        );
        setLoading(false);
      });
  };

  // metodos para rechazar una solicitud de cotizacion
  // metodo para mostrar modal
  const handleClickDecline = () => {
    setShowModalDecline(true);
  };

  // metodo para cancelar
  const handleClickCancel = () => {
    setReasonRejecting("");
    setShowModalDecline(false);
  };

  // limpiar campos
  const clearTextField = () => {
    setImportCost({
      serviceOrigin: {
        internationalTransport: 0,
        insurance: 0,
        expensiveOrigin: 0,
        subTotal: 0,
      },
      serviceDestination: {
        storage: 0,
        // warehouseExpenses: 0,
        storageExpenses: 0,
        onCosts: 0,
        operationExpensive: 0,
        administrativeExpensive: 0,
        desadunaje: 0,
        approval: 0,
        localTransport: 0,
        tax: 0,
        subTotal: 0,
        commissionCustoms: 0,
        returnContain: 0,
        deconsolidation: 0,
        otherExpenses: 0,
      },
    });

    setTaxesValue({
      adValorem: 0,
      igv: 0,
      ipm: 0,
      perception: 0,
      isc: 0,
      specificRight: 0,
      antidumpi: 0,
      surcharge: 0,
      subTotal: 0,
    });
    setDetail({
      transitTime: "",
      storageBase: "",
      expiration: "",
    });
    setRestriction(false);
    setObservation("");
    setCurrency("USD");
  };

  const handleCancel = () => {
    clearTextField();
    GetQuotationDetail(reqQuote.quotationId ? reqQuote.quotationId : "");
    setEdit(true);
  };

  // handle seach text change
  const handleSeachChange = useCallback(
    debounce((text)=> {
      setSearchText(text);
    }, 500), [searchText]);

  // method to auto sava quotation
  const handleAutoSave = () => {
    if (timer) {
      clearTimeout(timer);
    }
    if (!loading && !isFirstTime) {
      let timeOut = setTimeout(() => {
        handleQuotationSupplier(true);
      }, 2000);
      setTimer(timeOut);
    }
  };

  // method to get quotation, change local status and change statatus provider
  const getData = (rq: RequestQuoteInterface) => {
    // getttin quotation from firestore
    if (rq.quotationId && rq.quotationId.length > 0) {
      // toast.info("Obteniendo datos...");
      setQuoteStatus(2);
      GetQuotationDetail(rq.quotationId ? rq.quotationId : "");
      setEdit(
        !(rq.statusProvider === "Leída" || rq.statusProvider === "No leída")
      );
    }

    if (rq.statusProvider === "No leída" && !rq.quoteGenerated) {
      if (rq.id) {
        toast.info("Cotizacion marcada como leída");
        setLoading(true);
        ChangeProviderRequestQuote(rq.id, "Leída")
          .then((reponse) => {
            // console.log("🚀 ~ file: OrderProvider.tsx ~ line 273 ~ .then ~ reponse", reponse);
            setLoading(false);
            setReqQuote({ ...rq, statusProvider: "Leída" });
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
  };

  // method to send invitation and quotation to user
  const sendQuotationInvitation = async (userSelected: User | string) => {
    setShowSearchUser(false);
    setLoading(true);
    if (typeof userSelected === "object") { // if user exists already
      // useState quotation
      setReqQuote((prev: any) => ({
        ...prev,
        user: {
          id: userSelected.id,
          itNumber: userSelected.itNumber,
          name: userSelected?.name + " " + userSelected.lastName,
          numOperations: userSelected?.numOperation,
          rating: userSelected?.rating,
          typeIt: userSelected?.typeIt,
          phone: userSelected?.phone,
        },
        updatedAt: new Date(),
      }));
      // set useState userSelected
      setUser(userSelected);
      const response = await UpdateOperation({
        id: reqQuote.operationId,
        userId: userSelected.id,
        updatedAt: new Date(),
      });
      // update requestQuote in firestore
      const responseRq = await UpdateRequestQuote({
        ...reqQuote,
        user: {
          id: userSelected.id,
          itNumber: userSelected.itNumber ? userSelected.itNumber : 0,
          name: userSelected?.name + " " + userSelected.lastName,
          numOperations: userSelected?.numOperation
            ? userSelected.numOperation
            : "",
          rating: userSelected?.rating ? userSelected?.rating : 0,
          typeIt: userSelected?.typeIt ? userSelected?.typeIt : "",
          phone: (userSelected.phoneCode ? userSelected.phoneCode : "+51") + "" +userSelected.phone ? userSelected.phone : "",
        },
        updatedAt: new Date(),
      });
      // update quotation
      setTimeout(async () => {
        await updateQuotationData(false, '', userSelected.id);
      }, 1000);
    } else {
      console.log("enviar invitacion", userSelected);
      setSendEmail(userSelected);
      const response = await UpdateOperation({
        id: reqQuote.operationId,
        updatedAt: new Date(),
      });
      // console.log("🚀 ~ file: Quote.tsx:706 ~ sendQuotationInvitation ~ response:", response)
      await updateQuotationData(false, userSelected, '');
    }
    setLoading(false);
  };

  // metodo para actualizar RequestQuote
  const handleUpdateRq = () => {
    setEditRq(false);
    try {
      sendNotificationToUser({
        title: "¡Solicitud actualizada!",
        content: reqQuote.companyName + " ha modificado los requerimientos",
        operationName: reqQuote.title,
        operationId: reqQuote.operationId ? reqQuote.operationId : "",
        userId: reqQuote.user.id,
        name: reqQuote.companyName ? reqQuote.companyName : '',
        image: CompanyDetail.photoProfile ? CompanyDetail.photoProfile : "",
        event: "editRQProvider",
        urlPath: `${OPERATIONS}/${reqQuote.operationId}/${reqQuote.id}/cotizaciones/${quotationDetail.id ? quotationDetail.id : 'unknown'}`
      }).then((response) => {
        // GetRequestQuote(params.requestQuoteId ? params.requestQuoteId : "");
      }).catch((err) => {
        console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:391 ~ handleUpdateRq ~ err:", err)
      });
    } catch (error) {
      console.log(error)
    }
    toast.success("¡Se actualizaron los correctamente!");
    // GetRequestQuote(params.requestQuoteId ? params.requestQuoteId : "");
  };

  // metodo para rechazar solicitud de cotizacion
  const handleRejectClick = async () => {
    if (reasonRejecting.length > 0) {
      console.log("reasonRejecting", reasonRejecting);
      setLoading(true);
      try {
        const response = await RejecteRequestQuote(
          reqQuote.id ? reqQuote.id : "",
          reqQuote.operationId ? reqQuote.operationId : "",
          reasonRejecting
        );
        // console.log("🚀 ~ file: Quote.tsx ~ line 352 ~ handleRejectClick ~ response", response)
        if (response) {
          setShowModalDecline(false);
          setReasonRejecting("");
          toast.success("Solicitud de cotización rechazada");
          setTimeout(() => {
            navigation(-1);
          }, 1000);
       
          // Rechazando una solicitud
          try {
            sendNotificationToUser({
              title: "¡Solicitud rechazado!",
              content: "Tú solicitud " + reqQuote.companyName + " ha sido rechazada. Conoce los detalles en tu panel principal.",
              operationName: reqQuote.title,
              operationId: reqQuote.operationId ? reqQuote.operationId : "",
              userId: reqQuote.user.id,
              name: reqQuote.companyName ? reqQuote.companyName : '',
              image: CompanyDetail.photoProfile ? CompanyDetail.photoProfile : "",
              event: "editRQProvider",
              urlPath: `${OPERATIONS}/${reqQuote.operationId}/${reqQuote.id}/cotizaciones/${quotationDetail.id ? quotationDetail.id : 'unknown'}`
            }).then((response) => {
              // console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:389 ~ handleUpdateRq ~ response:", response)
            }).catch((err) => {
              console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:391 ~ handleUpdateRq ~ err:", err)
            });
          } catch (error) {
            console.log(error)
          }

        } else {
          setLoading(false);
          toast.error("Error al rechazar la solicitud de cotización");
        }
      } catch (error) {
        setLoading(false);
        //console.log(error);
        toast.error("Ocurio un error al rechazar la solicitud de cotización");
      }
    } else {
      setLoading(false);
      toast.error("Por favor ingrese el motivo del rechazo");
    }
  };

  // method to send whatsapp notification to provider
  const sendProviderWspNotification = async () => {
    try {
        // find all user to send whatsapp notification
        const users = chatdetail ? chatdetail.members.filter((m) => (m.userId !== userDetail.id && !m.isCompany)) : [];
        // iterate users to send whatsapp notification
        users.length> 0 && users.forEach(async (user) => {
            // get user detail by id
            const respUser: any = await GetUserById(user.userId);
            // send whatsapp notification
            sendUserMessageNotification(respUser.phoneCode+""+respUser.phone, respUser.name + " " + respUser.lastName, chatdetail?.companyName ? chatdetail.companyName : "")
            .then((wsp) => {
                // console.log("🚀 ~ file: ChatPage.tsx:490 ~ .then ~ wsp:", wsp);
            }).catch((errorWsp) => {
                console.log("🚀 ~ file: ChatPage.tsx:492 ~ .then ~ error:", errorWsp);
            });
        });

    } catch (error) {
        console.log("🚀 ~ file: ChatPage.tsx:488 ~ sendProviderWspNotification ~ error:", error);
    }
  }

  // method to send whatsapp notification to user
  const sendUserWspNotification = async () => {
      try {
          // find all user to send whatsapp notification
          const users = chatdetail ? chatdetail.members.filter((m) => (m.userId !== userDetail.id && m.isCompany)) : [];
          // iterate users to send whatsapp notification
          users.length> 0 && users.forEach(async (user) => {
              // get user detail by id
              const respUser: any = await GetUserById(user.userId);
              sendProviderMessageNotification(respUser.phoneCode+""+respUser.phone, userDetail.name + " " + userDetail.lastName, chatdetail?.companyName ? chatdetail.companyName : '')
              .then((wsp) => {
                  // console.log("🚀 ~ file: ChatPage.tsx:505 ~ .then ~ wsp:", wsp);
              }).catch((errorWsp) => {
                  console.log("🚀 ~ file: ChatPage.tsx: 507 ~ .then ~ error:", errorWsp);
              });
          });
      } catch (error) {
          console.log("🚀 ~ file: ChatPage.tsx:510 ~ sendUserWspNotification ~ error:", error)
      }
  }

  // method to send whatsapp notification
  const sendWhatsappNotification =() => {
    try {
        // console.log("enviar a whatsapp")
        userDetail.userType.includes('user') ? sendUserWspNotification() : sendProviderWspNotification();
    } catch (error) {
        console.log("🚀 ~ file: ChatPage.tsx:491 ~ debounce ~ error:", error);
    }
  }

  // method to get chat by id
  const getChat = (chatId: string) => {
    GetChatById(chatId, (value)=>{
      setchatDetail(value);
    });
  }

  // Method to send begin_checkout event to GA4
  const GA4_begin_checkout = (quotation: QuotationDB, requestQuote: RequestQuoteInterface) => {
    ReactGA.gtag("event", "begin_checkout", {
      currency: "USD",
      value: quotation.price,
      items: [{
        item_id: requestQuote.id,
        item_name: requestQuote.title,
        item_brand: requestQuote.companyName,
        item_category: requestQuote.serviceTypeLabel,
        item_list_id: "services_"+requestQuote.serviceTypeId,
        item_list_name: "Services "+requestQuote.serviceTypeLabel,
        price: quotation.price,
        quantity: 1
      }]
    });
  }

  // obtiene la solocitud de cotizacion segun id
  useEffect(() => {
    if (params && params.requestQuoteId) {
      GetRequestQuote(params.requestQuoteId);
      setIsFirstTime(false);
    }
  }, [params.requestQuoteId]);

  useEffect(() => {
    window.document.body.style.overflow = showSearchUser ? "hidden" : "unset";
  }, [showSearchUser]);

  console.log("reqQuote", reqQuote);

  return (
    <div className="ProviderSearch">
      <OperationHeader
        operationDetail={operationDetail}
        teams={[]}
        onClickHome={() => {}}
        showDots={userDetail.userType.includes('provider') && operationDetail?.requestQuotes.find((rq) => rq.quoteGenerated) ? true : false}
        updateOperation={()=> getOperationOfDetail(operationDetail?.id || "")}
      />

      {!loading && CompanyDetail ? (
        <div className="ProviderSearch-container">
          <div className="ProviderSearch-container-row">
            <div
              className={
                !showchat ? "ProviderSearch-left" : "ProviderSearch-left-chat"
              }
            >
              <QuoteProvider
                num={reqQuote.number}
                userId={
                  reqQuote?.user.id ? reqQuote.user.id : ""
                }
                userName={
                  // user && user.name ? user.name : ''
                  reqQuote?.user.name ? reqQuote.user.name : ""
                }
                userLastName={user && user.lastName ? user.lastName : ''}
                statusProvider={reqQuote.statusProvider}
                handleReviewUser={() => setShowReviewUser(true)}
                email={user && user.email ? user.email : ''}
                phone={ user && user.phone ? user.phone: 0}
                isVerified={user && user.isVerified}
                isFirstImport={user && (user.isFirstImport || true)}
                showVerify={user && user.isVerified ? false : true}
                setShowVerify={setShowVerify}
              />
              
              <div className="ProviderSearch-multimedia">
                <Multimedia
                  multimedia={chatdetail?.attachments}
                  title=""
                  number={5}
                />
              </div>

              <Orders
                title={window.innerWidth > 880 ? "" : ""}
                typeOperation={reqQuote?.category ? reqQuote.category : ""}
                type="Cotización"
                serviceTypeLabel={
                  reqQuote?.serviceTypeId === "agenciamiento-de-aduana"
                    ? "Logística y aduanas"
                    : (reqQuote?.serviceTypeLabel ? reqQuote.serviceTypeLabel : "")
                }
                rating={reqQuote?.numServiceRating}
                companyName={reqQuote?.companyName ? reqQuote.companyName : ""}
                active={true}
                order={{
                  num: numRQ,
                  numComments: reqQuote?.numServiceComments,
                  date: reqQuote?.createdAt
                    ? FormatDate(reqQuote?.createdAt.toDate())
                    : "",
                }}
                country={{
                  city: reqQuote.country?.city ? reqQuote.country.city : "Lima",
                  alpha2Code: reqQuote.country?.alpha2Code
                    ? reqQuote.country.alpha2Code
                    : "PE",
                  imgFlag: reqQuote.country?.imgFlag
                    ? reqQuote.country.imgFlag
                    : "https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fflag_of_Peru.png?alt=media&token=d8bfda2c-4c48-419a-b8b9-00a423a24d53",
                }}
                phases={PhasesImportData}
                addOrder={true}
                flagOrder={true}
                blackModeOrder={true}
                currency={currency}
                price={reqQuote?.price ? reqQuote.price : 0}
                onClickPhase={handlePhaseClick}
                showButton={false}
                handleClick={() => {}}
                unlockedPhases={1}
                numPhase={numPhase}
              />
            </div>

            <div className="ProviderSearch-right">
              {!showchat && (
                <>
                  <div className="ProviderSearch-quotation-title">
                    <div className='ProviderSearch-quotation-title-left'>
                      <div className="ProviderSearch-content-phaseNum">
                        <div className='paragraph-header ProviderSearch-phaseNum'>{1}</div>
                      </div>
                      <div className='small-bold ProviderSearch-colorText'>Cotización</div>
                    </div>
                    {reqQuote?.user.name && (
                      <ButtonBadget
                        name='Ver mensajes'
                        handleBadget={()=>handleMessageClick(chatdetail?.id?chatdetail.id:'')}
                        badget={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
                      />
                    )}
                  </div>
                </>
              )}

              {!showchat ? (
                <div className="ProviderSearch-right-body-content">
                  <div className="ProviderSearch-right-body">
                    {reqQuote.quoteGenerated ? (
                      <div className="ProviderSearch-right-body--badget">
                        <div className="ProviderSearch-right-body--badgetStatus ProviderSearch-colorText smalltext-header">
                          {" "}
                          Cotización
                        </div>
                      </div>
                    ) : (
                      <ImportTimelineSupplier
                        stage={quoteStatus}
                        setStage={
                          reqQuote.status !== "rejected" &&
                          reqQuote.statusProvider !== "No aceptada"
                            ? setQuoteStatus
                            : () => {}
                        }
                      />
                    )}
                    {quoteStatus === 2 && !edit && (
                      <div
                        className="paragraph-header"
                        style={{ color: "var(--red)" }}
                      >
                        Los precios deben estar expresados en dólares americanos
                        USD.
                      </div>
                    )}

                    {quoteStatus === 2 ? (
                      /** Cotizacion */
                      <QuotationSupplier
                        importCost={importCost}
                        setImportCost={setImportCost}
                        total={total}
                        setTotal={setTotal}
                        taxesValue={taxesValue}
                        setTaxesValue={setTaxesValue}
                        detail={detail}
                        setDetail={setDetail}
                        restrictions={restriction}
                        setRestrictions={setRestriction}
                        observation={observation}
                        setObservation={setObservation}
                        onClick={handleSave}
                        edit={edit}
                        setEdit={setEdit}
                        countryDestination={
                          reqQuote.transport?.destination &&
                          reqQuote.transport?.destination.country
                            ? reqQuote.transport.destination.country
                            : ""
                        }
                        countryOrigin={
                          reqQuote.transport?.origin &&
                          reqQuote.transport?.origin.country
                            ? reqQuote.transport.origin.country
                            : ""
                        }
                        typeTransport={
                          reqQuote.transport?.type ? reqQuote.transport.type : ""
                        }
                        merchandiseValue={
                          reqQuote.customs?.value ? reqQuote.customs.value : 0
                        }
                        typeContainer={reqQuote.transport?.typeContainer}
                        handleCancel={handleCancel}
                        statusProvider={
                          reqQuote.statusProvider ? reqQuote.statusProvider : ""
                        }
                        insurance={
                          reqQuote.customs?.insurance
                            ? reqQuote.customs?.insurance
                            : false
                        }
                        isLcl={
                          reqQuote.transport?.typeContainer?.lcl ? true : false
                        }
                        isFcl={
                          reqQuote.transport?.typeContainer?.fcl ? true : false
                        }
                        handleAutoSave={reqQuote.statusProvider !== "Cotización enviada" ? handleAutoSave: ()=>{}}
                        isCourier={reqQuote.serviceTypeId === "courier"}
                        currency={currency}
                        setcurrency={setCurrency}
                      />
                    ) :
                    ( <>
                        <div className="ProviderSearch-header-content-quote">
                          {/* <div className="ProviderSearch-colorText ProviderSearch-colorText__ smalltext-header">
                            Resumen
                          </div> */}

                          { reqQuote.statusProvider != 'Aceptada' && reqQuote.statusProvider != 'Rechazada' &&
                          // !reqQuote.quotationId &&
                          // Number(total) === 0 &&
                          (
                            <div className="ordersQuotationDetailPage-abstrac--edit">
                              <ButtonIcon
                                color='black-75'
                                onClick={()=>setEditRq(true)}
                                content="Editar"
                                Icon={<EditWhite/>}
                                size="extra-small"
                                place="left"
                              />
                            </div>
                          )}
                        </div>

                        <div className="ordersQuotationDetailPage-abstrac-summary box-shadow-card">
                          <QuoteTransportData
                            transport={reqQuote.transport}
                            isCourier={reqQuote.serviceTypeId === "courier"}
                          />
                          <QuoteCustomsData
                            requestQuoteId={reqQuote.id || ""}
                            updateData={()=>GetRequestQuote(params.requestQuoteId ||"")}
                            customs={reqQuote.customs}
                            isCourier={reqQuote.serviceTypeId === "courier"}
                            canUpdate={reqQuote.statusProvider !== "Aceptada" && reqQuote.statusProvider !== "Rechazada"}
                          />
                        </div>

                        {reasonRejecting && reasonRejecting.length > 0 && (
                          <div className="tableQuotation-container">
                            <div className="paragraph-header tableQuotation-title">
                              Motivo del rechazo
                            </div>
                            <div className="tableQuotation-body">
                              {/* <Textarea
                                title="Detalles"
                                value={reasonRejecting}
                                onChange={() => {}}
                                placeholder=" "
                                disable={true}
                              /> */}
                              <textarea
                              id="tableQuotation-body-textarea"
                              title="Detalles"
                              value={reasonRejecting}
                              onChange={() => {}}
                              placeholder=" "
                              ></textarea>
                            </div>
                          </div>
                        )}
                      </>
                    )
                  }
                  </div>

                  {reqQuote.status !== "rejected" &&
                    quoteStatus === 1 &&
                    reqQuote.statusProvider !== "Rechazada" &&
                    reqQuote.statusProvider !== "No aceptada" && (
                      <div className="ProviderSearch-right-footer">

                        <Button
                          content="Rechazar"
                          style={{ backgroundColor: "var(--red)" }}
                          onClick={handleClickDecline}
                        />
                        <Button
                          content="Enviar cotización"
                          onClick={() => handleQuoteClick()}
                        />
                      </div>
                    )}
                </div>
              ) : (
                <div className="ProviderSearch-chat">
                  <div className="ProviderSearch-chat--content">
                    <MessageTopbar
                      searchText=""
                      setSearchText={(e)=> handleSeachChange(e)}
                      team={chatdetail && chatdetail.members ? chatdetail.members.filter(m => m.userId !== userDetail.id && m.isCompany) : []}
                      typeChat={window.innerWidth <= 880 ? "Órdenes" : ""}
                      onClickType={() => {}}
                      canAddMembers
                    />
                    {chatdetail &&
                      <Chat
                        chatId={chatdetail?.id ? chatdetail.id : "" }
                        userId={userDetail?.id?userDetail.id:""}
                        userName={userDetail?.name + " " + userDetail?.lastName}
                        searchText={searchText}
                        members={chatdetail.members}
                        messagesUnRead={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
                        sendWhatsappNotification={sendWhatsappNotification}
                        paramHeight={window.innerHeight}
                      />
                    }
                  </div>
                </div>
              )}
            </div>
          </div>



        </div>
      ) : (
        <Loader />
      )}

      {/* <PaymentGateway
                    show={showPayment}
                    handlePayClick={handlePayClick}
                    setShow={setShowPayment}  /> */}

      <ModalComplete
        showM={showModalComplete}
        setShowM={setShowModalComplete}
        handleClick={() => {}}
        handleConfirm={()=>{}}
        companyName={reqQuote?.companyName ? reqQuote.companyName : ""}
      />

      <ModalDecline
        show={showModalDecline}
        text={reasonRejecting}
        setText={setReasonRejecting}
        setShow={setShowModalDecline}
        onClickCancel={handleClickCancel}
        onclickSave={handleRejectClick}
        loading={loading}
      />

      <ModalReviewUser
        showModal={showReviewUser}
        setShowModal={setShowReviewUser}
        title="Detalle del puntaje del cliente"
        review={reviewUser}
        userName={reqQuote?.user.name}
      />

      <ModalSearchUser
        title={reqQuote.title}
        subTitle="Cotización"
        show={showSearchUser}
        setShow={setShowSearchUser}
        onClickSend={sendQuotationInvitation}
      />

      {user && (
        <ModalSendMessage
          agencyName={user.name + " " + user.lastName}
          direction=""
          email={user.email}
          phone={user.phone ? user.phone.toString() : ""}
          setShowModal={setShowModalSendMessage}
          showModal={showModalSendMessage}
          title={"Datos de" + " " + user.name + " " + user.lastName}
          whatsapp=""
        />
      )}

      {/* <ModalQuote
        requestQuote={reqQuote}
        setRequestQuote={setReqQuote}
        show={editRq}
        setShow={setEditRq}
        updateData={handleUpdateRq}
        isUpdateRequestQuote
        fromPage="serviceDetail"
      />
 */}
      {editRq && <ModalProcessService
        fromPage="quotationUser"
        requestQuote={reqQuote}
        serviceTypeId={reqQuote.serviceTypeId || ""}
        show={editRq}
        setShow={setEditRq}
        companyId=""
        updatedData={handleUpdateRq}
      />}

      {showVerify && 
        <UserVerify
          title="Cuenta verificada" 
          showVerify={showVerify}
          setShowVerify={setShowVerify}
        />
      }
    </div>
  );
};
