import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";
import { RequirementsInterface } from "../interfaces/Requirements";

 
export const GetRequirementsById = async(id: string): Promise<RequirementsInterface>  => {
    const response = await getDoc(doc(DB, 'requeriments', id));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            let data:any = response.data();
            resolve({
                id: response.id,
                orderId: data.orderId,
                detailRequiments: data.detailRequiments,
                userId: data.userId,
                attachment: data.attachment,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt
            })
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

// Create deliveryOrder
export const CreateRequeriments = async(data: RequirementsInterface) => {
    try {
        const response:any = await addDoc(collection(DB, "requeriments"), data);  
        console.log("🚀 ~ file:CreateDeliveryOrder.ts ~ line 27 ~ CreateDeliveryOrder ~ response", response)
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
        return error
    }
}

// Update deliveryOrder
export const UpdateRequeriments = async(id: string, requeriments: any) => {
    console.log("🚀 ~ file: Requirements.service.ts:41 ~ UpdateRequeriments ~ requeriments:", requeriments)
    try {
        if(id.length>0) {
            const requestRef = doc(DB, 'requeriments', id);
            const response =  await updateDoc(requestRef, requeriments);

            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}