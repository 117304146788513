import React, {useRef} from "react";

import "./CheckCircle.css";

type CheckCircleProps = React.InputHTMLAttributes<HTMLInputElement> & {setChecked: (e:any)=>void};

export const CheckCircle: React.FC<CheckCircleProps> = ({
  checked,
  disabled,
  setChecked,
  role,
  ...props
}) => {
  let disabledClass = "";
  if (disabled) {
    disabledClass = "checkcircle-disabled";
  }

  const selectRef = useRef<HTMLDivElement>(null);

  {/*const setCheck = () => {
    selectRef.current?.classList.remove("checkcircle-check");
    selectRef.current?.classList.add("checkcircle-check-none")
  }*/}

  return (
    <div role='checkCircleRole' className={`checkcircle-container ${disabledClass}`}>
      <input role={role} type="checkbox" disabled={disabled} checked={checked} {...props} onChange={(e)=>{setChecked(e.target.checked)}} />
      <label></label>
      {checked && disabled ? (
        <div className="checkcircle-check"></div>
      ) : (
        <div className="checkcircle-check"></div>
      )}
    </div>
  );
};
