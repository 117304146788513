import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CheckWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const CheckWhite: React.FC<CheckWhiteProps> =({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/png/check-white-icon.png"}  alt=""  />
        </div>
    )
}