import React, { useEffect, useState } from "react";

/** importando componentes */

import { TextField } from "../../TextField/TextField";

/** importando estilo */
import './RowTaxes.css';
import { PhaseSeven_Base } from "../../../interfaces/PhasesSupplier";
import { ConverDecimal, ConverMoneyFormat } from "../../../utils/ConvertDecimal";
import { RoundNumber } from "../../../utils/roundNumber";
import { ArrivalControlChannel, TaxableBase } from "../../../interfaces/ArrivalControlChannelInterface";
import { InputElement } from "../../InputElement/InputElement";
import { InputElementMin } from "../../InputElementMin/InputElementMin";

/** declarando variables de interface */
interface RowTaxesProps {
    disabled: boolean;
    onChangeText: () => void;
    open?: boolean // estado de collapse;
    taxableBase: TaxableBase;
    setArrivalControlChannel: React.Dispatch<React.SetStateAction<ArrivalControlChannel>>
}

export const RowTaxes: React.FC<RowTaxesProps> = ({
    onChangeText,
    disabled,
    open,
    taxableBase,
    setArrivalControlChannel
}) => {
    
    const [collapse, setCollapse] = useState(open);
   
    // metodos 
    const handleProductCostChange = (value: number) => {
        setArrivalControlChannel((prev:any)=>({
            ...prev,
            dutiesTaxes: {
                ...prev.dutiesTaxes,
                taxableBase: {
                    ...prev.dutiesTaxes.taxableBase,
                    productCost: Number(value)>=0? value: prev.dutiesTaxes.taxableBase.productCost
                }
            }
            
        }));
    }

    const handleIgvChange = (value: number) => {
        setArrivalControlChannel((prev:any)=>({
            ...prev,
            dutiesTaxes: {
                ...prev.dutiesTaxes,
                taxableBase: {
                    ...prev.dutiesTaxes.taxableBase,
                    internationalTransport: Number(value)>=0? value:prev.dutiesTaxes.taxableBase.internationalTransport
                }
            }
        }));
    }

    const handleIpmChange = (value: number) => {
        setArrivalControlChannel((prev:any)=>({
            ...prev,
            dutiesTaxes: {
                ...prev.dutiesTaxes,
                taxableBase: {
                    ...prev.dutiesTaxes.taxableBase,
                    sure: Number(value)>=0? value: prev.dutiesTaxes.taxableBase.sure
                }
            }
        }));
    }

    /** Bloque 1, import cost */
    useEffect(() => {
        const {productCost, internationalTransport, sure } = taxableBase;
        let sum = Number(productCost) + Number(internationalTransport) + Number(sure);
        setArrivalControlChannel((prev:any) => ({
            ...prev,
            dutiesTaxes: {
                ...prev.dutiesTaxes,
                taxableBase: {
                    ...prev.dutiesTaxes.taxableBase,
                    total: Number(Number(RoundNumber(sum)).toFixed(2))
                }
            }
        }))
    }, [taxableBase.productCost, taxableBase.internationalTransport, taxableBase.sure])

    /** Bloque 1, import cost */
    // useEffect(() => {
    //     const { adValorem, igv, ipm } = base;
    //     let sum = Number(adValorem.value) + Number(igv.value) + Number(ipm.value);
    //     setBase((prev:any)=>{
    //         return {
    //             ...prev,
    //             subTotal: sum
    //         }
    //     });

    // }, [base.adValorem.value, base.igv.value, base.ipm.value])




    // Declarando suma total del bloque1 y el bloque 2
    // useEffect(() => {
    //     let sum = base.subTotal;
    //     setTotal(sum)
        
    // }, [base.subTotal])

    return (
        <div role='rowTaxesRole' className="rowTaxes-container">
            <div className="rowTaxes-body">
                <div className="rowTaxes__ rowTaxes_transparent" >
                    <div className="paragraph rowTaxes__text" style={{color: "var(--gray-1)"}}>Costo del producto (FOB)</div>
                       <InputElementMin
                            title="" 
                            name="" 
                            value={taxableBase.productCost} 
                            onChange={handleProductCostChange} 
                            disable={disabled} 
                        />
                </div>    
                <div className="rowTaxes__ rowTaxes_white">
                    <div className="paragraph rowTaxes__text" style={{color: "var(--gray-1)"}}>Transporte internacional</div>
                    <InputElementMin
                        title="" 
                        name="" 
                        value={taxableBase.internationalTransport} 
                        onChange={handleIgvChange} 
                        disable={disabled} 
                    />
                </div>    
                <div className="rowTaxes__ rowTaxes_transparent">
                    <div className="paragraph rowTaxes__text" style={{color: "var(--gray-1)"}}>Seguro</div>
                    <InputElementMin
                        title=""
                        name=""  
                        value={taxableBase.sure} 
                        onChange={handleIpmChange} 
                        disable={disabled}
                    />
                </div>      
            </div>

            {/** Footer total*/}
            <div className="paragraph-header rowTaxesFooter__">
                <div className="paragraph-header rowTaxesFooter__text" style={{color: "var(--gray-1)"}}> Total USD </div>
                <div className="paragraph-header rowTaxesFooter__text" style={{color: "var(--gray-1)"}}>$ {ConverMoneyFormat(taxableBase.total.toString())}</div>
                {/* <div className="paragraph-header" style={{color: "var(--gray-1)"}}>$ {base.subTotal?RoundNumber(base.subTotal): '0.00'}</div> */}
            </div>
        </div>
    )
}