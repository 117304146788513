import React, { useEffect, useRef, useState } from "react";

import { Attached } from "../../../icons/outline/Attached";

import './InputChat.css';
import { MessageType } from "../../../@types/messageType";
import { Send } from "../../../icons/solid/Send";
import { DocsFileValidation, ImageFileValidation, PDFFileValidation, SpreadSheetFileValidation } from "../../../Consts/FilesValidation";
import { toast } from "react-toastify";
import { ToastWarning } from "../../ToastWarning/ToastWarning";
import { AttachChat } from "../../../interfaces/MessageInterface";
import { EmojiMessage } from "../EmojiMessage/EmojiMessage";
import { Emoji } from "../../../icons/outline/Emoji";
import { CloseBigBlack } from "../../../icons/solid/CloseBigBlack";

interface InputChatProps {
    chatId: string;
    userId?: string;
    disabledInput?: boolean; // status to disable input
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // method to change value
    value: string; // value of input
    onClickSend?: () => void; // method to send input
    setTypeMessage?: React.Dispatch<React.SetStateAction<MessageType>>; // method to change type of message
    handleFileClick?: (e: AttachChat) => void; // method to
    handleEmojiClick?: (e: string) => void;
}

export const InputChat: React.FC<InputChatProps> = ({
    chatId,
    userId="",
    disabledInput,
    onChange,
    value,
    onClickSend=() => {},
    setTypeMessage=()=> {},
    handleFileClick=() => {},
    handleEmojiClick=() => {},
    ...props
}) => {
    
    const fileRef = useRef<HTMLInputElement>(null);

    const [showEmoji, setShowEmoji] = useState<boolean>(false);

    // handle on submit
    const handleSubmit = (e: any) => {
        e.preventDefault();
        onClickSend();
        setShowEmoji(false);
    }

    // handle click attached
    const handleAttachedClick = () => {
        setTypeMessage("attach")
        fileRef.current?.click();
    }

    const handleEmoji = () => {
        setShowEmoji(!showEmoji);
    }
    

    // handle file change
    const handleFileChange = (e: any) => {
        let file_ = e.target.files[0];
        // console.log(file_);
        if(!toast.isActive('confirm')) {
            toast(
                <ToastWarning
                    title = {"¿Desea enviar "+file_.name+"?"}
                    textButtonCancel = "No"
                    textButtonAccept = "Si"
                    onClickCancel={()=>{}}
                    onClickAccept={()=>handleFileClick(file_)}
                />, {
                    hideProgressBar: true,
                    autoClose: 10000,
                    toastId: 'confirm'
                }
            );
        } else {
            console.log('error')
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="inputChat-container" role='inputChatRole'>
                <div className="inputChat-circle" onClick={handleEmoji}>
                    {!showEmoji ? <Emoji/> : <CloseBigBlack/>}
                </div>    
                <div className="inputChat-circle inputChat-circle_cursor " onClick={()=>handleAttachedClick()} ><Attached/></div> 
                <input 
                    ref={fileRef} 
                    type="file" 
                    hidden 
                    accept={DocsFileValidation+","+ImageFileValidation+","+PDFFileValidation+","+SpreadSheetFileValidation} 
                    onChange={(handleFileChange)} 
                />
                <div className="inputChat-line"></div>
                <input
                    id="inputChat__input" 
                    role='inputRoleChat' 
                    disabled={disabledInput} 
                    className="inputChat__input paragraph" 
                    placeholder="Escribe un mensaje aquí" 
                    onChange={onChange} 
                    value={value}
                    autoComplete="off"
                    {...props} />  
                {/* <div className="inputChat-circle"><Alarm /></div> */}
                {value.length > 0 && <div className="inputChat-circle" onClick={()=> onClickSend()} ><Send /></div>}
            </div>

            {showEmoji && 
                <EmojiMessage
                    handleEmoji={handleEmojiClick}
                />}
        </form>
    )
}