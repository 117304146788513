
import { DB, Functions } from "../firebaseConfig/FirebaseConfig";

import { collection, doc, setDoc, getDocs, getDoc, addDoc, updateDoc, where, query, deleteDoc, orderBy, onSnapshot, documentId} from "firebase/firestore"; 
import { httpsCallable } from "firebase/functions";

import { RequestQuoteInterface } from "../interfaces/RequestQuote";
 
// obtiene toda las solucitudes de cotizacion activas de firestore
const GetAllRequestQuoteActive = async() => {
    const response = await getDocs(collection(DB, "requestQuote"));
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data.filter((d)=>(d.statusProvider !== 'Aceptada' && d.status === 'published'));
}

// obtiene toda las solucitudes de cotizacion activas de firestore
const GetAllProviderRequestQuote = async(companyId: string) => {
    const q = query(collection(DB, "requestQuote"), where("status", "==", 'published'), where("companyId", "==", companyId))
    const response = await getDocs(q);
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}

// obtener requestQuote segun id
const GetRequestQuoteById = async(id: string): Promise<RequestQuoteInterface>  => {
    const response = await getDoc(doc(DB, 'requestQuote', id));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            let data:any = response.data();
            resolve({
                id: response.id, 
                active: data.active || true,
                category: data.category || '',
                companyId: data.companyId || '',
                companyName: data.companyName || '',
                country: data.country || {
                    alpha2Code: '',
                    city: '',
                    imgFlag: '',
                },
                createdAt: data.createdAt || new Date(),
                customs: data.customs,
                number: data.number || 0,
                numServiceComments: data.numServiceComments || 0,
                phaseDraft: data.phaseDraft || 0,
                price: data.price || 0,
                quotationId: data.quotationId || '',
                serviceId: data.serviceId,
                numServiceRating: data.numServiceRating,
                serviceTypeId: data.serviceTypeId || '',
                serviceTypeLabel: data.serviceTypeLabel || '',
                status: data.status,
                statusProvider: data.statusProvider || '',
                observation: data.observation || '',
                title: data.title,
                transport: data.transport,
                updatedAt: data.updatedAt || new Date(),
                user: data.user,
                usersProvider: data.usersProvider || [],
                operationId: data.operationId || '',
                quoteGenerated: data.quoteGenerated || false,
                autogenerated: data.autogenerated || false,
                chatId: data.chatId || '',
                lastUserUpdated: data.lastUserUpdated || new Date(),
                lastMessage: data.lastMessage || '',
            })
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}


// obtener requestQuote segun array id
const GetRequestQuoteByIds = async(ids: string[]) => {
    const q = query(collection(DB, "requestQuote"), where(documentId(), "in", ids))
    const response = await getDocs(q);
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}

// crea una solicitud de cotizacion en firestore
const CreateRequestQuote = async (requestQuote: RequestQuoteInterface) => {
    // console.log("🚀 ~ file: RequestQuote.service.ts ~ line 44 ~ CreateRequestQuote ~ requestQuote", requestQuote)
    try {
        const response = await addDoc(collection(DB, "requestQuote"), requestQuote);  
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
        return error;
    }
}

// sobre escribe una solicitud de cotizacion segun el "id"
const SetRequestQuote = async (requestQuote: RequestQuoteInterface) => {
    //  console.log("🚀 ~ file: RequestQuote.service.ts ~ line 55 ~ SetRequestQuote ~ requestQuote", requestQuote)
    try {
        if(requestQuote.id) {
            const requestRef = doc(DB, 'requestQuote', requestQuote.id);
            const response =  await setDoc(requestRef, {...requestQuote});
            // console.log("SetRequestQuote", response)
            return true;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

// actualiza una solicitud de cotizacion segun el "id"
const UpdateRequestQuote = async(requestQuote: any) => {
    // console.log("🚀 ~ file: RequestQuote.service.ts ~ line 70 ~ UpdateRequestQuote ~ requestQuote", requestQuote)
    try {
        if(requestQuote.id) {
            const requestRef = doc(DB, 'requestQuote', requestQuote.id);
            const response =  await updateDoc(requestRef, {...requestQuote});

            // console.log("UpdateRequestQuote", response)
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

// obtiene toda las solicitudes de cotizacion que se encuentran como borradores
const GetAllRequestQuoteDraftUser = async(userId: string) => {
   try {
        const rqdu = query(collection(DB, "requestQuote"), where("status", "==", 'draft'), where("user.id", "==", userId), orderBy("updatedAt", "desc"))
        const response = await getDocs(rqdu);
        // console.log('test', response)
        let data:any[] = [];
        if(!response.empty) {
            data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
        }
        return data;
   } catch (error) {
    console.log(error);
    return error;
   }
}

const GetAllRequestQuoteDraft = async(userId: string, cb: (e: any)=>void = ()=> {}) => {
    const rqdu = query(collection(DB, "requestQuote"), where("status", "==", 'draft'), where("user.id", "==", userId), orderBy("updatedAt", "desc"))

    onSnapshot(rqdu, (querySnapshot) => {
        let data:any[] = [];
        querySnapshot.forEach((doc) => {
            data.push({id: doc.id, ...doc.data()});
        });

        cb(data);
    });
}

// eliminar solicitudes de cotizacion
const DeleteRequestQuote = async (id:string) => {
    if(id.length>0) {
        try {
            await deleteDoc(doc(DB, "requestQuote", id))
            return true;
        } catch (error) {
            console.log(error);
            return false
        }
    } else {
        return false;
    }  
}

// obtiene todas las solicitudes de cotizacion por id de la compañia
const GetRequestQuoteByCompanyAndUser = async(companyId:string, userId: string, cb: (e: any)=>void = ()=> {}) => {

        const rqc = query(collection(DB, "requestQuote"), where("companyId", "==", companyId), 
        where("usersProvider", "array-contains", userId), orderBy("updatedAt", "desc"));

        onSnapshot(rqc, (querySnapshot) => {
            let data: any[] = [];
            querySnapshot.docs.filter((doc) => {
                if(doc.data().status !== "draft" && doc.data().active) {
                    data.push({id: doc.id, ...doc.data()});
                }
            });
            cb(data);
        });

        // const response = await getDocs(rqc);
        // 
        // if(!response.empty) {
        //     
        // }
        // return data;

}

const SendRequestQuoteToOther = async (requestQuote: {
    operationId: string;
    requestQuoteId: string;
    sendRequestQuote: any[];
}) => {
    const sendQuoteToOtherSupplier = httpsCallable(Functions, 'sendQuoteToOtherSupplier');
    return sendQuoteToOtherSupplier(requestQuote)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.log(error);
        return error;
    });
}


// method to send request wih AI
const SendRequestWithAI = async (operationId: string, requestQuoteId: string) => {
    const sendRequestQuoteWithAI = httpsCallable(Functions, 'sendRequestQuoteWithAI');
    return sendRequestQuoteWithAI({
        operationId: operationId,
        requestQuoteId: requestQuoteId
    })
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.log(error);
        return error;
    });
}

//method to get request quote in real time by id
const GetRequestQuoteRT= async(id: string, cb: (e: any)=>void = ()=> {}) => {
    
    onSnapshot(doc(DB, "requestQuote", id), (doc) => {
        cb({id: doc.id, ...doc.data()});
    });
}

const ChangeProviderRequestQuote = async (requestQuoteId: string, status: string ) => {
    const changeRequestQuoteProviderStatus = httpsCallable(Functions, 'changeRequestQuoteProviderStatus');
    return changeRequestQuoteProviderStatus({requestQuoteId: requestQuoteId, updateData: {statusProvider: status}})
}

const RejecteRequestQuote = async (requestQuoteId: string, operationId: string, observation: string ) => {
    const rejectedRequestQuote = httpsCallable(Functions, 'rejectedRequestQuote');
    return rejectedRequestQuote({requestQuoteId: requestQuoteId, operationId: operationId, observation: observation});
}

export {
    ChangeProviderRequestQuote,
    SendRequestQuoteToOther, 
    DeleteRequestQuote, 
    GetAllRequestQuoteActive, 
    GetAllProviderRequestQuote, 
    CreateRequestQuote, 
    SetRequestQuote, 
    UpdateRequestQuote, 
    GetRequestQuoteById, 
    GetAllRequestQuoteDraftUser,
    GetRequestQuoteByCompanyAndUser,
    RejecteRequestQuote,
    GetAllRequestQuoteDraft,
    GetRequestQuoteRT,
    GetRequestQuoteByIds,
    SendRequestWithAI
}

