import { UMWeight, UMWeightMobile } from "../@types/UMWeight"
import { UMVolume, UMVolumeMobile } from "../@types/UMVolume"
import { UMUnitType, UMUnitMobileType } from "../@types/UMUnit"

export const UMListKilograms: UMWeight[]  = [ "Kilogramos", "Libras", "Toneladas"]

export const UMListKilogramsMobile: UMWeightMobile[]  = [ "kg", "lb", "tn"]

export const UMListVolume: UMVolume[] = ["Metros cúbicos","Pies cúbicos"]

export const UMListVolumeMobile: UMVolumeMobile[] = ["m³","ft³"]

export const UMUnit: UMUnitType[] = ["Metros", "Centímetros", "Pulgadas", "Pies"]

export const UMUnitMobile: UMUnitMobileType[] = ["m", "cm", "in", "ft"]
