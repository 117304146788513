import React, { useEffect, useState } from "react";

// importando componentes  
import { IShipment } from "../../interfaces/Shipment";

// importando interfaces
import { ErrorInterface } from "../../interfaces/ErrorInterface";
import { ContainerType } from "../../interfaces/ContainerType";

// importando estilos
import './InputOriginDestiny.css';
import { TypeTranportationInput } from "../TypeTransportInput/TypeTransportInput";
import { DefineRoute } from "../DefineRoute/DefineRoute";
import { TypeTransport } from "../../@types/TypeTransport";

interface InputOriginDestinyProps { 
    origin: IShipment | undefined;
    setOrigin:  (e: any) => void;
    destination: IShipment | undefined;
    setDestination: (e: any) => void;
    initDelivery?: string;
    setInitDelivery?: (e: any) => void;
    finalDelivery?: string;
    setFinalDelivery?: (e: any) => void;
    error?: {
        type: ErrorInterface;
        origin: ErrorInterface;
        destination: ErrorInterface
        warehouseOrigin?: ErrorInterface;
        warehouseDestination?: ErrorInterface;
    };
    type: TypeTransport; 
    setType: (e: TypeTransport) => void;
   
    setTypeContainer: (e: ContainerType) => void;
    serviceId?: string;
    serviceTypeId: string
    clearShipment: boolean;
    setClearShipment: React.Dispatch<React.SetStateAction<boolean>>;
    warehouseOrigin?: string;
    warehouseDestination?: string;
    handleWarehouseChange?: (value: string, type: "warehouseOrigin" | "warehouseDestination") => void;
    setSearchActive: React.Dispatch<React.SetStateAction<boolean>>;

}

export const InputOriginDestiny: React.FC<InputOriginDestinyProps> = ({
    origin,
    setOrigin,
    destination,
    setDestination,
    initDelivery='',
    setInitDelivery=()=>{},
    finalDelivery='',
    setFinalDelivery=()=>{},
    error={
        type: {
            status: false,
            message: ''
        },
        origin: {
            status: false,
            message: ''
        }, 
        destination: {
            status: false,
            message: ''
        }
    },
    type,
    setType,
    setTypeContainer,
    clearShipment=false,
    setClearShipment,
    warehouseOrigin,
    warehouseDestination,
    handleWarehouseChange=()=>{},
    serviceId = "",
    serviceTypeId, 
    setSearchActive
}) => {

    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    // useState service Plan
    // const [servicePlan, setServicePlan] = React.useState<ServicePlanInterface[]>([]);

    const handleAirClick = () => {
       if(type !== 'air') {
            setOrigin(undefined);
            setDestination(undefined);
            setTypeContainer({
                fcl: false,
                lcl: false,
                lclAir: true,
            });
            setType('air');
   
       
            setIsDisabled(false);
        } else {
            setTypeContainer({
                fcl: false,
                lcl: false,
                lclAir: false,
            });
            setType(undefined);
        }
    }

    const handleSeaClick = () => {
        if(type !== 'sea') {
            setOrigin(undefined);
            setDestination(undefined);
            setTypeContainer({
                fcl: false,
                lcl: false,
                lclAir: false,
            })
            setType('sea');
          
            setIsDisabled(false);
        }   else {
            setTypeContainer({
                fcl: false,
                lcl: false,
                lclAir: false,
            });
            setType(undefined);
        }  
       
    }


    const handleOriginChange = (value: IShipment) => {
        setOrigin(value);
        setIsDisabled(false);
    }

    const handleDestionationChange = (value: IShipment) => {
        setDestination(value);
        setIsDisabled(false);
        setSearchActive(false);
    }

   useEffect(() => {
       if((origin?.city && origin?.city.length>0 && destination?.city && destination.city.length>0) || serviceTypeId.length>0) { 
        setIsDisabled(false);
       }
    }, [origin, destination, serviceId]);


    return (
        <div className="inputOriginDestiny_main" role='input-origin-destiny'>
            <div className="inputOriginDestiny">
                {serviceTypeId === 'agenciamiento-de-aduana' && <TypeTranportationInput 
                    type={type}
                    handleAirClick={handleAirClick}
                    handleSeaClick={handleSeaClick}
                    error={error.type}
                />}
                
                {(serviceTypeId === 'courier' || type) && <DefineRoute 
                    type={type}
                    origin={origin}
                    handleOriginChange={handleOriginChange}
                    destination={destination}
                    handleDestionationChange={handleDestionationChange}
                    error={error}
                    initDelivery={initDelivery}
                    handleInitDelivery={setInitDelivery}
                    finalDelivery={finalDelivery}
                    handleFinalDelivery={setFinalDelivery}
                    disabled={isDisabled}
                    clearShipment={clearShipment}
                    setClearShipment={setClearShipment}
                    serviceId={serviceId}
                    warehouseOrigin={warehouseOrigin}
                    warehouseDestination={warehouseDestination}
                    handleWarehouseChange={handleWarehouseChange}
                    setSearchActive={setSearchActive}
                    serviceTypeId={serviceTypeId}
                />}
            </div>
        </div>
    )
}