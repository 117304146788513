import React from "react";

import "./ButtonSign.css";

interface ButtonSignProps {
    title: string;
    icon?: React.ReactNode
    onClick: () => void;
}

export const ButtonSign: React.FC<ButtonSignProps> = ({
    title,
    icon,
    onClick
}) => {
    return (
        <div className="buttonSignEmail-container" onClick={()=> onClick()}>
            {icon}
            <div className="paragraph-header buttonSignEmail-text">
                {title}
            </div>
        </div>
    )
}