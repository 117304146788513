import React, { useState } from "react";
import { ArrowDown } from "../../icons/outline/ArrowDown";
import { ArrowUp } from "../../icons/outline/ArrowUp";
import "./FaqOneQuestion.css";

interface FaqOneQuestionProps {
  question: string;
  questionText: string;
  questionText2?: string;
}

export const FaqOneQuestion: React.FC<FaqOneQuestionProps> = ({
  question,
  questionText,
  questionText2
}) => {
  const [isClick, setIsClick] = useState(true);

  const handleDown = () => {
    setIsClick(!isClick);
  };

  let Icon = isClick ? ArrowUp : ArrowDown;

  return (
    <div className="faq-one-question box-shadow-card" role='faq-one-question'>
      <div className="faq-one-question-title">
        <h2 className="small-bold">{question}</h2>
        <Icon onClick={handleDown} />
      </div>
      {isClick && <div className="faq-one-question-body">
        <p className="paragraph question-text"  dangerouslySetInnerHTML={{__html: questionText}} />
        {questionText2 && <p className="paragraph question-text"  dangerouslySetInnerHTML={{__html: questionText2}} />}
      </div>}
    </div>
  );
};
