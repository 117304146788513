export const ValidateField = (field: any, validation: 'required' | 'notUndefined' | 'number' | 'array' | 'boolean' | 'email' | 
'password' | 'RUC' | 'DNI') => {
    switch (validation) {
        case 'required':
           return  field && field.length > 0 ? false : true; 
        case 'notUndefined':
            return field !== undefined ? false : true;
        case 'number':
            return field !== undefined && !isNaN(field) && Number(field) && field>0? false : true;
        case 'array':
            return field !== undefined && Array.isArray(field) && field.length > 0 ? false : true;
        case 'boolean':
            return field !== undefined && typeof field === 'boolean' ? false : true;
        case 'email':
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // console.log(!(re.test(String(field).toLowerCase())))
            return !(re.test(String(field).toLowerCase()));

        case 'RUC':
            return field !== undefined && !isNaN(field) && Number(field) && Number(field)>=10000000000 && Number(field) <=99999999999 ? false : true;
        case 'DNI':
            return field !== undefined && !isNaN(field) && Number(field) && Number(field)>=10000000 && Number(field) <=99999999 ? false : true
        //VALIDANDO CAMPO DE CONTRASEÑA
        case 'password':
            let valid = true;
            // Validando minusculas
            const lowerCaseLetters = /[a-z]/g;
            if(!lowerCaseLetters.test(field)) {  
               valid = false;
            } 
            // Validando mayusculas
            // const upperCaseLetters = /[A-Z]/g;
            // if(!upperCaseLetters.test(field)) {  
            //     valid = false;
            // } 
            // Validando numeros
            const numbers = /[0-8]/g;
            if(!numbers.test(field)) {  
                valid = false;
            }          
            // Validando tamaño de caracteres
            if(field.length < 6) {
                valid = false;
            } 
            return valid;
        
        default:
            return false;
    }
}