import React, { useState } from "react";

/** Importando componente */
import { Button } from "../Button/Button";
import { CityCountryDisplay } from "../CityCountryDisplay/CityCountryDisplay";
import { CommentCount } from "../CommentCount/CommentCount";
import { Rating } from "../Rating/Rating";
import LazyLoad from 'react-lazyload';

/** Importando icon */
import { Heart_Red } from "../../icons/outline/Heart_Red";
import { Like } from "../../icons/outline/Like";
import { Share } from "../../icons/outline/Share";

/** Importando estilo */
import "./CompanyCard.css";

/** Importando interfaces */
import { Company } from "../../interfaces/Company";
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { Wsp } from "../../icons/outline/Wps";
import { VerifyBlack } from "../../icons/solid/VerifyBlack";
import { VerifyBlue } from "../../icons/solid/Verify_blue";
import { Verify } from "../Verify/Verify";
import { ServiceInterface } from "../../interfaces/Service";
import { CalendarColor } from "../../icons/solid/CalendarColor";
interface CompanyCardProps {
  company: Company;
  service: ServiceInterface;
  supplier?: boolean;
  setSupplier: React.Dispatch<React.SetStateAction<boolean>>;
  handleSend?: (companyId: string) => void;
  verify?: boolean;
  numberVerify: number;
}

export const CompanyCard: React.FC<CompanyCardProps> = ({ 
  company,
  service,
  supplier,
  handleSend = ()=>{},
  verify=false,
  numberVerify
}) => {

  // use state loading
  const [loading, setLoading] = useState<boolean>(false);

  const [showVerify, setShowVerify] = useState(false);

  const [isLike, setIsLike] = useState(false);

  let Icon = isLike ? Heart_Red : Like;

  const handleVerify = () => {
    setShowVerify(!showVerify)
  }
  const handleSendMessageClick = () => {
    setLoading(true);
    handleSend(company.id?company.id:"");
    setTimeout(() => {
      // setLoading(false)     
    }, 2000);
  }


  return (
    <div className="company-card box-shadow-card" role={'company-card'}>
      {showVerify && 
        <Verify 
          verify={showVerify} 
          numberVerify={numberVerify}
          setShowVerify={setShowVerify}
        />}
      <div className="company-card-imgs">
        <LazyLoad height={200}>
          <img role={'company-card-bg'}
            className="company-card-bg"
            src={company.photoCover}
            alt={`${company.serviceType}`}/>
        </LazyLoad>
        <div className="company-card-main-img-bg">
          <LazyLoad height={200}>
            <img role={'company-img'}
              className="company-card-main-img"
              src={company.photoProfile}
              alt={`${company.name}`}/>
          </LazyLoad>
        </div>
      </div>

      <div className="company-card-info">
        <div className="company-card-main">
          <div className="company-card-title">
            <h1 className="paragraph-header">{company.name}</h1>
            {verify && numberVerify < 3 ? 
                <VerifyBlack onClick={handleVerify} style={{cursor: 'pointer'}}/> : 
                <VerifyBlue onClick={handleVerify} style={{cursor: 'pointer'}}/>}
            {/* <LazyLoad height={200}>
              <img src={STORAGE_ASSETS+"/images/svg/circle_check.svg"} alt="checked" />
            </LazyLoad> */}
          </div>
          <h2 className="smalltext">{company.catProviderName}</h2>
          {/* <h3 className="smalltext">
            <span className="smalltext-header">
              {company.numOperation}
            </span>{" "}
            Operaciones Exitosas
          </h3> */}
        </div>

        <div className="company-card-body">
          <div className="company-card-work">
            <h4 className="smalltext-header">¿Por qué trabajar con nosotros?</h4>
            <p className="smalltext company-card-aboutUs">{company.aboutUs}</p>
          </div>

          { supplier ? '' :
            <div className="company-card-social">
              <Rating rating={Number((company.rating).toFixed(1))} />
              <CommentCount commentsCount={service.numComments} />
              <CityCountryDisplay
                city={company.city}
                country={company.country.alpha2Code}
                flagImg={company.country.imgFlag}
              />

              {/*<div className="company-card-share-like">
                <Share />
                <Icon onClick={handleClickLike} style={{cursor: 'pointer'}}/>
              </div>*/}
            </div>
          }
        </div>

        { supplier ? '' :
          <div className="company-card-btns">
            {/*<Button role={'profile-btn'} content="Ver Perfil" size="small" onClick={clickProfile}/>*/}
              {/* <a 
              href={company.phone>0 ? 'https://api.whatsapp.com/send?phone='+company.phone : ''} style={{width: '100%'}} target="_blank"
              >
               <Button role={'message-btn'} content="Enviar mensaje" color="blue-2" size="small" onClick={()=>handleSend()}/>
              </a> */}

              <Button 
                role={'message-btn'} 
                content="Enviar mensaje" 
                color="blue-2" size="small"
                onClick={()=>handleSendMessageClick()}
                loading={loading}
                disabled={loading}
              />

              <ButtonIcon
                content={window.innerWidth > 1037 ? "Agendar reunión" : "Agendar"}
                color="black-75"
                size="small"
                Icon={<CalendarColor />}
                onClick={() =>{}}
              />

              {/* <ButtonIcon
                content="Whatsapp"
                color="green-2"
                size="small"
                Icon={<Wsp />}
                onClick={() =>clickMessage()}
              />  */}
          </div> }
      </div> 
    </div>
  );
};