import React, { useState } from "react";

import './RatingMarker.css';

interface RatingMarkerProps {
    value?: number;
    setRating: (e: number) => void;
    disabled?: boolean;
}

export const RatingMarker: React.FC<RatingMarkerProps> = ({
    value=0,
    setRating,
    disabled=false
}) => {

    const [text, setText] = useState('Selecciona un puntaje')
    const [numActive, setNumActive] = useState(value);

    const handleClick = (n: number) => {
        setRating(n);
        setNumActive(n);
            switch (n) {
                case 1:
                    setText('¡Malo!');
                    break;
                case 2:
                    setText('¡Regular!');
                    break;
                case 3:
                    setText('¡Bueno!');
                    break;
                case 4:
                    setText('¡Muy Bueno!');
                    break;
                case 5:
                    setText('¡Excelente!');
                    break;
                default:
                    break;
            }
    }

    return (

        <div role='ratingMarkerRole' className="ratingMarker-container">
             <div className="ratingMarker-rating">
                {[1,2,3,4,5].map(n =>
                        <div key={n} onClick={()=> !disabled&& handleClick(n)} className={`${n===1? 'ratingMarker-item-incial': (n===5?'ratingMarker-item-final':'ratingMarker-item-middle')} ${(n <= numActive) && 'ratingMarker-item-clicked' }`}>

                        </div>
                    )}
            </div>
            <div className="paragraph ratingMarker-text" style={{color: 'var(--black-75)'}}>{text}</div>
        </div>
    )
}