import React from "react";

import './Body.css';

interface BodyProps {
    days:  any[],
}

export const BodyCalendar: React.FC<BodyProps> = ({
    days,
}) => { 

    return (        
        <div className="bodyCalendar">
            { days.length > 0 && days.map((d, idx) => {
                return (
                <div key={idx} className="bodyCalendar-content">
                    
                </div>
             )
            }) }
        </div>
    )
}