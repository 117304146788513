import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import "./ProcessOrder.css";

import { Loader } from "../Loader/Loader";
import { TableComparePlan } from "../TableComparePlan/TableComparePlan";
import { TableComparePlanMin } from "../TableComparePlanMin/TableComparePlanMin";
import { OrderInterface } from "../../interfaces/OrderInterface";
import { ServicePlanInterface } from "../../interfaces/ServicesPlanInterface";
import { GetServicePlanById } from "../../Services/ServicePlan.service";
import { GetServiceDetail } from "../../Services/Service.service";
import { ServiceInterface } from "../../interfaces/Service";
import { useSelector } from "react-redux";
import { Billing } from "../Billing/Billing";
import MercadoPagoCheckout from "../MercadoPago/MercadoPagoCheckout";
import { BillingInterface } from "../../interfaces/BillingInterface";
import { useNavigate } from "react-router-dom";
import { BillingErrorInterface } from "../../interfaces/BillingErrorInterface";
import { ValidateField } from "../../utils/ValidateField";
import debounce from "lodash.debounce";
import { OrderCreatePayInterface } from "../../interfaces/OrderCreatePay";
import { ProcessPay } from "../../Services/ProcessPayment";
import { CardInterface } from "../../interfaces/CardInterface";
import { MercadoPagoErrorsCode } from "../../Consts/MercadoPagoErrorsCode";
import { toast } from "react-toastify";
import { ResumePay } from "../ResumePay/ResumePay";
import { CardDataInterface } from "../../interfaces/BankAccount";
import { PaymentCD } from "../CheckboxDropdown/Payment/PaymentCD";
import { AUTH_MODAL, AUTH_REGISTER, OPERATIONS } from "../../Consts/BaseUrl";

interface ProcessOrderProps {
    serviceSelected?: ServiceInterface | undefined;
    stepProcess: number;
    title: string; // title of the modal
    planIndex?: number; // index of the plan selected
}

export const ProcessOrder = forwardRef((props: ProcessOrderProps, ref) => {

    const { isLoggedIn } = useSelector((state: any) => state.auth);
    const { userDetail } = useSelector((state: any) => state.user);

    const billingRef: HTMLInputElement | any = useRef<HTMLInputElement>(null);

    const paymentCDRef: HTMLInputElement | any = useRef<HTMLInputElement>(null);

    let navigation = useNavigate();

    // useState loading
    const [loading, setLoading] = useState<boolean>(false);

    // useState service plan
    const [servicePlan, setServicePlan] = useState<ServicePlanInterface | undefined>(undefined);

    // useState order
    const [orderCreatePay, setOrderOrderCreatePay] = useState<OrderCreatePayInterface>({
        companyId: '',
        companyName: '',
        country: {
            alpha2Code: 'PE',
            imgFlag: "https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fflag_of_Peru.png?alt=media&token=d8bfda2c-4c48-419a-b8b9-00a423a24d53",
            name: 'Perú',
        },
        createdAt: new Date(),
        paid: false,
        price: 0, 
        serviceId:  '',
        serviceTypeId:  '',
        serviceTypeLabel:  '',
        status: true,
        title:  '',
        type:  'Importación' || 'Exportación',
        updatedAt: new Date(),
        user: {
            id: '',
            userName: '',
        },
        userId: '',
        currency: '',
        unlockedPhases: 0,
        planId: '',
        
    });

    const [billingOrder, setBillingOrder] = useState<BillingInterface>({
        businessName: "",
        country: "Perú",
        countryFlag: "https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fflag_of_Peru.png?alt=media&token=d8bfda2c-4c48-419a-b8b9-00a423a24d53",
        countryAlpha2Code: "PE",
        direction: "",
        itNumber: 0,
        typeInvoice: "Boleta",
        identityType: "DNI",
    });

    const [cardData, setCardData] = useState<CardDataInterface>({
        cardNumber: 0,
        monthExp: 0,
        yearExp: 0,
        cvv: 0,
        name: "",
        dues: "1",
        email: "",
    });
    
    const [transacctionId, setTransacctionId] = useState<string>("");
    const [operationId, setOperationId] = useState<string>("");
    const [orderId, setOrderId] = useState<string>("");

    const handlePlanSelected = (servicePlanId: string, idx: number) => {
        setOrderOrderCreatePay((prev) => ({...prev,    
            planId: servicePlanId+"--"+idx,
            companyId: props.serviceSelected?.companyId || "",
            companyName: props.serviceSelected?.companyName || "",
            serviceId: props.serviceSelected?.id || "",
            serviceTypeId: props.serviceSelected?.serviceType || "",
            serviceTypeLabel: props.serviceSelected?.serviceTypeLabel || "",
            title: props.title,
            userId: userDetail.id,
            user: {
                id: userDetail.id,
                userName: userDetail.name+" "+userDetail.lastName,
            },
            currency: servicePlan?.price.values[idx].currency || "USD",
            unlockedPhases: 3,
        }));
    }
    
    const processPayment = async (): Promise<boolean> => {
        setLoading(true);
        toast.info("No cierres esta ventana, estamos procesando tu pago", {autoClose: 6000});
        const token =  await paymentCDRef.current.handleGetCheckoutToken();
        if(token) {
            const paymentData: CardInterface = {
                installments: Number(cardData.dues),
                payer: {
                    direccion: billingOrder.direction,
                    email: cardData.email,
                    nombre: cardData.name,
                    identification: {
                        number: billingOrder.itNumber.toString(),
                        type: billingOrder.identityType || "DNI",
                    },
                },
                payment_method_id: 'Card',
                token: token,
                currency_id: servicePlan?.price.values[Number(orderCreatePay.planId.split('--')[1]) || 0].currency || "USD",
                description: (servicePlan?.plans.values[Number(orderCreatePay.planId.split('--')[1]) || 0].value || "")+" - "+(props.serviceSelected?.serviceType || "")+" - "+(props.serviceSelected?.companyName || ""),
                transaction_amount: Number(servicePlan?.price.values[Number(orderCreatePay.planId.split('--')[1]) || 0].value || 0)*100,
            };
         
            try {
                
                const payment: any = await ProcessPay(paymentData, orderCreatePay, billingOrder);
                setTransacctionId(payment.orderNumber);
                setOperationId(payment.operationId);
                setOrderId(payment.orderId);
                setLoading(false)
                return true;
            } catch (error: any) {
                toast.error(error.details.user_message, {autoClose: 5000})
                setLoading(false);
                return false;
            }
         
        } else {
            setLoading(false)
            return false;
        }
    }


    const handleValidateSections =  async () => {
        return new Promise(async (resolve, reject) => {
            if(props.stepProcess === 1) {    
                resolve(false);
            } else if(props.stepProcess > 1 && props.stepProcess < 3) {
              
                const errorBilling: boolean =  billingRef.current.validateBillingFields();
                const errorPayment: boolean = paymentCDRef.current.validateCardData();
    
                if(!errorBilling && !errorPayment) {
                    const response=  await processPayment();
                    resolve(!response)
                } else {
                    resolve(true);
                }
            } else if(props.stepProcess === 3) {
                navigation(`/${OPERATIONS}/${operationId}/orden/${orderId}/1}`)
            }
            resolve(true);
        });
      
    }

    // Get service plan of service
    const getServicePlanByService= async (serviceId:string) => {
        setLoading(false)
        if(!loading && serviceId.length>0)  {
            try {
                setLoading(true);
                const service: ServiceInterface = await GetServiceDetail(serviceId);
                if(service) {
                    const response: ServicePlanInterface = await GetServicePlanById(service.servicePlanId ? service.servicePlanId : "");
                    if(response) {
                        setServicePlan(response);
                        //handlePlanSelected(response.id || "", props.planIndex || 1)
                        setLoading(false);
                    }
                }
            } catch (error) {
                console.log("Hubo un error al obtener los datos del servicio plan")
            }
        }
    }

    // expose method to parent component
    useImperativeHandle(ref, () => ({
        handleValidateSections
    }));

    useEffect(()=>{
        getServicePlanByService(props.serviceSelected?.id || "");
    }, [props.serviceSelected]);

    useEffect(() => {     
        if(props.stepProcess === 1) {
            window.document.body.style.overflow = 'hidden';
           !isLoggedIn && navigation(`?${AUTH_MODAL}=${AUTH_REGISTER}&noRedirect=true`);
        }
    }, [props.stepProcess, isLoggedIn]);

    useEffect(() => {
        setCardData((prev) => ({
            ...prev,
            email: userDetail.email,
            name: userDetail.name+" "+userDetail.lastName,
        }));
    }, []);

    return (
        <div className="processOrder-container" style={{padding: props.stepProcess === 3 ? '30px 0px' : 0}}> 
            { !loading ? props.stepProcess === 1 ?  
                <div className="processOrder-table-compare">
                    <div className="paragraph">
                        Te conseguimos al fabricante del producto que 
                        buscas y negociamos los precios por ti.
                    </div>
                    <TableComparePlanMin
                        handleModalPlan={handlePlanSelected}
                        servicePlan={servicePlan}
                        planIndex={props.planIndex}
                    />
                </div>: props.stepProcess === 2 ? 
            <div className="processOrder-billing">
                    <Billing 
                        ref={billingRef}
                        billingData={billingOrder}
                        setBillingData={setBillingOrder}
                    />
                    <PaymentCD
                        ref={paymentCDRef}
                        amount={Number(servicePlan?.price.values[Number(orderCreatePay.planId.split('--')[1]) || 0].value || 0)}
                        curency={servicePlan?.price.values[Number(orderCreatePay.planId.split('--')[1]) || 0].currency || ""}
                        cardData={cardData}
                        setCardData={setCardData}
                    />
                    {/* <MercadoPagoCheckout
                        ref={mercadoPagoCheckoutRef}
                        amount={Number(servicePlan?.price.values[Number(orderCreatePay.planId.split('--')[1]) || 0].value || 0)}
                    /> */}
                </div> : 
                <ResumePay 
                    // paymentId={mercadoPagoResp.id || ""}
                    onCloseClick={()=> {}}
                    onStarClick={()=> {}}    
                    amount={Number(servicePlan?.price.values[Number(orderCreatePay.planId.split('--')[1]) || 0].value || 0)}
                    servicePlan={(servicePlan?.plans.values[Number(orderCreatePay.planId.split('--')[1]) || 0].value || "").toString()}
                    installments={Number(cardData.dues)}
                    lastFourDigits={cardData.cardNumber.toString().slice(-4)}
                    operationNumber={transacctionId}
                />
            : <Loader />}
        </div>
    )
});
