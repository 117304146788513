import { FAQInterface } from "../interfaces/faq";

export const Questions: FAQInterface[] = [
    {
        question: "¿Qué es Arkabia?",
        answer: "Es una plataforma en línea que te ayuda a cotizar tus importaciones con distintas agencias de aduana y carga a la vez.",
    }, 
    {
        question: "¿Qué necesito para poder cotizar a través de Arkabia?",
        answer: "Basta con tener la proforma de tu proveedor, la lista de empaque y la ficha técnica para poder cotizar a través de Arkabia.",
    },
    {
        question: "¿Cuál es el tiempo estimado de respuesta?",
        answer: "De 2 a 4 horas, según la calidad de información que el usuario comparta.",
    },
    {
        question: "¿Quién puede ver mis requerimientos?",
        answer: "Sólo tu y las agencias con las que decidas cotizar.",
    },
    // {
    //     question: "¿Es gratis?",
    //     answer: "Si, Arkabia es completamente gratis.",
    // },
    {
        question: "¿Es necesario realizar toda mi importación a través de Arkabia?",
        answer: "No, los pasos que proporciona Arkabia para importar de inicio a fin son únicamente de apoyo para el usuario.",
    },
];