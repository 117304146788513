import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type HamburgerMenuProps = React.HTMLAttributes<HTMLDivElement> & {};

export const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/png/hamburger-menu-new.png"} alt="" />
    </div>
  );
};