import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type ArkabiaOpProps = React.HTMLAttributes<HTMLDivElement> & {};

export const ArkabiaOp: React.FC<ArkabiaOpProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/arkabia-op-icon.svg"}  alt=""  />
    </div>
  );
};
