import React from "react";

import { Button } from "..";

import './HeadPage.css';

interface HeadPageProps {
    titleH1?:boolean;
    title: any;
    subTitle: string;
}

export const HeadPage: React.FC<HeadPageProps> = ({
    titleH1 = false,
    title = '',
    subTitle = '',
}) => {
    return (
        <div className="headPage" role='header-customs-phases'>
            { titleH1 ? 
                <div className="big-header headPage-title">{title}</div> :
                <div className="small-header headPage-title">{title}</div>
            }
            <div className="small-medium  headPage-subtitle">{subTitle}</div>
        </div>
    )
}