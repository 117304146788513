import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

import './ProcessService.css';
import { SelectServiceType } from "../SelectServiceType/SelectServiceType";
import { TextField } from "../TextField/TextField";
import { error } from "console";
import { FromPage } from "../../@types/fromPageTypes";
import ProcessTitle from "./Title/ProcessTitle";
import { ImportTimeline } from "../ImportTimeline/ImportTimeline";
import ProcessRequestQuote from "../ProcessRequestQuote/ProcessRequestQuote";
import { ProcessOrder } from "../ProcessOrder/ProcessOrder";
import { title } from "process";
import { ServiceInterface, ServiceTypeInterface } from "../../interfaces/Service";
import { set } from "date-fns";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RequestQuoteInterface } from "../../interfaces/RequestQuote";
import debounce from "lodash.debounce";
import { ACTION_MODAL_REQUEST, AUTH_LOGIN, AUTH_MODAL, MODAL_REQUEST, SHOW_MODAL } from "../../Consts/BaseUrl";

interface ProcessServiceProps {
    companyId: string;
    serviceTypeId: string;
    stepProcess: number;
    setStepProcess: React.Dispatch<React.SetStateAction<number>>;
    fromPage: FromPage;
    title: string
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    setServiceType: React.Dispatch<React.SetStateAction<ServiceTypeInterface | undefined>>;
    serviceSelected?: ServiceInterface | undefined;
    setServiceSelected : React.Dispatch<React.SetStateAction<ServiceInterface | undefined>>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    requestQuoteUpdate: RequestQuoteInterface | undefined ; // request quote to update
    updatedData?: () => void; // function to update data
    planIndex?: number; // index of the plan selected
}

const ProcessService = forwardRef((props: ProcessServiceProps, ref) => {

    // ref from parent div
    const refDiv = useRef<HTMLDivElement>(null);

    const processTitleRef: HTMLInputElement | any = useRef<HTMLInputElement>(null);
    const processRequestQuoteRef: HTMLInputElement | any = useRef<HTMLInputElement>(null);
    const processOrderRef: HTMLInputElement | any = useRef<HTMLInputElement>(null);

     // natigation
     const navigation = useNavigate();
     // location
     const location = useLocation();
     // searchParams
     const [searchParams] = useSearchParams();

    // TODO: selectors 
    // redux user
    const {userDetail} = useSelector((state: any) => state.user);
    const {isLoggedIn } = useSelector((state: any) => state.auth);

    const validateFields = async () => {
        if(props.stepProcess === 0) {
            props.setStepProcess((prev) => !processTitleRef.current.validateTitleFields() ? prev + 1 : prev)
        } else if(props.stepProcess>0) {
            if(props.serviceTypeId === "agenciamiento-de-aduana" || props.serviceTypeId === "courier") {
                const error = processRequestQuoteRef.current.handleValidateSections();
                !error && isLoggedIn && props.stepProcess > 0 && processRequestQuoteRef.current.saveDraft();
               
                // if request quote is updated and the step process is 2, then update the data
                if((props.fromPage === "quotationUser" || props.fromPage === "quotationProvider") && props.requestQuoteUpdate && props.requestQuoteUpdate.id && props.stepProcess === 2) {
                     props.updatedData && props.updatedData();
                     return;
                } else {
                    props.setStepProcess((prev) => !error? prev + 1 : prev);
                }
            } else {
                try {
                    const error = await processOrderRef.current.handleValidateSections();
                    console.log("🚀 ~ file: ProcessService.tsx:73 ~ validateFields ~ error:", error);
                    props.setStepProcess((prev) => !error? prev + 1 : prev);
               } catch (error) {
                    console.log("🚀 ~ file: ProcessService.tsx:76 ~ validateFields ~ error:", error)
               }
            }
     
        }
    }

    const saveDraftRq = () => {
        processRequestQuoteRef.current.saveDraft();  
    }

    // method to close the modal
    const closeModal = () => {
        props.setShowModal(false);
    }

    // to scroll bottom component
    const executeScroll = useCallback(
        debounce((toPercent: number = 1.5)=>{
            if (refDiv) {
                refDiv.current?.scrollTo({
                    top: refDiv.current.scrollHeight/toPercent,
                    behavior: 'smooth',
                });
            }
        }, 500),[]);

    useImperativeHandle(ref, () => ({
        validateFields,
        saveDraftRq
    }));
    

    // useEffect to save request quote after user is logged in
    useEffect(()=>{
        if(searchParams.get(ACTION_MODAL_REQUEST) && !searchParams.get(AUTH_MODAL)) { 
            props.setStepProcess(3);
            if(userDetail && userDetail.userType.includes('user')) {
                if(searchParams.get(ACTION_MODAL_REQUEST) === 'saveDraft') {   
                    // handleDraftClick();
                    navigation(location.pathname);
                }
                if(searchParams.get(ACTION_MODAL_REQUEST) === 'sendQuote') {
                    processRequestQuoteRef.current.saveRequestQuote();          
                }       
            } else {
                toast.warning("Solo los importadores pueden cotizar en Arkabia 🧐");
                navigation(location.pathname+`?${AUTH_MODAL}=${AUTH_LOGIN}`);
            } 
        } 

     
    }, [searchParams]);

    return (
        <div  className="processService-container">
            {props.stepProcess === 0 ? 
                <ProcessTitle 
                    ref={processTitleRef}
                    companyId={props.companyId}
                    fromPage={props.fromPage}
                    serviceTypeId={props.serviceTypeId}
                    title={props.title}
                    setTitle={props.setTitle}
                    serviceSelected={props.serviceSelected}
                    setServiceSelected={props.setServiceSelected}
                    setServiceType={props.setServiceType}

                /> : props.stepProcess > 0 && 
                <div className="processService-content">
                    {props.stepProcess !== 3 && <ImportTimeline 
                        stage={props.stepProcess}
                        serviceTypeId={props.serviceTypeId}
                        activeClick={false}
                        setStage={props.setStepProcess}
                    />}
                    <div className="processService-detail" ref={refDiv}>
                        {(props.serviceTypeId === "courier" || props.serviceTypeId === "agenciamiento-de-aduana") ? 
                            <ProcessRequestQuote 
                                ref={processRequestQuoteRef}
                                requestQuoteUpdate={props.requestQuoteUpdate}
                                serviceTypeId={props.serviceTypeId}
                                stepProcess={props.stepProcess}
                                serviceSelected={props.serviceSelected}
                                title={props.title}
                                setTittle={props.setTitle}
                                fromPage={props.fromPage}
                                closeModal={closeModal}
                                updatedData={props.updatedData}
                                executeScroll={executeScroll}
                            />  : props.serviceTypeId === "busqueda-de-proveedor" && 
                            <ProcessOrder
                                ref={processOrderRef}
                                serviceSelected={props.serviceSelected}
                                stepProcess={props.stepProcess}
                                title={props.title}
                                planIndex={props.planIndex}
                            />}
                    </div>
                </div>
            }
         </div>
    )
});

export default ProcessService;