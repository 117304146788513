import { ServiceTypeQuestionIbterface } from "../interfaces/ServiceTypeQuestion.interface";

export const ServiceTypeQuestions: ServiceTypeQuestionIbterface[]  = [{
    serviceTypeId: "agenciamiento-de-aduana",
    question: "¿Qué quieres importar?"
}, {
    serviceTypeId: "busqueda-de-proveedor",
    question: "¿Qué producto buscas?"
}, {
    serviceTypeId: "courier",
    question: "¿Dónde será tu compra?"
}]
