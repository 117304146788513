import React from "react";

import './TableBody.css';
import { CardGradient } from "../CardGradient/CardGradient";
import { Download } from "../../icons/solid/Download";
import { formatReceiptNumber } from "../../utils/FormatNumber";
import { ReceiptInterface } from "../../interfaces/ReceiptInterface";

interface TableBodyProps {
    receipt: ReceiptInterface
}

export const TableBody: React.FC<TableBodyProps> = ({
    receipt,
}) => {

    return (
        <div className="tableBody">
            <div className="smalltext tableBody-text">{receipt.description}</div>
            <div className="smalltext tableBody-text">{formatReceiptNumber(receipt.documentNumber)}</div>
            {/* <div className="smalltext tableBody-text">{receipt.amount}</div> */}
            <div className="smalltext tableBody-text">{receipt.dateRecept}</div>
            <div className="smalltext tableBody-text">{receipt.amount+" "+receipt.currency}</div>
            {/* <div className="smalltext tableBody-text">Culqi</div> */}
            <div className="smalltext tableBody-text">{receipt.typeReceipt}</div>
            <a className="tableBody-img" href={receipt.documentUrl} target="_blank"><Download/></a>
        </div>
    ) 
}