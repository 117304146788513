
import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, doc, getDoc, addDoc, updateDoc} from "firebase/firestore"; 

import axios from "axios";

// import * as convert from "xml-js";

import { CORS_PROXY, HEROKU_CORS, MARINTRAFFIC_API, SHIPTRACKING } from "../Consts/BaseUrl";
import { CargoShippet } from "../interfaces/CargoShippet";


const GetCargoShippetId = async (cargoShippetId: string) => {
    const response = await getDoc (doc(DB, "cargoShippet", cargoShippetId));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()})
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

const GetVesselsByName = async (name: string) => {
    try {
        if(name && name.length>0) {
            const response:any = await axios.get(`${CORS_PROXY}`, {
                headers: { 
                    'X-RapidAPI-Key': 'fb787e7ee7msh626404bd86119e4p10d117jsn9c17995bb7c6',
                    'X-RapidAPI-Host': 'cors-proxy4.p.rapidapi.com'
                },
                params: {url: `${MARINTRAFFIC_API+name}`},
            });
            // console.log("🚀 ~ file: CargoShippet.ts ~ line 33 ~ GetVesselsByName ~ response", response.data)
            return response.data;
            // return JSON.parse(convert.xml2json(response.data,  {compact: true, ignoreComment: true, spaces: 4}));
        }    
    } catch (error) {
        console.log("🚀 ~ file: CargoShippet.ts ~ line 28 ~ GetVesselsByName ~ error", error)
        return error;
    }
}

const CreateCargoShippet = async (data: CargoShippet) => {
    try {
        const response = await addDoc(collection(DB, "cargoShippet"), data);  
        return response;
    } catch (error) {
        console.log(error);
        console.log(error);
    }
}

const UpdateCargoShippet = async (cargoShippetId: string, data: any) => {
    try {
        if(cargoShippetId) {
            const requestRef = doc(DB, 'cargoShippet', cargoShippetId);
            const response =  await updateDoc(requestRef, {...data});

            // console.log("UpdateRequestQuote", response)
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

const GetLocationByMMSI = async (mmsi: number) => {
    let config = {
        headers: {"Access-Control-Allow-Origin": "*", "X-Requested-With": "*"} 
    };
    try {
        if(mmsi && mmsi>0) {
            const response:any = await axios.get(`${HEROKU_CORS}${SHIPTRACKING}vesselonmap.php?type=json&mmsi=${mmsi}`, config);
            // console.log("🚀 ~ file: CargoShippet.ts ~ line 74 ~ GetLocationByMMSI ~ response", response.data)
            if(response) {
                return response.data;
            } else {
                return false;
            }
            
        }    
    } catch (error) {
        console.log("🚀 ~ file: CargoShippet.ts ~ line 78 ~ GetLocationByMMSI ~ error", error)
        return error;
    }
}


export { GetCargoShippetId, GetVesselsByName, CreateCargoShippet, UpdateCargoShippet, GetLocationByMMSI}