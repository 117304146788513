import React, { useEffect, useState } from "react";

import  './Multimedia.css';
import { AttachChat } from "../../interfaces/MessageInterface";
import { ModalNotification } from "../Modal/ModalNotification/ModalNotification";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface MultimediaProps {
    multimedia?: AttachChat[];
    title?: string; 
    isMobile?: boolean;
    number?: number;
    notModal?: boolean;
    handleClickArchive?: ()=>void;
}

export const Multimedia: React.FC<MultimediaProps> = ({
    multimedia = [],
    title= '',
    isMobile = false,
    number=0,
    notModal = false,
    handleClickArchive = ()=>{}
}) => {
 
    const [activeSubMenu, setActiveSubmenu] = useState('Imágenes');

    // useState de modalLateralRight
    const [showModalRight, setShowModalRight] = useState(false);

    //const document
    const [document, setDocument] = useState<AttachChat[]>([]);
    const [image, setImage] = useState<AttachChat[]>([]);

    const handleClickModalMultimedia = () => {
        setShowModalRight(true);
    }

    const getDocumentByType = () => {
        let tmpImg:AttachChat[] = [];
        let tmpDoc:AttachChat[] = []
        multimedia.forEach((m)=> {
            let nameSplit = m.fileName.split('.');
            (nameSplit[nameSplit.length-1].toLocaleLowerCase()==='jpg' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='jpeg' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='png' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='gif' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='ttf' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='bmp' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='tiff' ) ? 
            tmpImg.push(m): tmpDoc.push(m)
        });
        setImage(tmpImg); 
        setDocument(tmpDoc)
    }
    
    useEffect(()=>{
       multimedia.length>0 && getDocumentByType();
    }, [multimedia]);

    return (
        <> { multimedia.length > 0 && <div>
            <>
                {!isMobile ? <div role='multimediaRole' className="multimedia-container">
                    <div className="paragraph-header multimedia-text">Multimedia</div>
                    <div className='multimedia-row'>
                        {multimedia.slice(0, number).map((m, idx)=>{
                            return (
                                <div key={idx} className={'multimedia-card tinytext-header ' + ("multimedia__"+idx)}>
                                    {(idx) === number-1 ? <div  className="multimedia-more" 
                                        onClick={multimedia.length+1>=number ? (notModal ? ()=>handleClickArchive() : ()=>handleClickModalMultimedia()) : ()=>{}}> 
                                            más
                                        </div>: 
                                        ( m && m.fileUrl && m.fileIcon && <a href={m.fileUrl} target="_blank" >
                                            {m.fileIcon && 
                                            <img className={image.findIndex((i) => i.fileName === m.fileName) !== -1 ? "multimedia-card--icon-img" : "multimedia-card--icon-doc"} src={m.fileIcon} />}
                                        </a>)
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div> :

                <div className='messageTopBar-user_main--imageIcon' onClick={multimedia.length>=number ? (notModal ? ()=>handleClickArchive() : ()=>handleClickModalMultimedia()): ()=>{}}>
                    <img src={STORAGE_ASSETS+'/images/svg/imagen_icon.svg'} alt='' />
                </div> }
            </>
            <ModalNotification
                show={showModalRight} 
                setShow={setShowModalRight}
                setActive={setActiveSubmenu}
                active={activeSubMenu}
                subMenu={['Imágenes', 'Documentos']}
                title={title}
            >
                {activeSubMenu==='Imágenes' ? 
                <>
                    {image.length>0 ?<div className="modal-list-imagenes">
                        {image.map((i, idx)=> (
                        <div key={idx} className="smalltext modal-imagenes">
                            <img src={i.fileUrl} className="modal-imagenes-content"/> 
                        </div>
                        ))}
                    </div>: <div className="smalltext modal-notification-nothing">No tienes ninguna imagen</div> }
                </>: 
                <>
                    {document.length>0 ?<div className="modal-list-documents">
                        {document.map((d, idx)=> (
                            <div key={idx} className="modal-documents">
                                <img src={d.fileIcon} className="modal-documents-img"/>
                                <div className="smalltext modal-documents-text">{d.fileName}</div>
                            </div>
                        ))}
                    </div> : <div className="smalltext modal-notification-nothing">No tienes ningún documento</div>}
                </>}
            </ModalNotification>
        </div>

        }</>
    )
}