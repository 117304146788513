import React, { useEffect, useState } from "react";

import { TextField } from "../../TextField/TextField";
import { Dropdown } from "../../Dropdown/Dropdown";
import { ButtonEditChange } from "../../ButtonEditChange/ButtonEditChange"

import './ArrivalDelivery.css';
import { ArrivalControlChannel, FinalDeliveryPlace } from "../../../interfaces/ArrivalControlChannelInterface";
import { getDepartaments, getDistrict, getProvince } from "../../../Services/Places.service";

interface ArrivalDeliveryProps {
    finalDeliveryPlace: FinalDeliveryPlace;
    setArrivalControlChannel: React.Dispatch<React.SetStateAction<ArrivalControlChannel>>;

    showEdit?: boolean;
    setShowEdit?: React.Dispatch<React.SetStateAction<boolean>>,
}

export const ArrivalDelivery: React.FC<ArrivalDeliveryProps> = ({
    finalDeliveryPlace,
    setArrivalControlChannel,

    showEdit=false,
    setShowEdit=()=>{},
}) => {

    // const [ showEdit, setShowEdit ] = useState(false);
    const [loading, setLoading] = useState(true)
    const [departamento, setDepartamento] = useState<any>();
    const [province, setProvince] = useState<any>();
    const [district, setDistrict] = useState<any>();

    const handleEdit = () => {
        setShowEdit(true)
    };

    const handleClickCancel = () => {
        setShowEdit(false)
    }

    const handleDepartament = (value: string) => {
        setArrivalControlChannel((prev:any) => ({
            ...prev,
            finalDeliveryPlace: {
                ...prev.finalDeliveryPlace,
                department: value
            }
        }));
        getProvinces(value)
    }

    const handleProvince = (value: string) => {
        setArrivalControlChannel((prev:any) => ({
            ...prev,
            finalDeliveryPlace: {
                ...prev.finalDeliveryPlace,
                province: value
            }
        }));
        getDistricts(value)
    }

    const handleDistrict = (value: string) => {
        setArrivalControlChannel((prev:any) => ({
            ...prev,
            finalDeliveryPlace: {
                ...prev.finalDeliveryPlace,
                district: value
            }
        }))
    }

    const handleDirection = (value: string) => {
        setArrivalControlChannel((prev:any) => ({
            ...prev,
            finalDeliveryPlace: {
                ...prev.finalDeliveryPlace,
                direction: value
            }
        }))
    }

    const handleRefence = (value: string) => {
        setArrivalControlChannel((prev:any) => ({
            ...prev,
            finalDeliveryPlace: {
                ...prev.finalDeliveryPlace,
                reference: value
            }
        }))
    }

    /* Funcion para mostrar editar cuando los campos se encuentran llenos
       caso contrario se muestran los botones guardar y cancelar */
    // const checkFields = () => {
    //     /** Se coloca los value */
    //     if(department.length === 0 || province.length === 0 || district.length === 0 
    //         || address || !reference || reference.length === 0 ) { // los campos estan vacios 
    //         setShowEdit(true);
    //     } else {
    //         setShowEdit(false); // Campos llenos 
    //     }
    // }

    

     const getDistricts = (provincia: string) => {
     
        const response = getDistrict(provincia);
        setDistrict(response)
    }


    const getProvinces = (departamento: string) => {
    
        const response = getProvince(departamento);
        setProvince(response)
    }

    useEffect(()=> {
        // checkFields();
         const response = getDepartaments();
         setDepartamento(response);
    }, [])


     return (
         <div role='arrivalDeliveryRole' className='arrivalDelivery-main box-shadow-card'>
             <div className='paragraph-header arrivalDelivery-text'>Lugar de entrega final</div>
             <div className='arrivalDelivery-row'>
                <div className='arrivalDelivery-row-m'>
                    <Dropdown 
                        title='Departamento'
                        elements={departamento?departamento.map((d:any) => d.departamento) : []}
                        setValue={(e:any) => handleDepartament(e)}
                        value={finalDeliveryPlace.department}
                        disabled={!showEdit}
                        roleText='arrivalDeliveryRole-department'
                    />
                    <Dropdown 
                        title='Provincia'
                        elements={province?province.map((d:any) => d.provincia) : []}
                        setValue={(e:any) => {handleProvince(e)}}
                        value={finalDeliveryPlace.province}
                        disabled={!showEdit}
                        roleText='arrivalDeliveryRole-province'
                    />
                </div>
                
                <Dropdown 
                    title='Distrito'
                    elements={district?district.map((d:any) => d.distrito) : []}
                    setValue={(e:any) => {handleDistrict(e)}}
                    value={finalDeliveryPlace.district}
                    disabled={!showEdit}
                    roleText='arrivalDeliveryRole-district'
                />
             </div>
             <div className='arrivalDelivery-row'>
                <TextField 
                    title='Dirección'
                    value={finalDeliveryPlace.direction} 
                    placeholder=' ' 
                    onChange={(e:any) => handleDirection(e.target.value)} 
                    disabled={!showEdit}
                    role='arrivalDeliveryRole-address'
                />
             </div>
             <div className='arrivalDelivery-row'>
                <TextField 
                    title='Referencias'
                    value={finalDeliveryPlace.reference}
                    placeholder=' ' 
                    onChange={(e:any)=> handleRefence(e.target.value)} 
                    disabled={!showEdit}
                    role='arrivalDeliveryRole-reference'
                />
             </div>
             <div className='arrivalDelivery-button'>
             {/* {disabled && 
                <ButtonEditChange onClickEdit={()=>handleEdit()}
                                  edit={showEdit}
                                  setEdit={setShowEdit}
                                  add={showEdit}
                                  onClickCancel={()=>handleClickCancel()}
                                  onClickComplete={handleComplete}
                                  onClickSave={HandleClickSave}/> } */}
             </div>
         </div>
     )
}