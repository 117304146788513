import React, { ForwardedRef, useEffect, useRef } from "react";

/** importando estilos */
import './Verify.css';
import { VerifyBlack } from "../../icons/solid/VerifyBlack";
import { VerifyBlue } from "../../icons/solid/Verify_blue";
import { Calendar } from "../../icons/outline/Calendar";
import { Button } from "../Button/Button";

interface VerifyProps {
    verify?: boolean;
    numberVerify?: number;
    name?:string;
    setShowVerify: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Verify: React.FC<VerifyProps> = ({
    verify = false,
    numberVerify = 1, 
    name="",
    setShowVerify = ()=>{},
}) => {

    const ref = useRef<HTMLDivElement>(null);

    const [show, setShow] = React.useState<boolean>(false);

    const handleSendWsp = () => {
        window.open(`https://api.whatsapp.com/send/?phone=51905431863&text=¡Hola! Me gustaría obtener mi verificación`)
    } 

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                // console.log("click")
                setShowVerify(false);
            }
          };
          document.addEventListener('click', handleClickOutside, true);
          return () => {
            document.removeEventListener('click', handleClickOutside, true);
          };
    }, []);

    return (
        <div  role='verify' className="verify-container">
            <div ref={ref} className="verify-content" >
                <div className="verify-line" onClick={()=>setShow(false)}></div>

                <div className="verify-content-header">
                    <div className="verify-title paragraph-header">Verifica tu cuenta</div>
                    <div className={`paragraph ${numberVerify < 3 ? "verify-status" : "verify-status-top"}`}>
                       {numberVerify < 3 ? `Nivel ${numberVerify}` : "Top"}
                    </div>
                </div>

                <div className="verify-description">
                    {verify && numberVerify < 3 ? <VerifyBlack /> : <VerifyBlue /> }
                    {!show && <div className="paragraph verify-description-title">Está cuenta está verificada por Arkabia. 
                        <b className="verify-more" onClick={()=>setShow(!show)}> Ver más</b>
                    </div>}
                    {show && <div className="paragraph verify-description-title">
                    Está cuenta está verificada por Arkabia, cuenta con permisos y 
                    licencias de operación requeridos por su país.
                        <b className="verify-more" onClick={()=>setShow(false)}> Ver menos</b>
                    </div>}
                </div>

                <div className="verify-description">
                    <Calendar /> 
                    <div className="paragraph verify-description-title"> Verificada desde enero 2023 </div>
                </div>

                <Button content="Obtener verificación" onClick={handleSendWsp}/>
            </div>
        </div>
    )
}