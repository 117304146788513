import React, { useState } from "react";

import { Priority } from "../../../../@types/priority";
import './EventMonth.css'; 

interface EventMonthProps {
    title: string;
    priority: Priority;
    time: string;
}

export const EventMonth: React.FC<EventMonthProps> = ({
    title,
    priority,
    time
}) => {

    const [clicked, setClicked] = useState(false);

    return (
        <div className={(clicked && 'event-month-select' )+" event-month-container" } onClick={()=>setClicked(!clicked)} >
            <div className={`event-month-marker ${priority === 'low' ? "event-month-marker__low" : (
                priority === 'middle' ? "event-month-marker__middle" : "event-month-marker__high"
            ) }`}></div>
            <div className="event-month-body">
                <div className="tinytext-header event-month-textColor">{title}</div>
                <div className="tinytext event-month-time">{time}</div>
            </div>
        </div>
    )
}