import React, { useRef, useState } from "react";

/** Importando componentes */
import { ButtonIcon } from "../..";
import { Button } from "../..";
import { EventCard } from "../../EventCard/EventCard";

/** Importando utilidades */
import { TranslateDay } from "../../../utils/translateDay";
import { TranslateMonth } from "../../../utils/translateMonth";

import { format, isSameDay } from "date-fns";

/** importando estilos */
import "./Today.css";

/**declaracion de variables de interface */
interface TodayProps {
    events: any[];
    selectedToday: Date;
}

export const Today: React.FC<TodayProps> = ({ 
    events,
    selectedToday,
}) => {

 const [selectToday, setSelectToday] = useState(new Date());

  return (
    <div className="today">
        <div className="small-boldheader">{(isSameDay(selectedToday, selectToday) ? "Hoy" + " - " : '') + format(selectedToday, 'dd')+ " " + (TranslateDay(format(selectedToday, 'EEEE')))
        +" "+(TranslateMonth(format(selectedToday, 'MMMM'))?.substring(0,3))+ ", " + format(selectedToday, 'yyyy')}</div>
        <div className="today-list-event">
            { events.length>0 ? events.map((e, idx) => {
                return (
                    <EventCard 
                        key={idx}
                        event={e}
                    />
                )
            }): <div className="today-any-event">
                    <div className="paragraph today-any-event-message">No tienes ningún evento.</div> 
                </div>
            }
        </div>
    </div>
  );
};
