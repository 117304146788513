import React, { useEffect, useRef, useState }  from "react";
import "./Textarea.css";

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  title: string;
  disable?: boolean;
  completed?: boolean;
  refArea?: React.Ref<HTMLTextAreaElement>;
  value?: string;
  role?: string
};

export const Textarea: React.FC<TextareaProps> = ({
  title,
  disable = false,
  completed,
  placeholder,
  refArea,
  value='',
  role,
  ...props
}) => {

  const ref = useRef<HTMLSpanElement>(null);

  let disabledClass = "";
  if (disable) {
    disabledClass = "textarea-disabled";
  }

  if (completed) {
    disable = true;
  }

  //TODO find a better way to auto rezie
  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    let scHeight = e.currentTarget.scrollHeight;
    // if(scHeight>50){  
    //   e.currentTarget.setAttribute("style", `height: ${scHeight}px;`);
    // }
  };

  
  return (
    <div 
      // className="textarea" 
      role={role}>
      <span dangerouslySetInnerHTML={{__html: value}} ref={ref} 
        {...props}
        className={!disable ? "paragraph textarea" : "paragraph textarea-disabled"}
        title={title} 
        // placeholder={placeholder} 
        role="textbox"
        contentEditable={!disable}
        suppressContentEditableWarning={true}
      ></span>
      {/* <textarea
        // style={{height: (value?.length/3.4)+'px'}}
        className={`paragraph-header textarea-input ${disabledClass}`}
        onKeyUp={(e) => handleKeyUp(e)}
        disabled={disable}
        placeholder={`${disable ? " " : placeholder}`}
        {...props}
        ref={refArea}
        value={value}
        role='text-area'></textarea> */}
      {/* {<span className="textarea-title paragraph-header">{title}</span>} */}
    </div>
  );
};