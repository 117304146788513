import React, { useEffect, useRef, useState } from "react";

/** importando componentes */
import {  Dropdown, } from "../..";
import { Checkbox } from "../../Checkbox/Checkbox";
import { InputElement } from "../../InputElement/InputElement";


/** importando constantes */
import { UMListKilogramsMobile, UMListVolumeMobile } from "../../../Consts/UMlist";
import { CargoDescription } from "../../../Consts/CargoDescription";

// importando interfaces
import { TransportLCL } from "../../../interfaces/TrasportLCLError";

/** importando estilos */
import "./LCLCD.css";
import { PackageLCLInterface } from "../../../interfaces/LCL";
import { Packages } from "./Packages/Packages";
import { ConvertToKilogram } from "../../../utils/ConvertToKilogram";
import { ConvertToMeters } from "../../../utils/ConvertToMeters";


interface LCLCDProps {
  typeMeasure?: "volumen" | "paquestes";
  setTypeMeasure?: React.Dispatch<React.SetStateAction<"volumen" | "paquestes" | undefined>>;
  totalWeight: number | undefined;
  handleTotalWeight:(e: any)=>void;
  totalWeightUnit: string | undefined;
  setTotalWeightUnit: React.Dispatch<React.SetStateAction<string>>;
  totalVolume: number | undefined;
  handleTotalVolume: (e: any)=>void;
  totalVolumeUnit: string | undefined;
  setTotalVolumeUnit: React.Dispatch<React.SetStateAction<string>>;
  cargoDescription: string | undefined;
  setCargoDescription: React.Dispatch<React.SetStateAction<string>>;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  errorLCL?: TransportLCL;
  packages: PackageLCLInterface[];
  setPackages: React.Dispatch<React.SetStateAction<PackageLCLInterface[]>>;
  scrollToBottom?: ()=>void;
}

export const LCLCD: React.FC<LCLCDProps> = ({
  typeMeasure= "volumen",
  setTypeMeasure = () => {},
  totalWeight,
  handleTotalWeight,
  totalWeightUnit,
  setTotalWeightUnit,
  totalVolume,
  handleTotalVolume,
  totalVolumeUnit,
  setTotalVolumeUnit,
  cargoDescription,
  setCargoDescription,
  isChecked,
  setIsChecked,
  errorLCL,
  packages = [],
  setPackages = () => {},
  scrollToBottom = ()=>{},


}) => {


  const [bultos, setBultos] = useState<PackageLCLInterface[]>(packages.length>0? packages : [{
      type: "Caja",
      quantity: undefined,
      height: undefined,
      tall: undefined,
      unitMeasurement: "m",
      unitWeight: "kg",
      weight: undefined,
      width: undefined,
}]);

  // handle add new bulto
  const handleAddBulto = () => {
    setBultos((prev) => [...prev, {
      type: "Caja",
      quantity: undefined,
      height: undefined,
      tall: undefined,
      unitMeasurement: "m",
      unitWeight: "kg",
      weight: undefined,
      width: undefined,
    }]);
    scrollToBottom();
  }

  // method to handle remove bulto by index
  const handleRemoveBulto = (index: number) => {
    setBultos((prev) => prev.filter((_, i) => i !== index));
  }

  // metodo editar bulto
  const handleChangeInput = (id: number, type: "type" | 'height' | 'quantity' | 'width' | 'tall'| "weight" | "unitWeight" | "unitMeasurement" , value: any) => {
      setBultos(bultos.map((bulto, index) => {
          if (index === id) {
            switch (type) {
              case 'type':
                return {
                  ...bulto,
                  type: value
                }
                case 'quantity':
                  return {
                    ...bulto,
                    quantity: value
                  }
                case 'weight':
                  return {
                    ...bulto,
                    weight: value
                  }   
                case 'unitWeight':
                  return {
                    ...bulto,
                    unitWeight: value
                  } 
                case 'width':
                  return {
                    ...bulto,
                    width: value
                  }
                case 'tall':
                  return {
                    ...bulto,
                    tall: value
                  }
                case 'height':
                  return {
                    ...bulto,
                    height: value
                  }
                case 'unitMeasurement':
                  return {
                    ...bulto,
                    unitMeasurement: value
                  }
              }
          }
          return bulto;
      }));
  }

  // method to get total weight
  const getTotalWeight = (): number => {
    let total = bultos.reduce((acum, b) => 
                  acum + ((b.quantity ? b.quantity : 0) * (b.weight ? ConvertToKilogram(b.weight, b.unitWeight) : 0))
                , 0)
    totalWeight !== total && handleTotalWeight(total);
    return Number((total).toFixed(2));
  }

  // method to get total volume
  const getTotalVolume = (): number => {
    let totalDecimal = bultos.reduce((acum, b) => 
                  acum + Number(((b.width ? ConvertToMeters(b.width, b.unitMeasurement) : 0) * (b.tall ? ConvertToMeters(b.tall, b.unitMeasurement) : 0) *
                  (b.height ? ConvertToMeters(b.height, b.unitMeasurement) : 0) * (b.quantity ? b.quantity : 0)).toFixed(2))
                , 0)
    let total = Number(totalDecimal.toFixed(2));
    totalVolume !== total && handleTotalVolume(total);
    return total;
  }

  // method to handle total weight change
  const handleWeightChange = (value: string) => {
    let total = Number(value);
    // setShowMessage(total < 50);
    handleTotalWeight(value)
  }

  useEffect(() => {
    typeMeasure === "paquestes" && setPackages(bultos);
  }, [bultos]);

 
  return (
    <div role='lclcdRole' className="lclcd">
      <div className="lclcd-container">
        <Checkbox checked={isChecked} setChecked={setIsChecked} onClick={()=>scrollToBottom()}/>
        <div className="lclcd-title">
          <h2 className="paragraph">Contenedor compartido</h2>
          <p className="paragraph-header">

            LCL
          </p>
        </div>
      </div>
    
      {isChecked && (
        <div className="lclcd-bottom">
          <div className="lcl-row-description">
            <div className="lcl-row-description-check">
              <Checkbox checked={typeMeasure === "volumen"} setChecked={()=> setTypeMeasure("volumen")} />
              <div className="paragraph-header lclcd-colorText">Volumen</div>
              <Checkbox checked={typeMeasure === "paquestes"} setChecked={()=> setTypeMeasure("paquestes")} />
              <div className="paragraph-header lclcd-colorText">Paquetes</div>
            </div>
            {typeMeasure === "volumen" && <h2 className="paragraph-header lclcd-colorText">
              Describe tu carga
            </h2>}
          </div>
          {typeMeasure === "volumen" ? <div className="lclcd-first-row">
            <Dropdown
              title={"Tipo de carga"}
              elements={CargoDescription}
              value={cargoDescription?cargoDescription:CargoDescription[0]}
              setValue={setCargoDescription}
              error={errorLCL && errorLCL.cargoDescription}
              roleText='LCLCDcargoDescriptionRole'
            />

            {/* <DropdownSimple
              title="Tipo de carga"
              options={CargoDescription}
              value={cargoDescription?cargoDescription:CargoDescription[0]}
              setValue={setCargoDescription}
            /> */}
            
            <div className="lclcd-first-row-section-content">
              <div className="lclcd-first-row-section">
                <div className="lclcd-row-message">
                  <InputElement
                    value={totalWeight}
                    name="Peso total"
                    title="Peso total"
                    onChange={handleWeightChange}
             
                    error={errorLCL?.totalWeight}
                  />
               

              </div>
              <div className="lclcd-first-row-section-dropdown">
                  <Dropdown
                    title="Unidad"
                    elements={UMListKilogramsMobile}
                    value={totalWeightUnit?totalWeightUnit:(window.innerWidth<=600 ? UMListKilogramsMobile[0] : UMListKilogramsMobile[0])}
                    setValue={setTotalWeightUnit}
                    roleText='LCLCDtotalWeightUnitRole'
                    error={errorLCL?.unitWeight}
                  />
                </div>
              </div>

              <div className="lclcd-first-row-section ">
                <div className="lclcd-row-message">
                  <InputElement
                      value={totalVolume}
                      name="Volumen total"
                      title="Volumen total"
                      onChange={handleTotalVolume}
                      error={errorLCL && errorLCL.totalVolume}
                  />
                </div>
                <div className="lclcd-first-row-section-dropdown">
                  <Dropdown
                      title="Unidad"
                      elements={UMListVolumeMobile}
                      value={totalVolumeUnit?totalVolumeUnit:(window.innerWidth<=600 ? UMListVolumeMobile[0] : UMListVolumeMobile[0])}
                      setValue={setTotalVolumeUnit}
                      roleText='LCLCDtotalVolumeUnitRole'
                      error={errorLCL?.unitVolume}
                    />
                </div>
              </div>
            </div>
          </div> : <div className="lclcd-paquete">
            {bultos.map((bulto, index) => ( // package array
              <Packages 
                key={index}
                num={index+1}
                bulto={bulto}
                handleRemoveBulto={handleRemoveBulto}
                handleAddBulto={handleAddBulto}
                add={index === bultos.length - 1}
                handleChangeInput={handleChangeInput}
                getTotalWeight={()=>getTotalWeight()}
                getTotalVolume={()=>getTotalVolume()}
                showTotalAdd={bultos.length<=1}
              />))}
              <div className="lclcd-paquete-container">
                <div className="lclcd-paquete--total">
                  <span className="small-bold lclcd-paquete--total_send">Envío total:</span>
                  <div className="small-subheader lclcd-paquete-colorBlack">{` ${getTotalWeight()} kg;  ${getTotalVolume()} m3`}</div>
                </div>
                {/* {showMinModal || getTotalWeight() <= 50 && <div className="lclcd-paquete-msg">
                  <div className="tinytext lclcd-row-message--alert">{"Peso < 50kg: Courier ✈️"}</div>
                </div>} */}
              </div>
              {errorLCL && errorLCL.packages.status && <div className="smalltext-header text-error lclcd-paquete-error">{errorLCL.packages.message}</div>}

          </div>}
        </div>
      )}
    </div>
  );
};
