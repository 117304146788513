import React from "react";

import './SectionAnimation.css';
import { Animation } from "../Animation/Animation";
import { ToolsInterface } from "../../interfaces/ToolsInterface";
import { PLAN, STORAGE_ASSETS } from "../../Consts/BaseUrl";
import { useNavigate } from "react-router-dom";

interface SectionAnimationProps {
    tools: ToolsInterface;

}

export const SectionAnimation: React.FC<SectionAnimationProps> = ({
    tools
}) => {

    const navigate = useNavigate();

    const handleUrl = (url: string, isExternal: boolean) => {
       if (isExternal) {
           window.open(url, '_blank');
       } else {
          navigate(url);
          window.scrollTo(0, 0);
       }
    }

    return (
        <div className={`sectionAnimation ${tools.position ? "sectionAnimation" : "sectionAnimation-reverse" }`}>
            <div className="sectionAnimation-left">
                <div className="sectionAnimation-left-header">
                    <div className="big-header sectionAnimation-title">{tools.title}</div>
                </div>
                {tools.description && 
                <div className="paragraph sectionAnimation-description">
                    {tools.description}
                </div>}
                {tools.description2 &&
                    <div className="paragraph sectionAnimation-description2">
                        {tools.description2}
                    </div>
                }
                {tools.list && 
                <div className="sectionAnimation-list">
                    {tools.list && tools.list.map((item, index) => (
                        <div className='sectionAnimation-list-item' key={index}>
                            <img className='cardHoverExpand-img' src={STORAGE_ASSETS+'/images/png/'+item.img}/>
                            <div className='paragraph sectionAnimation-item'>{item.item}</div>
                        </div>
                    ))}
                </div>}

                {tools.btnNameSectionAnimation && 
                <div className="sectionAnimation-btn" onClick={()=>handleUrl(tools.url || "", tools.isExternal || false)}>
                   { tools.btnNameSectionAnimation}
                   <span className="sectionAnimation-btn-resalt">{tools.btnNameResaltSectionAnimation}</span>
                </div>}

            </div>
            <div className="sectionAnimation-right"> 
                {tools.animation && tools.animation.length>0 ?
                <Animation 
                    animation={tools.animation}
                    nameAnimation={tools.nameAnimation}/> : 
                <img className="sectionAnimation-img" src={STORAGE_ASSETS+'/images/png/'+tools.imgSectionAnimation}/>}
            </div>
        </div> 
    )
}

