import React from "react";

import './RowQuoteDetail.css';

interface RowQuoteDetailProps {
    transitTime: string;
    storageBase: string;
    expiration: string;
    isFcl: boolean;
    aboutStay: string;
    isCourier?: boolean;
}

export const RowQuoteDetail: React.FC<RowQuoteDetailProps> = ({
    transitTime,
    storageBase,
    expiration,
    isFcl,
    aboutStay,
    isCourier = false
}) => {
    return(
        <div className="rowQuoteDetail-container">
            <div className="rowQuoteDetail-body">
                <div className="rowQuoteDetail__ rowQuoteDetail_transparent">
                    <div className="paragraph rowQuoteDetail_text" style={{color: "var(--gray-1)"}}>Tiempo de tránsito</div>
                    <div className="paragraph rowQuoteDetail_text__" style={{color: "var(--gray-1)"}}>{transitTime}</div>
                </div>
                {!isCourier && <div className="rowQuoteDetail__ rowQuoteDetail_white">
                    <div className="paragraph rowQuoteDetail_text" style={{color: "var(--gray-1)"}}>Días libres de almacenamiento</div>
                    <div className="paragraph rowQuoteDetail_text__" style={{color: "var(--gray-1)"}}>{storageBase}</div>
                </div>}
                {!isCourier && isFcl && <div className="rowQuoteDetail__ rowQuoteDetail_transparent">
                    <div className="paragraph rowQuoteDetail_text" style={{color: "var(--gray-1)"}}>Sobre estadía</div>
                    <div className="paragraph rowQuoteDetail_text__" style={{color: "var(--gray-1)"}}>{aboutStay}</div>
                </div>}
                <div className={`rowQuoteDetail__   ${isFcl ? 'rowQuoteDetail_white' : 'rowQuoteDetail_transparent'}`}>
                    <div className="paragraph rowQuoteDetail_text" style={{color: "var(--gray-1)"}}>Vencimiento de cotización</div>
                    <div className="paragraph rowQuoteDetail_text__" style={{color: "var(--gray-1)"}}>{expiration}</div>
                </div>
            </div>
        </div>
    )
}