import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Textarea } from "../..";

/* Importando Componentes */
import { ButtonEditChange } from "../../ButtonEditChange/ButtonEditChange";

/** Importando estilos */
import "./ImportationSubstructureCargoagentandhscodeInput.css";
import { CardContent } from "../../CardContent/CardContent";
import { STORAGE_ASSETS } from "../../../Consts/BaseUrl";

interface ImportationSubstructureCargoagentandhscodeInputProps {
  textAreaRows?: number; // numero de columnas
  bulkingAgentValue: string; // Almacena el valor del textField
  setBulkingAgentValue: React.Dispatch<React.SetStateAction<string>>; // Metodo onChange del agente carga
  supplier?:boolean; // Si se utiliza en paginas referente al proveedor, caso contrario motrara el portapapeles cuando sea true
  disabled?: boolean; // permite activar y desactivar el texField, para poder editar
  HandleClickSave: ()=>void; //boton para guardar los campos que se editan
  handleClickCancel: ()=>void;
}

/** Componente de agente de carga */
export const ImportationSubstructureCargoagentandhscodeInput: React.FC<ImportationSubstructureCargoagentandhscodeInputProps> =
  ({
    textAreaRows = 3,
    bulkingAgentValue,
    setBulkingAgentValue,
    supplier= false,
    disabled = false,
    HandleClickSave,
    handleClickCancel
  }) => {

    const [ showEdit, setShowEdit ] = useState(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(bulkingAgentValue.length>0);
    const refArea = useRef<HTMLTextAreaElement>(null);

    const handleEdit = () => {
      setShowEdit(true);
    };

    /** Funcion para copiar el texto */
    const handleCopyClipboard = (e: any) => {
      window.navigator.clipboard.writeText(bulkingAgentValue).then(()=>{
        toast.info("Texto copiado");
     });
    }

    /* Funcion para mostrar editar cuando los campos se encuentran llenos
       caso contrario se muestran los botones guardar y cancelar */
       const checkFields = () => {
        /** Se coloca los value */
        if(bulkingAgentValue.length === 0 ) { // los campos estan vacios 
          setShowEdit(true);
        } else {
          setShowEdit(false); // Campos llenos 
        }
    }

    const handleBuilkAgentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setBulkingAgentValue(e.target.textContent || '');
    }

    useEffect(()=> {
        checkFields();
    }, [])

    return (
      <CardContent role='importation-SCAAHI' className="importation-substructure-cargoagentandhscode-card" 
        style={{backgroundImage:`url(${STORAGE_ASSETS+"/images/png/backgroundblur.png"})`}}>
        <h1 className="paragraph-header">Agente de carga</h1>
        <p className="paragraph">
          Es la persona o empresa que va a coordinar el transporte de tu carga. Bríndale la información del agente a 
          tu proveedor para que se pongan en contacto.
        </p>
        <div className="importation-substructure-cargoagentandhscode-input-bottom">
          <Textarea
            rows={textAreaRows}
            title="Agente de carga"
            value={bulkingAgentValue}
            onBlur={(e: any)=>handleBuilkAgentChange(e)}
            placeholder=' '
            disable={!showEdit}
            role='text-area-importation-SCAAHI'/>
          
          <div className="importation-substructure-cargoagentandhscode-content-btns">
            <div className="importation-substructure-cargoagentandhscode-input-btns">
              {disabled &&
                <ButtonEditChange onClickEdit={()=>handleEdit()}
                                  edit={showEdit}
                                  setEdit={setShowEdit}
                                  add={bulkingAgentValue.length>0}
                                  onClickCancel={()=>handleClickCancel()}
                                  onClickComplete={HandleClickSave}
                                  onClickSave={HandleClickSave}
                                  role='btn-area-importation-SCAAHI'/>
              }

              { supplier &&
                <>
                  <ButtonEditChange onClickEdit={()=>handleEdit()}
                                    edit={showEdit}
                                    setEdit={setShowEdit}
                                    add={false}
                                    onClickCancel={()=>{setShowEdit(false); handleClickCancel()}}
                                    onClickComplete={HandleClickSave}
                                    onClickSave={()=>HandleClickSave()}
                                    role='btn-area-importation-SCAAHI-'/>
                </>
              }
            </div>

            <div>
              {/* {bulkingAgentValue.length>0 &&   */}
                <Button 
                  content="Copiar al portapapeles"
                  size="extra-small"
                  color="black-75"
                  onClick = {(e: any)=>handleCopyClipboard(e)}/> 
              {/* } */}
            </div>
          </div>
        </div>
      </CardContent>
    );
  };
