import React, { useEffect, useRef, useState } from "react";

import { Search } from "../../icons/outline/Search";
import { SearchBlack } from "../../icons/outline/SearchBlack"

import './SearchTextField.css';

interface SearchTextFieldProps {
    title?: string;
    setTextSearch: React.Dispatch<React.SetStateAction<string>>;
    isActive?: boolean;
    isMobile?: boolean;
    darkIcon?: boolean;
    handleClick?: () => void;
}

export const SearchTextField: React.FC<SearchTextFieldProps> = ({
    title='Buscar',
    isActive=true,
    isMobile=false,
    setTextSearch,
    darkIcon=false,
    handleClick=()=>{}
}) => {

    const divRef = useRef<HTMLDivElement>(null)

    const [placeholder, setPlaceholder] = useState(title);


    const handleOnChange = (e:any) => {

        setTextSearch(e.target.value);
        
    } 

    const onClick = () => {
        if(placeholder.length === 0) {
            setPlaceholder('Buscar')
        } else if(placeholder === 'Buscar') {
            setPlaceholder('');
        }         
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                if(title.length === 0) {
                    setPlaceholder('');
                }
            }
          };
          document.addEventListener('click', handleClickOutside, true);
          return () => {
            document.removeEventListener('click', handleClickOutside, true);
          };
    }, []);

    return (
        <div ref={divRef} className={placeholder.length>0?'searchTextField-container':'searchTextField-min'} 
        role='search-text-field' onClick={()=>handleClick()}>
            {
                darkIcon  ? ( <SearchBlack style={{display: 'flex'}} 
                                           className="searchTextField-icon" 
                                           onClick={isActive ? isMobile ? onClick: ()=>{}:()=>{}}  
                                           role='search-text-field-icon-black'/> ) : 
                              <Search style={{display: 'flex'}} 
                                      className="searchTextField-icon" 
                                      onClick={isActive ? isMobile ? onClick: ()=>{}:()=>{}}
                                      role='search-text-field-icon'/>
            }
            { placeholder.length>0 && <input onChange={(e:any) => handleOnChange(e)} 
                                             className='paragraph searchTextField-input' 
                                             type='text' 
                                             placeholder={placeholder} 
                                             role = 'title-search-text-field'/>
            }
        </div>
    )
}