import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";
import {DeliveryOrderInterface } from "../interfaces/DeliveryOrderInterface";
 
export const GetDeliverOrderById = async(id: string): Promise<DeliveryOrderInterface>  => {
    const response = await getDoc(doc(DB, 'deliveryOrder', id));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            let data:any = response.data();
            resolve({
                id: response.id,
                detailDelivery: data.detailDelivery,
                totalRevision: data.totalRevision,
                numRevision: data.numRevision,
                userId: data.userId,
                orderId: data.orderId
            })
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

// Create deliveryOrder
export const CreateDeliveryOrder = async(data: DeliveryOrderInterface) => {
    try {
        const response:any = await addDoc(collection(DB, "deliveryOrder"), data);  
        // console.log("🚀 ~ file:CreateDeliveryOrder.ts ~ line 27 ~ CreateDeliveryOrder ~ response", response)
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
        return error
    }
}

// Update deliveryOrder
export const UpdateDeliveryOrder = async(id: string, deliveryOrder: any) => {
    try {
        if(id.length>0) {
            const requestRef = doc(DB, 'deliveryOrder', id);
            const response =  await updateDoc(requestRef, deliveryOrder);

            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}