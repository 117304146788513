import React, { useEffect } from "react";

import './UserPlanPage.css';
import { PlanSection } from "../../../components/PlanSection/PlanSection";
import { PlanDetailSection } from "../../../components/PlanDetailSection/PlanDetailSection";
import { PROFILE, STORAGE_ASSETS } from "../../../Consts/BaseUrl";
import { GoBack } from "../../../components/GoBack/GoBack";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const UserPlanPage: React.FC<{}> = () => {

    const navigation = useNavigate();

    // metthod to go before page
    const goBack = () => {
        // naviagte to profile page and reaload
        navigation('/'+PROFILE, { replace : true });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="userPlanPage">
            <div className="userPlanPage-goBack" onClick={()=>goBack()}><GoBack title="Regresar" /></div>
            <div className="userPlanPage-content">
                <PlanSection black={false} isChagePlan/>
                <div className={"userPlanPage-title-section"}>
                    <img src={STORAGE_ASSETS+"/images/png/threeImg.png"} alt="Arkabia" className="userPlanPage-title-img"/>
                    <div className="small-subheader userPlanPage-title-section__text"> ¿Cuentas con dudas? Lo resolvemos de inmediato con un asesor</div>
                </div>
                <PlanDetailSection black={true}/>
            </div>
        </div>
    )
}