import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import "./ProcessRequestQuote.css";

import { useLocation, useNavigate } from "react-router-dom";

import { RequestQuoteInterface } from "../../interfaces/RequestQuote";
import { CreateRequestQuote, UpdateRequestQuote } from "../../Services/RequestQuote.service";
import { QuoteTransport } from "../QuoteTransport/QuoteTransport";
import { Loader } from "../Loader/Loader";
import { QuotationDataError } from "../../interfaces/QuotationDataError";
import { QuoteCustoms } from "../QuoteCustoms/QuoteCustoms";
import { QuoteResume } from "../QuoteResume/QuoteResume";
import { useSelector } from "react-redux";
import { ServiceInterface } from "../../interfaces/Service";
import { ValidateField } from "../../utils/ValidateField";
import { FromPage } from "../../@types/fromPageTypes";
import { toast } from "react-toastify";
import { OperationInterface } from "../../interfaces/Operation";
import { ModalConfirm } from "../Modal/ModalConfirm/ModalConfirm";
import { ACTION_MODAL_REQUEST, AUTH_LOGIN, AUTH_MODAL, AUTH_REGISTER, OPERATIONS, QUOTATION, REQUEST_QUOTE } from "../../Consts/BaseUrl";
import { GetCurrency } from "../../utils/GetCurrency";
import { CreateOperation } from "../../Services/Operation.service";


interface ProcessRequestQuoteProps {
    title: string; // title of the modal
    setTittle: React.Dispatch<React.SetStateAction<string>>; // setter to title
    requestQuoteUpdate: RequestQuoteInterface | undefined; // request quote id
    serviceTypeId: string; // service type id
    stepProcess: number; // step process
    serviceSelected?: ServiceInterface | undefined; // service selected
    fromPage: FromPage; // from page
    closeModal: () => void; // method to close modal
    updatedData?: () => void; // function to update data
    executeScroll: (toPercent?: number) => void; // function to execute scroll
}

const ProcessRequestQuote = forwardRef((props: ProcessRequestQuoteProps, ref ) => {
    
    // TODO: selectors 
    // redux user
    const {userDetail} = useSelector((state: any) => state.user);
    const { isLoggedIn } = useSelector((state: any) => state.auth);

    const navigation = useNavigate();
    // location
    const location = useLocation();

    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [requestQuote, setRequestQuote] = useState<RequestQuoteInterface>({
        title: "",
        number: 0,
        user: {
          id: '',
          name: '',
          typeIt: '',
          itNumber: 0,
          numOperations: 0,
          phone: '',
          rating: 0,
        },
        companyName: '',
        serviceId: '',
        // serviceTypeId: 'agenciamiento-de-aduana',
        // serviceTypeLabel: 'Agenciamiento de aduana',
        status: 'draft',
        statusProvider: 'No leída',
        active: true,
        customs: {
          regime: 'Importación definitiva',
          incoterm: 'FOB',
          value: 0,
          coin: '',
          insurance: false,
          merchandisePermits: {
            dataSheet: [],
            proforma: [],
            packingList: [],
          },
          descriptionOperation: '',
        },
        transport: {
          type: undefined,
          origin: {
            city: '',
            country: '',
          },
          destination: {
            city: '',
            country: '',
          },
          typeContainer: {
            lcl: false,
            fcl: false,
            lclAir: false
          }, 
        },
        phaseDraft: 0,
        operationId: '',
        numServiceComments: 0,
        numServiceRating: 0,
        observation: '',
        category: 'Importación',
        createdAt: new Date(),
        updatedAt: new Date(),
    });

    const [requestQuoteError, setRequestQuoteError] = useState<QuotationDataError>({
        title: {
            message: '',
            status: false,
        },
        transportation: {
            type: {
                message: '',
                status: false,
            },
            origin: {
                message: '',
                status: false,
            },
            destination: {
                message: '',
                status: false,
            },
            warehouseOrigin: {
                message: '',
                status: false,
            },
            warehouseDestination: {
                message: '',
                status: false,
            }, 
            typeContainer: {
                message: '',
                status: false,
            },
        },
        customs: {
            regime: {
                message: '',
                status: false,
            },
            incoterm: {
                message: '',
                status: false,
            },
            value: {
                message: '',
                status: false,
            },
            coin: {
                message: '',
                status: false,
            },
            insurance: {
                message: '',
                status: false,
            },
            purchaseLink: {
                message: '',
                status: false,
            }
        }
    });

    const [temOperationId, setTemOperationId] = useState<string>('');

    const modalConfirm = (operationId: string) => {
        setTemOperationId(operationId);
        setShowModalConfirm(true);
        props.closeModal();
    }

    // method to save requestQuote
    const saveRequestQuote = async () => {
        setLoading(true);
       let tmpRq: RequestQuoteInterface = {...requestQuote};

        if(isLoggedIn) {
            if(userDetail && userDetail.userType.includes('user') ) {
                tmpRq = {
                    ...tmpRq,
                    // status: "published",
                    autogenerated: false,
                    customs: {
                        ...tmpRq.customs,
                        insurance: tmpRq.serviceTypeId === "courier" ? false : tmpRq.customs.insurance,
                    },
                    user: {
                        id: userDetail.id,
                        name: userDetail.name+" "+userDetail.lastName,
                        itNumber: userDetail.itNumber? userDetail.itNumber : 0,
                        typeIt: userDetail.typeIt? userDetail.typeIt : '',
                        numOperations: userDetail.numOperations? userDetail.numOperations : 0,
                        phone: (userDetail.phoneCode ? userDetail.phoneCode : "+51")+""+(userDetail.phone ? userDetail.phone : ""),
                        rating: userDetail.rating? userDetail.rating: 0,
                    },
                    updatedAt: new Date(),
                }
 
                if(requestQuote.id) { // if have id update requestQuote
                    try {
                        const response = await UpdateRequestQuote(tmpRq);    
                        !requestQuote.id && toast.success("Operación guardada con éxito");
                        if(!requestQuote.operationId || requestQuote.operationId?.length === 0 ) {
                            await createOperationWithQuote(tmpRq);
                        } else {
                            if(!(props.fromPage === "serviceDetail" || props.fromPage === "chat") && requestQuote.serviceTypeId !== "courier") {
                                if(props.fromPage === "quotationProvider" || props.fromPage === "quotationUser") {
                                    props.updatedData && props.updatedData();
                                    props.closeModal();
                                    return;
                                }
                                modalConfirm(requestQuote.operationId);
                            } else {
                                
                                navigateToQuotation(requestQuote.operationId, requestQuote.id);
                            }
                        }

                    } catch (error) {
                        console.log("🚀 ~ file: Quoter.tsx:398 ~ saveRequestQuote ~ error:", error)
                        toast.error("Error al actualizar la operación, intente de nuevo más tarde");
                    }
                } else { // create new requestQuote
                    try {
                        toast.info("Guardando operación...");
                        const response:any = await CreateRequestQuote(tmpRq);
                        setRequestQuote((prev) => ({
                            ...prev,
                            id: response.id
                        }) )
                        await createOperationWithQuote({
                            ...tmpRq,
                            id: response.id
                        })
                    
                    } catch (error) {
                        console.log("🚀 ~ file: Quoter.tsx:399 ~ saveRequestQuote ~ error:", error)   
                    }
                }
            } else {
                toast.warning("Solo los importadores pueden cotizar en Arkabia 🧐");
                navigation(location.pathname+`?${AUTH_MODAL}=${AUTH_LOGIN}`);
            }        
        } else {
            navigation(`?${AUTH_MODAL}=${AUTH_REGISTER}&noRedirect=true&${ACTION_MODAL_REQUEST}=sendQuote`)
            setLoading(false);
        }
    }

    // method to save draft requestQuote
    const saveDraft = async () => {
        if(!loading) {
            setLoading(true);
            let tmpRq: RequestQuoteInterface = {
                ...requestQuote,
                // status: "draft",
                phaseDraft: props.stepProcess,
                user: (props.fromPage === "quotationProvider" || props.fromPage === "quotationUser") && requestQuote.id && requestQuote.id.length>0 ? 
                requestQuote.user : {
                    id: userDetail.id,
                    name: userDetail.name+" "+userDetail.lastName,
                    itNumber: userDetail.itNumber? userDetail.itNumber : 0,
                    typeIt: userDetail.typeIt? userDetail.typeIt : '',
                    numOperations: userDetail.numOperations? userDetail.numOperations : 0,
                    phone: (userDetail.phoneCode ? userDetail.phoneCode : "+51")+""+(userDetail.phone ? userDetail.phone : ""),
                    rating: userDetail.rating? userDetail.rating: 0,
                },
                updatedAt: new Date(),
            };
        
            try {
                toast.success((props.fromPage === "quotationProvider" || props.fromPage === "quotationUser") &&  requestQuote.id && requestQuote.id.length>0 ? "Actualizando datos" : "Guardando borrador");
                const response:any = requestQuote.id && requestQuote.id.length>0 ? await UpdateRequestQuote(tmpRq) : await CreateRequestQuote(tmpRq);
                if(response && response.id && !requestQuote.id) {
                    setRequestQuote((prev)=>({
                        ...prev,
                        id: response.id,
                    }))
                }
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: Quoter.tsx:454 ~ saveDraft ~ error:", error)
                toast.error("Error al "+(requestQuote.id?"actualizar":"crear")+" la operación, intente de nuevo más tarde");
            }
            
        }
    }

    // method to create operation
    const createOperationWithQuote = async (rq: RequestQuoteInterface) => {
        let operation: OperationInterface = {
            active: true,
            title: rq.title,
            type: rq?.category ? rq.category :'',
            status: {
                requestQuote: true,
                order: false
            },
            requestQuotes: props.serviceSelected? [{
                id: rq?.id ? rq.id : '',
                companyName: props.serviceSelected.companyName,
                companyId: props.serviceSelected.companyId,
                serviceRating: props.serviceSelected.rating,
                serviceType: props.serviceSelected.serviceType,
                serviceTypeLabel: props.serviceSelected.serviceTypeLabel,
                price: rq.price ? rq.price : 0,
                quotationId: '',
                numRequestQuote: 0,
                serviceId: props.serviceSelected?.id ? props.serviceSelected.id : '',
                active: props.serviceSelected.active,
                createdAt: new Date(),
                updatedAt: new Date(),
                city: props.serviceSelected.city,
                rating: props.serviceSelected.rating,
                country: {
                  alpha2Code: props.serviceSelected.country.alpha2Code,
                  imgFlag: props.serviceSelected.country.imgFlag
                },
                observation: '',
                numServiceComments: props.serviceSelected.numComments,
              }] : [],
            paid: false,
            price: 0,
            orderId: '',
            requestQuoteId:  rq.id? rq.id: '',
            orders: [],
            requestQuoteType: rq?.serviceTypeId? rq.serviceTypeId : '',
            createdAt: new Date(),
            updatedAt: new Date(),
            numOperation: 0,
            userId: userDetail.id,
            currency: GetCurrency(rq.customs?.coin ? rq.customs.coin: '')
        }

        try {
            const responseOperation:any = await CreateOperation(operation);
            // console.log("🚀 ~ file: Quoter.tsx:407 ~ createOperationWithQuote ~ responseOperation:", responseOperation)
            const responseRQ = await UpdateRequestQuote({
                ...rq,
                id: rq.id? rq.id: '',
                operationId: responseOperation.id,
                status: "published",
            });     
            setTimeout(() => {
                // cleanFields();
                setLoading(false);
                rq.companyId && rq.companyId.length>0 && 
                rq.companyName && rq.companyName.length>0 ? 
                (!(props.fromPage === "serviceDetail" || props.fromPage === "chat")  && rq.serviceTypeId !== "courier" ? modalConfirm(responseOperation.id) : navigateToQuotation(responseOperation.id, rq.id || '')) :
                navigateToSendRequest(responseOperation.id);
            }, 1500);
         
        } catch (error) {
            console.log("🚀 ~ file: Quoter.tsx:366 ~ createOperationWithQuote ~ error:", error)
            toast.error("Error al crear la operación, intente de nuevo más tarde");
        }
    }

    // method to navigate to send request other providers page
    const navigateToSendRequest = async (operationId: string) => {
    //    console.log("🚀 ~ file: Quoter.tsx:353 ~ navigateToSendRequest ~ operationId:", operationId)
        props.closeModal();
        navigation(`/${OPERATIONS}/${REQUEST_QUOTE}/${operationId}${!(props.fromPage === "serviceDetail" || props.fromPage === "chat")  ? "?sendOtherQuote=provider": ""}`, {replace: true});
    }
    
    const navigateToQuotation = async (operationId: string, requestQuoteId: string) => {
        // console.log("🚀 ~ file: Quoter.tsx:360 ~ navigateToQuotation ~ operationId:", operationId)
        props.closeModal();
        navigation(`/${OPERATIONS}/${operationId}/${requestQuoteId}/${QUOTATION}/unknown`, {replace: true});
     }

    // method to handle title change
    const handleTitleChange = (value: string) => {
        setRequestQuote((prev) => ({
            ...prev,
            title: value
        }));
    };

    const validateLclFields = (): boolean =>  {
        let error = false;
        
        if(!requestQuote.transport.lcl?.typeMeasure || requestQuote.transport.lcl?.typeMeasure === "volumen") { 
         
            error = ValidateField(requestQuote.transport.lcl?.unitWeight, 'required') ||
            ValidateField(requestQuote.transport.lcl?.unitVolume, 'required') ||
            ValidateField(requestQuote.transport.lcl?.cargoDescription, 'required');

            error = error || ValidateField(requestQuote.transport.lcl?.totalWeight, "number")  ||
            ValidateField(requestQuote.transport.lcl?.totalVolume, "number") 

            setRequestQuoteError((prev)=> ({
                ...prev,
               transportation: {
                    ...prev.transportation,
                    lcl: {
                        totalWeight: {
                            message: 'Ingrese peso total',
                            status: ValidateField(requestQuote.transport.lcl?.totalWeight, "number")  
                        },
                        unitWeight: {
                            message: 'Seleccione una unidad de peso',
                            status: ValidateField(requestQuote.transport.lcl?.unitWeight,'required'),
                        },
                        totalVolume: {
                            message: 'Ingrese volumen total',
                            status: ValidateField(requestQuote.transport.lcl?.totalVolume, "number") 
                        },
                        unitVolume: {
                            message: 'Seleccione una unidad de volumen',
                            status: ValidateField(requestQuote.transport.lcl?.unitVolume,'required'),
                        },
                        cargoDescription: {
                            message: 'Seleccione una descripción de la carga',
                            status: ValidateField(requestQuote.transport.lcl?.cargoDescription,'required')
                        },
                        packages: {
                            message: 'Complete los campo(s) vacio(s).',
                            status: error
                        }
                    }
               }
            }));
        } else if(requestQuote.transport.lcl?.typeMeasure === "paquestes") {
      
            requestQuote.transport.lcl.packages.forEach(p => {
                error = ValidateField(p.type, 'required') || ValidateField(p.quantity, 'required') || ValidateField(p.weight, 'required') 
                || ValidateField(p.unitWeight, 'required') || ValidateField(p.height, "required") ||
                ValidateField(p.width, 'required') || ValidateField(p.tall, 'required') || ValidateField(p.unitMeasurement, 'required');
            }); 

            setRequestQuoteError((prev:any)=> ({
                ...prev,
               transportation: {
                    ...prev.transportation,
                    lcl: {
                        ...prev.transportation.lcl,
                        packages: {
                            message: 'Complete los campo(s) vacio(s).',
                            status: error,
                        }
                    }
               }
            }));
        }
        return error;
    }

    // method to validate fcl fields
    const validateFclFields = (): boolean => {
        let error = false;
        error = ValidateField(requestQuote.transport.fcl, "array");
        setRequestQuoteError((prev) => ({
            ...prev,
            transportation: {
                ...prev.transportation,
                fcl: {
                    message: 'No hay ningún contenedor agregado todavía.',
                    status: ValidateField(requestQuote.transport.fcl, "array")
                }
            }
        }));
        return error;
    }

    // method to validate LclAir fields
    const validateLclAirFields = (): boolean => {
        let error = false

        if(requestQuote.transport.lclAir?.packingType === "paquetes") {
            error = error ||  ValidateField(requestQuote.transport.lclAir?.packages, "array");
        } 
        error = error || ValidateField(requestQuote.transport.lclAir?.weight, "number") || 
        ValidateField(requestQuote.transport.lclAir?.volume, "number");


        setRequestQuoteError((prev) => ({
            ...prev,
            transportation: {
                ...prev.transportation,
                lclAir: {
                    list:  {
                        message: 'No hay ningún bulto agregado todavía.',
                        status: ValidateField(requestQuote.transport.lclAir?.packages, "array"),
                    },
                    items: {
                        message: "Ingreso datos correctos",
                        status:  ValidateField(requestQuote.transport.lclAir?.weight, "number") || ValidateField(requestQuote.transport.lclAir?.volume, "number")
                    }
                }
            }
        }));
        return error;
    }

    // method to transport fields
    const validateFirstSectionRq = ():boolean => {
        let error = false;
        error = ValidateField(requestQuote.transport.type, 'notUndefined') || 
        !(requestQuote.transport.typeContainer?.fcl || requestQuote.transport.typeContainer?.lcl || requestQuote.transport.typeContainer?.lclAir );

        if(requestQuote.serviceTypeId === "courier" ) {
            let tmpErr = ValidateField(requestQuote.transport.warehouseOrigin, 'notUndefined') || ValidateField(requestQuote.transport.warehouseDestination, 'notUndefined');
            error = error || tmpErr;
        } else {
            error = error || ValidateField(requestQuote.transport.origin, 'notUndefined') || 
            ValidateField(requestQuote.transport.destination, 'notUndefined')
        }

        setRequestQuoteError((prev) => ({
            ...prev,
           transportation: {
            ...prev.transportation,
            type: {
                message: 'Seleccione un tipo de transporte',
                status: ValidateField(requestQuote.transport.type, 'notUndefined')
            },
            origin: {
                message: 'Seleccione un origen',
                status: ValidateField(requestQuote.transport.origin, 'notUndefined')
            },
            destination: {
                message: 'Seleccione un destino',
                status: ValidateField(requestQuote.transport.destination, 'notUndefined')
            },
           
            warehouseOrigin: {
                message: 'Seleccione un almacén de origen',
                status: ValidateField(requestQuote.transport.warehouseOrigin, 'notUndefined')
            },
            warehouseDestination: {
                message: 'Seleccione un almacén de destino',
                status: ValidateField(requestQuote.transport.warehouseDestination, 'notUndefined')
            },
            typeContainer: {
                status: (requestQuote.transport.typeContainer?.fcl || requestQuote.transport.typeContainer?.lcl || requestQuote.transport.typeContainer?.lclAir) ? false : true,
                message: "Selecciona un tipo de contenedor"
            }
           }
        }));

        if(requestQuote.transport.typeContainer?.lcl) {
            let tmpErr = validateLclFields();
            error = error || tmpErr;
        }
        if (requestQuote.transport.typeContainer?.fcl) {
            let tmpErr = validateFclFields()
            error = error || tmpErr;
        }
        if(requestQuote.transport.typeContainer?.lclAir) {
           
            let tmpErr = validateLclAirFields();
            
            error = error || tmpErr;
        }
        return error;
    }

    // method to validate second section
    const validateSecondSectionRq = () : boolean => {
        let error = false;
        error = ValidateField(requestQuote.customs.regime, 'notUndefined') || ValidateField(requestQuote.customs.incoterm, 'required') || 
        ValidateField(requestQuote.customs.value, 'number') || ValidateField(requestQuote.customs.coin, 'required');

        if(requestQuote.serviceTypeId !== "courier") {
            error = error || ValidateField(requestQuote.customs.insurance, 'boolean'); 
        } 
        
        setRequestQuoteError((prev) => ({
            ...prev,
            customs: {
                ...prev.customs,
                value: {
                    message: 'Ingrese un valor',
                    status: ValidateField(requestQuote.customs.value, 'number')
                },
                coin: {
                    message: 'Seleccione una moneda',
                    status: ValidateField(requestQuote.customs.coin,'required')
                },
                incoterm: {
                    message: 'Seleccione un incoterm',
                    status: ValidateField(requestQuote.customs.incoterm,'required')
                },
                insurance: {
                    message: 'Seleccione si desea seguro',
                    status: ValidateField(requestQuote.customs.insurance, 'boolean')
                },
                // purchaseLink: {
                //     message: 'Ingrese un link de compra',
                //     status: ValidateField(requestQuote.customs.purchaseLink, 'notUndefined')
                // }
            }
        }))

        return error;
    }
    
    // method to validate sections
    const handleValidateSections = () : boolean => {
        switch (props.stepProcess) {
            case 1:
                return validateFirstSectionRq();
            case 2: 
                return validateSecondSectionRq();
            case 3: 
                saveRequestQuote();
                return true;
            default:
                return true;
        }
    }

    // expose method to parent component
    useImperativeHandle(ref, () => ({
        handleTitleChange,
        handleValidateSections,
        saveDraft,
        saveRequestQuote
    }));

   
    // update service type id
    useEffect(() => {
        setRequestQuote((prev) => ({
            ...prev,
            serviceTypeId: props.serviceTypeId,
        }))
    }, [props.serviceTypeId]);

    // if request quote id is not external, get request quote by id
    useEffect(() => {
        isLoggedIn && props.requestQuoteUpdate && props.requestQuoteUpdate.id && setRequestQuote(props.requestQuoteUpdate);
    }, [props.requestQuoteUpdate]);

    // update title
    useEffect(() => {
        props.title !== requestQuote.title && setRequestQuote((prev) => ({
            ...prev,
            title: props.title,
        }));
    }, [props.title]);

    // update service selected
    useEffect(() => {  
        props.serviceSelected && props.serviceSelected.id !== requestQuote.serviceId &&
        setRequestQuote((prev) => ({
            ...prev,
            serviceId: props.serviceSelected?.id || '',
            companyId: props.serviceSelected?.companyId || '',
            companyName: props.serviceSelected?.companyName || '',
            numServiceComments: props.serviceSelected?.numComments || 0,
            numServiceRating: props.serviceSelected?.rating || 0,            
        }));
    }, [props.serviceSelected]);

    console.log(requestQuote)

    return (
        
        <div className="processRequestQuote-container">
            { !loading ? props.stepProcess === 1 ?  
                <QuoteTransport
                    requestQuoteId={requestQuote.id? requestQuote.id : ''}
                    transport={requestQuote.transport}
                    setRequestQuote={setRequestQuote}
                    transportError={requestQuoteError.transportation}
                    serviceId={requestQuote.serviceId}
                    serviceTypeId={requestQuote.serviceTypeId || ''} 
                    executeScroll={props.executeScroll}
                /> : 
                props.stepProcess === 2 ?
                <QuoteCustoms
                    requestQuoteId={requestQuote.id? requestQuote.id : ''}
                    customs={requestQuote.customs}
                    setRequestQuote={setRequestQuote}
                    error={requestQuoteError.customs} 
                    typeTransport={requestQuote.transport?.type}
                    serviceTypeId={requestQuote.serviceTypeId || ''}
                /> : 
                <QuoteResume 
                    requestQuote={requestQuote}
                /> 
            : <Loader />}
            {(!(props.fromPage === "serviceDetail" || props.fromPage === "chat") && showModalConfirm) && <ModalConfirm
                show={showModalConfirm}
                setShow={setShowModalConfirm}
                title="¡Tu solicitud fue enviada!"
                subTitle="¿Deseas cotizar con más agencias?"
                handleYes={()=>navigateToSendRequest(temOperationId)}
                handleNo={()=>setShowModalConfirm(false)} 
            />}
        </div>
    )
});

export default ProcessRequestQuote;