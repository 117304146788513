import React, {useState} from "react";

/** Importando componentes */
import "./Hover.css";

interface HoverProps {
    children?: React.ReactNode;
    show: boolean;
    setShow: (e:boolean) => void;
    title?: string;
    content: any;
}

export const Hover: React.FC<HoverProps> = ({
    children,
    show,
    setShow,
    title='',
    content,
}) => {

  return (
    <div className="hover">
        { show && 
            <div className='hover-text'>
                {title.length>0 && <div className="smalltext-header hover-color hover-title">{title}</div>}
                <div className="smalltext hover-color">
                    {content}
                </div>
            </div>
        }
        <div className="hover-content">
            {children}
        </div>
        
    </div>
  );
};
