import React, { useEffect, useRef, useState } from "react";

import { useNavigate, useLocation, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

/** importando componentes */
import { Button } from "../Button/Button";
import { Dropdown } from "../Dropdown/Dropdown";

/** importando tipos */
import { OperationType } from "../../@types/operationType";

/** importando iconos */
import { ArkabiaOp } from "../../icons/outline/ArkabiaOp";
import { BackArrow } from "../../icons/outline/BackArrow";
import { Dots } from "../../icons/outline/Dots";
import { HomeFill } from "../../icons/solid/HomeFill";
import { Home_white } from "../../icons/solid/Home_white";

// Importanto interfaces 
import { OperationInterface } from "../../interfaces/Operation";

/** importando constantes */
import { CUSTOMS, OPERATIONS, PROVIDER, RESUME, REQUEST_QUOTE, ORDER } from "../../Consts/BaseUrl";

/** importando estilos */
import "./OperationHeader.css";
import { OperationHeaderInterface } from "../../interfaces/OperationHeader";
import { ModalOperationHeader } from "./ModalOperationHeader/ModalOperationHeader";
import { ChangeOperationTitle, DeteleOperation, SendNotificationProvider } from "../../Services/Operation.service";
import { toast } from "react-toastify";
import { ModalConfirm } from "../Modal/ModalConfirm/ModalConfirm";

interface OperationHeaderProps {
  // orderId?: string;
  // operationName: string;
  teams: OperationHeaderInterface[]; //TODO ADD TEMAS TYPE
  onClickHome: ()=> void;
  isQuotation?: boolean;
  onClickBefore?: ()=>void;
  operationDetail?: OperationInterface;
  updateOperation?: ()=>void;
  showDots: boolean;
}

export const OperationHeader: React.FC<OperationHeaderProps> = ({
  // orderId = '',
  // operationName,
  teams,
  isQuotation = false,
  operationDetail,
  onClickHome,
  onClickBefore,
  updateOperation=()=>{},
  showDots
}) => {

  let ref = useRef<HTMLDivElement>(null);

  let navigation = useNavigate();
  let params = useParams();
  const location = useLocation();

  const [activeHome, setACtiveHome] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModalEditTitle, setShowModalEditTitle] = useState(false);
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);

  const [newTitle, setNewTitle] = useState<string>('');

  const [activeTeam, setActiveTeam] = useState<string>(operationDetail?.orderId || '');

  const { operationList } = useSelector((state: any) => state.operation);
  const { userDetail } = useSelector((state: any) => state.user);
  

  const handleBeforeClick = ()=> {
    let urlLocation =  (location.pathname).split('/');
     if(urlLocation.length>=2 && urlLocation[1] === PROVIDER) {
      navigation(`/${PROVIDER}/${OPERATIONS}`);
    } else {
      navigation('/'+OPERATIONS);
    }
  }

  // handle push requestQuote
  const handleTeam = (id: string | undefined) => {
    if(isQuotation) {
      navigation('/'+OPERATIONS+'/'+REQUEST_QUOTE+'/'+id);
      // console.log('estoy en cotizacion')
    } 
  }

  const getActiveUrl = () => {
    let urlLocation =  (location.pathname).split('/');
  
    if(urlLocation.length>2 && (urlLocation[2] === CUSTOMS && urlLocation[3] === RESUME)) {
      setACtiveHome(true);
    } else {
      setACtiveHome(false);
    }
  }

  const handleTeamClick = (operationId: string, orderId: string) => {
    setActiveTeam(orderId);
    navigation('/'+OPERATIONS+'/'+operationId+'/'+ORDER+'/'+ orderId + '/1'); // pasar id del resumen
  }

  // recupera el servicio de redux segun id
  const  GetLocalOperationDetail = (id: string) => {
    return operationList.find((o:OperationInterface) => (o.id === id));
  }

  const handleClickCancel = () => {
    setShowModalEditTitle(false);
    setNewTitle('');
  };

  const sendChangeTitleNotification = async (lastTitle: string, newtitle: string) => { 
    if(operationDetail && operationDetail.id) {
        try {
          const response = await SendNotificationProvider("Actualización", `${userDetail.name} ${userDetail.lastName} ha cambiado el título de la operación "${lastTitle}" a "${newtitle}"`,
          "Actualización", operationDetail.id, userDetail.name+" "+userDetail.lastName, userDetail.photoURL, 'editOperation', '/operaciones', operationDetail.status.order ? 'order' : 'requestQuote',
          operationDetail.status.order ? operationDetail.orders.map((order)=>order.id) : operationDetail.requestQuotes.map((requestQuote)=>requestQuote.id))
        } catch (error) {
          console.log("🚀 ~ file: OperationHeader.tsx:130 ~ sendChangeTitleNotification ~ error:", error)
        }
    }
  }

  // metodo para modificar el titulo de la operacion
  const handleEditOperationTitle = async () => {  
    if (newTitle && newTitle.length > 0 && newTitle !== operationDetail?.title  && operationDetail && operationDetail.id) {
        setLoading(true);
        // tmp to send notificacion to provider
        const lastTitle = operationDetail.title;
        const newtitle = newTitle;

        ChangeOperationTitle(
          operationDetail.id,
          newTitle.trim()
        ).then(async (response) => {
          updateOperation();
          await sendChangeTitleNotification(lastTitle, newtitle);
          setShowModalEditTitle(false);
          setLoading(false);
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } 
  };

  const handlenewtitlechange = (value: string) => {
    if(value.length<=42) {
      setNewTitle(value);
    }
  }

  const handleShowModalConfirmDelete = () => { 
    setShowModalConfirmDelete(true);
    setOpenOption(false);
  }
  
  // metodo para eliminar la operacion
  const handleDeleteOperationClick = async () => {
    if (operationDetail && operationDetail.id) {
      // console.log("eliminando operacion "+operationDetail.id);
      setLoading(true);
      handleClickCancel();
      try {
        const response = await DeteleOperation(operationDetail.id);
        toast.success("Operación eliminada correctamente");
        // console.log("🚀 ~ file: OrdersQuotation.tsx ~ line 237 ~ handleDeleteOperationClick ~ response", response);
        setTimeout(() => {
          setLoading(false);
          navigation("/" + OPERATIONS);
        }, 2000);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getActiveUrl();
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if(openOption && ref.current && !ref.current.contains(e.target)) {
        setOpenOption(false);
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [openOption]);

  useEffect(() => {
    setNewTitle(operationDetail?.title || '');
  }, [operationDetail])

  // console.log(activeTeam === operationDetail?.orderId, operationDetail?.orderId);

  // console.log("🚀 ~ file: OperationHeader.tsx ~ line 191 ~ OperationHeader ~ operationDetail", (teams.map((team: OperationHeaderInterface, idx) => (
  //   operationDetail?.orderId === team.orderId ? 'Si' : 'No'
  // ))))



  return (
    <div className="operation-header" role='operation-header'>
      <div className="operation-header-left">
        <div 
          className="operation-header-left-content" 
          onClick={()=>!onClickBefore ? handleBeforeClick(): onClickBefore() }>
          <div className="operation-header-arrow-back">
            <BackArrow />
          </div>
          <div className="operation-title-container">
            <div className="arkabia-op-icon">
              <ArkabiaOp />
            </div>
            <div className="operation-title-content">
              <h2 className="paragraph-header operation-title-order">{operationDetail?.title}</h2>
              <h3 className="paragraph operation-title-type-operation">{operationDetail?.requestQuoteType}</h3>
            </div>
          </div>
        </div>

       {showDots && <div className="operation-header-options" onClick={()=> setOpenOption(!openOption)} >
          <Dots style={{display: 'flex', justifyContent: 'end'}}/>
        </div>}
      </div>

      {(window.innerWidth <= 768 && teams.length>=1) || (window.innerWidth > 769 && teams.length<=1) && <div className={"operation-header-right"}>
      {/* <div className={(teams.length >= 1) && window.innerWidth <= 768 ? "operation-header-right-nothing" : "operation-header-right"}> */}
        <div className="operation-header-right-content">
          <div className={"operation-header-home " + ( activeHome && ' operation-header-home--active')} onClick={()=>onClickHome()} >
            {activeHome ? 
            <Home_white  /> :
            <HomeFill />}
          </div>
          <div className="operation-header-right-dropdown">
            {<Dropdown
              title=""
              elements={teams.map((team, idx) => (team.teamName))}
              value={ teams && teams.length>0 ? (teams.find((team, idx) =>  activeTeam === team.orderId)?.teamName+"") : ''}
              setValue={() => {}}
            />}
          </div>

          {/* <h3 className="smalltext-header operation-header-right-team">Equipos</h3> */}
          
          <div className={teams.length===20?"operations-header-right-teams":'operations-header-right-teams-small' }>
            {teams.map((team: OperationHeaderInterface, idx) => (
              <Button key={idx} onClick={()=>handleTeamClick(team.operationId, team.orderId)} content={team.teamName} size="small" 
                      className={`${!activeHome && operationDetail?.orderId === team.orderId? 'btn-agency-add-active' : 'btn-agency-add'} smalltext-header`}/>
            ))}
          </div>

          {/* {
          teams.length <20 && 
            <Button className="smalltext-header btn-plus" 
                    content="+ Añadir equipo" 
                    size="only-text" 
                    onClick={()=>handleTeam(params.operationId)}
            />
          } */}
        </div>
        
       {showDots && <div className="operation-more-options"onClick={()=> setOpenOption(!openOption)} >
          <Dots/>
        </div> }
      </div>}
      { openOption && <div className="operation-option-header" ref={ref}>
            <div className="operation-option-figure"></div>
            <div className="operation-option-body">
              <div className="smalltext-header operation-option-body-item" onClick={()=>setShowModalEditTitle(true)}>
                Editar título
              </div>
              {operationDetail?.status.requestQuote && <div className="smalltext-header operation-option-body-item" onClick={()=> handleShowModalConfirmDelete()}>
                Eliminar
              </div>}
            </div>
        </div> }

        <ModalOperationHeader
          show={showModalEditTitle}
          setShow={setShowModalEditTitle}
          setTitle={(e: any) => handlenewtitlechange(e)}
          title={newTitle}
          editOperation
          onClickCancel={handleClickCancel}
          onClickSave={handleEditOperationTitle}
          loading={loading}
        />

        <ModalConfirm 
          handleNo={()=> setShowModalConfirmDelete(false)}
          handleYes={()=> handleDeleteOperationClick()}
          show={showModalConfirmDelete}
          setShow={setShowModalConfirmDelete}
          title="¿Deseas eliminar la operación? "
          subTitle={`Se eliminará definitivamente "${operationDetail?.title}" de tu Panel principal.`}
          loading={loading}
        />

    </div>
  );
}