import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

// Importando servicios
import { GetQuotationDetail } from "../Services/Quotation.service";

// metodo para obtener la cotizacion por ID 
export const GetQuotationById = createAsyncThunk(
    'quotation/getById',
    async (id: string, thunkAPI: any) => {
        try {
            const response = await GetQuotationDetail(id);
            // console.log("GetServicesActive", response)
            if(response) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response
            }
        } catch (error) {
            console.log("slice/quotation/getById => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error, code: 'not-fount' }));  
            return thunkAPI.rejectWithValue();
        }
    }
)

const initialState = {
    quotationDetail: {}
}

const quotationDetail = createSlice({
    name: "quotation",
    initialState,
    reducers: {},
    extraReducers: {
        [GetQuotationById.fulfilled.toString()]: (state, action) => {
            state.quotationDetail = action.payload
        },
        [GetQuotationById.rejected.toString()]: (state, action) => {
            state.quotationDetail = {}
        },
    }
})

const {reducer} = quotationDetail;
export default reducer;