import React, { useEffect, useState } from "react";

import {  useNavigate, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

/** import components */
import { Button } from "../../../../components";
import { OperationHeader } from "../../../../components";
import { Row } from "../../../../components/Row/Row";
import { RatingSlider } from "../../../../components";
import { TagButton } from "../../../../components/Button/Tag/TagButton";
import { MedalRating } from "../../../../icons/outline/MedalRating";
import { TableQuotation } from "../../../../components/Table/Quotation/TableQuotation";
import { Table } from "../../../../components/Table/Table";
import { ModalToken } from "../../../../components/Tokens/ModalToken/ModalToken";
import { PaySuccessful } from "../../../../components/PaySuccessful/PaySuccessful";

/** import fake data */

// importando interfaces
import { OperationInterface } from "../../../../interfaces/Operation";
import { CompareQuotationInterface } from "../../../../interfaces/CompareQuotation";

// Importando store
import type { AppDispatch } from "../../../../Store/Store";

// importando slices 
import { GetOperationDetail } from "../../../../Slices/Operation";

// importando servicios
import { GetCompareQuotation, HireQuotation } from "../../../../Services/Quotation.service";

/** import styles */
import './CompareQuotationPage.css';
import { CHAT, HELP_MODAL_COMPARE, OPERATIONS, ORDER, QUOTATION } from "../../../../Consts/BaseUrl";
import { toast } from "react-toastify";
import { GetRequestQuoteById, GetRequestQuoteByIds } from "../../../../Services/RequestQuote.service";
import { ButtonBadget } from "../../../../components/ButtonBadget/ButtonBadget";
import { MessageBlack } from "../../../../icons/outline/MessageBlack";
import { GetChatId } from "../../../../Services/Chat.service";
import { ModalComplete } from "../../../../components/Modal/ModalComplete/ModalComplete";
import { ModalAnimation } from "../../../../components/Modal/ModalAnimation/ModalAnimation";
import { GetLocalStorage, SetLocalStorage } from "../../../../LocalStorange/LocalStorange";
import { LoaderShimmerTable } from "../../../../components/LoaderShimmerTable/LoaderShimmerTable";


/** interface */
interface CompareQuotationPageProps {
}

export const CompareQuotationPage: React.FC<CompareQuotationPageProps> = () => {
    const params = useParams();
    const navigation = useNavigate();

    const {operationList, operationDetail} = useSelector((state: any) => state.operation);
    const { userDetail } = useSelector((state: any) => state.user);


    const dispatch = useDispatch<AppDispatch>();

    const ref = React.useRef<HTMLDivElement>(null);


    const [numQuotation, setNumQuotation] = useState(3);
    const [showModalToken, setShowModalToken] = useState(false);
    const [showModalThanks, setShowModalThanks] = useState(false);

    const [showModalAnimation, setShowModalAnimation] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [operation, setOperation] = useState<OperationInterface>();
    const [compare, setcompare] = useState<any>();
    const [messages, setMessages] = useState<{
        num: number;
        id: string,
    }[]>([]);

    const [cheapestIdx, setCheapestIdx] = useState<number>(0);
    const [mostExpensiveIdx, setMostExpensiveIdx] = useState<number>(0);

    const [showModalComplete, setShowModalComplete] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState<string>('');
    const [quotationId, setQuotationId] = useState<string>('');
    const [requestQuoteId, setRequestQuoteId] = useState<string>('');


    // const handleClickToken = () => {
    //     setShowModalToken(true);
    // }
   
    const handleClickModalThanks = () => {
        setShowModalThanks(true);
        setShowModalToken(false);
    }

    const handleBackModalToken = () => {
        setShowModalToken(true);
        setShowModalThanks(false);
    }

    // methos to get operation from firestore
    const getOperation = (operationId: string) => {
        setLoading(true);
        // si existe lista de operacion esta en redux obtengo la operacion
        if(operationList && operationList.length>0) { 
            setOperation(operationList.find((o:OperationInterface) => (o.id === operationId)));
        } else if(operationDetail && operationDetail.id === operationId) { // si existe el objeto operationDetail en redux obtengo la operacion
            setOperation(operationDetail);
        } else { //  si no existe ninguna de las dos obtengo la operacion de firestore
            setLoading(false);
            if(!loading) {
                setLoading(true);
                dispatch(GetOperationDetail(operationId))
                .unwrap()
                .then((response) => {
                    if(response.userId === userDetail.id) {
                        setOperation(response);
                        setLoading(false); 
                    } else {
                        setLoading(false);
                        toast.error("No tienes permisos para ver esta operación");
                        navigation("/"+OPERATIONS);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                })
            }  
        }
        setLoading(false);
    }

    const getQuotations = async () => {
        if(operation) {
          try {
            setLoading(true);
            const response:any = await GetCompareQuotation(operation.id?operation.id:'');
            getFormatData(response);
            getChatByRequestQuote(response.rqIds);
            setNumQuotation(response.total.length)
            setTimeout(() => {
                getHelpModalStatus();
            }, 5000);
          } catch (error) {
            console.log("🚀 ~ file: CompareQuotationPage.tsx ~ line 119 ~ getQuotations ~ error", error)
            toast.error("Ocurrio un error");
          }
        }
    }

    const getChatByRequestQuote =async (ids: string[])=> {
        const respRq = await GetRequestQuoteByIds(ids);
        
        if(respRq.length === 0) return;

        let tmp: {
            num: number;
            id: string,
        }[] = ids.map((id) => ({id: '', num: 0}));

        respRq.forEach(async (rq) => {
            
            const chat: any = await GetChatId(rq.chatId);
            if(chat) {
                let num =  chat.members.find((member: any) => (member.userId === userDetail.id)).unreadMessages || 0;
         
                tmp[ids.indexOf(rq.id)].num = num;
                tmp[ids.indexOf(rq.id)].id = rq.chatId;
       
            }
            setMessages(tmp);
        });
        
    }

    const getMaxAndMinPrice = (data: number[]) => {
        let max = Math.max(...data);
        let min = Math.min(...data);
        let maxIdx = data.indexOf(max);
        let minIdx = data.indexOf(min);
        setMostExpensiveIdx(maxIdx);
        setCheapestIdx(minIdx);
    }

    const getFormatData = (data: CompareQuotationInterface) => {
        // console.log("🚀 ~ file: CompareQuotationPage.tsx ~ line 127 ~ getFormatData ~ data", data)
        getMaxAndMinPrice(data.total);
        setcompare({
            ids: data.ids,
            rqIds: data.rqIds,
            logos: data.logos,
            serviceOrigin: {
                items: [
                    (data.serviceOrigin.internationalTransport && !data.serviceOrigin.internationalTransport.every(e => e ===0)) &&
                    {
                        name: 'Transporte internacional', 
                        value: data.serviceOrigin.internationalTransport
                    },
                    {
                        name: 'Seguro', 
                        value: data.serviceOrigin.insurance
                    },

                    (data.serviceOrigin.expensiveOrigin && !data.serviceOrigin.expensiveOrigin.every(e => Number(e) ===0)) &&
                    {
                        name: 'Gastos de origen', 
                        value: data.serviceOrigin.expensiveOrigin
                    }   
                ],
                subtotal: data.serviceOrigin.subTotal,
            },
            serviceDestination: {
                items: [
                    // {
                    //     name: 'Almacenaje',
                    //     value: data.serviceDestination.storage
                    // },
                    (data.serviceDestination.approval && !data.serviceDestination.approval.every(e => e ===0)) &&
                    {
                        name: 'Visto bueno',
                        value: data.serviceDestination.approval
                    },

                    (data.serviceDestination.storage && !data.serviceDestination.storage.every(e => e ===0)) &&
                    {
                        name: 'Almacén',
                        value: data.serviceDestination.storage
                    },

                    (data.serviceDestination.deconsolidation && !data.serviceDestination.deconsolidation.every(e => e ===0)) &&
                    {
                        name: 'Desconsolidación',
                        value: data.serviceDestination.deconsolidation
                    },

                    (data.serviceDestination.handling && data.serviceDestination.handling.length > 0 && !data.serviceDestination.handling.every(e => e ===0)) &&
                    {
                        name: 'Handling',
                        value: data.serviceDestination.handling
                    },
                    
                    (data.serviceDestination.storageExpenses && !data.serviceDestination.storageExpenses.every(e => e ===0)) &&
                    {
                        name: 'Gastos de almacenaje',
                        value: data.serviceDestination.storageExpenses
                    },

                    (data.serviceDestination.operationExpensive && !data.serviceDestination.operationExpensive.every(e => e ===0)) &&
                    {
                        name: 'Gastos operativos',
                        value: data.serviceDestination.operationExpensive
                    },

                    (data.serviceDestination.administrativeExpensive && !data.serviceDestination.administrativeExpensive.every(e => e ===0)) &&
                    {
                        name: 'Gastos administrativos',
                        value: data.serviceDestination.administrativeExpensive
                    },

                    (data.serviceDestination.desaduanaje && !data.serviceDestination.desaduanaje.every(e => e ===0)) &&
                    {
                        name: 'Desadunaje',
                        value: data.serviceDestination.desaduanaje
                    },

                    (data.serviceDestination.localTransport && !data.serviceDestination.localTransport.every(e => e ===0)) &&
                    {
                        name: 'Transporte local',
                        value: data.serviceDestination.localTransport
                    },

                    (data.serviceDestination.commissionCustoms && !data.serviceDestination.commissionCustoms.every(e => e === 0)) &&
                    {
                        name: 'Comisión por agenciamiento de aduana',
                        value: data.serviceDestination.commissionCustoms
                    },
                    
                    (data.serviceDestination.onCosts && !data.serviceDestination.onCosts.every(e => e ===0)) &&
                    {
                        name: 'Sobrecostos',
                        value: data.serviceDestination.onCosts
                    },
                   
                    (data.serviceDestination.returnContain && data.serviceDestination.returnContain.length>0 && !data.serviceDestination.returnContain.every(e => e ===0)) &&
                    {
                        name: 'Devolución de contenedor',
                        value: data.serviceDestination.returnContain
                    },

                    (data.serviceDestination.otherExpenses && !data.serviceDestination.otherExpenses.every(e => e ===0)) &&
                    {
                        name: 'Otros gastos',
                        value: data.serviceDestination.otherExpenses
                    },
                    {
                        name: 'IGV (18%)',
                        value: data.serviceDestination.tax
                    }
                ],
                subtotal: data.serviceDestination.subTotal,
            },
            total: data.total,
            tax: {
                items: [
                    {
                        name: 'Ad Valorem',
                        value: data.taxes.adValorem
                    },
                    {
                        name: 'IGV (16%)',
                        value: data.taxes.igv
                    },
                    {
                        name: 'IPM (2%)',
                        value: data.taxes.ipm
                    },

                    (data.taxes.perception && !data.taxes.perception.every(e => e ===0)) &&
                    {
                        name: 'Percepción (3.5%)',
                        value: data.taxes.perception
                    },

                    (!operation?.requestQuoteType && data.taxes.isc && !data.taxes.isc.every(e => e ===0)) &&
                    {
                        name: 'Impuesto Selectivo al Consumo',
                        value: data.taxes.isc
                    },

                    (!operation?.requestQuoteType && data.taxes.specificRight && !data.taxes.specificRight.every(e => e ===0)) &&
                    {
                        name: 'Derecho Específicos',
                        value: data.taxes.specificRight
                    },

                    (!operation?.requestQuoteType && data.taxes.antidumpi && !data.taxes.antidumpi.every(e => e ===0)) &&
                    {
                        name: 'Derecho Antidumping',
                        value: data.taxes.antidumpi
                    },

                    (!operation?.requestQuoteType && data.taxes.surcharge && !data.taxes.surcharge.every(e => e ===0)) &&
                    {
                        name: 'Sobretasa',
                        value: data.taxes.surcharge
                    }
                ],
                total: data.taxes.subTotal
            },
            restriction: {
                items: [
                    {
                        name: '¿Requiere permisos?',
                        value: data.restrictions
                    }
                
                ],
            },
            qualification: data.serviceRating,
            details: [
                {
                    name: "Tiempo de tránsito",
                    value: data.detail.transitTime
                },

                (data.detail.storageBase) &&
                {
                    name: "Días libres de almacenamiento",
                    value: data.detail.storageBase
                },

                {
                    name: "Vencimiento de cotización",
                    value: data.detail.expiration
                },
                
                // (data.detail.aboutStay && data.detail.aboutStay.length>0) &&
                // {
                //     name: 'Sobreestadía',
                //     value: data.detail.aboutStay
                // },
            ],
        });
        setLoading(false);
    }   

    const navigateToDetail = (quotationId: string, requestQuoteId: string) => {
        navigation(`/${OPERATIONS}/${operation?.id}/${requestQuoteId}/${QUOTATION}/${quotationId}`)
    }

    const navigateToChatDetail = (chatId: string) => {
        navigation(`/${CHAT}?activeChat=${chatId}`)
    }

    // show modal for first time
    const getHelpModalStatus = () => {
        const activeHelpModal = GetLocalStorage(HELP_MODAL_COMPARE);
        setShowModalAnimation(!activeHelpModal);
        SetLocalStorage(HELP_MODAL_COMPARE, true);
    }

    // method to 
    const handleReservationClick = async (quotationId: string, requestQuoteId: string) => { 
        setQuotationId(quotationId);
        setRequestQuoteId(requestQuoteId);
        try {
            const requestQuote: any = await GetRequestQuoteById(requestQuoteId);
            setCompanyName(requestQuote.companyName);
            setShowModalComplete(true);
        } catch (error) {
            console.log("🚀 ~ file: CompareQuotationPage.tsx:395 ~ handleReservationClick ~ error:", error)
            
        }
    }

    // method to hire quote
    const handleClickHireQuote = async () => {
        if(operation && quotationId.length>0 && requestQuoteId.length>0) { 
            setLoading(true);
            try {
                const requestQuote: any = await GetRequestQuoteById(requestQuoteId);
                toast.success("Reservando servicio...");
                const data: {
                    requestQuoteId: string;
                    operationId: string;
                    quotationId: string;
                    serviceId: string;
                    title: string;
                    type: string;
                    price: string;
                    serviceTypeId: string;
                    serviceTypeLabel: string;
                } = {
                    requestQuoteId: requestQuoteId,
                    operationId: operation?.id ? operation.id : "",
                    quotationId: quotationId,
                    serviceId: requestQuote.serviceId,
                    title: operation?.title ? operation.title : "",
                    type: operation?.type ? operation.type : "",
                    price: requestQuote.price,
                    serviceTypeId: requestQuote.serviceTypeId,
                    serviceTypeLabel: requestQuote.serviceType ? requestQuote.serviceType : "",
                };
                setLoading(false);
                HireQuotation(data)
                .then((response: any) => {
                    if (response) {
                        // console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:362 ~ .then ~ response:", response)
                        toast.success("¡Servicio contratado!");
                        setTimeout(() => {
                            setLoading(false);
                            navigation(`/${OPERATIONS}/${data.operationId}/${ORDER}/${response}/1`)
                        }, 2500);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error("Hubo un error al contratar :(");
                    setLoading(false);
                });
                setShowModalComplete(false);
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: CompareQuotationPage.tsx:319 ~ handleClickHireQuote ~ error:", error)
                toast.error("Hubo un error al contratar, por favor intentelo mas tarde");
            }
        }
    }

    const handleImgCompare = (quotationId: string, requestQuoteId: string) => {
        navigation(`/${OPERATIONS}/${operation?.id}/${requestQuoteId}/${QUOTATION}/${quotationId}`)
    }   

    useEffect(()=>{
        window.document.body.style.overflow =  showModalAnimation ? 'hidden' : 'unset';
      },[showModalAnimation]);

    useEffect(() => {       
        if(params.operationId) {
            getOperation(params.operationId);
        }
    }, [params.operationId]);

    useEffect(() => {
        getQuotations();
       
    }, [operation]);

    return (
        <div className='compareQuotation-main' >
            {/* <Navbar />*/}
            <OperationHeader    
                operationDetail={operation}
                teams={[]}
                onClickHome={()=>{}}
                updateOperation={()=> getOperation(operation?.id || '')}
                showDots={false}
            /> 
                <div className="compareQuotation-content" >
                    {/* <div className='compareQuotation-nav'>
                        <GobackButton />
                        {/* <div className='compareQuotation-numQuotation'>
                            <div className='smalltext-header '>({numQuotation} de 3 cotizaciones disponibles)</div> */}
                            {/* <Button  content='¿Más cotizaciones?' size='normal' color='yellow' onClick={()=>handleClickToken()}/> */}
                        {/* </div> 
                    </div> */}
                    
                    {!loading && compare ?<div className='compareQuotation-container' ref={ref} >
                    <div className='compareQuotation-more'>
                        <Row
                            title=''
                            color='transparent'
                            columValues={compare.total.map((item:any, idx:number)=>( idx === mostExpensiveIdx ? 
                                (<TagButton onClick={()=>navigateToDetail(compare.ids[idx], compare.rqIds[idx])} content='Más caro' color='red' />) : 
                                (idx === cheapestIdx ? <TagButton onClick={()=>navigateToDetail(compare.ids[idx], compare.rqIds[idx])} content='Más barato' color='green-2'/> : <></>)))}
                            content="text"
                        />
                    </div>
                    <TableQuotation 
                        head={{
                            title: "Conceptos",
                            content: 'image',
                            color: 'white',
                            values: compare.logos
                        }}
                        block1={{
                            title: 'Servicio en origen',
                            values: compare.serviceOrigin.items,
                            subtotal: compare.serviceOrigin.subtotal
                        }}
                        block2={{
                            title: 'Servicio de destino',
                            values: compare.serviceDestination.items,
                            subtotal: compare.serviceDestination.subtotal
                        }}
                        block3={{
                            title: 'Impuestos',
                            values: compare.tax.items,
                            subtotal: compare.tax.total
                        }}
                        total={compare.total}
                        handleImg={(idx:number)=>handleImgCompare(compare.ids[idx], compare.rqIds[idx])}
                    />
                    
                    {/* <Table 
                        header='Impuestos'
                        body={compare.tax.items}
                        footer={{
                            title:'Total USD',
                            values: compare.tax.total
                        }}
                    /> */}

                    <Row 
                        color="transparent"
                        title={<></>}
                        columValues={compare.ids.map((item: string, idx:number) => 
                            (<Button content="Reservar" color="black-75" onClick={()=>handleReservationClick(item, compare.rqIds[idx])} />))}
                        content='text'
                    />
                
                    {!loading && compare.restriction.items.length>0 && <Table 
                        header='Restricciones'
                        body={compare.restriction.items}
                        content= 'text'
                    />}
                   
                    <Table 
                        header='Detalles'
                        body={compare.details}
                        content= 'text'
                    />
                    <div className='compareQuotation-table box-shadow-card'>
                        <Row
                            title={<div className='paragraph-header quotationCompare-row'>Puntaje de servicio <MedalRating/> </div>}
                            color='white-90'
                            columValues={compare.qualification.map((item:any)=> (
                            <div className='compareQuotation-rating'>
                                <RatingSlider rating={item} /> 
                                <div className='paragraph'>{(item).toFixed(1)}</div>
                                <div className='compareQuotation-rating-icon'>
                                    <img src="/images/estrella.png" alt="estrella-arkabia"/>
                                </div>
                            </div>))}
                            content="text"
                        /> 
                    </div>
                    
                    {/* <div className='compareQuotation-content-button-msg'>
                    
                        <Button content="Contratar" color="black-75" />
                    </div> */}

                     <div className='compareQuotation-content-button-msg'>
                        <Row
                            title=''
                            color='transparent'
                            columValues={messages.map((m)=> (
                                <div className='compareQuotation-message'>
                                    <ButtonBadget
                                        name={
                                            window.innerWidth<=768 ? <MessageBlack/> : "Ver mensajes"
                                        }
                                        handleBadget={()=>navigateToChatDetail(m.id)}
                                        badget={m.num}
                                    />
                                </div>))}
                            content="text"
                        />
                    </div>
                    {/*</div> */}
                </div> : 
                <div className="compareQuotation-shimmer">
                    <LoaderShimmerTable/>
                    <LoaderShimmerTable/>
                    <LoaderShimmerTable/> 
                </div>}
            </div>

            <ModalToken show={showModalToken}
                        setShow={setShowModalToken}
                        handleClickModalThanks={handleClickModalThanks}/>

            <PaySuccessful show={showModalThanks}
                           setShow={setShowModalThanks}
                           companyName="Arkabia"
                           amount={5.00}
                           handleBack={handleBackModalToken}/>

            <ModalComplete
                showM={showModalComplete}
                setShowM={setShowModalComplete}
                handleClick={() => {
                    setShowModalComplete(false);
                }}
                companyName={companyName}
                handleConfirm={handleClickHireQuote}
                loading={loading}
            />

           { showModalAnimation && 
           <ModalAnimation
                show={showModalAnimation}
                setShow={setShowModalAnimation}
                animation={[
                    { 
                        path: '/animations/Desktop/Web_Organiza_1694px/organiza_1694px.json',
                        title: '¿Por qué reservar una agencia por aquí?',
                        image: '',
                        description: 'Al reservar, la agencia respetará el precio que cotizó.',
                        description2: 'Es gratis y no se te pedirá ninguna tarjeta de crédito.',
                        // description3: '¡Incluso verás en donde está tu barco en tiempo real!'
                    }
            ]}
            />}
        </div>
    )
}