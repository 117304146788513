export const FilterCategory: string[] = [
    "Madera",
    "Minerales",
    "Agro",
    "Alimentos de origen animal",
    "Alimentos de origen vegetal",
    "Sustancias peligrosas",
    "Alimentos y bebidas procesadas",
    "Pieles y cueros",
    "Animales vivos",
    "Armas, municiones y explosivos",
    "Insumos para la industria alimentaria",
    "Juegos y juguetes",
    "Relojes, joyas y accesorios",
    "Tecnología",
    "Petróleo y gas",
    "Arte y antigüedades",
    "Salud y medicina",
    "Artesanía",
    "Sidero metalúrgico",
    "Plástico y caucho",
    "Vehículos",
    "Bebidas alcohólicas y tabáco",
    "Belleza y cuidado personal",
    "Telecomunicaciones",
    "Moda y calzado",
    "Repuestos, partes y accesorios de vehículos",
    "Productos veterinarios",
    "Casino y tragamonedas",
    "Textilería",
    "Dispositivos de audio y video",
    "Suministros de oficina",
    "Electrodomésticos",
    "Equipo y maquinaria industrial",
    "Equipos e instrumentos médicos",
    "Vidrio",
    "Papel y cartón",
    "Herramientas, equipos y materiales para construcción",
    "Hogar y muebles",
    "Ropa y calzado industrial",
    "Instrumentos musicales",
]

export const FilterCountry: string[] = [
    "China", "EE.UU", "Todos"
]