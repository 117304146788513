import React, { useEffect, useRef } from "react";

import './FinalDirection.css';

interface FinalDirectionProps {
    textInput?: any;
    placeholder?: string;
    value: string;
    handleInputchange?: (value: string) => void;
    disabled?: boolean;
    showOptional?: boolean;
}
  
export const FinalDirection: React.FC<FinalDirectionProps> = ({
    textInput='',
    value,
    placeholder='',
    handleInputchange=()=>{},
    disabled=false,
    showOptional=true
}) => {

    // ref parent div
  const ref = useRef<HTMLDivElement>(null);
  // ref input
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  // use state to show input
  const [showInput, setShowInput] = React.useState<boolean>(false);

  const [showPlaceholder, setShowPlaceholder] = React.useState<boolean>(true);

  // method to handle input change
  const handleChanhge = (e: any) => {
    // console.log(e.target.innerHTML);
      let value = e.target.innerHTML;
      handleInputchange(value.trim());
  }

  const handleOnKeyDown = (e: any) => {
    let value = e.target.innerHTML;
    setShowPlaceholder(value.length>=0 ? false : true);
  }
  


  // method to handle click
  const handleClick = () => {
      setShowInput(true);
      setTimeout(() => {
        textAreaRef.current?.focus();
        // setShowPlaceholder(false);
      }, 300);
  }

  useEffect(() => {
      const checkIfClickedOutside = (e: any) => {
        if(showInput && ref.current && !ref.current.contains(e.target)) {
          setTimeout(() => {
            setShowInput(false);
          }, 300);
          // setText(placeholder);
        }
      }
  
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showInput]);

  

    return (
        <div ref={ref} className={`${!showInput ? "finalDirection-container-placeholder" : "finalDirection-container"} ${disabled ? "finalDirection-container--disabled" : ""}`}
          onClick={()=> !disabled ? handleClick() : {}}>
            <div className="finalDirection-row">
                <div className="smalltext-header finalDirection-text ">{textInput} {showOptional &&  <span className="">(Opcional)</span>} </div>
            </div>
            <div className="finalDirection-row" >
                {!showInput  ? <div className="finalDirection-placeholder finalDirection-placeholder-two" dangerouslySetInnerHTML={{__html: placeholder === value ? '' : value}} ></div> :
                <div>
                  {showPlaceholder &&<label className="paragraph finalDirection-placeholder">{placeholder}</label>}
                  <span dangerouslySetInnerHTML={{__html: placeholder === value ? '' : value}} ref={textAreaRef} 
                    className="paragraph finalDirection-textArea" 
                    title="title" 
                    // place={placeholder} 
                    role="textbox"
                    contentEditable={!disabled}
                    suppressContentEditableWarning={true}
                    onBlur={(e) => handleChanhge(e)}
                    onKeyDown={(e) => handleOnKeyDown(e)}
                  ></span>
                </div>
                // <textarea ref={textAreaRef} className="finalDirection-input" placeholder={placeholder} value={placeholder === text ? '' : text} maxLength={300} onChange={handleChanhge}/>
                }
            </div>
        </div>
    )
}