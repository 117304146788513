import { User } from "../interfaces/User";

export const UserList: User[] = [
    // {
    //     id: 1,
    //     name: "Juan",
    //     userName: "@juanSanchez",
    //     lastName: 'Sanchez',
    //     email: 'juansanchez@gmail.com',
    //     photoUrl: 'https://images.pexels.com/photos/32976/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    //     request: false,
    //     country: {
    //         alpha2Code:'',
    //         name: 'Peru',
    //         imgFlag: ''
    //     },
    //     password: ''
    // },
    // {
    //     id: 2,
    //     name: "Alexandra",
    //     userName: "@alexandraRodriguez",
    //     lastName: 'Rodriguez',
    //     email: 'juansanchez@gmail.com',
    //     photoUrl: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80',
    //     request: false,
    //     country: {
    //         alpha2Code:'',
    //         name: 'Peru',
    //         imgFlag: ''
    //     },
    //     password: ''
    // },
    // {
    //     id: 3,
    //     name: 'Pepe',
    //     userName: "@pepeMendoza",
    //     lastName: 'Mendoza',
    //     email: 'juansanchez@gmail.com',
    //     photoUrl: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80',
    //     request: true,
    //     country: {
    //         alpha2Code:'',
    //         name: 'Peru',
    //         imgFlag: ''
    //     },
    //     password: ''
    // },
    // {
    //     id: 4,
    //     name: 'Franchesco',
    //     userName: "@franchescoSantos",
    //     lastName: 'Santos',
    //     email: 'juansanchez@gmail.com',
    //     photoUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsIlzGp1laQheiAAjrbJJ3pasHLjMBnIUEZg&usqp=CAU',
    //     request: true,
    //     country: {
    //         alpha2Code:'',
    //         name: 'Peru',
    //         imgFlag: ''
    //     },
    //     password: ''
    // },
]