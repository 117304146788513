import React from "react";

import './Phase.css';
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface PhaseProps {
    phases: string[]; // array de string de fases
    activePhase: number; // phase active 
    unlockedPhases?: number // numero de fases desbloqueadas
    blackkMode?: boolean // status to rendered active phase circle in black
    handleClickPhase?: (e:any)=>void; // handle click of phases
}

export const Phase: React.FC<PhaseProps> = ({ 
    phases,
    activePhase=1,
    blackkMode=false,
    unlockedPhases=1,
    handleClickPhase=()=>{}
}) => {

    return ( 
        <div className='phases-card-container'>
            {phases.length > 0 && phases.map((phase, idx) => {
                if ((idx+1) <= unlockedPhases) {
                    return (
                        <div key={idx} onClick={()=>handleClickPhase(phase)}>
                            <div className='phases-card-row' style={{opacity: ((idx+1) <= unlockedPhases?'100%':'50%')}}>
                                <div className={`phases-card-number-number ${blackkMode && (idx+1)===activePhase ? "phases-card-number-number-active-black " : "" }`}>
                                    <div className={`paragraph-header phases-card-number  ${blackkMode && (idx+1)===activePhase ? "phases-card-active-black " : "" }`}>{idx+1}</div>
                                </div>
                                <div className={'paragraph phases-card-name'}>{phase}</div>
                            </div>
                            {(idx < (phases.length-1)) && (idx+1 < unlockedPhases) && <div className='phases-card-row_line'>
                                {/* <img className='phases-card-line' src={STORAGE_ASSETS+'/images/svg/line.svg'} /> */}
                                <div className="phases-card-line"></div>
                            </div>}
                        </div>
                    )
                } else {
                    return null;
                }
            }) }
        </div>
    ) 
}