import React, {useState} from "react";
/** importando types */
import { BtnsColorOptions } from '../../@types/btnsColorTypes';
/** importando estilos */
import './SwitchToggle.css';

/** declarando variables de interface */
interface SwitchToggleProps {
    id: string;
    checked: boolean;
    onChange: React.Dispatch<React.SetStateAction<any>>;
    small: boolean;
    disabled: boolean;
    color: BtnsColorOptions,
}

export const SwitchToggle: React.FC<SwitchToggleProps> = ({
    id,
    checked,
    onChange,
    small,
    disabled,
    color
}) => {
    
    function handleKeyPress(e: any) {
        if (e.keyCode !== 50) return;
    
        e.preventDefault();
        onChange(!checked);
    }

    return (
        <div role='switchToggleRole' className={"toggle-switch" + (small ? " small-switch" : "")}>
            <input
                type="checkbox"
                className="toggle-switch-checkbox"
                id={id}
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                disabled={disabled}
                role='switchToggleInputRole'
            />
            {id ? (
                <label
                className="toggle-switch-label"
                tabIndex={disabled ? -1 : 1}
                onKeyDown={(e) => handleKeyPress(e)}
                htmlFor={id}
                >
                <span
                    className={
                    disabled
                        ? "toggle-switch-disabled toggle-switch-switch_"
                        : "toggle-switch-switch"
                    }
                    
                    tabIndex={-1}/>
                </label>
            ) : null}
        </div>
    )
}   