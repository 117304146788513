import React from "react";
import "./FaqDisplay.css";
import { FaqOneQuestion } from "../FaqOneQuestion/FaqOneQuestion";
import { FAQInterface } from '../../interfaces/faq';

interface FaqDisplayProps {
  faqs: FAQInterface[];

}

export const FaqDisplay: React.FC<FaqDisplayProps> = ({
  faqs,

}) => {
  return (
    <section className="faq-display">
      <h1 className="small-header">Preguntas frecuentes</h1>
      {faqs.length>0 && faqs.map((faq, idx) => (
        <React.Fragment key={idx}>
          <FaqOneQuestion
            question={faq.question}
            questionText={faq.answer}
          />
        </React.Fragment>
      ))}
    </section>
  );
};
