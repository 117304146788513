import React, { useState, useEffect, useCallback }  from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import './ListChat.css';
import { SearchTextField } from "../SearchTextField/SearchTextField";
import { ItemPersonalChat } from "../ItemChat/ItemPersonalChat";
import { ItemGroupChat } from "../ItemChat/ItemGroupChat";

/**importando datos de prueba */
import { ItemListChat } from "./../../testdata/itemListChat";

// importando constantes
import { DETAIL, CHAT } from "./../../Consts/BaseUrl";

// Importando store
import type { AppDispatch } from "./../../Store/Store";
import { GetAllUser } from "../../Slices/User";
import { User } from "../../interfaces/User";
import debounce from "lodash.debounce";
import { GetAllCompanyActive } from "../../Services/Company.service";
import { Company } from "../../interfaces/Company";
import { GetUserById, GetUserList } from "../../Services/User.service";
import { ChatInterface, CreateChatInterface } from "../../interfaces/ChatInterface";
import { CheckChatByCompanyAndUser, CreateNewChat, GetChatsByUserId } from "../../Services/Chat.service";
import { toast } from "react-toastify";
import { Loader } from "../Loader/Loader";
import { Subheader } from "../Subheader/Subheader";
import { SearchBlack } from "../../icons/outline/SearchBlack";
import { BackArrow } from "../../icons/outline/BackArrow";
import { ButtonBadget } from "../ButtonBadget/ButtonBadget";

interface ListChatProps {
    itemChat: ChatInterface[]; // list of chats
    chatActive: string; // chat id active
    setActiveChat: React.Dispatch<React.SetStateAction<string>>; // setter to show
    onClickItem: (chatId: string) => void;
    getChatsByUserId: ()=> void;
    isProvider?: boolean // to know if user is provider
    companyProvider?: string; // company provider
}

export const ListChat: React.FC<ListChatProps> = ({
    itemChat,
    chatActive,
    setActiveChat,
    onClickItem,
    getChatsByUserId,
    isProvider=false,
    companyProvider=""
}) => {

    const dispatch = useDispatch<AppDispatch>();
    // navigation
    let navigation = useNavigate();
    // url location
    const location = useLocation();
    // url seach params
    const [searchParams] = useSearchParams();

    //get userDetail for redux
    const {userDetail} = useSelector((state: any) => state.user);

    const [activeSubMenu, setActiveSubmenu] = useState<"Chats" | "Operaciones">('Operaciones');

    const [clickSearch, setClickSearch] = useState<boolean>(false);

    // useState to loade
    const [loading, setLoading] = useState<boolean>(false);
    //useState if searching
    const [isSeaching, setIsSearching] = useState<boolean>(false);
    //useState user list
    const [userList, setUserList] = useState<User[]>([]);
    //useState company list
    const [companyList, setCompanyList] = useState<Company[]>([]);
    //useState result search User
    const [resultUser, setResultUser] = useState<User[]>([]);
    //useState result search company
    const [resultCompany, setResultCompany] = useState<Company[]>([]);

    const [resultOperationChat, setResultOperationChat] = useState<ChatInterface[]>([]);

    const [numMsgChat, setNumMsgChat] = useState<number>(0);
    const [numMsgOperation, setNumMsgOperation] = useState<number>(0);

    // handle click item
    const handleItemListClick = (chatId: string)=> {
        onClickItem(chatId);
    }

    /** Todas las compañias */
    const getCompany = async () => {
        if(!loading) {
            setLoading(true);
            try {
                const response: any = await GetAllCompanyActive();
                setCompanyList(response);
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: ListChat.tsx:64 ~ getCompany ~ error:", error)
                setLoading(false);
            }
        }
    }

    /** Todos los usuarios */
    const getUser = async () => {
        if(!loading) {
            setLoading(true);
            try {
                const response: any = await GetUserList();
                // console.log("🚀 ~ file: ListChat.tsx:97 ~ getUser ~ response:", response)
                setUserList(response);
                setLoading(false);
            } catch (error) {   
                console.log("🚀 ~ file: ListChat.tsx:81 ~ getUser ~ error:", error)
                setLoading(false);
            }
        }
    }

    //Buscar por usuarios
    const searchUser = (text: string) => {
        if(text.length>0 && userList.length>0) {
            setResultUser(
                userList.filter((u) => (u.name?.toUpperCase())?.includes(text.toUpperCase()))
            );
            } else {
            setResultUser([])
        }
    }

    //Buscar por company
    const searchCompany = (text: string) => {
        if(text.length>0) {
            setResultCompany(
                companyList.filter((c) => (c.name.toUpperCase()).includes(text.toUpperCase())))
            } else {
            setResultCompany([])
        }
    }

    // handle searchUser
    const handleSearchUser = useCallback( 
        debounce((value: string) => {
            setIsSearching(value.length>0);
            setLoading(true)
            isProvider ? 
                searchUser(value) 
                : 
                searchCompany(value)
            setLoading(false)
        }, 1000),[userList, companyList, isProvider]);
    
    // handle searchOperation in section operation
    const handleOperation = useCallback(
        debounce((value: string) => {
            // setIsSearching(value.length>0);
            if(value.length===0) {
                setResultOperationChat(itemChat.filter((chat) => (chat.typeChat==='group' && chat.lastMessage.length>0)));
                return;
            }

            let result = itemChat.filter((chat) => (chat.typeChat==='group' && chat.lastMessage.length>0 && chat.operationName?.toUpperCase().includes(value.toUpperCase())));
            console.log("🚀 ~ file: ListChat.tsx:152 ~ debounce ~ result:", result)
            setResultOperationChat(result);
           
        }, 500),[userList, companyList, isProvider]
    )

    // method to create a new chat with company
    const createChatwithCompany = (item: Company) => {
        setLoading(true);
        const chatData: CreateChatInterface = {
            generatedFrom: "messageCenter",
            typeChat: 'individual',
            userIdGenerated: userDetail.id,
            userTypeGenerated: userDetail.userType[0],
            recivedUserId: userDetail.id,
            receivedCompanyId: item.id?item.id:'',
          }
  
          CreateNewChat(chatData).then((response: any) => {
            // console.log("🚀 ~ file: ListChat.tsx:185 ~ CreateNewChat ~ response:", response)
            onClickItem(response)
            setLoading(false);
            
            getChatsByUserId();
          }).catch((error) => {
            setLoading(false);
            console.log("🚀 ~ file: ServiceDetailPage.tsx:340 ~ CreateNewChat ~ error:", error)
            toast.error("Hubo un error al creat Chat, por favor intentelo mas tarde");
          });
    }

    // method to create a new chat with user
    const createChatwithUser = (item: User) => {
        setLoading(true);
        const chatData: CreateChatInterface = {
            generatedFrom: "messageCenter",
            typeChat: 'individual',
            userIdGenerated: userDetail.id,
            userTypeGenerated: userDetail.userType[0],
            recivedUserId: item.id ? item.id : "",
            receivedCompanyId: companyProvider,
            }
    
        CreateNewChat(chatData).then((response: any) => {
            onClickItem(response);
            setLoading(false);
            
            getChatsByUserId();
        }).catch((error) => {
            setLoading(false);
            console.log("🚀 ~ file: ServiceDetailPage.tsx:340 ~ CreateNewChat ~ error:", error)
            toast.error("Hubo un error al creat Chat, por favor intentelo mas tarde");
        });
    }

    // handle item company clicked
    const handleItemCompanyClick = async (item: Company) => {
        try {
            if(!loading) {
                setLoading(true);
                setIsSearching(true);
                const response = await CheckChatByCompanyAndUser(item.id?item.id:'', userDetail.id);
                // console.log("🚀 ~ file: ListChat.tsx:228 ~ .then ~ responseCheck:", response)
                setIsSearching(false);
                setLoading(false);
                if(response) {
                    onClickItem(response.id);
                } else {
                    createChatwithCompany(item);
                }
              }
        } catch (error) {
            console.log("🚀 ~ file: ListChat.tsx:174 ~ handleItemCompanyClick ~ error:", error)
            setLoading(false);
        }
    }

    // handle item user clicked
    const handleItemUserClick = async (item: User) => {
        try {
            if(!loading) {
                setLoading(true);
                setIsSearching(false);
                CheckChatByCompanyAndUser(item.id?item.id:'', userDetail.id)
                .then((responseCheck) => {
                    setLoading(false);
                  
                    if(responseCheck) {
                        onClickItem(responseCheck.id)
                    } else {
                        createChatwithUser(item);
                    }
                setLoading(false);
                }).catch((err) => {
                    setLoading(false);
                    console.log("🚀 ~ file: ListChat.tsx:225 ~ .then ~ err:", err)
                });    
            }
        } catch (error) {
            console.log("🚀 ~ file: ListChat.tsx:174 ~ handleItemCompanyClick ~ error:", error)
            setLoading(false);
        }
    }

    // handle search
    const handleSearch = () => {
        setClickSearch(true);
    }

    //obtener servicios
    useEffect(() => {
        setLoading(true);
        (isProvider) ? getUser() : getCompany();
        setLoading(false);
    }, [isProvider]);


    useEffect(() => {
        setResultOperationChat(itemChat.filter((chat) => (chat.typeChat==='group' && chat.lastMessage.length>0)));
        setNumMsgChat(itemChat.reduce((prev, chat) => (prev + (chat.typeChat === "individual" && chat.members.find((m) => m.userId === userDetail.id && m.numMessagesUnread>0)?.numMessagesUnread || 0)), 0));
        setNumMsgOperation(itemChat.reduce((prev, chat) => (prev + (chat.typeChat === "group" && chat.members.find((m) => m.userId === userDetail.id && m.numMessagesUnread>0)?.numMessagesUnread || 0)), 0))
    },  [itemChat]);
    
    useEffect(() => {
        if(chatActive.length>0) {
            setActiveSubmenu(itemChat.find((chat) => chat.id === chatActive)?.typeChat==='group' ? 'Operaciones' : 'Chats');
        }
    }, [chatActive]);
  

    return (
    <>
        <div className="chat-left">
            <div className="chat-search">
                <div className={!clickSearch ? "chat-search-content" : "chat-show-search-content"}>
                    {!clickSearch ? 
                    <div className="chat-search-subheader">
                        <Subheader 
                            content={<div className="chat-numBadget">
                                Operaciones
                                {numMsgOperation>0 && <div className={
                                    numMsgOperation >= 100 ? 
                                        "tinytext chat-search-subheader-badget-100" : 
                                        "tinytext chat-search-subheader-badget"}>
                                            {numMsgOperation}
                                </div>}
                            </div>}
                            active={activeSubMenu === 'Operaciones'} 
                            handleClick={()=>{setActiveSubmenu('Operaciones')}} />
                        <Subheader 
                            content={<div className="chat-numBadget">
                                Chats
                                {numMsgChat>0 && <div className={numMsgChat >= 100 ?"tinytext chat-search-subheader-badget-100" : "tinytext chat-search-subheader-badget"}>
                                    {numMsgChat}
                                 </div>}
                            </div>}
                            active={activeSubMenu === 'Chats'} 
                            handleClick={()=>{setActiveSubmenu('Chats')}} />
                    </div> : 
                        <BackArrow 
                            className="chat-search-content-icon-back" 
                            onClick={()=>setClickSearch(false)}/>}
                    {!clickSearch ? 
                    <SearchBlack className="chat-search-icon" onClick={handleSearch}/> :
                    <SearchTextField 
                        setTextSearch={(e: any) => activeSubMenu === 'Chats' ? handleSearchUser(e) : handleOperation(e)}
                        title='Buscar'
                        darkIcon
                    />
                    }
                </div>
                {isSeaching && <div className="chat-content-search">
                    <div className="chat-content-list-search">
                        {   activeSubMenu === 'Chats' && 
                            <>
                                { isProvider ?  
                                    (!loading && resultUser && resultUser.length>0 &&
                                        resultUser?.map((u, idx)=> (
                                            <div key={idx} className="chat-content-search-item paragraph"
                                            onClick={()=>handleItemUserClick(u)}>
                                                {`${u.name} ${u.lastName} (${u.email})`} 
                                            </div>
                                        )) 
                                    // <div className="chat-content-search-item-nothing paragraph">no se encontró ningun usuario</div>
                                    ) 
                                : 
                                    ( resultCompany && resultCompany.length>0 &&
                                        resultCompany.map((c, idx)=> (
                                            <div key={idx} className="chat-content-search-item paragraph"
                                            onClick={()=>handleItemCompanyClick(c)}>
                                                {c.name}
                                            </div>
                                        ))
                                    // <div className="chat-content-search-item-nothing paragraph">no se encontró ninguna agencia</div> 
                                    )
                                }  
                            </>
                        

                            // <>
                            //     {
                            //     resultOperationChat && resultOperationChat.length>0 &&
                            //         resultOperationChat.map((c, idx)=> (
                            //             <div key={idx} className="chat-content-search-item paragraph"
                            //             onClick={()=>handleClickOperationSearch(c?.id || '')}>
                            //                 {`${c.operationName} - ${c.companyName}`} 
                            //             </div>
                            //         ))  
                            //     }
                            // </>
                        }
                    </div>
                </div>}
            </div>

            { !loading ?
            <div id="chat-page-list" className="chat-page-list box-shadow-card">
                
                { activeSubMenu === 'Chats' ? 
                    <>
                        { itemChat && itemChat.map((chat, idx) => (
                            chat.typeChat==='individual' && (chat.lastMessage.length >= 0 || chat.generatedFrom === "messageCenter") ?
                            <ItemPersonalChat
                                key={idx}
                                id={chat.id?chat.id:''}
                                lastMessage={chat.lastMessage}
                                onClick={handleItemListClick}

                                lastTime={chat.updatedAt.toDate()}
                                numNotificacion={chat.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread}
                                userActve={true}
                                
                                /** Datos del usuario */
                                userName={chat.members.find(m => m.userId !== userDetail.id)?.userName}
                                userLastName={chat.members.find(m => m.userId !== userDetail.id)?.userLastName}
                                profileUrl={chat.members.find(m => m.userId !== userDetail.id)?.userProfile}
                                

                                /** Datos de la compañia */
                                isCompany={chat.members.find(m => m.userId !== userDetail.id)?.isCompany}
                                companyImage={chat.members.find(m => m.userId !== userDetail.id)?.companyImage}
                                companyName={chat.members.find(m => m.userId !== userDetail.id)?.companyName}
                            
                                isActive={chat.id === chatActive}
                            /> : null
                        ))}
                    </>
                    :
                    <>
                        { resultOperationChat && resultOperationChat.map((chat, idy) => (
                            chat.typeChat==='group' && chat.lastMessage.length > 0 ?
                            <ItemGroupChat
                                key={idy}
                                id={chat.id?chat.id:''}
                                lastUser={chat.operationName?chat.operationName: ''}
                                title={chat.operationName?chat.operationName: ''}
                                business={chat.companyName?chat.companyName:''}
                                numChatNoti={chat.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread}
                                clock={chat.updatedAt.toDate()}
                                message={chat.lastMessage}
                                priority={'middle'}
                                onClick={handleItemListClick}

                                isActive={chat.id === chatActive}
                            /> : null
                        ))}
                    </>
                }

                {/* {itemChat && itemChat.map((chat, idx) => (
                    chat.typeChat==='individual' ? 
                    <ItemPersonalChat
                        key={idx}
                        id={chat.id?chat.id:''}
                        lastMessage={chat.lastMessage}
                        onClick={handleItemListClick}

                        lastTime={chat.updatedAt.toDate()}
                        numNotificacion={chat.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread}
                        userActve={true} */}
                        
                        {/** Datos del usuario */}
                        {/* userName={chat.members.find(m => m.userId !== userDetail.id)?.userName}
                        userLastName={chat.members.find(m => m.userId !== userDetail.id)?.userLastName}
                        profileUrl={chat.members.find(m => m.userId !== userDetail.id)?.userProfile}
                         */}

                        {/** Datos de la compañia */}
                        {/* isCompany={chat.members.find(m => m.userId !== userDetail.id)?.isCompany}
                        companyImage={chat.members.find(m => m.userId !== userDetail.id)?.companyImage}
                        companyName={chat.members.find(m => m.userId !== userDetail.id)?.companyName}
                    
                        isActive={chat.id === chatActive} */}
                    {/* /> : 
                    chat.lastMessage.length > 0 ? 
                    
                    <ItemGroupChat
                        key={idx}
                        id={chat.id?chat.id:''}
                        lastUser={chat.operationName?chat.operationName: ''}
                        title={chat.operationName?chat.operationName: ''}
                        business={chat.companyName?chat.companyName:''}
                        numChatNoti={chat.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread}
                        clock={chat.updatedAt.toDate()}
                        message={chat.lastMessage}
                        priority={'middle'}
                        onClick={handleItemListClick}

                        isActive={chat.id === chatActive}
                    /> : null 
                ))} */}
            </div>
            : <Loader /> }
        </div>
    </>
    )
}