import React, { useEffect, useState } from "react";
import { GetCityByCountry, GetCountryList } from "../../Services/Country";

import { Dropdown } from "../Dropdown/Dropdown";
import { TextField } from "../TextField/TextField";

import './TransactionInvoceTranslation.css';
import { CountryDropdown } from "../CountryDropdown/CountryDropdown";
import { CountryInterface } from "../../interfaces/Country";
import { COUNTRY_FLAG_BASE } from "../../Consts/BaseUrl";

interface TransactionInvoceTranslationProps {
    idx: number;
    incoterm: string;
    handleIncotermChange: (e: string, idx: number) => void;
    money: string;
    handleMoneychange: (e: string, idx: number) => void;
    country: CountryInterface;
    handleCountryChange: (e: CountryInterface, idx: number) => void;
    typePay: string;
    handleTypePayChange: (e: string, idx: number) => void;
    financialEntity: string;
    handleFinancialEntitychange: (e: string, idx: number) => void;
    delivery: string,
    handleDeliveryChange: (e: string, idx: number) => void;
    requiredAll?: boolean
    moneyList: string[];
    paymentList: string[];
    disabled: boolean; // permite activar y desactivar el texField, para poder editar
    countryList: {country: string; alpha2Code: string, phoneCode: string }[];
}   

export const TransactionInvoceTranslation: React.FC<TransactionInvoceTranslationProps> = ( {
    idx=-1,
    incoterm,
    money,
    country,
    typePay,
    financialEntity,
    delivery,
    requiredAll=false,
    moneyList,
    paymentList,
    handleIncotermChange,
    handleMoneychange,
    handleCountryChange,
    handleTypePayChange,
    handleFinancialEntitychange,
    handleDeliveryChange,
    disabled=false,
    countryList
}) => {

 
    const handleCountrySelect = (country: string, alpha2Code: string) => {
        handleCountryChange({
            name: country,
            imgFlag: COUNTRY_FLAG_BASE+alpha2Code.toLocaleLowerCase(),
            alpha2Code: alpha2Code,
        }, idx);
    }

    return (
        <div role='transactionInvoceTranslationRole' className='transactionInvoceTranslation-main'>
            <div className='transactionInvoceTranslation-text paragraph-header'>Transacción</div>
            <div className='transactionInvoceTranslation-row'>
                <div className='transactionInvoceTranslation-row-m'>
                    <Dropdown 
                        title='Incoterm'
                        elements={['EXW', 'FOB',"CFR", 'CIF']}
                        setValue={(e: any)=> e!== incoterm ? handleIncotermChange(e, idx) : {}}
                        value={incoterm}
                        required={requiredAll}
                        disabled={disabled}
                        roleClick='transactionInvoceTranslationRole-incoterm'
                    />
                    <Dropdown 
                        title='Moneda'
                        elements={moneyList}
                        setValue={(e: any)=> e!== money ? handleMoneychange(e, idx) : {}}
                        value={money}
                        required={requiredAll}
                        disabled={disabled}/>
                </div>
                <CountryDropdown 
                    title='País de adquisición'
                    country={country}
                    countryList={countryList}
                    handleCountryChange={handleCountrySelect}
                    disable={disabled}
                />
                {/* <Dropdown 
                    title='País de adquisición'
                    elements={}
                    setValue={(e: any)=> handleCountryChange(e, idx)}
                    value={country}
                    required={requiredAll}
                    disabled={disabled}/> */}
            </div>
            <div className='transactionInvoceTranslation-row'>
                <Dropdown 
                    title='Tipo de pago'
                    elements={paymentList}
                    setValue={(e: any)=> e!== typePay ? handleTypePayChange(e, idx) : {}}
                    value={typePay}
                    required={requiredAll}
                    disabled={disabled}/>
                <TextField 
                    title='Entidad financiera'
                    placeholder=' '
                    value={financialEntity}
                    onChange={(e: any) => handleFinancialEntitychange(e.target.value, idx)}
                    required={requiredAll}
                    disabled={disabled}
                    role='transactionInvoceTranslationRole-financialEntity'
                />
            </div>
            <TextField 
                    title='Lugar de entrega de la mercancía'
                    placeholder=''   
                    value={delivery}
                    onChange={(e: any) => handleDeliveryChange(e.target.value, idx)}
                    required={requiredAll}
                    disabled={disabled}/>
        </div> 
    )
}