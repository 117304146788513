import { DB, Functions } from "../firebaseConfig/FirebaseConfig";

import { collection, doc,  getDocs, getDoc, query, where, orderBy, } from "firebase/firestore"; 
import { httpsCallable } from "firebase/functions";
import { ServiceInterface } from "../interfaces/Service";

const JsonToService = (response: any): ServiceInterface => {
    let data = response.data();
    return {
        id: response.id, 
        active: data.active,
        basePrice: data.basePrice,
        city: data.city,
        companyId: data.companyId,
        companyName: data.companyName,
        companyLogo: data.companyLogo,
        companyWhatsApp: data.companyWhatsApp,
        keywords: data.keywords,
        reviewServiceId: data.reviewServiceId,
        country: data.country,
        createdAt: data.createdAt,
        description: data.description,
        faq: data.faq,
        images: data.images,
        numComments: data.numComments,
        rating: data.rating,
        serviceType: data.serviceType,
        serviceTypeLabel: data.serviceTypeLabel,
        status: data.status,
        title: data.title,
        updatedAt: data.updatedAt,
        userId: data.userId,
        video: data.video,
        servicePlanId: data.servicePlanId || '',
        verify: data.verify,
        numberVerify: data.numberVerify
    }
}

const GetAllServicesActive = async() => {
    const q = query(collection(DB, "service"), where("active", "==", true), orderBy("updatedAt", "desc"));
    const response = await getDocs(q);
    // const response = await getDocs(collection(DB, "service")); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

const GetServicesType = async(serviceType: string) => {
    const q = query(collection(DB, "service"), where("active", "==", true), where("serviceType", "==", serviceType), orderBy("updatedAt", "desc"));
    const response = await getDocs(q);
    // const response = await getDocs(collection(DB, "service")); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

// method to get service type by company id
const GetServiceByCompany = async(companyId: string) => {
    const u = query(collection(DB, "service"), where("companyId", "==", companyId), where("active", "==", true))
    const response = await getDocs(u);
    let data: ServiceInterface[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => (JsonToService(doc)));
    }
    return data;
}


const GetServiceDetail = async(id: string): Promise<ServiceInterface> => {
    const response = await getDoc(doc(DB, 'service', id)); // trae los documentos de la coleccion de firestore
    // console.log(response.data())
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve(JsonToService(response));
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    }); 
}

const FinishService = async (data: {serviceId: string, review: {rating: number, comment: string}, userId: string,
    billingReviewId: string, orderId: string, companyId: string}) =>{
    const endServiceUser = httpsCallable(Functions, 'endServiceUser');
    return endServiceUser(data)
    .then((response) => {
        console.log("🚀 ~ file: Service.service.ts ~ line 36 ~ .then ~ response", response)
        return response;
    })
    .catch((error) => {
        console.log(error);
        return error;
    });
}

const GetServiceByCompanyId =async (companyId: string) => {
    const q = query(collection(DB, "service"), where("active", "==", true), where("companyId", "==", companyId));
    const response = await getDocs(q);
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

export {
    GetAllServicesActive,
    GetServicesType,
    GetServiceDetail,
    FinishService,
    GetServiceByCompanyId,
    GetServiceByCompany
}