import React, { useRef, useState } from "react";

import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { Button } from "../Button/Button";

import { CircleCheckWhite } from "../../icons/outline/CircleCheckWhite";
import { Edit } from "../../icons/outline/Edit";

import './ButtonBadget.css';

interface ButtonBadgetProps {
    name: any;
    badget?: number;
    handleBadget: ()=>void;
}

export const ButtonBadget: React.FC<ButtonBadgetProps> = ( {
    name,
    badget=0,
    handleBadget
}) => {

    return (
        <div role='buttonBadgetRole' 
            className="paragraph-header buttonBadget"
            onClick={()=>handleBadget()}>
            <div>{name}</div>
            {badget>0 && 
            <div className="buttonBadget-badget">
                <div className="buttonBadget-badget-content">{badget}</div>
            </div>}
        </div>
    )
}      