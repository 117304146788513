import React, { useState } from "react";

/** Importando componentes */
import { ButtonIcon } from "../..";
import { Button } from "../..";

/** Importando iconos */
import { Week } from "../../../../src/icons/solid/Week";
import { Week_White } from "../../../../src/icons/solid/Week_White";
import { Today } from "../../../../src/icons/solid/Today";
import { Today_White } from "../../../../src/icons/solid/Today_White";
import { Month } from "../../../../src/icons/solid/Month";
import { Month_White } from "../../../../src/icons/solid/Month_White";
import { Left } from "../../../icons/solid/Left";
import { Right } from "../../../icons/solid/Right";

/** importando estilos */
import "./Option.css";
import { format } from "date-fns";
import { TranslateMonth } from "../../../utils/translateMonth";

/**declaracion de variables de interface */
interface OptionProps {
  active: string,
  prevWeek:()=>void,
  nextWeek:()=>void,
  prevMonth:()=>void,
  nextMonth:()=>void,
  rangeDate: Date[];
  setActive: React.Dispatch<React.SetStateAction<string>>;
  day?: Date;
  onClickModalEvent: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Option: React.FC<OptionProps> = ({ 
  active,
  prevWeek,
  nextWeek,
  prevMonth,
  nextMonth,
  rangeDate,
  setActive,
  day=new Date(),
  onClickModalEvent
}) => {

  return (
    <div className="option">
        <div className="option-content">
          <div className="option-tabs">
            <ButtonIcon onClick={()=>setActive('dia')} content="Dia" place="left" Icon={ active==='dia'? <Today_White/> : <Today/>} color={ active==='dia' ? 'black-75' : "white"} className={active==='dia' ? "option-tabs-active" : ""}/>
            <ButtonIcon onClick={()=>setActive('semana')} content="Semana" place="left" Icon={ active==='semana' ? <Week_White/> : <Week/>} color={ active==='semana' ? 'black-75' : "white"} className={active==='semana' ? "option-tabs-active" : ""}/>
            <ButtonIcon onClick={()=>setActive('mes')} content="Mes" place="left" Icon={ active==='mes' ? <Month_White/> : <Month/>} color={ active==='mes' ? 'black-75' : "white"} className={active==='mes' ? "option-tabs-active" : ""}/>
            <div className="option-range option-mobile">
              <Left onClick={()=>{active==='mes' ? prevMonth() :prevWeek()}} className="option-icon"/>
              { active ==='mes' ? 
              /** Formato para el mes */
                <div className="paragraph-header option-range-text">{(TranslateMonth(format(day, 'MMMM')))+ " " + format(day, 'yyyy')}</div> :
                /** Formato para el dia y semana */
                <div className="paragraph-header option-range-text"> 
                  {format(rangeDate[0], 'dd') +" "+(TranslateMonth(format(rangeDate[0], 'MMMM'))?.substring(0,3)) +" - " + 
                  format(rangeDate[rangeDate.length-1], 'dd') +" "+(TranslateMonth(format(rangeDate[rangeDate.length-1], 'MMMM'))?.substring(0,3)) +", "+format(rangeDate[0], 'yyyy')}
                </div>
              }
              <Right onClick={()=>{active==='mes' ? nextMonth() : nextWeek()}} className="option-icon"/>

            </div>
          </div>
          <div className="option-range option-desktop">

            <Left onClick={()=>{active==='mes' ? prevMonth() :prevWeek()}} className="option-icon"/>

            { active ==='mes' ? 
             /** Formato para el mes */
              <div className="paragraph-header option-range-text">{(TranslateMonth(format(day, 'MMMM')))+ " " + format(day, 'yyyy')}</div> :

              /** Formato para el dia y semana */
              <div className="paragraph-header option-range-text"> 
                {format(rangeDate[0], 'dd') +" "+(TranslateMonth(format(rangeDate[0], 'MMMM'))?.substring(0,3)) +" - " + 
                format(rangeDate[rangeDate.length-1], 'dd') +" "+(TranslateMonth(format(rangeDate[rangeDate.length-1], 'MMMM'))?.substring(0,3)) +", "+format(rangeDate[0], 'yyyy')}
              </div>
            }

            <Right onClick={()=>{active==='mes' ? nextMonth() : nextWeek()}} className="option-icon"/>

          </div>
        </div>
        { active==='semana' ? 
            <div className="option-range-week">
                <div className="option-range-week-content">
                  <Left onClick={()=>{prevWeek()}} className="option-icon"/>
                  <div className="paragraph-header option-range-text">
                    {format(rangeDate[0], 'dd') +" "+(TranslateMonth(format(rangeDate[0], 'MMMM'))?.substring(0,3)) +" - " + 
                    format(rangeDate[rangeDate.length-1], 'dd') +" "+(TranslateMonth(format(rangeDate[rangeDate.length-1], 'MMMM'))?.substring(0,3)) +", "+format(rangeDate[0], 'yyyy')} 
                  </div>
                  <Right onClick={()=>{nextWeek()}} className="option-icon"/>
              </div> 
            </div>: null
          }
        <div className="option-new-event">
          <Button content="+ Agregar evento" onClick={()=>onClickModalEvent(true)}/>
        </div>
    </div>
  );
};
