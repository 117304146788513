import React, { useState } from 'react';

import './ThridSeccion.css';
import { CardHoverExpand } from '../../CardHoverExpand/CardHoverExpand';
import { CardHoverExpandConst } from '../../../Consts/CardHoverExpandConst';
import { Helmet } from 'react-helmet-async';

interface ThridSeccionProps {
}

export const ThridSeccion: React.FC<ThridSeccionProps> = ({

}) => {

  return (
    <div className="third-section">
        <Helmet>
            <title>Arkabia | Agencia de Aduana</title>
            <meta name="description" 
            content="Crece tu negocio sin importar la industria. 
            Arkabia te conecta con las agencias de aduana más 
            confiables de Perú, ofreciendo herramientas clave para 
            optimizar tus importaciones y escalar con facilidad." />
            <meta name="keywords" content="agencia de aduana, 
            importaciones para empresas, servicios aduaneros Perú, 
            agencia de aduanas, optimización de importaciones, 
            crecer negocio importaciones, Arkabia, servicios de aduanas, 
            industria, grupo aduan" />
        </Helmet>

        <div className="third-section-header">
            <div className='third-section-text'>
                No importa cuál sea tu industria, 
            </div>
            <div className='third-section-text'>
                puedes crecer tu negocio con 
                <b className='third-section-text-grandient'> Arkabia</b>
            </div>
        </div>

        <div className="third-section-header-mob">
            <div className='third-section-text'>
                No importa cuál sea tu  
            </div>
            <div className='third-section-text'>
                industria, puedes crecer tu
            </div>
            <div className='third-section-text'>
                negocio con 
                <b className='third-section-text-grandient'> Arkabia</b>
            </div>
        </div>

        <div className='third-section-animation'>
            {CardHoverExpandConst.map((item, index) => (
                <CardHoverExpand
                    key={index}
                    itemsHover={item}
                />
            ))}
        </div>
    </div>
  );
};