import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { CloudUp } from "../../icons/outline/CloudUp";
import { FileValidate } from "../../utils/FileValidate";
import "./PhotoUnit.css";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type PhotoUnitProps = React.HTMLAttributes<HTMLDivElement> & {
  idx: number;
  type?: PhotoUnitTypes;
  picture?: string;
  imageSrc: string | ArrayBuffer | null
  setImageData: (idx: number, imgData: string | ArrayBuffer | null, name: string, fileSize: number) => void;
  disabled?: boolean;
  downloadable?: boolean //
  role?: string,
};

type PhotoUnitTypes = "cloudup" | "picture" | "none" | "more";

export const PhotoUnit: React.FC<PhotoUnitProps> = ({
  idx,
  type = "cloudup",
  picture,
  setImageData,
  imageSrc='',
  disabled= false,
  downloadable = false,
  role,
  ...props
}) => {
  const [myType, setMyType] = useState(type);
  // const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const anchRef = useRef<HTMLAnchorElement>(null);

  const handleUploadClick = () => {
    fileRef.current?.click();
  };

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files&& e.target.files.length > 0) {
      if(FileValidate(e.target.files[0], 'image/*', 20)) {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (readerEvent) => {
            setMyType("picture");
            // setImageSrc(readerEvent.target!.result);
            const fileValue = e.target.files ? e.target.files[0] : null;
            if(fileValue) {
              setImageData(idx, readerEvent.target!.result, fileValue.name, fileValue.size);
            }
            // setImageData(idx, readerEvent.target!.result, e.target.files && e.target.files[0] && e.target.files[0].name ? e.target.files[0].name : 'desconocido',  e.target.files[0].size && e.target.files[0].size);
          };
        } else {
          toast.info("Por favor seleccione una imagen de tamano menor a 20MB");
        }
      } 
  };

  const handleDownload = () => {
    anchRef.current?.click();
    //TODO https://www.py4u.net/discuss/281420
  };

  const handleRemoveImage = () => {
    setMyType("cloudup");
    // setImageSrc(null);
    setImageData(idx, null, '', 0);
  };

  useEffect(() => {
    if(imageSrc) {
      setMyType("picture");
      // console.info("PhotoUnit", imageSrc)
    }
  }, [imageSrc])

  return (
    <React.Fragment>
      {myType === "cloudup" ? (
        <div role={role}
          className="photo-unit photo-unit-cloud box-shadow-card"
          onClick={()=> !disabled ? handleUploadClick(): ()=>{}}
          {...props}
        >
          <CloudUp />
          <input type="file" ref={fileRef} onChange={handleAddImage} hidden accept="image/*"   />
        </div>
      ) : myType === "picture" ? (
        <div className="photo-unit photo-unit-picture" {...props} role={role}>
          <img
            className="photo-unit-picture-img"
            src={imageSrc as any}
            alt="unit-picture"
          />
          <div className="photo-unit-picture-hover-bg">
          {!disabled &&  <img
              className="photo-unit-picture-close"
              src={STORAGE_ASSETS+"/images/svg/close-big-icon.svg"}
              alt="close"
              onClick={() => !disabled ? handleRemoveImage() : ()=>{}}
            />}
            <img
              onClick={() => !disabled || downloadable ? handleDownload() : ()=>{}}
              src={STORAGE_ASSETS+"/images/svg/cloud_down_black.svg"}
              alt="cloud_down"
            />
            <a ref={anchRef} target='_blank' href={imageSrc as any} download hidden></a>
          </div>
        </div>
      ) : myType === "none" ? null : (
        <div className="photo-unit photo-unit-more" {...props}>
          <span className="tinytext-header">más</span>
        </div>
      )}
    </React.Fragment>
  );
};
