import React, { useCallback, useEffect, useState } from "react";


import './SupplierSearchRequirements.css';
import { CardContent } from "../CardContent/CardContent";
import { TextField } from "../TextField/TextField";
import { RequirementsDataError, RequirementsInterface } from "../../interfaces/Requirements";
import { ValidateField } from "../../utils/ValidateField";
import { Textarea } from "../Textarea/Textarea";
import { useSelector } from "react-redux";
import StoreService from "../../Services/Store.service";
import { toast } from "react-toastify";
import { CreateRequeriments, GetRequirementsById, UpdateRequeriments } from "../../Services/Requirements.service";
import { UploadFileArray } from "../UploadFile/UploadFileArray/UploadFileArray";
import { CompressFile, DocsFileValidation, ImageFileValidation, PDFFileValidation, SpreadSheetFileValidation } from "../../Consts/FilesValidation";
import { Button } from "../Button/Button";
import { UpdateOrder } from "../../Services/Order.service";
import { OrderInterface } from "../../interfaces/OrderInterface";
import { Loader } from "../Loader/Loader";
import { ModalConfirm } from "../Modal/ModalConfirm/ModalConfirm";

interface SupplierSearchRequirementsProps {
    requirementsId: string;
    orderId: string
    setOrderDetail: React.Dispatch<React.SetStateAction<OrderInterface>>;
    companyName?: string;
}

export const SupplierSearchRequirements: React.FC<SupplierSearchRequirementsProps> = ( {
    requirementsId,
    orderId,
    setOrderDetail,
    companyName = '',
}) => {

    const { userDetail } = useSelector((state: any) => state.user);

    const [isProvider, setIsProvider] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(false);

    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
 
    const [countWord, setCoundWord] = useState<number>(0);

    const [requirements, setRequirements] = useState<RequirementsInterface>({
        orderId: '',
        detailRequiments: '',
        userId: '',
        attachment: [],
        createdAt: new Date(),
        updatedAt: new Date(),
    
    });

    const [requirementsError, setRequirementsError] = useState<RequirementsDataError>({
        detailRequiments: {
            message: '',
            status: false,
        },
        attachment: {
            message: '',
            status: false,
        }
    });

    const handleModalConfirm = () => {
        const error = validateFields();
        if(!error) {
            setShowModalConfirm(true);

        }
    }

    const handleRequirementsLength = (e:  any) => {
        let value = e.target.innerText;
        if(value && value.length<=1500) {
            setCoundWord(value.length);
        }
    }

    const handleRequirementsChange = (e: any) => {
        let value = e.target.innerText;
        if(value && value.length<=1500) {
            setRequirements((prev) => ({
                ...prev,
                detailRequiments: value,
            }))
        }
    
    }

    const handleRequirementsAttachment = async (e: any) => {
        if(e.target.files[0]) { 
            try {
                setLoading(true); 
                const responseFile: any = await StoreService.UploadFile(
                    e.target.files[0], 
                    `order/`+orderId+`/requeriments/`+e.target.files[0].name,
                    //Añadiendo userId para que se guarde en la carpeta del usuario, si no lo puedes eliminar
                    userDetail.id
                );
                if(responseFile) {
                    setRequirements((prev) =>({
                      ...prev,
                      attachment:[
                        ...prev.attachment,
                        {
                            name: e.target.files[0].name,
                            url: responseFile,
                            size: e.target.files[0].size/1024
                        }
                      ] 
                    }))
                }
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: SupplierSearchRequirements.tsx:102 ~ handleRequirementsAttachment ~ error:", error)
                setLoading(false)
            }
              
        } else {
            toast.info("Por favor suba un archivo");
        }
    }

    const handleEditRequeriments = () => {
        setDisabled(false);
    }

    const handlreDeleteRequirements = async (i: number) => {
        setLoading(true);
        setRequirements((prev) => ({
            ...prev,
            attachment: prev.attachment.filter((item, idx) => idx !== i)
        }))
        await StoreService.DeleteFile(requirements.attachment[i].url, requirements.attachment[i].size, userDetail.id);
        toast.success("¡Se elimino con éxito el archivo!");
        setLoading(false);
    }

    const getRequerimentsById = async (requirementsId: string) => {
        if(!loading) {
            try {
                setLoading(true);
                const response: RequirementsInterface = await GetRequirementsById(requirementsId);
                setRequirements(response);
                setIsNew(response.attachment.length==0 && response.detailRequiments.length==0)
                setCoundWord(response.detailRequiments.length)
                setDisabled(response.detailRequiments.length>0);
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: SupplierSearchRequirements.tsx:92 ~ getRequerimentsById ~ error:", error)
                setLoading(false);
                toast.error("¡Hubo un error al cargar los datos, intentelo en unos minutos! (:");
            }
        }
    }

    // Create requeriments
    const createRequirements = async () => {
        if(!loading && orderId.length > 0) {
            try {
                setLoading(true);
                
                let tmpRequeriments: RequirementsInterface = {
                    orderId: orderId,
                    detailRequiments: '',
                    userId: userDetail.id,
                    attachment: [],
                    createdAt: new Date(),
                    updatedAt: new Date(),
                }

                const response = await CreateRequeriments(tmpRequeriments);

                
                if(response) {
                    tmpRequeriments.id = response.id;
                    setRequirements(tmpRequeriments);

                    await UpdateOrder({id: orderId, requirementsId: response.id, updatedAt: new Date()});

                    setOrderDetail((prev) => ({
                        ...prev,
                        requirementsId: response.id,
                    }));
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: OrderDelivery.tsx:116 ~ createDeliveryOrder ~ error:", error)
                toast.error("¡Hubo un error al crear los datos, intentelo en unos minutos!");        
            }
        }
    }

    // Update requeriments
    const handleSendRequeriments = async () => {

        const error = validateFields();
        if(!loading && requirements.id && !error) {
            try {
                setLoading(true);
                setTimeout(async () => {
                    await UpdateRequeriments(requirementsId , requirements);
                }, 500);
                setDisabled(true);
                setLoading(false);
                toast.success("Los datos se guardaron con éxito");
                setShowModalConfirm(false);
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: OrderDelivery.tsx:116 ~ createDeliveryOrder ~ error:", error)
                toast.error("Algo salio mal, por favor intentato en unos minutos");     
            }
        }
    }

    // Function to validate all fields
    const validateFields = (): boolean =>{
        let error = false;

        error = ValidateField(requirements.detailRequiments, "required") ||
        ValidateField(requirements.attachment, "array");

        setRequirementsError((prev) => ({
            ...prev,
            detailRequiments: {
                message: error ? "Por favor ingrese una descripción de su producto" : "",
                status: error,
            },
            attachment: {
                message: error ? "Por favor ingrese un archivo" : "",
                status: error,
            }
        }));
        return error;
    }

    useEffect(() => {
        if(requirementsId.length > 0) {
            getRequerimentsById(requirementsId)
        } 
        else {
            createRequirements();
        }
    }, [])

    useEffect(() => {
        setIsProvider(userDetail. userType.find((item: any) => item === 'provider') === 'provider');
    }, [userDetail]);

    return (
        <>
            {!loading ? <div className="supplierSearchRequirements-provider">
                {!isProvider && !isNew  &&
                    <div className="supplierSearchRequirements-provider-button">
                        {disabled && <Button 
                            content="Editar" 
                            onClick={handleEditRequeriments}
                        />}
                    </div> }
                <CardContent className="supplierSearchRequirements">
                    <div className="paragraph">¿Qué estamos buscando?</div>
                    <div className="answer-detail paragraph">Describe el producto que buscas a detalle.</div>
                    <div className="paragraph">Incluye:</div>
                        <ul className="answer-detail paragraph">
                            <li>Nombre comercial</li>
                            <li>Características técnicas (color, medidas, peso, voltaje, etc)</li>
                            <li>Imágenes de referencia</li>
                            <li>Cantidad deseada</li>
                            <li>Precio objetivo</li>
                            <li>Link de Alibaba</li>
                        </ul>
                    <div className="supplierSearchRequirements-detail">
                        <Textarea 
                            title='' 
                            onBlur={(e:any)=>handleRequirementsChange(e)}
                            onKeyUp={(e:any)=>handleRequirementsLength(e)} 
                            value={requirements?.detailRequiments} 
                            placeholder='Ej: Carros de juguete para navidad'
                            disable= {disabled} />
                        {requirementsError.detailRequiments && <div className='tinytext text-error'>{requirementsError.detailRequiments.message}</div>}
                        <div className="smalltext supplierSearchRequirements--number"> {countWord} / 1500 caracteres</div>
                    </div> 
                </CardContent> 

                <UploadFileArray
                    title='Archivos adjuntos'
                    subtitle='Sube aquí los documentos e imágenes que describan mejor tu producto.'
                    buttonContent="Subir archivo"
                    file={requirements?.attachment || []}
                    handleFileValue={handleRequirementsAttachment}
                    acceptType={`${CompressFile}, ${DocsFileValidation}, ${SpreadSheetFileValidation}, ${PDFFileValidation}, ${ImageFileValidation}`}
                    handleDelete={handlreDeleteRequirements}
          
                    isDelete={userDetail.userType.includes("user") && !disabled}
                />
                {requirementsError.attachment && <div className='tinytext text-error supplierSearchRequirements-error'>{requirementsError.attachment.message}</div>}

                {!disabled && !isProvider && <div className="supplierSearchRequirements-provider-button">
                    <Button 
                        content="Cancelar" 
                        size="large"
                        color="black-25"
                        onClick={()=>setDisabled(true)}
                    />
                    <Button 
                        content="Enviar requerimientos" 
                        size="large"
                        onClick={handleModalConfirm}
                    />
                </div>}
            </div> : 
            <Loader/>}

            <ModalConfirm
                title="Enviar requerimientos"
                subTitle={`La descripción de tu producto será enviada ${companyName} ¿Deseas continuar?`}
                show={showModalConfirm}
                setShow={setShowModalConfirm}
                handleYes={handleSendRequeriments}
                handleNo={()=>setShowModalConfirm(false)}
            />
        </>
    )
}