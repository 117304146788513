import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type LawProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Law: React.FC<LawProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/law-icon.svg'} alt='' />
        </div>
    )
}