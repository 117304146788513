import React, { Fragment, useEffect, useState } from "react";

import "./TableComparePlanMin.css";

// Import components
import { CardContent } from "../CardContent/CardContent";
import { Button } from "../Button/Button";
import { TagButton } from "../Button/Tag/TagButton";

// Import services
import { ServicePlanInterface } from "../../interfaces/ServicesPlanInterface";

// Import icons
import { Close_red } from "../../icons/solid/Close_red";
import { Check_green } from "../../icons/solid/Check_green";

interface TableComparePlanMinProps {
    handleModalPlan: (servicePlanId: string, idx: number) => void;
    servicePlan: ServicePlanInterface | undefined;
    callToActionText?: string;
    planIndex?: number;
};


export const TableComparePlanMin: React.FC<TableComparePlanMinProps> = ({
    handleModalPlan,
    servicePlan,
    callToActionText = 'Comprar',
    planIndex = 1
}) => {

    // const seen = new Set();

    // const [loading, setLoading] = useState<boolean>(false);

    // useState 
    const [idxButtonSelected, setIdxButtonSelected] = useState<number>(-1);

    const handleSelectPlan = (servicePlanId: string, idx: number) => {
        setIdxButtonSelected(idx);
        handleModalPlan(servicePlanId, idx);
    }

    useEffect(() => {
        handleSelectPlan(servicePlan?.id || "", planIndex);
    }, [])


    return (
        <CardContent className="tableComparePlanMin_contain">
            <div className="tableComparePlanMin__content">

                {/** Cabecera */}
                <div className="tableComparePlanMin__content_header">

                    <div className="tableComparePlan___row">
                        <div className="tableComparePlan___row_min tableComparePlan" style={{fontFamily: 'Faktum-Medium'}}>{servicePlan?.plans.title}</div>

                        <div className="tableComparePlan___row__right">
                            {servicePlan?.plans.values.map((item, idx) => (
                                <div className={`tableComparePlan__index ${idx === idxButtonSelected ? 'tableComparePlan-important' : 'tableComparePlan-paragraph '}`} key={idx}>{item.value}</div>
                            ))}
                        </div>
                    </div>
                </div>

                {/** Precio */}
                <div className="tableComparePlan___row">
                    <div className="tableComparePlan___row_min tableComparePlan" style={{fontFamily: 'Faktum-Medium'}}>{servicePlan?.price.title}</div>
                    
                    <div className="tableComparePlan___row__right">
                        {servicePlan?.price.values.map((item, idy) => (
                            <div className={`tableComparePlan__index ${idy === idxButtonSelected ? 'tableComparePlan-important' : 'tableComparePlan-small'}`} key={idy}>{item.value+" "+item.currency}</div>
                        ))}
                    </div>
                    
                </div>

                {/** Detail */}
                <div className="tableComparePlan__column">
                    <div className="tableComparePlan___row tableComparePlan" style={{fontFamily: 'Faktum-Medium'}}>Qué incluye</div>
                    <div className="tableComparePlan__column__min">
                        {servicePlan?.detail.map((row, idz) => (
                            <div className="tableComparePlan___row tableComparePlan__bottom" key={idz}>
                                <div className="tableComparePlan___row_min paragraph" style={{color: "#333"}}>{row.title}</div>
                                    <div className="tableComparePlan___row__right">
                                        { row.values.map((item, idy) => (
                                            <div key={idy} className="tableComparePlan__index paragraph">
                                                {item.type === "check" ?  (item.value === true ? <Check_green/> : <Close_red/>  ) : (item.type === 'string' ? item.value : (item.type === 'price' ? item.currencySymbol+' '+item.value : '')) }
                                            </div>
                                        ))}
                                    </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/** Time delivery */}
                <div className="tableComparePlan___row">
                    <div className="tableComparePlan___row_min tableComparePlan" style={{fontFamily: 'Faktum-Medium'}}>{servicePlan?.deliveryTime.title}</div>
                    
                    <div className="tableComparePlan___row__right">
                        {servicePlan?.deliveryTime.values.map((item, idd) => (
                            <div className={`tableComparePlan__index ${idd === idxButtonSelected ? 'tableComparePlan-important-paragraph' : 'tableComparePlan-paragraph' }`} key={idd}>{item.value + " días"}</div>
                        ))}
                    </div>
                </div>

                {/** Revision */}
                <div className="tableComparePlan___row">
                    <div className="tableComparePlan___row_min tableComparePlan" style={{fontFamily: 'Faktum-Medium'}}>{servicePlan?.numRevision.title}</div>
                    
                    <div className="tableComparePlan___row__right">
                        {servicePlan?.numRevision.values.map((item, idr) => (
                            <div className={`tableComparePlan__index ${idr === idxButtonSelected? 'tableComparePlan-important-paragraph' : 'tableComparePlan-paragraph' }`} key={idr}>{item.value}</div>
                        ))}
                    </div>
                </div>

                {/** Button */}
                <div className="tableComparePlan___row">
                    <div className="tableComparePlan___row_min"></div>
                    <div className="tableComparePlan___row__right">
                        {servicePlan?.price.values.map((item, idr) => (  
                            <div className="tableComparePlan__index">
                                <Button 
                                    key={idr}
                                    color={idxButtonSelected === idr ? "yellow" : "black-75"}
                                    onClick={()=>handleSelectPlan(servicePlan?.id || "", idr)}
                                    content={callToActionText}/>   
                            </div>                      
                        ))}
                    </div>
                </div>

            </div>
        </CardContent>
    );
};