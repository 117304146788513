import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    path: 'LOL'
};

export const HistoryLocationSlice = createSlice({
    name: 'historyLocation',
    initialState,
    reducers: {
        setHistoryLocation: (state, action) => {
            state.path = action.payload;
        }
    }
});

const {reducer, actions} = HistoryLocationSlice;
export const {setHistoryLocation} = actions;
export default reducer;