import React, { useState } from "react";

import "./RecoverAccount.css";
import { TextField } from "../TextField/TextField";
import { Loader } from "../Loader/Loader";
import { ValidateField } from "../../utils/ValidateField";
import { ErrorInterface } from "../../interfaces/ErrorInterface";
import { toast } from "react-toastify";
import authService from "../../Services/Auth.service";
import { AuthSteps_item } from "../../Consts/AuthSteps";

interface RecoverAccountprops {
    email: string;
    setAuthStep: React.Dispatch<React.SetStateAction<AuthSteps_item>>;
}

export const RecoverAccount: React.FC<RecoverAccountprops> = ({
    email,
    setAuthStep
}) => {

    // loading state
    const [loading, setLoading] = useState<boolean>(false);
    // email state
    const [recoverEmail, setRecoverEmail] = useState<string>(email);
    // state to manage error
    const [recoverError, setRecoverError] = useState<ErrorInterface>({
        status: false,
        message: ""
    });

    // handle email change
    const handleRecoverEmailChange = (value: string) => {
        setRecoverEmail(value);
    }

    // method to recover account
    const recoverAccountClick = async () => {
        if(!loading) {
            if(!ValidateField(recoverEmail, "email")) {
                setLoading(true);
                try {
                    const response = await authService.PasswordResetByEmail(email);
                    if(response) {
                        toast.success('Se ha enviado un email de recuperación de contraseña, así mismo revise en spam o correos no deseados');
                    } else {
                        toast.error('Error al enviar email de recuperación de cuenta');
                    }
                    setLoading(false);
                    setAuthStep("MAIN_LOGIN_STEP");
                } catch (error) {
                    setLoading(false)
                    console.log("🚀 ~ file: RecoverAccount.tsx:39 ~ recoverAccountClick ~ error:", error);
                    toast.error("Hubo un error al recuperar la cuenta, intente mas tarde")
                }
            } else {
                setRecoverError({
                    status: ValidateField(recoverEmail, "email"),
                    message: "Ingrese un correo electrónico válido"
                })
            }
        }
    }

    return (
        <div className="recoverAccount-container">
            {!loading ? <>
                <TextField 
                    title="Email" 
                    placeholder=" " 
                    value={recoverEmail}
                    onChange={(e)=>handleRecoverEmailChange(e.target.value)} 
                    error={recoverError}
                />
                <div className='recoverAccount-confirm paragraph-header' onClick={()=>recoverAccountClick()} >Reestablecer la contraseña</div>   
            </> : <Loader />}
        </div>
    )
}