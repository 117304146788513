import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, getDoc, doc, addDoc, updateDoc } from "firebase/firestore"; 

import { DocumentationInterface } from "../interfaces/Documentation";

/** Funcion para crear */
export const CreateDocumentation = async (data:DocumentationInterface) => {
    try {
        const response: any = await addDoc(collection(DB, "documentation"), data);
        // console.log("🚀 ~ file: Documentation.service.ts ~ line 11 ~ CreateDocumentation ~ response", response)
        return response;
    } catch (error) {   
        console.log(error);
        return error  
    }
}

/** Funcion para llamar los documentos */
export const getDocumentationById = async (documentationId: string) => {
    const response = await getDoc(doc(DB, 'documentation', documentationId));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()})
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

/** Funcion para actualizar los documentos */
export const UpdateDocumentation = async(documentationId: any) => {
    // console.log("🚀 ~ file: RequestQuote.service.ts ~ line 70 ~ UpdateRequestQuote ~ requestQuote", requestQuote)
    try {
        if(documentationId.id) {
            const requestRef = doc(DB, 'documentation', documentationId.id);
            const response =  await updateDoc(requestRef, {...documentationId});
            //  console.log("UpdateDocumentation", response)
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}