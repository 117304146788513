import React, { useEffect, useState } from "react";
import { User } from "../../interfaces/User";
import { Button } from "../Button/Button";

import { SearchUserItem } from "../SearchUserItem/SearchUserItem";

import './UserSearchList.css';


interface UserSearchListProps {
    userList: User[];
    onClick: (user: User | null) => void;
}

export const UserSearchList: React.FC<UserSearchListProps> = ({
    userList=[],
    onClick,
}) => {

    const [itemSelected, setItemSelected] = useState<number>(-1);

    const handleClickItem = (idx: number, user: User) => {
        setItemSelected(itemSelected === idx ? -1 : idx);
        onClick(itemSelected === idx ? null : user);
    }

    return (
        <div className="userSearchList">
            <div className="userSearchList-container">
                {
                    userList.map((u, idx) => (
                        <SearchUserItem 
                            key={idx}
                            name={u.name}
                            lastName={u.lastName}
                            email={u.email}
                            imgProfile={u.photoUrl ? u.photoUrl : 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fuser.png?alt=media&token=dd5bd9cb-ab0f-4799-8b73-31fb2831df7b'}
                            companyName={u.businessName ? u.businessName : ''}
                            onClick={()=>handleClickItem(idx, u)}
                            selected={idx === itemSelected}
                        />
                    ))
                }
            </div>
            
        </div>
    )
}