import React from "react";
import { DropdownItem } from "./DropdownItem";

import { ServiceOptios } from '../../@types/serviceTypes';
import { selectService } from "../../utils/selectService";

interface DropdownListProps {
  elements: string[];
  listRef: React.RefObject<HTMLDivElement>;
  refHr: React.RefObject<HTMLDivElement>;
  icon: any;
  setItem: (item: string) => void;
  setIdx?: (idx: number) => void;
  setIcon: (item: any) => void;
  className: string;
}

export const DropdownList: React.FC<DropdownListProps> = ({
  elements,
  listRef,
  refHr,
  icon=false,
  className='',
  setItem,
  setIcon,
  setIdx=()=>{}
}) => {

  const getIcon = (typeService: ServiceOptios) => {
    // console.log(typeService);
    const Icon = selectService(typeService)[0];
    return <Icon />;
  }

  const getTitle = (typeService: ServiceOptios) => {
    const title = selectService(typeService)[1] 
    return title+"";
  }

  return (
    <>
      <div className="dropdown-list" ref={listRef}>
        {!icon ?elements.map((item, idx) => (
          <DropdownItem 
            key={idx} 
            id={idx}
            item={item} 
            setItem={setItem}
            className={className}
            setIdx={setIdx}
            />
        )): elements.map((i:any, idx) => (
          <DropdownItem 
            key={idx} 
            id={idx}
            item={getTitle(i)} 
            setItem={setItem}
            icon={getIcon(i)}
            setIcon={setIcon}
            setIdx={setIdx}
            />
        ))}
      </div>
      {/* <div className="dropdown-hr" ref={refHr}></div> */}
    </>
  );
};
