import React from "react";
import { Dots } from "../../icons/outline/Dots";
import { Close_red } from "../../icons/solid/Close_red";

import './SearchUserItem.css';

interface SearchUserItemProps {
    name?: string;
    lastName?: string;
    imgProfile?: string;
    companyName: string;
    email: string;
    onClick: () => void;
    selected: boolean;
    isDelete?: boolean;
    onClickDelete?: ()=>void;
}

export const SearchUserItem: React.FC<SearchUserItemProps> = ({
    name,
    lastName,
    imgProfile,
    companyName,
    email,
    onClick,
    selected,
    isDelete=false,
    onClickDelete=()=>{}
}) => {
    return (
        <div className={`searchUserItem-container ${selected? 'searchUserItem-selected' : ''}`} onClick={onClick} >
            {/* {imgProfile  &&
                <img src={imgProfile} alt="Arkabia usuario" className="searchUserItem-img" />
            } */}
            <div className="searchUserItem-content">
                {name && <div className="paragraph-header searchUserItem-textcolor">{name}</div>}
                {companyName && <div className="paragraph searchUserItem-textcolor">{companyName}</div>}
                <div className="paragraph searchUserItem-textcolor">{email}</div>
            </div>
            {isDelete && <div onClick={()=>onClickDelete()}>
                <Close_red />
            </div>}
        </div>
    )
}

