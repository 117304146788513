import { FREQUENTLY_QUESTIONS, PLAN, TOOLS } from "./BaseUrl";

export const SubMenuHome = [
    {
        name: "Herramientas",
        event: "/"+TOOLS,
    },
    // {
    //     name: "Academy",
    //     event: "/"+TOOLS,
    // },
    {
        name: "Precio",
        event: "/"+PLAN,
    },
    {
        name: "Preguntas frecuentes",
        event: "/"+FREQUENTLY_QUESTIONS,
    }
];