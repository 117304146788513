import { title } from "process";
import React, { useState } from "react";

/** importando componentes */
import { Modal } from "../../Modal/Modal/Modal";
import { CardToken } from "../CardToken/CardToken";
import { Table } from "../../Table/Table";
import { PaymentCD } from "../../CheckboxDropdown/Payment/PaymentCD";
import { PaySafe } from "../../PaySafe/PaySafe";

/** Importando utilidades */
import { GetTax } from "../../../utils/GetTax";


/** importando estilos */
import './ModalToken.css';

interface ModalTokenProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handleClickModalThanks: ()=>void;
}

export const ModalToken: React.FC<ModalTokenProps> = ({
    show,
    setShow,
    handleClickModalThanks
}) => {

    const [activeCard, setActiveCard] = useState<number>(0);
    const [showDetailToken, setShowDetailToken] = useState(false);
    const [charge, setCharge] = useState<{subTotal:number, tax:number, total: number}>();

    const handleClick = (id: number) => {
        setActiveCard(id)
        setShowDetailToken(true)
        switch (id) {
            case 1:
                setCharge({
                    subTotal: 5-Number(GetTax(5,18)),
                    tax: Number(GetTax(5, 18)),
                    total: 5.00
                });
                break;
            case 2:  
                setCharge({
                    subTotal: 10-Number(GetTax(10,18)),
                    tax: Number(GetTax(10, 18)),
                    total: 10.00
                });
                break;

            case 3:  
                setCharge({
                    subTotal: 15-Number(GetTax(15,18)),
                    tax: Number(GetTax(15, 18)),
                    total: 15.00
                });
                break;
            default:
                
        }
    }

    return (
        <div role='modalTokenRole-container' className="modalToken-main">
            <Modal title="¿Más Tokens?" show={show} setShow={setShow} >
                <div className="paragraph modalToken-subtitle">Ahora Arkabia te ofrece <b>Tokens</b> para que puedas realizar más 
                     cotizaciones y no tengas que esperar hasta que se renueven 
                     la próxima semana. </div>
                <div className="modalToken-column">
                    <div className="smalltext-header">PASO 1 DE 2</div>
                    <div className="paragraph">Selecciona el paquete deseas</div>
                </div>
                <div className="modalToken-content-card">
                    <CardToken title="Paquete Nro "
                               price="5.00"
                               subTitle="5 Token" 
                               date="23/12/2022"
                               sale=""
                               active={activeCard===1}
                               handleClick={()=>handleClick(1)}/>

                    <CardToken title="Paquete Nro 2"
                               price="10.00"
                               subTitle="10 Token + 3 gratis" 
                               date="23/12/2022"
                               sale="3.00"
                               offer
                               active={activeCard===2}
                               handleClick={()=>handleClick(2)}/>

                    <CardToken title="Paquete Nro 3"
                               price="15.00"
                               subTitle="15 Token + 9 gratis" 
                               date="23/12/2022"
                               sale="9.00"
                               offer
                               active={activeCard===3}
                               handleClick={()=>handleClick(3)}/>
                </div>

                {showDetailToken &&
                (<div className="modalToken-payment">
                    <div>
                        <div className="smalltext-header">PASO 2 DE 2</div>
                        <div className="paragraph">Selecciona el medio de pago</div>
                    </div>

                    <div className="modalToken-payment-detail">
                        <Table  header="Concepto"
                                body={[{
                                    name: ['Paquete Nro 1'],
                                    value: ["$"+" "+charge?.subTotal]
                                },{
                                    name: ['IGV (18%)'],
                                    value: ["$"+" "+charge?.tax]
                                }]}
                                footer={{title: 'Total' , values: ["USD $ "+ charge?.total]}} />

                        {/* <PaymentCD 
                          amount={0}
                          curency=""
                        /> */}
                        <PaySafe />
                    </div>
                </div>)}
            </Modal>
        </div>
    )
}
