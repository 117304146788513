import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";
import { GetPlanById } from "../Services/PlanArkabia.service";

export const GetPlanId = createAsyncThunk(
    'plan/getPlanById',
    async (planId: string, thunkAPI: any) => {
        try {
            const response:any = await GetPlanById(planId);
            // console.log("🚀 ~ file: getPlanById.ts ~ line 12 ~ response", response)
        
            thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
            return response;
       
        } catch (error:any) {
            console.log('slice/plan/getPlanById => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
)

const initialState = {
    plan: {},
    plans: [],
}

const planSlice = createSlice ({
    name: "plan",
    initialState,
    reducers: {},
    extraReducers: {
        [GetPlanId.fulfilled.toString()]: (state, action) => {
            state.plan = action.payload
        },
        [GetPlanId.rejected.toString()]: (state, action) => {
            state.plan = {}
        }
    }
})

const {reducer} = planSlice;
export default reducer;