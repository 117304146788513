import React from "react";

/** importando css */
import './Tutorial.css';
import { TutorialInterface } from "../../interfaces/TutorialInterface";

/** declarando interface */
interface TutorialProps {
    tutorial: TutorialInterface;
    handleItemClick: ()=>void;
}

export const Tutorial: React.FC<TutorialProps> = ({
    tutorial,
    handleItemClick
}) => {

    return (
        <div className="tutorial" onClick={handleItemClick} >
            <div className="tutorial-left">
                <div className="tutorial-left-header">
                    <img src={tutorial.icon} />
                    <div className="paragraph-header tutorial-left-title">{tutorial.title}</div>
                </div>
                <div className="paragraph tutorial-left-description">{tutorial.description}</div>
                <div className="paragraph tutorial-left-duration">{tutorial.duration}</div>
            </div>
            <div className="tutorial-right">
                <img src={tutorial.preview}/>
            </div>
        </div>
    )
}
