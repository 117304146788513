import React from "react";

import './ModalProcessPay.css';
import { Modal } from "../Modal/Modal";
import { LinearLoader } from "../../LinearLoader/LinearLoader";
import { ArkabiaOp } from "../../../icons/outline/ArkabiaOp";

interface ModalProcessPayProps {
    show: boolean;
    setShow: (show: boolean) => void;
    loading: boolean;
}

export const ModalProcessPay: React.FC<ModalProcessPayProps> = ({
    show,
    setShow,
    loading
}) => {


    return (
        <>
           {show && <Modal title="Procesando pago" show={show} setShow={!loading ? setShow: ()=>{}} icon={<ArkabiaOp />}>
                <div className="modalProcessPay">
                    <div className="paragraph modalProcessPay-colorText">Por favor, no cierres ni actualices la página mientras completamos tu transacción. Este proceso puede tardar unos minutos.</div>
                    {/* <div className="paragraph modalProcessPay-colorText">Te notificaremos cuando el pago haya sido exitoso.</div>
                    <div className="paragraph modalProcessPay-colorText">¡Gracias por tu paciencia!</div> */}
                    <LinearLoader />
                </div>
            </Modal>}
        </>
    )
};