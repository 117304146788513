import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type ShieldProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Shield: React.FC<ShieldProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/svg/shield.svg"} alt="" />
    </div>
  );
};