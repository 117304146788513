import { DB } from "../firebaseConfig/FirebaseConfig";
import { collection, query, getDocs, where, addDoc } from "firebase/firestore";
import { reviewUserInterface } from "../interfaces/reviewUser";

const GetAllReviewUserId = async (id: string) => {
    const ru = query(collection(DB, 'reviewUser'), where("userId", "==", id));
    const response = await getDocs(ru);
    // console.log("🚀 ~ file: Review.service.ts ~ line 7 ~ GetAllReviewByServiceId ~ response", response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

const CreateReviewUser = async (reviewUser: reviewUserInterface) => {
    try {
        const response = await addDoc(collection(DB, "reviewUser"), reviewUser);  
        console.log("🚀 ~ file: ReviewUser.service.ts ~ line 19 ~ CreateReviewUser ~ response", response)
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
        return error;
    }
}

const GetAllReviewOrderId = async (orderId: string) => {
    const ru = query(collection(DB, 'reviewUser'), where("orderId", "==", orderId));
    const response = await getDocs(ru);
    // console.log("🚀 ~ file: Review.service.ts ~ line 7 ~ GetAllReviewByServiceId ~ response", response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}


export {
    GetAllReviewUserId,
    CreateReviewUser,
    GetAllReviewOrderId
}