import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type Month_WhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Month_White: React.FC<Month_WhiteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/Month_White.svg"} alt="" />
    </div>
  );
};