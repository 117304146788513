import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type NotificationProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Notification: React.FC<NotificationProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/icono_notification.svg"} style={{height: "21px"}} alt="" />
    </div>
  );
};
