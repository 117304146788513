import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";
import { PlanInterface } from "../interfaces/PlansInterface";
import { SecondaryPlanInterface } from "../interfaces/SecondaryPlanInterface";

// export const GetPlansArkabia = async(): Promise<PlanInterface[]> => {
//     const q = query(collection(DB, "plan"), orderBy("priceUsd", "asc"));
//     const response = await getDocs(q);
//     let data:PlanInterface[] = [];
//     if(!response.empty) {
//         data = response.docs.map(doc => ({id: doc.id, ...doc.data()} as PlanInterface))
//     }
//     return data;
// }

// method to get the secondary plan by id
export const GetSecondaryPlanById = async(id:string): Promise<SecondaryPlanInterface> => {
    const response = await getDoc(doc(DB, 'secondaryPlan', id));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            const data: SecondaryPlanInterface = response.data() as SecondaryPlanInterface;
            resolve(data);
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}