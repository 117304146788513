import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";
import { EventArkabiaInterface } from "../interfaces/EventArkabiaInterface";

// method to get User plan resources from firestore
export const GetEventsArkabia = async (): Promise<EventArkabiaInterface[]> => {
    try {
        const q = query(collection(DB, "eventArkabia"), where("status", "==", true), orderBy("startDate", "asc"));
        const response = await getDocs(q);
        return new Promise((resolve) => {
            let data: EventArkabiaInterface[] = [];
            if (!response.empty) {
                data = response.docs.map(doc => ({ id: doc.id, ...doc.data() as EventArkabiaInterface}));
            }
            resolve(data);
        });
    } catch (error) {
        console.log("🚀 ~ getUserPlanResources ~ error:", error);
        throw error;
    }
};