import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type FrontArrowProps = React.HTMLAttributes<HTMLDivElement> & {};

export const FrontArrow: React.FC<FrontArrowProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/front_arrow.svg"} alt="" />
    </div>
  );
};
