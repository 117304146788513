import React from "react";
import { Button } from "../../Button/Button";
import { Modal } from "../Modal/Modal";

import './ModalThanks.css';
import { STORAGE_ASSETS } from "../../../Consts/BaseUrl";

interface ModalThanksProps {
    show: boolean;
    setShow: (e: boolean) => void;
    handleBack: ()=> void;
}

export const ModalThanks: React.FC<ModalThanksProps> = ({
    show,
    setShow,
    handleBack
}) => {
    return (
       <Modal title="Regístrate cómo influencer Arkabia" show={show} setShow={setShow} role='modal-thanks-main'>
            <div className="modalThanks-container">
                <img className="modalThanks-icon " src={STORAGE_ASSETS+"/images/png/check_yellow.png"}/>
                <div className="paragraph modalThanks-colorText ">
                    ¡Muchas gracias! A la brevedad posible un ejecutivo de Arkabia se contactará contigo.
                </div>
            </div>
            <div className="modalThanks-button">
                <Button 
                    content='Regresar'
                    size="normal"
                    color='black-75'
                    onClick={handleBack}
                    role='btn-thanks'/>
            </div>
       </Modal>
    )
}