import React, { useEffect, useState } from "react";

// Importando componentes
import { CargoStatus } from "../CargoStatus/CargoStatus";
import { Row } from "..";


// Importando imagenes
import { Trash } from "../../icons/outline/Trash";
import { ArkabiaOp } from "../../icons/outline/ArkabiaOp";
import { ArrowUp } from "../../icons/outline/ArrowUp";
import { ArrowDown } from "../../icons/solid/ArrowDown";

// Importando estilos
import './SaveDraft.css';

interface SaveDraftProps {
    importation: string; 
    category?: string;
    companyName?: string;
    open?: boolean; // contrala si esta abierto el collapse
    date: string;
    handleClickDraft: () => void;
    handleClickTrash: () => void;
}

export const SaveDraft: React.FC<SaveDraftProps> = ({
    importation,
    open=false,
    category,
    companyName,
    date,
    handleClickDraft,
    handleClickTrash
}) => {
 
    const [expanded, setExpanded] = useState<boolean>(false);
    
    useEffect(() => {
        setExpanded(open);
    }, [open]);

    // console.log("🚀 ~ file: SaveDraft.tsx ~ line 38 ~ expanded", expanded)
    
    return (
        <div className="saveDraft-container box-shadow-card">
            <div onClick={()=> setExpanded(!expanded)} className={`saveDraft-header ${!expanded && "saveDraft-header__border"}`} >
                <div className="saveDraft-text">
                    <div className="saveDraft-text-content">
                        <ArkabiaOp />
                        <div className="saveDraft-title">
                            <div className="smalltext-header saveDraft__title">{importation} - Borrador</div>
                            {/* <div className="smalltext saveDraft__detail">{category}</div> */}
                        </div>
                    </div>
                    <div className="saveDraft-date-icon-mobile">{expanded?<ArrowUp /> :<ArrowDown />}</div>
                </div>
                <div>
                    <div className="saveDraft-date">
                        <div className="tinytext-header saveDraft-date-text">{date}</div>
                        <div className="saveDraft-date-icon">{expanded?<ArrowUp /> :<ArrowDown />}</div>
                    </div>
                </div>
            </div>

            {/** Listado de cotizaciones */}
            {expanded && 
                <div className="smalltext saveDraft-body"  role='btn-dashboardElements'>
                    <div className="" style={{cursor: 'pointer'}} onClick={()=>handleClickDraft()}>{"Solicitud de cotización " + companyName + " - Ver borrador"}</div>
                    <Trash style={{cursor: 'pointer'}} onClick={()=>handleClickTrash()}/>
                </div>
            }
        </div>    
    )
}