import ms from "date-fns/esm/locale/ms/index.js";
import React, { useState, useEffect } from "react";

import { Button } from "../..";


import { MessageInterface } from "../../../interfaces/MessageInterface";
import { AttachChat } from "../../../interfaces/MessageInterface";

import './MessageChat.css';
import { format } from "date-fns";
import { Time } from "../Time/Time";
import { FormatDate } from "../../../utils/formatDate";
import { DoneAll } from "../../../icons/solid/DoneAll";

interface MessageItemProps {
    msg: MessageInterface;
    userId: string;
}

export const MessageItem: React.FC<MessageItemProps> = ({
    msg,
    userId
}) => {

    //const document
    const [isImage , setIsImage] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getDocumentByType = () => {
        setLoading(true)
        if(msg.attach) {
            let nameSplit = msg.attach.fileName.split('.'); 
            setIsImage( (nameSplit[nameSplit.length-1].toLocaleLowerCase()==='jpg' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='jpeg' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='png' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='gif' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='ttf' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='bmp' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='tiff' ));
        }
        setLoading(false)
    }

    useEffect(()=>{
        getDocumentByType();
    }, [msg]);

    return (
        <>
            {
                !loading && <div className={`messageChat-box 
                ${msg.userId === userId? 'messageChat-box__rigth': 'messageChat-box__left'}
                ${isImage && msg.typeMessage === "attach" ? 'messageChatboxImage' : 'messageChatboxDoc'}`}
            >
                {msg.content && <div className={`paragraph 
                    ${msg.userId === userId ? 'messageChat-colorWhite' : 'messageChat-colorBlack'}
                    ${isImage && msg.typeMessage === "attach" ? 'messageChatboxContentImage' : 'messageChatboxContentDoc'}`}
                >
                    {msg.content}
                </div>}
                {msg.typeMessage === 'attach' && <a href={msg.attach?.fileUrl} target="_blank" style={{textDecoration: 'none', overflow: "hidden"}}>
                    <div className={`paragraph 
                        ${msg.userId === userId ? "messageChat-colorWhite messageChat-attachment": "messageChat-colorBlack messageChat-attachment-black"} 
                        ${isImage && msg.typeMessage === "attach" ? 'messageChatImage' : 'messageChatDoc'}`}
                    >
                        <img 
                            src={msg.attach?.fileIcon} 
                            alt="Arkabia, Agencia de aduana" 
                            className="messageChat-attachment--icon" 
                        />
    
                        {!isImage &&
                            <div 
                                className={`${msg.userId === userId ? 
                                "messageChat-name-documentWhite" : "messageChat-name-document"}`}
                            >
                            {msg.attach?.fileName}
                        </div>}
                    </div>
                </a>}
                {/* {msg.typeMessage === "attach" && 
                    <div className="paragraph messageChat-button">
                        <Button color='yellow' content={msg.attach?.fileName+""} /> 
                    </div>
                } */}
                <div className={`tinytext-header 
                    ${msg.userId === userId ? "messageChat-box__timeWhite" : "messageChat-box__timeBlack"}
                    ${isImage && msg.typeMessage === "attach" ? 'messageChatboxTimeImage' : 'messageChatboxTimeDoc'}`}>
                    {format(new Date(msg.created), 'HH:mm')}
                    {/* {msg.userId !== userId ? <DoneAll/> : null} */}
                </div>
            </div>
            }
        </>
    )
}