import { useEffect, useState } from "react";
import "./ModalFilter.css";
import { Button } from "../../Button/Button";
import { FilterService } from "../../FilterService/FilterService";
import { ServiceInterface } from "../../../interfaces/Service";
import { STORAGE_ASSETS } from "../../../Consts/BaseUrl";


interface ModalFilterProps {
    show: boolean; // variable to show modal
    setShow: React.Dispatch<React.SetStateAction<boolean>>; // setter to show modal
    typeService: string; // type of service
    handleFilterRelevance: (item: string) => void; // function to handle filter relevance
    FilterByRelevance: string[]; // array of relevance filters.
    FilterCategory: string[]; // array of category filters.
    handleFilter?: (item: string) => void; // function to handle filter by 
} 

export const ModalFilter: React.FC<ModalFilterProps> = ({
    show,
    setShow,
    typeService,
    handleFilterRelevance,
    FilterByRelevance,
    FilterCategory,
    handleFilter = () => {}
}) => {

    const [filterTitle, setFilterTitle] = useState<string>("Todos");
    const [filterCategory, setFilterCategory] = useState<string>("Todos");
    const [resultServiceList, setResulServiceList] = useState<
        ServiceInterface[]
    >([]);

    const handleMakeFilter = () => {
        setShow(!show);
        handleFilterRelevance(filterTitle);
        handleFilter(filterCategory);
    }

    const handleSearchChange = (filter: string) => {
        setFilterTitle(filter);
    }

    const handleFilterBy = (filter: string) => {
        setFilterCategory(filter);
    
    }

    useEffect(() => {
        setFilterTitle("Todos");
        setFilterCategory("Todos");
    }, [typeService])

    useEffect(() => {
        show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    }, [show]);

    return (
        <>
        { show && 
        <div className="modalFilter">
            <div className="modalFilter-container">
                <div className="modalFilter-header">
                    <button role='goback-button' className="go-back-button" onClick={()=>setShow(!true)}>
                        <img src={STORAGE_ASSETS+"/images/svg/left-arrow-icon.svg"} alt="" />
                        <span className="paragraph-header go-back-button-text">Filtros</span>
                    </button>
                    <div className="modalFilter-button">
                        <div className="paragraph-header modalFilter-delete">
                            Borrar filtros
                        </div>
                        <Button 
                            content="Aplicar filtros"
                            color="blue-2" size="normal" 
                            onClick={()=>handleMakeFilter()} />
                    </div>
                </div>
                <div className="modalFilter-body">
                    <div className="modalFilter-body-text">
                        <div className="paragraph-header modalFilter-body-text-filter">
                            Ordenar por
                        </div>
                        {typeService==="courier" ? 
                        <div className="paragraph-header modalFilter-body-text-filter">
                            Compra de
                        </div> : null}
                    </div>
                    <div className="modalFilter-body-filter">
                        <FilterService
                            title=""
                            titleDropdown={filterTitle}
                            filterItems= {FilterByRelevance}
                            handleItemSelected={handleSearchChange}
                        />
                        {typeService && typeService !== "busqueda-de-proveedor" && <FilterService
                            title=""
                            titleDropdown={filterCategory}
                            filterItems= {typeService === "agenciamiento-de-aduana" ? FilterCategory : ["China", "EE.UU", "Todos"]}
                            handleItemSelected={handleFilterBy}
                        />}
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}