import React, { useRef, useState } from "react";

import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { Button } from "../Button/Button";

import { BellBlack } from "../../icons/solid/BellBlack";

import './IconMessageBadget.css';
import { MessageBlack } from "../../icons/outline/MessageBlack";
import { MessageContentBlack } from "../../icons/solid/MessageContentBlack";
import { Mail } from "../../icons/outline/Mail";
import { MailActive } from "../../icons/outline/MailActive";

interface IconMessageBadgetProps {
    isActive: boolean;
    badget?: number;
    handleMessageClick?: ()=>void;
}

export const IconMessageBadget: React.FC<IconMessageBadgetProps> = ( {
    isActive,
    badget=0,
    handleMessageClick=()=>{}
}) => {

    return (
        <div role='iconMessageBadgetRole' 
            className="paragraph-header iconMessagBadget"
            onClick={()=>handleMessageClick()}>
                {badget>0?<MessageContentBlack/>: (isActive ? <MailActive/> : <Mail/>)}
                {badget>0 && 
                    <div className={
                            badget >= 100 ? 
                                "tinytext iconMessagBadget-badget-100" : 
                                "tinytext iconMessagBadget-badget"}>
                                    {badget}
                    </div>
                }
        </div>
    )
}      