import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type InfoCircleGrayProps = React.HTMLAttributes<HTMLDivElement> & {};

export const InfoCircleGray: React.FC<InfoCircleGrayProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/info-circle-outline-gray.svg'} alt='' />
        </div>
    )
} 