import React, {useEffect, useState} from "react";

/** importando componentes */
import { Button } from "../../Button/Button";
import { ButtonIcon } from "../../ButtonIcon/ButtonIcon";
import { ButtonEditChange } from "../../ButtonEditChange/ButtonEditChange";
import { SupplierInvoceTranslation } from "../../Supplier/SupplierInvoceTranslation";
import { TransactionInvoceTranslation } from "../../Transaction/TransactionInvoceTranslation";
import { ProductInvoceTranslation } from "../../Product/ProductInvoceTranslation";

/** importando interface */
import { Supplier as Supplierinterface } from "../../../interfaces/Supplier";
import { Trasaction as TransactionInterface } from "../../../interfaces/Transaction";
import { ProductDeclareInterface } from "../../../interfaces/ProductDeclare";

/** importando iconos */
import { FolderPlusGray } from "../../../icons/outline/FolderPlusGray";

import './InvoceTranslationSupplier.css';
import { InvoiceTranslation, Provider } from "../../../interfaces/InvoiceTranslation";
import { Subheader } from "../../Subheader/Subheader";
import { TabsProduct } from "../../Product/TabsProduct/TabsProduct";
import { ModalStatement } from "../../Modal/ModalStatement/ModalStatement";
import { GetCountryList } from "../../../Services/Country";
import { CountryInterface } from "../../../interfaces/Country";
import { Trash } from "../../../icons/outline/Trash";


interface InvoceTranslationSupplierProps {
    isNew?: boolean; // es nueva o ya existe en la absbasee de datos
    supplier: Provider[],
    setSupplier: React.Dispatch<React.SetStateAction<InvoiceTranslation>>;
    handleConfirmClick: () => void;
    supplier_ ?: boolean; //se muestra siempre y cuando se encuentre en el dashboard del proveedor,
                         //caso contrario muestra el boton confirmar si es true
    HandleClickSave: ()=>void; //boton para guardar los campos que se editan
    handleEdit: ()=>void; //boton para Editar los campos
    handleClickCancel: ()=>void; //boton para Cancelar los campos
    disabled: boolean; // permite activar y desactivar el texField, para poder editar
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    handleStatement: (providerIdx: number, q1: boolean, q2: boolean, q3: boolean, q4: boolean, q5: boolean, q6: boolean, q7: boolean, q8: boolean)=>void
    handleSave: ()=>void;
    handleRefresh: () => void;

}

export const InvoceTranslationSupplier: React.FC<InvoceTranslationSupplierProps> = ( {
    isNew=false,
    supplier,
    setSupplier,
    handleConfirmClick,
    supplier_ = false,
    HandleClickSave,
    handleEdit,
    handleClickCancel,
    disabled = false,
    setDisabled,
    handleStatement,
    handleSave,
    handleRefresh
 }) => {
    const [ showEdit, setShowEdit ] = useState(disabled);

    const [providerActive, setProviderActive] = useState<number>(0);

    // Modal de las declaraciones por proveedor
    const [showModalStatement, setShowModalStatement] = useState(false);

    const [countryList, setCountryList] = useState<{country: string; alpha2Code: string, phoneCode: string }[]>([]);

    // metodos para cambiar datos del proveedor
    // medoto para cambiar el nombre del proveedor
    const handleNameSupplierChange = (value: string, i: number) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, name: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo para cambiar el numero de la factura
    const handleNumFactSupplierChange = (value: string, i: number) => {
        // console.log("🚀 ~ file: InvoceTranslationSupplier.tsx:78 ~ handleNumFactSupplierChange ~ value:", value)
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, invoiceNumber: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo para cambiar pais
    const handleCountrySupplierChange = (value: CountryInterface, i: number) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, country: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo pra cambiar ciudad
    const handleCitySupplierChange = (value: string, i: number) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, city: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo para cambiar el tipo de proveedor
    const handletypeSupplierChange = (value: string, i: number) => {
            setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, providerType: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo para cambiar direccion del proveedor
    const handleDirectionSupplierChange = (value: string, i: number) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, providerAddress: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo para cambiar numero de telefono del proveedor
    const handlePhoneSupplierChange = (value: string, i: number) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, phone: value} : s)
        }));
        // handleAutoSave();
    }

    // handle trasaccion
    // metodo para cambia el incoterm de la trasaccion
    const handleIncotermTransactionChange = (value: string, i: number) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, incoterm: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo para cambiar la moneda de la transaccion
    const handleMoneyTransactionChange = (value: string, i: number) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, currency: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo para cambiar el pais de adquisicion de la transaccion
    const handleCountryTransactionChange = (value: CountryInterface, i: number ) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, countryAcquisition: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo para cambiar el tipo de pago de la transaccion
    const handleTypepayTransactionChange = (value: string, i: number ) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, paymentType: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo para cambiar la entidad financiera de la transaccion
    const handleFinancialEntityTransactionChange = (value: string, i: number ) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, financialEntity: value} : s)
        }));
        // handleAutoSave();
    }
    // metodo para cambiar direccion de entrega de la mencarncia
    const handleDeliveryTransactionChange = (value: string, i: number ) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((s, idx) => idx === i ? {...s, placeDelivery: value} : s)
        }));
        // handleAutoSave();
    }

    const handleAddProvider = () => {
        setSupplier(((prev:any)=> ({
            ...prev,
            provider: [
                ...prev.provider,
                {
                    name: '',
                    invoiceNumber: 0,
                    country: {
                        alpha2Code: '',
                        name: '',
                        imgFlag: '',
                    },
                    city: '',
                    providerType: '',
                    providerAddress: '',
                    phone: '',
                    incoterm: '',
                    currency: '',
                    countryAcquisition: {
                        alpha2Code: '',
                        name: '',
                        imgFlag: '',
                    },
                    paymentType: '', 
                    financialEntity: '',
                    placeDelivery: '',
                    product: [{
                        name: '',
                        brand:'',
                        model:'',
                        status: '',
                        descriptionMin: '',
                        principalFunction: '',
                        amount: 0,
                        typeAmount: '',
                        countryAcquisition: {
                            alpha2Code: '',
                            name: '',
                            imgFlag: '',
                        },
                        countryOrigin: {
                            alpha2Code: '',
                            name: '',
                            imgFlag: '',
                        },
                        photos: [{name: "", url: ""}, {name: "", url: ""}, {name: "", url: ""}, {name: "", url: ""}, {name: "", url: ""}]
                    }],
                }
            ]
        })));
        // handleAutoSave();
    }

    const handleDeleteProvider = (idx: number) => {
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.filter((p, idy) => idy !== idx)
        }));
        // handleAutoSave();
    }

    const handleMoveProduct = (productIdx:number, provider: string) => {
        let tmpProduct = supplier[0].product[productIdx];
        // console.log("🚀 ~ file: InvoceTranslationSupplier.tsx:206 ~ handleMoveProduct ~ tmpProduct", tmpProduct)
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((p, i) => i===0 ? {...p, product: p.product.filter((pr, j) => j !== productIdx)} : p )
        }));
        let label = provider.split(' ');
        // console.log("🚀 ~ file: InvoceTranslationSupplier.tsx:211 ~ handleMoveProduct ~ label", label[1])
        
        setSupplier((prev) => ({
            ...prev,
            provider: prev.provider.map((p, i) => i === (Number(label[1])-1) ? {...p, product: [tmpProduct, ...p.product]} : p )
        }));

        setProviderActive(Number(label[1])-1);
        // handleAutoSave();
    }

    const getCountries = async () => {
        try {
            const response: any = await GetCountryList();
            if(response.length>0) {
            setCountryList(response);
            }
        } catch (error) {
            console.log("🚀 ~ file: TransactionInvoceTranslation.tsx:64 ~ getCountries ~ error:", error)
        }
    }

    const handleSaveClick = () => {
        handleSave();
        setShowEdit(!showEdit);
    }

   const handleCancelClick = () => {
        handleRefresh()
        setShowEdit(true);
    }

useEffect(() => {
    getCountries();
    if(supplier.length>0 ) {
       setShowEdit(true);
    }
}, []);


    return (
        <div role='invoceTranslationSupplierRole' className='invoceTranslationSupplier-main'>
            <div className='invoceTranslationSupplier-container box-shadow-card'>
                <ButtonEditChange 
                    onClickEdit={()=>setShowEdit(!showEdit)}
                    edit={!showEdit}
                    setEdit={setShowEdit}
                    add={!showEdit}
                    onClickCancel={()=>handleCancelClick()}
                    onClickComplete={()=>handleSaveClick()}
                    onClickSave={()=>handleSaveClick()}
                />
                <div className={supplier.length>=5 ?"invoceTranslationSupplier-product-tabs-scroll" : "invoceTranslationSupplier-product-tabs"}>
                   {
                    supplier.length>0 && supplier.map((_, idx) => (
                        <Subheader key={idx} content={"Proveedor "+ (idx+1)} active={idx===providerActive} handleClick={()=>setProviderActive(idx)} />
                    ))
                   }
                </div>
                {supplier.length>0 && supplier.map((s, idx)=>(
                    providerActive === idx && <React.Fragment key={idx}>
                    <div className="invoceTranslationSupplier-content-supplier-transaction">
                        <SupplierInvoceTranslation
                            idx={idx}
                            name={s.name}
                            handleNameChange={handleNameSupplierChange}
                            numFac={s.invoiceNumber}
                            handleNumFacChange={handleNumFactSupplierChange}
                            country={s.country}
                            handleCountryChange={handleCountrySupplierChange}
                            city={s.city}
                            handleCityChange={handleCitySupplierChange}
                            type={s.providerType}
                            handleTypeChange={handletypeSupplierChange}
                            direction={s.providerAddress}
                            handleDirecionChange={handleDirectionSupplierChange}
                            phone={s.phone}
                            handlePhoneChange={handlePhoneSupplierChange}
                            typeSupplierList={['Fabricante', 'Mayorista', 'Minorista']}
                            requiredAll
                            disabled={showEdit}
                            handleDeleteProvider={handleDeleteProvider}
                            countryList={countryList}
                        />
                        <TransactionInvoceTranslation
                            idx={idx}
                            incoterm={s.incoterm}
                            handleIncotermChange={handleIncotermTransactionChange}
                            money={s.currency}
                            handleMoneychange={handleMoneyTransactionChange}
                            country={s.countryAcquisition}
                            handleCountryChange={handleCountryTransactionChange}
                            typePay={s.paymentType}
                            handleTypePayChange={handleTypepayTransactionChange}
                            financialEntity={s.financialEntity}
                            handleFinancialEntitychange={handleFinancialEntityTransactionChange}
                            delivery={s.placeDelivery}
                            handleDeliveryChange={handleDeliveryTransactionChange}
                            moneyList={['Dólares', 'Soles', 'Euros']}
                            paymentList={['Transferencia bancaria', 'Pago por adelantado', 'Pago contra documentos', 'Pago a plazo', 'Pago diferido', 'Carta de crédito']}
                            requiredAll
                            disabled={showEdit}
                            countryList={countryList}
                        />
                    </div>

                    <div className='invoceTranslationSupplier-add-container'>
                        <div className="invoceTranslationSupplier-add-container--item  smalltext-header" onClick={()=>{handleAddProvider(); setProviderActive(supplier.length)}}> + Agregar otro proveedor</div>
                        
                        {/* {idx> 0 &&  */}
                        <div className="paragraph-header supplierInvoceTranslation-delete" 
                            onClick={()=>{handleDeleteProvider(idx); setProviderActive(idx-1)}}>
                                <Trash/>
                        </div>
                        {/* } */}
                    </div>
                    <TabsProduct 
                        indexProvider={idx}
                        products={s.product}
                        supplier={supplier}
                        setSupplier={setSupplier}
                        disabled={!showEdit}
                        setDisabled={setDisabled}
                        isNew={isNew && supplier.length>1 && idx === 0 }
                        handleMoveProduct={handleMoveProduct}
                        handleAutoSave={handleSave}
                        countryList={countryList}
                    />
                    
                </React.Fragment>
                ))}
                
            </div>
            

            <div className='invoceTranslationSupplier-statement box-shadow-card'>
                <div className='invoceTranslationSupplier-text paragraph-header'>Declaración jurada</div>
                <div className='invoceTranslationSupplier-text paragraph'>Esta es información que pide la aduana peruana para todas las mercancías que arriban al país.</div>
                <div>
                    <Button
                        content='Ir a la declaración'
                        size='normal'
                        color='black-75'
                        onClick={()=>{setShowModalStatement(true)}}
                    />
                </div>
            </div>
           

            { supplier_ &&
                <div className='invoceTranslationSupplier-btns'>
                    <ButtonIcon content="guardar borrador"
                                Icon={<FolderPlusGray />}
                                color='clear'
                                size="extra-small"
                                place="left"/>
                    <ButtonEditChange onClickEdit={handleEdit}
                                  edit={disabled}
                                  setEdit={setDisabled}
                                  add={disabled}
                                  onClickCancel={handleClickCancel}
                                  onClickComplete={()=>{}}
                                  onClickSave={HandleClickSave}/>
                </div>

                // <div className='invoceTranslationSupplier-btn-confirm'>
                //     <ButtonEditChange 
                //         onClickEdit={()=>{}}
                //         edit={showEdit}
                //         setEdit={setShowEdit}
                //         add={showEdit}
                //         onClickCancel={()=>{}}
                //         onClickComplete={()=>{}}
                //         onClickSave={()=>{}}
                //     />
                // </div>
            }
            <ModalStatement
                setShowModal={setShowModalStatement}
                showModal={showModalStatement}
                title='¿Existe Vinculación entre el importador y el proveedor extranjero?'
                handleSaveStatement={handleStatement}
                providerIdx={providerActive}
                statement={supplier[providerActive].statement}
            />
        </div>
    )
}