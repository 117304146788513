import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, getDoc, doc, addDoc, updateDoc } from "firebase/firestore"; 

import { InvoiceTranslation } from "../interfaces/InvoiceTranslation";

const GetInvoiceTranslationById = async(id: string) => {
    const response = await getDoc(doc(DB, 'invoiceTranslation', id));
    /*console.log(response.data())*/
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()});
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

const UpdateInvoiceTranslation = async(data: InvoiceTranslation) => {
    try {
        if(data.id) {
            const invoiceTranslationRef = doc(DB, 'invoiceTranslation', data.id);
            const response =  await updateDoc(invoiceTranslationRef, {...data});
            // console.log("UpdateInvoiceTranslation", response)
            return true;
        } else {
            return false;
        }
       
    } catch (error) {
        console.log("🚀 ~ file: InvoiceTranslation.service.ts:31 ~ UpdateInvoiceTranslation ~ error", error)
        return false;
    }
}

const CreateInvoiceTranslation = async(data: InvoiceTranslation) => {
    try {
        const response = await addDoc(collection(DB, "invoiceTranslation"), data);  
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
    }
}

export {
    GetInvoiceTranslationById,
    UpdateInvoiceTranslation,
    CreateInvoiceTranslation
}