import React, { useEffect, useState } from "react";

// Importando estilo
import "./PlanDetail.css";
import { CheckWhite } from "../../icons/outline/CheckWhite";
import { CloseGray } from "../../icons/outline/CloseGray";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";
import { ComparePlansInterface } from "../../interfaces/ComparePlanInterface";
import { CheckBlack } from "../../icons/outline/CheckBlack";
import { CloseBigBlack } from "../../icons/solid/CloseBigBlack";

interface PlanDetailProps {
    comparePlan: ComparePlansInterface,
    black?: boolean;
}

export const PlanDetail: React.FC<PlanDetailProps> = ({ 
    comparePlan,
    black
}) => {

    return (
        <div className={`planDetail ${black ? "planDetail-black" : "planDetail-white"}`}>
            {/** Cabecera */}
            <div className="planDetail-content-header">
                { comparePlan.titles.map((item, idx) => (
                    <div className="planDetail___row planDetail__bottom" key={idx}>
                        <div className="planDetail_min_header big-subheader" style={{color: black ? "#fff" : "#000"}}>{item.title}</div>
                        <div className="planDetail___row__right">
                            { item.values.map((item, idy) => (
                                <div key={idy} className="planDetail__index_header big-subheader" style={{color: black ? "#fff" : "#000"}}>
                                    {item.type === "boolean" ?  (item.value === true ? <CheckWhite/> : <CloseGray/>  ) : item.value }
                                </div>
                            ))}
                        </div> 
                    </div>
                ))}
            </div>

            {/** Cursos */}  
            <div className="planDetail-content">
                <div className="planDetail_min paragraph-header" style={{color: black ? "#fff" : "#000"}}>Curso Emprendedor Digital con IA</div>
                {comparePlan?.courses.map((row, idz) => (
                    <div className="planDetail___row planDetail__bottom" key={idz}>
                        <div className="planDetail_min paragraph" style={{color: black ? "#fff" : "#000"}}>{row.title}</div>
                        <div className="planDetail___row__right">
                            { row.values.map((item, idy) => (
                                <div key={idy} className="planDetail__index paragraph" style={{color: black ? "#fff" : "#000"}}>
                                    {item.type === "boolean" ?  
                                    (item.value === true ? (black ?<CheckWhite/> : <CheckBlack/>) : 
                                    (black ?<CloseGray/>: <CloseBigBlack/>)) : item.value }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            {/** Logística y aduana */}  
            <div className="planDetail-content">
                <div className="planDetail_min paragraph-header" style={{color: black ? "#fff" : "#000"}}>Logística y aduana</div>
                {comparePlan?.logistics.map((row, idz) => (
                    <div className="planDetail___row planDetail__bottom" key={idz}>
                        <div className="planDetail_min paragraph" style={{color: black ? "#fff" : "#000"}}>{row.title}</div>
                        <div className="planDetail___row__right">
                            { row.values.map((item, idy) => (
                                <div key={idy} className="planDetail__index paragraph" style={{color: black ? "#fff" : "#000"}}>
                                    {item.type === "boolean" ?  (item.value === true ? (black ?<CheckWhite/> : <CheckBlack/>) : 
                                    (black ?<CloseGray/>: <CloseBigBlack/>)) : item.value }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            {/** Búsqueda de proveedor */}  
            <div className="planDetail-content">
                <div className="planDetail_min paragraph-header" style={{color: black ? "#fff" : "#000"}}>Búsqueda de proveedor</div>
                {comparePlan?.supplier.map((row, idz) => (
                    <div className="planDetail___row planDetail__bottom" key={idz}>
                        <div className="planDetail_min paragraph" style={{color: black ? "#fff" : "#000"}}>{row.title}</div>
                        <div className="planDetail___row__right">
                            { row.values.map((item, idy) => (
                                <div key={idy} className="planDetail__index paragraph" style={{color: black ? "#fff" : "#000"}}>
                                    {item.type === "boolean" ?  (item.value === true ? (black ?<CheckWhite/> : <CheckBlack/>) : 
                                    (black ?<CloseGray/>: <CloseBigBlack/>)) : item.value }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            {/** Courier */}  
            <div className="planDetail-content">
                <div className="planDetail_min paragraph-header" style={{color: black ? "#fff" : "#000"}}>Courier</div>
                {comparePlan?.courier.map((row, idz) => (
                    <div className="planDetail___row planDetail__bottom" key={idz}>
                        <div className="planDetail_min paragraph" style={{color: black ? "#fff" : "#000"}}>{row.title}</div>
                        <div className="planDetail___row__right">
                            { row.values.map((item, idy) => (
                                <div key={idy} className="planDetail__index paragraph" style={{color: black ? "#fff" : "#000"}}>
                                    {item.type === "boolean" ? (item.value === true ? (black ?<CheckWhite/> : <CheckBlack/>) : 
                                    (black ?<CloseGray/>: <CloseBigBlack/>)) : item.value }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            {/** Importaciones grupales */}  
            {/* <div className="planDetail-content">
                <div className="planDetail_min paragraph-header" style={{color: "#fff"}}>Asesorías</div>
                {PlanDetailConsts?.groupImports.map((row, idz) => (
                    <div className="planDetail___row planDetail__bottom" key={idz}>
                        <div className="planDetail_min paragraph" style={{color: "#fff"}}>{row.title}</div>
                        <div className="planDetail___row__right">
                            { row.values.map((item, idy) => (
                                <div key={idy} className="planDetail__index paragraph" style={{color: "#fff"}}>
                                    {item.type === "check" ?  (item.value === true ? <CheckWhite/> : <CloseGray/>  ) : item.value }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div> */}

            {/** Asesorías */}  
            <div className="planDetail-content">
                <div className="planDetail_min paragraph-header" style={{color: black ? "#fff" : "#000"}}>Asesorías</div>
                {comparePlan?.consulting.map((row, idz) => (
                    <div className="planDetail___row planDetail__bottom" key={idz}>
                        <div className="planDetail_min paragraph" style={{color: black ? "#fff" : "#000"}}>{row.title}</div>
                        <div className="planDetail___row__right">
                            { row.values.map((item, idy) => (
                                <div key={idy} className="planDetail__index paragraph" style={{color: black ? "#fff" : "#000"}}>
                                    {item.type === "boolean" ? (item.value === true ? (black ?<CheckWhite/> : <CheckBlack/>) : 
                                    (black ?<CloseGray/>: <CloseBigBlack/>)) : item.value }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            {/** Panel Principal */}  
            <div className="planDetail-content">
                <div className="planDetail_min paragraph-header" style={{color: black ? "#fff" : "#000"}}>Panel principal</div>
                {comparePlan?.mainPanel.map((row, idz) => (
                    <div className="planDetail___row planDetail__bottom" key={idz}>
                        <div className="planDetail_min paragraph" style={{color: black ? "#fff" : "#000"}}>{row.title}</div>
                        <div className="planDetail___row__right">
                            { row.values.map((item, idy) => (
                                <div key={idy} className="planDetail__index paragraph" style={{color: black ? "#fff" : "#000"}}>
                                    {item.type === "boolean" ? (item.value === true ? (black ?<CheckWhite/> : <CheckBlack/>) : 
                                    (black ?<CloseGray/>: <CloseBigBlack/>)) : item.value }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            {/** Pasos de importación */}  
            <div className="planDetail-content">
                <div className="planDetail_min paragraph-header" style={{color: black ? "#fff" : "#000"}}>Pasos de importaciónuda</div>
                {comparePlan?.importSteps.map((row, idz) => (
                    <div className="planDetail___row planDetail__bottom" key={idz}>
                        <div className="planDetail_min paragraph" style={{color: black ? "#fff" : "#000"}}>{row.title}</div>
                        <div className="planDetail___row__right">
                            { row.values.map((item, idy) => (
                                <div key={idy} className="planDetail__index paragraph" style={{color: black ? "#fff" : "#000"}}>
                                    {item.type === "boolean" ? (item.value === true ? (black ?<CheckWhite/> : <CheckBlack/>) : 
                                    (black ?<CloseGray/>: <CloseBigBlack/>)) : item.value }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            {/** Ayuda */}  
            <div className="planDetail-content">
                <div className="planDetail_min paragraph-header" style={{color: black ? "#fff" : "#000"}}>Ayuda</div>
                {comparePlan?.help.map((row, idz) => (
                    <div className="planDetail___row planDetail__bottom" key={idz}>
                        <div className="planDetail_min paragraph" style={{color: black ? "#fff" : "#000"}}>{row.title}</div>
                        <div className="planDetail___row__right">
                            { row.values.map((item, idy) => (
                                <div key={idy} className="planDetail__index paragraph" style={{color: black ? "#fff" : "#000"}}>
                                    {item.type === "boolean" ? (item.value === true ? (black ?<CheckWhite/> : <CheckBlack/>) : 
                                    (black ?<CloseGray/>: <CloseBigBlack/>)) : item.value }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div className="planDetail-content-down">
                <div className="planDetail_min paragraph-header" style={{color: black ? "#fff" : "#000"}}>Ver menos</div>
                <img className="planDetail-img" src={STORAGE_ASSETS+'/images/png/down-white.png'}/>
            </div>
        </div>
    );
};
