import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, getDocs, query, orderBy, where } from "firebase/firestore"; 

const GetAllServicesTypeActive =async () => {
    const q = query(collection(DB, "serviceType"), where("status", "==", true), orderBy("updateAt", "desc"))
    const response = await getDocs(q);

    let data:any[] = [];

    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
} 


const GetAllServicesHover =async () => {
    const q = query(collection(DB, "serviceType"), where("hover", "==", false), orderBy("updateAt", "desc"))
    const response = await getDocs(q);

    let data:any[] = [];

    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
} 

export { GetAllServicesTypeActive, GetAllServicesHover }