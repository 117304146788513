import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";
import { UserCompany } from "../interfaces/UserCompany";

export const CreateUserCompany = async(userCompany: UserCompany) => {
    try {
        const response:any = await addDoc(collection(DB, "userCompany"), userCompany);
        // console.log("create user", response);  
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
    }
}

export const GetUserCompany = async(userCompanyId: string) => {
    const response = await getDoc(doc(DB, "userCompany", userCompanyId)); // trae el documento segun "id" de la coleccion de firestore
    // console.log(response)
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()})
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

export const GetUserCompanyByUserId = async(userId: string) => {
    const q = query(collection(DB, "userCompany"), where("userId", "==", userId));
    const response = await getDocs(q);
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
        
    }
    return data[0];
}


export const UpdateUserCompany = async (userCompanyId: string, userCompany: any) => {
    try {
        if(userCompanyId) {
            const requestRef = doc(DB, 'userCompany', userCompanyId);
            const response = await updateDoc(requestRef, {...userCompany});

            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

