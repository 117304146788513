
import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type PlayIconProps = React.HTMLAttributes<HTMLDivElement> & {};

export const PlayIcon: React.FC<PlayIconProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/icono_play.svg"} alt="" style={{height: '25px'}}/>
    </div>
  );
};