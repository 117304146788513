import axios from "axios";
import { FUNCTIONS_API, FUNCTIONS_API_COMPARE_PLAN } from "../Consts/BaseUrl";
import { ComparePlansInterface } from "../interfaces/ComparePlanInterface";
import { PlanDetailInterface } from "../interfaces/PlanDetail";
import { doc, getDoc } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";

const { 
    REACT_APP_BASIC_AUTH
  } = process.env;

// method to get compare table
export const getCompareTablePlans = async (): Promise<ComparePlansInterface>=> {
    let config = {
        method: 'get',
        url: FUNCTIONS_API+FUNCTIONS_API_COMPARE_PLAN,
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Basic '+REACT_APP_BASIC_AUTH,
        }
    };
    return axios.request(config)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error);
            throw error;
        });
}

// method to get the plan detail by id
export const GetPlanDetailById = async(id:string): Promise<PlanDetailInterface> => {
    const response = await getDoc(doc(DB, 'planDetail', id));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            const data: PlanDetailInterface = response.data() as PlanDetailInterface;
            resolve(data);
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}