import React, { useEffect, useState } from "react";

/** importando componentes */
import { ArrowUp } from "../../../icons/outline/ArrowUp";
import { ArrowDown } from "../../../icons/solid/ArrowDown";
import { InputElement } from "../../InputElement/InputElement";

// importando utilidades
import { ConverDecimal, ConverMoneyFormat } from "../../../utils/ConvertDecimal";
import { RoundNumber } from "../../../utils/roundNumber";

// importando interfaces
import { ImportCost } from "../../../interfaces/Quotation";
import { Taxes } from "../../../interfaces/Quotation";

/** importando estilo */
import './RowGroupSupplier.css';
import { ContainerType } from "../../../interfaces/ContainerType";
import { InputElementMin } from "../../InputElementMin/InputElementMin";


/** declarando variables de interface */
interface RowGroupSupplierProps {
    disabled: boolean;
    open?: boolean // estado de collapse;
    open1?: boolean // estado de collapse;
    open2?: boolean // estado de collapse;
    importCost: ImportCost;
    setImportCost: React.Dispatch<React.SetStateAction<ImportCost>>
    total: string;
    setTotal: React.Dispatch<React.SetStateAction<string>>
    role?: string;
    typeContainter: ContainerType | undefined // tipo de contenedor
    originCountry: string; // pais de origen
    destinationCountry: string; // pais de destino
    insurance: boolean; // la carga tendra seguro?
    isLcl: boolean; // es LCL
    handleAutoSave?: ()=>void;
    isCourier?: boolean;

    taxesValue: Taxes,
    setTaxesValue: React.Dispatch<React.SetStateAction<Taxes>>,
    merchandiseValue: number
}

export const RowGroupSupplier: React.FC<RowGroupSupplierProps> = ({
    disabled,
    open,
    open1,
    open2,
    importCost,
    setImportCost,
    total,
    setTotal,
    role,
    originCountry,
    destinationCountry,
   
    typeContainter = {
        lcl: false,
        fcl: false,
        lclAir: false
    },
    insurance,
    isLcl,
    handleAutoSave=()=>{},
    isCourier = false,
    
    taxesValue,
    setTaxesValue,
    merchandiseValue,
}) => {
    
    const [collapse, setCollapse] = useState(open);
    const [collapse1, setCollapse1] = useState(open1);
    const [collapse2, setCollapse2] = useState(open2);

    const [cif, setCif] = useState<number>(Number(importCost.serviceOrigin.subTotal)+merchandiseValue)


    // metodos
    // metidoos servicios de origen 
    // handle transporta internacional
    const handleTransportChange = (value: number) => {
        // console.log(value)
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceOrigin: {
                    ...prev.serviceOrigin,
                    internationalTransport:Number(value)>=0? value: prev.serviceOrigin.internationalTransport
                }
            }
        });
        handleAutoSave();
    }

    // handle seguro
    const handleSureChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceOrigin: {
                    ...prev.serviceOrigin,
                    insurance: Number(value)>=0? value: prev.serviceOrigin.insurance
                }
            }
        });
        handleAutoSave();
    }

    // handle gastos de origen
    const handleExpensesOriginChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceOrigin: {
                    ...prev.serviceOrigin,
                    expensiveOrigin: Number(value)>=0? value: prev.serviceOrigin.expensiveOrigin
                }
            }
        });
        handleAutoSave();
    }

    // metodos servicios de destino
    // handle gastos de almacenaje
    // const handleWarehouseExpensesChange = (value: number) => {
    //     setImportCost((prev:any)=>{
    //         return {
    //             ...prev,
    //             serviceDestination: {
    //                 ...prev.serviceDestination,
    //                 warehouseExpenses: Number(value)>=0? value: prev.serviceDestination.warehouseExpenses
    //             }
    //         }
    //     });
    // }

      // handle gastos de almacenaje
      const handleStorangeExpensesChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    storageExpenses: Number(value)>=0? value: prev.serviceDestination.storageExpenses
                }
            }
        });
        handleAutoSave();
    }

      // handle sobre costos
      const handleOnCostsChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    onCosts: Number(value)>=0? value: prev.serviceDestination.onCosts
                }
            }
        });
        handleAutoSave();
    }

      // handle almacenaje
      const handleStorageChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    storage: Number(value)>=0? value: prev.serviceDestination.storage
                }
            }
        });
        handleAutoSave();
    }

    // handle gastos operativos
    const handleOperatingExpensesChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    operationExpensive: Number(value)>=0? value: prev.serviceDestination.operationExpensive
                }
            }
        });
        handleAutoSave();
    }

    // handle gastos administrativeExpensive
    const handleAdministrativeExpensiveChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    administrativeExpensive: Number(value)>=0? value: prev.serviceDestination.administrativeExpensive
                }
            }
        });
        handleAutoSave();
    }

    // handle desaduanaje
    const handleDesadunajeChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    desadunaje: Number(value)>=0? value: prev.serviceDestination.desadunaje
                }
            }
        });
        handleAutoSave();
    }

    // handle gastos approval
    const handleapprovalChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    approval: Number(value)>=0? value: prev.serviceDestination.approval
                }
            }
        });
        handleAutoSave();
    }

    // handle handling 
    const handleaHandlingChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    handling: Number(value)>=0? value: prev.serviceDestination.handling
                }
            }
        });
        handleAutoSave();
    }

    // handle transporte local
    const handleLocalTransportChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    localTransport: Number(value)>=0? value: prev.serviceDestination.localTransport
                }
            }
        });
        handleAutoSave();
    }

    // handle devolucion del contenedor
    const handleContainerReturnChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    returnContain: Number(value)>=0? value: prev.serviceDestination.returnContain
                }
            }
        });
        handleAutoSave();
    }

    // handle desconsolidacion del contenedor
    const handleDeconsolidationChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    deconsolidation: Number(value)>=0? value: prev.serviceDestination.deconsolidation
                }
            }
        });
        handleAutoSave();
    }

    // handle comision por agenciamiento de aduna
    const handleCommissionChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    commissionCustoms: Number(value)>=0? value: prev.serviceDestination.commissionCustoms
                }
            }
        });
        handleAutoSave();
    }

    // handle otros gastos
    const handleOtherExpensesChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    otherExpenses: Number(value)>=0? value: prev.serviceDestination.otherExpenses
                }
            }
        });
        handleAutoSave();
    }
    
    // handle IGV (18%)
    const handleIgvChange = (value: number) => {
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    tax: Number(value)>=0? value: prev.serviceDestination.tax
                }
            }
        });
        handleAutoSave();
    }

    // metodos 
    // handle change advalorem
    const handleAdValoremChange = (value: number|string) => {
        setTaxesValue((prev: any) => {
            return {
                ...prev,
                adValorem: Number(value)>=0?value : prev.adValorem
            }
        });
        handleAutoSave();
    }

    // handle change IGV (16%)
    const handleIgvTaxesChange = (value: number|string) => {
        setTaxesValue((prev:any)=>{
            return {
                ...prev,
                igv: Number(value)>=0?value : prev.igv
            }
        });
        handleAutoSave();
    }

    // handle change IMP
    const handleIpmChange = (value: number|string ) => {
        setTaxesValue((prev:any)=>{
            return {
                ...prev,
                ipm: Number(value)>=0?value : prev.ipm
            }
        });
        handleAutoSave();
    }

    // handle change percepcion
    const handlePerceptionChange = (value: number|string) => {
        setTaxesValue((prev:any)=>{
            return {
                ...prev,
                perception: Number(value)>=0?value : prev.perception
            }
        });
        handleAutoSave();
    }

    //handle change ISC
    const handleISCChange = (value: number|string) => {

        setTaxesValue((prev:any)=>{
            return {
                ...prev,
                isc: Number(value)>=0?value : prev.isc
            }
        });
        handleAutoSave();
    }

    // handle change derechos especificos
    const handleSpecificLawChange = (value: number|string) => {
        setTaxesValue((prev:any)=>{
            return {
                ...prev,
                specificRight: Number(value)>=0?value : prev.specificRight
            }
        });
        handleAutoSave();
    }

    // handle change derecho antidumping
    const handleAntidumpingLawChange = (value: number|string) => {
        setTaxesValue((prev:any)=>{
            return {
                ...prev,
                antidumpi: Number(value)>=0?value : prev.antidumpi
            }
        });
        handleAutoSave();
    }

    const handleSurchargeChange = (value: number|string) => {
        setTaxesValue((prev:any)=>{
            return {
                ...prev,
                surcharge: Number(value)>=0?value : prev.surcharge
            }
        });
        handleAutoSave();
    }

    /** Bloque 1, import cost */
    useEffect(() => {
        const { internationalTransport, expensiveOrigin, insurance } = importCost.serviceOrigin;
        let sum = Number(internationalTransport) + Number(expensiveOrigin) + Number(insurance);
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceOrigin: {
                    ...prev.serviceOrigin,
                    subTotal: Number(Number(RoundNumber(sum)).toFixed(2))
                }
            }
        });

    }, [importCost.serviceOrigin.internationalTransport, importCost.serviceOrigin.expensiveOrigin, importCost.serviceOrigin.insurance]);

    // /** Bloque 2, import destiny */
    useEffect(() => {
        const { storage, commissionCustoms, localTransport, operationExpensive, returnContain, deconsolidation, tax, storageExpenses, onCosts, administrativeExpensive, approval, handling, otherExpenses, desadunaje } = importCost.serviceDestination;
        let sum = Number(storage) + Number(commissionCustoms) + Number(localTransport) + Number(operationExpensive) + Number(returnContain) + Number(deconsolidation) + Number(tax)  + Number(storageExpenses) + Number(onCosts) + Number(administrativeExpensive) + 
        Number(approval) + Number(otherExpenses) + Number(desadunaje)
       if(handling) {
        sum = sum + Number(handling);
       }
        setImportCost((prev:any)=>{
            return {
                ...prev,
                serviceDestination: {
                    ...prev.serviceDestination,
                    subTotal: Number(Number(RoundNumber(sum)).toFixed(2))
                }
            }
        });
    }, [importCost.serviceDestination.storage, importCost.serviceDestination.storageExpenses, importCost.serviceDestination.onCosts, importCost.serviceDestination.commissionCustoms,
        importCost.serviceDestination.localTransport, importCost.serviceDestination.operationExpensive, importCost.serviceDestination.approval, importCost.serviceDestination.administrativeExpensive,
        importCost.serviceDestination.returnContain, importCost.serviceDestination.deconsolidation, importCost.serviceDestination.otherExpenses, importCost.serviceDestination.handling, importCost.serviceDestination.tax]);

    useEffect(() => {
        const {adValorem, antidumpi, igv, ipm, isc, perception, specificRight, surcharge} = taxesValue;
        let sum = Number(adValorem) + Number(igv) + Number(ipm) + Number(perception) + Number(isc) +
                  Number(antidumpi) + Number(specificRight) + Number(surcharge);
        setTaxesValue((prev: any) => {
            return {
                ...prev,
                subTotal: Number(RoundNumber(sum))
            }
        })
    }, [taxesValue.adValorem, taxesValue.igv, taxesValue.ipm, taxesValue.isc, taxesValue.perception, taxesValue.specificRight, 
        taxesValue.antidumpi, taxesValue.surcharge])

    // Declarando suma total del bloque1 y el bloque 2
    useEffect(() => {
        let sum = Number(importCost.serviceOrigin.subTotal) + Number(importCost.serviceDestination.subTotal) + Number(taxesValue.subTotal);
        setTotal(RoundNumber(sum).toString());
        
    }, [importCost.serviceOrigin.subTotal, importCost.serviceDestination.subTotal, taxesValue.subTotal]);

    // console.log(importCost)

    return ( 
        <div className="rowGroup-supplier-container" role={role}>
            <div className="rowGroup-supplier-header-container rowGroup-supplier-header--pointer" onClick={()=>setCollapse(!collapse)}>
                <div className="paragraph-header rowGroup-supplier-header--title">{`Servicios en origen ${originCountry && originCountry.length>0 ? '('+originCountry+')' : ''}`}</div>
                {!collapse ? <ArrowDown className="rowGroup-supplier-header--pointer"/> : 
                <ArrowUp className="rowGroup-supplier-header--pointer" />}
            </div>
            {
                collapse && 
                <div className="rowGroup-supplier-body">
                    <div className="rowGroup__" >
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Transporte internacional</div>
                        <InputElementMin
                            value={(importCost.serviceOrigin.internationalTransport)}
                            name=""
                            title=""
                            onChange={handleTransportChange}
                            disable={disabled}
                        />
                    </div>
                   {insurance && <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Seguro</div>
                        <InputElementMin
                            value={importCost.serviceOrigin.insurance}
                            name=""
                            title=""
                            onChange={handleSureChange}
                            disable={disabled}
                        />
                    </div>}
                    <div className={`rowGroup__`}>
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Gastos de Origen</div>
                        <InputElementMin
                            value={importCost.serviceOrigin.expensiveOrigin}
                            name=""
                            title=""
                            onChange={handleExpensesOriginChange}
                            disable={disabled}
                        />
                    </div>
                    <div className="rowGroup_black" >
                        <div className="paragraph" style={{color: "var(--white)"}}>Subtotal 1</div>
                        <div className="paragraph" style={{color: "var(--white)"}}>{ConverMoneyFormat(importCost.serviceOrigin.subTotal.toString())}</div>
                    </div>
                </div>
            }  


            {/** Segundo bloque  */ } 
            <div className="rowGroup-supplier-header-container rowGroup-supplier-header--pointer" onClick={()=>setCollapse1(!collapse1)}>
                <div className="paragraph-header rowGroup-supplier-header--title">{`Servicios en destino ${destinationCountry && destinationCountry.length>0 ? '('+destinationCountry+')' : ''}`}</div>
                {!collapse1 ? <ArrowDown className="rowGroup-supplier-header--pointer"/> : 
                <ArrowUp className="rowGroup-supplier-header--pointer" />}
            </div>

            {
                collapse1 && 
                <div className="rowGroup-supplier-body">
                    {!isCourier && <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Visto bueno</div>
                        <InputElementMin
                            value={importCost.serviceDestination.approval}
                            name=""
                            title=""
                            onChange={handleapprovalChange}
                            disable={disabled}
                        />
                    </div>}
                    {/* <div className="rowGroup__" >
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Gastos de almacén</div>
                        <InputElement
                            value={importCost.serviceDestination.warehouseExpenses}
                            name=""
                            title=""
                            onChange={handleWarehouseExpensesChange}
                            disable={disabled}
                        />
                    </div> */}
                    <div className="rowGroup__" >
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Almacén</div>
                        <InputElementMin
                            value={importCost.serviceDestination.storage}
                            name=""
                            title=""
                            onChange={handleStorageChange}
                            disable={disabled}
                        />
                    </div>
                    {!isCourier && (typeContainter.lcl || typeContainter.lclAir) && <div className="rowGroup__ rowGroup_white">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Desconsolidación</div>
                        <InputElementMin
                            value={importCost.serviceDestination.deconsolidation}
                            name=""
                            title=""
                            onChange={handleDeconsolidationChange}
                            disable={disabled}
                        />
                    </div>}
                    {(isCourier || isLcl || typeContainter.lclAir) && <div className={`rowGroup__ ${typeContainter.fcl? 'rowGroup_transparent': 'rowGroup_white'}`}>
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Handling</div>
                        <InputElementMin
                            value={importCost.serviceDestination.handling}
                            name=""
                            title=""
                            onChange={handleaHandlingChange}
                            disable={disabled}
                        />
                    </div>}
                    {!isCourier && <div className="rowGroup__" >
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Gastos de almacenaje</div>
                        <InputElementMin
                            value={importCost.serviceDestination.storageExpenses}
                            name=""
                            title=""
                            onChange={handleStorangeExpensesChange}
                            disable={disabled}
                        />
                    </div>}
                    <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Gastos operativos</div>
                        <InputElementMin
                            value={importCost.serviceDestination.operationExpensive}
                            name=""
                            title=""
                            onChange={handleOperatingExpensesChange}
                            disable={disabled}
                        />
                    </div>
                    {!isCourier && <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Gastos administrativos</div>
                        <InputElementMin
                            value={importCost.serviceDestination.administrativeExpensive}
                            name=""
                            title=""
                            onChange={handleAdministrativeExpensiveChange}
                            disable={disabled}
                        />
                    </div>}

                    {isCourier && <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Desadunaje</div>
                        <InputElementMin
                            value={importCost.serviceDestination.desadunaje}
                            name=""
                            title=""
                            onChange={handleDesadunajeChange}
                            disable={disabled}
                        />
                    </div>}

                    <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Transporte local</div>
                        <InputElementMin
                            value={importCost.serviceDestination.localTransport}
                            name=""
                            title=""
                            onChange={handleLocalTransportChange}
                            disable={disabled}
                        />
                    </div>
                    {!isCourier && <div className="rowGroup__" >
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Comisión por agenciamiento de aduana</div>
                        <InputElementMin
                            value={importCost.serviceDestination.commissionCustoms}
                            name=""
                            title=""
                            onChange={handleCommissionChange}
                            disable={disabled}
                        />
                    </div>}
                    {!isCourier && <div className="rowGroup__" >
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Sobrecostos</div>
                        <InputElementMin
                            value={importCost.serviceDestination.onCosts}
                            name=""
                            title=""
                            onChange={handleOnCostsChange}
                            disable={disabled}
                        />
                    </div>}
                    {!isCourier && typeContainter.fcl && <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Devolución del contenedor</div>
                        <InputElementMin
                            value={importCost.serviceDestination.returnContain}
                            name=""
                            title=""
                            onChange={handleContainerReturnChange}
                            disable={disabled}
                        />
                    </div>}
                    <div className="rowGroup__" >
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Otros gastos</div>
                        <InputElementMin
                            value={importCost.serviceDestination.otherExpenses}
                            name=""
                            title=""
                            onChange={handleOtherExpensesChange}
                            disable={disabled}
                        />
                    </div>
                    <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}> IGV (18%) </div>
                        <InputElementMin
                            value={importCost.serviceDestination.tax}
                            name=""
                            title=""
                            onChange={handleIgvChange}
                            disable={disabled}
                        />
                    </div>
                    <div className="rowGroup_black" >
                        <div className="paragraph" style={{color: "var(--white)"}}>Subtotal 2</div>
                        <div className="paragraph" style={{color: "var(--white)"}}>{ConverMoneyFormat(importCost.serviceDestination.subTotal.toString())}</div>
                    </div>
                </div>
            } 

            {/** Tercer bloque  */ } 
            <div className="rowGroup-supplier-header-container rowGroup-supplier-header--pointer" onClick={()=>setCollapse2(!collapse2)}>
                <div className="paragraph-header rowGroup-supplier-header--title">Impuesto</div>
                {!collapse2 ? <ArrowDown className="rowGroup-supplier-header--pointer"/> : 
                <ArrowUp className="rowGroup-supplier-header--pointer" />}
            </div> 

            {
                collapse2 && 
                <div className="rowGroup-supplier-body">
                    <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Ad Valorem</div>
                        <InputElementMin
                            value={taxesValue.adValorem}
                            name=""
                            title=""
                            onChange={handleAdValoremChange}
                            disable={disabled}
                        />
                    </div>

                    <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>IGV (16%)</div>
                        <InputElementMin
                            value={taxesValue.igv}
                            name=""
                            title=""
                            onChange={handleIgvTaxesChange}
                            disable={disabled}
                        />
                    </div>

                    <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>IPM (2%)</div>
                        <InputElementMin
                            value={taxesValue.ipm}
                            name=""
                            title=""
                            onChange={handleIpmChange}
                            disable={disabled}
                        />
                    </div>

                    <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Percepción</div>
                        <InputElementMin
                            value={taxesValue.perception}
                            name=""
                            title=""
                            onChange={handlePerceptionChange}
                            disable={disabled}
                        />
                    </div>

                    {!isCourier && <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Impuesto Selectivo al Consumo</div>
                        <InputElementMin
                            value={taxesValue.isc}
                            name=""
                            title=""
                            onChange={handleISCChange}
                            disable={disabled}
                        />
                    </div>}

                    {/* {!isCourier && <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Impuesto Selectivo al Consumo</div>
                        <InputElementMin
                            value={taxesValue.specificRight}
                            name=""
                            title=""
                            onChange={handleSpecificLawChange}
                            disable={disabled}
                        />
                    </div>} */}

                    {!isCourier && <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Derecho Antidumping</div>
                        <InputElementMin
                            value={taxesValue.antidumpi}
                            name=""
                            title=""
                            onChange={handleAntidumpingLawChange}
                            disable={disabled}
                        />
                    </div>}
                    {!isCourier && <div className="rowGroup__">
                        <div className="paragraph rowGroup__text" style={{color: "var(--gray-1)"}}>Sobretasa</div>
                        <InputElementMin
                            value={taxesValue.surcharge}
                            name=""
                            title=""
                            onChange={handleSurchargeChange}
                            disable={disabled}
                        />
                    </div>}

                    <div className="rowGroup_black" >
                        <div className="paragraph" style={{color: "var(--white)"}}>Subtotal 3</div>
                        <div className="paragraph" style={{color: "var(--white)"}}>{ConverMoneyFormat(taxesValue.subTotal.toString())}</div>
                    </div>
                </div>
            }

            {/** Footer total*/}
            <div className="paragraph-header rowGroupFooter__">
                <div className="paragraph-header" style={{color: "var(--gray-1)"}}> Total USD </div>
                <div className="paragraph-header" style={{color: "var(--gray-1)"}}>$ {ConverMoneyFormat(total.toString())} </div>
            </div>
        </div>
    )
}

