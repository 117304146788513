import React, { useState } from "react";

// ** Importanto componentes*
import { BodyMonth } from "./BodyMonth/BodyMonth";
import { HeaderMonth } from "./HeaderMonth/HeaderMonth";
import { TranslateMonth } from "../../../utils/translateMonth";
import { DaysNameMin } from '../../../Consts/daysNameMin';


// ** Importanto utilidades*
import { SelectMonth } from '../../../utils/SelectMonth';

// ** Importanto formato*
import {format, isSameMonth} from "date-fns";

// ** Importanto estilos*
import './CalendarMonth.css';

interface CalendarMonthProps {
    disabled?: boolean
    setDay: (e: Date) => void;
    data: any[];
    selectedMonth: Date,
}

export const CalendarMonth: React.FC<CalendarMonthProps> = ({
    disabled = false,
    setDay,
    data,
    selectedMonth,
}) => {
    const [selectMonth, setSelectedMonth] = useState(new Date());
    const [today, setToday] = useState(new Date());
   
    const onClickDay = (day: Date) => {
        setSelectedMonth(day);
        setDay(day);
    }

    return (        
        <div className="calendar-month-container">
            <div className="calendar-month-title">
                <div className="medium-header calendar-name-month">{`${TranslateMonth(format(selectedMonth, 'MMMM'))}`}</div> 
                <div className="small-subheader calendar-month-year">{`${format(selectedMonth, 'yyyy')}`}</div>
            </div>
            <HeaderMonth rowData={DaysNameMin} header={true} />
            
            <div className="calendar-month-body">
                {data.length>0 && data.map((d, idx) => {
                    return (
                        <BodyMonth 
                            key={idx} 
                            rowData={d} 
                            selectedDate={selectMonth} 
                            today={today}
                            setSelectDay={!disabled ? onClickDay : ()=>{}}
                        />
                    )
                })}
            </div>
        </div>
    )
}