export const TranslateTime = (timeText:string ):string => {
    switch (timeText) {
        case 'seconds':
            return 'segundos'
        case 'second':
            return 'segundo'
        case 'minutes':
            return 'minutos'
        case 'minute':
            return 'minuto'
        case 'hours':
            return 'horas'
        case 'hour':
            return 'hora'
        case 'days':
            return 'días'
        case 'day':
            return 'día'
        case 'months':
            return 'meses'
        case 'month':
            return 'mes'
        case 'years':
            return 'años'
        case 'year':
            return 'año'
        default:
            return ''
    }
}


export const TranslateTimeMin = (timeText:string ):string => {
    switch (timeText) {
        case 'seconds':
            return 's'
        case 'second':
            return 's'
        case 'minutes':
            return 'min'
        case 'minute':
            return 'min'
        case 'hours':
            return 'h'
        case 'hour':
            return 'h'
        case 'day':
            return 'd'
        case 'days':
            return 'd'
        case 'months':
            return 'm'
        case 'month':
            return 'm'
        case 'years':
            return 'años'
        case 'year':
            return 'año'
        default:
            return ''
    }
}