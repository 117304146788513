import React from "react";

import { Row } from './../Row/Row';
import { RowContentType } from "../../@types/rowsContentType";

import './Table.css';

interface TableProps {
    header?: string
    headerValues?: string[];
    headerType?: RowContentType;
    body: any[],
    footer?: {
        title: string,
        values: any[]
    },
    content?: 'text' | 'number';
    handleImg?: (idx:number)=>void
}

export const Table: React.FC<TableProps> = ({
    header='',
    headerValues=[],
    headerType='text',
    body,
    content= 'text',
    footer,
    handleImg=()=>{}
}) => {
    return (
        <div className='table-container box-shadow-card'>
            <Row 
                title={header}
                color='white-90'
                columValues={headerValues}
                content={headerType}
                className="paragraph table-radius--top"
                classRow="paragraph-header table-color--text"
                classRowValue="paragraph-header table-color--text"
                handleImg={handleImg}
                imageClass="imageClass"
            />
            {body && body.map((v:any, idx:number)=> {
                    return (
                        v && <Row 
                            key={idx}
                            title={v.name}
                            color={'transparent'}
                            content={content}
                            columValues={v.value}
                            classRow="paragraph"
                            className="table-content-body"
                        />
                    )
                })   
            }
            {footer && <Row
                        title={footer.title}
                        color='white-90'
                        columValues={footer.values}
                        content="number"
                        className="paragraph table-raduis--bottom"
                        classRow="paragraph-header table-color--text"
                        classRowValue="paragraph-header table-color--text"
                    />}
        </div>
    )
}