import React, { useEffect, useRef } from "react";

import './ModalRQ.css';
import { Button } from "../..";
import { useActionData } from "react-router-dom";
import { CloseBig } from "../../../icons/solid/CloseBig";
import { set } from "date-fns";

interface ModalRQProps {
    sendMore: boolean;
    showModal: boolean;
    setShowModal: (e:boolean) => void;
    handleClick: () => void;
}

export const ModalRQ: React.FC<ModalRQProps> = ({
    sendMore,
    showModal,
    setShowModal,
    handleClick,
    ...props
}) => {

    const refModalRQ = useRef<HTMLDivElement>(null);

    const handleCloseModal = () => {
        setShowModal(false);
    }

    useEffect(() => {
        showModal ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    }, [showModal])

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (refModalRQ.current && !refModalRQ.current.contains(event.target)) {
                // console.log("click")
                setShowModal(false);
            }
          };
          document.addEventListener('click', handleClickOutside, true);
          return () => {
            document.removeEventListener('click', handleClickOutside, true);
          };
    }, []);
    
    return (
        <>
            {showModal &&
            <div role='modalRQRole' className="modalRQ">
                <div className="modalRQ-container" ref={refModalRQ}>
                    <div className="modalRQ-container-header">
                        <div className="modalRQ-container-nav">
                            <div className="small-bold ">{sendMore ? "¡Solicitudes enviadas!" : "¡Solicitud enviad!"}</div>
                            <div onClick={()=> handleCloseModal()} className="modalRQ-container-nav--close"><CloseBig /></div>
                        </div>
                        <div className="paragraph">{sendMore ? "Las agencias pronto te responderán con una cotización" : "La agencia pronto te responderá con una cotización"} 😉</div>
                    </div>
                    <div className="modalRQ-container-buttons">
                        <Button content="Salir" color="black-25" size="normal" onClick={()=> handleClick()}  />
                        <Button content="Ver detalle" color="black-75" size="normal" onClick={()=> handleCloseModal()} />
                    </div>
                </div>   
            </div>}  
        </>
    )
}