import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate, useParams } from "react-router-dom";
import { ImportTimelineStages } from "../../../../@types/timelineStagesTypes";
import { toast } from "react-toastify";

import debounce from "lodash.debounce";

// Importando store
import type { AppDispatch } from "../../../../Store/Store";

/** importando componentes */
import { OperationHeader } from "../../../../components";
import { ImportTimelineSupplier } from "../../../../components/ImportTimelineSupplier/ImportTimelineSupplier";
import { QuoteTransportData } from "../../../../components/QuoteTransportData/QuoteTransportData";
import { QuoteCustomsData } from "../../../../components/QuoteCustomsData/QuoteCustomsData";
import { Quote } from "../../../../components/Quotation/Quote/Quote";
import { MessageTopbar } from "../../../../components/MessageTopBar/MessageTopbar";
import { Chat } from "../../../../components/Chat/Chat";
import { Loader } from "../../../../components/Loader/Loader";
import { ImportPhasesCard } from "../../../../components/ImportPhasesCard/ImportPhasesCard";
import { HeaderCustomsPhases } from "../../../../components/HeaderCustomsPhases/HeaderCustomsPhases";
import { ImportationSubstructureConsignee } from "../../../../components/ImportationSubstructureConsignee/ImportationSubstructureConsignee";
import { ImportationSubstructureCargoagentandhscodeInput } from "../../../../components/ImportationSubstructureCargoagentandhscode/Input/ImportationSubstructureCargoagentandhscodeInput";
import { FilesFLC } from "../../../../components/FilesFLC/FilesFLC";
import { TrackingShipping } from "../../../../components/TranckingShipping/TrackingShipping";
import { InvoceTranslationImporter } from "../../../../components/InvoceTranslation/Importer/InvoceTranslationImporter";
import { InvoceTranslationLegal } from "../../../../components/InvoceTranslation/LegalRepresentative/InvoceTranslationLegal";
import { InvoceTranslationSupplier } from "../../../../components/InvoceTranslation/Supplier/InvoceTranslationSupplier";
import { ArrivalNotice } from "../../../../components/ArrivalandControl/ArrivalNotice/ArrivalNotice";
import { ChannelControl } from "../../../../components/ArrivalandControl/ChannelControl/ChannelControl";
import { ArrivalDelivery } from "../../../../components/ArrivalandControl/Delivery/ArrivalDelivery";
import { ReviewCard } from "../../../../components/ReviewCard/ReviewCard";
import { ProductMerchandiseModal } from "../../../../components/ProductMerchandiseModal/ProductMerchandiseModal";
import { UploadFileArray } from "../../../../components/UploadFile/UploadFileArray/UploadFileArray";
import { RowPhaseSeven } from "../../../../components/Row/RowPhaseSeven/RowPhaseSeven";
import { ButtonBadget } from "../../../../components/ButtonBadget/ButtonBadget";
import { ModalSendMessage } from "../../../../components/Modal/ModalSendMessage/ModalSendMessage";


// importando interfaces
import { OperationInterface } from "../../../../interfaces/Operation";
import { OrderInterface } from "../../../../interfaces/OrderInterface";
import { Consignee as ConsigneeInterface } from "../../../../interfaces/Consignee";

import { ProductDeclareInterface } from "../../../../interfaces/ProductDeclare";
import { QuotationDB } from "../../../../interfaces/Quotation";
import { RequestQuoteInterface } from "../../../../interfaces/RequestQuote";
import { BillingReviewInterface } from "../../../../interfaces/BillingReviewInterface";
import { ChatInterface } from "../../../../interfaces/ChatInterface";
import { Consignee } from "../../../../interfaces/Consignee";
import { reviewUserInterface } from "../../../../interfaces/reviewUser";
import { CargoShippet } from "../../../../interfaces/CargoShippet";
import { DocumentationInterface } from "../../../../interfaces/Documentation";
import { ConsigneeError } from "../../../../interfaces/ConsigneeError";
import { InvoiceTranslation } from "../../../../interfaces/InvoiceTranslation";
import { User } from "../../../../interfaces/User";
import { ArrivalControlChannel } from "../../../../interfaces/ArrivalControlChannelInterface";

/** importando services */
import { SetOrder, UpdateOrder } from "../../../../Services/Order.service";
import { CreateBillingReview, GetBillingReview, UpdateBillingReview } from "../../../../Services/BillingReview.service";
import { getDocumentationById } from "../../../../Services/Documentation.service";
import StoreService from "../../../../Services/Store.service";
import { CreateReviewUser, GetAllReviewOrderId } from "../../../../Services/ReviewUser.service";
import { GetCompanyById } from "../../../../Services/Company.service";
import { CreateCargoShippet, GetCargoShippetId, UpdateCargoShippet } from "../../../../Services/CargoShippet";
import { CreateConsignee, GetAllConsigneeByUserId, UpdateConsignee } from "../../../../Services/Consignee.service";
import { CreateInvoiceTranslation, GetInvoiceTranslationById, UpdateInvoiceTranslation } from "../../../../Services/InvoiceTranslation.service";
import { CreateArrivalControlChannel, GetArrivalControlChannel, UpdateArrivalControlChannel } from "../../../../Services/ArrivalControlChannel.service";
import { GetUserById } from "../../../../Services/User.service";
import { GetRequestQuoteById } from "../../../../Services/RequestQuote.service";
import { GetChatById } from "../../../../Services/Chat.service";

/** importando slices */
import { GetOperationDetail } from "../../../../Slices/Operation";
import { GetQuotationById } from "../../../../Slices/Quotation";
import { GetOrderById } from "../../../../Slices/Order";

/** importando consts */
import { CHAT, CUSTOMS, DETAIL, OPERATIONS, ORDER, PROVIDER } from "../../../../Consts/BaseUrl";
import {  phaseTypeService } from "../../../../Consts/phasesImport";
import { TypePersons } from "../../../../Consts/TypePersons";
import { TypeDocuments } from "../../../../Consts/TypeDocuments";
import { CommercialLevel } from "../../../../Consts/CommercialLevel";
import { ChannelControls } from "../../../../Consts/ChannelControls";
import { CompressFile, DocsFileValidation, ImageFileValidation, PDFFileValidation, SpreadSheetFileValidation } from "../../../../Consts/FilesValidation";

/** importando estilo */
import './ProcessPage.css';

/** Utilidades */
import { FormatDate } from "../../../../utils/formatDate";
import { ValidateField } from "../../../../utils/ValidateField";
import { Multimedia } from "../../../../components/Multimedia/Multimedia";
import { sendProviderMessageNotification, sendUserMessageNotification } from "../../../../Services/Whatsapp.service";
import { MessageBlack } from "../../../../icons/outline/MessageBlack";
import { Arrow } from "../../../../components/Arrow/Arrow";
import { DeliveryOrder } from "../../../../components/DeliveryOrder/DeliveryOrder";
import { SupplierSearchRequirements } from "../../../../components/SupplierSearchRequirements/SupplierSearchRequirements";
import { ButtonEditChange } from "../../../../components/ButtonEditChange/ButtonEditChange";


export const ProcessPage: React.FC<{}> = () => {
    
    const navigation = useNavigate();
    const params = useParams();

    const dispatch = useDispatch<AppDispatch>();

    const { userDetail } = useSelector((state: any) => state.user);
    const { operationList } = useSelector((state: any) => state.operation);

    const ref = useRef(null);

    const [phase, setPhase] = useState(1); // fase 0 chat
    const [mobileMode, setMobileMode] = useState(window.innerWidth<=880);
    const [loading, setLoading] = useState<boolean>(false);
    const [showEditProduct, setShowEditProduct] = useState(false);
    const [orderStatus, setOrderStatus] = useState<ImportTimelineStages>(2);
    const [proformaFile, setProformaFile] = useState<File[]>([]);
    const  [packingListFile, setPackingListFile] = useState<File[]>([]);
    const [dataSheetFile, setDataSheetFile] = useState<File[]>([]);
    const [showEdit, setShowEdit] = useState(false);
    const [showchat, setShowChat] = useState(true);
    const [timer, setTimer] = useState<any>(null);
    const [showLateral, setShowLateral] = useState<boolean>(false);

    // Modal para enviar mensaje
    const [showModalSendMessage, setShowModalSendMessage] = useState(false);

    // modals
    const [showMenu, setShowMenu] = useState(true);

    // useState search
    const [searchText, setSearchText] = useState<string>('');

    // state consignee
    const [consigneeSelected, setConsigneeSelected] = useState<string>();
    const [consigneeList, setConsigneeList] = useState<ConsigneeInterface[]>([]);
    const [consignee, setConsignee] = useState<Consignee>({
        companyName: '',
        fiscalAddress: '',
        itNumber: undefined,
        lastName: '',
        name: '',
        typeDocument: 'Persona jurídica',
        typeIt: 'RUC',
        userId: '',
        email: '',
        phone: undefined,
        status: true,
        createdAt: new Date(),
        updatedAt: new Date()
    });
    const [consigneeError, setConsigneeError] = useState<ConsigneeError>({
        typeIt: {
            message: '',
            status: false,
        },
        typeDocument: {
            message: '',
            status: false,
        }, 
        itNumber: {
            message: '',
            status: false,
        },
        companyName: {
            message: '',
            status: false,
        },
        fiscalAddress: {
            message: '',
            status: false,
        },
        email: {
            message: '',
            status: false,
        },
        phone: {
            message: '',
            status: false,
        },
        lastName: {
            message: '',
            status: false,
        },
        name: {
            message: '',
            status: false,
        }
    });

    const [operationDetail, setOperationDetail] = useState<OperationInterface>();
    const [orderDetail, setOrderDetail] = useState<OrderInterface>({
        activePhase: 0,
        companyId: '',
        companyName: '',
        country: {
            alpha2Code: '',
            imgFlag: '',
            name: ''
        },
        createdAt: undefined,
        paid: false,
        price: 0,
        quotationId: '',
        requestQuoteId: '',
        requirementsId: '',
        serviceId: '',
        serviceTypeId: '',
        serviceTypeLabel: '',
        planId: '',
        operationId: '', 
        status: false,
        title: '',
        type: 'Importación',
        updatedAt: new Date(),
        user: {
          id: '',
          userName: '',
        },
        number: '',
        phasesId: '',
    });
       
    // almacena el billing que se va crear o de la base de datos
    const [billingReviewData, setBillingReviewData] = useState<BillingReviewInterface>({
        companyId: '',
        orderId: '',
        serviceId: '',
        userId: '',
        billingUrl: [],
        reviewService: {
            comment: '',
            rating: 0,
            userId: '',
        },
        reviewClient: {
            comment: '',
            rating: 0,
            userId: ''
        },
        operationId: '',
        status: false,
        createdAt: new Date(),
        updatedAt: new Date(),
    });

    // Almacena el documento que se va a crear en la base de datos
    const [documentationData, setDocumentationData] = useState<DocumentationInterface>({
        companyId: '',
        orderId: '',
        serviceId: '',
        userId: '',
        providerId: '',
        operationId: '',
        comercialInvoice:[],
        packingList: [],
        billLading: [],
        insurancePolicy: [],
        additionDocumentation: [],
        lettering:[],
        status: false,
        createdAt: new Date(),
        updatedAt: new Date(),
    })

     /** InvoiceTranslation */
     const [invoiceTranslation, setInvoiceTranslation] = useState<InvoiceTranslation>({
        importer: {
            typeDocument: 'Persona jurídica',
            typeIt: 'DNI',
            nameCompany: '',
            itNumber: 0,
            commercialLevel: 'Mayorista',
            name: '',
            lastName: '',
        },
        legalRepresentative: {
            declarantName: '',
            declarantLastName: '',
            position: '',
            typeIt: 'DNI',
            itNumber: 0,
        },
        provider: [{
            name: '',
            invoiceNumber: '',
            country: {
                name: '',
                alpha2Code: '',
                imgFlag: '',
            },
            city: '',
            providerType: '',
            providerAddress: '',
            phone: '',
            incoterm: '',
            currency: '',
            countryAcquisition: {
                name: '',
                alpha2Code: '',
                imgFlag: '',
            },
            paymentType: '',
            financialEntity: '',
            placeDelivery: '',
            product: []
        }],
        userId: '',
        status: true,
        createdAt: new Date(),
        updatedAt: new Date()
    });

    const [arrivalControlChannel, setArrivalControlChannel] = useState<ArrivalControlChannel>({
        noticeArrival: [],
        dutiesTaxes: {
            taxableBase: {
                productCost: 0,
                internationalTransport: 0,
                sure: 0,
                total: 0,
            },
            taxes: {
                adValorem: 0,
                igv: 0,
                ipm: 0,
                perception: 0,
                total: 0,
            },
            file: [],
            exchangeRate: 0,
        },
        finalDeliveryPlace: {
            department: '',
            province: '',
            district: '',
            direction: '',
            reference: '',
        },
        controlChannel: 'No asignado todavía',
        status: true,
        userId: '',
        createdAt: new Date(),
        updatedAt: new Date(),
    })
   
    const [invoice, setInvoice] = useState<any>(undefined);
    const [review, setReview] = useState<{raintg:  number, review: string}>({
        raintg: 0,
        review: '',
    });

    // state agente de carga
    const [bulkingAgent, setBuilkingAgent] = useState<string>('');
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isDisabledTranslation, setIsDisabledTranslation] = useState<boolean>(true);

    const [quotationDetail, setQuotationDetail] = useState<QuotationDB>();
    const [requestQuote, setRequestQuote] = useState<RequestQuoteInterface>(
        {
            active: false,
            serviceId: '',
            serviceTypeLabel: '',
            status: 'draft',
            title: '',
            user: {
                id: '',
                itNumber: 0,
                name: '',
                numOperations: 0,
                typeIt: '',
                phone: '',
                rating: 0,
            },
            statusProvider: 'No leída',
            country: {
                alpha2Code:'',
                imgFlag: '',
                city: '',
            },
            companyName: '',
            numServiceRating: 0,
            numServiceComments: 0,
            customs: {
                coin: '',
                incoterm: '',
                insurance: false,
                regime: '',
                value: 0,
                descriptionOperation: '',
            },
            transport: {
                origin: {
                    city: '',
                    country: '',
                },
                destination: {
                    city: '',
                    country: '',
                },
                typeContainer: {
                    lcl: false,
                    fcl: false,
                    lclAir: false,
        
                },
                type: undefined,
            },
            observation: ''
        }
    );
    const [userClient, setUserClient] = useState<User>({
        country: {
            alpha2Code: '',
            imgFlag: '',
            name: '',
        },
        email: '',
        phone: undefined,
        userName: '',
        authStep: 1,
        culqiCustomerId: '',
        planId: '',
        subscriptionId: '',
        documentType: '',
    });

    // cargo Shippet
    const [cargoShippet, setCargoShippet] = useState<CargoShippet>({
        arrivalDate: undefined,
        mmsi: 0,
        createdAt: new Date(),
        nameBoat: '',
        operationId: orderDetail ? orderDetail?.operationId: '',
        orderId: orderDetail ? orderDetail.id: '',
        providerId: userDetail ? userDetail.id: '',
        typeBoat: '',
        vTypeBoat: 0,
        status: true,
        updatedAt: new Date(),
    });

    //chat
    const [chatdetail, setchatDetail] = useState<ChatInterface>();
   
    /** metodos */
    const handlePhaseClick = async (orderStep: number) => {
        setShowMenu(false);
        setPhase(orderStep);
        if (window.innerWidth > 699 ) {
            setShowLateral(false);
        } else {
            setShowLateral(true)
        }
        switch (orderStep) {
            case 1: 
                setShowChat(false);
            break;
            case 2:
                setBuilkingAgent(orderDetail.bulkingAgent ? orderDetail.bulkingAgent:'');
                await getConsigneeList(orderDetail?.user.id?orderDetail.user.id:'', orderDetail.consigneeId?orderDetail.consigneeId:'');
                break;
            case 3:
                if(orderDetail.serviceTypeId === "agenciamiento-de-aduana" || orderDetail.serviceTypeId === "courier")   {
                    await getDocumentation(orderDetail.documentationId);
                } else {
                    await getBillingReviewList(orderDetail.billingReviewId);
                }  
                break;
            case 4:
                if(orderDetail?.cargoShippetId && orderDetail.cargoShippetId.length>0) {
                await getCargoShippetData(orderDetail?.cargoShippetId ? orderDetail.cargoShippetId: '');
                }
                break;
            case 5:
                await getInvoiceTranslation(orderDetail?.invoiceTranslationId?orderDetail.invoiceTranslationId:'', requestQuote.customs.incoterm || "", requestQuote.customs.coin || "");
                break;
            case 6:
                await getArrivalControlChannel(orderDetail?.arrivalControlChannelId?orderDetail.arrivalControlChannelId:'');
                break;  
            case 7:
                await getBillingReviewList(orderDetail?.billingReviewId)
                break;

            default:
                break;
        }
        navigation(`/${PROVIDER}/${OPERATIONS}/${params.operationId}/${ORDER}/${params.orderId}/${orderStep}`);
    }

    const handleMessageButtonClick = () => {
        setPhase(0);
        navigation(`/${CUSTOMS}/${params.customsId}/${0}`);
    }

    // const handleHomeClick = () => {
    //     // navigation("/"+CUSTOMS+"/"+RESUME+"/"+1);
    // }

    const handleMessageMobileClick = (id: string) => {
        navigation("/"+CHAT+"/"+DETAIL+"/"+id);
    }

    const handleMessageClick = (chatId: string) => {
        window.innerWidth <= 880 ? handleMessageMobileClick(chatId) : setShowChat(true);
        setPhase(0);
    }

    // const handleGoChatClick  = () => {
    //     setShowMenu(false);
    //     setPhase(0);
    //     // navigation(`/${CUSTOMS}/${params.customsId}/${0}`);
    // }


    // consignee metodths
    // metodo para validar los campos
    const validateConsigneeFields =  () => {
        let error:boolean = false;
        if(consignee.typeDocument === 'Persona jurídica') {
            error = ValidateField(consignee.itNumber, consignee.typeIt === 'RUC' ? 'RUC': (consignee.typeIt === 'DNI'? 'DNI': 'required')) || ValidateField(consignee.companyName, 'required') || ValidateField(consignee.fiscalAddress, 'required') || 
            ValidateField(consignee.email, 'required') || ValidateField(consignee.phone, 'number') || ValidateField(consignee.typeIt, "required");
            // setting error object
            setConsigneeError({
                    typeDocument: {
                        status: ValidateField(consignee.typeDocument, 'required'),
                        message: 'Selecciona entidad',
                    },
                    typeIt: {
                        status: ValidateField(consignee.typeIt, 'required'),
                        message: 'Ingresa de documento',
                    },
                    itNumber: {
                        status: ValidateField(consignee.itNumber, consignee.typeIt === 'RUC' ? 'RUC': (consignee.typeIt === 'DNI'? 'DNI': 'required')),
                        message: 'Ingresa número válido de ' + consignee.typeIt,
                    },
                    companyName: {
                        status: ValidateField(consignee.companyName, 'required'),
                        message: 'Ingresa razon social',
                    },
                    fiscalAddress: {
                        status: ValidateField(consignee.fiscalAddress, 'required'),
                        message: 'Ingresa una dirección',
                    },
                    email: {
                        status: ValidateField(consignee.email, 'required'),
                        message: 'Ingresa un correo electrónico',
                    },
                    phone: {
                        status: ValidateField(consignee.phone, 'number'),
                        message: 'Ingresa teléfono',
                    },
                    name: {
                        status: false,
                        message: 'Ingresa nombre del declarante',
                    },
                    lastName: {
                        status: false,
                        message: 'Ingresa apellido del declarante',
                }
            });
        } else if (consignee.typeDocument === 'Persona natural') {
            error = ValidateField(consignee.name, 'required') || ValidateField(consignee.lastName, 'required') || ValidateField(consignee.typeIt, 'required') || 
            ValidateField(consignee.itNumber, consignee.typeIt === 'RUC' ? 'RUC': (consignee.typeIt === 'DNI'? 'DNI': 'required')) || ValidateField(consignee.email, 'required') || 
            ValidateField(consignee.phone, 'required');
            setConsigneeError({
                typeDocument: {
                    status: ValidateField(consignee.typeDocument, 'required'),
                    message: 'Selecciona entidad',
                },
                typeIt: {
                    status: ValidateField(consignee.typeIt, 'required'),
                    message: 'Ingresa de documento',
                },
                itNumber: {
                    status: ValidateField(consignee.itNumber, consignee.typeIt === 'RUC' ? 'RUC': (consignee.typeIt === 'DNI'? 'DNI': 'required')),
                    message: 'Ingresa número válido de ' + consignee.typeIt,
                },
                companyName: {
                    status: false,
                    message: 'Ingresa razon social',
                },
                fiscalAddress: {
                    status: false,
                    message: 'Ingresa una dirección',
                },
                email: {
                    status: ValidateField(consignee.email, 'required'),
                    message: 'Ingresa un correo electrónico',
                },
                phone: {
                    status: ValidateField(consignee.phone, 'number'),
                    message: 'Ingresa teléfono',
                },
                name: {
                    status: ValidateField(consignee.name, 'required'),
                    message: 'Ingresa nombre del declarante',
                },
                lastName: {
                    status: ValidateField(consignee.lastName, 'required'),
                    message: 'Ingresa apellido del declarante',
                },
            });
        } 

        return error;
    }
   
    // metodo para crear y asignar consignatario
    const handleSaveConsigneeClick =  async() => {
        let error = validateConsigneeFields();
        if(!error) {
            try {
                if(consignee && consignee.id) { // update consignee
                    // console.log("handleSaveConsigneeClick update");
                    setLoading(true);
                    const response:any = await UpdateConsignee({...consignee, userId: orderDetail?.user.id ? orderDetail.user.id : '', updatedAt: new Date()})
                    toast.success("¡Los datos se guardaron con éxito!");
                    setLoading(false);
                    getConsigneeList(orderDetail?.user.id ? orderDetail.user.id : '', consignee.id?consignee.id:'');
                    setIsDisabled(true);
                } else { // create a new consignee
                    setLoading(true);
                    const response:any = await CreateConsignee({...consignee, userId: orderDetail?.user.id ? orderDetail.user.id : ''});
                    toast.success("¡Los datos se guardaron con éxito!");
                    UpdateOrder({
                        id: orderDetail.id?orderDetail.id:'',
                        consigneeId: response.id,
                        updatedAt: new Date(),
                    }).then(() => {
                        setLoading(false);
                        getConsigneeList(orderDetail?.user.id ? orderDetail.user.id : '', response.id);
                        setIsDisabled(true)
                    }).catch((error) => {
                        console.log(error);
                        setLoading(false);
                    })          
                }
            } catch (error) { 
                console.log("🚀 ~ file: CustomsAgency.tsx:638 ~ handleSaveConsigneeClick ~ error:", error)
                setLoading(false);
                toast.error("Algo salio mal, por favor intentato en unos minutos");
            }     
        } else {
            toast.warning('¡Ingresa todo los campos de forma correcta!');
        }
    }

    // Crear Arribo y canal de control
    const handleSaveArrivalControlChannel = async () => {
        let error = false
        if(!error) {
            if(orderDetail.arrivalControlChannelId && orderDetail.arrivalControlChannelId.length>0) {
                // toast.warning('¡Actualizando datos!');
                setLoading(true);
                const response:any = await UpdateArrivalControlChannel({...arrivalControlChannel, userId: orderDetail?.user.id ? orderDetail.user.id : '', updatedAt: new Date()});
                toast.success('¡Los datos se actualizaron correctamente!')
                setLoading(false);
                getArrivalControlChannel(orderDetail.arrivalControlChannelId);  
                setShowEdit(false); 
            } else {
                try {
                    // toast.info("Guardando arribo y canal de control");
                    setLoading(true);
                    const response:any = await CreateArrivalControlChannel({...arrivalControlChannel, userId: orderDetail?.user.id ? orderDetail.user.id : ''})
                    toast.success("¡Los datos se guardaron con éxito!");
                    setShowEdit(false);
                    UpdateOrder({
                        id: orderDetail.id?orderDetail.id: '',
                        arrivalControlChannelId: response.id,
                        updatedAt: new Date(),
                    }).then(()=> {
                        // clearArrivalControlFields();
                        setLoading(false);
                        // getArrivalControlChannel(orderDetail?.user.id ? orderDetail.user.id: '');
                    }).catch((error) =>{
                        //console.log(error);
                        setLoading(false);
                    })
                } catch (error) {
                    setLoading(false);
                    //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 715 ~ handleSaveArrivalControlChannel ~ error", error)
                    toast.error("Algo salio mal, por favor intentato en unos minutos");
                }
            }
        } 
    }

    // metodo para asignar consignatario a orden
    const assignConsignee = async (idx: number) => {
        if(consigneeList.length === idx) {
            setConsignee({
                companyName: '',
                fiscalAddress: '',
                itNumber: undefined,
                lastName: '',
                name: '',
                typeDocument: 'Persona jurídica',
                typeIt: 'RUC',
                userId: '',
                email: '',
                phone: undefined,
                status: true,
                createdAt: new Date(),
                updatedAt: new Date(),
            });
            setIsDisabled(false);
        } else {
            // toast.info("Procesando...");
            setLoading(true);
            let consigneeId = consigneeList[idx].id;
            //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 604 ~ assignConsignee ~ consigneeId", consigneeId)
            setOrderDetail((prev:any) => ({
                ...prev,
                consigneeId: consigneeId
            }));
            setConsignee( consigneeList[idx]);
            try {
                const response = await UpdateOrder({
                    id: orderDetail?.id,
                    consigneeId: consigneeId,
                    updatedAt: new Date(),
                })
                //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 614 ~ assignConsignee ~ response", response);
                toast.success("Se asignó de forma correcta al consignatario2");
                setLoading(false);
                getOrderDetail(orderDetail.id?orderDetail.id:'');
            } catch (error) {
                //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 617 ~ assignConsignee ~ error", error)
                toast.error("hubo un error al asignar al consignatario");
                setLoading(false);
            }
        }
    }
    
    /** Metodo para guardar agente de carga  */
    const handleSaveBulkingAgent = async () => {
        setTimeout(() => {
            setLoading(true);
            UpdateOrder({
                id: orderDetail.id,
                bulkingAgent: bulkingAgent,
                updatedAt: new Date(),
            }).then((response) => {
                toast.success("Los datos se almacenaron con éxito");
                setLoading(false);
                getOrderDetail(orderDetail.id ? orderDetail.id : '');
            })
            .catch((error) => {
                setLoading(false);
                console.log("🚀 ~ file: CustomsAgency.tsx ~ line 895 ~ handleSaveBulkingAgent ~ error", error)
                toast.error("Hubo un problema al guardar los datos");
            })
       }, 300);
    }

    // facturacion y reseña
    // handle billing
    const handleInvoiceChange = async (e: any) => {
        if(e.target.files[0] && operationDetail && orderDetail) {
            // toast("Subiendo Archivos...");
           
            setInvoice(e.target.files[0])
            setLoading(true)
            const responseFile =  await StoreService.UploadFile(
                e.target.files[0], 
                operationDetail.id+`/billingReview/provider/`+e.target.files[0].name,
                // Estoy agregando el id del usuario para que se cree una carpeta con el id del usuario,
                // si no es esa la solucion lo puedes eliminar
                userDetail.id?userDetail.id:''
            );
    
            if(responseFile) {
                let tmpBillingReview: BillingReviewInterface = {
                    ...billingReviewData,
                    companyId: orderDetail?.companyId,
                    orderId: orderDetail.id?orderDetail.id:'',
                    serviceId: orderDetail.serviceId,
                    userId: orderDetail.user.id,
                    billingUrl: [...billingReviewData.billingUrl, {
                        name: e.target.files[0].name,
                        url: responseFile.toString(),
                        size: (e.target.files[0].size)/1024,
                    }],
                    operationId: orderDetail.operationId,
                    status: true,
                    updatedAt: new Date()
                };

                if(orderDetail?.billingReviewId && orderDetail.billingReviewId.length>0) {
                    const response = await UpdateBillingReview(billingReviewData.id ? billingReviewData.id : '', tmpBillingReview);
                    toast.success("Los archivos se actualizaron exitosamente");
                    setBillingReviewData(tmpBillingReview);
                } else {
                    // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 475 ~ handleInvoiceChange ~ tmpBillingReview", tmpBillingReview)
                    setBillingReviewData({...tmpBillingReview, createdAt: new Date()});
                    const responseBillingReview = await CreateBillingReview({...tmpBillingReview, createdAt: new Date()});
                    if(responseBillingReview) {
                        setOrderDetail((prev:any) => ({
                            ...prev,
                            billingReviewId: responseBillingReview.id,
                            updatedAt: new Date(),
                            activePhase: 3,
                        }));
                        const responseOrder = await SetOrder({...orderDetail,  billingReviewId: responseBillingReview.id, activePhase: 3, updatedAt: new Date()});
                        // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 497 ~ handleInvoiceChange ~ responseOrder", responseOrder)
                        toast.success('Los archivos se guardaron exitosamente!');
                    } else {
                        toast.error("hubo un error al almacenar la factura")
                    }
                }
                setLoading(false);
            } 
        } else {
            toast.info("Por favor suba un archivo");
        }

        // setInvoice(e.target.files[0]);
    }

    const handleDeleteBilling =  async(i: number) => {
        // console.log("handleDeleteBilling", i)
        setLoading(true);

        let tmpBillingReview: BillingReviewInterface =  {
            ...billingReviewData,
            billingUrl: billingReviewData.billingUrl.filter((_, idx) => idx !== i),
            updatedAt: new Date(),
        }
        setBillingReviewData(tmpBillingReview);
        const response = await UpdateBillingReview(billingReviewData.id ? billingReviewData.id : '', tmpBillingReview);
        await StoreService.DeleteFile(billingReviewData.billingUrl[i].url, billingReviewData.billingUrl[i].size, userDetail.id || "");
        setLoading(false);
        //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 534 ~ handleDeleteBilling ~ response", response)
        toast.success("¡Se elimino con éxito el archivo!");
    }

    // handle Review section
    //handle rating
    const handleRatingReviewChange = (value: number) => {
        setReview((prev:any) => {
            return {
                ...prev,
                raintg: value
            }
        })
    }

    // handle comment
    const handleValueReviewChange = (value: string) => {
        setReview((prev:any) => {
            return {
                ...prev,
                review: value
            }
        })
    }

    const handleConfirmInvoiceTranslationClick = async (background: boolean = false) => {
        if(invoiceTranslation.id) {
            !background && setLoading(true);
            // toast.info("Actualizando datos..")
            const response = await UpdateInvoiceTranslation(invoiceTranslation);
            !background && (response ? toast.success("Los datos se actualizaron éxitosamente") : toast.error("Hubo un error al actualizar los datos"));

            !background && setLoading(false)
        } else {
            try {
                !background && setLoading(true)
                const response = await CreateInvoiceTranslation(invoiceTranslation);
                //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 1105 ~ handleConfirmInvoiceTranslationClick ~ response", response)
                setInvoiceTranslation((prev) => ({
                    ...prev,
                    id: response?.id,
                }));
                setOrderDetail((prev) => ({
                    ...prev,
                    invoiceTranslationId: response?.id
                }));
                UpdateOrder({...orderDetail, invoiceTranslation: response?.id, updatedAt: new Date()});
                !background && setLoading(false);
                !background && toast.success("Los datos se guardaron con éxito");
            } catch (error) {
                //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 1111 ~ handleConfirmInvoiceTranslationClick ~ error", error)
                toast.error("Hubo un error al almacenar los datos");
                setLoading(false);
            }
        }
    }

    const handleInvoicestatementSave = async (providerIdx: number, q1: boolean, q2: boolean, q3: boolean, q4: boolean, q5: boolean, q6: boolean, q7: boolean, 
        q8: boolean) => {
            let providetTmp = invoiceTranslation.provider.map((p, idx) => idx === providerIdx ? {...p, statement: {
                q1: q1,  q2: q2,  q3: q3,  q4: q4,  q5: q5,  q6: q6,  q7: q7,  q8: q8,
            }} : p )
            
            setInvoiceTranslation((prev) => ({
                ...prev,
                provider: providetTmp,
                updatedAt: new Date()
            }));
            
        if(invoiceTranslation.id) {
            setLoading(true)
           try {
            const response = await UpdateInvoiceTranslation({...invoiceTranslation, provider: providetTmp, updatedAt: new Date()});
            //console.log("🚀 ~ file: CustomsAgency.tsx:1390 ~ response", response)
            setLoading(false);
            toast.success("¡Los datos se guardaron correctamente!")
           } catch (error) {
                setLoading(false);
                //console.log("🚀 ~ file: CustomsAgency.tsx:1391 ~ error", error)
                toast.error("Hubo un error al guardar la declaración");
           }
        } else {
            toast.warning("Guarda la factura para continuar...");
        }
    }
  
    /** Subiendo archivos de aviso de llegada */
    const handleNoticeArrivalChange = async (d:any) => {
        if(d .target.files[0] && operationDetail && orderDetail) {
            toast("Subiendo Archivos...");
            try {
                setLoading(true)
                const responseFile = await StoreService.UploadFile(
                    d.target.files[0], 
                    operationDetail.id+`/noticeArrival/`+d.target.files[0].name,
                    // Estoy agregando el id del usuario para que se cree una carpeta con el id del usuario,
                    // si no es esa la solucion lo puedes eliminar
                    userDetail.id?userDetail.id:''
                );
                // console.log("🚀 ~ file: ProcessPage.tsx ~ line 577 ~ handleDocumentationChange ~ responseFile", responseFile)
                
                if(responseFile) {
                    let tmpArrivalControlChannel: ArrivalControlChannel = {
                       ...arrivalControlChannel,
                       noticeArrival: [
                        ...arrivalControlChannel.noticeArrival, {
                            name: d.target.files[0].name,
                            url: responseFile.toString(),
                            userId: orderDetail.user.id,
                            size: (d.target.files[0].size)/1024,
                        }
                       ]
                    }

                    setArrivalControlChannel(tmpArrivalControlChannel);
                    
                    if(orderDetail?.arrivalControlChannelId && orderDetail.arrivalControlChannelId.length>0) {
                        await UpdateArrivalControlChannel(tmpArrivalControlChannel);
                    } else {
                        await handleSaveArrivalControlChannel();
                    }
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                //console.log("🚀 ~ file: FilesFLC.tsx ~ line 120 ~ handleComercialInvoiceChange ~ error", error)
                toast.error("Hubo un error al subir el archivo a Facturacion comercial");
            }
        } else {
            toast.info("Por favor suba un archivo");
        }
    }

    /** Eliminando archivos de factura comercial */
    const handleDeleteNoticeArrival = async (dd: number) => {
        try {
            // setLoading(true);
            let tmpArrivalControlChannel: ArrivalControlChannel = {
                ...arrivalControlChannel,
                noticeArrival: [...arrivalControlChannel.noticeArrival.filter((_, idx) => idx !== dd)],
                updatedAt: new Date(),
            }
            setArrivalControlChannel(tmpArrivalControlChannel);
            const response = await UpdateArrivalControlChannel(tmpArrivalControlChannel);
            await StoreService.DeleteFile(arrivalControlChannel.noticeArrival[dd].url, arrivalControlChannel.noticeArrival[dd].size, userDetail.id || "");
            setLoading(false);
            // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 534 ~ handleDeleteDocument ~ response", response)
            toast.success("¡Se elimino con éxito el archivo!");
        } catch (error) {
            //console.log("🚀 ~ file: FilesFLC.tsx ~ line 388 ~ handleDeleteComercialInvoice ~ error", error)
            setLoading(false);
            toast.error("Hubo un error al eliminar la Factura comercial");
        }
    }
    const createReviewUser = async (rating: number, comment: string, billingReviewId: string) => {
        if(orderDetail) {      
            try {
                const responseCompany:any = await GetCompanyById(orderDetail.companyId);
                if(responseCompany) {
                    let newreviewUser: reviewUserInterface = {
                        userId: orderDetail.user.id,
                        companyId: orderDetail.companyId,
                        userProviderId: userDetail.id,
                        serviceId: orderDetail.serviceId,
                        serviceTypeId: orderDetail.serviceTypeId,
                        serviceTypeLabel: orderDetail.serviceTypeLabel,
                        orderId: orderDetail.id?orderDetail.id:'',
                        rating: rating,
                        comment: comment,
                        status: true,
                        operationId: orderDetail.operationId,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        companyName: orderDetail.companyName,
                        companyLogo: responseCompany.photoProfile,
                    }
                try {
                        const responseReviewUser = await CreateReviewUser(newreviewUser);
                        console.log("🚀 ~ file: ProcessPage.tsx ~ line 617 ~ handleEndServiceClick ~ responseReviewUser", responseReviewUser);
                        if(responseReviewUser) {
                        } else {
                            toast.error("hubo un error al crear el review 😕");
                        }
                        setLoading(false);
                } catch (error) {
                        console.log(error);
                        setLoading(false);
                }
                    setOrderDetail((prev:any) => ({
                        ...prev,
                        billingReviewId: billingReviewId,
                        finishedProvider: true,
                        updatedAt: new Date(),
                        activePhase: 3,
                    }));
                }
            } catch (error) {
                
            }
        }
    }

    // handle finalizar servicio
    const handleEndServiceClick = async () => {
        setLoading(true);
        if(review.raintg>0 && review.review.length>0 && orderDetail) {
            let tmpBillingReview: BillingReviewInterface = {
                ...billingReviewData,
                companyId: orderDetail?.companyId,
                orderId: orderDetail.id?orderDetail.id:'',
                serviceId: orderDetail.serviceId,
                userId: orderDetail.user.id,
                operationId: orderDetail.operationId,
                reviewClient:  {
                    rating: review.raintg,
                    comment: review.review,
                    userId: userDetail.id,
                },
                status: true,
                updatedAt: new Date(),
            };
            if(orderDetail?.billingReviewId && orderDetail.billingReviewId.length>0) {
                try {
                    setBillingReviewData({...tmpBillingReview, createdAt: new Date()});
                    // console.log("actualizando!");
                    const response = await UpdateBillingReview(orderDetail?.billingReviewId ? orderDetail?.billingReviewId : '', tmpBillingReview);
                    // console.log("🚀 ~ file: ProcessPage.tsx:985 ~ handleEndServiceClick ~ response:", response)
                    const responseOrder = await SetOrder({...orderDetail, activePhase: 3, finishedProvider: true, updatedAt: new Date()});
                    setOrderDetail((prev)=> ({
                        ...prev,
                        activePhase: 3,
                        finishedProvider: true,
                        updatedAt: new Date()
                    }));
                    // console.log("🚀 ~ file: ProcessPage.tsx ~ line 617 ~ handleEndServiceClick ~ responseOrder", responseOrder);
                    const findReiewUser = await GetAllReviewOrderId(orderDetail.id?orderDetail.id:'');
                    if(findReiewUser.length===0) {
                        await createReviewUser(tmpBillingReview.reviewClient.rating, tmpBillingReview.reviewClient.comment, orderDetail.billingReviewId);
                        toast.success("La reseña se actualizó exitosamente🔥");
                    }
                    setLoading(false);
                } catch (error) {
                    //console.log("🚀 ~ file: ProcessPage.tsx ~ line 621 ~ handleEndServiceClick ~ error", error)
                    toast.error("Hubo un error al actualizar la reseña");
                }
            } else  {
                // console.log("creando!");
                try {
                    const responseBillingReview = await CreateBillingReview({...tmpBillingReview, createdAt: new Date()});
                    //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 621 ~ handleInvoiceChange ~ responseBillingReview", responseBillingReview);
                    if(responseBillingReview) {
                        setBillingReviewData((prev) => ({
                            ...tmpBillingReview,
                            id: responseBillingReview.id
                        }));
                        const responseOrder = await SetOrder({...orderDetail,  billingReviewId: responseBillingReview.id, activePhase: 3, finishedProvider: true, updatedAt: new Date()});
                        setOrderDetail((prev)=> ({
                            ...prev,
                            billingReviewId: responseBillingReview.id, 
                            activePhase: 3, 
                            finishedProvider: true, 
                            updatedAt: new Date()
                        }));
                        //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 625 ~ handleInvoiceChange ~ responseOrder", responseOrder);
                        await createReviewUser(tmpBillingReview.reviewClient.rating, tmpBillingReview.reviewClient.comment, responseBillingReview.id);
                        setLoading(false);
                        toast.success('la reseña se guardo exitosamente!');
                    } else {
                        setLoading(false);
                        toast.error("hubo un error al guardar la reseña")
                    }    
                } catch (error) {
                    setLoading(false);
                    // console.log(error);
                    toast.error("hubo un error al guardar la reseña")
                }
            }
        }  else {
            toast.warning("Selecciona un puntaje e ingresa una reseña para finalizar el servicio");
            setLoading(false);
        }
  
    }

    // handle create cargoShippet
    const handleSaveCargoShippet = () => {
        // toast.info("¡Guardando información! 🚢");
        setTimeout(async () => {
            if(!loading && orderDetail && !orderDetail.cargoShippetId && !cargoShippet.id) {
        
                try {
                    setLoading(true);
                    const response:any = await CreateCargoShippet({...cargoShippet, operationId: orderDetail.operationId, orderId: orderDetail.id, providerId: userDetail.id});
                    // console.log("🚀 ~ file: ProcessPage.tsx ~ line 664 ~ setTimeout ~ response", response);
                    const responseOrder = await SetOrder({...orderDetail, activePhase: 2, cargoShippetId: response.id, updatedAt: new Date()});
                    setOrderDetail((prev:any) => ({
                        ...prev,
                        cargoShippetId: response.id,
                        activePhase: 2,
                        updatedAt: new Date(),
                    }))
                    setCargoShippet((prev) => ({
                        ...prev,
                        id: response.id,
                        // operationId: orderDetail.operationId,
                        // orderId: orderDetail.id,
                        // providerId: userDetail.id
                    }))
                    toast.success("¡Los datos fueron guardados exitosamente! 🚢");
                    setLoading(false);
                } catch (error) {
                    setLoading(false)
                    //console.log("🚀 ~ file: TrackingShipping.tsx ~ line 147 ~ HandleClickComplete ~ error", error)
                    toast.error("Hubo un error al guardar los datos 🙃");
                }
            } else {
                // console.log("actualizando")
                try {
                    const response = await UpdateCargoShippet(cargoShippet.id?cargoShippet.id: '', {...cargoShippet, updatedAt: new Date(9)});
                    //console.log("🚀 ~ file: ProcessPage.tsx ~ line 688 ~ setTimeout ~ response", response);
                    toast.success("¡Los datos fueron actualizado exitosamente!");
                    getCargoShippetData(cargoShippet.id?cargoShippet.id:'');
                } catch (error) {
                    //console.log("🚀 ~ file: ProcessPage.tsx ~ line 691 ~ setTimeout ~ error", error)
                    toast.error("Hubo un error al actualizar los datos");
                }
            }
        }, 2000);
    }

    // handle seach text change
    const handleSeachChange = useCallback(
        debounce((text)=> {
          setSearchText(text);
        }, 500), [searchText]);

    //obteniendo detalle de la order por requestQuote
    const getRequestQuote =async (id: string) => {
        // console.log("🚀 ~ getRequestQuote ~ id:", id)
        setLoading(false);
        if(!loading) {
            try {
                setLoading(true);
                const response:any = await  GetRequestQuoteById(id);
                // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 929 ~ getRequestQuote ~ response", response)
                if(response) {
                    setRequestQuote(response);
                    setLoading(false);
                    getChat(response.chatId);
                }
                return response;
            } catch (error) {
                //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 934 ~ getRequestQuote ~ error", error)
                toast.error("Hubo un error al obtener los datos de la cotización");
            }
        }
    }

    const getUser =async (userId:string) => {
        setLoading(false);
        if(!loading) {
            try {
                setLoading(true);
                const response:any = await GetUserById(userId);
                if(response) {
                    setUserClient(response);
                }
                setLoading(false);
            } catch (error) {
                //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 934 ~ getRequestQuote ~ error", error)
                toast.error("Hubo un error al obtener los datos del usuario");
            }
        }
    }

    //Obteniendo detalle de la order por cotizacion
    const getQuotationDetail = async (id: string) => {
        if(!loading) {
            setLoading(true);
            dispatch(GetQuotationById(id))
            .unwrap()
            .then((response) => {
                setQuotationDetail(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log('error', error);
                setLoading(false)
            })
        }
    }

    const getCargoShippetData = async (cargoShippetId: string) => { 
        if(!loading) {
            setLoading(true)
             try {
                const response:any = await GetCargoShippetId(cargoShippetId);
                if(response) {
                    setCargoShippet({
                        id: response.id,
                        arrivalDate: response.arrivalDate,
                        mmsi: response.mmsi,
                        nameBoat: response.nameBoat,
                        typeBoat: response.typeBoat,
                        vTypeBoat: response.vTypeBoat,
                        operationId: response.operationId,
                        orderId: response.orderId,
                        providerId: response.providerId,
                        createdAt: response.createdAt,
                        updatedAt: response.updatedAt
                    });
                }
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: ProcessPage.tsx ~ line 721 ~ getCargoShippetData ~ error", error)
                setLoading(false);
            }
        }
    }

    //obteniendo ordenes de firebase
    const getOrderDetail = async (id:string) => {
        // console.log("🚀 ~ getOrderDetail ~ id:", id)
        if(!loading) {
            setLoading(true);
            dispatch(GetOrderById(id))
            .unwrap()
            .then(async (response) => { 
                if(response.usersProvider.includes(userDetail.id) ) {
                setLoading(false);
                let rq : RequestQuoteInterface | undefined;
                if(response.serviceTypeId === "agenciamiento-de-aduana" || response.serviceTypeId === "courier") {
                    rq = await getRequestQuote(response.requestQuoteId);
                    getQuotationDetail(response.quotationId)
                }
               
                await getUser(response.userId);
                setOrderDetail(response);
               
                if(!chatdetail) {
                    getChat(response.chatId || "");
                }

                if(params.numPhase){
                    switch (parseInt(params.numPhase)) {
                        case 2:
                            setBuilkingAgent(response.bulkingAgent ? response.bulkingAgent : '');
                            await getConsigneeList(response.userId, response.consigneeId ? response.consigneeId:'');
                            break;
                        case 3:
                            if(response.serviceTypeId === "agenciamiento-de-aduana" || response.serviceTypeId === "courier")   {
                                await getDocumentation(response.documentationId);
                            } else {
                                await getBillingReviewList(response.billingReviewId);
                            }  
                            break;
                        case 4:
                            if(response.cargoShippetId && response.cargoShippetId.length>0) {
                                await getCargoShippetData(response.cargoShippetId);
                            } else {
                                setCargoShippet((prev) => ({
                                    ...prev,
                                    operationId: response.operationId,
                                    orderId: response.id
                                }))
                            }
                            break
                        case 5:
                            await getInvoiceTranslation(response.invoiceTranslation, rq?.customs.incoterm || "", rq?.customs.coin || "");
                            break;
                        case 6:
                            await getArrivalControlChannel(response.arrivalControlChannelId);
                            break;    
                        case 7:
                            await getBillingReviewList(response.billingReviewId);
                            break;

                        default:
                            break;
                    }
               }
                setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("No tienes permisos para ver esta operación");
                    navigation("/"+OPERATIONS);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            }) 
        }
    }

    //Listando cotizaciones de la operacion
    const getOperationDetail = async (operationId: string) => {
        if(!loading) {
            setLoading(true);
            dispatch(GetOperationDetail(operationId))
            .unwrap()
            .then((response) => {
                    // console.log(response);
                    setOperationDetail(response);
                    setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
        }
    }

    //obteniendo ordenes de redux
    const getOperationLocal = (operationId: string) => {
        setOperationDetail(operationList.find((o:OperationInterface) => (o.id === operationId)))
    }
    
    const getBillingReviewList = async (billingReviewId: string | undefined) => {
        if(billingReviewId && billingReviewId.length>0) {
            setLoading(true);
            const response:any = await GetBillingReview(billingReviewId);
            // console.log("🚀 ~ file: ProcessPage.tsx:1237 ~ getBillingReviewList ~ response:", response)
            if(response) {
                setBillingReviewData(response);
                setReview({
                    raintg: response.reviewClient.rating,
                    review: response.reviewClient.comment
                })
            }
            setLoading(false);
        }
    } 

    const getDocumentation = async (documentationId:string | undefined) => {
        if(documentationId && documentationId.length>0) {
            setLoading(true);
            try {
                const response:any = await getDocumentationById(documentationId);
            if(response) {
                setDocumentationData(response);
            }
            setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: ProcessPage.tsx ~ line 883 ~ getDocumentation ~ error", error)
                setLoading(false)
            }
        }
    }

    //Obteniendo los consignatarios
    const getConsigneeList = async (userId: string, consigneeId: string) => {
        if(userId.length>0) {
            setLoading(true);
            try {
                const response = await GetAllConsigneeByUserId(userId);
                setIsDisabled(response.length > 0);
                setConsigneeList(response);
                if(response.length>0) {
                    let r = response.find(c => c.id === consigneeId);
                    if(r) {
                        setConsigneeSelected(r.typeDocument === 'Persona jurídica' ? r.companyName : r.name+' '+r.lastName);
                        setConsignee(r);
                    } else {
                        setConsigneeSelected('Selecciona consignatario');
                    }
                } else {
                    setConsigneeSelected('Aún no tienes consignatarios guardados');
                }
                setLoading(false);
            } catch (error) {
                //console.log("🚀 ~ file: ProcessPage.tsx ~ line 874 ~ getConsigneeList ~ error", error)
                setLoading(false);
                toast.error('hubo un error al obtener la lista de consignatatios :c')
            }
        }
    }

    //Obteniendo las facturas
    const getInvoiceTranslation = async (id: string, incoterm: string, coin: string) => {
       // existe id 
        if(id && id.length>0) {
            setLoading(true)
            try {
                const response:any = await GetInvoiceTranslationById(id);
                if(response) {
                    if(response.provider.length===0) {
                        setInvoiceTranslation({
                            ...response,
                            provider: [{
                                city: '',
                                country: {
                                    name: ''
                                },
                                countryAcquisition: {
                                    name: ''
                                },
                                currency: '',
                                financialEntity: '',
                                incoterm: '',
                                invoiceNumber: '',
                                name: '',
                                paymentType: '',
                                phone: '',
                                placeDelivery: '',
                                // product: products,
                                providerAddress: '',
                                providerType: ''
                            }]
                        });
                    } else {
                        setInvoiceTranslation(response);
                    }
                }
                setLoading(false)
            } catch (error) {
                //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 925 ~ getInvoiceTranslationList ~ error", error)
                setLoading(false);
                toast.error('hubo un error al obtener la lista de factura traducida :c')
            }
        } else { // no existe id
            setLoading(true);
            let products: ProductDeclareInterface[] = [];
            await getConsigneeList(orderDetail.user.id, orderDetail.consigneeId ? orderDetail.consigneeId: '');
            const consigneeSelect = consigneeList.find(c => c.id === orderDetail.consigneeId);
            
            setInvoiceTranslation((prev:any) => ({
               ...prev,
               importer: {
                ...consigneeSelect ? {
                    commercialLevel: '',
                    itNumber: consigneeSelect.itNumber ? consigneeSelect.itNumber: undefined,
                    lastName: consigneeSelect.lastName,
                    name: consigneeSelect.name,
                    nameCompany: consigneeSelect.companyName,
                    typeDocument: consigneeSelect.typeDocument,
                    typeIt: consigneeSelect.typeIt
                } : prev.importer
               },
                provider: [{
                    name: '',
                    invoiceNumber: '',
                    country: {
                        name: '',
                        alpha2Code: '',
                        imgFlag: '',
                    },
                    city: '',
                    providerType: '',
                    providerAddress: '',
                    phone: '',
                    incoterm: incoterm,
                    currency: coin,
                    countryAcquisition: {
                        name: '',
                        alpha2Code: '',
                        imgFlag: '',
                    },
                    paymentType: '',
                    financialEntity: '',
                    placeDelivery: '',
                    product: products,
                }]
            }));
            setLoading(false);
        }
    }

    //Obteniendo Arribo y canal de control 
    const getArrivalControlChannel = async (id: string) => {
        if(id && id.length>0) {
            setLoading(true)
            try {
                const response:any = await GetArrivalControlChannel(id);
                // console.log("🚀 ~ file: ProcessPage.tsx ~ line 1104 ~ getArrivalControlChannel ~ response", response)
                setArrivalControlChannel(response);
                setLoading(false)
            } catch (error) {
                //console.log("🚀 ~ file: ProcessPage.tsx ~ line 1108 ~ getArrivalControlChannel ~ error", error)
                setLoading(false);
                toast.error('hubo un error al obtener los datos de arribo y control')
            }
        }
    }

      // method to send whatsapp notification to provider
    const sendProviderWspNotification = async () => {
        try {
            // find all user to send whatsapp notification
            const users = chatdetail ? chatdetail.members.filter((m) => (m.userId !== userDetail.id && !m.isCompany)) : [];
            // iterate users to send whatsapp notification
            users.length> 0 && users.forEach(async (user) => {
                // get user detail by id
                const respUser: any = await GetUserById(user.userId);
                // send whatsapp notification
                sendUserMessageNotification(respUser.phoneCode+""+respUser.phone, respUser.name + " " + respUser.lastName, chatdetail?.companyName ? chatdetail.companyName : "")
                .then((wsp) => {
                    // console.log("🚀 ~ file: ChatPage.tsx:490 ~ .then ~ wsp:", wsp);
                }).catch((errorWsp) => {
                    console.log("🚀 ~ file: ChatPage.tsx:492 ~ .then ~ error:", errorWsp);
                });      
            });
            
        } catch (error) {
            console.log("🚀 ~ file: ChatPage.tsx:488 ~ sendProviderWspNotification ~ error:", error);
        }
    }

    // method to send whatsapp notification to user
    const sendUserWspNotification = async () => {
        try {
            // find all user to send whatsapp notification
            const users = chatdetail ? chatdetail.members.filter((m) => (m.userId !== userDetail.id && m.isCompany)) : [];
            // iterate users to send whatsapp notification
            users.length> 0 && users.forEach(async (user) => {
                // get user detail by id
                const respUser: any = await GetUserById(user.userId);
                sendProviderMessageNotification(respUser.phoneCode+""+respUser.phone, userDetail.name + " " + userDetail.lastName, chatdetail?.companyName ? chatdetail.companyName : '')
                .then((wsp) => {
                    // console.log("🚀 ~ file: ChatPage.tsx:505 ~ .then ~ wsp:", wsp);
                }).catch((errorWsp) => {
                    console.log("🚀 ~ file: ChatPage.tsx: 507 ~ .then ~ error:", errorWsp);
                });
            });
        } catch (error) {
            console.log("🚀 ~ file: ChatPage.tsx:510 ~ sendUserWspNotification ~ error:", error)
        }
    }

    // method to send whatsapp notification
    const sendWhatsappNotification =() => {
        try {
            // console.log("enviar a whatsapp")
            userDetail.userType.includes('user') ? sendUserWspNotification() : sendProviderWspNotification();
        } catch (error) {
            console.log("🚀 ~ file: ChatPage.tsx:491 ~ debounce ~ error:", error);
        }
    }
    // method to get chat by id
    const getChat = (chatId: string) => {
        GetChatById(chatId, (value)=>{
        setchatDetail(value); 
        });
    }

    const handleClickCancelSix = () => {
        setShowEdit(false)
    }

    const handleEditSix = () => {
        setShowEdit(true)
    };

    const handleClickBefore = () => {
        if(phase > 0 && window.innerWidth <= 700) {
            setPhase(0);
            setShowLateral(false);
        } else {
            navigation('/'+OPERATIONS);
        }
    }

    useEffect(() => {
        if(params && params.operationId) {
            if(operationList && operationList.length>0) {
                getOperationLocal(params.operationId);

            } else {
                getOperationDetail(params.operationId);
            }
        }
    }, [params.operationId]);

    useEffect(() => {
        if(params && params.orderId) {
            getOrderDetail(params.orderId);
        }
        
    }, [params.orderId])

    useEffect(()=> {
        if(params.numPhase && (parseInt(params.numPhase) >= 0 && parseInt(params.numPhase) <=8)) {
            setPhase(parseInt(params.numPhase));
            // (window.innerWidth<=880) ? setShowMenu(false): setShowMenu(true);
        } else {
            setPhase(0);
        }
    }, [params.orderStep]);

   return (
    <div className="processPage-container">
        <OperationHeader
          operationDetail={operationDetail}
            teams={operationDetail ? operationDetail.orders.map(or => 
                    ({operationId: operationDetail.id ? operationDetail.id: '', 
                        orderId: or.id, teamName: or.companyName})): 
                        []
                    }
            onClickHome={()=>{}}
            showDots={operationDetail?.requestQuotes.find((rq) => rq.quoteGenerated) ? true : false}
            onClickBefore={handleClickBefore}
        />
        <div className="processPage-content"> 
            <div className="processPage-content-row">
                {!showLateral && <div className="processPage-left">
                    <div className="processPage-left-header">
                        <div className="processPage-title paragraph-header">Órdenes</div>
                        <ButtonBadget
                            name={
                                <MessageBlack/>
                            }
                            handleBadget={()=>handleMessageClick(chatdetail?.id?chatdetail.id:'')}
                            badget={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
                        />
                    </div>
                    <ImportPhasesCard
                        type="Orden" 
                        serviceTypeLabel={orderDetail?.serviceTypeLabel ? orderDetail.serviceTypeLabel : 'Importación'}
                        typeOperation={orderDetail ? orderDetail.type : ''}
                        companyName={orderDetail? orderDetail.companyName : ''}
                        rating={requestQuote.numServiceRating ? requestQuote.numServiceRating : 0}
                        order={{
                            num: Number(orderDetail.number),
                            numComments: 0,
                            date: FormatDate(orderDetail.createdAt ? new Date(orderDetail.createdAt.toDate()) : new Date())
                        }}
                        country={{
                            alpha2Code: orderDetail.country?.alpha2Code ? orderDetail.country.alpha2Code : 'PE',
                            city: 'Lima',
                            imgFlag: orderDetail.country?.imgFlag ? orderDetail.country.imgFlag : '',
                        }}
                        activePhase={phase}
                        online={true}
                        black={true}        
                        // order={orderMin}
                        phases={
                            orderDetail.serviceTypeId != 'busqueda-de-proveedor' ? 
                            phaseTypeService.agenciamiento : 
                            phaseTypeService.busquedaProveedor
                        }
                        currency={quotationDetail?.currency || "USD"}
                        price={orderDetail ? orderDetail.price : 0.00}
                        showFlags={false}
                        handlePhaseClick={handlePhaseClick}
                        resume={false}
                        handleClick={()=>{}}
                        unlockedPhases={orderDetail?.unlockedPhases}/>
                    {chatdetail?.attachments && chatdetail?.attachments.length>0 && 
                        <div className="processPage-left-multimedia">
                            <Multimedia 
                                multimedia={chatdetail?.attachments}
                                title=""
                                number={5}
                            />
                        </div>
                    }
                </div>}
                { (window.innerWidth > 699 ? !showLateral : showLateral) && 
                <div className="processPage-right">
                    {phase !== 0 && 
                    <div className="processPage-chat-content">
                        <HeaderCustomsPhases
                            numPhase={phase}
                            title={
                                orderDetail.serviceTypeId != 'busqueda-de-proveedor' ? 
                                phaseTypeService.agenciamiento[phase-1] : 
                                phaseTypeService.busquedaProveedor[phase-1]}
                            handleMessageclick={handleMessageButtonClick}
                        />
                        <ButtonBadget
                            name="Ver mensajes"
                            handleBadget={()=>handleMessageClick(chatdetail?.id?chatdetail.id:'')}
                            badget={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
                        />
                    </div>}
                    {showchat &&
                        phase === 0 ? // seccion chat
                        <div className={showchat ? "processPage-chat--content" : "processPage-chat--content-chat"} style={{display: mobileMode ? (showMenu ? 'none' : '') : ''}}>  {/** chat seccion */}
                            <MessageTopbar
                                searchText=""
                                setSearchText={(e)=> handleSeachChange(e)}
                                team={chatdetail && chatdetail.members ? chatdetail.members.filter(m => m.userId !== userDetail.id && m.isCompany) : []}
                                typeChat={window.innerWidth <=880 ? "Órdenes":''}
                                onClickType={() => {}}
                                canAddMembers
                            />
                            { chatdetail && 
                                <Chat 
                                    chatId={chatdetail?.id ? chatdetail.id : "" } 
                                    userId={userDetail?.id?userDetail.id:""}
                                    userName={userDetail?.name + " " + userDetail?.lastName} 
                                    searchText={searchText}
                                    sendWhatsappNotification={sendWhatsappNotification}
                                    paramHeight={window.innerHeight}
                                />
                            }
                        </div> :
                        <div ref={ref} className="processPage-right-phases">
                            {phase === 1 && 
                            // !showchat && 
                            orderDetail.serviceTypeId != 'busqueda-de-proveedor' &&
                            <ImportTimelineSupplier stage={orderStatus} setStage={setOrderStatus} />}   
                            
                            { orderStatus === 1 && phase === 1 && 
                            <div className="processPage-right-phases-one">  
                                <div className="small-bold processPage-colorText">Resumen</div>
                                <div className="processPage-right-phases-one-content box-shadow-card">
                                    <QuoteTransportData
                                        transport={requestQuote.transport}
                         
                                        isCourier={requestQuote.serviceTypeId === 'courier'}
                                    /> 
                                    <QuoteCustomsData
                                        requestQuoteId={requestQuote.id || ""}
                                        customs={requestQuote.customs} 
                                        isCourier={requestQuote.serviceTypeId === "courier"}
                                        updateData={()=>getOrderDetail("1")}
                                        canUpdate={false}
                                    /> 
                                </div>
                            </div>}
                            { orderDetail && operationDetail && phase === 1 &&
                             orderDetail.serviceTypeId === 'busqueda-de-proveedor' &&
                                <SupplierSearchRequirements
                                    requirementsId={orderDetail.requirementsId || ''}
                                    orderId={orderDetail.id || ''}
                                    setOrderDetail={setOrderDetail}
                                />
                            }

                            {/**Seccion detalle de la cotización*/}
                            { !loading ?
                                <>
                                    {  orderStatus !== 1 && phase === 1 && 
                                       orderDetail.serviceTypeId != 'busqueda-de-proveedor' && quotationDetail &&// seccion de cotizacion
                                        <Quote
                                            edit= {true}
                                            setEdit={()=>{}}
                                            rating={quotationDetail.numServiceRating?quotationDetail.numServiceRating:0}
                                            countryOrigin={quotationDetail.countryOrigin}
                                            transportInternational={quotationDetail.importCost.serviceOrigin.internationalTransport.toString()}
                                            safe={quotationDetail.importCost.serviceOrigin.insurance.toString()}
                                            expensesOrigin={quotationDetail.importCost.serviceOrigin.expensiveOrigin.toString()}
                                            subTotal1={quotationDetail.importCost.serviceOrigin.subTotal.toString()}
                                            subTotal2={quotationDetail.importCost.serviceDestination.subTotal.toString()}
                                            destinationCountry={quotationDetail.destinationCountry}
                                            storage={quotationDetail.importCost.serviceDestination.storage.toString()}
                                            // warehouseExpenses={quotationDetail.importCost.serviceDestination.warehouseExpenses.toString()}
                                            storageExpenses={quotationDetail.importCost.serviceDestination.storageExpenses.toString()}
                                            onCosts={quotationDetail.importCost.serviceDestination.onCosts.toString()}
                                            operatingExpenses={quotationDetail.importCost.serviceDestination.operationExpensive.toString()}
                                            administrativeExpensive={quotationDetail.importCost.serviceDestination.administrativeExpensive ? quotationDetail.importCost.serviceDestination.administrativeExpensive.toString() : '0.00'}
                                            desadunaje={'quotationDetail.importCost.serviceDestination.desadunaje.toString()'}
                                            approval={quotationDetail.importCost.serviceDestination.approval ? quotationDetail.importCost.serviceDestination.approval.toString() : '0.00'}
                                            localTransport={quotationDetail.importCost.serviceDestination.localTransport.toString()}
                                            handling={quotationDetail.importCost.serviceDestination.handling ? quotationDetail.importCost.serviceDestination.handling.toString(): '0.00'}
                                            deconsolidation={quotationDetail.importCost.serviceDestination.deconsolidation.toString()}
                                            returnContain={quotationDetail.importCost.serviceDestination.returnContain.toString()}
                                            commissionCustoms={quotationDetail.importCost.serviceDestination.commissionCustoms.toString()}
                                            otherExpenses={quotationDetail.importCost.serviceDestination.otherExpenses ? quotationDetail.importCost.serviceDestination.otherExpenses.toString(): ''}
                                            tax={quotationDetail.importCost.serviceDestination.tax.toString()}
                                            total={quotationDetail.price.toString()}
                                            toValue={quotationDetail.taxes.adValorem.toString()}
                                            igv={quotationDetail.taxes.igv.toString()}
                                            ipm={quotationDetail.taxes.ipm.toString()}
                                            perception={quotationDetail.taxes.perception.toString()}
                                            isc={quotationDetail.taxes.isc.toString()}
                                            specificRight={quotationDetail.taxes.specificRight.toString()}
                                            antidumpi={quotationDetail.taxes.antidumpi.toString()}
                                            surcharge={quotationDetail.taxes.surcharge.toString()}
                                            totalTaxes={quotationDetail.taxes.subTotal.toString()}
                                            transitTime={quotationDetail.detail.transitTime}
                                            storageBase={quotationDetail.detail.storageBase}
                                            expiration={quotationDetail.detail.expiration}
                                            restrictions={quotationDetail.restriction}
                                            typeContainer={quotationDetail.typeContainer}
                                            quotationObservation={quotationDetail.observation}
                                            setQuotationObservation={()=>{}}
                                            isInsurance={requestQuote.customs?.insurance}
                                            isFcl={requestQuote.transport?.typeContainer?.fcl ? true: false}
                                            aboutStay={quotationDetail.detail.aboutStay}
                                            isCourier={requestQuote.serviceTypeId === 'courier'}
                                            currency={quotationDetail.currency || "USD"}
                                        />
                                    }
                                    {
                                        consigneeSelected && phase  === 2 &&  // seccion de consignatario
                                        (orderDetail.serviceTypeId != 'busqueda-de-proveedor' ? 
                                        <>
                                            <ImportationSubstructureConsignee
                                                consigneeSelected={consigneeSelected}
                                                consigneeElements={[...consigneeList.map(c=> c.typeDocument === 'Persona jurídica' ? c.companyName : c.name+' '+c.lastName), "Agregar consignatario"]}
                                                consignee={consignee}
                                                setConsignee={setConsignee}
                                                personOrCompanyElements={TypePersons}
                                                documentTypeElements={TypeDocuments} 
                                                handleSaveConsignee={handleSaveConsigneeClick}
                                                errors={consigneeError}
                                                handleConsigneeSelected={assignConsignee}
                
                                                disabled={isDisabled}
                                                setDisabled={setIsDisabled}
                                            />
                                            <ImportationSubstructureCargoagentandhscodeInput
                                                textAreaRows={1}
                                                HandleClickSave={handleSaveBulkingAgent}
                                                bulkingAgentValue={bulkingAgent}
                                                handleClickCancel={()=>setBuilkingAgent(orderDetail.bulkingAgent ? orderDetail.bulkingAgent : '')}
                                                supplier
                                                setBulkingAgentValue={setBuilkingAgent}
                                            />
                                        </> : 
                                        <div className="processPage-provider">
                                            <DeliveryOrder
                                                deliveryOrderId={orderDetail.deliveryOrderId || ''}
                                                servicePlanId={orderDetail.planId || ""}
                                                orderId={orderDetail.id || ''}
                                                setOrderDetail={setOrderDetail}
                                            />
                                        </div>
                                        )
                                    }

                                    {orderDetail && operationDetail && phase === 3 &&   // seccion documentacion
                                        <>
                                            {orderDetail.serviceTypeId != 'busqueda-de-proveedor' ? 
                                            <FilesFLC 
                                                documentation={documentationData}
                                                setDocumentation={setDocumentationData}
                                                operationDetail={operationDetail}
                                                orderDetail={orderDetail}
                                                setOrderDetail={setOrderDetail}
                                                hiddenTitle={true}
                                                supplier
                                                multimedia
                                            /> :
                                            <div className="processPage-provider">
                                                <UploadFileArray
                                                    title='Facturación'
                                                    subtitle='El proveedor colocará aquí tu comprobante de compra.'
                                                    buttonContent="Subir archivo"
                                                    fileValue={invoice}
                                                    file={billingReviewData.billingUrl}
                                                    handleFileValue={handleInvoiceChange}
                                                    acceptType={`${CompressFile}, ${DocsFileValidation}, ${SpreadSheetFileValidation}, ${PDFFileValidation}, ${ImageFileValidation}`}
                                                    handleDelete={handleDeleteBilling}
                                                    disabled={userDetail.userType.includes('user')}
                                                />

                                                {review && <ReviewCard
                                                    title="Reseña"
                                                    companyName={requestQuote.user.name}
                                                    concept="Qué te pareció el cliente "
                                                    amount={orderDetail?.price?orderDetail.price: 0}
                                                    addReview={false}
                                                    responseProvider={false}
                                                    valueRating={review.raintg}
                                                    reviewValue={review ? review?.review: ''}
                                                    handleRatingchange={handleRatingReviewChange}
                                                    handleReviewChange={handleValueReviewChange}
                                                    handleClick={handleEndServiceClick}
                                                    edit={!orderDetail?.finishedProvider}
                                                />}
                                            </div>}
                                        </>
                                    }

                                    {phase === 4 &&
                                        <TrackingShipping 
                                            date
                                            location={{lat: 11, lng: 11}}
                                            setCargoShippet={setCargoShippet}
                                            cargoShippet={cargoShippet}
                                            handleSave={handleSaveCargoShippet}
                                        />
                                    }

                                    { phase === 5 &&  // seecion de traduccion de la factura
                                        <>
                                            <InvoceTranslationImporter
                                                typeDocument={TypePersons}
                                                typeIt={TypeDocuments}
                                                importer={invoiceTranslation.importer}
                                                setInvoiceTranslation={setInvoiceTranslation}
                                                commercialLevel={CommercialLevel}
                                                disabled={true}
                                                handleSave={()=>handleConfirmInvoiceTranslationClick(true)}
                                                handleRefresh={()=>getInvoiceTranslation(orderDetail.invoiceTranslationId || "", requestQuote?.customs.incoterm || "", requestQuote?.customs.coin || "")}/>
                                            <InvoceTranslationLegal
                                                legalRepresentative={invoiceTranslation.legalRepresentative}
                                                setLegalRepresentative={setInvoiceTranslation}
                                                typeDocs={TypeDocuments}
                                                disabled={true}
                                                handleSave={()=>handleConfirmInvoiceTranslationClick(true)}
                                                handleRefresh={()=>getInvoiceTranslation(orderDetail.invoiceTranslationId || "", requestQuote?.customs.incoterm || "", requestQuote?.customs.coin || "")}/>
                                            <InvoceTranslationSupplier
                                                supplier={invoiceTranslation.provider}
                                                setSupplier={setInvoiceTranslation}
                                                handleConfirmClick={()=>handleConfirmInvoiceTranslationClick(false)}
                                                HandleClickSave={()=>handleConfirmInvoiceTranslationClick(false)}
                                                handleClickCancel={()=>{}}
                                                handleEdit={()=>{}}
                                                disabled={true}
                                                setDisabled={()=>{}}
                                                handleStatement={handleInvoicestatementSave}
                                                isNew={invoiceTranslation.id?false:true}
                                                handleSave={()=>handleConfirmInvoiceTranslationClick(true)}
                                                handleRefresh={()=>getInvoiceTranslation(orderDetail.invoiceTranslationId || "", requestQuote?.customs.incoterm || "", requestQuote?.customs.coin || "")}/>
                                        </>
                                    }  

                                    { phase === 6 && // seccion de arribo y canal de control
                                        <>
                                            <ArrivalNotice
                                                noticeArrival={arrivalControlChannel.noticeArrival}
                                                handleFileChange={handleNoticeArrivalChange}
                                                aceptTypeFiles={DocsFileValidation+','+SpreadSheetFileValidation+','+PDFFileValidation+','+ImageFileValidation}
                                                deleteFile={handleDeleteNoticeArrival}
                                                disabled={!showEdit}
                                            />
                                            <RowPhaseSeven
                                                taxableBase={arrivalControlChannel.dutiesTaxes.taxableBase}
                                                dutiesTaxes={arrivalControlChannel.dutiesTaxes}
                                                taxes={arrivalControlChannel.dutiesTaxes.taxes}
                                                exchageRate={arrivalControlChannel.dutiesTaxes.exchangeRate}
                                                setArrivalControlChannel={setArrivalControlChannel}
                                                onClickSave={()=>{}}
                                                comercialInvoice={arrivalControlChannel.dutiesTaxes.file}
                                                operationId={operationDetail && operationDetail.id ? operationDetail.id : ''}
                                                showEdit={showEdit}
                                                setShowEdit={setShowEdit}
                                                //Se esta agregando userId para que se pueda guardar el archivo
                                                //Si no es necesario lo puedes eliminar
                                                userId={userDetail.id}
                                            />
                                        
                                            <div className="processPage-phase-six ">
                                                <ChannelControl 
                                                    disabled={!showEdit}
                                                    arrivalControlChannel={arrivalControlChannel}
                                                    setArrivalControlChannel={setArrivalControlChannel}
                                                    controlChannel={ChannelControls}
                                                />
                                                <ArrivalDelivery 
                                                    finalDeliveryPlace = {arrivalControlChannel.finalDeliveryPlace}
                                                    setArrivalControlChannel={setArrivalControlChannel}
                                                    showEdit={showEdit}
                                                    setShowEdit={()=>{}}/>

                                                <ButtonEditChange 
                                                    onClickEdit={()=>handleEditSix()}
                                                    edit={showEdit}
                                                    setEdit={setShowEdit}
                                                    add={showEdit}
                                                    onClickCancel={()=>handleClickCancelSix()}
                                                    onClickComplete={()=>handleSaveArrivalControlChannel()}
                                                    onClickSave={()=>handleSaveArrivalControlChannel()}
                                                />
                                            </div>
                                        </>
                                    }

                                    {phase === 7 && // seccion de facturacion y reseña
                                        <>
                                            {/* <div className="processPage-content--bankAcount">
                                                <Button content="Ver cuentas bancarias de Grupo Aduan Agencia de Aduana S.A.C." 
                                                        color='black-75'
                                                        size="extra-small"
                                                        onClick={()=>handleBankClick()} />
                                            </div> */}
                                            <div className="processPage-phase-seven">
                                                <UploadFileArray 
                                                    title='Facturación'
                                                    subtitle={
                                                        window.innerWidth <= 880 ?
                                                        'Tu agencia pondrá aquí los comprobantes y facturas que ha pagado por tu carga.' : 
                                                        'El consolidado de todos los pagos que ha tenido tu carga. Tu agencia colocará los comprobantes, facturas y documentos finales aquí.'                                             }
                                                    buttonContent="Subir archivo"
                                                    fileValue={invoice}
                                                    file={billingReviewData.billingUrl}
                                                    handleFileValue={handleInvoiceChange}
                                                    acceptType={`${CompressFile}, ${DocsFileValidation}, ${SpreadSheetFileValidation}, ${PDFFileValidation}, ${ImageFileValidation}`}
                                                    handleDelete={handleDeleteBilling}
                                            
                                                />
                                            </div>
                                            {review && <ReviewCard
                                                title="Reseña"
                                                companyName={requestQuote.user.name}
                                                concept="Qué te pareció el cliente "
                                                amount={orderDetail?.price?orderDetail.price: 0}
                                                addReview={false}
                                                responseProvider={false}
                                                valueRating={review.raintg}
                                                reviewValue={review ? review?.review: ''}
                                                handleRatingchange={handleRatingReviewChange}
                                                handleReviewChange={handleValueReviewChange}
                                                handleClick={handleEndServiceClick}
                                                edit={!orderDetail?.finishedProvider}
                                            />}
                                        </>
                                    }
                                </> : 
                                <Loader/>
                            }
                        </div>
                    }
                </div>}
            </div>

            <ModalSendMessage
                agencyName={userClient.name + ' ' +userClient.lastName}
                direction={''}
                email={userClient.email}
                phone={userClient.phone  ? userClient.phone?.toString(): ''}
                setShowModal={setShowModalSendMessage}
                showModal={showModalSendMessage}
                title='Datos de'
                whatsapp=""
            />
        </div>
    </div>
    )
}