import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type LogoutWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const LogoutWhite: React.FC<LogoutWhiteProps> =({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/svg/logoutWhite.svg"} alt="" />
        </div>
    )
}