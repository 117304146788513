import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type HomeFillProps = React.HTMLAttributes<HTMLDivElement> & {};

export const HomeFill: React.FC<HomeFillProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/home-fill-icon.svg"} alt="" />
    </div>
  );
};
