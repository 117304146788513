import { ServiceOptios } from "../@types/serviceTypes";

import { CustomsAgency } from "../icons/outline/CustomsAgency";
import { SearchProvider } from "../icons/outline/SearchProvider";
import { Documents } from "../icons/outline/Documents";
import { Inspection } from "../icons/outline/Inspection";
import { Law } from "../icons/outline/Law";
import { Analitycs } from "../icons/outline/Analitycs";
import { Translation } from "../icons/outline/Translation";
import { Courier } from "../icons/outline/Courier";

export const selectService = (option: ServiceOptios) => {
    switch(option) {
        case 'Logística y aduanas':
            return [SearchProvider, 'Logística y aduanas']
        case 'Courier':
                return [Courier, 'Courier']
        
        case 'Búsqueda de proveedor': 
            return [CustomsAgency, "Búsqueda de proveedor"]

        case 'Permisos para restricciones':
            return [Documents, "Permisos para restricciones"]

        case 'Inspecciones de carga':
            return [Inspection, "Inspecciones de carga"]

        case 'Derecho aduanero':
            return [Law, "Derecho aduanero"]
        
        case 'Análisis de datos':
            return [Analitycs, "Análisis de datos"]

        case 'Traducción e interpretación':
            return [Translation, "Traducción e interpretación"]

    }
}