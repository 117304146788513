import { DB, Functions } from "../firebaseConfig/FirebaseConfig";

import { collection, doc, getDoc, addDoc,updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { QuotationDB } from "../interfaces/Quotation";

const GetQuotationDetail = async(id: string) => {
    const response = await getDoc(doc(DB, 'quotation', id));
    /*console.log(response.data())*/
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()});
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

const CreateQuotation = async(data: QuotationDB) => {
    try {
        const response:any = await addDoc(collection(DB, "quotation"), data);  
        // console.log(response)
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
        return error
    }
}

const GetCompareQuotation = async (operationId: string) => {
    if(operationId.length > 0) {
        const compareQuotations = httpsCallable(Functions, 'compareQuotations');
        return compareQuotations({operationId: operationId})
        .then((response) => {
            // console.log("🚀 ~ file: Quotation.service.ts ~ line 36 ~ .then ~ response", response)
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
    });
    } else {
        return [];
    }
}

const UpdateQuotation = async (quotationId: string, quotation: any) => {
    try {
        if(quotationId.length>0) {
            const requestRef = doc(DB, 'quotation', quotationId);
            const response =  await updateDoc(requestRef, {...quotation});
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

const HireQuotation = async (data: {requestQuoteId: string; operationId:string; quotationId:string; 
    serviceId: string; title: string; type: string; price: string; serviceTypeId: string;
    serviceTypeLabel: string}) => {
    const hireQuote = httpsCallable(Functions, 'hireQuote');
    return hireQuote(data)
        .then((response) => {
            // console.log("🚀 ~ files: Quotation.service.ts ~ line 69 ~ .then ~ response", response)
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}


export { GetQuotationDetail, HireQuotation, CreateQuotation, GetCompareQuotation, UpdateQuotation }