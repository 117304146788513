import React from "react";
import { Subheader } from "../Subheader/Subheader";

// import styles
import './TabBottom.css';

interface TabBottomProps {
    subMenu: {
        img: React.ReactNode;
        imgBlack: React.ReactNode;
        title: string;
        event: ()=> void;
    }[],
    menuActive: number;
}

export const TabBottom: React.FC<TabBottomProps> = ({
    subMenu,
    menuActive,
}) => {
    return (
      <div className="tabBottom" role='tabs'>
        { subMenu.length > 0 && subMenu.map((item, idx) => {
            return (
                <Subheader 
                    key={idx} 
                    active={idx === menuActive} 
                    content={
                        <div className={item.title.length> 0 ? "tabBottom-subheader-content" : "tabBottom-subheader-content-nothing-text"}>
                            {idx === menuActive ? item.img: item.imgBlack}
                            <div> {item.title}</div>
                        </div>
                    } 
                    handleClick={()=>{
                        item.event();
                        // setMenuActive(idx);
                    }} 
                    className="tabBottom-subheader"
                />
            )})
        } 
      </div>
    );
}