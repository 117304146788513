import React from "react";

import { ImportPhasesCard } from "../ImportPhasesCard/ImportPhasesCard";
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

import './Orders.css';

interface OrdersProps {
    title: string;
    type: "Cotización" | "Orden"
    serviceTypeLabel: string;
    typeOperation: string;
    companyName: string;
    rating: number;
    order: {
        num: number;
        numComments: number;
        date: string;
    };
    active: boolean
    phases: any[];
    addOrder: boolean;
    flagOrder?: boolean;
    blackModeOrder?: boolean;
    resume?: boolean;
    price: number;
    onClickPhase: (e: number) => void;
    showButton?: boolean;
    handleClick: ()=>void;
    country: {
        city: string;
        alpha2Code: string;
        imgFlag: string;
    },
    unlockedPhases?: number
    numPhase?: number,
    currency: string
}

export const Orders: React.FC<OrdersProps> = ({
    title,
    type,
    serviceTypeLabel,
    typeOperation,
    companyName,
    rating,
    order,
    active = false,
    phases,
    addOrder,
    flagOrder = false,
    blackModeOrder = false,
    resume = false,
    price,
    onClickPhase,
    showButton = false,
    handleClick,
    country,
    unlockedPhases = 1,
    numPhase = 1,
    currency
}) => {
    
    return (
        <div role='ordersRole' className="orders-container">
            {title.length>0 && <div className="orders-header">
                <div className="paragraph-header">{title}</div>
                {/*addOrder && <div className="smalltext-header" style={{color: 'var(--blue-2)'}}>+ Agregar orden de @grupoaduan</div>*/}
            </div>}
            {order ?
                <ImportPhasesCard
                    type={type}
                    serviceTypeLabel={serviceTypeLabel}
                    typeOperation={typeOperation}
                    companyName={companyName}
                    rating={rating}
                    activePhase={numPhase}
                    online={active}
                    black={blackModeOrder}
                    order={order}
                    phases={phases}
                    price={price}
                    showFlags={flagOrder}
                    handlePhaseClick={onClickPhase}
                    handleClick= {()=>handleClick()}
                    resume={resume}
                    showButton={showButton} 
                    country={country}
                    unlockedPhases={unlockedPhases}
                    currency={currency}
                />
            : <div className="paragraph" style={{color: 'var(--gray-3)'}}>No tienes ninguna orden.</div>}
        </div>
    )
}