import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

// importando servicios 
import { GetAllServicesTypeActive, GetAllServicesHover } from "../Services/ServiceType.service";

export const GetServiceTypeActive = createAsyncThunk(
    'serviceType/getAllActive',
   async (_, thunkAPI: any) => {
        try {
            const response = await GetAllServicesTypeActive();

            if(response.length>0) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response;
            }
        } catch (error: any) {
            console.log("slice/serviceType/getAllActive => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
            return thunkAPI.rejectWithValue();
        }
   }
)

export const GetServiceHover = createAsyncThunk(
    'serviceTypeHover/getAllHover',
   async (_, thunkAPI: any) => {
        try {
            const response = await GetAllServicesHover();
            if(response.length>0) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response;
            }
        } catch (error: any) {
            console.log("slice/serviceTypeHover/getAllHover => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
            return thunkAPI.rejectWithValue();
        }
   }
)

const initialState = {
    serviceTypeList: [],
    serviceHover: [],
}

const serviceTypeSlice = createSlice({
    name: "serviceType",
    initialState,
    reducers: {},
    extraReducers: {
        [GetServiceTypeActive.fulfilled.toString()] : (state, action) =>{
            state.serviceTypeList = action.payload
        },
        [GetServiceTypeActive.rejected.toString()] : (state, action) => {
            state.serviceTypeList = []
        },
        [GetServiceHover.fulfilled.toString()] : (state, action) =>{
            state.serviceHover = action.payload
        },
        [GetServiceHover.rejected.toString()] : (state, action) => {
            state.serviceHover = []
        }
    }
}) 

const {reducer} = serviceTypeSlice;
export default reducer;