import { TutorialInterface } from "../interfaces/TutorialInterface";
import { STORAGE_ASSETS } from "./BaseUrl";

export const ReviewsConst: TutorialInterface[] = [
    {
        id: 1,
        icon: STORAGE_ASSETS+"/images/png/orderfilter.png",
        title: "Ordenar y filtar",
        description: "Filtra agencias por industria o calificación",
        duration: "0.10",
        preview: STORAGE_ASSETS+"/images/png/preview1.png",
        path:"./animations/Desktop/OrderFilter/data_ordenar_y_filtrar_1024.json"
    },
    {
        id: 2,
        icon: STORAGE_ASSETS+"/images/png/message.png",
        title: "Enviar mensaje",
        description: "Conversa directamente con expertos sobre tu producto.",
        duration: "0.15",
        preview: STORAGE_ASSETS+"/images/png/preview2.png",
        path:"./animations/Desktop/ProfileMessage/data_perfil_y_mensaje_1024.json"
    },
    {
        id: 3,
        icon: STORAGE_ASSETS+"/images/png/requestquote.png",
        title: "Solicitar cotización",
        description: "Envíale tus requerimientos a distintas agencias y compara precios.",
        duration: "0.30",
        preview: STORAGE_ASSETS+"/images/png/preview3.png",
        path:"/animations/Desktop/RequestQuote/data_solicitar_cotizacion_1024.json"
    },
    {
        id: 4,
        icon: STORAGE_ASSETS+"/images/png/sentoother.png",
        title: "Enviar solicitud",
        description: "Selecciona hasta 3 agencias de aduana para cotizar tu carga.",
        duration: "0.30",
        preview: STORAGE_ASSETS+"/images/png/preview4.png",
        path:"/animations/Desktop/SendRequest/data_enviar_solicitud_1024.json"
    },
    {
        id: 5,
        icon: STORAGE_ASSETS+"/images/png/orderservice.png",
        title: "Reservar servicio",
        description: "Acepta una cotización e inicia tu importación.",
        duration: "0.15",
        preview: STORAGE_ASSETS+"/images/png/preview4.png",
        path:"/animations/Desktop/OrderService/data_contratar_servicio_1024.json"
    }
];