import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type SearchBlackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const SearchBlack: React.FC<SearchBlackProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/svg/icono_search.svg"} alt="" style={{height: '20px'}}/>
        </div>
    )
}