
import { getStorage, ref, uploadBytes, getDownloadURL, uploadString, deleteObject } from "firebase/storage";
import { UpdateUserPlanResourcesFromUser, ValidateUserPlanResources } from "./UserPlanResources.service";
import { toast } from "react-toastify";

// Create a root reference
const storage = getStorage();

const UploadFile = async (file: File, folderName: string, userId: string) => {
    const storageRef = ref(storage, `${folderName}/${file.name}`);
    const fileSizeInMB = file.size / 1024;
    // validate if user have stoge to upload file
    const haveSpace = userId && userId.length>0 ? await ValidateUserPlanResources(userId, 'storage', fileSizeInMB) : true;
    if(haveSpace) {
          // 'file' comes from the Blob or File API
        return new Promise((resolve, reject) => {
            uploadBytes(storageRef, file)
            .then((snapshot) => {
                // console.log('upload: ', snapshot);
                getDownloadURL(ref(storage, snapshot.ref.fullPath))
                .then(async (url) => {
                    console.log("🚀 ~ .then ~ url:", url)
                    if(userId && userId.length>0) {
                        const respUp = await UpdateUserPlanResourcesFromUser(userId, 'storage', fileSizeInMB);
                        console.log("🚀 ~ .then ~ respUp:", respUp)
                    }                    
                    resolve(url);
                })
                .catch((error) => {
                    console.log("error al obtener url", error);
                    reject(error);
                })
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
        })    
    } else {
        toast.error('No tienes suficiente espacio para subir este archivo, por favor actualiza tu plan.');
        return false;
    }
}

const UploadString = async (file: string | undefined, folderName: string, userId: string, fileSize: number) => {
    const storageRef = ref(storage, folderName);
    const fileSizeInMB = fileSize / 1024;
    if(file) {
        const haveSpace = userId.length>0 ? await ValidateUserPlanResources(userId, 'storage', fileSizeInMB) : true;
        if(haveSpace) {
            return new Promise((resolve, reject) => {
                uploadString(storageRef, file, 'data_url').then((snapshot) => {
                    getDownloadURL(ref(storage, snapshot.ref.fullPath))
                    .then( async(url) => {
                        // console.log(url)
                        if(userId.length>0) {
                            const respUp = await UpdateUserPlanResourcesFromUser(userId, 'storage', fileSizeInMB);
                            console.log("🚀 ~ .then ~ respUp:", respUp);
                        }
                        resolve(url)
                    })
                    .catch((error) => {
                        console.log("error al obtener url", error);
                        reject(error);
                    })
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
            })
        } else {
            toast.error('No tienes suficiente espacio para subir este archivo, por favor actualiza tu plan.');
            return false;
        }         
    }
}

// method to delete file from storage
const DeleteFile = async (fileUrl: string, size: number, userId: string) => {
    const storage = getStorage();
    const storageRef = ref(storage, fileUrl);

    const fileName = fileUrl.split('/').pop();
    return new Promise((resolve, reject) => {
        deleteObject(storageRef).then(() => {
            if(userId.length>0) {
                UpdateUserPlanResourcesFromUser(userId, 'storage', -size);
            }
            resolve(fileName);
        }).catch((error) => {
            console.log("🚀 ~ deleteObject ~ error:", error)
            reject(error);
        })
    });
}

export default {
    UploadFile,
    UploadString,
    DeleteFile
}

// userId/requestQuote/loquesea.jpg
// userId/requestQuote/requestId/loquesea.jpg =>
// userId/order/orderId/phase_1/loquesea.pdf