import React, { useState } from "react";

import './RatingService.css';
import { ErrorInterface } from "../../interfaces/ErrorInterface";

interface RatingServiceProps {
    textInicial: string;
    textFinal: string;
    value?: number;
    setRating: (e: number) => void;
    disabled?: boolean;
    error?: ErrorInterface;
    setError?: React.Dispatch<React.SetStateAction<ErrorInterface>>;
}

export const RatingService: React.FC<RatingServiceProps> = ({
    textInicial= "",
    textFinal= "",
    value=0,
    setRating,
    disabled=false,
    error={status: false, message: ''},
    setError=()=>{},
}) => {
    const [numActive, setNumActive] = useState(value);

    const handleClick = (n: number) => {
        setRating(n);
        setNumActive(n);
    }

    return (

        <div role='ratingServiceRole' className="ratingService-container">
             <div className="ratingService-rating">
                {[1,2,3,4,5].map(n =>
                    <div 
                        key={n} 
                        onClick={()=> !disabled&& handleClick(n)}
                        className={`${n===1? 'ratingService-item-incial': 
                                    (n===5?'ratingService-item-final':'ratingService-item-middle')} 
                                    ${(n <= numActive) && 'ratingService-item-clicked' }`}>

                    </div>
                )}
            </div>
            <div className="ratingService-rating-row">
                <div className="smalltext" style={{color: '#333333'}}>{textInicial}</div>
                <div className="smalltext" style={{color: '#333333'}}>{textFinal}</div>
            </div>
            {(error.status) && <div className="textfield-error tinytext-header">{error.message}</div>}
        </div>
    )
}