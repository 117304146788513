import React, { useRef, useState } from "react";

import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { Button } from "../Button/Button";

import { BellBlack } from "../../icons/solid/BellBlack";

import './IconNotificationBadget.css';
import { BellWhite } from "../../icons/solid/BellWhite";
import { Notification } from "../../icons/outline/Notification";
import { NotificationActive } from "../../icons/outline/NotificationActive";

interface IconNotificationBadgetProps {
    badget?: number;
    isActive: boolean;
}

export const IconNotificationBadget: React.FC<IconNotificationBadgetProps> = ( {
    badget=0,
    isActive
}) => {

    return (
        <div role='iconNotificationBadgetRole' 
            className="paragraph-header iconNotificationBadget">
                {badget>0?<BellBlack/>: (isActive ? <NotificationActive/> : <Notification/>)}
                {badget>0 && 
                    <div className={
                            badget >= 100 ? 
                                "tinytext iconNotificationBadget-badget-100" : 
                                "tinytext iconNotificationBadget-badget"}>
                                    {badget}
                    </div>
                }
        </div>
    )
}      