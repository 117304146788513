import React, { useEffect, useState } from "react";

// Importando estilo
import "./PlanDetailSection.css";
import { PlanDetail } from "../PlanDetail/PlanDetail";
import { ComparePlansInterface } from "../../interfaces/ComparePlanInterface";
import { getCompareTablePlans } from "../../Services/PlanDetailArkabia.service";
import { toast } from "react-toastify";

interface PlanDetailSectionProps {
    black?: boolean;
}

export const PlanDetailSection: React.FC<PlanDetailSectionProps> = ({
    black
}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [comparePlans, setComaprePlans] = useState<ComparePlansInterface>({
        titles: [],
        courses: [],
        logistics: [],
        supplier: [],
        courier: [],
        groupImports: [],
        consulting: [],
        mainPanel: [],
        importSteps: [],
        help: []
    });


    const getComparePlans = async () => {
        try {
            setLoading(true);
            const response = await getCompareTablePlans();
            // console.log("🚀 ~ getComparePlans ~ response:", response);
            setComaprePlans(response);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getComparePlans ~ error:", error)
            setLoading(false);
            toast.error("Ocurrió un error al obtener los planes, por favor intenta nuevamente");
        }
    }

    useEffect(() => {
        getComparePlans();
    }, []);

    return (
        <div id="planDetailSectionId" className="planDetailSection">
            <div className={`big-header ${black ? "planDetailSection-title-black" : "planDetailSection-title"}`}>
                Compara todas las características
            </div>
            <div className="planDetailSection-detail">
            {!loading && <PlanDetail
                    black={!black}
                    comparePlan={comparePlans}
                />}
            </div>
        </div>
    );
}; 