import React, { useEffect, useState } from "react";

/** importando components */
import { Button } from "../../../Button/Button";

import { GetLastTimeMinText } from "../../../../utils/GetLastTimeText";
import { useNavigate } from "react-router-dom";
import { UserActive } from "../../../UserActive/UserActive";

/** importando css */
import './pushNotification.css';
import { CloseBig } from "../../../../icons/solid/CloseBig";
import { PushNotificationInterface } from "../../../../interfaces/NotificationInterface";


/** declarando interface */
interface PushNotificationProps {
    createAt: Date;
    content: string;
    title?: string;
    handleItemClick: ()=>void;
    setPushNotification: React.Dispatch<React.SetStateAction<PushNotificationInterface | undefined>>;
}

export const PushNotification: React.FC<PushNotificationProps> = ({
    createAt,
    content,
    title = "",
    handleItemClick,
    setPushNotification
}) => {

    const handleCloseClick = () => {
        setPushNotification(undefined);
    }

    useEffect(() => {
        setTimeout(() => {
            handleCloseClick();
        }, 20000);
    }, []);

    return (
         <div onClick={()=> handleItemClick()} role='pushNotificationRole' className={"push-notification"}> {/* type === 'turn' ? "card-notification-event" : */}
            {<div className="push-notification-content">
                <div className="paragraph-header header-title-content">
                    {title}
                </div>
                <div onClick={()=>handleCloseClick()} ><CloseBig /></div>
            </div>}
            <div className="push-notification-business">
                <div className="smalltext header-title-business">{content}</div>
            </div>
            <div className="push-notification-footer">
                <div className="tinytext-header push-notification-header-time">{ "hace " + GetLastTimeMinText(createAt)}</div>
            </div>
        </div>
    )
}
