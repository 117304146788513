import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";
import { GetSubscriptionById } from "../Services/Subscription.service";

export const GetSubscriptionId = createAsyncThunk(
    'subscription/getSubscriptionById',
    async (planId: string, thunkAPI: any) => {
        try {
            const response:any = await GetSubscriptionById(planId);
            // console.log("🚀 ~ file: GetSubscriptionId.ts ~ line 12 ~ response", response)
        
            thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
            return response;
       
        } catch (error:any) {
            console.log('subscription/getSubscriptionById => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
)

const initialState = {
    subscription: {},
    subscriptions: [],
}

const subscriptionSlice = createSlice ({
    name: "plan",
    initialState,
    reducers: {},
    extraReducers: {
        [GetSubscriptionId.fulfilled.toString()]: (state, action) => {
            state.subscription = action.payload
        },
        [GetSubscriptionId.rejected.toString()]: (state, action) => {
            state.subscription = {}
        }
    }
})

const {reducer} = subscriptionSlice;
export default reducer;