import React, { useEffect, useState } from "react";

// Importando estilo
import "./SimplePlan.css";
import { PlanInterface } from "../../interfaces/PlansInterface";
import { Button } from "../Button/Button";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";
import { PlanDetailInterface } from "../../interfaces/PlanDetail";
import { GetPlanDetailById } from "../../Services/PlanDetailArkabia.service";
import { toast } from "react-toastify";
import { CheckBlack } from "../../icons/outline/CheckBlack";
import { CheckWhite } from "../../icons/outline/CheckWhite";
import { CloseGray } from "../../icons/outline/CloseGray";
import { CloseWhite } from "../../icons/solid/CloseWhite";
import { CloseBlack } from "../../icons/outline/CloseBlack";

interface SimplePlanProps {
    currencyActive: "USD" | "PEN";
    plan: PlanInterface
    onclickPlan: (plan: PlanInterface) => void;
    isPlanSelected: boolean;
    black?: boolean;
}

export const SimplePlan: React.FC<SimplePlanProps> = ({
    currencyActive,
    plan,
    onclickPlan,
    isPlanSelected,
    black = true,
}) => {

    const [loading, setLoading] = useState(false);
    const [planDetail, setPlanDetail] = useState<PlanDetailInterface>();

    const titleArray = plan.title.split("(");

    const getPlanDetail = async (id: string) => {
        if(loading) return;

        setLoading(true);

        try {
            const response = await GetPlanDetailById(id);
            if(response) {
                setPlanDetail(response);
            } else {
                toast.error('No se pudo obtener el detalle del plan');
            }
            
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getPlans ~ error:", error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getPlanDetail(plan.planDetailId || "");
    }, [plan]);


    return (
    <div 
        className={`plan-content 
            ${isPlanSelected 
                //  !plan.isPopular
                 ? (black ? 'plan-selected-white' :'plan-selected-black') : "" } 
            ${black ? "plan-black" : "plan-white"}`} 
            onClick={()=>onclickPlan(plan)}
    >
            {plan.isPopular && 
        <div className={"plan-popular"}>
            <div className="paragraph-header">
                Más popular
            </div>
        </div>}
        <div className={`plan-gradient ${plan.isPopular ? " plan-gradient-popular" : ""}`}>
            <div className="plan">
                <div className="plan-body-first">
                    <div className="plan-header-price">
                        <div className="plan-header">
                            <div className={`small-bold ${black ? "plan-text-white" : "plan-text-black"} plan-text-title plan-text-row`}>
                                {titleArray[0]}
                                {titleArray[1] && 
                                <div className={`paragraph-header ${black ? "plan-text-white" : "plan-text-black"}`}>
                                    {"(" + titleArray[1]}
                                </div>}
                            </div>
                            <div className="plan-price">
                                <div className={`big-header ${black ? "plan-text-white" : "plan-text-black"}`}>
                                    {currencyActive === "USD" ? plan.priceUsd : plan.pricePen}
                                </div>
                               
                                <div className={`smalltext ${black ? "plan-text-white" : "plan-text-black"} plan-price__text`}>
                                    {`${currencyActive} ${plan.billingFrequency.length>0 ? ' / ' + plan.billingFrequency : ''}`}
                                </div>
                                    {/* <div className="tinytext-header plan-text">{plan.note}</div> */}
                                 
                            </div>
                        </div>
                    </div>

                    <div className="plan-detail-row">
                        <div className={`plan-body smalltext ${black ? "plan-text-white" : "plan-text-black"}`}>
                            {plan.minDescription}
                        </div>
                    </div>
                    <div className={`plan-button ${black ? "plan-button-white" : "plan-button-black"} plan-button-selected`} style={{backgroundImage:`url(${STORAGE_ASSETS}/images/png/selectBackground.png`}}>
                        <Button
                            onClick={() => onclickPlan(plan)}
                            content='Empezar ahora'
                        />
                    </div>

                </div>

                {planDetail && 
                <div className="plan-body-second">
                    <div className="plan-detail-row">
                        <div className="plan-detail-icon">
                            {!planDetail?.logistic[1].detail[0].value ? 
                                ((isPlanSelected && black) ? <CloseBlack className="plan-detail-icon-close"/> : <CloseWhite className="plan-detail-icon-close"/>) : 
                                ((isPlanSelected && black) ? <CheckBlack className="plan-detail-icon-check"/> : <CheckWhite className="plan-detail-icon-check"/> )
                            }
                        </div>
                        <div className={`smalltext ${black ? "plan-text-white" : "plan-text-black"} plan-text`}>
                            {planDetail?.logistic[1].name + " "}
                            <span className={`smalltext-header ${black ? "plan-text-white" : "plan-text-black"} plan-text`}>
                                ({planDetail?.logistic[1].detail[0].value})
                            </span>
                        </div>
                    </div>

                    <div className="plan-detail-row">
                        <div className="plan-detail-icon">
                            {!planDetail?.logistic[2].detail[0].value ? 
                                ((isPlanSelected && black) ? <CloseBlack className="plan-detail-icon-close"/> : <CloseWhite className="plan-detail-icon-close"/>) : 
                                ((isPlanSelected && black) ? <CheckBlack className="plan-detail-icon-check"/> : <CheckWhite className="plan-detail-icon-check"/> )
                            }
                        </div>
                        <div className={`smalltext ${black ? "plan-text-white" : "plan-text-black"} plan-text`}>
                            {planDetail?.logistic[2].name + " "}
                        </div>
                    </div>

                    <div className="plan-detail-row">
                        <div className="plan-detail-icon">
                            {!planDetail?.logistic[3].detail[0].value ? 
                                ((isPlanSelected && black) ? <CloseBlack className="plan-detail-icon-close"/> : <CloseWhite className="plan-detail-icon-close"/>) : 
                                ((isPlanSelected && black) ? <CheckBlack className="plan-detail-icon-check"/> : <CheckWhite className="plan-detail-icon-check"/> )
                            }
                        </div>
                        <div className={`smalltext ${black ? "plan-text-white" : "plan-text-black"} plan-text`}>
                            {planDetail?.logistic[3].name + " "}
                        </div>
                    </div>

                    <div className="plan-detail-row">
                        <div className="plan-detail-icon">
                            {!planDetail?.course[2].detail[0].value ? 
                                ((isPlanSelected && black) ? <CloseBlack className="plan-detail-icon-close"/> : <CloseWhite className="plan-detail-icon-close"/>) : 
                                ((isPlanSelected && black) ? <CheckBlack className="plan-detail-icon-check"/> : <CheckWhite className="plan-detail-icon-check"/> )
                            }
                        </div>
                        <div className={`smalltext ${black ? "plan-text-white" : "plan-text-black"} plan-text`}>
                            {planDetail?.course[2].name + " "}
                        </div>
                    </div>

                    <div className="plan-detail-row">
                        <div className="plan-detail-icon">
                            {!planDetail?.course[3].detail[0].value ? 
                                ((isPlanSelected && black) ? <CloseBlack className="plan-detail-icon-close"/> : <CloseWhite className="plan-detail-icon-close"/>) : 
                                ((isPlanSelected && black) ? <CheckBlack className="plan-detail-icon-check"/> : <CheckWhite className="plan-detail-icon-check"/> )
                            }
                        </div>
                        <div className={`smalltext ${black ? "plan-text-white" : "plan-text-black"} plan-text`}>
                            {planDetail?.course[3].name + " "}
                        </div>
                    </div>

                    <div className="plan-detail-row">
                        <div className="plan-detail-icon">
                            {!planDetail?.course[4].detail[0].value ? 
                                ((isPlanSelected && black) ? <CloseBlack className="plan-detail-icon-close"/> : <CloseWhite className="plan-detail-icon-close"/>) : 
                                ((isPlanSelected && black) ? <CheckBlack className="plan-detail-icon-check"/> : <CheckWhite className="plan-detail-icon-check"/> )
                            }
                        </div>
                        <div className={`smalltext ${black ? "plan-text-white" : "plan-text-black"} plan-text`}>
                            {planDetail?.course[4].name + " "}
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
    );
};
