import React from "react";

import { Priority } from "../../../../@types/priority";
import './EventCalendar.css'; 

interface EventCalendarProps {
    title: string;
    detail: string;
    priority: Priority;
    startHour: number;
    startMinute: number;
    endHour: number;
    endMinute: number;
}

export const EventCalendar: React.FC<EventCalendarProps> = ({
    title,
    detail,
    priority,
    startHour,
    startMinute,
    endHour,
    endMinute
}) => {

    let heighEvent = (((endHour-startHour)*136) + ((endMinute-startMinute)*2.6));
    return (
        <div className="eventDetail-container" style={{height: heighEvent}}>
            <div className={`eventDetail-marker ${priority === 'low' ? "eventDetail-marker__low" : (
                priority === 'middle' ? "eventDetail-marker__middle" : "eventDetail-marker__high"
            ) }`}></div>
            <div className="eventDetail-body">
                <div className="tinytext-header eventDetail-textColor">{title}</div>
                <div className="tinytext eventDetail-textColor">{detail}</div>
            </div>
        </div>
    )
}