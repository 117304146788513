import React from 'react';

import './ProcessServiceFooter.css';

import { ArkabiaOp } from '../../../../icons/outline/ArkabiaOp';
import { CloseBig } from '../../../../icons/solid/CloseBig';
import { Button } from '../../../Button/Button';
import { useSelector } from 'react-redux';
import { STORAGE_ASSETS } from '../../../../Consts/BaseUrl';

interface ProcessServiceFooterProps {
    stepProcess: number; // title of the modal
    onClickNextStep: () => void;
    onClickBeforeStep: () => void;
    showDraftButton?: boolean;
    isOrder?: boolean;
}

export const ProcessServiceFooter: React.FC<ProcessServiceFooterProps> = ({
    stepProcess,
    onClickNextStep,
    onClickBeforeStep,
    showDraftButton = true,
    isOrder = false
}) => {

    const {isLoggedIn} = useSelector((state: any) => state.auth);

    return (
        <div className="modalProcessService-footer-container">
            {isLoggedIn && stepProcess >0 && showDraftButton &&<div className="modalProcessService-footer--draft" onClick={()=>{}}>
                <img src={STORAGE_ASSETS+'/images/png/folder_plus.png'} alt=""/>
                <div className="paragraph modalProcessService-footer-draft">guardar borrador</div>
            </div>}
           {/* {stepProcess<3 &&  */}
           <div className="modalProcessService-footer--buttons">
                {stepProcess >0 && 
                    <Button content={stepProcess<=3 && !isOrder ? 'Volver' : 'Salir'} color="black-25" size="small" onClick={()=>onClickBeforeStep()} />}
                    <Button content={stepProcess<=3 && !isOrder ?'Siguiente' : 'Empezar'} color="black-75" size="small" onClick={()=>onClickNextStep()} />
            </div>
            {/* } */}
        </div>
    )
}