import React, {useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";

/** Importando componentes */
import { Calendar } from "../../../components/Calendar/Calendar";
import { CalendarMonth } from "../../../components/Calendar/Month/CalendarMonth";
import { CalendarDetail } from "../../../components/Calendar/Detail/CalendarDetail";
import { Button, Subheader } from "../../../components";
// import { FilterSelectorMiniature } from "../../../components/FilterSelector/FilterSelectorMobile/FilterSelectorMiniature";
import { FilterSelectorMin } from "../../../components/FilterSelector/FilterSelectorMin/FilterSelectorMin";
// import { FilterButton } from "../../../components/Button/Filter/FilterButton";
import { SearchTextField } from "../../../components/SearchTextField/SearchTextField";
// import { DashboardElementsRowData } from "../../../testdata/DashboardElementsRowData";
// import { DashboardElementsDisputes } from "../../../testdata/DashboardElementsDisputes"; 
// import { EventView } from "../../../components/EventView/EventView";
import { AbsoluteButton } from "../../../components/Button/Absolute/Absolute";
import { DashboardElementsSupplier } from "../../../components/DashboardElementsSupplier/DashboardElementsSupplier";
import { Option } from "../../../components/Calendar/Option/Option";
import { Today } from "../../../components/Calendar/Today/Today";
import { ModalAddEvent } from "../../../components/Modal/ModalAddEvent/ModalAddEvent";

/** Importanto redirecciones */
import { REQUEST_QUOTE, OPERATIONS, PROVIDER, ORDER } from "../../../Consts/BaseUrl";

/** Importando data */
import { UserList } from "../../../testdata/UserSearchList";
import { Event } from "../../../interfaces/Event";

import { useDispatch, useSelector } from "react-redux"; 

// Importando slices
// import { GetProviderRequestQuote, GetRequestQuoteListByCompanyAndUser } from "../../../Slices/RequestQuote";
import { GetCompanyUser } from "../../../Slices/Company";
import { GetUserByUID } from "../../../Slices/User";

// Importando utilitarios
import { FormatDate } from "../../../utils/formatDate";

// Importando store
import type { AppDispatch } from "../../../Store/Store";

// importando interfaces 
import { RequestQuoteInterface } from "../../../interfaces/RequestQuote";

// importando servicios
import { ChangeProviderRequestQuote, GetRequestQuoteByCompanyAndUser } from "../../../Services/RequestQuote.service";

/** Importando estilos */
import './OrderProvider.css';

import { addWeeks, set } from "date-fns";
import { addMonths } from "date-fns/esm";
import { SelectWeek } from "../../../utils/SelectWeek";
import { SelectMonth } from "../../../utils/SelectMonth";
import { CategoryEvent } from "../../../interfaces/Event";
import { Loader } from "../../../components/Loader/Loader";
import { ConverMoneyFormat } from "../../../utils/ConvertDecimal";
import { OrderInterface } from "../../../interfaces/OrderInterface";
import { GetCurrency } from "../../../utils/GetCurrency";
import { ModalQuoteSupplier } from "../../../components/Modal/ModalQuoteSupplier/ModalQuoteSupplier";
import { QuotationDataError } from "../../../interfaces/QuotationDataError";
import { GetAllServiceByCompany } from "../../../Slices/Service";
import { Company } from "../../../interfaces/Company";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { GetOrderByCompanyIdRT } from "../../../Services/Order.service";
import { SearchBlack } from "../../../icons/outline/SearchBlack";
import { BackArrow } from "../../../icons/outline/BackArrow";
import { FilterSelectorExtended } from "../../../components/FilterSelector/FilterSelectorExtended/FilterSelectorExtended";
import { FilterStatus } from "../../../Consts/FilterStatus";
import { StatusProviderTypes } from "../../../@types/statusProviderTypes";

export const OrderProvider: React.FC<{}> = () => {


    const dispatch = useDispatch<AppDispatch>();
    // recuperando state de Redux
    const {providerRequestQuote} = useSelector((state: any) => state.requestQuote)

    const [loading, setLoading] = useState<boolean>(false);

    const [activeSubMenu, setActiveSubmenu] = useState<"contizacion" | "vendido" | "calendario">('contizacion');
    const [activeCalendar, setActiveCalendar] = useState('semana');
    
    const [showMinModal, setShowMinModal] = useState(false);
    const [showAddEvent, setShowAddEvent] = useState(false);
    const [clickSearch, setClickSearch] = useState<boolean>(false);
    const [showModalQuoteSupplier, setShowModalQuoteSupplier] = useState<boolean>(false);

    const [selectedDay, setSelectedDay] = useState(new Date());
    const [selectedWeek, setSelectedWeek] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [openRequestquote, setOpenRequestquote] = useState<number>(0);

    const {userDetail} = useSelector((state: any) => state.user);
    // const {requestQuoteListCompany} = useSelector((state: any) => state.requestQuote);
    // const { orderList } = useSelector((state: any) => state.order);
    const {serviceListCompany} = useSelector((state: any) => state.service);

    const [companyDetail, setCompanyDetail] = useState<Company>();

    const [reqQuotes, setReqQuotes] = useState<RequestQuoteInterface[]>([]);
    const [reqQuoteList, setReqQuoteList] = useState<any[]>([]);
    const [numUnReadRq, setNumUnReadRq] = useState<number>(0);

    const [orders, setOrders] = useState<OrderInterface[]>([]);
    const [orderList, setOrderList] = useState<OrderInterface[]>([]);

    const [quotatioData, setQuotatioData] = useState<RequestQuoteInterface>({
        title: "",
        number: 0,
        user: {
          id: '',
          name: '',
          typeIt: '',
          itNumber: 0,
          numOperations: 0,
          phone: '',
          rating: 0,
        },
        companyName: '',
        serviceId: '',
        serviceTypeId: '',
        serviceTypeLabel: '',
        status: 'published',
        statusProvider: 'No leída',
        active: true,
        customs: {
          regime: 'Importación definitiva',
          incoterm: 'FOB',
          value: 0,
          coin: '',
          insurance: false,
          merchandisePermits: {
            dataSheet: [],
            proforma: [],
            packingList: [],
          },
          exwDirection: '',
          descriptionOperation: '',
        },
        transport: {
          type: 'sea',
          origin: {
            city: '',
            country: '',
          },
          destination: {
            city: '',
            country: '',
          },
          typeContainer: {
            lcl: false,
            fcl: false,
            lclAir: false
          }, 
        },
        phaseDraft: 1,
        operationId: '',
        numServiceComments: 0,
        numServiceRating: 0,
        observation: '',
        category: 'Importación',
        createdAt: new Date(),
        updatedAt: new Date(),
        quoteGenerated: true,
        country: {
            alpha2Code: 'PE',
            city: 'Lima',
            imgFlag: 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fflag_of_Peru.png?alt=media&token=d8bfda2c-4c48-419a-b8b9-00a423a24d53'
        },
        autogenerated: false,
    });

    /** state error fields cotizacion */
    const [quotatioErrors, setQuotatioErrors] = useState<QuotationDataError>({
        title: {
            status: false,
            message: "",
        },
        transportation: {
            type: {
                status: false,
                message: "",
            },
            origin: {
                status: false,
                message: "",
            },
            destination: {
                status: false,
                message: "",
            },
            delivery: {
                status: false,
                message: "",
            },
            typeContainer: {
                status: false,
                message: "",
            },
            lcl: {
                totalWeight: {
                status: false,
                message: "",  
                },
                unitWeight: {
                status: false,
                message: "",  
                },
                totalVolume: {
                status: false,
                message: "",  
                },
                unitVolume: {
                status: false,
                message: "",  
                },
                cargoDescription: {
                status: false,
                message: "",  
                },
                packages: {
                    status: false,
                    message: "",  
                }
            },
            lclAir: {
                list: {
                    message: '',
                    status: false,
                },
                items: {
                    message: '',
                    status: false,
                }
            }
        },
        customs: {
            regime: {
                status: false,
                message: "",
            },
            incoterm: {
                status: false,
                message: "",
            },
            value: {
                status: false,
                message: "",
            },
            coin: {
                status: false,
                message: "",
            },
            insurance: {
                status: false,
                message: "",
            }
        }
    });
    
    let week = SelectWeek(selectedWeek)();
    let month = SelectMonth(selectedDate)();

     // state nuevo evento 
     const [newEvent, setNewEvent] = useState<Event>({
        id: 0,
        detail: '',
        date: undefined,
        startHour: undefined,
        endHour: undefined,
        participants: [],
        categoryId: 0
    });
    //state nueva categoria de eventi
    const [newCategory, setNewCategory] = useState<CategoryEvent>({
        id: 0,
        title: '',
        color: '#FFFFFF',
        userId: 1
    });

    const [filters, setFilters] = useState({
        preferences: [],
        location: [],
        sortBy: [],
        status: []
    }) 

    const navigation = useNavigate();
    const location = useLocation();

    // // metodo para ir la pagina de servicios
    // const handleClickService = () => {
    //     navigation('/'+SERVICE);
    // }

    const handleClickModalSupplier = () => {
        setShowModalQuoteSupplier(true);
    }

    // metodo que se ejecuta al hacer click en una cotizacion
    const handleClickQuote = (id: string) => {
        let urlLocation =  (location.pathname).split('/');
        if(urlLocation.length===2 && urlLocation[1] === PROVIDER) {
            navigation(`${OPERATIONS}/${REQUEST_QUOTE}/${id}`);
        } else {
            navigation(`${REQUEST_QUOTE}/${id}`);
        }
        
    }

    // metodo que se ejecuta al hacer click en un dia del calendario
    const handleCalendarDayClick = (day:Date)=> {
        setSelectedDay(day);
        setSelectedWeek(day);
    }

    /** Siguiente semana */
    const nextWeek = ()=> {
        setSelectedWeek(addWeeks(selectedWeek, 1));          
    }

    // metodo para retroceder una semana
    const preWeek = ()=> {
        setSelectedWeek(addWeeks(selectedWeek, -1));         
    }

    /** Siguiente mes */
    const nextMonth = ()=> {
        setSelectedDate(addMonths(selectedDate, 1));
    }

    // metodo para retroceder un mes
    const prevMonth = ()=> {
        setSelectedDate(addWeeks(selectedDate, -4)); 
    }

    // obtiene las solicitudes de cotizacion de firestore
    // const getRequestQuote = async (id: string) => {
    //     if(!loading){
    //         setLoading(true);
    //         dispatch(GetProviderRequestQuote(id))
    //         .unwrap()
    //         .then((response) => {
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setLoading(false);
    //         })
    //     }
    // }

    // obteniendo la compañia por usuario
    const getCompanyByUser = async (userId: string) => {
        setLoading(false);
        if(!loading){
            setLoading(true);
            dispatch(GetCompanyUser(userId))
            .unwrap()
            .then((response) => {
                setCompanyDetail(response);
                getServiceByCompanyId(response.id);
                GetRequestQuoteListCompanyAndUser(response.id);
                getOrderByCompany(response.id);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
        }
    }

    const getServiceByCompanyId = async (companyId: string) => {
        setLoading(false);
        if(!loading) {
            setLoading(true);
            dispatch(GetAllServiceByCompany(companyId))
            .unwrap()
            .then((response) => {
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
        }
    }

    const getOrderByCompany = async (companyId: string) => {
        setLoading(false);
        if(!loading) {
            setLoading(true);
            try {
                GetOrderByCompanyIdRT(companyId, (response) => {
                    setLoading(false);
                    setOrders(response);
                    setOrderList(response);
                });
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        } 
    }

    // obteniendo id del usuario
    const getByUser = async (UID: string) => {
        setLoading(false);
        if(!loading){
            setLoading(true);
            dispatch(GetUserByUID(UID))
            .unwrap()
            .then((response) => {
                getCompanyByUser(response.id);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
        }
    }

    // obteniendo las solicitudes de cotizacion segun empresa de firestore
    const GetRequestQuoteListCompanyAndUser = async(companyId: string) => {
        setLoading(false);
        if(!loading && userDetail.id) {
            setLoading(true);
            try {
                GetRequestQuoteByCompanyAndUser(companyId, userDetail.id, (response) => {
                    setReqQuotes(response);
                  
                    let numUnRead = response.reduce((acum: number, current: any) => (
                        acum = acum + (current.statusProvider === 'No leída' ? 1 : 0)
                    ), 0)
             
                    setNumUnReadRq(numUnRead);
                    getRequestQuoteStructure(response);
                    if(response && response.length > 0 && response.length < 4) {
                        setOpenRequestquote(response.length);
                    } else {
                        setOpenRequestquote(4);
                    }
                    setLoading(false);
                })
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    }

    // cargando el state segun la estructura del row
    const getRequestQuoteStructure = (rqList: RequestQuoteInterface[]) => {
        if(rqList) {
            let list: any[] = [];
            rqList.map((rq: RequestQuoteInterface) => {
                list.push({
                    id: rq.id,
                    number: rq.number,
                    title: rq.title,
                    category: rq.category,
                    companyName: rq.companyName,
                    photoURL: rq.user.photoURL,
                    statusProvider: rq.statusProvider,
                    numOperation: rq.number,
                    date: FormatDate(rq.createdAt.toDate()),
                    client: rq.user.name,
                    user: {userName: rq.user.name},
                    numServiceComments: rq.numServiceComments,
                    serviceRating: rq.numServiceRating,
                    price: ConverMoneyFormat(rq.customs?.value ? rq.customs.value .toString() : ''),
                    transport: capitaLetter(rq.transport?.origin && rq.transport?.origin.name?.toLocaleLowerCase()),
                    content: [
                        {
                            detail: rq.transport?.type === 'air' ? capitaLetter(rq.transport?.origin && rq.transport?.origin.country.toLocaleLowerCase()): capitaLetter(rq.transport?.origin && rq.transport?.origin.name?.toLocaleLowerCase()),
                            subDetail: rq.transport?.type === 'sea' ? 'Marítimo': 'Aéreo',
                            type: 'text'
                        },
                        {
                            detail: GetCurrency(rq.customs?.coin ? rq.customs?.coin:'')+" "+ConverMoneyFormat(rq.customs?.value+""),
                            type: 'text'
                        },
                        {
                            detail: rq.lastMessage || "Todavía no hay ningún mensaje",
                            type: 'text'
                        }
                    ]
                })
            });
            
            setReqQuoteList(list);
         }
    }

    const handleFilterStatus = (status: StatusProviderTypes | undefined) => {
        if(activeSubMenu === "vendido") {
            return;
        }

        if(status && status.length > 0) {
            const filter = reqQuotes.filter((rq) => rq.statusProvider === status);
            if(filter.length > 0) {
                getRequestQuoteStructure(filter);
            }else {
                setReqQuoteList([]);
            }  
        } else {
            getRequestQuoteStructure(reqQuotes);
        }
    }

    const handleFilterService = (serviceType: string) => {      
        if(serviceType.length === 0) {
            setOrderList(orders);
        } else {
            const filter: any = activeSubMenu === "contizacion" ? (reqQuotes.filter((rq) => rq.serviceTypeLabel === serviceType)) :
            orders.find((t) => t.serviceTypeLabel === serviceType);
            if(filter) {
                activeSubMenu === "contizacion" ? getRequestQuoteStructure(filter) : setOrderList(filter);
            }
        }       
    }

    //Buscar por titulo de las ordenes 
    const searchOrderProvider = (text: string) => {
        if(text.length>0) {
     
            let response:any = activeSubMenu === 'contizacion' ? reqQuotes.filter((rqdl:RequestQuoteInterface) => 
                        (rqdl.title.toUpperCase()).includes(text.toUpperCase()) || 
                        (rqdl.user.name.toUpperCase()).includes(text.toUpperCase())) :
                        orders.filter((or:OrderInterface) => 
                        (or.title.toUpperCase()).includes(text.toUpperCase())) ;

                        activeSubMenu === 'contizacion' ? getRequestQuoteStructure(response) : setOrderList(response);
        } else {
            activeSubMenu === 'contizacion' ?  getRequestQuoteStructure(reqQuotes) : setOrderList(orders);
        }
        /*text.length>0?
        setResulDraft(requestQuoteDraftList.filter((rqdl:any) => (rqdl.title.toUpperCase()).includes(text.toUpperCase()))):
        setResulDraft(requestQuoteDraftList)

        console.log('buscar', text)*/
    }

    // metodo para cambiar el statusProvider de una solicitud de cotizacion
    const changeStatusProvider = (id: string, status: string) => {
        setLoading(true);
        ChangeProviderRequestQuote(id, status)
        .then((reponse) => {
            // console.log("🚀 ~ file: OrderProvider.tsx ~ line 273 ~ .then ~ reponse", reponse);
            setLoading(false);
            getCompanyByUser(userDetail.id);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    const capitaLetter = (str:any) => {
        if(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    }

    // handle search
    const handleSearch = () => {
        setClickSearch(true);
    }

    // metodo para mostrar el detalle de la orden
    const handleClickOrder = (operationId: string, orderId: string, activePhase: number) => {
        navigation(`/${PROVIDER}/${OPERATIONS}/${operationId}/${ORDER}/${orderId}/${activePhase}`);
    }

    useEffect (() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (userCurrent) => {
            if(userCurrent) {
                getByUser(userCurrent.uid);
            } 
        });        
    }, []);

    useEffect(() => {
        setQuotatioData((prev) => ({
            ...prev,
            companyId: companyDetail?.id ? companyDetail.id : '',
            companyName: companyDetail?.name ? companyDetail.name : '',
            usersProvider: [userDetail.id]
        }))
    }, [companyDetail]);
    
    // cargando la estructura de los rows
    // useEffect(() => {
    //     getRequestQuoteStructure();
    // }, [requestQuoteListCompany]);

    return ( 
        <div className="order-supplier">
            <> 
                <div className="order-supplier-content">
                    <div className="order-supplier-left">
                        <div className="order-supplier-left-row">
                            <div className="order__nav">
                                {/** Tab de ordenes y calendario */}
                                <Subheader content="Órdenes vendidas" active={activeSubMenu === 'vendido'} 
                                        handleClick={()=>{ setActiveSubmenu('vendido');}} />
                                <Subheader content={`Cotizaciones ${numUnReadRq>0 ? "("+numUnReadRq+")" : "" }`} active={activeSubMenu === 'contizacion'} 
                                        handleClick={()=>{ setActiveSubmenu('contizacion');}} /> 
                                {/* <Subheader content="Calendario" active={activeSubMenu === 'calendario'} 
                                        handleClick={()=>{ setActiveSubmenu('calendario');}} />  */}
                            </div>
                            <div>
                                { (activeSubMenu === 'contizacion') && <Button content="Iniciar cotización" onClick={handleClickModalSupplier}/>}
                            </div>
                        </div>
        
                    {/* { (activeSubMenu !== 'calendario') &&    
                    <div className="order-supplier_search">
                        <div className="order-supplier-filters">
                            <FilterSelectorMiniature preferences={[]} sortBy={[]} />
                            <SearchTextField title={window.innerWidth <= 481 ? "Buscar": ''} setTextSearch={()=>{}} />
                            
                        </div>
                        <FilterButton sliderIcon title="Filtros" setModal={setShowMinModal}/>
                    </div>} */}

                    <div className="order-supplier-search-content">
                        { window.innerWidth > 600 ? 
                            <div className="order-supplier-search">
                                {!clickSearch? 
                                    <SearchBlack className="chat-search-icon" onClick={handleSearch} style={{cursor: 'pointer'}}/> :
                                    <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
                                        <BackArrow
                                            className="chat-search-content-icon-back" 
                                            onClick={()=>setClickSearch(false)}/>
                                        <SearchTextField 
                                            setTextSearch={(e:any)=>searchOrderProvider(e)} 
                                            title='Buscar'
                                            darkIcon
                                        />
                                    </div>
                                    
                                }
                            </div> : 
                            <SearchTextField 
                                setTextSearch={(e:any)=>searchOrderProvider(e)} 
                                title='Buscar'
                                darkIcon
                            />
                        }

                        <FilterSelectorExtended
                            relevanceFilter={FilterStatus}
                            handleSelectRelevanceItem={(e: any)=>handleFilterStatus(e)}
                            handleKeyboardSelected={handleFilterService}
                            category= {[]}
                            handleCategorySelected={()=>{}} 
                            categoryId={''}
                            titleFilterOne={activeSubMenu === 'contizacion' ? "Estado" : ""}
                            titleFilterTwo={activeSubMenu === 'contizacion' ? "Servicio" : ""}
                        />
                    </div> 
        
                    {!loading ? <>
                    { (activeSubMenu === 'vendido') && 
                        <>
                            <div className="order-supplier-quote order-supplier-quote-desktop">
                            {orderList && orderList.length>0 ? orderList.map((o: OrderInterface, idx: number) => (
                                    <DashboardElementsSupplier 
                                        id={o.id?o.id:''}
                                        key={idx}
                                        date={FormatDate(new Date(o.createdAt.toDate()))}
                                        open={true}
                                        type="order"
                                        badge={false}
                                        handleClickQuote= {()=>{}}
                                        dataImportation={o}
                                        handleClickOrder= {()=>handleClickOrder(o.operationId, o.id ? o.id : '', o.activePhase)}
                                    />
                            )): <div className="paragraph-header orders-notFound">Aún no tienes ninguna orden vendida</div>}
        
                            </div>
                        </> 
                    }
                    { /** Cotizaciones */ }
                    { (  activeSubMenu === 'contizacion') &&  
                        <div className="order-supplier-quote">
                            { reqQuotes && reqQuotes.length>0 ? reqQuotes.map((rq:RequestQuoteInterface, idx:number) => (
                                <DashboardElementsSupplier 
                                    id={rq.id || ""}
                                    key={idx}         
                                    date={""}
                                    open={idx<=openRequestquote}
                                    dataImportation={rq}
                                    type="requestQuote"
                                    badge={false}
                                    handleClickQuote= {()=>handleClickQuote(rq.id || '')}
                                    handleClickOrder= {()=> {}}

                                /> 
                            )): 
                                <div className="orderProvider-NotFound">
                                    <div className="paragraph-header orderProvider-NotFound-text">
                                        Aún no tienes ninguna cotización
                                    </div>
                                </div>
                            }
                          
                        </div>
                    }
                    </> : <Loader />}
        
                    { /** Calendario */ }
                    { (  activeSubMenu === 'calendario') &&  
                        <div className="order-calendar-detail">
                            <Option nextWeek={nextWeek}
                                    prevWeek={preWeek}
                                    active= {activeCalendar}
                                    setActive={setActiveCalendar}
                                    rangeDate={week}
                                    prevMonth={prevMonth}
                                    nextMonth={nextMonth}
                                    day={selectedDate}
                                    onClickModalEvent={setShowAddEvent}
                            />
        
                            { /** Calendario diario*/}
                            { activeCalendar === 'dia' ? 
                                <div className="order-calendar-detail-list-today">
                                    <div className="order-calendar-detail-list-today-scroll">
                                        { [...Array(2)].map((c, idx) => (
                                            <Today key={idx} 
                                                selectedToday={selectedDay}
                                                events={[{
                                                title: "Enviar factura 0 a Julissa. Lorem ipsum dolor sit amet, consectetur adipiscing elitisum et diresa.",
                                                detail: "Termómetros infrarrojos sin contacto",
                                                date: "8:00 - 9:00",
                                                participants: "",
                                                priority: "high"
                                                },
                                                {
                                                    title: "Enviar factura a Julissa. Lorem ipsum dolor sit amet, consectetur adipiscing elitisum et diresa.",
                                                    detail: "Termómetros infrarrojos sin contacto",
                                                    date: "8:00 - 9:00",
                                                    participants: "",
                                                    priority: "low"
                                                },
                                                {
                                                    title: "Enviar factura a Julissa. Lorem ipsum dolor sit amet, consectetur adipiscing elitisum et diresa.",
                                                    detail: "Termómetros infrarrojos sin contacto",
                                                    date: "8:00 - 9:00",
                                                    participants: "",
                                                    priority: "middle"
                                                },
                                                {
                                                    title: "Enviar factura a Julissa. Lorem ipsum dolor sit amet, consectetur adipiscing elitisum et diresa.",
                                                    detail: "Termómetros infrarrojos sin contacto",
                                                    date: "8:00 - 9:00",
                                                    participants: "",
                                                    priority: "middle"
                                                },
                                                {
                                                    title: "Enviar factura a Julissa. Lorem ipsum dolor sit amet, consectetur adipiscing elitisum et diresa.",
                                                    detail: "Termómetros infrarrojos sin contacto",
                                                    date: "8:00 - 9:00",
                                                    participants: "",
                                                    priority: "high"
                                                },
            
                                            ]}/>
                                            ))
                                        }
                                    </div>
                                </div> : 
                                
                                /** Calendario semanal*/
                                activeCalendar === 'semana' ? 
                                <CalendarDetail week={week}  events={[]} /> :
                
                                /** Calendario mensual*/
                                <>
                                    <CalendarMonth 
                                        selectedMonth={selectedDate}
                                        data= {month}
                                        setDay={handleCalendarDayClick}/>
                                    <div className={"order-calendar-month"}>
                                        <Calendar 
                                            setDay={handleCalendarDayClick}
                                        />
                                        {/*<div className="order-calendar-event">
                                            <EventView 
                                                onClickModal={setShowAddEvent}
                                                date="Para hoy"
                                                events={[{title:'Reunión con el proveedor por Zoom.', 
                                                        detail:'Carros de juguete para navidad',
                                                        date: '21:00 - 22:00',
                                                        participants: 'Kristhian y tú',
                                                        priority: 'low'},
                                                        {title:'Reunión con el proveedor por Zoom.', 
                                                        detail:'Carros de juguete para navidad',
                                                        date: '21:00 - 22:00',
                                                        participants: 'Kristhian y tú',
                                                        priority: 'middle'},
                                                        {title:'Reunión con el proveedor por Zoom.', 
                                                        detail:'Carros de juguete para navidad',
                                                        date: '21:00 - 22:00',
                                                        participants: 'Kristhian y tú',
                                                        priority: 'high'},
                                                        {title:'Reunión con el proveedor por Zoom.', 
                                                        detail:'Carros de juguete para navidad',
                                                        date: '21:00 - 22:00',
                                                        participants: 'Kristhian y tú',
                                                        priority: 'high'},
                                                        ]}
                                                    />
                                                    </div>*/}
                                    </div>
                                </>
                            }
                        </div>
                    }
                    </div>
        
                    
                    <div className="order-supplier-right">
                        <Calendar setDay={handleCalendarDayClick}/>
                        {/*<div className="order-supplier-right-calendar-event">
                            <EventView  onClickModal={setShowAddEvent}
                                        date="Para hoy"
                                        events={[{title:'Reunión con el proveedor por Zoom.', 
                                                detail:'Carros de juguete para navidad',
                                                date: '21:00 - 22:00',
                                                participants: 'Kristhian y tú',
                                                priority: 'low'},
                                                {title:'Reunión con el proveedor por Zoom.', 
                                                detail:'Carros de juguete para navidad',
                                                date: '21:00 - 22:00',
                                                participants: 'Kristhian y tú',
                                                priority: 'middle'},
                                                {title:'Reunión con el proveedor por Zoom.', 
                                                detail:'Carros de juguete para navidad',
                                                date: '21:00 - 22:00',
                                                participants: 'Kristhian y tú',
                                                priority: 'high'},
                                                {title:'Reunión con el proveedor por Zoom.', 
                                                detail:'Carros de juguete para navidad',
                                                date: '21:00 - 22:00',
                                                participants: 'Kristhian y tú',
                                                priority: 'high'},
                                                ]}/>
                                            </div>*/}
                    </div>
                </div>
                    
                { (activeSubMenu === 'contizacion' || activeSubMenu === 'calendario' ) &&
                    <AbsoluteButton title={ activeSubMenu === 'contizacion' ? "Enviar cotización": ( activeSubMenu ==='calendario'  ? '+ Agregar evento' : '' ) }
                                    color="black-75" 
                                    handleClick={handleClickModalSupplier}/>}
    
                    <FilterSelectorMin 
                        showModal={showMinModal} 
                        filterItems={[
                            {
                                title: 'Preferencias', 
                                options: [{
                                    text: 'Preferencias',
                                    type: 'text',
                                }]
                            },
                            {
                                title: 'Ordenar por', 
                                options: [{
                                text: 'Relevancia',
                                type: 'text'
                                }]
                            }, 
                        ]}
                        setShowModal={setShowMinModal}  
                        setFilter={setFilters}
                    />
        
                    { companyDetail && serviceListCompany && showModalQuoteSupplier && <ModalQuoteSupplier
                        showModal={showModalQuoteSupplier} 
                        setShowModal={setShowModalQuoteSupplier} 
                        quotatioErrors={quotatioErrors}
                        setQuotationErrors={setQuotatioErrors}
                        requestQuote={quotatioData}
                        setRequestQuote={setQuotatioData}
                        companyId = {companyDetail.id?companyDetail.id:''}
                        companyName = {companyDetail.name}
                        serviceId = {serviceListCompany[0].id}
                        serviceRating = {serviceListCompany[0].rating}
                        serviceType = {serviceListCompany[0].serviceType}
                        serviceTypeLabel = {serviceListCompany[0].serviceTypeLabel}
                        numServiceComment = {serviceListCompany[0].numComments}
                    />
                    }

                    <ModalAddEvent 
                        userList ={UserList}
                        show={showAddEvent} 
                        setShow={setShowAddEvent}
                        newCategory={newCategory}
                        setNewCategory={setNewCategory}
                        newEvent={newEvent}
                        setNewEvent={setNewEvent}
                    />
            </>
        </div>
    )
}