import { DB } from "../firebaseConfig/FirebaseConfig";
import { collection, doc, getDocs, getDoc, where, query, orderBy, updateDoc } from "firebase/firestore"; 
import { Company } from "../interfaces/Company";

const GetAllCompanyActive = async() => {
    const q = query(collection(DB, "company"), where("status", "==", true), orderBy("updatedAt", "desc"));
    const response = await getDocs(q);
    // const response = await getDocs(collection(DB, "service")); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

const GetCompanyById = async(id: string): Promise<Company> => {
    const response = await getDoc(doc(DB, "company", id)); // trae el documento segun "id" de la coleccion de firestore
    // console.log(response)
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()} as Company)
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

const GetCompanyByUser =async(id: string) => {
    const u = query(collection(DB, "company"), where("userAdmin", "==", id))
    const response = await getDocs(u);
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}

const UpdatCompany = async (company: Company) => {
    try {
        if(company.id) {
            const requestRef = doc(DB, 'company', company.id);
            const response = await updateDoc(requestRef, {...company});

            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}


export { 
    GetCompanyById,
    GetCompanyByUser,
    UpdatCompany,
    GetAllCompanyActive
}