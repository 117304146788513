import React from 'react';

import './ProcessServiceHeader.css';

import { ArkabiaOp } from '../../../../icons/outline/ArkabiaOp';
import { CloseBig } from '../../../../icons/solid/CloseBig';

interface ProcessServiceHeaderProps {
    title: string; // title of the modal
    subtitle?: string; // subtitle of the modal
    showLogo: boolean; // show logo
    onClose: () => void; // function to close the modal
}

export const ProcessServiceHeader: React.FC<ProcessServiceHeaderProps> = ({
    title,
    subtitle="",
    showLogo=false,
    onClose,
}) => {
    return (
        <div className='processServiceHeader-container'>
            <div className='processServiceHeader-left'>
               {showLogo && <div className='processServiceHeader-left--logo'>
                    <ArkabiaOp/>
                </div>}
                <div className='processServiceHeader-left--content'>
                    <div className='small-header processServiceHeader-colorText'>{title}</div>
                    {subtitle && subtitle.length>0 && <div className='smalltext processServiceHeader-colorText'>{subtitle}</div>}
                </div>
            </div>
            <div className='processServiceHeader--close'>
                <CloseBig onClick={()=>onClose()} />
            </div>            
        </div>
    )
}