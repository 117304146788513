import { Company } from "../interfaces/Company";
import { OrderInterface_ } from "../interfaces/OrderInterface";
import { User } from "../interfaces/User";
import { Consignee } from "../interfaces/Consignee";

export const peruFlag =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Flag_of_Peru.svg/255px-Flag_of_Peru.svg.png";

export const COUNTRIES = ["China", "Estados Unidos", "Rusia", "España"];

export const companyImgUrl =
  "https://grupoaduan.com/wp-content/uploads/2018/12/logonuevo.png";

export const bgImgUrl =
  "https://www.gannett-cdn.com/presto/2020/06/11/PDTF/c6ba22c1-213b-4054-b27e-a56ca3987d9d-AP_Fedex_Drug_Trafficking_F.jpg";

export const COUNTRYFLAG =
  "https://upload.wikimedia.org/wikipedia/commons/2/2d/Flag_of_Peru.png";


export const DCAP: Company = {
  name: "DeChinaAPeru",
  serviceType: "Broker de importciones",
  photoProfile: companyImgUrl,
  photoCover: bgImgUrl,
  numOperation: 143,
  aboutUs:
    "Nuestro equipo está conformado por  profesionales competentes quienes posee una amplia experiencia, empatía y pasión por su trabajo. ",
  rating: 4.47,
  numComments: 13,
  country: {
    name: "Perú",
    alpha2Code: "PE",
    imgFlag: COUNTRYFLAG
  },
  city: "Lima",
  phone: '',
  direction:'',
  catProviderName: "Broker de importciones",
  status: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  userAdmin: "5f1b8f8f-f8f8-4f8f-8f8f-8f8f8f8f8f8f",
  reviewServiceId: ''
};

export const DCAPNew: Company = {
  name: "DeChinaAPeru",
  serviceType: "Broker de importciones",
  photoProfile: companyImgUrl,
  photoCover: bgImgUrl,
  numOperation: 143,
  aboutUs:
    "Nuestro equipo está conformado por  profesionales competentes quienes posee una amplia experiencia, empatía y pasión por su trabajo. ",
  rating: 4.47,
  phone: '',
  numComments: 13,
  country: {
    name: "Perú",
    alpha2Code: "PE",
    imgFlag: COUNTRYFLAG
  },
  city: "Lima",
  catProviderName: "Broker de importciones",
  status: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  userAdmin: "5f1b8f8f-f8f8-4f8f-8f8f-8f8f8f8f8f8f",
  reviewServiceId: '',
  direction:''
};


const USERIMGURL =
  "https://www.looper.com/img/gallery/see-robert-pattinson-as-bruce-wayne-on-the-set-of-the-batman/l-intro-1602527667.jpg";

export const REVIEWDATA = {
  userImgUrl: "./kjdfnjskd",
  username: "Eloy Duran",
  lastname: "Duran",
  rating: 4.7,
  review: "Me parecieron una agencia excelente, resolvieron todas mis dudas.",
  likes: 3,
};

export const ORDERDATA: OrderInterface_ = {
  num: 0,
  city:'',
  country: {
      alpha2Code: '',
      imgFlag: ''
  },
  date: '',
  numComments: 0
};

export const USERDATA: User = {
  id: '1',
  name: "Ernesto",
  userName: "@ernestoSanchez",
  lastName: "Sanchez",
  email: "ernesto@gmail.com",
  photoUrl: USERIMGURL,
  documentType: "DNI",
  country: {
    alpha2Code:'PE',
    imgFlag:'',
    name:'PERU'
  },
  itNumber: '',
  phone: undefined,
  typeIt: '',
  authStep: 1,
  culqiCustomerId: '',
  planId: '',
  subscriptionId: '',
};

const TEAMS = [
  { id: 1, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 2, teamName: "Grupo Aduan Agencia Aduan" },
  /*{ id: 3, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 4, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 5, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 6, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 7, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 8, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 9, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 10, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 11, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 12, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 13, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 14, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 15, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 16, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 17, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 18, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 19, teamName: "Grupo Aduan Agencia Aduan" },
  { id: 20, teamName: "Grupo Aduan Agencia Aduan" }*/

];

export const OPERATIONDATA = {
  order: "Carros de juguete para navidad",
  operationType: "importacion" as any,
  teams: TEAMS,
};

export const UDTR: any = {
  title: "Titulo",
  subtitle: "Texto aqui",
  rowsData: [
    {
      title: "Input 1",
      columValues: [],
      color: "white-90",
    },
    {
      title: "Input",
      columValues: ["^"],
      color: "white-50",
    },
    {
      title: "Input 5",
      columValues: ["text"],
      color: "transparent",
    },
    {
      title: "Input",
      columValues: ["21050"],
      color: "white-25",
    },
    {
      title: "Input",
      columValues: ["text"],
      color: "transparent",
    },
    {
      title: "Input",
      columValues: ["text"],
      color: "black-50",
    },
  ],
};

export const CONSIGNEEDATA: Consignee = {
  id: 'dfd',
  companyName: 'hola',
  createdAt: new Date(),
  updatedAt: new Date(),
  fiscalAddress: '',
  itNumber: 9,
  lastName: 'jose',
  name: 'ramos',
  typeIt: 'dni',
  userId: '923u284jh',
  email: 'arkabia@gmil.com',
  phone: 999999999,
  typeDocument: 'DNI',
  status: true
}