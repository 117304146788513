import React, { useState } from "react";

import { Button } from "../Button/Button";
import './ToastWarning.css';

interface ToastWarningProps {
    title: string,
    textButtonCancel: string,
    textButtonAccept: string,
    onClickCancel: ()=>void,
    onClickAccept: ()=>void,
}

export const ToastWarning: React.FC<ToastWarningProps> = ({
    title='',
    textButtonCancel='',
    textButtonAccept='',
    onClickCancel,
    onClickAccept,
}) => {
    return(
        <div className="toastWarning">
            <div className="paragraph toastWarning-title">{title}</div> 
            <div className="toastWarning-buttons">
                <Button content={textButtonCancel} color="red" onClick={onClickCancel}/>
                <Button content={textButtonAccept} color="green-2" onClick={onClickAccept}/>
            </div>
        </div>
    )
}