import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type LogoutProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Logout: React.FC<LogoutProps> =({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/svg/logout.svg"} alt="" />
        </div>
    )
}
