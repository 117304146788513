import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

// importando servicios
import { GetAllRequestQuoteActive, 
         GetRequestQuoteById, 
         GetAllProviderRequestQuote, 
         GetAllRequestQuoteDraftUser, 
         GetRequestQuoteByCompanyAndUser} from "../Services/RequestQuote.service";

// metodo para obtener todos los servicios
export const GetRequestQuoteActive = createAsyncThunk(
    'requestQuote/getRequestQuoteActive', 
    async (_, thunkAPI: any) => {
        try {
            const response = await GetAllRequestQuoteActive();

            if(response.length>0) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
            }
        } catch (error: any) {
            // console.log('slice/requestQuote/getRequestQuoteActive => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const GetProviderRequestQuote = createAsyncThunk(
    'requestQuote/getProviderRequestQuote', 
    async (companyId: string, thunkAPI: any) => {
        try {
            const response = await GetAllProviderRequestQuote(companyId);

            if(response.length>0) {
                // console.log('response', response)
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
            }
        } catch (error: any) {
            // console.log('slice/requestQuote/getProviderRequestQuote => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const GetRequestQuoteDetail = createAsyncThunk(
    'requestQuote/getById',
    async (id: string, thunkAPI: any) => {
        try {
            const response = GetRequestQuoteById(id);
            // console.log("🚀 ~ file: RequestQuote.ts ~ line 55 ~ response", response)
            if(response) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response
            }
        } catch (error) {
            // console.log("slice/requestQuote/getById => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error, code: 'not-fount' }));  
            return thunkAPI.rejectWithValue();
        }
    }
);

export const GetRequestQuoteDraftUser = createAsyncThunk(
    'requestQuote/GetAllRequestQuoteStatus',
    async (userId: string, thunkAPI: any) => {
        // console.log('slice/requestQuote/GetAllRequestQuoteStatus => userId:', userId);
        try {
            const response:any = await GetAllRequestQuoteDraftUser(userId);
            // console.log('draft', response)
            if(response.length>0) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
            } else {
                return []
            }
        } catch (error: any) {
            // console.log('slice/requestQuote/GetAllRequestQuoteStatus => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const GetRequestQuoteListByCompanyAndUser = createAsyncThunk(
    'requestQuote/requestQuoteByCompanyId',
   async (data: {companyId: string, userId: string}, thunkAPI: any) => {
       try {
           const response:any = await GetRequestQuoteByCompanyAndUser(data.companyId, data.userId);
           if(response.length>0) {
               thunkAPI.dispatch(setError({ status: false, message:'', code:''}));
               return response;
           }
       } catch (error: any) {
            // console.log('slice/requestQuote/requestQuoteByCompanyId => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
       }
   }
)


const initialState = {
    requestQuoteList: [],
    requestQuoteDetail: {},
    providerRequestQuote: [],
    requestQuoteDraftList: [],
    requestQuoteListCompany: [],
}

const requestQuoteSlice = createSlice({
    name: "requestQuote",
    initialState,
    reducers: {},
    extraReducers: {
        [GetRequestQuoteActive.fulfilled.toString()]: (state, action) => {
            state.requestQuoteList = action.payload
        },
        [GetRequestQuoteActive.rejected.toString()]: (state, action) => {
            state.requestQuoteList = []
        },
        [GetRequestQuoteDetail.fulfilled.toString()]: (state, action) => {
            state.requestQuoteDetail = action.payload;
        },
        [GetRequestQuoteDetail.rejected.toString()]: (state, action) => {
            state.requestQuoteDetail = {}
        },
        [GetProviderRequestQuote. fulfilled.toString()]: (state, action) => {
            state.providerRequestQuote = action.payload;
        },
        [GetProviderRequestQuote. rejected.toString()]: (state, action) => {
            state.providerRequestQuote = [];
        },
        [GetRequestQuoteDraftUser. fulfilled.toString()]: (state, action) => {
            state.requestQuoteDraftList = action.payload;
        },
        [GetRequestQuoteDraftUser. rejected.toString()]: (state, action) => {
            state.requestQuoteDraftList = [];
        },
        [GetRequestQuoteListByCompanyAndUser. fulfilled.toString()]: (state, action) => {
            state.requestQuoteListCompany = action.payload;
        },
        [GetRequestQuoteListByCompanyAndUser. rejected.toString()]: (state, action) => {
            state.requestQuoteListCompany = [];
        }
    }
})

const {reducer} = requestQuoteSlice;
export default reducer;