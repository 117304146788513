import React from "react";

import './ResumePay.css';

import { StatusScreen } from '@mercadopago/sdk-react';
import { Button } from "../Button/Button";
import { TextField } from "../TextField/TextField";
import { BannerPayment } from "../BannerPayment/BannerPayment";
import { Checkyellowblack } from "../../icons/solid/Checkyellowblack";

interface ResumePayProps {
    amount: number;
    servicePlan: string;
    installments: number;
    lastFourDigits: string;
    operationNumber: string;
    onCloseClick: () => void;
    onStarClick: () => void;
}

export const ResumePay: React.FC<ResumePayProps> = ({
    // paymentId,
    amount,
    servicePlan,
    installments,
    lastFourDigits,
    operationNumber,
    onCloseClick,
    onStarClick,
}) => {

    return (
        <div className="resumePay-container">
            <div className="resumePay-img">
                <Checkyellowblack/>
            </div>

            <div className="medium-header resumePay--colorText">¡Contratado!</div>

            <div className="paragraph-header resumePay--colorText">Se ha cargado a tu tarjeta ${amount.toFixed(2)}</div>

            <div className="resumePay-content-first">
                <div className="resumePay-content__row">
                    <div className="resumePay_questions">Servicio</div>
                    <div className="resumePay_answer">{servicePlan}</div>
                </div>
                <div className="resumePay-content__row">
                    <div className="resumePay_questions">Cuotas</div>
                    <div className="resumePay_answer">{installments}</div>
                </div>
                {/* <div className="resumePay-content__row">
                    <div className="resumePay_questions">Cuotas aprox.</div>
                    <div className="resumePay_answer">$34.00</div>
                </div> */}
                <div className="resumePay-content__row">
                    <div className="resumePay_questions">Nº de tarjeta</div>
                    <div className="resumePay_answer">**** {lastFourDigits}</div>
                </div>
            </div>

            <div className="resumePay-content-first">
               <div className="resumePay-content-first_text"> Nº de Operación: {operationNumber}</div>
            </div>

            {/* <div className="resumePay-buttons">
                <Button content="Salir" color="black-25" onClick={()=>onCloseClick()} />
                <Button content="Empezar" onClick={()=>onStarClick()} />
            </div> */}
        </div>
    );
}