import React, { useEffect, useState, useRef } from "react";

import './HoursGrid.css';

interface HoursGridCalendarProps {
 
}

export const HoursGridCalendar: React.FC<HoursGridCalendarProps> = ({
   
}) => { 
  

   return (
    <div className="hoursGridCalendar-container">
        {[...Array(24)].map((r, idx) => {
            return (
                <div key={idx} className="hoursGridCalendar-container--row">
                    <div className="hoursGridCalendar-hour--minute"> 
                        {<div className="smalltext-header hoursGridCalendar-hour--text">{idx < 10 ? '0'+idx: ''+idx}:00</div>} 
                    </div>
                    <>
                    {[...Array(60)].map((m, idy) => <div key={idy} className="hoursGridCalendar-hour--minute">
                        {idy === 29 && <div className="hoursGridCalendar-hour--text smalltext-header">{idx < 10 ? '0'+idx: ''+idx}:30</div>}
                    </div>)}
                    </>
                </div>
            )
        })}
    </div>
    )
}