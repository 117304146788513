
export type AuthSteps_item = "MAIN_LOGIN_STEP" | "MAIN_REGISTER_STEP" | "RESTORE_STEP" | "SELECT_SITUATION" | "MAIN_REGISTER_PAY";

export type AuthTitle_Step = 'Iniciar sesión' | "Regístrate" | "Recuperar contraseña";

export const AuthSteps : {
    step: AuthSteps_item,
    title: AuthTitle_Step
}[] = [
    {
        step: "MAIN_LOGIN_STEP",
        title: "Iniciar sesión"
    },

    {
        step: "MAIN_REGISTER_STEP",
        title: "Regístrate"
    },
    {
        step: "MAIN_REGISTER_PAY",
        title: "Regístrate"
    },
   
    {
        step: "RESTORE_STEP",
        title: "Recuperar contraseña"
    }
]
