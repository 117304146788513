import { CommentInterface } from "../interfaces/CommentInterface";

export const CommentsConsts: CommentInterface[] = [
    {
        comment: 'Me encantó la reunión; súper profesionales y con toda la predisposición de ayudar. Definitivamente súper recomendable.',
        profile: 'comment1.png',
        name: 'Cindy Bayona',
        nickame: '@cindybayona',
    },
    {
        comment: 'Bastante innovador, el mecanismo de trabajo reduce tiempo y lo hace más fácil al consumidor',
        profile: 'comment2.png',
        name: 'Erick Cahuas',
        nickame: '@erick.ch',
    },
    {
        comment: 'Arkabia es una increíble plataforma, con un gran equipo que trabaja día con día para impactar positivamente en el mundo de las importaciones, sigan creciendo 👏👏',
        profile: 'comment3.png',
        name: 'Luis Vera',
        nickame: '@luisvera',
    },
    {
        comment: 'Me sirvió mucho la información que recibí debido a que yo realizó importaciones y no tenía idea de esta página.',
        profile: 'comment4.png',
        name: 'Kathia Prado',
        nickame: '@kath.prado',
    },
    {
        comment: 'Me parece una plataforma excelente,  el equipo resolvió todas mis dudas.',
        profile: 'comment5.png',
        name: 'Eloy Durán',
        nickame: '@dechinaaperu.com',
    },
    {
        comment: 'Muy bonita experiencia, grazie!',
        profile: 'comment6.png',
        name: 'Stacy CM',
        nickame: '@stacy.cm',
    },
    {
        comment: 'Excelente plataforma',
        profile: 'comment7.png',
        name: 'Cesar Salvatierra',
        nickame: '@cersa.salva',
    },
]