import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

// importnaod estilos
import "./Menu.css";

import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { Profile } from "../../icons/outline/Profile";
import { HelpCircleBlack } from "../../icons/outline/HelpCircleBlack";
import { Logout } from "../../icons/outline/Logout";
import { ProfileBlack } from "../../icons/outline/ProfileBlack";
import { HelpCircleWhite } from "../../icons/outline/HelpCircleWhite";
import { LogoutWhite } from "../../icons/outline/LogoutWhite";
import { Card } from "../../icons/solid/Card";
import { Money } from "../../icons/outline/Money";
import { MoneyBlack } from "../../icons/outline/MoneyBlack";
import { CreditCardWhite } from "../../icons/outline/CreditCardBlack";
import { CreditCard } from "../../icons/outline/CreditCard";
import { useSelector } from "react-redux";
import { subscriptionInterface } from "../../interfaces/SubscriptionInterface";
import { toast } from "react-toastify";
import { GetSubscriptionById } from "../../Services/Subscription.service";

interface MenuProps {
    active: string,
    setActive: React.Dispatch<React.SetStateAction<string>>;
    handleLogoutClick: ()=> void,
    handleWhatsapp?: ()=> void,
}

export const Menu: React.FC<MenuProps> = ({
    active,
    setActive,
    handleLogoutClick,
    handleWhatsapp,
}) => {

  const {userDetail} = useSelector((state: any) => state.user);

  return (
    <div className="menu">
      <div className="tinytext-header menu-title">Menú</div>
      <div className="menu-body">
        <div className="menu-item">
          <ButtonIcon
            content="Mi perfil" 
            onClick={()=>setActive('perfil')} 
            Icon={active==='perfil'? <Profile /> : <ProfileBlack/>} 
            size="extra-small" 
            color={active==='perfil' ? 'black-50' : "white-50"}
            className={active==='perfil' ? "menu-item-active" : "menu-item-desactive"}
          />

         {userDetail.userType.includes("user") && <>
            <ButtonIcon
              content="Mi suscripción" 
              onClick={()=>setActive('suscripcion')} 
              Icon={active==='suscripcion'? <Money /> : <MoneyBlack/>} 
              size="extra-small" 
              color={active==='suscripcion' ? 'black-50' : "white-50"}
              className={active==='suscripcion' ? "menu-item-active" : "menu-item-desactive"}
            />
  
            <ButtonIcon
              content="Mis recibos" 
              onClick={()=>setActive('recibos')} 
              Icon={active==='recibos'? <CreditCard /> : <CreditCardWhite/>} 
              size="extra-small" 
              color={active==='recibos' ? 'black-50' : "white-50"}
              className={active==='recibos' ? "menu-item-active" : "menu-item-desactive"}
            />
         </>}
        </div>

        <div className="menu-footer">
          <ButtonIcon
            content="Ayuda" 
            onClick={handleWhatsapp} 
            Icon={active==='ayuda' ?<HelpCircleWhite/> : <HelpCircleBlack />} 
            size="extra-small" 
            color={active==='ayuda' ? 'black-50' : "white-50"}
            className={active==='ayuda' ? "menu-item-active" : "menu-item-desactive"}
          />
          <ButtonIcon
            content="Cerrar Sesión" 
            onClick={()=>handleLogoutClick()} 
            Icon={active==='ayuda' ? <LogoutWhite/> : <Logout />} 
            size="extra-small" 
            color={active==='cerrar-sesion' ? 'black-50' : "white-50"}
            className={active==='cerrar-sesion' ? "menu-item-active" : "menu-item-desactive"}
          />
        </div>
      </div>
    </div>
    // <div className="profile-provider-list-button" role='list-button'>
    //   {/* <Button onClick={()=>setActive('perfil')} content="Mi perfil" color={active==='perfil' ? 'black-50' : "white-50"} className={active==='perfil' ? "list-button-active" : "btn-large"}/> */}
    //   <Button onClick={()=>setActive('perfil')} content="Mi Perfil" color={active==='perfil' ? 'black-50' : "white-50"} className={active==='perfil' ? "list-button-active" : "btn-large"}/>
    //   {/* <Button onClick={()=>setActive('empresa')} content="Mi empresa" color={active==='empresa' ? 'black-50' : "white-50"} className={active==='empresa' ? "list-button-active" : "btn-large"}/> */}
    //   {/*<Button onClick={()=>setActive('contactos')} content="Mis contactos" color={active==='contactos' ? 'black-50' : "white-50"} className={active==='contactos' ? "list-button-active" : "btn-large"}/>
    //   <Button onClick={()=>setActive('factura')} content="Mi facturación" color={active==='factura' ? 'black-50' : "white-50"} className={active==='factura' ? "list-button-active" : "btn-large"}/>
    //   <Button onClick={()=>setActive('reseña')} content="Mis reseñas" color={active==='reseña' ? 'black-50' : "white-50"} className={active==='reseña' ? "list-button-active" : "btn-large"}/>
    //   <Button onClick={()=>setActive('referencia')} content="Mis referencias" color={active==='referencia' ? 'black-50' : "white-50"} className={active==='referencia' ? "list-button-active" : "btn-large"}/>  
    //   <Button onClick={()=>setActive('saldo')} content="Mi saldo" color={active==='saldo' ? 'black-50' : "white-50"} className={active==='saldo' ? "list-button-active" : "btn-large"}/>  */}
    //   <Button onClick={()=>handleLogoutClick()} content="Cerrar Sesión" color={active==='cerrar' ? 'black-50' : "white-50"} className={active==='cerrar' ? "list-button-active" : "btn-large"}/>  
        
    //     {/*<Button onClick={()=>setActive('disputas')} content="Mis disputas" color={active==='disputas' ? 'black-50' : "white-50"} className={active==='disputas' ? "list-button-active" : "btn-normal"}/>*/}
    // </div>


    // <div className="list-button" role='list-button'>
    //   <div className="list-button-body">
    //     <ButtonIcon
    //       content="Mi perfil" 
    //       onClick={()=>setActive('perfil')} 
    //       Icon={active==='perfil'? <Profile /> : <ProfileBlack/>} 
    //       size="extra-small" 
    //       color={active==='perfil' ? 'black-50' : "white-50"}
    //       className={active==='perfil' ? "list-button-active" : "list-button-desactive"}
    //     />
    //   </div>

    //   <div className="list-button-footer">
    //     <ButtonIcon
    //       content="Ayuda" 
    //       onClick={handleWhatsapp} 
    //       Icon={active==='ayuda' ?<HelpCircleWhite/> : <HelpCircleBlack />} 
    //       size="extra-small" 
    //       color={active==='ayuda' ? 'black-50' : "white-50"}
    //       className={active==='ayuda' ? "list-button-active" : "list-button-desactive"}
    //     />
    //     <ButtonIcon
    //       content="Cerrar Sesión" 
    //       onClick={()=>handleLogoutClick()} 
    //       Icon={active==='ayuda' ? <LogoutWhite/> : <Logout />} 
    //       size="extra-small" 
    //       color={active==='cerrar-sesion' ? 'black-50' : "white-50"}
    //       className={active==='cerrar-sesion' ? "list-button-active" : "list-button-desactive"}
    //     />
    //   </div>
    // </div>
  );
};
