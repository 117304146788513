export const GetCurrency = (label: string) => {
    switch (label) {
        case 'Dólares':
            return 'USD $';
        case 'Soles':
            return 'PEN S/.';
        case 'Euro':
            return 'EUR €';
        default: 
        return 'USD $';
    }
}

export const currencyUtils = (label: string) => {
    switch (label) {
        case 'Dólares':
            return '$';
        case 'Soles':
            return 'S/.';
        case 'Euro':
            return '€';
        default: 
        return '$';
    }
}