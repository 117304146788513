import { httpsCallable } from "firebase/functions";
import { Functions } from "../firebaseConfig/FirebaseConfig";

export const sendUserMessageNotification = async (phone: string, userName: string, companyName: string) => {

    const message = `Hola *${userName}*,
💬 La agencia ${companyName} te ha enviado un nuevo mensaje.
Puedes verlo aquí 👇.`;
    // console.log("sendUserMessageNotification", phone, message);
    const sendWhatsapp = httpsCallable(Functions, "sendWhatsapp");
    return sendWhatsapp({
        phone: phone,
        message: message,
    }).then((res) => {
        return res;
    }).catch((err) => {
        console.log("🚀 ~ file: Whatsapp.service.ts:17 ~ sendUserMessageNotification ~ err:", err)
        return err;
    });
}

export const sendProviderMessageNotification = async (phone: string, userName: string, companyName: string) => {
      const message = `Hola ${companyName},
💬 El usuario ${userName} te ha enviado un mensaje.
Puedes verlo aquí 👇.`;
      
    // console.log("sendProviderMessageNotification", phone, message);

    const sendWhatsapp = httpsCallable(Functions, "sendWhatsapp");
    return sendWhatsapp({
        phone: phone,
        message: message,
    }).then((res) => {
        return res;
    }).catch((err) => {
        console.log("🚀 ~ file: Whatsapp.service.ts:36 ~ sendProviderMessageNotification ~ err:", err)
        return err;
    });
  }