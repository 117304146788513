import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type EditWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const EditWhite: React.FC<EditWhiteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/edit_white.svg"}  alt=""  />
    </div>
  );
};
