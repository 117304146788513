import React from "react";

type ContainerBlackProps = React.HTMLAttributes<HTMLDivElement> & {urlImg: string};

export const ContainerBlack: React.FC<ContainerBlackProps> = ({ urlImg, ...props }) => {
  return (
    <div {...props}>
      <img src={urlImg} alt=""/>
    </div>
  );
};