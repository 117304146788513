import React from "react";
import { Button } from "../..";
import { ArrivalControlChannel } from "../../../interfaces/ArrivalControlChannelInterface";
import { Dropdown } from "../../Dropdown/Dropdown";

import './ChannelControl.css';

interface ChannelControlProps {
    controlChannel: string[];
    arrivalControlChannel: ArrivalControlChannel;
    setArrivalControlChannel: React.Dispatch<React.SetStateAction<ArrivalControlChannel>>;
    disabled?: boolean;
}

export const ChannelControl: React.FC<ChannelControlProps> = ( {
    arrivalControlChannel,
    controlChannel,
    setArrivalControlChannel,
    disabled = false

}) => {

    const handleControlChannel = (value: string) => {
        setArrivalControlChannel((prev:any) => ({
            ...prev,
            controlChannel: value
        }))
    }

    return (
        <div className='channelControl-main box-shadow-card' role='channelRole'>
            <div className='channelControl-colorText paragraph-header'>Canal de control</div>
            <div className='channelControl-colorText paragraph'>La asignación del canal determina el tipo de control al que se sujetarán tus mercancías.</div>
            <Dropdown 
                title='Canal de control'
                elements={controlChannel}
                setValue={(e:any) => {handleControlChannel(e)}}
                value={arrivalControlChannel.controlChannel}
                roleClick='channelClickRole'
                roleText='channelDropdownRole'
                disabled={disabled}
            /> 

            {arrivalControlChannel.controlChannel === "Canal verde" && <div className='channelControl-row'>
                <div className='channelControl-status-green'></div>
                <div className='channelControl-colorText paragraph'>
                    <span className="paragraph-header">Canal verde: </span> 
                    no requiere ninguna revisión documentaria de la 
                    declaración ni el reconocimiento físico de la mercancía.
                </div>
            </div>}
            {arrivalControlChannel.controlChannel === "Canal naranja" && <div className='channelControl-row'>
                <div className='channelControl-status-amber'></div>
                <div className='channelControl-colorText paragraph'>
                    <span className="paragraph-header">Canal naranja: </span>
                    la declaración es sometida a revisión documentaria.
                </div>
            </div>}
            {arrivalControlChannel.controlChannel === "Canal rojo" && <div className='channelControl-row'>
                <div className='channelControl-status-red'></div>
                <div className='channelControl-colorText paragraph'>
                    <span className="paragraph-header">Canal rojo: </span>
                    la mercancía se encuentra sujeta a reconocimiento 
                    físico y documentario.
                </div>
            </div>}
            {/* <Button
                content='Canal de control no asignado todavía'
                size='small'
                color='white-50'
            />  */}
        </div>
    )
}