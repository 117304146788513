import { FirebaseApp} from "../firebaseConfig/FirebaseConfig";

import { formatDistanceToNowStrict } from 'date-fns'

import { GetIpHost } from "./GetIp.service";
import { AddMinutesToDate } from "../utils/AddMinutesToDate";
import { GenerateEmailCode } from "../utils/GenerateEmailCode";
import { GetBrowsetAgent } from "../utils/BrowserDetec";
import { CodeValidationInterface } from "../interfaces/EmailValidationInterface";
import { CreateCodeValidation, GetCodeValidationActive, GetCodeValidationByCode, UpdateCodeValidation } from "./CodeValidation.service";

import { 
    getAuth, 
    updateProfile, 
    createUserWithEmailAndPassword, 
    GoogleAuthProvider, 
    signInWithPopup, 
    FacebookAuthProvider, 
    signInWithEmailAndPassword, 
    setPersistence,
    browserSessionPersistence,
    browserLocalPersistence,
    signOut,
    sendPasswordResetEmail,
    updatePassword,
    fetchSignInMethodsForEmail,
} from "firebase/auth";

// importando interfaces
import { User } from "../interfaces/User";import { AUTH_LOGIN, AUTH_MODAL } from "../Consts/BaseUrl";
;

const auth = getAuth(FirebaseApp);

const CheckEmailRegister = (email: string) => {
    return new Promise((resolve, rejects) => {
        fetchSignInMethodsForEmail(auth, email).then( async (response) => {
            resolve(response.length > 0);
        }).catch((error) => {
            console.log("🚀 ~ file: Auth.service.ts:31 ~ fetchSignInMethodsForEmail ~ error:", error)
            rejects(error);
        })
    });
}

//  
 const RegisterWithEmail=  (email: string, password: string) => {
    return new Promise((resolve, reject) => {
        createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
            // console.log("RegisterUser", userCredential);
            resolve(userCredential.user);
        }).catch((error) => {
            console.log("RegisterUser => error:", error.code)
            reject(error);
        });
    });   
}

const UpdateProfileUser = (user: User) => {
    const auth = getAuth();
    let currentUser = auth.currentUser;
    return new Promise((resolve, reject) => {
        if(currentUser) {
            updateProfile(currentUser, {
                displayName: user.userName, photoURL: user.photoUrl
            }).then(() => {
                resolve(true);
            }).catch((error) => {
                console.log(error)
                reject(false);
            });
        }
    });
}

const RegisterWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return new Promise((resolve, reject) => {
        signInWithPopup(auth, provider)
        .then((userCredential) => {
            // console.log("🚀 ~ file: Auth.service.ts ~ line 57 ~ .then ~ userCredential", userCredential);
             // This gives you a Google Access Token. You can use it to access the Google API.
            const credential:any = GoogleAuthProvider.credentialFromResult(userCredential);
            const token = credential.accessToken;
            // console.log("🚀 ~ file: Auth.service.ts ~ line 61 ~ .then ~ token", token)
            resolve(userCredential.user);
        })
        .catch((error) => {
            console.log("RegisterWithGoogle => error:", error.code)
            reject(error);
        });
    });
}

const RegisterWithFacebook = () => {
    return new Promise((resolve, reject) => {
        const provider = new FacebookAuthProvider();
        provider.addScope('public_profile');
        provider.addScope('email');
        provider.setCustomParameters({
            'display': 'popup'
        });
        signInWithPopup(auth, provider)
        .then((userCredential) => {
            resolve(userCredential.user);
        })
        .catch((error) => {
            console.log("RegisterWithFacebook => error:", error.code)
            reject(error);
        });
          
    });
}

const LoginWithEmail = (email: string, password: string, remember: boolean) => {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
        setPersistence(auth, remember ? browserLocalPersistence : browserSessionPersistence)
        .then(() => {
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // console.log("service LoginWithEmail", user);
                resolve(user);
            })
            .catch((error) => {
                console.log(error)
                const errorCode = error.code;
                const errorMessage = error.message;
                reject(error);
            });
        })
        .catch((error) => {
            // Handle Errors here.
            console.log(error)
            const errorCode = error.code;
            const errorMessage = error.message;
            reject(error);
        });
    });
} 

const PasswordResetByEmail = (email: string) => {
    const auth = getAuth();
    return new Promise((resolve, reject) => {

        sendPasswordResetEmail(auth, email, {url: `${window.location.href}?${AUTH_MODAL}=${AUTH_LOGIN}`})
        .then(() => {
            resolve(true);
        }).catch((error) => {
            console.log(error);
            reject(false);
        });
    })
}

const PasswordReset = (password: string) => {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if(user) {
        return new Promise((resolve, reject) => {
            updatePassword(user, password).then(() => {
                resolve(true);
              }).catch((error) => {
                console.log(error);
                reject(false);
              });
        })
    }
}

const LogOutUser = async () => {
    const auth = await getAuth();
    return new Promise((resolve, reject) => {
        signOut(auth).then(() => {
            resolve(true);
        }).catch((error) => {
            console.log(error);
            reject(false);
        });
    })
}


const sendCodeVerification = async (type: "email" | "phone", valueType: string) => {
    const code = GenerateEmailCode();
    const codeValidation: CodeValidationInterface = {
        status: true,
        code: code,
        type: type,
        valueType: valueType,
        ip: await GetIpHost(),
        browser: await GetBrowsetAgent(),
        validDate: AddMinutesToDate(new Date(), 10),
        createdAt: new Date(),
        updatedAt: new Date(),
    };

    try {
        const codeValidations = await GetCodeValidationActive(type, valueType);
        if(codeValidations.length > 0) {
            const codeInDate = codeValidations.filter((e) => Number(formatDistanceToNowStrict(e.validDate.toDate(), {unit: "minute"}).split(" ")[0]) <=10 )
            codeInDate.forEach(async (c) => {
                await UpdateCodeValidation(c.id ? c.id : '', {status: false});
            });
        }

        const response = await CreateCodeValidation(codeValidation);
        if(response) {
            return response
        } else {
            return false;
        }
    } catch (error) {
        console.log("🚀 ~ file: Auth.service.ts:212 ~ sendEmailCodeVerification ~ error:", error)
        return error;
    }
   
}

const ValidateRegisterCode = async (type: "email" | "phone", valueType: string, code: string) => {
    // console.log("🚀 ~ file: Auth.service.ts:225 ~ ValidateRegisterCode ~ type:", type, valueType, code)
    const emailValidations = await GetCodeValidationActive(type, valueType);
    const valitdCode = emailValidations.filter((e) => e.code === code && Number(formatDistanceToNowStrict(e.validDate.toDate(), {unit: "minute"}).split(" ")[0]) <=10 )
    return valitdCode.length > 0;
}

const DisableCodeRegister= async (type: "email" | "phone", valueType: string, code: string) => {
    try {
        const responseCodes = await GetCodeValidationByCode(type, valueType, code);
        return await Promise.all(responseCodes.map(async (c) => {
                await UpdateCodeValidation(c.id ? c.id : '', {status: false});
        }));
    } catch (error) {
        console.log("🚀 ~ file: Auth.service.ts:246 ~ DisableCodeEmail ~ error:", error)
        return error;
    }
 }

const authService = {
    RegisterWithEmail,
    RegisterWithGoogle,
    RegisterWithFacebook,
    LoginWithEmail,
    UpdateProfileUser,
    LogOutUser,
    PasswordResetByEmail,
    PasswordReset,
    CheckEmailRegister,
    sendCodeVerification,
    ValidateRegisterCode,
    DisableCodeRegister
};

export default authService;
