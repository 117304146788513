import React from "react";

import './LoaderShimmerService.css'

export const LoaderShimmerService: React.FC<{}> = ({...props}) => {
    return (
        <div className="card">
            <div className="loader-shimmer-service-banner shimmer-animation"></div>
            <div className="loader-shimmer-service-content">
                <div className="loader-shimmer-service-header">
                    <div className="loader-shimmer-service-info shimmer-animation"></div>
                    <div className="loader-shimmer-service-info shimmer-animation"></div>
                </div>
                <div className="loader-shimmer-service-footer">
                    <div className="loader-shimmer-service-action shimmer-animation"></div>
                    <div className="loader-shimmer-service-action shimmer-animation"></div>
                    <div className="loader-shimmer-service-action shimmer-animation"></div>
                </div>
            </div>
        </div>
    )
}