import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type DownDropdownProps = React.HTMLAttributes<HTMLDivElement> & {};

export const DownDropdown: React.FC<DownDropdownProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/downDropdown.svg"}  alt=""  />
    </div>
  );
};