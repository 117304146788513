import React, { useState, useEffect } from "react";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import { useDispatch, useSelector } from "react-redux";

//importando componentes
import { Menu } from "../../../components/Menu/Menu";
import { DetailProfile } from "./DetailProfile/DetailProfile";

import { ModalInfluencer } from "../../../components/Modal/ModalInfluencer/ModalInfluencer";
import { ModalThanks } from "../../../components/Modal/ModalThanks/ModalThanks";

// importando const

// Importando Slice
import { GetUserByUID } from "../../../Slices/User";

// Store
import type { AppDispatch } from "../../../Store/Store";

// importando estilos
import authService from "../../../Services/Auth.service";
import { Loader } from "../../../components/Loader/Loader";
import { UserCompany } from "../../../interfaces/UserCompany";
import { GetUserCompanyByUserId } from "../../../Services/UserCompany.service";
import { fetchToken } from "../../../firebaseConfig/FirebaseConfig";
import { unRegisterTopicService } from "../../../Services/Topic.service";
import { User } from "../../../interfaces/User";
import { CardContent } from "../../../components/CardContent/CardContent";
import { UserCard } from "../../../components/UserCard/UserCard";

import './ProfilePage.css';
import { SuscriptionPage } from "./SuscriptionPage/SuscriptionPage";
import { Receipts } from "../../../components/Receipts/Receipts";
interface ProfilePageProps {
}

export const ProfilePage: React.FC<ProfilePageProps> = () =>{

    // let navigation = useNavigate();

    const dispatch = useDispatch<AppDispatch>();

    const {userDetail} = useSelector((state: any) => state.user);
    const {CompanyDetail} = useSelector((state: any) => state.company);

    const [showModal, setShowModal] = useState(false);
    const [showThanks, setShowThanks] = useState(false);
    const [activeContentProfile, setActiveContentProfile] = useState('perfil');
    const [supplier, setSupplier] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [showVerify, setShowVerify] = useState(false);

    const [userCompany, setUserCompany] = useState<UserCompany>({
        itNumber: '',
        name: '',
        position: '',
        status: true,
        typeIt: '',
        userId: '',
        createdAt: new Date(),
        updatedAt: new Date(),
    });

    const [password, setPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');

    const [userInfo, setUserInfo] = useState<User>();

    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
    });


    const handleClickCancel = () => {
        setShowModal(false);
    }

    const handleClickComplete = () => {
        setShowThanks(true);
        setShowModal(false);
    }

    const handleBack = () => {
        setShowModal(true);
        setShowThanks(false);
    }

    // method to logout user and delete push token
    const handleLogout = async () => {
        setLoading(true);
        try {
            fetchToken(async (token) => {
                const repsonse = await unRegisterTopicService(token);
                // console.log("🚀 ~ file: ProfileProvider.tsx:154 ~ fetchToken ~ repsonse:", repsonse); 
            });

            setTimeout(async () => {
                const response = await authService.LogOutUser();
                if(response) {
                    setLoading(false);
                    window.location.href = window.location.origin+'/';
                }
            }, 1500);
        } catch (error) {
            console.log("🚀 ~ file: ProfileProvider.tsx:155 ~ handleLogout ~ error:", error);
        }
    }

    const handleWhatsapp = () => {
        window.open(`https://api.whatsapp.com/send/?phone=51959340149&text=¡Hola! Mi nombre es ${userDetail.name} ${userDetail.lastName} y necesito ayuda`)
    }

    const getUserCompany = async(userId: string) => {
        setLoading(true)
        try {
            const response:any = await GetUserCompanyByUserId(userId);
            setLoading(false);
            if(response) {
                setUserCompany(response);
                setUserInfo((prev:any) => ({
                    ...prev, 
                    businessName: response.name
                }));
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const getUserDetail = async (UID: string) => {
        setLoading(true)
        dispatch(GetUserByUID(UID))
        .unwrap()
        .then((response) => {
            setLoading(false);
            setUserInfo(response);
            getUserCompany(response.id)
            setLoading(false);
        })
        .catch((error) => {
            console.log("🚀 ~ file: Orders.tsx ~ line 264 ~ getUser ~ error", error)
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        const auth = getAuth();
        onAuthStateChanged(auth, (userCurrent) => {
            if(userCurrent) {
                setUser(userCurrent);
                getUserDetail(userCurrent.uid);
                setLoading(false)
                
            }
            setLoading(false);
        });
    }, []);

    return (
        <div className="profile">
            <div className="profile-content">
                <div className="profile-left">
                    <CardContent>
                        { !loading && userInfo &&
                        <UserCard 
                            user={userInfo}
                            myProfile={true}
                            showVerify={showVerify}
                            setShowVerify={setShowVerify}
                        />}

                        <Menu 
                            active = {activeContentProfile} 
                            setActive={setActiveContentProfile} 
                            handleLogoutClick={handleLogout}
                            handleWhatsapp={handleWhatsapp}
                        />
                    </CardContent>
                </div>

                <div className="profile-right">
                {!loading && userInfo ? 
                    <>
                        { activeContentProfile === 'perfil' &&
                            <DetailProfile 
                                updateData={()=>getUserDetail(userInfo.UID?userInfo.UID:'')} 
                            />}
                        {activeContentProfile === 'suscripcion' &&
                            <SuscriptionPage/>}
                        {activeContentProfile === 'recibos' &&
                            <Receipts />
                        }
                    </>
                    :
                    <Loader />}
                </div>
            </div>

            <ModalInfluencer 
                show={showModal}
                setShow={setShowModal}
                handleClickCancel={handleClickCancel}
                handleClickComplete={handleClickComplete}/>

            <ModalThanks show={showThanks}
                         setShow={setShowThanks}
                         handleBack={handleBack} />
        </div>
    );
}