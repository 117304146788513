import React, { useCallback, useEffect, useState } from "react";

import ReactGA from "react-ga4";


import './Quoter.css';


import { TextField } from "../TextField/TextField";
import { RequestQuoteInterface } from "../../interfaces/RequestQuote";
import { QuotationDataError } from "../../interfaces/QuotationDataError";
import { Button } from "../Button/Button";
import { ValidateField } from "../../utils/ValidateField";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ImportTimeline } from "../ImportTimeline/ImportTimeline";
import { QuoteTransport } from "../QuoteTransport/QuoteTransport";
import { QuoteCustoms } from "../QuoteCustoms/QuoteCustoms";
import { QuoteResume } from "../QuoteResume/QuoteResume";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CreateRequestQuote, UpdateRequestQuote } from "../../Services/RequestQuote.service";
import { CreateOperation } from "../../Services/Operation.service";
import { OperationInterface } from "../../interfaces/Operation";
import { GetCurrency } from "../../utils/GetCurrency";
import { OPERATIONS, QUOTATION, REQUEST_QUOTE, STORAGE_ASSETS } from "../../Consts/BaseUrl";
import { ServiceInterface } from "../../interfaces/Service";


interface QuoterProps {
    showModal: boolean; // show modal
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;  // set show modal
    requestQuote: RequestQuoteInterface; // request quote data
    setRequestQuote: React.Dispatch<React.SetStateAction<RequestQuoteInterface>>; // set requet quote data
    setTitle: React.Dispatch<React.SetStateAction<string>>; // set title
    setShowSubtitle: React.Dispatch<React.SetStateAction<boolean>>; // set show sub title
    setShowDescription: React.Dispatch<React.SetStateAction<boolean>>; // set show description
    setSection: React.Dispatch<React.SetStateAction<'QUESTION' | 'QUOTER' | 'INSTRUCTION' | 'RECOMENDATION'>>; // set section
    isUpdateRequestQuote?: boolean; // mode update request quote
    fromPage?: "home" |"chat" | "mainService" | "serviceDetail";
    setTemOperationId: React.Dispatch<React.SetStateAction<string>>; // set temporal operation id
    setShowModalConfirm: React.Dispatch<React.SetStateAction<boolean>>; // set show modal confirmation
    updateData?: () => void; // method to update componente
    hiddenConfirm?: boolean; // no redirect to confirm
    serviceSelected?: ServiceInterface; // service selected
    setServiceSelected?: React.Dispatch<React.SetStateAction<ServiceInterface | undefined>>; // set service selected
}

export const Quoter: React.FC<QuoterProps> = ({
    showModal,
    requestQuote,
    setRequestQuote,
    setTitle,
    setShowSubtitle,
    setShowDescription,
    setSection,
    isUpdateRequestQuote=false,
    setShowModal,
    fromPage="home",

    setTemOperationId,
    setShowModalConfirm,
    updateData = ()=>{},
    hiddenConfirm,
    serviceSelected,
    setServiceSelected = () => {}
}) => {

    // natigation
    const navigation = useNavigate();
    // location
    const location = useLocation();
    // searchParams
    const [searchParams] = useSearchParams();

    // TODO: selectors 
    // redux user
    const {userDetail} = useSelector((state: any) => state.user);

    // loading 
    const [loading, setLoading] = useState<boolean>(false);
    
   // MAGIC number to show phases
    const [stepQuote, setStepQuote] = useState<number>(0); // 0 title, 1 transport, 2 customs and 3 resume
    // UseState para contar caracteres del titulo
    const [countWord, setCoundWord] = useState<number>(0);
    // useState to know if user is loginig
    const [userIsLogin, setUserIsLogin] = useState<boolean>(false);
    // variable error requestQute fields
    const [requestQuoteError, setRequestQuoteError] = useState<QuotationDataError>({
        title: {
            message: '',
            status: false,
        },
        transportation: {
            type: {
                message: '',
                status: false,
            },
            origin: {
                message: '',
                status: false,
            },
            destination: {
                message: '',
                status: false,
            },
            warehouseOrigin: {
                message: '',
                status: false,
            },
            warehouseDestination: {
                message: '',
                status: false,
            }, 
            typeContainer: {
                message: '',
                status: false,
            },
        },
        customs: {
            regime: {
                message: '',
                status: false,
            },
            incoterm: {
                message: '',
                status: false,
            },
            value: {
                message: '',
                status: false,
            },
            coin: {
                message: '',
                status: false,
            },
            insurance: {
                message: '',
                status: false,
            },
            purchaseLink: {
                message: '',
                status: false,
            }
        }
    });


    // method to change title
    const handleTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if(value.length<=42) {
            setRequestQuote((prev)=>({...prev, title: value}));
            // setQuoteTitle(e.target.value);
            setCoundWord((value.length));
        } 
    }, [requestQuote.title]);

    // metodo para validar el titulo
    const validateTitleField = (): boolean => {
        let error = false;
        error = ValidateField(requestQuote.title, "required");
        setRequestQuoteError((prev) => ({
            ...prev,
            title: {
                status: ValidateField(requestQuote.title, "required"),
                message: 'Ingrese un título para la operación'
            }
        }))
        return error;
    }

    // method to validate lcl fields
    const validateLclFields = (): boolean =>  {
        let error = false;
        
        if(!requestQuote.transport.lcl?.typeMeasure || requestQuote.transport.lcl?.typeMeasure === "volumen") { 
         
            error = ValidateField(requestQuote.transport.lcl?.unitWeight, 'required') ||
            ValidateField(requestQuote.transport.lcl?.unitVolume, 'required') ||
            ValidateField(requestQuote.transport.lcl?.cargoDescription, 'required');

            error = error || ValidateField(requestQuote.transport.lcl?.totalWeight, "number")  ||
            ValidateField(requestQuote.transport.lcl?.totalVolume, "number") 

            setRequestQuoteError((prev)=> ({
                ...prev,
               transportation: {
                    ...prev.transportation,
                    lcl: {
                        totalWeight: {
                            message: 'Ingrese peso total',
                            status: ValidateField(requestQuote.transport.lcl?.totalWeight, "number")  
                        },
                        unitWeight: {
                            message: 'Seleccione una unidad de peso',
                            status: ValidateField(requestQuote.transport.lcl?.unitWeight,'required'),
                        },
                        totalVolume: {
                            message: 'Ingrese volumen total',
                            status: ValidateField(requestQuote.transport.lcl?.totalVolume, "number") 
                        },
                        unitVolume: {
                            message: 'Seleccione una unidad de volumen',
                            status: ValidateField(requestQuote.transport.lcl?.unitVolume,'required'),
                        },
                        cargoDescription: {
                            message: 'Seleccione una descripción de la carga',
                            status: ValidateField(requestQuote.transport.lcl?.cargoDescription,'required')
                        },
                        packages: {
                            message: 'Complete los campo(s) vacio(s).',
                            status: error
                        }
                    }
               }
            }));
        } else if(requestQuote.transport.lcl?.typeMeasure === "paquestes") {
      
            requestQuote.transport.lcl.packages.forEach(p => {
                error = ValidateField(p.type, 'required') || ValidateField(p.quantity, 'required') || ValidateField(p.weight, 'required') 
                || ValidateField(p.unitWeight, 'required') || ValidateField(p.height, "required") ||
                ValidateField(p.width, 'required') || ValidateField(p.tall, 'required') || ValidateField(p.unitMeasurement, 'required');
            }); 

            setRequestQuoteError((prev:any)=> ({
                ...prev,
               transportation: {
                    ...prev.transportation,
                    lcl: {
                        ...prev.transportation.lcl,
                        packages: {
                            message: 'Complete los campo(s) vacio(s).',
                            status: error,
                        }
                    }
               }
            }));
        }
        return error;
    }

    // method to validate fcl fields
    const validateFclFields = (): boolean => {
        let error = false;
        error = ValidateField(requestQuote.transport.fcl, "array");
        setRequestQuoteError((prev) => ({
            ...prev,
            transportation: {
                ...prev.transportation,
                fcl: {
                    message: 'No hay ningún contenedor agregado todavía.',
                    status: ValidateField(requestQuote.transport.fcl, "array")
                }
            }
        }));
        return error;
    }

    // method to validate LclAir fields
    const validateLclAirFields = (): boolean => {
        let error = false

        if(requestQuote.transport.lclAir?.packingType === "paquetes") {
            error = error ||  ValidateField(requestQuote.transport.lclAir?.packages, "array");
        } 
        error = error || ValidateField(requestQuote.transport.lclAir?.weight, "number") || 
        ValidateField(requestQuote.transport.lclAir?.volume, "number");


        setRequestQuoteError((prev) => ({
            ...prev,
            transportation: {
                ...prev.transportation,
                lclAir: {
                    list:  {
                        message: 'No hay ningún bulto agregado todavía.',
                        status: ValidateField(requestQuote.transport.lclAir?.packages, "array"),
                    },
                    items: {
                        message: "Ingreso datos correctos",
                        status:  ValidateField(requestQuote.transport.lclAir?.weight, "number") || ValidateField(requestQuote.transport.lclAir?.volume, "number")
                    }
                }
            }
        }));
        return error;
    }

    // method to transport fields
    const validateTransportFields = ():boolean => {
        let error = false;
        error = ValidateField(requestQuote.transport.type, 'notUndefined') || 
        !(requestQuote.transport.typeContainer?.fcl || requestQuote.transport.typeContainer?.lcl || requestQuote.transport.typeContainer?.lclAir );

        if(requestQuote.serviceTypeId === "courier" ) {
            let tmpErr = ValidateField(requestQuote.transport.warehouseOrigin, 'notUndefined') || ValidateField(requestQuote.transport.warehouseDestination, 'notUndefined');
            error = error || tmpErr;
        } else {
            error = error || ValidateField(requestQuote.transport.origin, 'notUndefined') || 
            ValidateField(requestQuote.transport.destination, 'notUndefined')
        }

        setRequestQuoteError((prev) => ({
            ...prev,
           transportation: {
            ...prev.transportation,
            type: {
                message: 'Seleccione un tipo de transporte',
                status: ValidateField(requestQuote.transport.type, 'notUndefined')
            },
            origin: {
                message: 'Seleccione un origen',
                status: ValidateField(requestQuote.transport.origin, 'notUndefined')
            },
            destination: {
                message: 'Seleccione un destino',
                status: ValidateField(requestQuote.transport.destination, 'notUndefined')
            },
            warehouseOrigin: {
                message: 'Seleccione un almacén de origen',
                status: ValidateField(requestQuote.transport.warehouseOrigin, 'notUndefined')
            },
            warehouseDestination: {
                message: 'Seleccione un almacén de destino',
                status: ValidateField(requestQuote.transport.warehouseDestination, 'notUndefined')
            },
           }
        }));

        if(requestQuote.transport.typeContainer?.lcl) {
            let tmpErr = validateLclFields();
            error = error || tmpErr;
        }
        if (requestQuote.transport.typeContainer?.fcl) {
            let tmpErr = validateFclFields()
            error = error || tmpErr;
        }
        if(requestQuote.transport.typeContainer?.lclAir) {
           
            let tmpErr = validateLclAirFields();
            
            error = error || tmpErr;
        }
        return error;
    }

    // method to validate order
    const validateOrderClick = (): boolean => {
        let error = false;
        return error;
    }

    // method to validate customs fields
    const validateCustomsFields = () : boolean => {
        let error = false;
        error = ValidateField(requestQuote.customs.regime, 'notUndefined') || ValidateField(requestQuote.customs.incoterm, 'required') || 
        ValidateField(requestQuote.customs.value, 'number') || ValidateField(requestQuote.customs.coin, 'required');

        if(requestQuote.serviceTypeId !== "courier") {
            error = error || ValidateField(requestQuote.customs.insurance, 'boolean'); 
        } 
        
        setRequestQuoteError((prev) => ({
            ...prev,
            customs: {
                ...prev.customs,
                value: {
                    message: 'Ingrese un valor',
                    status: ValidateField(requestQuote.customs.value, 'number')
                },
                coin: {
                    message: 'Seleccione una moneda',
                    status: ValidateField(requestQuote.customs.coin,'required')
                },
                incoterm: {
                    message: 'Seleccione un incoterm',
                    status: ValidateField(requestQuote.customs.incoterm,'required')
                },
                insurance: {
                    message: 'Seleccione si desea seguro',
                    status: ValidateField(requestQuote.customs.insurance, 'boolean')
                },
                // purchaseLink: {
                //     message: 'Ingrese un link de compra',
                //     status: ValidateField(requestQuote.customs.purchaseLink, 'notUndefined')
                // }
            }
        }))

        return error;
    }

    // metodo generico para validar los campos
    const ValidateFields = (): boolean => {
        switch (stepQuote) {
            case 0:
                return validateTitleField();
            case 1:
                return requestQuote.serviceTypeId === 'agenciamiento-de-aduana' || 
                       requestQuote.serviceTypeId === 'courier'
                        ? validateTransportFields() : validateOrderClick();
            case 2:
                return validateCustomsFields();
            default:
                return false;
        }
    }
    
    // method to return previus step
    const handleReturnClick = () => {
        if(stepQuote>0) {
            if(stepQuote === 1) {
                setShowSubtitle(false);
                setShowDescription(true);
            }
            setStepQuote((prev) => prev - 1);
        } else {
            setStepQuote(0); 
            setTitle('Primero, cuéntanos');
            setSection('QUESTION');
        }
    }

    // method to go next step
    const handleNextClick =  async () => {
        if(!ValidateFields()) { // valid field for phase
            if(stepQuote === 0){
                setTitle(requestQuote.title);
                setShowDescription(false);
                setShowSubtitle(true);
            } else if(stepQuote === 3 ) {
                if(isUpdateRequestQuote) {
                    saveDraft();
                    updateData();
                    setShowModal(false);
                } else {
                    await saveRequestQuote();
                }
                return;
            }

            if(stepQuote<3) {
                setStepQuote((prev) => prev +1);
            }

            if(userIsLogin && stepQuote>0 && stepQuote<3) {
                saveDraft();
            }
        }
    }

    // method to navigate to send request other providers page
    const navigateToSendRequest = async (operationId: string) => {
    //    console.log("🚀 ~ file: Quoter.tsx:353 ~ navigateToSendRequest ~ operationId:", operationId)
       navigation(`/${OPERATIONS}/${REQUEST_QUOTE}/${operationId}${!hiddenConfirm ? "?sendOtherQuote=provider": ""}`, {replace: true});
    }

    const navigateToQuotation = async (operationId: string, requestQuoteId: string) => {
        // console.log("🚀 ~ file: Quoter.tsx:360 ~ navigateToQuotation ~ operationId:", operationId)
        navigation(`/${OPERATIONS}/${operationId}/${requestQuoteId}/${QUOTATION}/unknown`, {replace: true});
     }

    // method to create operation
    const createOperationWithQuote = async (rq: RequestQuoteInterface) => {
        let operation: OperationInterface = {
            active: true,
            title: rq.title,
            type: rq?.category ? rq.category :'',
            status: {
                requestQuote: true,
                order: false
            },
            requestQuotes: serviceSelected? [{
                id: rq?.id ? rq.id : '',
                companyName: serviceSelected.companyName,
                companyId: serviceSelected.companyId,
                serviceRating: serviceSelected.rating,
                serviceType: serviceSelected.serviceType,
                serviceTypeLabel: serviceSelected.serviceTypeLabel,
                price: rq.price ? rq.price : 0,
                quotationId: '',
                numRequestQuote: 0,
                serviceId: serviceSelected?.id ? serviceSelected.id : '',
                active: serviceSelected.active,
                createdAt: new Date(),
                updatedAt: new Date(),
                city: serviceSelected.city,
                rating: serviceSelected.rating,
                country: {
                  alpha2Code: serviceSelected.country.alpha2Code,
                  imgFlag: serviceSelected.country.imgFlag
                },
                observation: '',
                numServiceComments: serviceSelected.numComments,
              }] : [],
            paid: false,
            price: 0,
            orderId: '',
            requestQuoteId:  rq.id? rq.id: '',
            orders: [],
            requestQuoteType: rq?.serviceTypeId? rq.serviceTypeId : '',
            createdAt: new Date(),
            updatedAt: new Date(),
            numOperation: 0,
            userId: userDetail.id,
            currency: GetCurrency(rq.customs?.coin ? rq.customs.coin: '')
        }

        try {
            const responseOperation:any = await CreateOperation(operation);
            // console.log("🚀 ~ file: Quoter.tsx:407 ~ createOperationWithQuote ~ responseOperation:", responseOperation)
            const responseRQ = await UpdateRequestQuote({
                ...rq,
                id: rq.id? rq.id: '',
                operationId: responseOperation.id,
                status: "published",
            });     
            setTimeout(() => {
                // cleanFields();
                setLoading(false);
                setShowModal(false)
                rq.companyId && rq.companyId.length>0 && 
                rq.companyName && rq.companyName.length>0 ? 
                (!hiddenConfirm && rq.serviceTypeId !== "courier" ? showQuestionSend(responseOperation.id) : navigateToQuotation(responseOperation.id, rq.id || '')) :
                navigateToSendRequest(responseOperation.id);
            }, 1500);
         
        } catch (error) {
            console.log("🚀 ~ file: Quoter.tsx:366 ~ createOperationWithQuote ~ error:", error)
            toast.error("Error al crear la operación, intente de nuevo más tarde");
        }
    }

    // method to show modal question
    const showQuestionSend = (operationId: string) => {
        setTemOperationId(operationId);
        setShowModalConfirm(true);
        setShowModal(false);
    }

    // method to save requestQuote
    const saveRequestQuote = async () => {
        setLoading(true);
       let tmpRq: RequestQuoteInterface = {...requestQuote};

        if(userIsLogin) {
            if(userDetail && userDetail.userType.includes('user') ) {
                tmpRq = {
                    ...tmpRq,
                    // status: "published",
                    autogenerated: false,
                    customs: {
                        ...tmpRq.customs,
                        insurance: tmpRq.serviceTypeId === "courier" ? false : tmpRq.customs.insurance,
                    },
                    user: {
                        id: userDetail.id,
                        name: userDetail.name+" "+userDetail.lastName,
                        itNumber: userDetail.itNumber? userDetail.itNumber : 0,
                        typeIt: userDetail.typeIt? userDetail.typeIt : '',
                        numOperations: userDetail.numOperations? userDetail.numOperations : 0,
                        phone: (userDetail.phoneCode ? userDetail.phoneCode : "+51")+""+(userDetail.phone ? userDetail.phone : ""),
                        rating: userDetail.rating? userDetail.rating: 0,
                    },
                    updatedAt: new Date(),
                }
 
                if(requestQuote.id) { // if have id update requestQuote
                    try {
                        const response = await UpdateRequestQuote(tmpRq);    
                        toast.success("Operación guardada con éxito");
                        if(!requestQuote.operationId || requestQuote.operationId?.length === 0 ) {
                            await createOperationWithQuote(tmpRq);
                        } else {
                            !hiddenConfirm && requestQuote.serviceTypeId !== "courier" && showQuestionSend(requestQuote.operationId);
                            !hiddenConfirm && navigateToSendRequest(requestQuote.operationId);
                        }

                    } catch (error) {
                        console.log("🚀 ~ file: Quoter.tsx:398 ~ saveRequestQuote ~ error:", error)
                        toast.error("Error al actualizar la operación, intente de nuevo más tarde");
                    }
                } else { // create new requestQuote
                    try {
                        toast.info("Guardando operación...");
                        const response:any = await CreateRequestQuote(tmpRq);
                        setRequestQuote((prev) => ({
                            ...prev,
                            id: response.id
                        }) )
                        await createOperationWithQuote({
                            ...tmpRq,
                            id: response.id
                        })
                    
                    } catch (error) {
                        console.log("🚀 ~ file: Quoter.tsx:399 ~ saveRequestQuote ~ error:", error)
                        
                    }
                }
            } else {
                toast.warning("Solo los importadores pueden cotizar en Arkabia 🧐");
                navigation(location.pathname+'?showModal=login');
            }        
        } else {
            navigation('?showModal=register&noRedirect=true&actionModalRequest=sendQuote')
            setLoading(false);
        }
    }
 
    // method to save draft requestQuote
    const saveDraft = async () => {
       
        if(!loading) {
            setLoading(true);
            let tmpRq: RequestQuoteInterface = {
                ...requestQuote,
                // status: "draft",
                phaseDraft: stepQuote,
                user: isUpdateRequestQuote ? requestQuote.user : {
                    id: userDetail.id,
                       name: userDetail.name+" "+userDetail.lastName,
                       itNumber: userDetail.itNumber? userDetail.itNumber : 0,
                       typeIt: userDetail.typeIt? userDetail.typeIt : '',
                       numOperations: userDetail.numOperations? userDetail.numOperations : 0,
                       phone: (userDetail.phoneCode ? userDetail.phoneCode : "+51")+""+(userDetail.phone ? userDetail.phone : ""),
                       rating: userDetail.rating? userDetail.rating: 0,
               },
                updatedAt: new Date(),
            };
        
            try {
                toast.success(isUpdateRequestQuote ? "Actualizando datos" : "Guardando borrador");
                const response:any = requestQuote.id && requestQuote.id.length>0 ? await UpdateRequestQuote(tmpRq) : await CreateRequestQuote(tmpRq);
                if(response && response.id && !requestQuote.id) {
                    setRequestQuote((prev)=>({
                        ...prev,
                        id: response.id,
                    }))
                }
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: Quoter.tsx:454 ~ saveDraft ~ error:", error)
                toast.error("Error al "+(requestQuote.id?"actualizar":"crear")+" la operación, intente de nuevo más tarde");
            }
           
        }
    }

    // handle submit title
    const handleSubmitTitle = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleNextClick();
    }

    const handleServiceSelected = (serviceId: string, companyId: string, companyName: string) => {
        setRequestQuote((prev)=>({
            ...prev,
            companyId: companyId,
            companyName: companyName,
            serviceId: serviceId
        }));
    }

    // method to get if is login or not
    const getUserIfLogin = useCallback(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (userCurrent) => {
            if(userCurrent) {
                setUserIsLogin(true);
            }
        });
    }, []);

    // method to get service plan by serviceId


    
    // methos to load request in step that was saved
    const loadDraft = useCallback(() => {
        setStepQuote(requestQuote.phaseDraft?requestQuote.phaseDraft: 0);
    }, []);

    useEffect(()=>{
        getUserIfLogin();
        loadDraft();
    }, []);

    useEffect(()=>{
        if(searchParams.get('actionModalRequest') && !searchParams.get('showModal')) {
            setStepQuote(3);
            if(!loading) {
                setLoading(true);
                if(userDetail && userDetail.userType.includes('user')) {
                    if(searchParams.get('actionModalRequest') === 'saveDraft') {   
                        // handleDraftClick();
                        navigation(location.pathname);
                        setLoading(false);
                    }
                    if(searchParams.get('actionModalRequest') === 'sendQuote') {
                        console.log('useEffect sendQuote');
                        saveRequestQuote();
                        setLoading(false);                      
                    }       
                } else {
                    toast.warning("Solo los importadores pueden cotizar en Arkabia 🧐");
                    navigation(location.pathname+'?showModal=login');
                } 
            }
        }
    }, [searchParams]);

    useEffect(()=> {
        showModal ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    }, [showModal]);


    useEffect(()=>{
        isUpdateRequestQuote && setStepQuote(1)
    }, [isUpdateRequestQuote]);

    return (
        <div className={`quoter-container ${stepQuote>0 ? "quote-container_full":""}`} >
            {
                !loading ? <div className="quoter-content">
                    {stepQuote !== 0 && <div className="quoter-timeline">
                        <ImportTimeline 
                            stage={stepQuote}
                            serviceTypeId={requestQuote.serviceTypeId || ''}
                            activeClick={false}
                            setStage={setStepQuote}
                        />
                    </div>}

                    <div className={stepQuote === 0 ? "quoter-body-simple" : "quoter-body" }>
                    {
                        stepQuote === 0 ? 
                        <form className="quoter-formTitle" onSubmit={(e)=>handleSubmitTitle(e)}>
                            <div className="quoter-title">
                                <TextField 
                                    value={requestQuote.title} 
                                    title=''
                                    placeholder="Ej: Carros de juguete para navidad" 
                                    onChange={handleTitleChange} 
                                    role='text-modal-quote'
                                    type='text'
                                    error={requestQuoteError?.title} 
                                />
                                <div className="smalltext quoter-title--number"> {countWord} / 42 caracteres</div>
                            </div> 

                            {(requestQuote.serviceTypeId === 'courier' || 
                             requestQuote.serviceTypeId === 'busqueda-de-proveedor') && 
                             (fromPage === "home" || fromPage === "mainService") && null
                                // <SearchService
                                //     title="Buscar"
                                //     serviceTypeId={requestQuote.serviceTypeId}
                                //     handleServiceSelected={handleServiceSelected}
                                //     serviceSelected={serviceSelected}
                                //     setServiceSelected={setServiceSelected}
                                // /> 
                            }
                        </form>:
                        stepQuote === 1 ? 
                        <QuoteTransport
                            requestQuoteId={requestQuote.id? requestQuote.id : 'external'}
                            transport={requestQuote.transport}
                            setRequestQuote={setRequestQuote}
                            transportError={requestQuoteError.transportation}

                            serviceId={requestQuote.serviceId}
                            serviceTypeId={requestQuote.serviceTypeId || ''}
                            executeScroll={()=>{}}
                            
                        />: 
                        stepQuote === 2?
                        <QuoteCustoms 
                            requestQuoteId={requestQuote.id? requestQuote.id : 'external'}
                            customs={requestQuote.customs}
                            setRequestQuote={setRequestQuote}
                            error={requestQuoteError.customs} 
                            typeTransport={requestQuote.transport?.type}
                            serviceTypeId={requestQuote.serviceTypeId || ''}
                        />: 
                        // <QuoteResume 
                        //     typeTransport={requestQuote.transport?.type}
                        //     origin={requestQuote.transport.origin}
                        //     destination={requestQuote.transport.destination}
                        //     isLcl={requestQuote.transport.typeContainer?.lcl?true:false}
                        //     lcl={requestQuote.transport.lcl}
                        //     isFcl={requestQuote.transport.typeContainer?.fcl?true:false}
                        //     fcl={requestQuote.transport?.fcl}
                        //     isLclAir={requestQuote.transport.typeContainer?.lclAir?true:false}
                        //     lclAir={requestQuote.transport.lclAir}
                        //     customs={requestQuote.customs}
                        //     directionFinal={requestQuote.transport?.finalDelivery}
                        //     directionInitial={requestQuote.transport?.initDelivery}
                        //     packingFile={requestQuote.transport.lcl?.packingList}
                        //     proformaFile={requestQuote.customs.merchandisePermits?.proforma ? requestQuote.customs.merchandisePermits.proforma : []}
                        //     dataSheet={requestQuote.customs.merchandisePermits?.dataSheet ? requestQuote.customs.merchandisePermits.dataSheet : []}
                        //     warehouseOrigin={requestQuote.transport.warehouseOrigin}
                        //     warehouseDestination={requestQuote.transport.warehouseDestination}
                        //     isCourier={requestQuote.serviceTypeId === 'courier'}

                        // /> 
                        null
                    }
                    </div>
                </div> :
                <Loader />
            }
            <div className={stepQuote>0 ? "quoter-container-buttons" : "quoter-container-buttons-one"}>
                {userIsLogin && stepQuote> 0 ? <div className="quoter-buttons__draft" onClick={()=>saveDraft()}>
                    <img src={STORAGE_ASSETS+'/images/png/folder_plus.png'} alt=""/>
                    <div className="paragraph quoter-container-buttons-draft">guardar borrador</div>
                </div> : <div className="quoter-buttons__draft-none"> </div>}
                <div className={ stepQuote>0 ? "quoter-buttons"  : "quoter-buttons-one"}>
                    {(!isUpdateRequestQuote && stepQuote>0 ) && <Button content='Volver' color="black-25" size="small" onClick={()=>handleReturnClick()} />}
                    <Button content='Siguiente' color="black-75" size="small" onClick={()=>handleNextClick()} />
                </div>
            </div>

        </div>
    )
}