import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

// Importando interfaces
import { ServiceInterface } from "../interfaces/Service";

// Importando servicios
import { GetAllServicesActive, GetServiceByCompanyId, GetServiceDetail, GetServicesType } from "../Services/Service.service";
import { GetCompanyById } from "../Services/Company.service";

// metodo para obtener todos los servicios
export const GetServicesActive = createAsyncThunk(
    'service/getAllActive',
    async (_, thunkAPI: any) => {
        try {
            // let tmp: ServiceInterface[]= [];
            const response = await GetAllServicesActive();
            // for (let i = 0; i < response.length; i++) {
            //     const c: any = await GetCompanyById(response[i].companyId);
            //     tmp.push({...response[i], companyWhatsApp: c.whatsapp});
              
            // }
            thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
            return response;
        } catch (error:any) {
            console.log("slice/service/getAllActive => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const GetServicesByType = createAsyncThunk(
    'service/getServiceByType',
    async (type: string, thunkAPI: any) => {
        try {
            // let tmp: ServiceInterface[]= [];
            const response = await GetServicesType(type);
            // for (let i = 0; i < response.length; i++) {
            //     const c: any = await GetCompanyById(response[i].companyId);
            //     tmp.push({...response[i], companyWhatsApp: c.whatsapp});
              
            // }
            thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
            return response;
        } catch (error:any) {
            console.log("slice/service/getAllActive => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
            return thunkAPI.rejectWithValue();
        }
    }
);

// metodo para obtener todos los servicios por ID
export const GetServiceById = createAsyncThunk(
    'service/getById',
    async (id: string, thunkAPI: any) => {
        try {
            const response = await GetServiceDetail(id);
            // console.log("GetServicesActive", response)
            if(response) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response
            }
        } catch (error) {
            console.log("slice/service/getById => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error, code: 'not-fount' }));  
            return thunkAPI.rejectWithValue();
        }
    }
)

export const GetAllServiceByCompany = createAsyncThunk(
    'service/getByCompanyId',
    async (id: string, thunkAPI: any) => {
        try {
            const response = await GetServiceByCompanyId(id);
            // console.log("GetServicesActive", response)
            if(response) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response
            }
        } catch (error) {
            console.log("slice/service/getByCompanyId => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error, code: 'not-fount' }));  
            return thunkAPI.rejectWithValue();
        }
    }
)


const initialState = {
    serviceList:[],
    serviceDetail: {},
    serviceListCompany:[],
}

const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {},
    extraReducers: {
        [GetServicesActive.fulfilled.toString()]: (state, action) => {
            state.serviceList = action.payload
        },
        [GetServicesActive.rejected.toString()]: (state, action) => {
            state.serviceList = []
        },
        [GetServiceById.fulfilled.toString()]: (state, action) => {
            state.serviceDetail = action.payload
        },
        [GetServiceById.rejected.toString()]: (state, action) => {
            state.serviceDetail = {}
        },
        [GetAllServiceByCompany.fulfilled.toString()]: (state, action) => {
            state.serviceListCompany = action.payload
        },
        [GetAllServiceByCompany.rejected.toString()]: (state, action) => {
            state.serviceListCompany = []
        },
        [GetServicesByType.fulfilled.toString()]: (state, action) => {
            state.serviceList = action.payload
        },
        [GetServicesByType.rejected.toString()]: (state, action) => {
            state.serviceList = []
        }
    }
});

const {reducer} = serviceSlice;
export default reducer;