import React, { useCallback, useState } from "react";

/** importando componentes */
import { Modal } from "../Modal/Modal";
import { Textarea } from "../../Textarea/Textarea";
import { Button } from "../../Button/Button";

// Immportando estilos
import './ModalDecline.css';
import debounce from "lodash.debounce";
import { set } from "date-fns";
import { LinearLoader } from "../../LinearLoader/LinearLoader";

interface ModalDeclineProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    onClickCancel: ()=>void;
    onclickSave: ()=>void;
    text: string;
    setText: React.Dispatch<React.SetStateAction<string>>;
    loading?: boolean;
}

export const ModalDecline: React.FC<ModalDeclineProps> = ({
    show,
    setShow,
    onclickSave,
    onClickCancel=()=>{},
    text,
    setText,
    loading=false
}) => {

    // const handleChangeText = useCallback(debounce((e: string)=>{
    //     console.log(e)
    //     setText(e);
    // },500), [text]);


    
    return (
        <Modal title="Rechazar cotización" show={show} onClose={()=>setText("")} setShow={setShow} className="modalOperationHeader" >
            {/* <Textarea 
                title=""
                placeholder=" "
                onKeyUp={(e:any)=>handleChangeText(e.target.innerHTML)}
                maxLength={300}
                value={text}
            /> */}
            <textarea 
                id="modalDecline-body-textarea" 
                title="Explique el motivo de rechazo"
                value={text}
                onChange={(e:any)=>setText(e.target.value)}
                placeholder="Inserta aquí tus notas"
            ></textarea>

           {!loading ? <div className="modalOperationHeader-footer">
                <Button content="Cancelar" color="red" onClick={onClickCancel}/>
                <Button content="Aceptar" color="green-2" onClick={onclickSave}/>
            </div> : <LinearLoader />}
        </Modal>
    )
}