import React from "react";

import { Dropdown, TextField } from "../..";
import { Modal } from "../../Modal/Modal/Modal";
import { Button } from "../..";
import { FooterSignUp } from "../FooterSignUp";

import './SignUpProvider.css';
import { CountryInterface } from "../../../interfaces/Country";

interface SignUpProviderProps {
    show: boolean;
    setShow: (e: boolean) => void;
    name?: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    country?: CountryInterface;
    setCountry: React.Dispatch<React.SetStateAction<string>>;
    onClick?: () => void;
}

export const SignUpProvider: React.FC<SignUpProviderProps> = ({
    show,
    setShow,
    name,
    setName,
    country={
        name:''
    },
    setCountry,
    onClick
}) => {

    
    return (
        <Modal title="Únete a Arkabia" show={show} setShow={setShow}>
            <div role='signUpEmailRole' className="signUpEmail-container">
                <TextField
                    title='Elige un nombre de usuario'
                    placeholder=" "
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                    role='signUpEmailNameRole'
                />
                <Dropdown
                    elements={[country.name]}
                    setValue={setCountry}
                    title="Elige tu país"
                    value={country.name}
                    roleClick='signUpEmailCountryRole'
                />
                <Button 
                    content="Registrarme"
                    size="large"
                    color='black-75'
                    onClick={onClick}
                    role='signUpEmailRegisterRole'
                />
                <FooterSignUp />
            </div>
        </Modal>
    )
}