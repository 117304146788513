import React, {useEffect, useRef} from "react";

import './ModalComplete.css';
import { Button } from "../..";
import { LinearLoader } from "../../LinearLoader/LinearLoader";
import { STORAGE_ASSETS } from "../../../Consts/BaseUrl";

interface ModalCompleteProps {
    showM: boolean;
    setShowM: (e:boolean) => void;
    handleClick: () => void;
    handleConfirm: () => void;
    companyName: string;
    loading?: boolean;
}

export const ModalComplete: React.FC<ModalCompleteProps> = ({
    showM,
    setShowM,
    handleClick,
    handleConfirm,
    companyName,
    loading=false,
    ...props
}) => {

    const refModalComplete = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(!loading) {
            const handleClickOutside = (event: any) => {
                if (refModalComplete.current && !refModalComplete.current.contains(event.target)) {
                    // console.log("click")
                    setShowM(false);
                }
              };
              document.addEventListener('click', handleClickOutside, true);
              return () => {
                document.removeEventListener('click', handleClickOutside, true);
              };
        }
    }, [loading]);

    return (
        <>
            {showM &&
            <div role='modalCompleteRole' className="modalComplete">
                <div className="modalComplete-container" ref={refModalComplete}>
                    <div className="modalComplete__header">
                            <div className="modalComplete__heade-left">
                                <div className="modalComplete-header__text">
                                    <div className={'small-bold modalComplete-header__tex-title'}>Reserva tu cotización</div>
                                </div>
                            </div>
                            {!loading && <img src={STORAGE_ASSETS+'/images/svg/close_big.svg'} alt="" onClick={()=>{handleClick(); setShowM(false)}}/>}
                    </div>

                    <div className="modalComplete-body">
                        <div className="paragraph modalComplete-body-text">Le informaremos a la agencia <span className="paragraph"> {companyName}</span> que quieres reservar su servicio. ¿Deseas continuar?</div>
                    </div>
                    
                   {!loading ? <div className="modalComplete-footer">
                        <Button role='modalCompleteButton' content="No" onClick={()=>{setShowM(false)}}/>
                        <Button role='modalCompleteButton' color="green-2" content={"Si"} onClick={handleConfirm}/>
                    </div>: <LinearLoader />}
                </div>   
            </div>}  
        </>
    )
}