import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CourierProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Courier: React.FC<CourierProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/icon_courier.svg'}  alt=""  />
        </div>
    )
}