import React from "react";
import "./CityCountryDisplay.css";

interface CityCountryDisplayProps {
  city: string;
  country: string;
  flagImg: string;
  classText?: string;
}

export const CityCountryDisplay: React.FC<CityCountryDisplayProps> = ({
  city,
  country,
  flagImg,
  classText=null
}) => {
  return (
    <div className="city-country-display box-shadow-card">
      <span className={classText? classText:"smalltext-header"} style={{color: '#333333'}}>
       {city.length>0 ? city+',': ''} {country}
      </span>
      <div className="city-country--image">
        <img src={flagImg} alt={`${country}-flag`} />
      </div>
    </div>
  );
};
