import React, { useEffect, useState } from "react";

// Importando estilo
import "./GoBack.css";
import { LeftArrow } from "../../icons/outline/LeftArrow";

interface GoBackProps {
    handleClick?: ()=>void;
    title: string
}

export const GoBack: React.FC<GoBackProps> = ({
    handleClick = () => {},
    title
}) => {

  
  return (
    <div className="goBack-back" onClick={handleClick}>
        <LeftArrow />
        <div className="paragraph-header goBack-text">{title}</div>
    </div>
  );
};
