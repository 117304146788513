import React, { useRef } from 'react';


/** Importando componentes */
import { Button } from "../Button/Button";

import "./CardContent.css";

interface CardContentProps {
    children?: React.ReactNode;
    role?: string;
    className?: string;
    style?: React.CSSProperties;
};


export const CardContent: React.FC<CardContentProps> = ({
    children,
    className,
    role,
    style
}) => {

  return (
    <div className={`${className}` + " card-content box-shadow-card"} role={role} style={style}>
        {children}
    </div>
  );
};
