import React from "react";
import "./ContainerRow.css";
import { TextField } from "../../..";

import { ContainerBlack } from "../../../../icons/solid/Container";
import { STORAGE_ASSETS } from "../../../../Consts/BaseUrl";

interface ContainerRowProps {
  containersArr: any[];
  setContainerArr: React.Dispatch<React.SetStateAction<any[]>>;
  setContainer: React.Dispatch<React.SetStateAction<any[]>>;
  isFirst: boolean;
}

export const ContainerRow: React.FC<ContainerRowProps> = ({
  containersArr,
  isFirst,
  setContainerArr,
  setContainer
}) => {
  if (containersArr.length < 0) {
    // console.error("uniqueContainer can not be less than 0!");
    return <></>;
  }

  const handleTrash = (idx: number) => {
    setContainerArr((p) => {
      return p.filter((v, i) => i !== idx);
    });

    setContainer(containersArr.filter((v, i) => i !== idx));
  };

  return (
    <div role='containerRowRole'>
      {!isFirst && containersArr.length === 0 ? (
        <h2 className="smalltext-header" style={{ color: "var(--red)" }}>
          No hay ningún contenedor agregado todavía.
        </h2>
      ) : (
        <div className="container-row-list">
          {/* <p className="paragraph-header">Mi(s) contenedor(es)</p> */}
          { containersArr.length > 0 ? <p className="paragraph-header">Agregado</p>:''}
          {containersArr.map((container, idx) => (
            <div
              className="container-row"
              // style={{
              //   backgroundColor: `${
              //     containersArr.length > 0 ? "var(--black-05)" : ""
              //   }`,
              // }}
              key={idx}
            >
              <div className="container-row-info">
                <ContainerBlack urlImg={container.imgUrl} className='container-img' />
                <div className="container-row-textfields">
                  <TextField
                    title="Tipo de contenedor"
                    placeholder=" "
                    disable
                    value={container.type}
                  />
                  <TextField
                    title="Tamaño"
                    placeholder=" "
                    disable
                    value={container.size}
                  />
                  <TextField
                    title="Cantidad"
                    placeholder=" "
                    disable
                    value={container.quantity}
                  />
                  <div
                    className="container-row-trash"
                    onClick={() => handleTrash(idx)}
                  >
                    <img src={STORAGE_ASSETS+"/images/svg/trash-full.svg"} alt="trash-icon" />
                  </div>
                </div>

                <div className="container-row-textfields-min">
                  <div className="content-row-textfields-min">
                    <p className="paragraph-header content-row-textfields-text">{container.type} {container.size} </p>
                    <p className="paragraph content-row-textfields-text_answer" style={{color: 'var(--gray-3)'}}>Cantidad: {container.quantity}</p>
                  </div>
                  <div
                    className="container-row-trash"
                    onClick={() => handleTrash(idx)}
                  >
                    <img src={STORAGE_ASSETS+"/images/svg/trash-full.svg"} alt="trash-icon" />
                  </div>
                </div>
              </div>
              
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
