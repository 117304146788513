import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Important componentes
import { ButtonEditChange } from "../../../../components/ButtonEditChange/ButtonEditChange";
import { TextField } from "../../../../components/TextField/TextField";
import { ProfileDetail } from "../../../../components/ProfileDetail/ProfileDetail";
import { Loader } from "../../../../components/Loader/Loader";
import { Button, Dropdown } from "../../../../components";
import { TextFieldPassword } from "../../../../components/TextField/TextFieldPassword/TextFieldPassword";

// Store

// Services
import { GetCountryList } from "../../../../Services/Country";
import { GetUserByFirebaseId, UpdateUser } from "../../../../Services/User.service";
import authService from "../../../../Services/Auth.service";

// Utilidades

// Important estilos
import "./DetailProfile.css";

// Firebase
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { CountryDropdown } from "../../../../components/CountryDropdown/CountryDropdown";
import { PhoneCountry } from "../../../../components/PhoneCountry/PhoneCountry";
import { HeadPage } from "../../../../components/HeadPage/HeadPage";
import { CreateUserCompany, GetUserCompanyByUserId, UpdateUserCompany } from "../../../../Services/UserCompany.service";
import { UserCompany } from "../../../../interfaces/UserCompany";
import { ValidateField } from "../../../../utils/ValidateField";
import { UserCompanyError } from "../../../../interfaces/UserCompanyError";
import { UserError } from "../../../../interfaces/UserError";
import { User } from "../../../../interfaces/User";
import { UserVerify } from "../../../../components/UserVerify/UserVerify";
import { CardContent } from "../../../../components/CardContent/CardContent";
import { TypeItConst } from "../../../../Consts/TypeIdentify";
import { id } from "date-fns/locale";

interface DetailProfileProps {
  updateData?: ()=>void
}

export const DetailProfile: React.FC<DetailProfileProps> = ({
  updateData = ()=>{}
}) => {

  let disabledClass = "";

  const {userDetail} = useSelector((state: any) => state.user);

  const [showEdit, setShowEdit] = useState(false);
  const [showEditBusiness, setShowEditBusiness] = useState(false);
  const [showChangePassword, setShowChangePassword ]= useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<{country: string; alpha2Code: string, phoneCode: string }[]>([]);

  const [errorUserCompany, setErrorUserCompany] = useState<UserCompanyError>({
    itNumber: {
      message:'',
      status: false,
    },
    name: {
      message:'',
      status: false,
    },
    position: {
      message:'',
      status: false,
    },
    typeIt: {
      message:'',
      status: false,
    },
  });

  const [errorUser, setErrorUser] = useState<UserError>({
    name: {
      message:'',
      status: false,
    },
    lastName: {
      message:'',
      status: false,
    },
    email: {
      message:'',
      status: false,
    },
    phone: {
      message:'',
      status: false,
    },
    country: { 
        alpha2Code: {
          message:'',
          status: false,
        },
        imgFlag:{
          message:'',
          status: false,
        },
        name: {
          message:'',
          status: false,
        }
    },
    phoneCode: {
      message:'',
      status: false,
    }, 
    phoneCodeFlag: {
      message:'',
      status: false,
    }
  });

  const [userCompany, setUserCompany] = useState<UserCompany>({
    itNumber: '',
    name: '',
    position: '',
    status: true,
    typeIt: '',
    userId: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  const [userInfo, setUserInfo] = useState<User>();

  if (!showEdit) {
    disabledClass = "detail-profile__password-content-disabled";
  }

  /** Detalle del perfil */
  const handleNameChange = (value: string) => {
    setUserInfo((prev: any) => {
      return {
        ...prev,
        name: value
      }
    });
  }

  const handleLastNameChange = (value: string) => {
    setUserInfo((prev: any) => {
      return {
        ...prev,
        lastName: value
      }
    });
  }

  const handleEmailChange = (value: string) => {
    setUserInfo((prev: any) => {
      return {
        ...prev,
        email: value
      }
    });
  }

  // const handleBackupMailChange = (value: string) => {
  //   setUserInfo((prev) => {
  //     return {
  //       ...prev,
  //       backupMail: value
  //     }
  //   });
  // }
  
  const handleCodePhonechange = (phone: string, alpha2Code: string) => {
    setUserInfo((prev:any) => {
      return {
        ...prev,
        phoneCode: phone,
        phoneCodeFlag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${alpha2Code.toLowerCase()}.svg`,
      }
    });
  }

  const handleCountryChange = (country: string, alpha2Code: string) => {
    setUserInfo((prev:any) => {
      return {
        ...prev,
        country: {
          name: country,
          imgFlag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${alpha2Code.toLowerCase()}.svg`,
          alpha2Code: alpha2Code
        }
      }
    });
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInfo((prev: any) => {
        return {
            ...prev,
            phone: e.target.value
        }
    })
 }

  const handlePhoneCodeChange = (e: string) => {
    setUserInfo((prev: any) => {
        return {
            ...prev,
            phoneCode: e
        }
    })
  }

  const handleDocumentTtpeChange = (value: string) => {
    setUserInfo((prev: any) => ({
        ...prev,
        documentType: value
    }));
  }

  const handleDocumentNumberChange = (value: string) => {
    setUserInfo((prev: any) => ({
      ...prev,
      itNumber: value
    }));
  }
  
  const handleIsFirstImportChange = (value: string) => {
    setUserInfo((prev: any) => {
      return {
        ...prev,
        isFirstImport: value === 'Sí' ? true : false
      }
    });
  }

  // const handleHappybirtdayChange = (value: string) => {
  //   setUserInfo((prev) => {
  //     return {
  //       ...prev,
  //       birthday: GetValidDate(value)
  //     }
  //   });
  // }

  const getUserCompany = async(userId: string) => {
    try {
      const response:any = await GetUserCompanyByUserId(userId);
      if(response) {
        setUserCompany(response);
        setUserInfo((prev: any) => ({
          ...prev,
          businessName: response.name
        }));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const getUserDetail = async (UID: string) => {
    setLoading(true);
    try {
      const response = await GetUserByFirebaseId(UID);
      setUserInfo(response);
      await getUserCompany(response.id);
      setLoading(false)
    } catch (error) {
      console.log("🚀 ~ file: DetailProfile.tsx:292 ~ getUserDetail ~ error:", error)
    }
  }


  const handleNewPasswordChange = (value: string) => {
    setNewPassword(value);
  }


  /** Detalle de empresa */
  const handleNameBusiness = (value: string) => {
    setUserCompany((prev) => {
      return {
        ...prev,
        name: value
      }
    });
  }

  const handleItNumber = (value: string) => {
    let typeIt = "Persona Natural";
    if(value.length>2) {
        let firtLetter = value.slice(0,2);
        typeIt = firtLetter === "20" ? "Persona jurídica" : "Persona Natural"
    }

    setUserCompany((prev) => {
      return {
        ...prev,
        itNumber: value,
        typeIt: typeIt,
      }
    });
  }

  const handlePosition = (value: string) => {
    setUserCompany((prev) => {
      return {
        ...prev,
        position: value
      }
    });
  }

  const handleEdit = () => {
      setShowEdit(true)
  }

  const handleClickCancel = () => {
      setShowEdit(false);
      getUserDetail(userDetail.UID);
      setShowChangePassword(false);
  }

  const handleEditBusiness = () => {
    setShowEditBusiness(true)
  }

  const handleClickCancelBusiness = () => {
    setShowEditBusiness(false)
  }

  /** Muestra los campos para reestablecer contraseña */
  const handleClickPassword = () => {
    if(showChangePassword) {
      setShowChangePassword(false)

    } else {
      setShowChangePassword(true)
    }
  }

  const validateFieldsUser = () => {
    if(!userInfo) return;

    let error = false;

    if(!userInfo) return;

    error = ValidateField(userInfo.name, 'required') ||  ValidateField(userInfo.lastName,'required') ||
    ValidateField(userInfo.email,'required') ||  ValidateField(userInfo.phone,'number') ||
    ValidateField(userInfo.phoneCode,'required') || ValidateField(userInfo.country.name,'required')
    || ValidateField(userInfo.country.imgFlag,'required') || ValidateField(userInfo.country.alpha2Code,'required');

    setErrorUser({
      name: {
        message: 'Ingrese Nombre',
        status: ValidateField(userInfo.name, 'required')
      },
      lastName: {
        message: 'Ingrese Apellido',
        status: ValidateField(userInfo.lastName, 'required')
      },
      email: {
        message: 'Ingrese Email',
        status: ValidateField(userInfo.email, 'required')
      },
      phone: {
        message: 'Ingrese Número de celular',
        status: ValidateField(userInfo.phone, 'number')
      },
      phoneCodeFlag: {
        message: 'Ingrese Pais',
        status: ValidateField(userInfo.country.name, 'required')
      },
      phoneCode: {
        message: 'Ingrese Codigo',
        status: ValidateField(userInfo.phoneCode, 'required')
      },
      country: {
        name: {
          message: 'Ingrese Pais',
          status: ValidateField(userInfo.country.name, 'required')
        },
        imgFlag: {
          message: 'Ingrese Pais',
          status: ValidateField(userInfo.country.name, 'required')
        },
        alpha2Code: {
          message: 'Ingrese Pais',
          status: ValidateField(userInfo.country.name, 'required')
        }
      },
    })

    return error;
  }

  const validateFieldsUserCompany = () => {
    if(!userInfo) return;
    
    let error = false;

    if(!userInfo) return;

    error = ValidateField(userInfo.name, 'required') ||  ValidateField(userCompany.position, 'required') || 
    ValidateField(userCompany.typeIt, 'required') || ValidateField(userCompany.itNumber, 'RUC');

    setErrorUserCompany({
      name: {
        message: 'Ingrese Razón Social',
        status: ValidateField(userCompany.name, 'required')
      },
      position: {
        message: 'Ingrese Puesto actual',
        status: ValidateField(userCompany.position, 'required')
      },
      typeIt: {
        message: 'Ingrese Identificación Fiscal',
        status: ValidateField(userCompany.typeIt, 'required')
      },
      itNumber: {
        message: 'Ingrese Número de RUC',
        status: ValidateField(userCompany.itNumber, 'RUC')
      }
    })

    return error;
  }

  const handleSaveUserCompany = async () => {
    if(!userInfo) return;

    setLoading(true);
      if (userCompany.id) {
        UpdateUserCompany(
          userCompany.id,  
          {
          itNumber: userCompany.itNumber,
          name: userCompany.name,
          position: userCompany.position,
          status: userCompany.status,
          typeIt: userCompany.typeIt,
          userId: userCompany.userId
        }).then(() => {
          setLoading(false);
          updateData();
          toast.success('Se actualizaron los datos correctamente')
          getUserCompany(userCompany?.userId ? userCompany.userId : '');
          setShowEditBusiness(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            toast.error("Hubo un error al guardar los datos, intentelo en uno munutos")
        });
      } else {
        if(!validateFieldsUserCompany()) {
         try {
            const response = await CreateUserCompany({...userCompany, userId: userDetail.id});
            await UpdateUser({
              id: userInfo.id,
              userCompanyId: response.id,
            })
            setLoading(false);
            toast.success('Se actualizaron los datos correctamente');
            getUserDetail(userDetail.UID);
            setShowEditBusiness(false)
         } catch (error) {
            console.log("🚀 ~ file: DetailProfile.tsx:445 ~ handleSaveUserCompany ~ error:", error);
            toast.error("Hubo un error al guardar los datos, intentelo en uno munutos")
            setLoading(false);
         }
        }
        setLoading(false);
      }
  }

  const handleSaveUserClick =  async () => {
    if(!userInfo) return; 
      
    setLoading(true);
    if(userDetail.id && userInfo) {
      UpdateUser({
          id: userDetail.id,
          email: userInfo.email,
          lastName: userInfo.lastName,
          name: userInfo.name,
          country: {
            alpha2Code: userInfo.country.alpha2Code,
            imgFlag: userInfo.country.imgFlag,
            name: userInfo.country.name,
          },
          phoneCode: userInfo.phoneCode,
          phoneCodeFlag: userInfo.phoneCodeFlag,
          phone: userInfo.phone,
          isFirstImport: userInfo.isFirstImport,
          documentType: userInfo.documentType,
          itNumber: userInfo.itNumber,
          updatedAt: new Date()
        }).then(() => {
          setLoading(false);
          updateData();
          toast.success('Se actualizaron los datos correctamente')
          getUserDetail(userDetail?.UID ? userDetail.UID : '');
          setShowEdit(false)
        }).catch((error) => {
          console.log(error);
          setLoading(false);
          toast.error("Hubo un error al guardar los datos, intentelo en uno minutos")
        });
    } else {
      if(!validateFieldsUser()) {
        try {
          setLoading(false);
          toast.success('Se actualizaron los datos correctamente');
          getUserDetail(userDetail.UID);
          setShowEdit(false)
        } catch (error) {
          console.log("🚀 ~ file: DetailProfile.tsx:445 ~ handleSaveUserCompany ~ error:", error);
          toast.error("Hubo un error al guardar los datos, intentelo en uno munutos")
          setLoading(false);
        }
      }
      setLoading(false);
    }
  } 

  const handleChangePassword = async () => {
    if( userInfo && newPassword.length>0 && newPassword.length>0) {
            setLoading(true);
            try {
                const responseLogin = await authService.LoginWithEmail(userInfo.email, currentPassword, false);
                try {
                    const response = await authService.PasswordReset(newPassword);          
                    toast.success('Se actualizó con exito');
                    setCurrentPassword('');
                    setNewPassword('');
                    setShowEdit(false);
                    setShowChangePassword(false);
                    setLoading(false)
                } catch (error) {
                    toast.error('Ingrese una contraseña válida, mínimo 6 caracteres');
                    setLoading(false)
                }
        
            } catch (error) {
                setLoading(false);
                toast.error('Contraseña actual incorrecta')  
            }
          
    } else {
        toast.success('Las contraseñas ingresadas no coinciden')
    }
  }

  // obteniendo lista de paises
  const getCountries = async () => {
    try {
        const response: any = await GetCountryList();
        setCountryList(response);
    } catch (error) {
        console.log("🚀 ~ file: SupplierInvoceTranslation.tsx:65 ~ getCountries ~ error", error);
    }
  }

  useEffect(() => {
    setLoading(true);
    const auth = getAuth();
    onAuthStateChanged(auth, (userCurrent) => {
        if(userCurrent) {
            getUserDetail(userCurrent.uid);
        }
        setLoading(false);
    });
  }, []);
  
  useEffect(() => {
    showEdit && getCountries();
  }, [showEdit]);

  return (
    <div className="detailProfile">
        <CardContent>
        {/* {showVerify && 
            <UserVerify
              title="Cuenta verificada" 
              showVerify={userDetail.isVerified}
              setShowVerify={setShowVerify}
            />} */}
        {!loading && userInfo ? <div className="detail-profile">
            <HeadPage
              title="Mi Perfil"
              subTitle=""
            />
            {/* <div className="detail-profile-header">
              <ProfileDetail user={userInfo}
                            myProfile={true}
                            showVerify={true}
                            setShowVerify={setShowVerify}
              />
              <ContentNumber numberContact = "3"
                            numberOrden = "1"
                            numberReview = "0" />
            </div>  */}

            {/** Section Contacto */}
            <div className="detail-profile__row__">
              <div className="smalltext-header detail-profile-title-section">Contacto</div>
              <ButtonEditChange add={false} 
                                setEdit={setShowEdit} 
                                edit={showEdit} 
                                onClickCancel={handleClickCancel} 
                                onClickComplete={handleSaveUserClick} 
                                onClickEdit={handleEdit}
                                onClickSave={handleSaveUserClick}/>
            </div>

            <div className="detail-profile__row">
              <TextField title='Nombre'
                        placeholder=' '
                        value={userInfo.name}
                        onChange={(e:any) => handleNameChange(e.target.value)}
                        disabled={!showEdit}/>
              
              <TextField title='Apellidos'
                        placeholder=' '
                        value={userInfo.lastName}
                        onChange={(e:any) => handleLastNameChange(e.target.value)}
                        disabled={!showEdit}/>
              <TextField title='Correo electrónico'
                placeholder=' '
                value={userInfo.email}
                onChange={(e:any) => handleEmailChange(e.target.value)}
                disabled
              />
            </div>

            <div className="detail-profile__row">             

              <PhoneCountry 
                phoneCode={userInfo.phoneCode || ""}
                phoneFlag={userInfo.phoneCodeFlag || ""}
                countryList={countryList}
                phone={userInfo.phone?userInfo.phone.toString():''}
                handleCountryChange={handleCodePhonechange} 
                handlePhoneChange={handlePhoneChange}
                // phoneCode={userInfo.phoneCode ? userInfo.phoneCode : ''}
                handlePhoneCodeChange={handlePhoneCodeChange} 
                title="Código"
                disable={!showEdit}
              />
              <div className="detail-profile__row-it">
                <Dropdown 
                  className="detail-profile__row-typeIt "
                  title={`Tipo de ${window.innerWidth>600 ? 'documento' : 'doc'}`}
                  value={userInfo.documentType}
                  elements={TypeItConst.map((item) => item.label)}
                  setValue={(e: any)=>handleDocumentTtpeChange(e)}
                  disabled={!showEdit}
                />
                <TextField
                  className="textfield-input detail-profile__row-itNumber"
                  title={`Nro de ${window.innerWidth>600 ? 'documento' : 'doc'}`}
                  placeholder=' '
                  value={userInfo.itNumber}
                  onChange={(e:any) => handleDocumentNumberChange(e.target.value)}
                  disabled={!showEdit}
                />
              </div>
            </div>

            <div className="detail-profile__row">
              <div className="detail-profile__row___">
                <CountryDropdown
                  title="País"
                  country={userInfo.country}
                  countryList={countryList}
                  handleCountryChange={handleCountryChange}
                  disable={!showEdit}
                />
                <Dropdown 
                  elements={["Sí", "No"]}
                  title="Es tu primera importación"
                  value={userInfo.isFirstImport ? "Si" : "No"}
                  setValue={(e: any)=>handleIsFirstImportChange(e)}
                  disabled={!showEdit}
                />

                {/* <TextField title='Es tu primera importación'
                  placeholder=' '
                  value={'Si'}
                  onChange={(e:any) => handleEmailChange(e.target.value)}
                  disabled
                /> */}
               
              </div>

              <div className={`detail-profile__password-content ${disabledClass}`} onClick={()=> showEdit ? handleClickPassword():()=>{}}>
                <div className="tinytext">Contraseña</div>
                <div className="paragraph-header detail-profile__password">Reestablecer contraseña</div>
              </div>
            </div>

            { showChangePassword && 
              <>
                <div className="paragraph-header">Reestablecer contraseña</div>
                <div className="detail-profile__row">
                  <TextFieldPassword
                    title='Contraseña actual'
                    placeholder=" "
                    type='password'
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <TextFieldPassword
                    title='Nueva contraseña'
                    placeholder=" "
                    type='password'
                    value={newPassword}
                    onChange={(e:any) => handleNewPasswordChange(e.target.value)}
                  />
                </div>
                <div className="detail-profile__row_">
                  <Button onClick={handleChangePassword} content='Actualizar contraseña' color='black-75' size='small' />
                </div>
              </>
            }


            {/** Seccion empresa */}
            { (userDetail.userType && userDetail.userType.includes('user')) &&
            <>
              <div className="detail-profile__row__">
                <div className="smalltext-header detail-profile-title-section">Empresa</div>
                <ButtonEditChange add={false} 
                                  setEdit={setShowEditBusiness} 
                                  edit={showEditBusiness} 
                                  onClickCancel={handleClickCancelBusiness} 
                                  onClickComplete={handleSaveUserClick} 
                                  onClickEdit={handleEditBusiness}
                                  onClickSave={handleSaveUserCompany}/>
              </div>
            
              <div className="detail-profile__row">
                <TextField title='Razón social'
                          placeholder=' '
                          value={userCompany.name}
                          onChange={(e:any) => handleNameBusiness(e.target.value)}
                          disabled={!showEditBusiness}
                          error={errorUserCompany.name}/>
                
                <TextField title='RUC'
                          placeholder=' '
                          value={userCompany.itNumber}
                          onChange={(e:any) => handleItNumber(e.target.value)}
                          disabled={!showEditBusiness}
                          error={errorUserCompany.itNumber}/>
              </div>

              <div className="detail-profile__row">
                {/* <TextField title='Identificación fiscal'
                          placeholder=' '
                          value={userCompany.typeIt}
                          onChange={(e:any) => handleTypeItChange(e.target.value)}
                          disabled={!showEditBusiness}
                          error={errorUserCompany.typeIt}/> */}
                
                <TextField title='Puesto actual'
                          placeholder=' '
                          value={userCompany.position}
                          onChange={(e:any) => handlePosition(e.target.value)}
                          disabled={!showEditBusiness}
                          error={errorUserCompany.position}/>
              </div>
            </>
            }
        </div> : <Loader /> }
      </CardContent>
    </div>
  );
};
