import React, { useRef, useState } from "react";

import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { Button } from "../Button/Button";

import { CircleCheckWhite } from "../../icons/outline/CircleCheckWhite";
import { Edit } from "../../icons/outline/Edit";

import './ButtonEditChange.css';
import { toast } from "react-toastify";
import { EditWhite } from "../../icons/outline/EditWhite";

interface ButtonEditChangeProps {
    onClickEdit: ()=>void,
    onClickCancel: ()=>void,
    onClickComplete: ()=>void,
    onClickSave: ()=>void,
    edit: boolean,
    add: boolean;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>,
    role?: string
}

export const ButtonEditChange: React.FC<ButtonEditChangeProps> = ( {
    onClickEdit,
    onClickCancel,
    onClickComplete,
    edit,
    setEdit,
    add,
    onClickSave,
    role
}) => {

    const refArea = useRef<HTMLTextAreaElement>(null);
    
    const handleCopyClipboard = (e: any) => {
        refArea.current?.select();
        let copied = window.document.execCommand('copy');
        toast.success("¡Texto copiado!");
        e.target.focus();
      }

      


    return (
        <div role='buttonEditChangeRole' className="buttonEditChange">
            { (!edit && !add) && (
                <ButtonIcon content="Editar"
                            Icon={<EditWhite />}
                            color="black-75"
                            size="extra-small"
                            onClick={onClickEdit}
                            role='buttonEditChangeEdit'
                            />)}
            
                
            { (edit || add) && <>
                <Button content="Cancelar"
                        color="red"
                        size="extra-small"
                        onClick={()=>onClickCancel()}
                        role='buttonEditChangeCancel'
                        />

                
                    <ButtonIcon role='buttonEditChangeComplete' content={add ? 'Completar': (edit ? 'Guardar': '')}
                            Icon={add ? <CircleCheckWhite /> : <div className="icon-button"/>}
                            color={add ? 'green-2': 'blue-2'}
                            size="extra-small"
                            place="left"
                            onClick={add ? onClickComplete : (edit ? onClickSave : ()=>{})}/>
            </>}
        </div>
    )
}      