import { DB } from "../firebaseConfig/FirebaseConfig";

import { getDocs, collection, orderBy, query } from "firebase/firestore";
import { Fcl_containers } from "../interfaces/Fcl_containers";

export const GetContainers = async () : Promise<Fcl_containers[]>=> {
    const q = query(collection(DB, "fcl_containers"), orderBy("createdAt", "desc"));
    const response = await getDocs(q);
    let data:Fcl_containers[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}) as Fcl_containers)
    }
    return data;
}