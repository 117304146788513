import React, { useState } from "react";

/** Importando componente */
import { Button } from "../Button/Button";
import { Rating } from "../Rating/Rating";
import { TextField } from "..";

/** Importando interface */
import { reviewUserInterface } from "../../interfaces/reviewUser"; 

/** Importando data */
import { ComentReview } from "../../testdata/ComentReview";

/** Importando icon */
import { Dots } from "../../icons/outline/Dots";

/** Importando estilo */
import "./ReviewUser.css";
import { FormatDate } from "../../utils/formatDate";

interface ReviewUserProps {
  review: reviewUserInterface;
}

export const ReviewUser: React.FC<ReviewUserProps> = ({ review }) => {
  return (
    <div role='reviewRole' className="reviewUser">
      <div className="reviewUser-top">
        <img
          className="reviewUser-user-img"
          src={review.companyLogo}
          alt={review.companyName}
        />

        <div className="reviewUser-content-user">
          <h1 className="small-bold reviewUser-name-text">{review.companyName}</h1>
          <div className="tinytext-header reviewUser-color-Date">{FormatDate(new Date(review.createdAt.toDate()))}</div>
        </div>

        <Rating rating={review.rating} />
        <Dots />
      </div>

      <div className="reviewUser-mid">
        <p className="paragraph">{review.comment}</p>
      </div>
    </div>
  );
};
