import React, { useEffect } from "react";

import './Tools.css';
import { SectionAnimation } from "../../../components/SectionAnimation/SectionAnimation";
import { PLAN, STORAGE_ASSETS } from "../../../Consts/BaseUrl";
import { ToolsPageConsts } from "../../../Consts/ToolsConsts";
import { useNavigate } from "react-router-dom";

export const Tools: React.FC<{}> = () => {

    const navigate = useNavigate();

    const handlePlan = () => {
        navigate("/"+PLAN);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="tools">
            <div className="tools-first">
                <div className="tools-content">
                    <div className="small-subheader tools-white tools-title-min">¿Luchas por hacer crecer tu negocio de importaciones?</div>
                    <div className="tools-title tools-white">
                        Simplifica tu trabajo con 
                        <span className="tools-text-degradado"> Arkabia</span> 
                    </div>
                    <div className="tools-content-w" style={{paddingBottom: '20px'}}>
                        <div className="header-medium tools-white tools-subtitle">
                            La plataforma que convierte el proceso de importación en una  
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        experiencia sencilla, accesible y exitosa para dropshippers e
                        </div>
                        <div className="header-medium tools-white tools-subtitle">importadores.</div>
                    </div>

                    <div className="tools-content-m" style={{paddingBottom: '20px'}}>
                        <div className="header-medium tools-white tools-subtitle">
                          La plataforma que convierte el proceso de  
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        importación en una experiencia sencilla, accesible  
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        y exitosa para dropshippers e importadores.</div>
            
                    </div>

                    <div className="tools-content-s" style={{paddingBottom: '20px'}}>
                        <div className="header-medium tools-white tools-subtitle">
                          La plataforma que convierte    
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        el proceso de importación en una 
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        experiencia sencilla, accesible y exitosa</div>
                        <div className="header-medium tools-white tools-subtitle">
                        para dropshippers e importadores.</div>
                    </div>
                    <div className='small-boldheader tools-button' onClick={()=>handlePlan()}>
                        Probar Ahora Gratis
                    </div>
                    <img className="tools-img-main" src={STORAGE_ASSETS+'/images/png/tools-main.png'}/>
                </div>
            </div>

            <div className="tools-second">
                <div className="tools-second-header">
                    <div className="tools-white tools-second-title">
                        Una herramienta. Muchas posibilidades
                    </div>
                    <div className="small-boldheader tools-white tools-second-subtitle">
                        Todo lo que necesitas para escalar tu negocio de importaciones 
                        desde cero está aquí.
                    </div>
                </div>
                <div className="tools-second-body-content">
                    <div className="tools-second-body">
                        <div className="tools-second-item">
                            <img 
                                className="tools-second-img" 
                                src={STORAGE_ASSETS+'/images/png/rayo-icon.png'}/>
                            <div className="small-medium tools-white tools-item-title"> Optimiza costos </div>
                            <div className="paragraph tools-white tools-item-description">
                                Arkabia te permite maximizar tus ganancias en cada 
                                importación, asegurando que obtengas siempre la mejor 
                                relación calidad-precio en tus cotizaciones.
                            </div>
                        </div>
                        <div className="tools-second-item">
                            <img 
                                className="tools-second-img" 
                                src={STORAGE_ASSETS+'/images/png/crecer-icon.png'}/>
                            <div className="small-medium tools-white tools-item-title"> Reduce procesos </div>
                            <div className="paragraph tools-white tools-item-description">
                                Con Arkabia, tus importaciones no solo serán rápidas, sino 
                                también eficientes y organizadas de principio a fin.
                            </div>
                        </div>
                        <div className="tools-second-item">
                            <img 
                                className="tools-second-img" 
                                src={STORAGE_ASSETS+'/images/png/expand-icon.png'}/>
                            <div className="small-medium tools-white tools-item-title"> Evita incautaciones </div>
                            <div className="paragraph tools-white tools-item-description">
                                Arkabia reduce el riesgo de retenciones y sanciones al 
                                anticipar los permisos que Aduanas podría requerir, 
                                asegurando que tu carga llegue sin contratiempos.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tools-thrid">
                {ToolsPageConsts.map((item, index) => (
                    <SectionAnimation 
                        key={index}
                        tools={item}
                    />
                ))}
            </div>
        </div>
    )
}