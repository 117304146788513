import React, { useEffect, useState } from "react";

import './DropdownMultiLine.css';
import { ArrowDown } from "../../icons/solid/ArrowDown";
import { ArrowUp } from "../../icons/outline/ArrowUp";
import { set } from "date-fns";
import { Warehouse } from "../../interfaces/WarehouseCourierInterface";
import { ErrorInterface } from "../../interfaces/ErrorInterface";

interface DropdownMultiLineProps {
    placeholder: string;
    items: {
        title: string;
        values: string[];
    }[];
    value: string;
    onItemClick: (value: string, direction: string) => void;
    error? : ErrorInterface;
    disabled?: boolean;
}

export const DropdownMultiLine: React.FC<DropdownMultiLineProps> = ({
    placeholder,
    items,
    value = "",
    onItemClick,
    error = {
        status: false,
        message: "",
    },
    disabled = false,
}) => {

    const [showOptions, setShowOptions] = useState<boolean>(false);

    const handleItemCick = (value: string, direction: string) => {
        setShowOptions(false);
        onItemClick(value, direction);
    }
    
    return (
        <div className={`dropdownMultiLine-container ${showOptions ? "dropdownMultiLine-nonblur" : ""}`}>
           <div className="dropdownMultiLine-title--container" onClick={()=>!disabled ? setShowOptions((prev) => !prev) : {}}>
                <div className="dropdownMultiLine-title">
                    <div className={`dropdownMultiLine-colorText smalltext-header ${value.length> 0 ? "dropdownMultiLine-placeholder--up" : ""}`}>{placeholder}</div>
                    {value.length> 0 && <div className="dropdownMultiLine-colorText smalltext-header">{value}</div>}
                </div>
                <div className="dropdownMultiLine-arrow">
                    {showOptions ? <ArrowUp/> : <ArrowDown className="dropdownMultiLine-arrow--down"/> }
                </div>
            </div>

            {showOptions && items.length> 0 && <div className="dropdownMultiLine-options">
                {items.map((item) => (
                    <div className="dropdownMultiLine-options--item" onClick={() =>handleItemCick(item.title, item.values[0]+"<br/>"+item.values[1])}>
                        <div className="smalltext-header dropdownMultiLine-colorText">{item.title}</div>
                        {item.values.map((value)=> (
                            <div className="smalltext dropdownMultiLine-colorText">{value}</div>
                        ))}
                    </div>
                ))}
            </div>}
            {error.status && <div className="dropdownMultiLine-error tinytext text-error">{error.message}</div>}
        </div>
    );
}