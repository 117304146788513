import React from "react";

import { Modal } from "../Modal/Modal/Modal";
import { ButtonIcon } from "..";
import { GoogleIcon } from "../../icons/solid/googleIcon";
import { FacebookIcon } from "../../icons/solid/facebookIcon";
import { AppleIcon } from "../../icons/solid/appleIcon";
import { TextField } from "..";
import { TextFieldPassword } from "../TextField/TextFieldPassword/TextFieldPassword";
import { Button } from "..";

import './LogIn.css';
import { Checkbox } from "../Checkbox/Checkbox";

interface LogInProps {
    show: boolean;
    setShow: (e: boolean) => void;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    onClckLogin: () => void;
    remember?: boolean; // si se quiere recordar el usuario
    msg?: string;
    btnGoogle?: ()=>void;
    btnFacebook?: ()=>void;
    btnApple?: ()=>void;
    onClickRegiter?: ()=>void;
    setRememberMe?: React.Dispatch<React.SetStateAction<boolean>>;
    loading?: boolean;
    handleForgotPassword?: ()=>void;
}

export const LogIn: React.FC<LogInProps> = ({
    show,
    setShow,
    email,
    setEmail,
    password,
    setPassword,
    onClckLogin,
    msg='',
    remember=false,
    btnGoogle,
    btnFacebook,
    btnApple,
    onClickRegiter=()=>{},
    setRememberMe=()=>{},
    loading=false,
    handleForgotPassword=()=>{}

}) => {
    return (
        // <Modal title="Inicia sesión en Arkabia" show={show} setShow={setShow} role='modal-login' disableToast > 
            <div className="logIn-container">
                <ButtonIcon 
                    Icon={<GoogleIcon style={{width: '26px', height: '26px'}} />}
                    content="Gmail"
                    place="left"
                    size='large'
                    color='white'
                    style={{color: 'var(--gray-1)', background: 'white'}}
                    role='btn-login-google'
                    onClick={btnGoogle}/>
                 {/* <ButtonIcon 
                    Icon={<FacebookIcon style={{width: '30px', height: '30px'}} />}
                    content="Continuar con Facebook"
                    place="left"
                    size='large'
                    color='white'
                    style={{color: 'var(--gray-1)', background: 'white'}}
                    role='btn-login-facebook'
                    onClick={btnFacebook}/> */}
                 {/* <ButtonIcon 
                    Icon={<AppleIcon style={{width: '30px', height: '30px'}} />}
                    content="Continuar con Apple"
                    place="left"
                    size='large'
                    color='white'
                    style={{color: 'var(--gray-1)', background: 'white'}}
                    role='btn-login-apple'
                    onClick={btnApple}/>
                     */}
                 <hr className="logIn-divider" />
                <TextField
                    title='Email'
                    placeholder=" "
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    type='email'
                />
                <TextFieldPassword
                    title='Contraseña'
                    placeholder=" "
                    type='password'
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                />
                <div className="logIn-remember">
                    <div className="logIn-checkbox">
                        <Checkbox setChecked={setRememberMe} checked={remember}/>
                        <div className="paragraph-header logIn-remember-text" style={{color: 'var(--gray-1)'}}>Recuérdame</div>
                    </div>
                    <div className="smalltext-header logIn-remember-text" style={{color: 'var(--blue-2)', cursor: 'pointer'}} onClick={()=>handleForgotPassword()} >¿Olvidaste la contraseña?</div>
                </div>
                {msg.length >0 && <div className="tinytext-header " style={{color: 'var(--red)'}}>{msg}</div>}
                <Button 
                    content={`${!loading ? "Iniciar sesión": 'Cargando ...'}`}
                    size="large"
                    color='black-75'
                    onClick={()=> !loading ? onClckLogin():{}}
                    role='btn-login-access'/>
                <hr className="logIn-divider" />
                <div className="small-bold footer-login" style={{color: 'var(--gray-1)'}}>¿No tienes cuenta? <span style={{color: 'var(--blue-2)', cursor: 'pointer'}} onClick={()=>onClickRegiter()}>Regístrate aquí</span></div>
            </div>
        // </Modal>
    )
}