import React, { useEffect, useRef, useState } from 'react';
import './GaleryScroll.css';
import { STORAGE_ASSETS } from '../../Consts/BaseUrl';

interface GaleryScrollProps {
    images: {
        href: string;
        src: string
    }[]
}

const GaleryScroll: React.FC<GaleryScrollProps> = ({ images }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const handleMouseOver = (event: MouseEvent) => {
            const target = event.target as HTMLImageElement;
            if (target.tagName !== 'IMG') return;

            const imgRect = target.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();
            const scrollLeft = imgRect.left - containerRect.left + container.scrollLeft - (containerRect.width / 2) + (imgRect.width / 2);

            // Ensure the image is fully visible
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            const newScrollLeft = Math.max(0, Math.min(scrollLeft, maxScrollLeft));

            container.scrollTo({
                left: newScrollLeft,
                behavior: 'smooth'
            });
        };

        container.addEventListener('mouseover', handleMouseOver);

        return () => {
            container.removeEventListener('mouseover', handleMouseOver);
        };
    }, []);

    return (
        <div className='galery-scroll' ref={containerRef}>
            {images.map((i, index) => (
                <a href={i.href} target="_blank">
                    <img key={index} src={i.src} alt={`Image ${index + 1}`} />
                </a>
            ))}
        </div>
    );
};

export default GaleryScroll;