import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ArkabiaOp } from "../../../icons/outline/ArkabiaOp";
import { User } from "../../../interfaces/User";
import { GetUserByEmailOrName } from "../../../Services/User.service";
import { ValidateField } from "../../../utils/ValidateField";
import { Button } from "../../Button/Button";
import { SearchUserItem } from "../../SearchUserItem/SearchUserItem";
import { TextField } from "../../TextField/TextField";
import { UserSearchList } from "../../UserSearchList/UserSearchList";
import { Modal } from "../Modal/Modal";

import './ModalSearchUser.css';

interface ModalSearchUserProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    subTitle?: string;
    description?: string;
    onClickSend: (user: User | string) => void;
    
}

export const ModalSearchUser: React.FC<ModalSearchUserProps> = ({
    show, 
    setShow,
    title,
    subTitle='',
    description='Envíale la cotización a la persona que quieras.',
    onClickSend
}) => {

    const [userSearch, setUserSearch] = useState<User[]>([]);
    const [search, setSearch] = useState<string>('');
    const [userSelected, setUserSelected] = useState<User | string | null>(null);
    const [showEmailInvitation, setShowEmailInvitation] = useState<boolean>(false);

    const handleChangeSearch = useCallback(
        debounce(async (e) => {
            let value = e.target.value;
            if(value.length>0) {
                const response:any = await GetUserByEmailOrName(value);
                setUserSearch(response);
            } else {
                setUserSearch([]);
                setShowEmailInvitation(false);
            }
          
        }, 500),
    []);

    const handleSelectUser = (user: User | null) => {
        setUserSelected(user);
        setUserSearch([]);
    }

    const onclickSave = () => {
        if(showEmailInvitation) {
            setUserSelected(search);
        }

        if(userSelected) {
            onClickSend(userSelected);
        } else {
            toast.warning("Busca y selecciona a un usuario o ingrese un correo electrónico")
        }
    }

    const onClickDelete = () => {
        setUserSelected(null);
        setSearch('');
    }

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>)  => {
        setSearch(e.target.value);
        handleChangeSearch(e); 
        setShowEmailInvitation(!ValidateField(search, 'email'))
    }

    useEffect(() => {
      
        return () => {
            handleChangeSearch.cancel();
        }
    }, []);

    return (
        <Modal icon={<ArkabiaOp />} title={title} subTitle={subTitle} show={show} setShow={setShow}> 
            <div className="modalSearchUser-container">
                <div className="modalSearchUser-colorText paragraph-header">{description}</div>
                <TextField 
                    title="Escribe su nombre de usuario o correo" 
                    placeholder=" "
                    onChange={(e) => handleSearchInputChange(e)} 
                    value={search}
                />
                {userSearch.length>0 ? <UserSearchList userList={userSearch.slice(0,4)} onClick={handleSelectUser} /> : 
                showEmailInvitation &&
                    <div className="modalSearchUser--email">
                        <SearchUserItem 
                            email={search}
                            companyName='Enviar la cotizacion a:'
                            onClick={()=>{setUserSelected(search); setShowEmailInvitation(false)}}
                            selected={false}
                        />
                    </div>}
                {userSelected && <div className="modalSearchUser-selected">
                    {typeof(userSelected) !== 'string' ? <>
                        <div className="modalSearchUser-colorText paragraph-header">Usuario seleccionado </div>
                        <SearchUserItem 
                            name={userSelected.name}
                            lastName={userSelected.lastName}
                            email={userSelected.email}
                            imgProfile={userSelected.photoUrl ? userSelected.photoUrl : 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fuser.png?alt=media&token=dd5bd9cb-ab0f-4799-8b73-31fb2831df7b'}
                            companyName={userSelected.businessName ? userSelected.businessName : ''}
                            onClick={()=>{}}
                            selected={false}
                            isDelete
                            onClickDelete={onClickDelete}
                        />
                    </>: search.length>0 && typeof(userSelected) === 'string' ? <SearchUserItem 
                            email={search}
                            companyName='Correo seleccionado'
                            onClick={()=>{}}
                            selected={false}
                            isDelete
                            onClickDelete={onClickDelete}
                        /> : null}
                </div>}
                {userSelected && <div className="modalSearchUser-button">
                    <Button content="Enviar" onClick={() =>onclickSave()} />
                </div>}
            </div>
        </Modal>
    )
}