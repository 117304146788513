import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import ReactGA from "react-ga4";

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../Store/Store';

import './MainRegisterNew.css';

import { GoogleIcon } from '../../icons/solid/googleIcon';
import { ButtonSign } from '../ButtonSign/ButtonSign';

// import services
import authService from '../../Services/Auth.service';
import { CreateUser, GetUserByEmail } from '../../Services/User.service';
import { toast } from 'react-toastify';
import { User } from '../../interfaces/User';
import { GetUser, GetUserByUID } from '../../Slices/User';
import { RegisterWithEmail, setAuth } from '../../Slices/Auth';
import { AuthSteps_item } from '../../Consts/AuthSteps';
import { Loader } from '../Loader/Loader';
import { TextField } from '../TextField/TextField';

import { PhoneCountry } from '../PhoneCountry/PhoneCountry';
import { TextFieldPassword } from '../TextField/TextFieldPassword/TextFieldPassword';
import { ValidateField } from '../../utils/ValidateField';
import { UserError } from '../../interfaces/UserError';
import { Checkbox } from '../Checkbox/Checkbox';
import { UserCompany } from '../../interfaces/UserCompany';
import { UserCompanyError } from '../../interfaces/UserCompanyError';
import { OPERATIONS } from '../../Consts/BaseUrl';
import { CreateUserCompany } from '../../Services/UserCompany.service';
import { ErrorInterface } from '../../interfaces/ErrorInterface';
import { GetCountryList } from '../../Services/Country';
import { Dropdown } from '../Dropdown/Dropdown';
import { TypeItConst } from '../../Consts/TypeIdentify';
import { getIpData } from '../../Services/GetIpData.service';

interface MainRegisterProps {
    isFreePlan: boolean;
    isEmailFromParams?: boolean;
    fullRegister: User;
    setFullRegister: React.Dispatch<React.SetStateAction<User>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    setDetailRegisterModal:  React.Dispatch<React.SetStateAction<boolean>>;
    setAuthStep: React.Dispatch<React.SetStateAction<AuthSteps_item>>;
    internalStep?: number;
    redirectToPath: () => void;
    closeAuthModal: ()=>void;
    onlyCloseModal: ()=>void;
    handleTyC: ()=>void;
    createSuscription: (userId: string, token: string)=>void;
}

const MainRegisterNew = forwardRef((props: MainRegisterProps, ref) => {

    // redux
    const navigation = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const dispatch = useDispatch<AppDispatch>();

    // local states
    const [loading, setLoading] = useState<boolean>(false);

    // state to manage country list
    const [countryList, setCountryList] = useState<{country: string; alpha2Code: string, phoneCode: string }[]>([]);

    // step 0 main registration. 1 email register, 2 full registration
    const [step, setStep] = useState<number>(0);
    const [phaseRegister, setPhaseRegister] = useState<number>(1);
    // show business account section
    const [showBusinessAccount, setShowBusinessAccount] = useState<boolean>(false);
    // userCompany
    const [userCompany, setUserCompany] = useState<UserCompany>({
        name: '',
        position: '',
        typeIt: '',
        itNumber: '',
        status: true,
        userId: '',
        createdAt: new Date(),
        updatedAt: new Date(),
    });

    // state to management register error message
    const [registerError, setRegisterError] = useState<UserError>({
        email: {
            message: "",
            status: false
        },
        name: {
            message: "",
            status: false
        },
        lastName: {
            message: "",
            status: false
        },
        country: {
            alpha2Code: {
                message: "",
                status: false
            },
            imgFlag: {
                message: "",
                status: false
            },
            name: {
                message: "",
                status: false
            }
        },
        phone: {
            message: "",
            status: false
        },
        phoneCode: {
            message: "",
            status: false
        },
        phoneCodeFlag: {
            message: "",
            status: false
        },
        password: {
            message: "",
            status: false
        },
        code: {
            message: "",
            status: false
        }
    });

    const [businessError, setBusinessError] = useState<UserCompanyError>({
        itNumber: {
            message: "",
            status: false
        },
        name: {
            message: "",
            status: false
        },
        position: {
            message: "",
            status: false
        },
        typeIt: {
            message: "",
            status: false
        }
    });
    const [emailCode, setEmailCode] = useState<string>('');
    const [phoneCode, setPhoneCode] = useState<string>('');

    const [emailCodeError, setEmailCodeError] = useState<ErrorInterface>({
        message: '',
        status: false
    });

    const [phoneCodeError, setPhoneCodeError] = useState<ErrorInterface>({
        message: '',
        status: false
    });

    const signOff = () => {
        dispatch(
            setAuth({
                isLoggedIn: false,
                displayName: "",
                userId: ""
            }));
    }

    // method to process search parameters 
    const processSearchParams = async (uid: string) => {
        if(searchParams.get('noRedirect') && searchParams.get('noRedirect') === 'true') {
            dispatch(GetUserByUID(uid))
            .unwrap()
            .then((responseUser) => {
                props.redirectToPath();
                props.closeAuthModal();
                setLoading(false)
            })
            .catch((error) => {
                console.log("🚀 ~ file: MainRegister.tsx:407 ~ setTimeout ~ error:", error)
                setLoading(false);
            })
        } else {
            props.setAuthStep("SELECT_SITUATION");
      
        }
    }

    // method register eith google
    const handleGoogleRegister = async () => {
        if(!loading) {
            setLoading(true);
            const toastGogle = toast.loading('Solicitando las credenciales a Google...');
            try {
                const response:any = await authService.RegisterWithGoogle();
                if(response) {
                    const isCreated = await GetUserByEmail(response.email);
                    if(!isCreated) {
                        setLoading(false);
                        toast.update(toastGogle, 
                            {
                                render: 'Credenciales recibidas', 
                                type: 'success', 
                                isLoading: false,
                                autoClose: 2000,
                                closeButton: true,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false
                            }
                        );

                        let splitDisplayName = response.displayName.split(' ');
                        
                        props.setFullRegister((prev)=>({
                            ...prev,
                            name: splitDisplayName[0],
                            lastName: splitDisplayName[1],
                            email: response.email,
                            photoUrl: response.photoURL || '',                          
                            authProvider: 'google',
                            providerToken: response.accessToken,
                            UID: response.uid,
                        }));
                       setStep(1);
                    } else {
                        toast.update(toastGogle, 
                            {
                                render: '¡Ya existe un usuario con esta cuenta!, estamos iniciando sesión con ella 🚢', 
                                type: 'info', 
                                isLoading: false,
                                autoClose: 5000,
                                closeButton: true,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false
                            }
                        );
                        const responseUser = await GetUserByEmail(response.email);
                        dispatch(setAuth({
                            isLoggedIn: true,
                            displayName: responseUser.name+" "+responseUser.lastName,
                            userId: responseUser.id,
                            uid: responseUser.UID,
                            imgProfile: responseUser.photoUrl
                        }));

                        setTimeout(() => { 
                            if(searchParams.get('noRedirect') && searchParams.get('noRedirect') === 'true') {
                                toast.success("¡Inició sesión con éxito!");
                                processSearchParams('');
                                props.closeAuthModal();
                            } else {
                                navigation(`/${OPERATIONS}`, {replace: true});
                                props.closeAuthModal();
                            }
                        }, 3500);
                    }
                } else {
                    toast.update(toastGogle, 
                        {
                            render: 'Error al solicitar las credenciales a Google', 
                            type: 'error', 
                            isLoading: false,
                            autoClose: 3500,
                            closeButton: true,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false
                        }
                    );
                }
            } catch (error) {
                toast.update(toastGogle, 
                    {
                        render: 'Error al registrar usuario con Google', 
                        type: 'error', 
                        isLoading: false,
                        autoClose: 3500,
                        closeButton: true,
                        pauseOnHover: false,
                        pauseOnFocusLoss: false
                    }
                );
                signOff();

               // toast.error("Error al registrar usuario con Google");
                setLoading(false);
            }
        }
    }

    // method to show email validation step
    // const handleEmailRegister = () => {
    //     setStep(1);
    // }

    // method to show login authStep
    const handleLogin = () => {
        props.setAuthStep("MAIN_LOGIN_STEP");
    }

    // method to return internal step
    const handleReturnclick = () => {
        if(step === 0) return false;

        setStep((prev)=> step>0 ? prev-1 : prev);
        return true;
    }

    // handle change country    
    const handleCountryChange = (country: string, alpha2Code: string) => {
        props.setFullRegister((prev:any) => {
            return {
                ...prev,
                country: {
                    alpha2Code: alpha2Code,
                    imgFlag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${(alpha2Code).toLowerCase()}.svg`,
                    name: country,
                },
                phoneCodeFlag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${(alpha2Code).toLowerCase()}.svg`,
            }
        });
    }

    // handle phone number change
    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setFullRegister((prev: any) => {
            return {
                ...prev,
                phone: e.target.value
            }
        })
    }

    // handle is first import change
    const handleIsFirstImportChange = (value: string) => {
        props.setFullRegister((prev: any) => {
            return {
                ...prev,
                isFirstImport: value === "Sí"
            }
        })
    }

    // handle phone code change
    const handlePhoneCodeChange = (e: string) => {
        props.setFullRegister((prev: any) => {
            return {
                ...prev,
                phoneCode: e
            }
        })
    }

    // handle email change
    const handleEmailChange = (e: string) => {
        props.setFullRegister((prev: any) =>({
            ...prev,
            email: e
        }))
    }

    // handle email change
    // const handleCodeChange = (e: string) => {
    //     setEmailCode(e);
    // }

    const handleValidatePhoneCodeChange = (e: string) => {
        setPhoneCode(e);
    }

    // handle password change
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setPassword(e.target.value);
    }

    // handle name change
    const handleNameChange = (e: string) => {
        props.setFullRegister((prev: any) =>({
            ...prev,
            name: e,
            userName: e
        }));
    }

    // handle name change
    const handleLastNameChange = (e: string) => {
        props.setFullRegister((prev: any) =>({
            ...prev,
            lastName: e
        }));
    }

    // handle importartion
    // const handleImportationChange = (e: string) => {
    //     props.setFullRegister((prev: any) =>({
    //         ...prev,
    //         importation: e === 'Si' ? true : false
    //     }))
    // }

    // handle business change
    const handleBusinessNameChange = (e: string) => {
        setUserCompany((prev: any) =>({
            ...prev,
            name: e
        }));
    }

    // handle lastName change
    const handleItNumberChange = (e: string) => {
        let typeIt = "Persona Natural";
        if(e.length>2) {
            let firtLetter = e.slice(0,2);
            typeIt = firtLetter === "20" ? "Persona jurídica" : "Persona Natural"
        }

        setUserCompany((prev: any) =>({
            ...prev,
            itNumber: e,
            typeIt: typeIt
        }));
    }

    // handle lastName change
    const handleBusinessPositionChange = (e: string) => {
        setUserCompany((prev: any) =>({
            ...prev,
            position: e
        }));
    }


    // method to check if email is valid and is register in database
    const checkEmail = async () => {
        if(!validateFielEmailAnddPassword() && !loading){
            setLoading(true);
            try {
                const response: any = await authService.CheckEmailRegister(props.fullRegister.email);
                if(!response) { 
                    setLoading(false);
                    props.setFullRegister((prev: any) =>({
                        ...prev,
                        authStep: 2,
                    }));
                    setStep((prev)=> prev+1);
                } else {
                    setLoading(false);
                    setRegisterError((prev)=>({...prev, email: {message: "Correo electrónico registrado", status: true}}));
                }                 
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: SignUp.tsx:70 ~ handleNextClick ~ error:", error)
                toast.error("Hubo un error al verificar el correo electrónico, por favor intentelo en unos minutos");
           }
        }
    }  

    // method to check if email is valid and is register in database
    const handleValidateRegister = async () => {

        if(!validateFields() && !ValidateField(props.fullRegister.phone, 'required') && !ValidateField(props.fullRegister.phoneCode, 'required') && !loading){
            setLoading(true);
            try {
                const phoneNumber: string = props.fullRegister.phoneCode+""+props.fullRegister.phone; 
                const respCode: any = await authService.sendCodeVerification("phone", phoneNumber);  
                if(respCode) {
                    setStep((prev)=> prev+1);
                }             
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: SignUp.tsx:70 ~ handleNextClick ~ error:", error)
                toast.error("Hubo un error al verificar el código, por favor intentelo en unos minutos");
           }
        } else {
          setPhoneCodeError({message: "Ingrese número válido", status: true});
        }
    }  

    // method to validate register fields
    const validateFields = (): boolean => {
        let error = false;

        error = error || ValidateField(props.fullRegister.name, "required") || 
        ValidateField(props.fullRegister.lastName, "required") || 
        ValidateField(props.fullRegister.phone, "number");

        if(!props.fullRegister.providerToken) {
            error= error;
        }

        setRegisterError({
            ...registerError,
            name: {
                status: ValidateField(props.fullRegister.name, "required"),
                message: "Ingrese nombre"
            },
            lastName: {
                status: ValidateField(props.fullRegister.lastName, "required"),
                message: "Ingrese apellido"
            },
            phoneCode: {
                status: ValidateField(props.fullRegister.phoneCode, "required"),
                message: "Ingrese prefijo"
            },
            phone: {
                status: ValidateField(props.fullRegister.phone, "number"),
                message: "Ingrese número de telefono correcto"
            },
            country: {
                ...registerError.country,
                name: {
                    status: ValidateField(props.fullRegister.country.name, "required"),
                    message: "Ingrese nombre"
                }
            },
            // password:  {
            //     message: "Ingrese contraseña válida",
            //     status:  !ValidateField(props.fullRegister.password, "password")
            // }
        });


        if(showBusinessAccount) {
            error = ValidateField(userCompany?.name, 'required') || ValidateField(userCompany?.itNumber, 'RUC') || 
            ValidateField(userCompany?.position, "required");

            setBusinessError({
                name: {
                    message: "Ingrese Razón social",
                    status:  ValidateField(userCompany?.name, 'required')
                },
                itNumber: {
                    message: 'Ingrese RUC válido',
                    status:  ValidateField(userCompany?.itNumber, 'RUC')
                },
                position: {
                    message: 'Ingrese su cargo actual en la empresa',
                    status:  ValidateField(userCompany?.position, 'required')
                },
                typeIt: {
                    message: '',
                    status:  false
                }
            });
        }
        return error;
    }

    const validateFielEmailAnddPassword = (): boolean => {
        let error = !ValidateField(props.password, "password") || ValidateField(props.fullRegister.email, 'email');

        setRegisterError({
            ...registerError,
            email: {
                message: "Ingrese correo valido", 
                status: ValidateField(props.fullRegister.email, 'email'),
            },
            password: {
                message: "Ingrese contraseña válida",
                status:  !ValidateField(props.password, "password")
            }
        });

        return error;
    }

    // method to save userCompany
    const saveUserBusiness = async (userId: string) => {
        try {
            let response = await CreateUserCompany({...userCompany, userId: userId});
        } catch (error) {
            console.log("🚀 ~ file: MainRegister.tsx:401 ~ saveUserBusiness ~ error:", error)
            
        }
    }

    const handleValidateCode = async (type: "email" | "phone", valueType: string, code: string) => {
        setLoading(true);
        try {
            const response = await authService.ValidateRegisterCode(type, valueType, code);
            if(response) {
                const disableCodes = await authService.DisableCodeRegister(type, valueType, code);
                setLoading(false);
                if(disableCodes) {
                    return true
                } else {
                    return false
                }
            } else {
                setLoading(false);
                toast.warning("Código incorrecto, intentelo otra vez");
                return false;
            }
           
        } catch (error) {
            console.log("🚀 ~ file: MainRegister.tsx:474 ~ handleValidateCode ~ error:", error)
            toast.error("Hubo un error al validar el código, intentelo en unos minutos");
            setLoading(false);
            return false;
        }
    }

    const handldeTypeItChange = (e: string) => {
        props.setFullRegister((prev: any) =>({
            ...prev,
            typeIt: e
        }));
    }

    const handldeItNumberChange = (e: string) => {
        props.setFullRegister((prev: any) =>({
            ...prev,
            itNumber: e
        }));
    }

    // handle register click
    const handleRegisterClick = async () => {
        if(!ValidateField(phoneCode, "required")) {
            const validatePhone = await handleValidateCode("phone", props.fullRegister.phoneCode+""+props.fullRegister.phone, phoneCode);
            if(validatePhone) {   
                props.setFullRegister((prev: any) =>({
                    ...prev,
                    authStep: 3,
                }));  
                if(!props.fullRegister.authProvider) {
                    await handleRegisterWithEmailClick();
                } else {
                    await registerUserWithProvider();
                }
            }
        } else {
            setPhoneCodeError({
                message: "Ingrese código válido",
                status: true
            });
        }
       
    }

    // method to register model user
    const registerUserWithProvider = async () => {
        if(!loading) {
            try {
                setLoading(true);
                const response = await CreateUser({...props.fullRegister, authStep: 3});
                await getUserById(response.id);
                dispatch(setAuth({
                    isLoggedIn: true,
                    displayName: props.fullRegister.name+" "+props.fullRegister.lastName,
                    userId: response.id,
                    uid: props.fullRegister.UID,
                    imgProfile: props.fullRegister.photoUrl
                }));
                if(showBusinessAccount) {
                    await saveUserBusiness(response.id); // save userCompany
                } 
                
               setTimeout(() => {
                    if(props.isFreePlan) {
                        // getUserById(response.id); 
                        props.createSuscription(response.id, "");
                    } else {
                        props.setAuthStep('MAIN_REGISTER_PAY');
                    }
               }, 5600);
            } catch (error) {
                    setLoading(false);
                    signOff();
                    console.log("🚀 ~ file: MainRegister.tsx:429 ~ registerUserWithProvider ~ error:", error)
                    toast.error("Hubo un error al crear el usuario, intentelo en unos minutos");
            }
       }
    }

    // redux get user by uid
    const getUserById =  async (userId: string) => {
        dispatch(GetUser(userId))
        .unwrap()
        .then(async (response) => {
            console.log("🚀 ~ .then ~ response:", response);           
        })
        .catch((error)=> {
            console.log("🚀 ~ file: AdminPage.tsx:232 ~ getUserById ~ error:", error);
            setLoading(false);
        })
    }

    // method to register user with email and link to userCompany
    const handleRegisterWithEmailClick = async () => {
        if(!loading) {
            setLoading(true);
            // const toastMessage = toast.loading('Registrando usuario...');
            dispatch(RegisterWithEmail({user: {...props.fullRegister, authStep: 3}, password: props.password}))
            .unwrap()
            .then(async(response) => {
                if(response) {
                    dispatch(setAuth({
                        isLoggedIn: true,
                        displayName: props.fullRegister.name+" "+props.fullRegister.lastName,
                        userId: response.userId,
                        uid: response.uid,
                        imgProfile: response.imgProfile
                    }));
                   
                    toast.success('¡Se creó la cuenta exitosamente!');
                    GA4_register(props.fullRegister.authProvider?props.fullRegister.authProvider:'email');

                    // is business account
                    if(showBusinessAccount) {
                        await saveUserBusiness(response.userId); // save userCompany
                    }

                    setTimeout(() => { 
                        if(props.isFreePlan) {
                            props.createSuscription(response.userId, "");
                        } else {
                            props.setAuthStep('MAIN_REGISTER_PAY');
                        }
          
                    }, 5600);
                }
            })
            .catch((error: any) => {
                console.log("🚀 ~ file: MainRegister.tsx:451 ~ handleRegisterWithEmailClick ~ error:", error)
                toast.error('Error al registrar usuario');
                signOff();
                setLoading(false);
            })
        }
    }   

    // obteniendo lista de paises
    const getCountries = async () => {
        try {
            const response:any = await GetCountryList();
            setCountryList(response);
        } catch (error) {
            console.log("🚀 ~ getCountries ~ error:", error)
            
        }
    }

    // method to get data from ip
    const getDataFromIp = async () => {
        try {
            const response = await getIpData();
            handleCountryChange(response.country_name, response.country_code);
            handlePhoneCodeChange(response.country_calling_code);
        } catch (error) {
            console.log("🚀 ~ getIpData ~ error:", error)
            throw error;
        }
    }

    // method to send the register event to GA4
    const GA4_register = (method: string) => {
        ReactGA.gtag("event", "sign_up", {
            method: method
        });
    }

    useImperativeHandle(ref, () => ({
        handleReturnclick
    }))

    // get intermal number from parent component
    useEffect(() => {
        (props.internalStep && props.internalStep >0) && setStep(props.internalStep);
    }, [props.internalStep]);

    useEffect(() => {
        getCountries();
        getDataFromIp();
    }, []);    
    
    return (
        <div className={'mainRegisterNew-container'}>
            {!loading ? <>
                {    
                    <div className={'mainRegisterNew-items'+(step >0 ? " mainRegisterNew-full-items" : "")}>
                        <div className='smalltext mainRegisterNew-text mainRegisterNew-bottom'>Crea una cuenta en Arkabia.<span className='mainRegisterNew-link big-subheader'> Paso {phaseRegister} de 2 </span></div>
                        {step === 0 ? <>
                            <TextField 
                                title='Correo electrónico' 
                                placeholder=' '
                                value={props.fullRegister.email}
                                onChange={(e: any) => handleEmailChange(e.target.value)} 
                                error={registerError.email}
                            />
                            {!props.fullRegister.authProvider && <div className='mainRegister-item--password'>
                                <TextFieldPassword
                                    title='Contraseña'
                                    placeholder=" "
                                    type='password'
                                    value={props.password}
                                    onChange={handlePasswordChange}
                                    error={registerError.password}
                                />
                                <div className='mainRegister-password--label tinytext-header '>6 caracteres mínimo, incluyendo números y letras.</div>
                            </div>}
                        </> :
                        step === 1 ? <>
                            <div className='mainRegisterNew-scroll-items'>
                                <TextField 
                                    title='Nombre' 
                                    placeholder=' '
                                    value={props.fullRegister.name}
                                    onChange={(e: any) => handleNameChange(e.target.value)} 
                                    error={registerError.name}
                                />  
                                <TextField 
                                    title='Apellido' 
                                    placeholder=' '
                                    value={props.fullRegister.lastName}
                                    onChange={(e:any) => handleLastNameChange(e.target.value)}
                                    error={registerError.lastName}
                                /> 
                            </div> 
                            <div className='mainRegisterNew-scroll-items'>
                                <PhoneCountry 
                                    phoneCode={props.fullRegister.phoneCode? props.fullRegister.phoneCode:''}
                                    phoneFlag={props.fullRegister.phoneCodeFlag? props.fullRegister.phoneCodeFlag:''}
                                    phone={props.fullRegister.phone? props.fullRegister.phone.toString():''}
                                    countryList={countryList}
                                    handleCountryChange={handleCountryChange} 
                                    handlePhoneChange={handlePhoneChange}
                                    // phoneCode={signUp.phoneCode ? signUp.phoneCode : ''}
                                    handlePhoneCodeChange={handlePhoneCodeChange} 
                                    title="Prefijo"
                                    error={{
                                        phone: registerError.phone,
                                        phoneCode: registerError.phoneCode,
                                    }}
                                />
                                <Dropdown 
                                    title='¿Es tu primera importación?'
                                    elements={["Sí", "No"]}
                                    value={props.fullRegister.isFirstImport ? "Sí" : "No"}
                                    setValue={(e: any)=>handleIsFirstImportChange(e)} />
                            </div>   
                            <div className='mainRegisterNew-scroll-items'>
                                <Dropdown 
                                    title='Tipo de documento'
                                    elements={TypeItConst.map((item)=>item.label)}
                                    value={props.fullRegister.typeIt || "DNI"}
                                    setValue={(e: any)=>handldeTypeItChange(e)} />
                                <TextField 
                                    title='Número de documento'
                                    placeholder=' '
                                    value={props.fullRegister.itNumber}
                                    onChange={(e: any) => handldeItNumberChange(e.target.value)} 
                                    error={registerError.name}
                                />  
                            </div>                         
                            <div className='mainRegisterNew-item--row mainRegister-businessAcount' onClick={()=>setShowBusinessAccount((prev)=>!prev)}>
                                <Checkbox setChecked={()=>{}} checked={showBusinessAccount} />
                                <div className='mainRegister-text paragraph-header'>Cuenta empresarial</div>
                            </div>
                            {showBusinessAccount && <>
                                <div className='mainRegisterNew-scroll-items'>
                                    <TextField 
                                        title='Razón social' 
                                        placeholder=' '
                                        value={userCompany?.name}
                                        onChange={(e: any) =>handleBusinessNameChange(e.target.value)}
                                        error={businessError.name}
                                    /> 
                                    <TextField 
                                        title='RUC' 
                                        placeholder=' ' 
                                        type='number' 
                                        value={userCompany?.itNumber}
                                        onChange={(e: any) =>handleItNumberChange(e.target.value)}
                                        error={businessError.itNumber}
                                    />
                                </div>
                                
                                <TextField 
                                    title='Cargo actual'
                                    placeholder=' '
                                    value={userCompany?.position}
                                    onChange={(e: any) => handleBusinessPositionChange(e.target.value)}
                                    error={businessError.position}
                                />   
                            </>}
                         </> :  
                        step === 2 ? <> 
                            <div className='mainRegister-items-validate'>
                                <div className='paragraph-header mainRegisterNew-text '>Te hemos enviado un código a tu teléfono</div>
                                <div className='paragraph-header mainRegisterNew-text'>{props.fullRegister.phone}</div>
                            </div>
                            <div className='mainRegister-items-phoceCode'>
                                <TextField 
                                    title='Código' 
                                    placeholder=' '
                                    value={phoneCode}
                                    error={phoneCodeError}   
                                    onChange={(e:any) => handleValidatePhoneCodeChange(e.target.value)} 
                                />      
                            </div>                      
                        </> : null
                       }
                    </div>} 
                    
                    <div className='mainRegisterNew-confirm small-subheader' 
                        // step === 3 ? checkPhone() : (step === 1 ? handleEmailValidate()
                        onClick={()=> step === 0 ? checkEmail() : (step === 1 ? handleValidateRegister() : handleRegisterClick())} >
                        {step===0 ? "Siguiente" : (step===1 ? "Siguiente" : (showBusinessAccount ? "Siguiente" : "Siguiente"))}
                    </div>

                   {step === 0 &&  <>
                    <div className='marinRegisterNewSeparator'>
                            <hr className='mainRegisterNew-hr'/>
                            <div className='paragraph mainRegisterNew-o'>o</div>
                            <hr className='mainRegisterNew-hr'/>
                        </div>

                        <ButtonSign 
                            title='Continuar con Google' 
                            icon={<GoogleIcon style={{width: '26px', height: '26px'}} />}
                            onClick={()=>handleGoogleRegister()}
                        />

                        <div className='paragraph mainRegisterNew-text'>¿Ya tienes cuenta? 
                        <span onClick={()=>handleLogin()} className='mainRegisterNew-link big-subheader'> Inicia sesión</span>
                        </div>
                    </>}
            </>: <Loader />}
        </div>
    )
});

export default MainRegisterNew;
