import { CardHoverExpandInterface } from "../interfaces/CardHoverExpandInterface";

export const CardHoverExpandConst:CardHoverExpandInterface[] = 
[
    {
        id: 0,
        title: 'Para importadores', 
        description: 'Obtén las mejores ofertas de importación al instante. Optimiza costos, minimiza riesgos y reduce procesos con facilidad.',
        img: 'import-mobile.png',
        alt: 'Obtén las mejores ofertas de importación al instante.',
        list: [
            {
                img: 'rayo-icon.png',
                item: 'Optimiza costos'
            },
            {
                img: 'expand-icon.png',
                item: 'Minimiza riesgos'
            },
            {
                img: 'crecer-icon.png',
                item: 'Reduce procesos'
            }
        ],
    },
    {
        id: 1,
        title: 'Para Dropshippers', 
        description: 'Únete a las importaciones de los líderes del mercado.',
        img: 'import-mobile2.png',
        alt: 'Únete a las importaciones de los líderes del mercado.',
        list: [
            {
                img: 'rayo-icon.png',
                item: 'Poca inversión'
            },
            {
                img: 'crecer-icon.png',
                item: 'Rápida ejecución'
            },
            {
                img: 'expand-icon.png',
                item: 'Éxito garantizado'
            }
        ],
    },
]
    