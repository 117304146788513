import React from "react";
import './ItemVessels.css';

interface ItemVesselsProps {
    alpha2Code: string;
    name: string;
    description?: string;
    handleclick: () => void;
}

export const ItemVessels: React.FC<ItemVesselsProps> = ({
    alpha2Code,
    name,
    description='',
    handleclick
}) => {
    
    return (
        <div onClick={()=>handleclick()} className="ItemVessels-container">
            {alpha2Code.length> 0 && <img width={25} src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${alpha2Code}.svg`} alt='agencia aduana' />}
            <div className="smalltext-header">{name}</div>
            {description && description.length>0 && <div className="smalltext">{description}</div>}
        </div>
    )
}