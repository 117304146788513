import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

// importando interfaces
import { OrderInterface } from "../interfaces/OrderInterface";

// Importando servicios
import { GetAllOrderActive, GetOrderActivByCompanyId, GetOrderDetail, GetOrderByQuotationId } from "../Services/Order.service";

// metodo para obtener todos los servicios
export const GetOrderActive = createAsyncThunk(
    'order/getOrderActive',
    async (_, thunkAPI: any) => {
        try {
            const response = await GetAllOrderActive();
         
            if(response.length>0) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response;
            }
        } catch (error:any) {
            console.log("slice/order/getOrderActive => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const GetOrderListByCompany = createAsyncThunk(
    'order/orderByCompanyId',
   async (id:string, thunkAPI: any) => {
       try {
           const response:any = await GetOrderActivByCompanyId(id);
        
            thunkAPI.dispatch(setError({ status: false, message:'', code:''}));
            return response;
          
       } catch (error: any) {
            // console.log('slice/requestQuote/requestQuoteByCompanyId => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
       }
   }
)

export const GetOrderById = createAsyncThunk(
    'order/getOrderById',
    async (id: string, thunkAPI: any) => {
        try {
            const response = await GetOrderDetail(id);
            if(response) {
                thunkAPI.dispatch(setError({status: false, message: '', code: ''}));
                return response;
            }
        } catch (error) {
            thunkAPI.dispatch(setError({status: true, message: error, code: 'not-found'}));
            return thunkAPI.rejectWithValue();
        }
    }
)

export const GetOrderQuotation = createAsyncThunk(
    'order/getOrderQuotation',
    async (quotationId: string, thunkAPI: any) => {
        try {
            const response = await GetOrderByQuotationId(quotationId);
            if(response.length>0) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
            }
        } catch (error: any) {
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
)


const initialState = {
    orderList: [],
}

const orderSlice = createSlice({
    name: "orderQuote",
    initialState,
    reducers: {},
    extraReducers: {
        [GetOrderActive.fulfilled.toString()]: (state, action) => {
            state.orderList = action.payload
        },
        [GetOrderActive.rejected.toString()]: (state, action) => {
            state.orderList = []
        },
        [GetOrderListByCompany.fulfilled.toString()]: (state, action) => {
            state.orderList = action.payload
        },
        [GetOrderListByCompany.rejected.toString()]: (state, action) => {
            state.orderList = []
        },
        [GetOrderById.fulfilled.toString()]: (state, action) => {
            state.orderList = action.payload
        },
        [GetOrderById.rejected.toString()]: (state, action) => {
            state.orderList = []
        },
        [GetOrderQuotation. fulfilled.toString()]: (state, action) => {
            state.orderList = action.payload;
        },
        [GetOrderQuotation. rejected.toString()]: (state, action) => {
            state.orderList = [];
        },
    }
})

const {reducer} = orderSlice;
export default reducer;