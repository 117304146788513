import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, query, where, getDocs, doc, addDoc, updateDoc } from "firebase/firestore" 

import { Consignee } from "../interfaces/Consignee";

const GetAllConsigneeByUserId = async(id: string) => {
    const q = query(collection(DB, "consignee"), where("userId", "==", id), where("status", "==", true));
    const response = await getDocs(q); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

const CreateConsignee = async (consignee: Consignee) => {
    try {
        const response = await addDoc(collection(DB, "consignee"), consignee);  
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
        return error;
    }
}

const UpdateConsignee = async (consignee: Consignee) => {
    try {
        if(consignee.id) {
            const requestRef = doc(DB, 'consignee', consignee.id);
            const response = await updateDoc(requestRef, {...consignee});

            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

export {
    GetAllConsigneeByUserId,
    CreateConsignee,
    UpdateConsignee
}