import React, { useEffect, useState } from "react";


// importando componentes
import { Checkbox } from "../../../Checkbox/Checkbox";
import { Bulto } from "./Bulto/Bulto";

// importando interfaces
import { LCLAirInterface, PackageLCLAir } from "../../../../interfaces/LCLAir";
import { ErrorInterface } from "../../../../interfaces/ErrorInterface";
import {ContainerType} from "../../../../interfaces/ContainerType";

// importando estilos
import './LCLCDAir.css';
import { ConvertToKilogram } from "../../../../utils/ConvertToKilogram";
import { ConvertToMeters } from "../../../../utils/ConvertToMeters";
import { TransportLCLAirError } from "../../../../interfaces/TrasportLCLAirError";
import { Dropdown } from "../../../Dropdown/Dropdown";
import { InputElement } from "../../../InputElement/InputElement";
import { CargoDescription } from "../../../../Consts/CargoDescription";
import { UMListKilogramsMobile, UMListVolumeMobile } from "../../../../Consts/UMlist";


interface LCLCDAirProps {
    typeContainer: ContainerType | undefined;
    lclcdAir?: LCLAirInterface;
    handleLclAirChange: (lclcdAir: LCLAirInterface) => void;
    error?: TransportLCLAirError
    isCourier?: boolean;
    scrollToBottom?: ()=>void;
}

export const LCLCDAir: React.FC<LCLCDAirProps> = ({
    lclcdAir = {
        cargoDescription: 'Caja',
        weight: undefined,
        unitWeight: 'kg',
        unitVolume: 'm³',
        packages: [],
        packingType: "volumen",
        volume: undefined
    },
    handleLclAirChange,
    error,
    typeContainer,
    isCourier = false,
    scrollToBottom = ()=>{}
}) => {

    const [type, setType] = useState<'volumen' | 'paquetes'>('paquetes');

    // useState
    const [bultos, setBultos] = useState<PackageLCLAir[]>(lclcdAir.packages && lclcdAir.packages.length? lclcdAir.packages : [{
        numBulto: 0,
        weight: 0,
        unitWeight: 'kg',
        // volume: 0,
        // unitVolume: 'Metros cúbicos',
        unit: 'm',
        width: 0,
        length: 0,
        tall: 0,
        cargoDescription: 'Caja'
    }]);

    // metodos
    // metodo editar bulto
    const handleChangeInput = (type: 'weight' | 'unitWeight' | 'volume' | 'unitVolume' | 'cargoDescription', value: any) => {
       handleLclAirChange({
            ...lclcdAir,
            [type]: value
        })    
    
    }

    // metodo para agregar bulto con campos vacios
    const handleAddBulto = () => {
        setBultos((prev) => [...prev, 
            {
                numBulto: undefined,
                weight: undefined,
                unitWeight: 'kg',
                // volume: undefined,
                // unitVolume: 'Metros cúbicos',
                unit: 'm',
                width: undefined,
                length:undefined,
                tall: undefined,
                cargoDescription: 'Caja',
                stackable: "Si"
            }
        ])
    }
    
    // metodo editar bulto
    const handleChangeBulto = (id: number, type: "cargoDescription" | 'numBulto' | 'weight' | 'unitWeight' | 'width'| "length" | "tall" | "unit" , value: any) => {
    setBultos(bultos.map((bulto, index) => {
        if (index === id) {
            switch (type) {
              case 'cargoDescription':
                return {
                    ...bulto,
                    cargoDescription: value
                }
                case 'numBulto':
                    return {
                        ...bulto,
                        numBulto: value
                    }
                case 'weight':
                    return {
                        ...bulto,
                        weight: value
                    }   
                case 'unitWeight':
                    return {
                        ...bulto,
                        unitWeight: value
                    } 
                case 'width':
                    return {
                        ...bulto,
                        width: value
                    }
                case 'tall':
                    return {
                        ...bulto,
                        tall: value
                    }
                case 'length':
                    return {
                        ...bulto,
                        length: value
                    }
                case 'unit':
                    return {
                        ...bulto,
                        unit: value
                    }
            }
        }
        return bulto;
    }));
    }

    const handleRemoveBulto = (id: number) => {
        setBultos(bultos.filter((bulto, index) => index !== id));
    }

    const handleTypechange = (type: "volumen" | "paquetes") => {
        setType(type);
        handleLclAirChange({
            ...lclcdAir,
            packingType: type
        })
    }

    const handlePackageChange = (packages: PackageLCLAir) => {
        handleLclAirChange({
            ...lclcdAir,
            packages: [...lclcdAir.packages, packages]
        })
    }

    // method to get total weight
    const getTotalWeight = (): number => {
        let total = bultos.reduce((acum, b) => 
                    acum + ((b.numBulto ? b.numBulto : 0) * (b.weight ? ConvertToKilogram(b.weight, b.unitWeight) : 0))
                    , 0)
        lclcdAir.weight !== total && handleChangeInput("weight", total);
        return Number((total).toFixed(2));
    }

    // method to get total volume
    const getTotalVolume = (): number => {
        let total = bultos.reduce((acum, b) => 
                    acum + Number(((b.width ? ConvertToMeters(b.width, b.unit) : 0) * (b.length ? ConvertToMeters(b.length, b.unit) : 0) * 
                    (b.tall ? ConvertToMeters(b.tall, b.unit) : 0) * (b.numBulto ? b.numBulto : 0) ).toFixed(4))
                    , 0)
        lclcdAir.volume !== total && handleChangeInput("volume", total);
        return total;
    }

    useEffect(() => {
        handleLclAirChange({
            ...lclcdAir,
            packages: bultos
        });
    }, [bultos]);

    useEffect(() => {
        handleTypechange("paquetes");
    }, []);

    return (
        <div className="lclcdAir-container">
            <div className="lclcdAir-header">
                {/* <Checkbox checked={typeContainer?.lclAir} setChecked={(e)=>setTypeContainer({lclAir: e})} />
                <div className="lclcdAir-header--title">
                    <div className="paragraph lclcdAir-colorText">Contenedor compartido</div>
                    <div className="paragraph-header lclcdAir-colorText--">LCL</div>
                </div> */}
                <div className="lclcdAir-header--check">
                    {/* <Checkbox checked={type === "volumen"} setChecked={()=>handleTypechange("volumen")} />
                    <div className="lclcdAir-colorText smalltext-header">Volumen</div> */}
                    <Checkbox checked={type === "paquetes"} setChecked={()=>handleTypechange("paquetes")} />
                    <div className="lclcdAir-colorText smalltext-header">Paquetes</div>
                </div>
            </div>
            {type === "volumen" && <div className="lclcdAir-volumen">
                <div className="lclcdAir-volumen-col ">
                    <Dropdown
                        title={window.innerWidth>830 ? "Tipo de embalaje" : "Embalaje"}
                        elements={CargoDescription}
                        setValue={(e: any) => handleChangeInput('cargoDescription', e)}
                        value={lclcdAir.cargoDescription || 'Caja'}
                        onClick={()=>scrollToBottom()}
                    />
                </div>
                 <div className="lclcdAir-volumen-col lclcdAir-volumen-col--inputs">
                    <InputElement 
                        name='weight' 
                        title="Peso" 
                        value={lclcdAir.weight} 
                        onChange={(e:any) =>handleChangeInput('weight', e)} 
                    />

                    <Dropdown
                        title={"Unidad"}
                        elements={UMListKilogramsMobile}
                        setValue={(e: any) => handleChangeInput('unitWeight', e)}
                        value={lclcdAir.unitWeight || 'kg'}
                        onClick={()=>scrollToBottom()}
                    />
                 </div>
                 <div className="lclcdAir-volumen-col lclcdAir-volumen-col--inputs">
                    <InputElement 
                        name='Volumen' 
                        title="Volúmen" 
                        value={lclcdAir.volume} 
                        onChange={(e:any) =>handleChangeInput('volume', e)} 
                        decimal={4}
                        />
                    <Dropdown
                        title={"Unidad"}
                        elements={UMListVolumeMobile}
                        setValue={(e: any) => handleChangeInput('unitWeight', e)}
                        value={lclcdAir.unitVolume}
                        onClick={()=>scrollToBottom()}
                        
                    />
                 </div>
            </div>}
            {/* <div className="paragraph-header bulto-colorText">Carga Nro1</div> */}
            {type === "paquetes" && typeContainer?.lclAir && 
                bultos && bultos.map((b, idx) => (
                    <Bulto 
                        key={idx}
                        num={idx + 1}
                        add={idx === (bultos.length - 1)} 
                        bulto={b}
                        isCourier={isCourier}
                        handleChange={handleChangeBulto}
                        handleAdd={handleAddBulto}
                        handleRemove={handleRemoveBulto}
                        handlePackageChange={handlePackageChange}
                    />
                ))
            }
            {error && error?.items.status && <div className="text-error tinytext-header" >{error.items.message}</div>}
            {lclcdAir.packingType === "paquetes" && <div className="lclcdAir-paquete-container">
                <div className="lclcdAir-paquete--total">
                  <span className="small-bold lclcdAir-paquete--total_send">Envío total:</span>
                  <div className="small-subheader lclcdAir-paquete-colorBlack">{`${getTotalWeight()}  kg;  ${getTotalVolume()} m3`}</div>
                </div>

            </div>}
            {error && error?.list.status && <div className="text-error tinytext-header" >{error.list.message}</div>}
        </div>
    )
}