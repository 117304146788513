import React from "react";

// import styles
import './TextAreaInput.css';

interface TextAreaInputProps {
    title: string;
    value?: any;
}

export const TextAreaInput: React.FC<TextAreaInputProps> = ({
    title,
    value
}) => {

    return (
      <div className='textAreaInput' role='tabs'>
        <div className="textAreaInput-title paragraph">
            {title}
        </div>
        <div className="textAreaInput-answer" dangerouslySetInnerHTML={{__html: value}}>
        </div>
      </div>
    );
}