import React, { useState } from "react";

/** importando iconos */
import { ArrowDown } from "../../icons/solid/ArrowDown";
import { ArrowUp } from "../../icons/outline/ArrowUp";
import { Edit } from "../../icons/outline/Edit";
import { BackGray } from "../../icons/outline/BackGray";

import { GithubPicker } from 'react-color';
import { CirclePicker } from 'react-color'

/** importando componentes */
import { Button } from "..";
import { TextField } from "..";
import { Checkbox } from "../Checkbox/Checkbox";

/** importando constantes */
import { ColorArray } from "../../Consts/ColorsArray";
import { ColorArrayMin } from "../../Consts/ColorsArrayMin";

/** importandos estilos */
import './SelectCategoryEvent.css';

import { CategoryEvent } from "../../interfaces/Event";

interface SelectCategoryEventProps {
    show: boolean;
    tag: any[];
    service: any[];
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    newCategory: CategoryEvent;
    setNewCategory: React.Dispatch<React.SetStateAction<CategoryEvent>>;
}

export const SelectCategoryEvent: React.FC<SelectCategoryEventProps> = ({
    show,
    tag,
    service,
    setShow,
    newCategory,
    setNewCategory,
}) => {
    const [addTag, setAddTag] = useState(false);
    const [categorySelected, setCategorySelected] = useState<CategoryEvent | undefined>(undefined);

    const handleColorClick = (color: any, event: any) => {
        setNewCategory((prev)=> {
            return {
                ...prev,
                color: color.hex
            }
        });
    }

    const handleTitleChange = (e: string) => {
        setNewCategory((prev) => {
            return {
                ...prev,
                title: e
            }
        })
    }

    const handleCategoryClick = (category: CategoryEvent) => {
        setNewCategory((prev) => {
            return {
                ...prev,
                id: category.id
            }
        });
        setCategorySelected(category);
    }

    return (
        <div className="selectCategoryEvent-container" role='select-category-event'>
            <div className="selectCategoryEvent-title" onClick={()=>setShow(!show)}  role='btn-select-category'>
                <div className="paragraph-header selectCategoryEvent-title--colorText"> 
                    {categorySelected !== undefined ? 
                    <div style={{flexDirection: 'row', display: 'flex', gap: 10}}>
                        <div className={"selectCategoryEvent-service--box"} style={{background: categorySelected.color}}></div>
                        <div className="paragraph-header selectCategoryEvent-service--name">{categorySelected.title}</div>
                    </div>
                    : 'Selecciona tu categoría'}
                </div>

                {show ? <ArrowUp/> : <ArrowDown />}
            </div>


            {show && (
                addTag ?
                    <div className="selectCategoryEvent-content">
                        <div className="selectCategoryEvent-add-title" onClick={()=>setAddTag(false)}>
                            <BackGray/>
                            <div className="paragraph-header selectCategoryEvent-add-color-title">Agrega un título a tu etiqueta</div>
                        </div>
                         
                        <TextField title="Ej: Reunión" placeholder=" " value={newCategory.title} onChange={(e:any) =>{handleTitleChange(e.target.value)}}/>

                        <div className="selectCategoryEvent-content-color-responsive">
                            <CirclePicker circleSize={18} circleSpacing={0} colors={ColorArrayMin} onChangeComplete={handleColorClick}/>
                            <div className="selectCategoryEvent-content-color-select" style={{background: newCategory.color}}></div>
                        </div>

                        <div className="selectCategoryEvent-content-color">
                            <GithubPicker width="170%" colors={ColorArray} onChangeComplete={handleColorClick}/>
                            
                            <div className="selectCategoryEvent-content-color-select" style={{background: newCategory.color}}></div>
                        </div>
                        <div className="selectCategoryEvent-button">
                            <Button content="Cancelar" color="black-25" size="large" onClick={()=>setAddTag(false)}/>
                            <Button content="Agregar" color="yellow" size="large" onClick={()=>setAddTag(false)}/>
                        </div>
                    </div> :

                    <div className="selectCategoryEvent-category">
                    <div className="smalltext-header selectCategoryEvent-title--colorText selectCategoryEvent-borderBottom"> Servicios activos </div>
                    
                    {/** loop de servicios */}
                    {service.length>0 && service.map((s) => 
                        <div key={s} className="selectCategoryEvent-service___"> 
                            <div style={{flexDirection: 'row', display: 'flex', gap: 10}}>
                                <Checkbox setChecked={()=>{}}/>
                                <div className="paragraph-header selectCategoryEvent-service--name">Carros de juguete para navidad</div>
                            </div>
                            <Edit style={{alignSelf: 'center'}}/>
                        </div>
                    )}

                    <div className="selectCategoryEvent-tag">
                        <div className="smalltext-header selectCategoryEvent-title--colorText">Creadas</div>
                        <div onClick={() => setAddTag(true)} className="smalltext-header selectCategoryEvent-title--textBlue">+ Agregar etiqueta</div>
                    </div>

                    {/** loop de etiquetas */}
                    {tag.length === 0 ? 
                        <>
                        {service.length>0 && service.map((s) => 
                            <div key={s} className="selectCategoryEvent-service___"> 
                                <div style={{flexDirection: 'row', display: 'flex', gap: 10, cursor: 'pointer'}} 
                                     onClick={()=>{handleCategoryClick({id: 1, title: 'Carros de juguete para navidad', color: '#EB5757', userId: 1})}}>
                                    <div className="selectCategoryEvent-service--box"></div>
                                    <div className="paragraph-header selectCategoryEvent-service--name">Carros de juguete para navidad</div>
                                </div>
                                <Edit style={{alignSelf: 'center'}}/>
                            </div>
                        )}
                        </>:
                        <div className="smalltext-header selectCategoryEvent-title--colorText"> No tienes etiquetas creadas todavía </div>}
                </div>
            )}
        </div>
    )
}