import React, { useEffect, useRef, useState } from 'react';

import './CarouselInfinitive.css';
import { CommentsConsts } from '../../Consts/CommentsConsts';
import { CardComment } from '../CardComment/CardComment';
import { STORAGE_ASSETS } from '../../Consts/BaseUrl';

interface CarouselInfinitiveProps {
}

export const CarouselInfinitive: React.FC<CarouselInfinitiveProps> = ({

}) => {

    return (
        // <div className='eight-section-list-comment'>
        //     {CommentsConsts.concat(CommentsConsts).map((c, idx) =>
        //         <CardComment
        //             key={idx}
        //             commentItem={c}
        //         />
        //     )}
        // </div> 

        <div className="slider">
            <div className="slide-track">
                {CommentsConsts.concat(CommentsConsts).map((c, idx) =>
                     <CardComment
                         key={idx}
                         commentItem={c}
                     />
                 )}
            </div>
        </div>
    );
};