import React, { useCallback, useEffect, useState }  from "react";
import { useNavigate, useParams } from "react-router-dom";

// importando componentes
import { ChatHeader } from "../../../components/ChatHeader/ChatHeader";
import { Chat } from "../../../components/Chat/Chat";

/**importando datos de prueba */
import { OPERATIONDATA } from "../../../testdata/fakedata";
import { UserList } from "../../../testdata/UserList";

//importando estilos
import './ChatDetailPage.css';
import { GetChatById } from "../../../Services/Chat.service";
import { ChatInterface } from "../../../interfaces/ChatInterface";
import { Loader } from "../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { ModalSearchUser } from "../../../components/Modal/ModalSearchUser/ModalSearchUser";
import { sendProviderMessageNotification, sendUserMessageNotification } from "../../../Services/Whatsapp.service";
import { GetUserById } from "../../../Services/User.service";

import { OperationHeaderOptionInterface } from "../../../interfaces/OperationHeader";
import { ModalNotification } from "../../../components/Modal/ModalNotification/ModalNotification";
import { BackArrowBlack } from "../../../icons/outline/BackArrowBlack";

import { AttachChat } from "../../../interfaces/MessageInterface";
import { SearchTextField } from "../../../components/SearchTextField/SearchTextField";
import { ModalQuote } from "../../../components/Modal/ModalQuote/ModalQuote";
import { ServiceInterface } from "../../../interfaces/Service";
import { Company } from "../../../interfaces/Company";
import { RequestQuoteInterface } from "../../../interfaces/RequestQuote";
import { GetServiceByCompanyId } from "../../../Services/Service.service";
import { OPERATIONS } from "../../../Consts/BaseUrl";
import { toast } from "react-toastify";

interface ChatProps {
    
}

export const ChatDetailPage: React.FC<ChatProps> = () => { 

    // navigation
    let navigation = useNavigate();
    // paramas url
    let params = useParams();

    //get userDetail for redux
    const {userDetail} = useSelector((state: any) => state.user);

    // loading
    const [loading, setLoading] = useState<boolean>(false);

    // use state detail chat
    const [chatDetail, setchatDetail] = useState<ChatInterface>();

    const [showHeaderInSearch, setShowHeaderInSearch] = useState<boolean>(false);
    const [showArchive, setShowArchive] = useState(false);
    const [activeMenuRight, setActiveMenuRight] = useState<boolean>(false);
    const [activeSearchMessage, setActiveSearchMessage] = useState<boolean>(false);
    const [activeSubMenu, setActiveSubmenu] = useState('Imágenes');

    //const is Image or is Document in archive
    const [isDocument, setIsDocument] = useState<AttachChat[]>([]);
    const [isImage, setIsImage] = useState<AttachChat[]>([]);

    // useState search
    const [searchText, setSearchText] = useState<string>('');

     // useState to show modal add member
     const [modalAddMember, setModalAddMember] = useState<boolean>(false);

     // useState to list menu options header
    const [menuOptionHeader, setMenuOptionHeader] = useState<OperationHeaderOptionInterface[]>([
        {
            name: 'Buscar',
            method: () => (setShowHeaderInSearch(true), setActiveSearchMessage(true))
        },
        {
            name: 'Archivos y Doc.',
            method: () => (window.innerWidth > 1155 ? setShowArchive((prev) => !prev) : (setShowArchive(true), setActiveMenuRight(true))),
        },
        //   
        // {
        //     name: 'Reportar',
        //     method: () => {},
        // },
        // {
        //     name: 'Descargar chat',
        //     method: () => {},
        // }
    ]);

    /** state cotizacion */
    const [requestQuote, setRequestQuote] = useState<RequestQuoteInterface>({
        id: "",
        title: "",
        number: 0,
        user: {
            id: '',
            name: '',
            typeIt: '',
            itNumber: 0,
            numOperations: 0,
            phone: '',
            rating: 0,
        },
        companyName: '',
        serviceId: '',
        serviceTypeId: 'agenciamiento-de-aduana',
        serviceTypeLabel: 'Agenciamiento de aduana',
        status: 'published',
        statusProvider: 'No leída',
        active: true,
        category: 'Importación',
        customs: {
            regime: 'Importación definitiva',
            incoterm: 'FOB',
            value: 0,
            coin: '',
            insurance: undefined,
            merchandisePermits: {
                dataSheet: [],
                proforma: [],
                packingList: [],
            },
            exwDirection: '',
            descriptionOperation: '',
        },
        transport: {
            type: undefined,
            origin: {
                city: '',
                country: '',
            },
            destination: {
                city: '',
                country: '',
            },
            typeContainer: {
                lcl: false,
                fcl: false,
                lclAir: false
            },      
        },
        phaseDraft: 0,
        operationId: '',
        numServiceComments: 0,
        numServiceRating: 0,
        observation: '',
        autogenerated: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        country: {
            alpha2Code: 'PE',
            city: 'Lima',
            imgFlag: 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fflag_of_Peru.png?alt=media&token=d8bfda2c-4c48-419a-b8b9-00a423a24d53'
        }
    });
    
    /** useState del modal cotizacion */
    const [showModalQuote, setShowModalQuote] = useState<boolean>(false);

    // useState service
    const [service, setService] = useState<ServiceInterface>();

    // useState company
    const [company, setCompany] = useState<Company>();

    const handleGoBackClick = () => {
        navigation(-1);
    }

    // handle seach text change
    const handleSeachChange = useCallback(
        debounce((text)=> {
        setSearchText(text);
        }, 500), [searchText]);

    // list service detail
    const getServiceByCompany = async (companyId: string | undefined) => {
        if(!loading && companyId) {
            setLoading(true);
            try {
                const response:any = await GetServiceByCompanyId(companyId);
                const service = response.find((s: ServiceInterface) => s.serviceType === "agenciamiento-de-aduana")
                setService(service);
                setLoading(false)
                
            } catch (error) {
                console.log("🚀 ~ file: ChatPage.tsx:234 ~ GetService ~ error:", error)
                setLoading(false)
            }
        }
    }
 
    // method to get chat by id
    const getChat = (chatId: string) => {
        GetChatById(chatId, (value)=>{
            if(value.members.findIndex((m: any) => m.userId === userDetail.id)!== -1) {
                setchatDetail(value); 
                getServiceByCompany(value.members.find((m: any) => m.userId !== userDetail.id && m.isCompany)?.companyId);
                getDocumentByType(value);
            } else {
                navigation('/'+OPERATIONS);
                toast.error('No tienes permisos para ver este chat');
            }
           
        });
    }
    
    const handleShowModalAddMember = () => {
        setModalAddMember(true);
    }

   // method to send whatsapp notification to provider
   const sendProviderWspNotification = async () => {
    try {
        // find all user to send whatsapp notification
        const users = chatDetail ? chatDetail.members.filter((m) => (m.userId !== userDetail.id && !m.isCompany)) : [];
        // iterate users to send whatsapp notification
        users.length> 0 && users.forEach(async (user) => {
            // get user detail by id
            const respUser: any = await GetUserById(user.userId);
            // send whatsapp notification
            sendUserMessageNotification(respUser.phoneCode+""+respUser.phone, respUser.name + " " + respUser.lastName, chatDetail?.companyName ? chatDetail.companyName : "")
            .then((wsp) => {
                // console.log("🚀 ~ file: ChatPage.tsx:490 ~ .then ~ wsp:", wsp);
            }).catch((errorWsp) => {
                console.log("🚀 ~ file: ChatPage.tsx:492 ~ .then ~ error:", errorWsp);
            });      
        });
        
    } catch (error) {
        console.log("🚀 ~ file: ChatPage.tsx:488 ~ sendProviderWspNotification ~ error:", error);
    }
  }

    const getDocumentByType = (chatDetail: ChatInterface) => {
    let tmpImg: AttachChat[] = [];
    let tmpDoc: AttachChat[] = [];

    chatDetail?.attachments.forEach((a) => {
        let nameSplit = a.fileName.split('.');
        return (
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='jpg' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='jpeg' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='png' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='gif' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='ttf' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='bmp' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='tiff'
        ) ?
        tmpImg.push(a): tmpDoc.push(a)
    })

    setIsImage(tmpImg);
    setIsDocument(tmpDoc);
  }

  // method to send whatsapp notification to user
  const sendUserWspNotification = async () => {
      try {
          // find all user to send whatsapp notification
          const users = chatDetail ? chatDetail.members.filter((m) => (m.userId !== userDetail.id && m.isCompany)) : [];
          // iterate users to send whatsapp notification
          users.length> 0 && users.forEach(async (user) => {
              // get user detail by id
              const respUser: any = await GetUserById(user.userId);
              sendProviderMessageNotification(respUser.phoneCode+""+respUser.phone, userDetail.name + " " + userDetail.lastName, chatDetail?.companyName ? chatDetail.companyName : '')
              .then((wsp) => {
                  // console.log("🚀 ~ file: ChatPage.tsx:505 ~ .then ~ wsp:", wsp);
              }).catch((errorWsp) => {
                  console.log("🚀 ~ file: ChatPage.tsx: 507 ~ .then ~ error:", errorWsp);
              });
          });
      } catch (error) {
          console.log("🚀 ~ file: ChatPage.tsx:510 ~ sendUserWspNotification ~ error:", error)
      }
  }

  // method to send whatsapp notification
  const sendWhatsappNotification =() => {
    try {
        userDetail.userType.includes('user') ? sendUserWspNotification() : sendProviderWspNotification();
    } catch (error) {
        console.log("🚀 ~ file: ChatPage.tsx:491 ~ debounce ~ error:", error);
    }
    }

    const handleShowModalRQClick = async () => {
        if(!loading) {
            setLoading(true)
            // const memberProvider = chatSelected?.members.find((m) => (m.userId !== userDetail.id && m.isCompany));
            // loadRequestQuote(memberProvider?.companyId?memberProvider.companyId: '', memberProvider?.companyName?memberProvider.companyName: '');
            setLoading(false)
            setShowModalQuote(true);
        } 
    }

    // use effect
    useEffect(() => {
        if(params && params.chatId && params.chatId.length > 0) {
            getChat(params.chatId);
        }

        if(menuOptionHeader.findIndex((m) => m.name === "Solicitar cotización") === -1 && userDetail.userType.includes('user')){
            setMenuOptionHeader((prev) => [...prev,   {
                name: 'Solicitar cotización',
                method: () => (handleShowModalRQClick())
            }]);
        }
    }, [userDetail]);

    return (
        <div className="chat-message-page">
            {!loading && chatDetail? 
            <div className="chat-message">
                {!activeSearchMessage && 
                <ChatHeader
                    onclickBack = {handleGoBackClick}
                    backIcon
                    order={OPERATIONDATA.order}
                    // operationType={OPERATIONDATA.operationType}
                    teams={OPERATIONDATA.teams}
                    userList={UserList.splice(0,2)}
                    
                    userId={chatDetail? chatDetail.members.find(m => m.userId !== userDetail.id)?.userId : ''}
                    userImg={chatDetail ? chatDetail.members.find(m => m.userId !== userDetail.id)?.userProfile : ''}
                    userName={chatDetail ? chatDetail.members.find(m => m.userId !== userDetail.id)?.userName : ''}
                    userLastName={chatDetail ? chatDetail.members.find(m => m.userId !== userDetail.id)?.userLastName : ''}
                    
                    /** Detalles de la compañia */
                    companyName={chatDetail ? chatDetail.members.find(m => m.userId !== userDetail.id)?.companyName : ''}
                    companyImage={chatDetail ? chatDetail.members.find(m => m.userId !== userDetail.id)?.companyImage : ''}
                    isCompany={chatDetail ? chatDetail.members.find(m => m.userId !== userDetail.id)?.isCompany : false}
                    isGroup={chatDetail?.typeChat === "group"}
                    black

                    // memebers chat 
                    handleSearch={handleSeachChange}
                    members={chatDetail ? chatDetail.members : []}
                    handleAddMemberClick={handleShowModalAddMember}

                    // menu options
                    optionMenu={menuOptionHeader}
                >
                </ChatHeader>}

                {activeSearchMessage && 
                <div className={"chatDetail-search-responsive"}>
                    <BackArrowBlack onClick={()=>setActiveSearchMessage(false)} style={{cursor: 'pointer'}} className="chat-right-search_active-icon-search"/>
                    <SearchTextField 
                        setTextSearch={(e)=> handleSeachChange(e)}
                        title='Buscar'
                        darkIcon
                        handleClick={()=> !activeSearchMessage ? setActiveSearchMessage(true) : {}}
                    />
                    {/* <div className="chat-right-up-down">
                        <UpDropdown/>
                        <DownDropdown/>
                    </div> */}
                </div>}

            <div className={activeSearchMessage ? "chat-message-search" : "chat-message-page-content"}>
                <Chat 
                    chatId={chatDetail.id?chatDetail.id:''} 
                    userId={userDetail.id} 
                    userName={userDetail.name+" "+userDetail.lastName} 
                    isGroup={chatDetail?.typeChat === "group"}
                    searchText={searchText}
                    sendWhatsappNotification={sendWhatsappNotification}
                    paramHeight={window.innerHeight}
                />
            </div>
            </div> : <Loader/>}
            <ModalSearchUser
                title="Buscar usuario"
                subTitle=""
                description="Busca a un miembro de tu empresa y agregalo a la operación"
                show={modalAddMember}
                setShow={setModalAddMember}
                onClickSend={()=> {}}
            />
            
            <ModalNotification                                                                                            
                show={showArchive} 
                setShow={setShowArchive}
                setActive={setActiveSubmenu}
                active={activeSubMenu}
                subMenu={['Imágenes', 'Documentos']}
                title={
                    <div className="chat-modal-archive-title">
                        <BackArrowBlack 
                            onClick={()=>(setActiveMenuRight(false), 
                                setShowArchive(false))} 
                                style={{cursor: 'pointer'}}
                        />
                        <div className="paragraph-header chatDetail-title">
                            {chatDetail ? chatDetail.members.find(m => m.userId !== userDetail.id)?.companyName : ''}
                        </div>
                    </div>
                }
            >
                {activeSubMenu==='Imágenes' ? 
                <>
                    {isImage.length>0 ?<div className="chatDetail-archive-list-imgs">
                        {isImage.map((i, idx)=> (
                        <div key={idx} className="chatDetail-archive-imagen">
                            <img src={i.fileUrl} className="modal-imagenes-content"/> 
                        </div>
                        ))}
                    </div>: <div className="smalltext modal-notification-nothing">No tienes ninguna imagen</div> }
                </>: 
                <>
                    {isDocument.length>0 ?<div className="chat-modal-archive-list">
                        {isDocument.map((d, idx)=> (
                            <div key={idx} className="chat-modal-archive-documents">
                                <img src={d.fileIcon} className="chat-modal-archive-documents-img"/>
                                <div className="smalltext modal-documents-text">{d.fileName}</div>
                            </div>
                        ))}
                    </div> : <div className="smalltext modal-notification-nothing">No tienes ningún documento</div>}
                </>}
            </ModalNotification>
            {showModalQuote && <ModalQuote
                hiddenConfirm
                serviceDetail={service}
                requestQuote={requestQuote}
                setRequestQuote={setRequestQuote}
                show={showModalQuote}
                setShow={setShowModalQuote}
                fromPage="serviceDetail"
            />}
        </div>
    );
};