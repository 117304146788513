import React, { useState, ChangeEvent, useEffect } from 'react';
import './CreditNumber.css';
import { STORAGE_ASSETS } from '../../Consts/BaseUrl';
// import { STORAGE_ASSETS } from '../../Const/BaseUrlConst';

interface CreditNumberProps {
    textCard?: boolean;
    brandCard?: string;
    lastDigits?: string;
}

export const CreditNumber: React.FC<CreditNumberProps> = ({
    textCard=false,
    brandCard='Visa',
    lastDigits=''
}) => {

  const [cardType, setCardType] = useState('visa.png');
  const [cardName, setCardName] = useState('');


  const getBrandCardName = (brand: string) => {
    if (brand === "Visa") {
      setCardType(STORAGE_ASSETS+'/images/png/visa.png');
      setCardName('Visa');
    } else if (brand === "MasterCard") {
      setCardType(STORAGE_ASSETS+'/images/png/mastercard.png');
      setCardName('Mastercard');
    } else if (brand === 'Amex') {
      setCardType(STORAGE_ASSETS+'/images/png/amex.png');
      setCardName('American Express');
    } else if (brand === 'Diners') {
      setCardType(STORAGE_ASSETS+'/images/png/dinner.png');
      setCardName('Diners Club');
    } else {
      setCardName('');
    }
  }

  useEffect(() => {
    if (brandCard) {
      getBrandCardName(brandCard);
    }
  }, []);

  return (
    <div className='creditNumber'>
       {!textCard && <div className='tinytext-header creditNumber-textColor'>Tarjeta de pago</div>}
        <div className='creditNumber-input'>
            <div className='creditNumber-data'>
              <div className='creditNumber-icon'>
                  <img src={process.env.PUBLIC_URL + cardType}/>
              </div>
              <div className='creditNumber-detail'>
                {textCard && <div className='smalltext-header creditNumber-text'>{cardName}</div>} 
                {lastDigits.length>0 && <div className='smalltext-header creditNumber-number'>**** **** {lastDigits}</div>}
              </div>
            </div>
        </div>
    </div>
  );
};
