import React, { useRef, useState } from "react";

import './IconAnimation.css';
import { Animation } from "../../icons/outline/Animation";
import { PlayIcon } from "../../icons/outline/PlayIcon";
import { PlayIconActive } from "../../icons/outline/PlayIconActive";

interface IconAnimationProps {
    isActive: boolean;
    handleClick?: ()=>void;
}

export const IconAnimation: React.FC<IconAnimationProps> = ( {
    isActive,
    handleClick=()=>{}
}) => {


    return (
        <div role='iconMessageBadgetRole' 
            className="paragraph-header iconMessagBadget"
            onClick={()=>handleClick()}>
                {isActive ? <PlayIconActive/> : <PlayIcon/>}
                {/* {badget>0 && 
                    <div className={
                            badget >= 100 ? 
                                "tinytext iconMessagBadget-badget-100" : 
                                "tinytext iconMessagBadget-badget"}>
                                    {badget}
                    </div>
                } */}
        </div>
    )
}      