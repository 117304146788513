export const ColorArrayMin: string[] = 
[      
    '#c0392b',
    '#e74c3c',
    '#9b59b6',
    '#8e44ad',
    '#2980b9',
    '#3498db',
    '#1abc9c',
    '#16a085',
    '#27ae60',
    '#2ecc71',
    '#f1c40f',
    '#f39c12',
    '#e67e22',
    '#d35400',
    '#ecf0f1',
    '#bdc3c7',
    '#95a5a6',
    '#7f8c8d',
    '#34495e',
    '#2c3e50',
    
    
    '#a93226',
    '#cb4335',
    '#884ea0',
    '#7d3c98',
    '#2471a3',
    '#2e86c1',
    '#17a589',
    '#138d75',
    '#229954',
    '#28b463',
    '#d4ac0d',
    '#d68910',
    '#ca6f1e',
    '#ba4a00',
    '#d0d3d4',
    '#a6acaf',
    '#839192',
    '#707b7c',
    '#2e4053',
    '#273746',
    
    
    '#922b21',
    '#b03a2e',
    '#76448a',
    '#6c3483',
    '#1f618d',
    '#2874a6',
    '#148f77',
    '#117a65',
    '#1e8449',
    '#239b56',
    '#b7950b',
    '#b9770e',
    '#af601a',
    '#a04000',
    '#b3b6b7',
    '#909497',
    '#717d7e',
    '#616a6b',
    '#283747',
    '#212f3d',
    
    
    '#7b241c',
    '#943126',
    '#633974',
    '#5b2c6f',
    '#1a5276',
    '#21618c',
    '#117864',
    '#0e6655',
    '#196f3d',
    '#1d8348',
    '#9a7d0a',
    '#9c640c',
    '#935116',
    '#873600',
    '#979a9a',
    '#797d7f',
    '#5f6a6a',
    '#515a5a',
    '#212f3c',
    '#1c2833',
    
    
    '#641e16',
    '#78281f',
    '#512e5f',
    '#4a235a',
    '#154360',
    '#1b4f72',
    '#0e6251',
    '#0b5345',
    '#145a32',
    '#186a3b',
    '#7d6608',
    '#7e5109',
    '#784212',
    '#6e2c00',
    '#7b7d7d',
    '#626567',
    '#4d5656',
    '#424949',
    '#1b2631',
    '#17202a',
]
