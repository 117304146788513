import React, { useCallback, useEffect, useState } from "react";

import './AdminPaid.css';
import { GoBack } from "../GoBack/GoBack";
import { CreditNumber } from "../CreditNumber/CreditNumber";
import { Dots } from "../../icons/outline/Dots";
import { AdminCard } from "../AdminCard/AdminCard";
import { PaymentMethod } from "../../interfaces/PaymentMethodInterface";
import { AddPaymentMethod, GetPaymentMethodsByUserId } from "../../Services/PaymentMethod.service";
import { useSelector } from "react-redux";
import { Loader } from "../Loader/Loader";
import { AddPaymentInterface } from "../../interfaces/AddPaymentInterface";
import { toast } from "react-toastify";
import { deletePaymentMethod, updateSuscription } from "../../Services/Subscription.service";
import { Close_red } from "../../icons/solid/Close_red";

interface AdminPaidProps {
    subscriptionId: string;
    handleGoBack: () => void;
}
export const AdminPaid: React.FC<AdminPaidProps> = ({
    subscriptionId,
    handleGoBack = () => {},
}) => {

  const { userDetail } = useSelector((state: any) => state.user);

  const [activeComponent, setActiveComponent] = useState('AdminPaid');

  const [loading, setLoading] = useState<boolean>(false);
  const [PaymentMethods, setPaymentMethos] = useState<PaymentMethod[]>([]);

  // method to save new card
  const handleSaveCard = async (token: string) => {
    try {
      // console.log("token: ", token);
      setLoading(true);
      const tmpPaymentMethod: AddPaymentInterface = {
          token: token,
          userId: userDetail.id
      };
      const response = await AddPaymentMethod(tmpPaymentMethod);
      // console.log("🚀 ~ handleSaveCard ~ response:", response);
      setLoading(false);
      if(response){
          toast.success("Tarjeta guardada correctamente", {autoClose: 6000});
          getPaymentMethod();
          setActiveComponent('AdminPaid');  
      } else {
          toast.error("Error al guardar la tarjeta");
      }
    } catch (error:any) {
        // console.log("🚀 ~ handleSaveCardClick ~ error:", error);
        setLoading(false);
        toast.error("Error al guardar la tarjeta "+error.merchant_message, {autoClose: 6500});
    }
  }

  // method to change the primary payment method
  const handleSelectClick = async (paymentMethod: PaymentMethod) => {
    // console.log("🚀 ~ handleSelectClick ~ paymentMethod:", paymentMethod, subscriptionId);
    if(paymentMethod.active) {
        toast.warning("La tarjeta seleccionada ya está activa");
    } else {
        setLoading(true);
        try {
            const response = await updateSuscription({
                paymentMethodId: paymentMethod.id || "",
                subscriptionId: subscriptionId
            });
            if(response) {
                toast.success("Tarjeta seleccionada correctamente", {autoClose: 6000});
                getPaymentMethod();
                setActiveComponent('AdminPaid');  
            } else {
                toast.error("Error al seleccionar la tarjeta");
            }
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ handleSelectClick ~ error:", error);
            setLoading(false);
            toast.error("Error al seleccionar la tarjeta");
        }
    }
  }

  // method to delete the payment method
  const handleDeletePaymentMethod = async (paymentMethod: PaymentMethod) => {
    console.log("🚀 ~ handleDeletePaymentMethod ~ paymentMethod", paymentMethod)
    if(!paymentMethod.active) { 
        setLoading(true);
        try {
            const response = await deletePaymentMethod(paymentMethod.id || "");
            console.log("🚀 ~ handleDeletePaymentMethod ~ response:", response);
            toast.error("Tarjeta eliminada correctamente");
            getPaymentMethod();
            setActiveComponent('AdminPaid');  
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ handleDeletePaymentMethod ~ error:", error);
            setLoading(false);
            toast.error("Hubo un error al eliminar la tarjeta, por favor inténtelo más tarde.");
        }
    } else {
        toast.error("No es posible eliminar la tarjeta seleccionada, ya que está asociada a una suscripción activa.", {autoClose: 6500});
    }
  }
  const handleAddCard = () => {
    // if(scheduleOperation && scheduleOperation.type === "cancelSubscription") {
    //   toast.error("No puedes administrar la forma de pago, ya que tienes una cancelación de suscripción programada");
    // } else {
      setActiveComponent('addCard');
    // }
  }

  // metho to back to the previous component
  const handletoGoBack = () => {
    if(activeComponent === 'AdminPaid') {
      handleGoBack();
    } else {
      setActiveComponent('AdminPaid');
    }
  }

  // method to get the payment method from the user
  const getPaymentMethod = async () => {
    try {
      setLoading(true);
      const response = await GetPaymentMethodsByUserId(userDetail.id);
      console.log("🚀 ~ getPaymentMethod ~ response:", response)
      if(response) {
        setPaymentMethos(response);
      }
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ getPaymentMethod ~ error:", error);
      setLoading(false);
      // toast.error("Error al obtener el método de pago");
    }
  }
  // method to scroll to the top of the page
  const scrollToTop = useCallback(() => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
  }, []);

  useEffect(() => {
      scrollToTop();
  }, []);

  useEffect(() => {
    getPaymentMethod();
  }, [activeComponent]);

  return (
    <div className="adminPaid">
        <GoBack
            title={activeComponent === 'AdminPaid' ? "Administrar forma de pago" : "Agregar tarjeta"}
            handleClick={()=>handletoGoBack()}
        />
       {!loading ? <>
         {activeComponent === 'AdminPaid' ?
        <div className="admin-content-card">
            {PaymentMethods.map((c, idx) => (
                <div className="adminPaid-card" key={idx}>
                    <div className="adminPaid-row">  
                      <CreditNumber
                        brandCard={c.cardBrand}
                        lastDigits={c.lastFour}
                        textCard
                      />
                      <div className="adminPaid-button-dot" onClick={()=>handleDeletePaymentMethod(c)}>
                        <Close_red/>
                      </div>
                    </div>
                    <div onClick={()=>handleSelectClick(c)} className={`smalltext-header ${c.active ? "adminPaid-button" : "adminPaid-button-none"}`}>
                      {c.active ? "Tarjeta seleccionada" : "Utilizar tarjeta"}
                    </div>
                </div>
            ))}
        </div> : 
        <AdminCard
            handleSaveCard={handleSaveCard}
        />}
        {activeComponent === 'AdminPaid' && 
        <div 
            onClick={()=>handleAddCard()} 
            className="smalltext-header adminPaid-text-blue">
                + Agregar tarjeta
        </div>}
       </>: <Loader />}
    </div>
  )
}