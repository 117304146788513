import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CloseWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const CloseWhite: React.FC<CloseWhiteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/png/close-white.png"} alt="" />
    </div>
  );
};