import React, { useCallback, useEffect } from "react";

import './Receipts.css';
import { TableHeader } from "../TableHeader/TableHeader";
import { TableBody } from "../TableBody/TableBody";
import { ReceiptInterface } from "../../interfaces/ReceiptInterface";
import { CardContent } from "../CardContent/CardContent";
import { ReceiptConst } from "../../Consts/ReceiptConst";
import { GoBack } from "../GoBack/GoBack";
import { ReceiptMobile } from "../ReceiptMobile/ReceiptMobile";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { GetReceiptFromUser } from "../../Services/Receipt.service";
import { Loader } from "../Loader/Loader";

interface ReceiptsProps {
}

export const Receipts: React.FC<ReceiptsProps> = ({

}) => {

    const {userDetail} = useSelector((state: any) => state.user);


    const [loading, setLoading] = React.useState<boolean>(false);
    const [receipts, setReceipts] = React.useState<ReceiptInterface[]>([]);

    // metho to get receipts of the user
    const getReceipts = async () => {
        try {
            setLoading(true);
            const response = await GetReceiptFromUser(userDetail.id);
            setReceipts(response);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getReceipts ~ error:", error);
            setLoading(false);
            toast.error("Error al obtener los recibos");
        }
    }

        // method to scroll to the top of the page
        const scrollToTop = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
   
    

    useEffect(() => {
        scrollToTop();
        getReceipts();
    }, []);

    return (
        <div className="receipts-content">
            {window.innerWidth > 961 && 
            <GoBack
                title={"Mis recibos"}
                handleClick={()=>{}}
            />}
            {!loading ? <>
                <CardContent className="receipt-web">
                <TableHeader name={["Descripción", `${window.innerWidth < 1150 ? 
                    'Nº Doc' : 'Nro de Doc'}`, "Fecha", "Monto", "Recibo", "Acciones"]} />
                { receipts.length>0 ?  receipts.map((d, idx) => {
                    return (
                    <div className="receipts-body">
                        <TableBody key={idx} receipt={d}/>
                    </div>
                    )
                }) : 
                <div className="receipts-nothing">
                    <div className="paragraph-header receipts-text">No tienes ningún recibo</div>
                </div>}
            </CardContent> 
            <div className="receipts-movil">
                {receipts.map((d, idx) => {
                    return (
                        <ReceiptMobile key={idx} receipt={d}/>
                    )
                })}
            </div>
            </>: <Loader />}
        </div>
    )  
} 