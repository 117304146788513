
import React, { useEffect, useState } from "react";

import './DefineRoute.css';

// importando componentes
import { Onboarding } from "../Onboarding/Onboarding";
import { Shipment } from "../Shipment/Shipment";


// importando iconos
import { LocalizationTag } from "../../icons/outline/LocalizationTag";
import { LocationCircle } from "../../icons/solid/LocationCircle";

// importando interfaces
import { IShipment } from "../../interfaces/Shipment";
import { ErrorInterface } from "../../interfaces/ErrorInterface";
import { FinalDirection } from "../FInalDirection/FinalDirection";
import { Compass } from "../../icons/solid/Compass";
import { Factory } from "../../icons/solid/Factory";
import { House } from "../../icons/solid/House";
import { DropdownMultiLine } from "../DropdownMultiLine/DropdownMultiLine";
import { Warehouse, WarehouseCourier } from "../../interfaces/WarehouseCourierInterface";
import { GetWarehouseCourierByService } from "../../Services/Warehouse.service";
import { set } from "date-fns";

interface DefineRouteProps {
    type: 'air' | 'sea' | 'land' | undefined; 
    origin: IShipment | undefined;
    handleOriginChange: (value: IShipment) => void;
    destination: IShipment | undefined;
    handleDestionationChange: (value: IShipment) => void;
    error?: {
        origin: ErrorInterface;
        destination: ErrorInterface;
        warehouseOrigin?: ErrorInterface;
        warehouseDestination?: ErrorInterface;
    }
    initDelivery?: string;
    handleInitDelivery?: (e: any) => void;
    finalDelivery?: string;
    handleFinalDelivery?: (e: any) => void;
    disabled: boolean;
    clearShipment: boolean;
    setClearShipment: React.Dispatch<React.SetStateAction<boolean>>;
    serviceId?: string;
    serviceTypeId: string;
    warehouseOrigin?: string;
    warehouseDestination?: string;
    handleWarehouseChange?: (value: string, type: "warehouseOrigin" | "warehouseDestination") => void;
    // setIsFullScroll?: React.Dispatch<React.SetStateAction<boolean>>;

    setSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DefineRoute: React.FC<DefineRouteProps> = ({
    type,
    origin,
    handleOriginChange,
    destination,
    handleDestionationChange,
    initDelivery='',
    handleInitDelivery=()=>{},
    finalDelivery='',
    handleFinalDelivery=()=>{},
    // setIsFullScroll = () =>{},
    error={
        type: {
            status: false,
            message: ''
        },
        origin: {
            status: false,
            message: ''
        }, 
        destination: {
            status: false,
            message: ''
        },
        warehouseDestination: {
            status: false,
            message: ''
        }, 
        warehouseOrigin: {
            status: false,
            message: ''
        }
    },
    disabled,
    clearShipment = false,
    setClearShipment,
    serviceId = "",
    serviceTypeId,
    warehouseOrigin = "",
    warehouseDestination = "",
    handleWarehouseChange = ()=>{},
    setSearchActive = () => {},
}) => {
    
    const [loading, setLoading] = useState<boolean>(false);

    const [warehouse, setWarehouse] = useState<WarehouseCourier>();

    const handleWarehouseOriginChange = (value: string, direction: string) => {
        handleWarehouseChange(value, "warehouseOrigin");
        handleInitDelivery(direction);
    }

    const handleWarehouseDestinationChange = (value: string, direction: string) => {
        handleWarehouseChange(value, "warehouseDestination");
        // handleFinalDelivery(direction);
        // setIsFullScroll(true);
       // handleFinalDelivery(direction);
    }

    const getWarehouseOrigin = async () => {
        try {
            setLoading(true);
            const response: any = await GetWarehouseCourierByService(serviceId);
            if(response.length>0) { 
                setWarehouse(response[0]);
            }
           
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ file: DefineRoute.tsx:104 ~ getWarehouseOrigin ~ error:", error)
            setLoading(false);
        }
    }


    useEffect(() => {
        if(serviceTypeId === 'courier' && serviceId.length>0) {
            getWarehouseOrigin();
        }
    }, [serviceId]);   

    
    return (
        <>
            {!loading && <div className="defineRoute-container">
            <div className="paragraph-header defineRoute-btn-color defineRoute-row__ defineRoute-title">
                <Compass/>
                Ruta
            </div>
            <div className="defineRoute-row defineRoute-row_bottom">
                <div className="defineRoute-from">
                    <LocationCircle/>
                    <div className="defineRoute-column ">
                        {serviceTypeId === 'agenciamiento-de-aduana' ? <Shipment 
                            text={`Ciudad, ${type === "air" ? "Aeropuerto" : "Puerto"}`}
                            type={type} 
                            shipment={origin} 
                            setShipment={handleOriginChange} 
                            error={error.origin} 
                            role='errorOrigin-origin-destiny'
                            disabled={disabled || origin?.city === ''}
                            clearShipment={clearShipment}
                            setClearShipment={setClearShipment}
                            setSearchActive={setSearchActive}
                        /> : 
                        <DropdownMultiLine
                            placeholder="Origen"
                            items={warehouse?.warehouseOrigin ? warehouse.warehouseOrigin.map((item) => ({
                                title: item.name,
                                values: [item.direction, item.city]
                            })) : []}
                            value={warehouseOrigin}
                            onItemClick={handleWarehouseOriginChange}
                            error={error.warehouseOrigin}
                        />}
                        {origin && origin.type === "city" && <FinalDirection 
                            textInput={
                                <div className="defineRoute-row__">
                                    <Factory className="icon-factory"/>
                                    Dirección de recojo
                                </div>
                            } 
                            placeholder="Fábrica, almacén"
                            handleInputchange={(e) => handleInitDelivery(e)}
                            value={initDelivery}
                            showOptional={serviceTypeId != 'courier'}
                            disabled={serviceTypeId === 'courier'} 
                        />}
                    </div>
                </div>

                <div className="defineRoute-to">
                    <div className="smalltext-header defineRoute-textColor">hacia</div>
                    <LocalizationTag className="defineRoute-icon"/>
                    <div className="defineRoute-column ">
                       {serviceTypeId === 'agenciamiento-de-aduana' ? <Shipment 
                            type={type} 
                            text={`${type === "air" ? "Aeropuerto" : "Puerto"}`}
                            shipment={destination} 
                            setShipment={handleDestionationChange} 
                            error={error.destination} 
                            role='errorDestination-origin-destiny'
                            disabled={false}
                            clearShipment={clearShipment}
                            setClearShipment={setClearShipment}
                            setSearchActive={setSearchActive}
                            isDestination
                        /> : 
                        <DropdownMultiLine
                            placeholder="Destino"
                            items={warehouse?.warehouseDestination ? warehouse.warehouseDestination.map((item) => ({
                                title: item.name,
                                values: [item.direction, item.city]
                            })) : []}
                            value={warehouseDestination}
                            onItemClick={handleWarehouseDestinationChange}
                            error={error.warehouseDestination}
                        />}
    
                        {destination && <FinalDirection 
                            textInput={
                                <div className="defineRoute-row__">
                                    <House className="icon-house"/>
                                    Dirección de entrega
                                </div>
                            }
                            placeholder="Fábrica, almacén"
                            handleInputchange={(e) => handleFinalDelivery(e)}  
                            value={finalDelivery} 
                            showOptional={serviceTypeId != 'courier'}
                            // disabled={serviceTypeId === 'courier'} 
                        />}
                    </div>
                </div>
            </div>
        </div>}
        </>
    )
}