import React, { useState } from "react";

/** importando componentes */
import { Modal } from "../../Modal/Modal/Modal";
import { TextField } from "../../TextField/TextField";
import { Button } from "../../Button/Button";

// Immportando estilos
import './ModalOperationHeader.css';
import { Loader } from "../../Loader/Loader";

interface ModalOperationHeaderProps {
    title?:string;
    setTitle?: React.Dispatch<React.SetStateAction<string>>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    editOperation?: boolean;
    onClickCancel: ()=>void;
    onClickSave: ()=>void;
    onClickDelete?: ()=>void;
    loading?: boolean;
}

export const ModalOperationHeader: React.FC<ModalOperationHeaderProps> = ({
    title,
    setTitle=()=>{},
    show,
    setShow,
    editOperation = false,
    loading = false,
    onClickCancel,
    onClickSave,
    onClickDelete=()=>{}
}) => {

    // UseState for number of characters in the title
    const [countWord, setCoundWord] = useState<number>(0);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value.length <= 60) {
            setTitle(e.target.value);
            setCoundWord(e.target.value.length);
        }       
    }

    return (
        <div className="modalOperationHeader">
            <Modal title={editOperation?'Edita el título de la operación': '¿Estas seguro que deseas eliminar esta operación?'} show={show} setShow={setShow} >
                { !loading ? <> 
                    {editOperation ? <div className="modalOperationHeader-textField">
                    <div className="modalOperationHeader-edit ">
                        <TextField 
                            value={title} 
                            title="" 
                            placeholder="Ingrese el nuevo nombre de la operación" 
                            onChange={handleTitleChange} 
                            role='text-modal-quote'
                            type='text'
                        />
                        <div className="smalltext modalOperationHeader-countLetters"> {countWord} / 42 caracteres</div>
                    </div>
                    <div className="modalOperationHeader-footer">
                        <Button content="Cancelar" color="red" onClick={onClickCancel}/>
                        <Button content="Aceptar" color="green-2" onClick={onClickSave}/>
                    </div>
                    </div> : 
                    <div className="modalOperationHeader-delete">
                        <div className="paragraph">
                            Recuerda que una vez eliminada no podrás recuperarla. Selecciona aceptar para confirmar.
                        </div>
                        <div className="modalOperationHeader-footer">
                            <Button content="Cancelar" color="red" onClick={onClickCancel}/>
                            <Button content="Aceptar" color="green-2" onClick={onClickDelete}/>
                        </div>
                    </div>}
                </> : <Loader />}
            </Modal>
        </div>
    )
}