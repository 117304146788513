import React, { useCallback, useEffect, useState } from "react";

import './AdminCard.css';
import { useSelector } from "react-redux";
import { CreditCard } from "../CreditCard/CreditCard";

interface AdminCardProps {
    handleSaveCard: (token: string)=>void;
}
export const AdminCard: React.FC<AdminCardProps> = ({
    handleSaveCard
}) => {

   const { userDetail } = useSelector((state: any) => state.user);

     // method to scroll to the top of the page
    const scrollToTop = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        scrollToTop();
    }, []);


  return (
    <div className="adminCard">
        <CreditCard
            price={0}
            email={userDetail.email}
            currency={"USD"}
            onClick={handleSaveCard}
        />
    </div>
  )
}