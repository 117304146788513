import { formatDistanceStrict } from 'date-fns';
import { TranslateTime, TranslateTimeMin } from './TranslateTime';

export const GetLastTimeText = (day: any) =>  {
    const timeText = formatDistanceStrict( day, new Date())
    const textSplit = timeText.split(' ');

    return parseDateLocal(Number(textSplit[0]), textSplit[1])
    // return textSplit[0]+' '+TranslateTime(textSplit[1]);
}

export const GetLastTimeMinText = (day: any) =>  {
    const timeText = formatDistanceStrict( day, new Date())
    const textSplit = timeText.split(' ');
    return textSplit[0]+' '+TranslateTimeMin(textSplit[1]);
}

const parseDateLocal = (numDate: number, nameDate: string) : string => {
    if(nameDate.includes("second") || nameDate.includes('minute') || nameDate.includes('hour')) {
        return "Hoy";
    } else if(nameDate.includes('day')) {
        return numDate === 1 ? "Hoy" : (numDate >2 && numDate < 7 ? "Ayer" : (numDate > 7 && numDate < 14 ? "1 semana" : 
        (numDate > 14 && numDate < 21 ? "2 semanas" : "3 semanas")));
    } else {
        return numDate+' '+TranslateTime(nameDate);
    }
}   