import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

// importando servicios

import { GetAllReviewByServiceId } from "../Services/Review.service";

//metodo para obtener los revies de cada servicio
export const GetReviewByServiceId = createAsyncThunk(
    'reviewService/reviewByService',
    async (id:string, thunkAPI: any) => {
        try {
            const response = await GetAllReviewByServiceId(id);
            // console.log("🚀 ~ file: Review.ts ~ line 15 ~ response", response)
            if(response) {
                thunkAPI.dispatch(setError({ status: false, message:'', code:''}));
                return response;
            }
        } catch (error: any) {
             console.log('slice/reviewService/reviewByService => error:', error);
             thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
             return thunkAPI.rejectWithValue();
        }
    }
)

const initialState = {
    reviewListService: null,
}

const reviewSlice = createSlice({
    name: "review",
    initialState,
    reducers: {},
    extraReducers: {
        [GetReviewByServiceId. fulfilled.toString()]: (state, action) => {
            state.reviewListService = action.payload;
        },
        [GetReviewByServiceId. rejected.toString()]: (state, action) => {
            state.reviewListService = null;
        },
    }
})

const {reducer} = reviewSlice;
export default reducer;