import React from "react";


/** importando modals */

/** importando css */
import './Modal.css';
import { STORAGE_ASSETS } from "../../../../Consts/BaseUrl";

/** declarando interface */
interface ModalProps {
    title: string;
    icon?: React.ReactNode;
    show: boolean;
    setShow: (e:boolean) => void;
    children?: React.ReactNode;
    close?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
    title,
    icon,
    show,
    setShow,
    children,
    close
}) => {


    return (
        <>
            {show &&
            <div className="modal-notification--main" role='modal-notification-main'>
                <div className="modal-notification-container">
                    <div className="modal-notification__header">
                        <div className="modal-notification__heade-left">
                            <div className="modal-notification-logo">
                                {icon}
                            </div>
                            
                            <div className="modal-notification-header__text">
                                <div className="small-bold ">{title}</div>
                            </div>
                        </div>
                        <img src={STORAGE_ASSETS+'/images/svg/close_big.svg'} alt="" onClick={()=>{setShow(false)}} role='modal-btn-notification'/>
                    </div>
                    
                    {children}
                </div>   
            </div>}  
        </>
    )
}
