import { DB, Functions } from "../firebaseConfig/FirebaseConfig";

import { collection, doc, getDocs, getDoc, addDoc, query, where, orderBy, updateDoc, onSnapshot, DocumentSnapshot, limit, startAfter, getCountFromServer} from "firebase/firestore"; 
import { httpsCallable } from "firebase/functions";
import { GetRequestQuoteById } from "./RequestQuote.service";
import { GetOrderDetail } from "./Order.service";
import { sendNotificationToUser } from "./Notification.service";
import { OperationInterface } from "../interfaces/Operation";


const GetAllOperation= async() => {
    const response = await getDocs(collection(DB, "operation")); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}
const GetOperationByUser= async(id: string) => {
    const q = query(collection(DB, "operation"), where("userId", "==", id), where("active", "==", true), orderBy("updatedAt", "desc"));
    const response = await getDocs(q); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

// method to get operation in real time by user id
const GetOperationByUserIdRT= async(userId: string, cb: (e: any)=>void = ()=> {}) => {
    const q = query(collection(DB, "operation"), where("userId", "==", userId), where("active", "==", true), orderBy("updatedAt", "desc"));
    onSnapshot(q, (querySnapshot) => {
        let data:any[] = [];
        querySnapshot.forEach((doc) => {
            data.push({id: doc.id, ...doc.data()});
        });

        cb(data);
    });
}

// method to get operation with pagination in real time by user id 
const GetOperationByUserIdRTPagination= async(userId: string, startAfterDoc: DocumentSnapshot | undefined,
        numPerPage: number) => {
    //cb: (e: OperationInterface[], lastDoc: DocumentSnapshot)=>void = ()=> {}
    const dataCollection = collection(DB, "operation");
    let dataQuery= query(dataCollection, where("userId", "==", userId), where("active", "==", true),
        orderBy("updatedAt", "desc"), limit(numPerPage));
    if(startAfterDoc) {
        dataQuery = query(dataQuery, startAfter(startAfterDoc));
    }
    const response = await getDocs(dataQuery);
    let data:OperationInterface[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()} as OperationInterface))
    } 
    return {
        data: data,
        endDoc: response.docs[response.docs.length-1]
    };
    // onSnapshot(dataQuery, (querySnapshot) => {
    //     let data:OperationInterface[] = [];
    //     querySnapshot.forEach((doc) => {
    //         data.push({id: doc.id, ...doc.data()} as OperationInterface);
    //     });
    //     cb(data, querySnapshot.docs[querySnapshot.docs.length-1]);
    // });
}

export const getNumPages = async (userIrd: string) => {
    const q = query(collection(DB, "operation"), where("userId", "==", userIrd), where("active", "==", true));
    const count = await getCountFromServer(q);
    return  count.data().count;
}

export const GetNumberOfOperationsByUser = async(userId: string) => {
    const q = query(collection(DB, "operation"), where("userId", "==", userId));
    const response = await getDocs(q);
    return response.size;
}

// method to get operation in real time by id
const GetOperationDetailRT= async(id: string, cb: (e: any)=>void = ()=> {}) => { 
    onSnapshot(doc(DB, "operation", id), (doc) => {
        cb({id: doc.id, ...doc.data()});
    });
}

// const GetOperationQuotation = async () => {
//     const q = query(collection(DB, "operation"), where("status.requestQuote", "==", true))
//     const response = await getDocs(q); // trae los documentos de la coleccion de firestore
//     let data:any[] = [];
//     if(!response.empty) {
//         data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
//     } 
//     return data;
// }

const GetOperationById = async (id: string) => {
    const response = await getDoc(doc(DB, 'operation', id)); // trae los documentos de la coleccion de firestore
    /*console.log(response.data())*/
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()});
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

const CreateOperation =  async(operation: any) => {
    try {
        const response = await addDoc(collection(DB, "operation"), operation);  
        // console.log("🚀 ~ file: Operation.service.ts ~ line 53 ~ CreateOperation ~ response", response)
        return response;
    } catch (error) {
        console.log(error);
        // console.log(error);
    }
}

const ChangeOperationTitle = async(operationId: string, title: string) => {
    const editOperationTitle =  httpsCallable(Functions, 'editOperationTitle')
    return editOperationTitle({operationId: operationId, title: title})
    .then((response)=>{
        return response;
    })
    .catch((error) => {
        console.log(error);
        return error;
    });
}

const DeteleOperation = async(operationId: string) => {
    const desactiveOperation = httpsCallable(Functions, 'desactiveOperation')
    return desactiveOperation({operationId: operationId})
    .then((response)=>{
        return response;
    }
    ).catch((error) => {
        console.log(error);
        return error;
    });
}

// actualiza una operacion segun el "id"
const UpdateOperation = async(operation: any) => {
    try {
        if(operation.id) {
            const requestRef = doc(DB, 'operation', operation.id);
            const response =  await updateDoc(requestRef, {...operation});

            // console.log("UpdateRequestQuote", response)
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

// method to send push notification
const SendNotificationProvider = async (title: string, content: string, operationName: string, operationId: string,
    userName: string, userImg: string, event: "newRQ" | "respQuotation" | "newQuotation" | "newOrder" | "editRQUser" | "editRQProvider" |
    "rejectRQ" | "editOperation", urlPath: string, documentType: "requestQuote" | "order", documentIds: string[]) => {
    try {
        if(documentIds.length> 0) {
            documentIds.forEach(async (id: string) => {
                const response: any = await (documentType === "requestQuote" ? GetRequestQuoteById(id) : GetOrderDetail(id));
                if(response && response.usersProvider && response.usersProvider.length>0) {
                    response.usersProvider.forEach(async (userId: any) => {
                        sendNotificationToUser({
                            title: title,
                            content: content,
                            operationName: operationName,
                            operationId: operationId,
                            userId: userId,
                            name: userName,
                            image: userImg,
                            event: event,
                            urlPath: urlPath
                        }).then((responseSend) => {
                            return responseSend
                        }).catch((error) => {
                            console.log("🚀 ~ file: Operation.service.ts:147 ~ response.usersProvider.forEach ~ error:", error)
                            return error;
                        });
                    });            
                }
            })
        }
    } catch (error) {
      console.log("🚀 ~ file: Operation.service.ts:155 ~ error:", error)
      return error;
    }
  }


export {
    DeteleOperation,
    ChangeOperationTitle,
    CreateOperation,
    GetAllOperation,
    GetOperationById,
    GetOperationByUser,
    UpdateOperation,
    GetOperationByUserIdRT,
    GetOperationDetailRT,
    SendNotificationProvider,
    GetOperationByUserIdRTPagination
}