import React, { Fragment, useEffect } from "react";
import { ImportTimelineStages } from "../../@types/timelineStagesTypes";
import "./ImportTimeline.css";
import { TimeLineModalInterface } from "../../interfaces/TimeLineModalInterface";
import { GetAllTimeLineByServiceType } from "../../Services/TimeLineModal.service";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface ImportTimelineProps {
  stage: number;
  serviceTypeId: string;
  activeClick: boolean;
  setStage: React.Dispatch<React.SetStateAction<number>>;
}

export const ImportTimeline: React.FC<ImportTimelineProps> = ({
  stage = 1,
  serviceTypeId,
  activeClick = false,
  setStage,
}) => {

  const [timelineStages, setTimelineStages] = React.useState<TimeLineModalInterface[]>([]);

  const getTimeLineModal = async () => {
    try {
      const response = await GetAllTimeLineByServiceType(serviceTypeId);
      setTimelineStages(response);
    } catch (error) {
      console.log("🚀 ~ file: ImportTimeline.tsx:24 ~ getTimeLineModal ~ error:", error)
    
    }
  }

  useEffect(() => {
    getTimeLineModal();
  },[serviceTypeId]);
  
  return (
    <div className="import-timeline">
      { timelineStages.length>0 && timelineStages.map((tm: TimeLineModalInterface, idx: number) => (
        <Fragment key={idx}>
        {timelineStages.length-1 !== idx ? 
          <div
            className={ tm.position <= stage ? "import-timeline-stage-yellow" : "import-timeline-stage"}
            onClick={activeClick ? () => setStage(tm.position) : () => {}}
          >
            <h2 className="smalltext-header">{tm.name}</h2>
          </div> :
          tm.name === "Resumen" && <div
              onClick={activeClick ? () => setStage(tm.position) : () => {}}
              className={ stage === 3 ? "import-timeline-last-stage-yellow" : "import-timeline-last-stage"}
              style={{
                backgroundColor: `${
                  stage === 3 ? "var(--yellow)" : "var(--white-75)"
                }`,
              }}
            >
              <img src={STORAGE_ASSETS+"/images/svg/target-flag-icon.svg"} alt="" />
            </div>
          }

         {timelineStages.length-1 != idx && <div
            className={tm.position <= stage ? "import-timeline-connection-yellow" : "import-timeline-connection"}
          />}
        </Fragment>
      ))} 
    </div>
  );
};
